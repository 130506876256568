app.directive('baiInsightsDecisionMapWizard', [function () {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'App/templates/insights/mappingdecisions/wizard/baiInsightsDecisionMapWizard.html',
        scope: true,
        controller: ['$scope', '$q', '$location', '$timeout', 'InsightsService', 'InsightsDecisionMapService', 'errorDisplayerUtil',
            function ($scope, $q, $location, $timeout, InsightsService, InsightsDecisionMapService, errorDisplayerUtil) {
                $scope.decisionMapId = InsightsDecisionMapService.getDecisionMap().id;
                $scope.decisionMapName = InsightsDecisionMapService.getDecisionMap().name;
            $scope.isNextEnabled = false;
            $scope.updateDecisionMapError = {};
            $scope.updateDecisionMapError.error = errorDisplayerUtil.create();
            $scope.isNextLoading = false;
            $scope.functionStep = false;
            $scope.roleStep = false;
            $scope.responsibilitiesStep = false;
            $scope.experiencesStep = false;
            $scope.risksStep = false;
            $scope.commentLayout = 'col-md-12';
            if ($scope.currentWizardStep === "function") {
                $scope.backStepUrl = "";
                $scope.nextStepUrl = "insights/wizard/role";
                $scope.functionStep = true;
                $scope.commentType = 'functionComment';
                $scope.stepTips = 'In many organizations the function name will be the same as the department name; however, if you are organized geographically, for example, one department might have multiple functions. These will need separate decision reports because each report can only have one function and one role.';
            } else if ($scope.currentWizardStep === "role") {
                $scope.backStepUrl = "insights/wizard/function";
                $scope.nextStepUrl = "insights/wizard/responsibilities";
                $scope.functionStep = true;
                $scope.roleStep = true;
                $scope.commentType = 'roleComment';
                $scope.stepTips = 'Role is like title and position but standardized so data from other organizations can be leveraged in your decisions. Only one role can be selected per decision report. If you are excluding some people who have similar titles or including people with very different titles, an explanatory comment might be necessary.';
            } else if ($scope.currentWizardStep === "responsibilities") {
                $scope.backStepUrl = "insights/wizard/role";
                $scope.nextStepUrl = "insights/wizard/experiences";
                $scope.functionStep = true;
                $scope.roleStep = true;
                $scope.responsibilitiesStep = true;
                $scope.commentType = 'responsibilityComment';
                $scope.stepTips = 'Be thorough. Selecting all the applicable responsibilities is necessary to see an appropriate course listing. If you do not see a responsibility you can select <b>See More</b> for a complete listing. If you are excluding some of the responsibilities typically associated with a particular role, or including some responsibilities not typically associated with a role, an explanatory comment might be necessary.';
            } else if ($scope.currentWizardStep === "experiences") {
                $scope.backStepUrl = "insights/wizard/responsibilities";
                $scope.nextStepUrl = "insights/wizard/risks";
                $scope.functionStep = true;
                $scope.roleStep = true;
                $scope.responsibilitiesStep = true;
                $scope.experiencesStep = true;
                $scope.commentType = 'experienceComment';
                $scope.stepTips = 'Most groups of people include multiple experience levels. Your selections will influence whether Introduction, Essentials, or Comprehensive courses are displayed.';
            } else if ($scope.currentWizardStep === "risks") {
                $scope.backStepUrl = "insights/wizard/experiences";
                $scope.nextStepUrl = "insights/decisionmapcourses/" + $scope.decisionMapId;
                $scope.functionStep = true;
                $scope.roleStep = true;
                $scope.responsibilitiesStep = true;
                $scope.experiencesStep = true;
                $scope.risksStep = true;
                $scope.commentType = 'riskComment';
                $scope.stepTips = 'Including comments to explain risk rankings is recommended. If a risk is not appropriate, it does not need to be ranked, and will not appear in the final report. If you do not see a relevant risk, you can select <b>See More</b> for a complete listing. There may be other risks or training requirements of your specific regulator, state, or financial institution that BAI Training Insights does not address. We recommend you reference those and what steps you are taking to address them in the comments.';
            } 


            $scope.newCommentText = '';
            $scope.commentsClass = ($scope.commentType === 'functionComment' || $scope.commentType === 'roleComment' || $scope.commentType === 'experienceComment') ? 'insightsComments' : '';

            $scope.gotToNextStep = function() {
                $scope.isNextLoading = true;

                var saveDecisionmap = InsightsService.updateDecisionMap(InsightsDecisionMapService.getDecisionMap());
                var savePromises = [];
                savePromises.push(saveDecisionmap);

                if ($scope.newCommentText !== undefined && $scope.newCommentText !== '') {
                    var saveComment = InsightsService.saveComment($scope.decisionMapId, $scope.newCommentText, $scope.commentType);
                    savePromises.push(saveComment);
                }               

                $q.all(savePromises).then(function(success) {
                    $location.path($scope.nextStepUrl);
                    if ($scope.currentWizardStep !== "risks"){
                        $scope.isNextLoading = false;
                    }
                }, function(failure) {
                    if ($scope.currentWizardStep !== "risks") {
                        $scope.isNextLoading = false;
                    }
                    $scope.updateDecisionMapError.error.setError(failure.StatusCode, failure.ErrorMessage);
                    $timeout(function() {
                        $scope.updateDecisionMapError.error.reset();
                    }, 3000);
                });

            };

            $scope.goToPreviousStep = function() {
                $location.path($scope.backStepUrl);
            };

            this.enableNextButton = function () {
                $scope.isNextEnabled = true;
            };
            this.disableNextButton = function() {
                $scope.isNextEnabled = false;
            };
        }]
    };
}]);