app.service('StatesService', ['$http', '$q', function($http, $q) {
    var _this = this;

    _this.getLatitudeStateList = function (countryId) {
        var req = {
            method: 'GET',
            url: 'api/user/states/'  + countryId + '/'
        };

        return $http.get(req.url).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };


    _this.getLatitudeAllStateList = function () {
        var req = {
            method: 'GET',
            url: 'api/user/states/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http.get(req.url).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };
}]);