app.factory('PeopleGroupFunctions', ['$http', '$q', '$filter', 'GroupsService', 'UsersService', 'OrganizationsService', 'JobsService', 'CountriesService', 'StatesService', function ($http, $q, $filter, GroupsService, UsersService, OrganizationsService, JobsService, CountriesService, StatesService) {

    function peopleGroupActions(peopleGroupId) {
        var peopleGroupStructure;
        if (typeof peopleGroupId === 'undefined' || peopleGroupId === null) {
            peopleGroupStructure = GroupsService.generateRootContainer(true);
        } else if (typeof peopleGroupId !== 'undefined' && peopleGroupId !== null && peopleGroupId !== 'ruleTab') {
            peopleGroupStructure = GroupsService.peopleGroupStructure(peopleGroupId);
        } else if (typeof peopleGroupId !== 'undefined' && peopleGroupId === 'ruleTab') {
            peopleGroupStructure = null;
        }

        var selectOptionsData = GroupsService.getPeopleGroupFilters();
        var containerOperandsDataPromise = GroupsService.getContainerOperands();
        var orgTreePromiseGroups = OrganizationsService.getOrganizationHierarchy();
        var peopleGroupsTreePromise = GroupsService.getPeopleGroups();
        var jobs = JobsService.getAllJobs();
        var countries = CountriesService.getLatitudeCountryList(UsersService.getPortalId());
        var states = StatesService.getLatitudeAllStateList();

        var all = [peopleGroupStructure, selectOptionsData, containerOperandsDataPromise, orgTreePromiseGroups, peopleGroupsTreePromise, jobs, countries, states];

        return all;

    }

    return {
        peopleGroupActions: peopleGroupActions
    };


}]);
