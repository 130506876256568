app.directive('baiDecisionMapSummary', [function () {
    return {
        restrict: 'E',
        templateUrl: 'App/templates/insights/home/baiDecisionMapSummary.html',
        controller: ['$scope', '$location', '$timeout', '$filter', 'InsightsService', 'InsightsDecisionMapService', 'errorDisplayerUtil', 'UsersService',
            function ($scope, $location, $timeout, $filter, InsightsService, InsightsDecisionMapService, errorDisplayerUtil, UsersService) {
                $scope.isLoading = true;
                $scope.isEditLoading = false;
                $scope.hideSummaryTable = false;
                $scope.reverseSort = true; // default to true to sort by created date descending
                $scope.sortPropertyName = 'fullDateWithTime';
                $scope.archiveLinkText = 'View Archived';
                $scope.pages = {};
                $scope.pages.pageLimit = 50;
                $scope.pages.currentPage = 1;
                $scope.pages.maxVisiblePages = 10;
                $scope.actionsPopoverFlags = [];
                $scope.getDecisionMapSummaryError = errorDisplayerUtil.create();
                $scope.deleteSuccess = errorDisplayerUtil.create();
                $scope.editClickError = errorDisplayerUtil.create();
                $scope.currentAction = '';

                $scope.currentRoleTA = false;
                var currentUserRole = UsersService.getCurrentRoleType();
                if (currentUserRole === 'ta') {
                    $scope.currentRoleTA = true;
                }

                $scope.getDecisionMapSummaries = function (includeArchived) {
                    $scope.isLoading = true;
                    $scope.hideSummaryTable = false;
                    var decisionMapSummaryPromise = InsightsService.getDecisionMapSummaries(includeArchived);
                  return decisionMapSummaryPromise.then(function (success) {
                        $scope.isLoading = false;
                        $scope.decisionMaps = success;
                        $scope.loadSummaryTable();
                    }, function (failure) {
                        $scope.isLoading = false;
                        if (failure.StatusCode === 404) {
                            $scope.hideSummaryTable = true;
                        } else {
                            $scope.getDecisionMapSummaryError.setError(failure.StatusCode, failure.ErrorMessage);
                        }
                    });
                };

                $scope.loadSummaryTable = function () {
                    $scope.decisionMaps = $filter('orderBy')($scope.decisionMaps, $scope.sortPropertyName, $scope.reverseSort);
                    $scope.nextPage(1);
                    _.each($scope.decisionMaps, function (dm) {
                        dm.isEditLoading = false;
                        dm.fullDateWithTime = dm.created.jsDate;
                        dm.pgORorg = dm.businessOrganizationName !== '' ? dm.businessOrganizationName + '<br />' + (dm.includeOrganizationHierarchy ? 'Include Hierarchy: Yes' : 'Include Hierarchy: No') : dm.peopleGroupName;
                        //for the decision makers in each map, order by the username
                        var dms = $filter('orderBy')(dm.decisionMakers, 'userName', false);
                        if (dms.length > 0) {
                            //then use the sorted list to get the username to sort by
                            dm.decisionMakerSort = dms[0].userName;
                        }
                        $scope.actionsPopoverFlags.push(false);
                    });
                    $scope.isLoading = false;
                };


                $scope.getDecisionMapSummaries(false);

                $scope.sortBy = function(propertyName) {
                    $scope.closeAllActionMenus();
                    $scope.reverseSort = ($scope.sortPropertyName === propertyName) ? !$scope.reverseSort : false;
                    $scope.sortPropertyName = propertyName;
                    $scope.decisionMaps = $filter('orderBy')($scope.decisionMaps, propertyName, $scope.reverseSort);
                    $scope.pages.currentPage = 1;
                    $scope.nextPage();
                };

                $scope.nextPage = function () {
                    $scope.pagedDecisionMaps = $scope.decisionMaps.slice(($scope.pages.currentPage - 1) * $scope.pages.pageLimit, $scope.pages.currentPage * $scope.pages.pageLimit);
                };

                $scope.editClick = function (id) {
                    var index = _.findIndex($scope.decisionMaps, { "id": id });
                    if (index > -1) {
                        if ($scope.decisionMaps[index].status === "Created" || $scope.decisionMaps[index].status === "Started") {
                            $scope.decisionMaps[index].isEditLoading = true;
                            $scope.isEditLoading = true;
                            InsightsService.getDecisionMapById(id).then(function (success) {
                                InsightsDecisionMapService.setDecisionMap(success);
                                if ($scope.decisionMaps[index].status === "Created") {
                                    $location.path('insights/createdecisionmap');
                                } else if ($scope.decisionMaps[index].status === 'Started') {
                                    $location.path('insights/wizard/function');
                                }
                                $scope.isEditLoading = false;
                                $scope.decisionMaps[index].isEditLoading = false;
                            }, function (failure) {
                                $scope.editClickError.setError(failure.StatusCode, failure.ErrorMessage);
                                $timeout(function () {
                                    $scope.editClickError.reset();
                                }, 3000);
                                $scope.isEditLoading = false;
                                $scope.decisionMaps[index].isEditLoading = false;
                            });
                        } else if ($scope.decisionMaps[index].status === 'Confirmed' || $scope.decisionMaps[index].status === 'Rule Created' || $scope.decisionMaps[index].status === 'Archived') {
                            $location.path('insights/decisionmapreport/' + $scope.decisionMaps[index].id);
                        } else {
                            $location.path('insights/home');
                        }
                    }
                };

                $scope.copyDecisonMap = function (decisionMapId) {
                    var index = _.findIndex($scope.decisionMaps, { "id": decisionMapId });
                    $scope.decisionMaps[index].isEditLoading = true;
                    $scope.isEditLoading = true;
                    InsightsService.getDecisionMapById(decisionMapId).then(function (success) {
                        success.sourceDecisionMapId = success.id;
                        success.id = 0;
                        success.status = null;
                        success.statusChanged = null;
                        success.created = null;
                        success.createdBy = null;
                        success.modifiedBy = null;
                        success.modified = null;
                        success.endDate = null;
                        success.confirmedDateTime = null;
                        success.rule = { key: 0, value: "" };
                        success.name = success.name + '_copy';
                        _.each(success.decisionMakers, function (dm) {
                            dm.confirmed = 'false';
                        });
                        InsightsDecisionMapService.setDecisionMap(success);
                        $scope.decisionMaps[index].isEditLoading = false;
                        $location.path('/insights/createdecisionmap');
                    }, function (failure) {
                        $scope.editClickError.setError(failure.StatusCode, failure.ErrorMessage);
                        $timeout(function () {
                            $scope.editClickError.reset();
                        }, 3000);
                        $scope.decisionMaps[index].isEditLoading = false;
                        $scope.isEditLoading = false;
                    });
                };

                $scope.archiveUnarchiveDecisionMap = function (decisionMapId, status) {
                    var index = _.findIndex($scope.decisionMaps, { "id": decisionMapId });
                    $scope.decisionMaps[index].isEditLoading = true;
                    $scope.isEditLoading = true;
                    var decisionMapCall = InsightsService.getDecisionMapById(decisionMapId);
                    decisionMapCall.then(function (success) {
                        var decisionMap = success;
                        decisionMap.status = status;
                        var updateCall = InsightsService.updateDecisionMap(decisionMap);
                        updateCall.then(function (success) {
                            $scope.decisionMaps[index].isEditLoading = false;
                            $scope.isEditLoading = false;

                            if (status === 'Archived') {
                                var archivedMapIdx = _.findIndex($scope.decisionMaps, { "id": decisionMap.id });
                                if (archivedMapIdx > -1) {
                                    if ($scope.archiveLinkText === 'View Archived') {
                                        $scope.decisionMaps.splice(archivedMapIdx, 1);
                                    }
                                    $scope.loadSummaryTable();
                                }
                            }

                            if (status === 'Unarchive') {
                                //the report was unarchived, to get here, the archived should be on the page, now only show reports that are not archived
                                $scope.archiveLinkText = 'View Archived';
                                $scope.getDecisionMapSummaries(false);
                            }

                        }, function (failure) {
                            $scope.decisionMaps[index].isEditLoading = false;
                            $scope.isEditLoading = false;
                            $scope.editClickError.setError(failure.StatusCode, failure.ErrorMessage);
                        });
                    }, function (failure) {
                        $scope.decisionMaps[index].isEditLoading = false;
                        $scope.isEditLoading = false;
                        $scope.editClickError.setError(failure.StatusCode, failure.ErrorMessage);
                    });
                };

                $scope.setPopoverAction = function (action) {
                    $scope.currentAction = action;
                };

                $scope.deleteDecisionMap = function (decisionMapId) {
                    var index = _.findIndex($scope.decisionMaps, { "id": decisionMapId });
                    $scope.decisionMaps[index].isEditLoading = true;
                    $scope.isEditLoading = true;
                    var deleteCall = InsightsService.deleteDecisionMap(decisionMapId);
                    deleteCall.then(function (success) {
                        $scope.decisionMaps[index].isEditLoading = false;
                        $scope.isEditLoading = false;
                        $scope.getDecisionMapSummaries(true).then(function(success) {
                            $scope.deleteSuccess.setError(202, "Decision Report successfully deleted");
                            $timeout(function () {
                                $scope.deleteSuccess.reset();
                            }, 3000);
                        });
                    }, function(failure) {
                        $scope.decisionMaps[index].isEditLoading = false;
                        $scope.isEditLoading = false;
                        $scope.editClickError.setError(failure.StatusCode, failure.ErrorMessage);
                        $timeout(function() {
                            $scope.editClickError.reset();
                        }, 3000);
                    });
                };

                $scope.archiveDecisonMap = function (decisionMapId) {
                    var index = _.findIndex($scope.decisionMaps, { "id": decisionMapId });
                    $scope.decisionMaps[index].isEditLoading = true;
                    $scope.isEditLoading = true;

                    var decisionMapCall = InsightsService.getDecisionMapById(decisionMapId);
                    decisionMapCall.then(function (success) {
                        var decisionMap = success;
                        decisionMap.status = 'Archived';

                        var updateCall = InsightsService.updateDecisionMap(decisionMap);
                        updateCall.then(function (success) {
                            var archivedMapIdx = _.findIndex($scope.decisionMaps, { "id": decisionMap.id });
                            if (archivedMapIdx > -1) {
                                $scope.decisionMaps.splice(archivedMapIdx, 1);
                                $scope.loadSummaryTable();
                            }

                            $scope.decisionMaps[index].isEditLoading = false;
                            $scope.isEditLoading = false;
                        }, function (failure) {
                            $scope.decisionMaps[index].isEditLoading = false;
                            $scope.isEditLoading = false;
                            $scope.editClickError.setError(failure.StatusCode, failure.ErrorMessage);
                        });
                    }, function (failure) {
                        $scope.decisionMaps[index].isEditLoading = false;
                        $scope.isEditLoading = false;
                        $scope.editClickError.setError(failure.StatusCode, failure.ErrorMessage);
                    });
                };

                $scope.openPopover = function (index) {
                    var i = 0;
                    var curIndex = index;
                    for (i = 0; i < $scope.actionsPopoverFlags.length; i++) {
                        $scope.actionsPopoverFlags[i] = false;
                    }
                    $scope.actionsPopoverFlags[index] = true;
                    $("div[uib-popover-template-popup]").on("mouseleave", function () {
                        $scope.$apply(function () {
                            $scope.currentAction = '';
                            $scope.actionsPopoverFlags[curIndex] = false;
                        });
                    });
                };

                $scope.executeAction = function (action, confirmAction, popoverIndex, dmId) {
                    $scope.currentAction = '';
                    $scope.actionsPopoverFlags[popoverIndex] = false;
                    if (action === 'delete') {
                        if (confirmAction === 'yes') {
                            $scope.deleteDecisionMap(dmId);
                        }
                    }
                };

                $scope.closeAllActionMenus = function() {
                    var i = 0;
                    for (i = 0; i < $scope.actionsPopoverFlags.length; i++) {
                        $scope.actionsPopoverFlags[i] = false;
                    }
                };

                $scope.viewArchivedReports = function () {

                    if ($scope.archiveLinkText === 'View Archived') {
                        $scope.getDecisionMapSummaries(true);
                        $scope.archiveLinkText = 'Hide Archived';
                    } else {
                        $scope.archiveLinkText = 'View Archived';
                        $scope.getDecisionMapSummaries(false);
                        $scope.loadSummaryTable();                       
                    }                    
                };

                $scope.openReportRenameModal = function (decisionReport) {
                    $scope.reportToRename = angular.copy(decisionReport);
                    angular.element(document.querySelector('#renameModal')).modal();
                };

                $scope.renameResult = [];
                $scope.renameResult.error = errorDisplayerUtil.create();
                $scope.renameResult.success = errorDisplayerUtil.create();

                $scope.renameDecisionReport = function (newReportName, reportId) {

                        var renameCall = InsightsService.renameDecisionReport(reportId, newReportName);
                        renameCall.then(function (success) {
                            
                            var reportIdx = _.findIndex($scope.decisionMaps, { "id": reportId });
                            if (reportIdx > -1) {
                                $scope.decisionMaps[reportIdx].name = newReportName;
                            }
                            $scope.renameResult.success.setError(200, "The Decision Report was successfully renamed");
                            $timeout(function () {
                                angular.element(document.querySelector('#renameModal')).modal('hide');
                                $scope.renameResult.success.reset();
                            }, 3000);

                        }, function (failure) {
                            $scope.renameResult.error.setError(failure.StatusCode, failure.ErrorMessage);
                            $timeout(function () {
                                $scope.renameResult.error.reset();
                            }, 3000);
                        });
                };
            }
        ]
    };
}
]);