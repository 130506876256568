(function() {
    app.directive('baiCourseActionButton', [
        function() {
            return {
                restrict: 'E',
                scope: {
                    assignmentPromises: '=',
                    productId: '='
                },
                templateUrl: 'App/templates/components/baiCourseActionButton.html',
                controller: 'actionButtonController'
            };
        }
    ]);
})();