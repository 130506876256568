app.factory('loginBackgroundUtil', [function () {
    function parseColor(input) {
        // Takes an 'rgba(1,2,3,4)' string. (From background-color in css stylesheet.)
        // Returns [1,2,3,4] in [r,g,b,a] format
        // @TODO If the users decide to use other ways of specifying colors
        // For instance #00ff00, implement that here.
        var values = input.split("(")[1].split(")")[0].split(",");
        function formatIntegers(element, index, array) {
            array[index] = parseInt(element);
        }
        values.forEach(formatIntegers);
        return values;
    }

    function blendColorToContext(canvas, rgbaColorArray) {
        // Write the colors to the canvas
        // From: http://albertogasparin.it/articles/2011/05/html5-multiply-filter-canvas/
        // Takes a canvas
        // An rgbaColorArray in the form of [r,g,b,a]
        // And uses the multiply filter to blend the color to the canvas
        
        var ctx = canvas.getContext("2d");

        var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var pix = imgData.data;

        function multiply(topValue, bottomValue){
              return topValue * bottomValue / 255;
        }

        // Loop over each pixel and change the color.
        var i = 0;
        var n = 0;

        for (i = 0, n = pix.length; i < n; i += 4) {
            pix[i] = multiply(rgbaColorArray[0], pix[i]); // red
            pix[i+1] = multiply(rgbaColorArray[1], pix[i+1]); // green
            pix[i+2] = multiply(rgbaColorArray[2], pix[i+2]); // blue
            // pix[i+3] is alpha channel (ignored)
        }
        // Draw the result on the canvas
        ctx.putImageData(imgData, 0, 0);
    }

    function drawBackground() {
        // Hide original portal login background image
        // Show canvas background
        // Load original portal login background image data
        // Put original portal login background image onto canvas
        // Get background color of .portalLoginFormOverlay1
        // Overlay background color onto canvas
        // ???
        // Profit!
        
        // GO!
        
        // Enable the canvas
        $('.portalLoginBackground,.portalLoginFormOverlay1').hide();
        $('#portalLoginBackgroundCanvas').show();
        var canvas = document.getElementById("portalLoginBackgroundCanvas");
        canvas.width = $(window).width();
        canvas.height = $(window).height(); 

        // Get a canvas context
        var ctx = canvas.getContext("2d");

        // Draw the image to the canvas
        var image_uri = $('.portalLoginBackground').css('background-image');
        image_uri = image_uri.replace('url("','');
        image_uri = image_uri.replace('")','');

        var background_image = new Image();

        // @TODO Turn this into promises.
        background_image.onload = function() {
            // Draw image differently depending on window height
            if (background_image.width < canvas.width || 
                background_image.height < canvas.height) {
                ctx.drawImage(background_image, 0, 0, canvas.width, canvas.height);
            } else {
                ctx.drawImage(background_image, 0, 0);
            }

            // Get the overlay color and draw to screen
            var background_color = $('.portalLoginFormOverlay1').css('background-color');
            var multiplyColor = parseColor(background_color);
            blendColorToContext(canvas, multiplyColor);
        };

        background_image.src = image_uri;
    }

    return {
        'drawBackground': drawBackground
    };
}]);
