app.service('CssService', ['$http', '$q', function ($http, $q) {
    var _this = this;

    _this.response = null;

    _this.currentcss = null;
	
	_this.RGBtoGrey = function (color) {
        // See http://stackoverflow.com/a/689547/761726
        // For how this works.
		/* function which takes a color in CSS RGB format and returns its
		grescale value on a scale of 0-255 */
		var Y,
		R,
		G,
		B;
		/*regex to check that input is of correct CSS RGB form "#0a0b0c" or "#0ab"*/
		var re = /^#([0-9A-F]{6}|[0-9A-F]{3})$/;
		if (typeof color !== "string") {
			return undefined;
		}
		color = color.trim().toUpperCase();

		/* check that the string is a CSS RGB color */
		if (!re.test(color)) {
			return undefined;
		}

		/* if we get a shorthand 4-character color code
		replicate the values to make it a standard 6-character */
		if (color.length === 4) {
			color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
		}

		/*break string into components and calculate greyscale value */
		R = parseInt(color.substring(1, 3), 16);
		G = parseInt(color.substring(3, 5), 16);
		B = parseInt(color.substring(5, 7), 16);

		//console.log(R,G,B);

		/* calculate greyscale value */
		Y = 0.2126 * R + 0.7152 * G + 0.0722 * B;

		return Math.round(Y, 0);
	};

    // Return true if color is too light
    _this.isColorTooLight = function(color) {
        var Y = _this.RGBtoGrey(color);
        // *** IS COLOR TOO LIGHT ***
        if (Y >= 255 * 0.85) {
            return true;
        }
        return false;
    };

	_this.shouldTextBeLight = function (color) {
		/* based on input color, return true
		if the input color is so light that
		white text would be unreadable */

		var Y = _this.RGBtoGrey(color);

		/* use dark grey as threshold */
		if (Y <= 255 * 0.3) {
			return true;
		} else {
			return false;
		}
	};

	_this.extractColorFromCSS = function (css) {
		/* extract the portalBackground color from CSS returned via API
		this regex is very specific to the CSS being returned from current API */
		var re = /\.portalBackground\s*\{\s*background-color:\s*(#[a-fA-F0-9]{6}|#[a-fA-F0-9]{3})!important/;
		var res;

		//console.log(css.test(re));
		res = re.exec(css);

		/* return the match of the first named capture in parenthesis
		after testing that result indicates something was found by
		regex */
		if (res && typeof res === 'object' && typeof res[1] === 'string') {
			return res[1];
		} else {
			return null;
		}
	};

	_this.getAlternateColor = function (color) {
		/* return an alternate darker color
		could be modified to be some function to darken color passed in
		or similar in the future. */

		/* right now just return 50% grey */
        return '#808080';
	};

	_this.handleContrastProblems = function (css) {
		var color = _this.extractColorFromCSS(css);

		var adminTabSelectedColor = color ? " .admin-tab-selected-color { color: " + color + " !important; > svg { stroke: " + color + " !important; fill: " + color + " !important; } }" : " .admin-tab-selected-color { color: #1EABE3 !important; > svg { stroke: #1EABE3 !important; fill: #1EABE3 !important; } }";
		css += adminTabSelectedColor;

        // couldn't find the color so just pass CSS back through
		if (!color) {
			return css;
		}

        // Y is the luminosity value
        // See http://stackoverflow.com/a/689547/761726
        var Y = _this.RGBtoGrey(color);
        // *** IS COLOR TOO LIGHT ***
        // If the color is too light, change the buttons and 
	    // calendar selected box and date highlight colors
	    // and radio / checkbox colors to an alternate color
		if (Y >= 255 * 0.85) {
		    css += "html body button.btn-default { background-color: " + _this.getAlternateColor(color) + " !important; }";
		    css += "html body a.btn-default { background-color: " + _this.getAlternateColor(color) + "!important; }";
		    css += "html body button.md-primary { background-color: " + _this.getAlternateColor(color) + "!important; }";
            css += "html body #calendarMonthView .selected { background-color: " + _this.getAlternateColor(color) + " !important; }";
            css += ".checkboxHolder input[type='checkbox']:checked + label::after { color: " + _this.getAlternateColor(color) + " !important; }";
            css += ".radio input[type='radio']:checked + label::after { background-color: " + _this.getAlternateColor(color) + "!important; }";
		    css += ".md-calendar-date.md-calendar-date-today { color: " + _this.getAlternateColor(color) + "!important; }";
		    css += ".md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator { background-color: " + color + " !important; }";
		    css += ".insightsPortalTooLightFontColor { color: black !important; }";
		    css += ".insightsPortalTooLightFontColor:hover { color: black !important; }";
		    css += ".tipsImage { background-image:url('/lms-web/App/images/icons/lightbulb_black.svg') !important;}";
		    css += ".stepActiveText { color: " + _this.getAlternateColor(color) + "!important; }";
		    css += ".stepActiveText:hover { color: " + _this.getAlternateColor(color) + "!important; }";
		    css += "#insightsHeader ul.glommer .active { background:" + _this.getAlternateColor(color) + "!important; }";
		    css += "#insightsHeader ul.glommer li.active.portalBackground::before  { background:" + _this.getAlternateColor(color) + "!important; }";
		    css += ".portalCheck input[type='checkbox']:checked + label::after {border-color: " + _this.getAlternateColor(color) + "!important; }";
		    css += "ul.bai-tab-nav li.active>a { color: black !important; }";
			css += "bai-catalog-header button.catalog-button.btn-div { background-color: " + _this.getAlternateColor(color) + " !important; }";
			css += "bai-catalog-header-lms button.catalog-button.btn-div { background-color: " + _this.getAlternateColor(color) + " !important; }";
			css += adminTabSvgColorAsString;
            return css;
        }

        // Calendar text should be bright if the color is too dark.
        // The text on the calendar is dark by default.
        if (Y <= 255 * 0.45) {
            css += '.selected .portalLightText { color: white !important; }';
            css += ".md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator { background-color: " + color + " !important; color: white !important;}";
            return css;
        }

		// no fixes needed, return original CSS
        return css;
	};


    _this.fetchByUserId = function (query) {
        if(_this.response !== null) {
			return $q(function(resolve) {
				resolve(_this.response);
			});
        }

        var req = {
            method: 'GET',
            url: 'api/account/customcss/user/' + query,
            headers: {
                'Content-type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            /* apply contrast fixes to css response */
			_this.response = _this.handleContrastProblems(success.data);
			_this.currentcss = success.data;
           return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.fetchByPortalId = function (portalId) {
        if (_this.response !== null) {
            return $q(function (resolve) {
                resolve(_this.response);
            });
        }

        var req = {
            method: 'GET',
            url: 'api/account/customcss/company/' + portalId,
            headers: {
                'Content-type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            /* apply contrast fixes to css response */
            _this.response = _this.handleContrastProblems(success.data);
            _this.currentcss = success.data;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };


	/**
	 * This method is required to get rid of any existing results. Reason is if you switch between portals,
	 * which who would do that, you will get cached data. Homey don't play, tho.
	 */
	_this.purgeResults = function() {
		_this.response = null;
	};
}]);
