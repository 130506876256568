app.service('MessagesService', ['$rootScope', '$http', '$q', function($rootScope, $http, $q) {
    var _this = this;

    _this.response = null;
    _this.responseComplete = false;
    _this.request = '';

    _this.dateOrged = {};
    _this.typeOrged = {};

    _this.fetch = function() {
        if(typeof _this.response !== 'undefined' && _this.responseComplete === true) {
            return $q(function(resolve) {
                _this.format();

                resolve(_this.data);
            });
        }

        var req = {
            method: 'GET',
            url: 'api/message/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = success.data;

            $rootScope.$broadcast('messages:model:success', success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.count = function() {
        return _this.response.length;
    };

    _this.setRequest = function(request) {
        _this.request = request;
    };

    _this.getRequest = function() {
        return _this.request;
    };

    _this.getResponse = function() {
        return _this.response;
    };

    //This is the return value
    /*
    Object {organization: Object, system: Object}
    organization:Object label:"organization"
    messages:Array[1]0:Object
    description:"WELCOME APPLE STAFF MEMBERS!
    ↵
    ↵If you have any questions or concerns, please contact the Training Department."
    endDate:null
    id:0
    name:""
    startDate:null
    type:"organization"

    */
    _this.orderByType = function() {
        if(Object.keys(_this.typeOrged).length !== 0) {
            return _this.typeOrged;
        }

        var messages = _this.response;
        var types = ['organization', 'system'];

        for(var i in messages) {
            for(var node in messages[i]) {
                if (typeof _this.typeOrged[node] === 'undefined') {
                    if (jQuery.inArray(messages[i].type, types) > -1) {
                        _this.typeOrged[messages[i].type] = {
                            label: messages[i].type,
                            messages: []
                        };

                        _this.typeOrged[messages[i].type].messages.push(messages[i]);

                        break;
                    }
                }
            }
        }

        return _this.typeOrged;
    };

    _this.orderByDate = function() {
        var messages = _this.response.data;

        var ref = moment();
        var today = ref.clone().startOf('day');
        var yesterday = ref.clone().subtract(1, 'days').startOf('day');

        var date = null;
        var label = '';

        for(var i in messages) {
            date = moment.unix(messages[i].sent);

            if(date.isSame(yesterday, 'd')) {
                if(typeof _this.dateOrged.Yesterday === 'undefined') {
                    _this.dateOrged.Yesterday = {
                        label: 'Yesterday',
                        messages: []
                    };
                }

                _this.dateOrged.Yesterday.messages.push(messages[i]);
            } else {
                label = date.format('MMMM Do, YYYY');
                if(typeof _this.dateOrged[label] === 'undefined') {
                    _this.dateOrged[label] = {
                        label: label,
                        messages: []
                    };
                }

                _this.dateOrged[label].messages.push(messages[i]);
            }
        }

        return _this.dateOrged;
    };
}]);
