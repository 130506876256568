app.service('ProxyService', ['$http', '$cookies', 'dateUtil', function ($http, $cookies, dateUtil) {
    var _this = this;
    _this.response = null;
    _this.roleObj = null;
    _this.user = null;
    _this.originalResponse = null;
    _this.singleResponse = null;
    _this.userApplicationType = null;
    _this.auth = {
        currentRole: null,
        isLoggedIn: false,
        roles: null,
        authedUser: null,
        isTopLevel: null
    };


    _this.view = [{
        'name': 'Team Management',
        'value': 0
    },
    {
        'name': 'Reports Desk',
        'value': 1
    }];

    /**
     * Format the response; mainly to format all dates to UI.
     */
    _this.format = function (response) {
        var dates = ['endDate', 'startDate'];

        var current = null;
        var node;

        if (response.length > 0) {
            for (var rule in response) {
                current = response[rule];

                for (node in current) {
                    if (typeof current[node] !== 'undefined' && current[node] !== null && $.inArray(node, dates) !== -1) {
                        current[node] = dateUtil.formatDate(current[node]);
                    }
                }
            }
        } else {
            for (node in response) {
                if (typeof response[node] !== 'undefined' && response[node] !== null && $.inArray(node, dates) !== -1) {
                    response[node] = dateUtil.formatDate(response[node]);
                }
            }
        }

        return response;
    };


    _this.createProxy = function (proxyData) {
        var req = {
            method: 'POST',
            url: 'api/proxy/create/',
            data: proxyData,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.editProxy = function (proxyData) {
        var req = {
            method: 'PUT',
            url: 'api/proxy/' + proxyData.iAudit_ImpersonationID,
            data: proxyData,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getSingle = function (id) {
        var req = {
            method: 'GET',
            url: 'api/proxy/' + id + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format([success.data])[0];

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getAllProxy = function () {
        var req = {
            method: 'GET',
            url: 'api/proxy/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getManagerList = function () {
        var req = {
            method: 'GET',
            url: 'api/proxy/managers/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return _this.format(success.data);
        }, function (failure) {
            throw failure;
        });
    };

    /* Sample advanced search struct
         * See the UsersService.advancedSearch for complete list of fields
         */
    /* Returns a list of user objects */
    _this.advancedSearch = function (advancedSearchStruct) {
        // See if the user entered anything in the date fields

        var req = {
            method: 'GET',
            url: 'api/proxy/search/' + advancedSearchStruct + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };


        return $http(req).then(function (success) {
            return _this.format(success.data);
        }, function (failure) {
            throw failure;
        });
    };

    _this.getUrlsForProxy = function () {

        var req = {
            method: 'GET',
            url: 'api/proxy/serach/userproxy/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format([success.data])[0];

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

}]);