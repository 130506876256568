(function () {
    app.controller('studentTilesController', ['$scope', '$filter', '$q', '$window', 'AssignmentsService', 'CoursesService', 'UsersService', studentTilesController]);

    function studentTilesController($scope, $filter, $q, $window, AssignmentsService, CoursesService, UsersService) {

        $scope.rowLength = 4;
        $scope.showingMore = false;

        $scope.isLoading = false;
        $scope.seeMoreText = "See More";
        $scope.seeMoreGlyphClass = "glyphicon-chevron-down";
        $scope.popoverFlags = [];

        if ($scope.list) {
            for (cp = 0; cp < $scope.list.length; cp++) {

                $scope.list[cp].launchButtonPromises = [];

                var deferredCareerPathPromise = $q.defer();
                deferredCareerPathPromise.resolve($scope.list[cp]);
                $scope.list[cp].launchButtonPromises.push(deferredCareerPathPromise.promise);

                var deferredCareerPathOfferingsPromise = $q.defer();
                var emptyCareerPathOfferings = []; // offerings will always be empty because schedule course button is not shown on tile
                deferredCareerPathOfferingsPromise.resolve(emptyCareerPathOfferings);
                $scope.list[cp].launchButtonPromises.push(deferredCareerPathOfferingsPromise.promise);

                if ($scope.list[cp].isManagerApprovalRequired === true && $scope.list[cp].careerPathStatus === "pendingApproval") {
                    $scope.list[cp].hideActionButton = true;
                } else {
                    $scope.list[cp].hideActionButton = false;
                }
            }

            $scope.list.sort(compare);
        }

        $scope.LaunchResource = function (assignmentId, type) {
            var spinner = jQuery(event.target).parent().find(".spinner");
            spinner.removeClass('ng-hide').css('display', 'content');
            var row = jQuery(event.target);
            row.attr('disabled', 'disabled');

            var enrollCoursePromise = CoursesService.getCourseUrl(assignmentId, type, UsersService.getId()); // Getting the URL will also enroll (create transcript) for the course.
            enrollCoursePromise.then(function (success) {
                var uri = null;

                if (typeof success !== 'undefined' && typeof success.uri !== 'undefined') {
                    uri = success.uri;
                } else if (typeof success !== 'undefined' && typeof success.contentLaunchUrl !== 'undefined') {
                    uri = success.contentLaunchUrl;
                } else {
                    uri = success;
                }

                spinner.addClass('ng-hide');
                row.removeAttr('disabled');

                var uriAfterOriginCheck = uri.startsWith("http") ? uri : addOriginInfo(uri);
                launchedCourse = window.open(uriAfterOriginCheck);
                var refreshTimer = setInterval(function () {
                    if (launchedCourse.closed) {
                        clearInterval(refreshTimer);
                        $window.location.reload();
                    }
                }, 500);
            });
        };

        function compare(a, b) {
            var comparison = 0;
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                comparison = 1;
            } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                comparison = -1;
            }
            return comparison;
        }

        function compareDate(a, b) { 
            var comparison = 0; 
            if (a.assignedOn.original < b.assignedOn.original) { 
                comparison = -1; 
            } 
            else if (a.assignedOn.original > b.assignedOn.original) { 
                comparison = 1; 
            } 
            return comparison; 
        }
        $scope.isDuplicated = function (index) {
            for (j = 0; j < $scope.assignments.length; j++) 
            { 
                    if ($scope.assignments[j].assignmentCourseId !== $scope.assignments[index].assignmentCourseId && $scope.assignments[j].id === $scope.assignments[index].id) {
                        if ($scope.assignments[j].assignmentCourseId === -1) {
                            return true;
                        }
                        else {
                            $scope.assignments[j].hasDuplicate = true;
                            $scope.assignments[index].hasDuplicate = false;
                            $scope.assignmentsToRemove.push(j);
                        }
                    }               
            }
        };
 
        if ($scope.assignments) {
            $scope.assignmentsToRemove = [];
            $scope.orderAssignmentsBy = $scope.courseSectionName === "myDocuments" ? '-assignedOn.original' : 'dueDate.original';
            if ($scope.courseSectionName === "myDocuments") {
                $scope.assignments.sort(compareDate);
            }

            var i = 0;

            for (i = 0; i < $scope.assignments.length; i++) {
                $scope.assignments[i].launchButtonPromises = [];

                var deferredCoursePromise = $q.defer();
                deferredCoursePromise.resolve($scope.assignments[i]);
                $scope.assignments[i].launchButtonPromises.push(deferredCoursePromise.promise);

                var deferredOfferingsPromise = $q.defer();
                var emptyOfferings = []; // offerings will always be empty because schedule course button is not shown on tile
                deferredOfferingsPromise.resolve(emptyOfferings);
                $scope.assignments[i].launchButtonPromises.push(deferredOfferingsPromise.promise);

                $scope.assignments[i].hideActionButton = $scope.assignments[i].type === "ilt" || $scope.assignments[i].type === "task";

                if ($scope.assignments[i].name.length > 43 && !$scope.assignments[i].name.substring(0, 43).includes(" ")) {
                    $scope.assignments[i].displayName = $scope.assignments[i].name.substring(0, 43) + "...";
                }
                else if ($scope.assignments[i].name.length > 70) {
                    $scope.assignments[i].displayName = $scope.assignments[i].name.substring(0, 70) + "...";
                } else {
                    $scope.assignments[i].displayName = $scope.assignments[i].name;
                }

                $scope.assignments[i].dueDateStyle = "duedate";
                $scope.assignments[i].showHeart = $scope.courseSectionName === "electives";
                $scope.assignments[i].catalogID = $scope.assignments[i].id;
                
                if ($scope.assignments[i].type === "courseGroup" || $scope.assignments[i].type === "courseGroupCurricula" || $scope.assignments[i].type === "courseGroupCertification") {
                    $scope.assignments[i].showHeart = false;
                    $scope.assignments[i].showDocumentHeart = false;
                }
                if ($scope.assignments[i].dueDate !== undefined && $scope.assignments[i].dueDate !== null) {
                    if ($scope.assignments[i].dueDate.overDue === true) {
                        $scope.assignments[i].dueDateStyle = "duedate-overdue";
                    } else if ($scope.assignments[i].dueDate.within24Hours === true) {
                        $scope.assignments[i].dueDateStyle = "duedate-duesoon";
                    }
                }

                if ($scope.courseSectionName === "myDocuments") {
                    $scope.assignments[i].showDocumentHeart = true;
                    $scope.assignments[i].productType = 'Document';
                    $scope.assignments[i].catalogID = $scope.assignments[i].parentBAICourseId;
                    $scope.assignments[i].myDocumentCourse = {
                        CourseId: $scope.assignments[i].parentBAICourseId,
                        CourseType: 'Document',
                        isInMyDocuments: $scope.assignments[i].isInMyDocuments
                    };
                    if (typeof $scope.assignments[i].hasDuplicate === 'undefined') {
                        $scope.isDuplicated(i);
                    }
                } else {
                    $scope.assignments[i].playlistCourse = {
                        CourseId: $scope.assignments[i].id,
                        CourseType: 'Course',
                        isInPlaylist: $scope.assignments[i].isInPlaylist
                    };
                }
                $scope.popoverFlags.push(false);

            }
            if ($scope.assignmentsToRemove.length > 0) {
                $scope.assignmentsToRemove.sort(function (a, b) { return b - a; });
                for (var j = 0; j < $scope.assignmentsToRemove.length; j++) {
                    $scope.assignments.splice($scope.assignmentsToRemove[j], 1);
                }
            }
            if ($scope.assignments.containsNMLSCourse) {
                var user = UsersService.fetch(UsersService.getId());
                user.then(function (success) {
                    //Set the scoped NMLS values
                    $scope.isNMLSGroup = (success.isNMLSSafeHarbor || success.isNMLSReporting);
                    $scope.isNMLSProvisioned = success.isNMLSProvisioned;

                    $scope.isLoading = false; // We're no longer loading!
                    $scope.responseComplete = true;

                }, function (failure) {
                    //If user get fails, let the page load. The course will have a normal launch link
                    //but the content will still prompt the user to BioSig
                    $scope.isLoading = false; // We're no longer loading!
                    $scope.responseComplete = true;
                });
            }
        }

        $scope.closeAllPopovers = function () {
            var i = 0;
            for (i = 0; i < $scope.popoverFlags.length; i++) {
                $scope.popoverFlags[i] = false;
            }
        };

        $scope.openPopover = function (index) {
            $scope.closeAllPopovers();
            if ($scope.assignments[index].dueDate !== undefined && $scope.assignments[index].dueDate !== null) {
                if ($scope.assignments[index].dueDate.overDue === true || $scope.assignments[index].dueDate.within24Hours === true) {
                    $scope.popoverFlags[index] = true;
                }
            }
        };


        $scope.seeMoreClick = function () {
            if (!$scope.showingMore) {
                $scope.seeMoreText = "See Less";
                $scope.seeMoreGlyphClass = "glyphicon-chevron-up";
            }
            else {
                $scope.seeMoreText = "See More";
                $scope.seeMoreGlyphClass = "glyphicon-chevron-down";
            }
            $scope.showingMore = !$scope.showingMore;
        };


        $scope.viewMoreClick = function (input, param) {
            $scope.isResources = false;
            $scope.inGroup = false;
            var relevantAssignment = null;
            if (param.includes("courseGroup")) {
                $scope.inGroup = true;
            }
            if (param.includes("resources")) {
                $scope.isResources = true;
            }
            var origin = $scope.courseSectionName;
            $scope.assignment = null;
            if ($scope.inGroup) {
                $scope.singleGroupIsLoading = true;
                relevantAssignment = $scope.courseGroupCourses[input];
                origin = ($scope.courseSectionName || "") + "courseGroup";
            }
            else {
                $scope.singleIsLoading = true;
                $scope.courseGroup = undefined;
                relevantAssignment = $scope.assignments[input];
            }
            $scope.showSlideButton = false;
            matchedClose = false;
            if (input === -1) {
                matchedClose = true;
                if ($scope.inGroup) {
                    $scope.singleGroupIsLoading = false;
                }
                else {
                    $scope.singleIsLoading = false;
                }
            }
            //Close any existing singlecourses and change the corresponding text to view more
            detaildiv = jQuery("#" + origin + "singlecourse")[0];
            var regexid = RegExp('[0-9]+', 'g');
            var current = 0;
            var currentDetails = jQuery("." + origin + "openDetails")[0];
            var currentResources = jQuery("." + origin + "resourcesopenDetails")[0];
            //line will need changing once merged
            if (currentDetails) {
                current = regexid.exec(currentDetails.id);
                if (current[0] === "" + input && !$scope.isResources) {
                    matchedClose = true;
                    $scope.singleGroupIsLoading = false;
                    $scope.singleIsLoading = false;
                }
                jQuery("." + origin + "View" + current)[0].innerHTML = "Details";
                jQuery("." + origin + "openDetails")[0].classList.remove(origin + "openDetails");
                jQuery("#" + origin + "singlecourse").slideUp("fast");
            }
            else if (currentResources) {
                current = regexid.exec(currentResources.id);
                if (current[0] === "" + input && $scope.isResources) {
                    matchedClose = true;
                    $scope.singleGroupIsLoading = false;
                    $scope.singleIsLoading = false;
                }
                jQuery("." + origin + "resources" + current)[0].innerHTML = "Resources";
                jQuery("." + origin + "resourcesopenDetails")[0].classList.remove(origin + "resourcesopenDetails");
                jQuery("#" + origin + "resourcessinglecourse").slideUp("fast");
            }

            var courseDiv = document.getElementById(origin + "tile" + input);
            if (matchedClose === false) {
                if ($scope.inGroup) {
                    position = input;
                }
                else {
                    if (($scope.assignments.length) % 4 !== 0 && (input + 1 - 4 * Math.floor($scope.assignments.length / 4) > 0)) {
                        position = $scope.assignments.length - 1;
                    }
                    else {
                        position = (Math.ceil((input + 1) / 4) * 4) - 1;
                    }
                }
                var topdiv = document.getElementById(origin + "detailsTopContainer");
                jQuery("#" + origin + "tile" + position).after(topdiv);

                if ($scope.isResources) {
                    courseDiv.classList.add(origin + "resourcesopenDetails");
                    jQuery("." + origin + "resources" + input)[0].innerHTML = "Hide Resources";
                    $scope.resourcesSlideDown((origin + "resources"), relevantAssignment);
                }
                else {
                    courseDiv.classList.add(origin + "openDetails");
                    jQuery("." + origin + "View" + input)[0].innerHTML = "View Less";

                    if (!$scope.inGroup) {
                        $scope.courseGroupCourses = undefined;
                    }

                    switch (relevantAssignment.type) {
                        case "wbt":
                        case "survey":
                        case "assessment":
                        case "evaluation":
                        case "mp3":
                        case "mP3":
                        case "mp4":
                        case "mP4":
                        case "pdf":
                        case "word":
                        case "excel":
                        case "url":
                        case "other":
                            $scope.fillBasicSlideDown(origin, input, relevantAssignment);
                            break;
                        case "ilt":
                            $scope.fillILTSlideDown(origin, input);
                            break;
                        case "task":
                            $scope.fillTaskSlideDown(origin, input, relevantAssignment);
                            break;
                        case "courseGroup":
                            $scope.fillCourseGroupSlideDown(origin, input);
                            break;
                        case "courseGroupCertification":
                            $scope.fillCourseGroupSlideDown(origin, input);
                            break;
                        case "courseGroupCurricula":
                            $scope.fillCourseGroupSlideDown(origin, input);
                            break;
                        default:
                            jQuery("#" + origin + "InfoSection")[0].innerHTML = "This Section:";
                            jQuery("#" + origin + "DetailsSection")[0].innerHTML = "Under Construction";
                            window.setTimeout(function () {
                                $scope.openSlideDown(origin);
                            }, 500);
                            $scope.singleGroupIsLoading = false;
                            $scope.singleIsLoading = false;
                            break;
                    }
                }
            }
            else {
                var outerdiv = document.getElementById(origin + "detailsTopContainer");
                jQuery("#" + origin + "container").after(outerdiv);
            }
        };

        $scope.openSlideDown = function (origin) {
            jQuery("#" + origin + "singlecourse").slideDown();
        };

        $scope.fillBasicSlideDown = function (origin, index, assignment) {
            $scope.assignment = assignment;
            jQuery("#" + origin + "InfoSection")[0].innerHTML = "<b>Content Code: </b>" + assignment.code + " | <b>Content Type: </b>" + $filter("assignmentAssetTypeName")(assignment.type) + " | <b>Source:</b> " + assignment.source + " | <b>Expected Duration: </b> " + $filter("duration")(assignment.duration, assignment.source);
            jQuery("#" + origin + "DetailsSection")[0].innerHTML = "<b>" + assignment.name + "</b><br>" + (assignment.description || "");
            window.setTimeout(function () {
                $scope.openSlideDown(origin);
            }, 500);
            $scope.singleGroupIsLoading = false;
            $scope.singleIsLoading = false;
        };
        $scope.fillILTSlideDown = function (origin, index) {
            //get assignment
            var assignment = null;
            var courseId = 0;
            var courseType = 'ilt';
            if ($scope.inGroup) {
                assignment = $scope.courseGroupCourses[index];
                courseId = $scope.courseGroupCourses[index].courseId || $scope.courseGroupCourses[index].id;
                courseType = $scope.courseGroupCourses[index].type;
            }
            else {
                assignment = $scope.assignments[index];
                courseId = $scope.assignments[index].courseId || $scope.assignments[index].id;
                courseType = $scope.assignments[index].type;
            }
            $scope.iltNoOfferingsMessage = false;

            /**
             * This was changed to get single course, not get single assignment. This is not correct.
             * This method must get a single assignment, to ensure proper retrieval of assignment based
             * information for the student.
             */
            // var singleCourse = CoursesService.getSingleCourse(courseId);
            var singleAssignment = AssignmentsService.fetchSingle(courseId, courseType);

            singleAssignment.then(
                   function (success) {
                       var propToCheck = "assignedOffering";
                       if (success[propToCheck] !== null && (assignment.status === "inProgress" || assignment.status === "successful" || assignment.status === "waitlisted")) {
                           success.isScheduled = false;
                           $scope.assignment = success;
                           if (origin === "courseGroup") {
                               $scope.assignment.source = $scope.careerPath.name;
                           }
                           jQuery("#" + origin + "InfoSection")[0].innerHTML = "<b>Content Code: </b>" + $scope.assignment.code + " | <b>Content Type: </b>" + $filter("assignmentAssetTypeName")($scope.assignment.type) + " | <b>Source:</b> " + $scope.assignment.source + " | <b>Expected Duration: </b> " + $filter("duration")($scope.assignment.duration, $scope.assignment.source);
                           jQuery("#" + origin + "DetailsSection")[0].innerHTML = "<b>" + $scope.assignment.name + "</b><br>";
                           //LMS-5878 when type is ilt and assignedOffering is not null: overwrite offerings for this course
                           if (success.type === 'ilt' && success.assignedOffering !== null) {
                               var offeringsPromise = CoursesService.getSingleCourseOfferings(courseId);
                               offeringsPromise.then(function (success) {
                                   $scope.assignment.offerings = success;
                                   if ($scope.inGroup) {
                                       $scope.assignment.groupOfferingsDisplay = true;
                                   } else {
                                       $scope.assignment.offeringsDisplay = true;
                                   }
                                   //ITERATE THROUGH EXISTING OFFERINGS
                                   $scope.singleGroupIsLoading = false;
                                   $scope.singleIsLoading = false;
                                   window.setTimeout(function () {
                                       $scope.openSlideDown(origin);
                                   }, 500);
                               }, function (failure) {
                                   $scope.apiError = true;
                                   $scope.singleGroupIsLoading = false;
                                   $scope.singleIsLoading = false;
                               });
                           }
                       } else {
                           $scope.getOfferings(origin, index, assignment);
                       }
                   },
            function (failure) { // Failed to find assignment
                $scope.apiError = true;
                $scope.singleGroupIsLoading = false;
                $scope.singleIsLoading = false;
            });
        };

        $scope.getOfferings = function (origin, index, assignment) {
            $scope.apiError = false;
            if ($scope.inGroup) {
                $scope.singleGroupIsLoading = true;
            }
            else {
                $scope.singleIsLoading = true;
            }
            var launchPromises = assignment.launchButtonPromises;
            var courseId = typeof assignment.courseId !== 'undefined' && assignment.courseId !== null ? assignment.courseId : assignment.id;
            var isInCourseGroup = assignment.isInCourseGroup;
            $scope.assignment = assignment;
                $scope.slideType = "ilt";
            $scope.showSlideButton = true;
            if (origin === "courseGroup") {
                $scope.assignment.source = $scope.careerPath.name;
            }
                var offeringsPromise = CoursesService.getSingleCourseOfferings(courseId);
                return offeringsPromise.then(function (success) {
                    $scope.assignment.offerings = success;
                    launchPromises[1].$$state.value = success;
                    $scope.assignment.launchButtonPromises = launchPromises;
                    if ($scope.inGroup) {
                        $scope.assignment.groupSecondaryButton = true;
                    } else {
                        $scope.assignment.secondaryButton = true;
                    }
                    $scope.assignment.offeringId = null;
                    $scope.assignment.assignedOffering = null;
                    $scope.iltNoOfferingsMessage = false;
                    $scope.focusedID = $scope.assignment.id;
                    var novalidofferings = true;
                    var currentDate = new Date();
                    for (i = 0; i < success.length; i++) {
                        for (j = 0; j < success[i].sessions.length; j++) {
                            if (success[i].sessions[j].endDateTime.jsDate >= currentDate) {
                                novalidofferings = false;
                            }
                        }
                    }
                    
                    jQuery("#" + origin + "InfoSection")[0].innerHTML = "<b>Content Code: </b>" + $scope.assignment.code + " | <b>Content Type: </b>" + $filter("assignmentAssetTypeName")($scope.assignment.type) + " | <b>Source:</b> " + $scope.assignment.source + " | <b>Expected Duration: </b> " + $filter("duration")($scope.assignment.duration, $scope.assignment.source);
                    jQuery("#" + origin + "DetailsSection")[0].innerHTML = "<b>" + $scope.assignment.name + "</b><br> " + ($scope.assignment.description || "");
                    if (novalidofferings) {
                        jQuery("#" + origin + "DetailsSection")[0].innerHTML = jQuery("#" + origin + "DetailsSection")[0].innerHTML + "There are no available offerings.";
                        if ($scope.inGroup) {
                            $scope.assignment.groupSecondaryButton = false;
                        } else { $scope.assignment.secondaryButton = false; }
                    }
                    $scope.singleGroupIsLoading = false;
                    $scope.singleIsLoading = false;
                    window.setTimeout(function () {
                        $scope.openSlideDown(origin);
                    }, 500);
                }, function (failure) {
                    $scope.singleGroupIsLoading = false;
                    $scope.singleIsLoading = false;

                    if (failure.StatusCode === 404) {
                        //If the offerings get returns a 404, there is no error, there are just no offerings available
                        $scope.iltNoOfferingsMessage = true;
                    } else {
                        $scope.apiError = true;
                    }

                    // scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
        };


        $scope.fillTaskSlideDown = function (origin, index, assignment) {
            jQuery("#" + origin + "InfoSection")[0].innerHTML = "<b>Content Code: </b>" + assignment.code + " | <b>Content Type: </b>" + $filter("assignmentAssetTypeName")(assignment.type) + " | <b>Source:</b> " + assignment.source + " | <b>Expected Duration: </b> " + $filter("duration")(assignment.duration, assignment.source);
            jQuery("#" + origin + "DetailsSection")[0].innerHTML = "<b>" + assignment.name + "</b><br> " + (assignment.description || "");
            $scope.assignment = assignment;
            if ($scope.inGroup) {
                if (assignment.status !== "successful") {
                    $scope.assignment.groupSecondaryButton = true;
                    $scope.assignment.hideActionButton = true;
                }
                $scope.singleGroupIsLoading = false;
            } else { $scope.assignment.secondaryButton = true; }
            $scope.singleIsLoading = false;
            window.setTimeout(function () {
                $scope.openSlideDown(origin);
            }, 500);
        };

        $scope.fillCourseGroupSlideDown = function (origin, index) {
            $scope.singleIsLoading = true;
            $scope.assignment = $scope.assignments[index];
            $scope.courseGroup = $scope.assignment;
            $scope.courseGroup.skillsCount = 0;
            $scope.courseGroup.achievedSkillsCount = 0;
            $scope.getCourseGroupData($scope.assignment, origin);
        };

        $scope.getCourseGroupData = function (assignment, origin) {
            $scope.apiError = false;
            $scope.singleIsLoading = true;

            var courseGroupID = assignment.courseGroupId || assignment.id;
            var courseType = assignment.type;
            var student = assignment.studentId;
            var courseGroupType = courseType;
            var courseGroupStatus = assignment.status;
            var courseGroupIsManagerApprovalRequired = assignment.isManagerApprovalRequired;
            var courseGroupIsRequired = assignment.isRequired;

            if (courseType === 'courseGroupCurricula') { courseGroupType = 'Curricula'; }
            else if (courseType === 'courseGroupCertification') { courseGroupType = 'Certification'; }

            var courseGroups = {};
            if (courseType === 'careerPath') {
                courseGroups = AssignmentsService.getCareerPathCourses(student, courseGroupID);
            } else {
                courseGroups = AssignmentsService.getCourseGroupCourses(student, courseType, courseGroupID);
            }

            courseGroups.then(
                function (success) {
                    success.isScheduled = false;
                    var courseGroupdata = success;
                    for (i = 0; i < courseGroupdata.length; i++) {
                        courseGroupdata[i].launchButtonPromises = [];
                        var deferredCoursePromise = $q.defer();
                        deferredCoursePromise.resolve(courseGroupdata[i]);
                        courseGroupdata[i].launchButtonPromises.push(deferredCoursePromise.promise);
                        var deferredOfferingsPromise = $q.defer();
                        var emptyOfferings = []; // offerings will always be empty because schedule course button is not shown on tile
                        deferredOfferingsPromise.resolve(emptyOfferings);
                        courseGroupdata[i].launchButtonPromises.push(deferredOfferingsPromise.promise);
                        if (courseGroupdata[i].type === "task" || courseGroupdata[i].type === "ilt") { courseGroupdata[i].hideActionButton = true; }

                        //If isInCourseGroup is not null, then it SHOULD be part of a course group / group curricula / group certification
                        if (courseGroupdata[i].isInCourseGroup !== null) {
                            //Remove the action button for each course if the elective is pending approval
                            if (((courseGroupStatus === "new" && courseGroupIsManagerApprovalRequired === true) || courseGroupStatus === "pendingApproval")) {
                                //Hide the action button, assign the individual courses in the course group the same status as the course group parent
                                courseGroupdata[i].hideActionButton = true;
                                courseGroupdata[i].status = courseGroupStatus;

                            }
                        }
                    }

                    if (courseType === 'careerPath') {
                        for (i = courseGroupdata.length - 1; i >= 0; --i) {
                            current = courseGroupdata[i];
                            current.skillsList = "";
                            if (current.skills !== null) {
                                for (s = 0; s < current.skills.length; s++) {
                                    if (s === 0) {
                                        current.skillsList = current.skills[s].name;
                                    } else {
                                        current.skillsList += '; ' + current.skills[s].name;
                                    }
                                }
                            }
                        }

                        var summary = CareerPathService.createSummary(assignment, courseGroupdata);
                        $scope.courseGroup.skillsCount = summary.skills.total;
                        $scope.courseGroup.achievedSkillsCount = summary.skills.completed;
                        $scope.courseGroup.totalCourses = summary.courses.total;
                        $scope.courseGroup.totalCompletedCourses = summary.courses.completed;
                    } else {
                        var completedCourses = 0;
                        //Get the amount of courseGroup courses before we take out successful records
                        $scope.courseGroup.totalCourses = courseGroupdata.length;
                        for (i = courseGroupdata.length - 1; i >= 0; --i) {
                            if (courseGroupdata[i].productType === "Document"){
                                courseGroupdata[i].catalogID = courseGroupdata[i].parentBAICourseId;
                            } else {
                                courseGroupdata[i].catalogID = courseGroupdata[i].id;                               
                            }
                            if (courseGroupdata[i].name.length > 70) {
                                courseGroupdata[i].displayName = courseGroupdata[i].name.substring(0, 70) + "...";
                            } else {
                                courseGroupdata[i].displayName = courseGroupdata[i].name;
                            }
                            current = courseGroupdata[i];
                            //Remove any successful results, student does not see successful courses
                            if (current.status === 'successful') {
                                courseGroupdata.splice(i, 1);
                                completedCourses++;
                            }
                        }
                        $scope.courseGroup.totalCompletedCourses = completedCourses;
                    }
                    $scope.courseGroupCourses = courseGroupdata;
                    setCourseGroupDetailsHtml(origin);
                    $scope.singleGroupIsLoading = false;
                    $scope.singleIsLoading = false;
                    window.setTimeout(function () {
                        $scope.openSlideDown(origin);
                    }, 500);
                }, function (failure) {
                    $scope.totals = false;
                    $scope.courseGroupCourses = [];
                    $scope.singleGroupIsLoading = false;
                    $scope.singleIsLoading = false;
                    if (failure.StatusCode !== 404) {
                        $scope.apiError = true;
                    } else {
                        $scope.courseGroup.totalCourses = 0;
                        $scope.courseGroup.totalCompletedCourses = 0;
                        setCourseGroupDetailsHtml(origin);
                        $scope.assignment.displayTypeName = $filter("assignmentAssetTypeName")($scope.assignment.type).toLowerCase();
                        window.setTimeout(function () {
                            $scope.openSlideDown(origin);
                        }, 500);
                    }
                }
            );
        };

        function setCourseGroupDetailsHtml (origin) {
            jQuery("#" + origin + "InfoSection")[0].innerHTML = "<b>Content Code: </b>" + $scope.assignment.code + " | <b>Content Group Type: </b>" + $filter("assignmentAssetTypeName")($scope.assignment.type) + " | <b>Source:</b> " + $scope.assignment.source + "| <b>Completions: </b>" + $scope.courseGroup.totalCompletedCourses + "/" + $scope.courseGroup.totalCourses + " | <b>Expected Duration: </b> " + $filter("duration")($scope.assignment.duration, $scope.assignment.source);
            jQuery("#" + origin + "DetailsSection")[0].innerHTML = "<b>" + $scope.assignment.name + "</b><br> " + ($scope.assignment.description || "");
        }

        $scope.resourcesSlideDown = function (origin, assignment) {
            var resources = assignment.courseAssets;
            for (var r in resources) {
                current = resources[r];
                if (current.fileFormat === 'pdf') { resources[r].fileFormatName = '.PDF'; }
                else if (current.fileFormat === 'excel') { resources[r].fileFormatName = '.XLS'; }
                else if (current.fileFormat === 'url') { resources[r].fileFormatName = 'URL'; }
                else if (current.fileFormat === 'word') { resources[r].fileFormatName = '.DOCX'; }
                else if (current.fileFormat === 'powerpoint') { resources[r].fileFormatName = '.PPT'; }
                else if (current.fileFormat === 'text') { resources[r].fileFormatName = '.TXT'; }
                else if (current.fileFormat === 'audio') { resources[r].fileFormatName = '.MP3'; }
                else if (current.fileFormat === 'video') { resources[r].fileFormatName = '.MP4'; }
                else if (current.fileFormat === 'picture') { resources[r].fileFormatName = '.PNG'; }
                else if (current.fileFormat === 'zip') { resources[r].fileFormatName = '.ZIP'; }
            }

            $scope.resources = resources;



            window.setTimeout(function () {
                $scope.openSlideDown(origin);
            }, 500);
            $scope.singleGroupIsLoading = false;
            $scope.singleIsLoading = false;
        };




        $scope.dropElectiveCourse = function (assignment, origin, $event) {
            $scope.apiActionError = false;
            $scope.apiActionLoader = true;
            $scope.beingDropped = true;

            var confirmDrop = confirm("Are you sure you want to drop?");

            if (confirmDrop) {
                var assignmentDrop = AssignmentsService.removeAssignment(assignment);

                assignmentDrop.then(function (success) {
                    $scope.apiActionLoader = false;

                    jQuery('#' + origin + 'singlecourse').slideUp(500, function () {
                        // Force a re-render of the page; can't see another way except to fetch data for now;
                        // couldn't get a refresh to work for whatever reason...
                        $scope.beingDropped = false;


                        window.location.reload();
                    });
                }, function (response) {
                    $scope.apiActionError = true;
                    $scope.apiErrorMessage = 'Sorry! There was an issue dropping your course. Please try again!';
                    $scope.apiActionLoader = false;
                    $scope.beingDropped = false;


                    jQuery('#transcriptAddError').slideDown(500, function () {
                        var _this = jQuery(this);

                        setTimeout(function () {
                            _this.slideUp(500);
                        }, 1500);
                    });
                });
            } else {
                $scope.apiActionLoader = false;
            }
        };
        $scope.deleteCourseGroup = function (courseGroup, $event) {

            var confirmDrop = confirm("Are you sure you want to drop?");
    
            if (confirmDrop) {
                
                var deleteCourseGroup = AssignmentsService.removeAssignment(courseGroup);
    
                deleteCourseGroup.then(function (success) {
                    $scope.apiActionLoader = false;
    
                        $scope.isLoading = true;
                        window.location.reload();
    
                },
                function (failure) {
                    alert("There was a problem deleting the Course Group");
                });
           }
        };

        $scope.viewMorePathClick = function (index, param) {

            $scope.apiError = false;
            $scope.courseGroupCourses = null;
            $scope.careerPath = [];
            
            var childPromises = [];
            var careerPathSummary = $scope.list[index];

            var currentOpen = jQuery(".openCareerPath")[0];
            var regexid = RegExp('[0-9]+', 'g');
            var matched = false;
            var topdiv = document.getElementById("detailsTopContainer");
            if (currentOpen) {
                var temp = regexid.exec(currentOpen.id);
                matched = (temp[0] === index.toString());
                currentOpen.innerText = "Show Course List";
                currentOpen.classList.remove("openCareerPath");
                jQuery("#singlecourse").slideUp("fast");
                topdiv.classList.remove("topLevel");
            }
            jQuery("#careerPath" + index).after(topdiv);
            if (matched) {
                $scope.IsLoading = false;
                return;
            }
            $scope.IsLoading = true;
            $scope.singleIsLoading = true;

            var careerPathAssignment = AssignmentsService.fetchSingle(careerPathSummary.careerPathCourseID, "careerPath");
            careerPathAssignment.then(function (success) { 
                $scope.careerPath = success;
                $scope.careerPath.childrenTotal = careerPathSummary.childrenTotal;
                var id = careerPathSummary.careerPathCourseID;
                
                if (!matched) {
                    if (careerPathSummary.childrenTotal > 0) {
                        var childPromise = AssignmentsService.getCareerPathCourses($scope.careerPath.studentId, id);
                        childPromises.push(childPromise);

                        $q.all(childPromises).then(function (success) {
                            topdiv.classList.add("topLevel");
                            $scope.courseGroupCourses = success[0];
                            for (i = 0; i < $scope.courseGroupCourses.length; i++) {
                                current = $scope.courseGroupCourses[i];
                                current.skillsList = "";
                                if (current.skills !== null) {
                                    for (s = 0; s < current.skills.length; s++) {
                                        if (s === 0) {
                                            current.skillsList = current.skills[s].name;
                                        } else {
                                            current.skillsList += '; ' + current.skills[s].name;
                                        }
                                    }
                                    $scope.courseGroupCourses[i] = current;
                                    $scope.courseGroupCourses[i].source = $scope.careerPath.name;
                                }

                                $scope.courseGroupCourses[i].launchButtonPromises = [];

                                var deferredCoursePromise = $q.defer();
                                deferredCoursePromise.resolve($scope.courseGroupCourses[i]);
                                $scope.courseGroupCourses[i].launchButtonPromises.push(deferredCoursePromise.promise);

                                var deferredOfferingsPromise = $q.defer();
                                var emptyOfferings = []; // offerings will always be empty because schedule course button is not shown on tile
                                deferredOfferingsPromise.resolve(emptyOfferings);
                                $scope.courseGroupCourses[i].launchButtonPromises.push(deferredOfferingsPromise.promise);

                                $scope.courseGroupCourses[i].hideActionButton = $scope.courseGroupCourses[i].type === "ilt" || $scope.courseGroupCourses[i].type === "courseGroupCertification" || $scope.courseGroupCourses[i].type === "courseGroup" || $scope.courseGroupCourses[i].type === "courseGroupCertification" || $scope.courseGroupCourses[i].type === "task";

                                //Individual Launch buttons for career path courses
                                if (careerPathSummary.careerPathStatus === "pendingApproval" || (careerPathSummary.careerPathStatus === "new" && careerPathSummary.isManagerApprovalRequired === true)) {
                                    $scope.courseGroupCourses[i].hideActionButton = true;
                                }

                                if ($scope.courseGroupCourses[i].name.length > 70) {
                                    $scope.courseGroupCourses[i].displayName = $scope.courseGroupCourses[i].name.substring(0, 70) + "...";
                                } else {
                                    $scope.courseGroupCourses[i].displayName = $scope.courseGroupCourses[i].name;
                                }

                                $scope.courseGroupCourses[i].dueDateStyle = "duedate";

                                if ($scope.courseGroupCourses[i].dueDate !== undefined && $scope.courseGroupCourses[i].dueDate !== null) {
                                    if ($scope.courseGroupCourses[i].dueDate.overDue === true) {
                                        $scope.courseGroupCourses[i].dueDateStyle = "duedate-overdue";
                                    } else if ($scope.courseGroupCourses[i].dueDate.within24Hours === true) {
                                        $scope.courseGroupCourses[i].dueDateStyle = "duedate-duesoon";
                                    }
                                }
                                $scope.popoverFlags.push(false);

                            }

                            //set link values
                            HandleCareerPathDropdown(careerPathSummary, index);

                        }, function (failure) {
                            $scope.apiError = true;
                            $scope.errorMessage = failure.ErrorMessage;
                            $scope.singleGroupIsLoading = false;
                            $scope.IsLoading = false;
                        });
                    }
                    else {
                        HandleCareerPathDropdown(careerPathSummary, index);
                    }
                }
            }, function (failure) {
                    //failure getting assignment details
                    $scope.errorMessage = failure.ErrorMessage;
                    $scope.apiError = true;
                    $scope.IsLoading = false;
                    $scope.singleIsLoading = false;
                    $scope.singleGroupIsLoading = false;
            });
        };

        function HandleCareerPathDropdown(careerPathSummary, index) {
            jQuery('#showList' + index)[0].classList.add("openCareerPath");
            jQuery('#showList' + index)[0].innerText = "Hide Course List";
            var order = "No";
            if (careerPathSummary.enforceOrder) { order = "Yes"; }
            jQuery("#InfoSection")[0].innerHTML = "<b>Course Completions: </b>" + careerPathSummary.childrenPassed + "/" + careerPathSummary.childrenTotal + " | <b>Skills Achieved: </b>" + careerPathSummary.skillCountPassed + "/" + careerPathSummary.skillCountTotal + " | <b>Source:</b> " + careerPathSummary.source + " | <b>Enforce Order: </b> " + order;
            jQuery("#DetailsSection")[0].innerHTML = "<b>" + careerPathSummary.name + "</b><br>" + (careerPathSummary.description || "");
            window.setTimeout(function () {
                $scope.openSlideDown("");
            }, 500);

            $scope.singleGroupIsLoading = false;
            $scope.singleIsLoading = false;
            $scope.IsLoading = false;
        }

        $scope.mapStatusLabel = function (status) {
            /* For mapping assignment status to a css class
            * The word new should be colored green for instance on the assignments page.
            * See https://projects.invisionapp.com/share/QS765VMF3#/screens/157758405/comments
            */
            var statuses = {
                'completed': 'Completed',
                'inProgress': 'In Progress',
                'new': 'New',
                'pendingApproval': 'Pending Approval',
                'successful': 'Successful',
                'unsuccessful': 'Unsuccessful',
                'unknown': ''
            };

            if (status in statuses) {
                return statuses[status];
            }

            return status;
        };

        $scope.removeFromPlaylistTiles = function (id, isInPlaylist) {
            $scope.viewMoreClick(-1, '');
            if (isInPlaylist === false) {
                var index = _.findIndex($scope.assignments, { id: id });
                if ($scope.assignments[index].assignmentCourseId === -1) {
                    $scope.assignments.splice(index, 1);
                }
            }
            
        };

        $scope.removeFromMyDocumentsTiles = function (id, isInMyDocuments) {
            $scope.viewMoreClick(-1, '');
            var index = _.findIndex($scope.assignments, { parentBAICourseId: id });
            if (isInMyDocuments === true) {
                //right now this only works for BAI docs using the parent course ID
                $scope.assignments[index].isDroppable = true;
                if ($scope.assignments[index].assignmentCourseId === -1 && !$scope.assignments[index].hasDuplicate) {
                    $scope.assignments.splice(index, 1);
                }
            }
            else {
                $scope.assignments[index].isDroppable = false;
            }
        };

        $scope.isRowEnd = function (index) {
            return (index + 1) % $scope.rowLength === 0;
        };

        $scope.isVisible = function (index) {
            return index < $scope.rowLength || (index >= $scope.rowLength && $scope.showingMore);
        };
    }
})();