/* The help tile view and actual content */
app.controller('helpController', ['$scope', '$route', '$location', '$anchorScroll', 'BreadcrumbsService', 'UsersService', 'SettingsService',
                         function ($scope,   $route,   $location,   $anchorScroll,   BreadcrumbsService,   UsersService, SettingsService) {
    var _this = this;
    var currentMethod = $route.current.params.name;

    $scope.isPremiumContent = false;
    $scope.isLoading = false;
    $scope.hasInstructorRole = _.findWhere(UsersService.auth.roles, { type: 'instructor' }) ? true : false;
    $scope.hasManagerRole = _.findWhere(UsersService.auth.roles, { type: 'manager' }) ? true : false;
    $scope.hasTaRole = _.findWhere(UsersService.auth.roles, { type: 'ta' }) ? true : false;
    /* Training Insights access determined by the organization settings */
    var sett = SettingsService.getSettings().then(function (success) {
        $scope.taHasInsights = $scope.hasTaRole && SettingsService.isTrainingInsightsEnabled() ? true : false;
        $scope.dmHasInsights = _.findWhere(UsersService.auth.roles, { type: 'decisionMaker' }) && SettingsService.isTrainingInsightsEnabled() ? true : false;
    });

    $scope.scrollTo = function (scrollId) {
        $location.hash(scrollId);
        $anchorScroll();
    };

    _this.studentAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('student user manual');
    };

    _this.studentquickstartAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('student quick start');
    };

    _this.studentdemovideoAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('student demo video');
    };


    _this.instructorAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('instructor user manual');
    };

    _this.instructorquickstartAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('instructor quick start');
    };

    _this.instructordemovideoAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('instructor demo video');
    };


    _this.managerAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('manager user manual');
    };

    _this.managerquickstartAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('manager quick start');
    };

    _this.managerdemovideoAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('manager demo video');
    };

    _this.taAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('training administrator user manual');
    };

    _this.taquickstartAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('training administrator quick start');
    };

    _this.tademovideoAction = function () {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('training administrator demo video');
    };

    _this.traininginsightsAction = function() {
        BreadcrumbsService.pushCrumb('help', 'help/');
        BreadcrumbsService.pushCrumb('Training Insights');
    };



    if (typeof currentMethod === 'undefined') {
        return;
    } else {
        switch (currentMethod.toLowerCase()) {
            case "student":
                _this.studentAction();
                break;
            case "studentquickstart":
                _this.studentquickstartAction();
                break;
            case "studentdemovideo":
                _this.studentdemovideoAction();
                break;

            case "instructor":
                _this.instructorAction();
                break;
            case "instructorquickstart":
                _this.instructorquickstartAction();
                break;
            case "instructordemovideo":
                _this.instructordemovideoAction();
                break;

            case "manager":
                _this.managerAction();
                break;
            case "managerquickstart":
                _this.managerquickstartAction();
                break;
            case "managerdemovideo":
                _this.managerdemovideoAction();
                break;
            
            case "ta":
                _this.taAction();
                break;
            case "taquickstart":
                _this.taquickstartAction();
                break;
            case "tademovideo":
                _this.tademovideoAction();
                break;
            case "traininginsights":
                _this.traininginsightsAction();
                break;
            default:
                break;
        }
    }

    $scope.forcedScrollTo = function () {
        if (location.hash) {
            $anchorScroll();
        }
    };
}]);
