app.service('UsersService', ['$rootScope', '$cookies', '$http', '$location', '$q', 'addressUtil', 'dateUtil', 'messagingDisplayerUtil', '$httpParamSerializer', 'SettingsService',
    function ($rootScope, $cookies, $http, $location, $q, addressUtil, dateUtil, messagingDisplayerUtil, $httpParamSerializer, SettingsService) {
        var _this = this;

        _this.roleObj = null;
        _this.user = null;
        _this.response = null;
        _this.originalResponse = null;
        _this.singleResponse = null;
        _this.userApplicationType = null;
        _this.auth = {
            currentRole: null,
            isLoggedIn: false,
            roles: null,
            authedUser: null,
            isTopLevel: null
        };
        _this.userIsChangingPw = false;
        _this.userIsEditingProfile = false;
        _this.homeRole = 'student';
        _this.goingToTranscript = false;

        _this.setChangingPwFlag = function (value) {
            _this.userIsChangingPw = value;
        };

        _this.setEditProfileFlag = function (value) {
            _this.userIsEditingProfile = value;
        };

        _this.setHomeRole = function () {
            if (_this.goingToTranscript === false) {
                _this.homeRole = _this.getCurrentRoleType();
            }
            _this.goingToTranscript = false;
        };
        /**
         * Contains possible values for Person types
         *
         * @type {array|objects}
         */
        _this.personTypes = [{
            'name': 'Regular',
            'value': 'regular'
        }, {
            'name': 'Part Time',
            'value': 'partTime'
        }, {
            'name': 'Independent Contractor',
            'value': 'independentContractor'
        }, {
            'name': 'Intern',
            'value': 'intern'
        }, {
            'name': 'Outsourced',
            'value': 'outsourced'
        }, {
            'name': 'Seasonal',
            'value': 'seasonal'
        }, {
            'name': 'Temporary',
            'value': 'temporary'
        }, {
            'name': 'Unknown',
            'value': 'unknown'
        }
        ];

        /**
         * Contains possible values for Person types
         *
         * @type {array|objects}
         */
        _this.personStatuses = [{
            'name': 'Prior-To-Hire',
            'value': 'priorToHire'
        }, {
            'name': 'Active',
            'value': 'active'
        }, {
            'name': 'Terminated',
            'value': 'terminated'
        }, {
            'name': 'Leave',
            'value': 'leave'
        }, {
            'name': 'Paid Leave Of Absence',
            'value': 'paidLeaveOfAbsence'
        }, {
            'name': 'Suspend',
            'value': 'suspend'
        }, {
            'name': 'Retired',
            'value': 'retired'
        }, {
            'name': 'Unknown',
            'value': 'unknown'
        }
        ];

        /**
         * Fetch a single user
         *
         * @param id User ID
         * @returns {*}
         *
         * NOTE: Ignores the id that you pass in. Only returns the currently logged in user.
         * See _this.formatSingleUser
         */
        _this.fetch = function (id) {
            var req = {
                method: 'GET',
                url: 'api/user/single/' + id + '/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.format([success.data]);

                return _this.response[0];
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Fetch a single user
         *
         * @param id User ID
         * @returns {*}
         */
        _this.fetchSingle = function (id) {
            var req = {
                method: 'GET',
                url: 'api/user/single/' + id + '/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };


            return $http.get(req.url).then(function (success) {
                _this.response = success.data;

                return _this.response;
            }, function (failure) {

            });
        };

        _this.search = function (query, optionalType) {
            if (!optionalType)
                optionalType = 'all';

            var req = {
                method: 'GET',
                url: 'api/searchuser/' + optionalType + '?q=' + query,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.formatMultipleUsers(success.data);

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Validate a user's password. This reruns login, based on API logic. Will fully login a person. This
         * should only be run after a user has reset their password.
         *
         * @param username
         * @param password
         * @returns {*}
         */
        _this.validatePassword = function (username, password) {
            var req = {
                method: 'POST',
                url: 'api/account/login/',
                data: {
                    'Password': password,
                    'UserName': username
                },
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.refreshReactCookieStore();
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Send a request to generate a new password, when a user forgets their current password.
         *
         * @param username
         * @returns {*}
         */
        _this.requestTemporaryPassword = function (username) {
            _this.username = username;

            var req = {
                method: 'POST',
                url: 'api/account/forgotpassword/',
                data: {
                    'UserName': _this.username
                },
                headers: {
                    'Content-type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Reset a user's password. This will either grab the current user's username or a preset username.
         *
         * @param password
         * @returns {*}
         */
        _this.resetPassword = function (password) {
            _this.password = password;
            _this.username = typeof _this.username === 'undefined' ? _this.user.userName : _this.username;

            var req = {
                method: 'POST',
                url: 'api/account/savepassword/',
                data: {
                    'Password': _this.password,
                    'UserName': _this.username
                },
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.formatSingleUser(success.data);

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Login a user into the application
         *
         * @param username
         * @param password
         * @returns {*}
         */
        _this.login = function (username, password) {
            _this.password = password;
            _this.username = username;

            var req = {
                method: 'POST',
                url: 'api/account/login/',
                data: {
                    'Password': _this.password,
                    'UserName': _this.username
                },
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {

                _this.originalResponse = _this.response = success.data;

                _this.response = _this.formatSingleUser(success.data);

                _this.setUpCreds();


                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         *
         * @param response
         * @returns {undefined}
         */
        _this.updateFromReact = function (responseData) {
            _this.originalResponse = _this.response = responseData;
            _this.response = _this.formatSingleUser(responseData);
            _this.setUpCreds();
        };


        /**
         * Changing to user context for proxy mode
         *
         * @param username
         * @returns {*}
        */
        _this.changeUserContext = function (username) {
            _this.username = username;

            var req = {
                method: 'POST',
                url: 'api/account/changeUserContext/',
                data: {
                    'UserName': _this.username
                },
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {

                _this.originalResponse = _this.response = success.data;
                _this.response = _this.formatSingleUser(success.data);

                _this.setUpCreds();

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
        * Revoking impersonation
        *
        * @returns {*}
        */
        _this.revokeImpersonation = function () {
            var req = {
                method: 'POST',
                url: 'api/account/revoke/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.originalResponse = _this.response = success.data;
                _this.response = _this.formatSingleUser(success.data);

                _this.setUpCreds();

                return _this.response;
            });
        };

        /*
         * Switches the user's active profile to the one attached to the org passed in by orgId
         */
        _this.switchProfile = function (orgId) {
            var req = {
                method: 'POST',
                url: 'api/account/switchprofile/' + orgId,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };
            return $http(req).then(function (success) {
            }, function (failure) {
                throw failure;
            });

        };

        /**
         * Log out of the app entirely.
         * @returns $http Instance of $http object, with all references to the log out call
         */
        _this.clearSession = function () {
            var req = {
                method: 'POST',
                url: 'api/account/logout/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            _this.clearLocalSessionState();
            sessionStorage.removeItem('disableDashboard');

            return $http(req).then(function (success) {
                var baseUri = document.querySelector('base').href.split('/')[3];

                Cookies.remove('userRole', {
                    path: '/' + baseUri
                });

                _this.refreshReactCookieStore();

                return success.data;
            }, function (failure) {
                throw failure;
            });
        };


        /**
         *
         *
         */
        _this.clearLocalSessionState = function () {
            // Unset all local variables
            _this.auth = {
                isLoggedIn: false,
                roles: null,
                authedUser: null
            };

            _this.user = null;
            _this.response = null;
            _this.originalResponse = null;
        };


        /**
         * Refreshes the cookie store in React.
         * This ensures auth or proxy state changes propogate to React.
         *
         * @returns {undefined}
         */
        _this.refreshReactCookieStore = function () {
            try {
                window.LEGACY_GLOBALS.refreshCookieStore();
            } catch (e) {
                console.warn('Failed to refresh React cookie store.');
                console.warn(e);
            }
        };

        /**
         * update my profile
         *
         * @returns {*}
         */
        _this.editMyProfile = function () {
            var userCopy = angular.copy(_this.user);
            var req = {
                method: 'PUT',
                url: 'api/User/updateMyProfile/',
                data: _this.formatToSend(userCopy),
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                //The call will update the user cookie with the saved information
                var response = _this.format(success.data);
                _this.user = response.user;

            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Edit a user's profile
         *
         * @returns {*}
         */
        _this.editProfile = function () {
            var req = {
                method: 'PUT',
                url: 'api/user/' + _this.getId() + '/',
                data: _this.user,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                jQuery.extend(_this.originalResponse.user, _this.user);

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * @TODO: @adass Get this removed IMMEDIATELY and standardized with above call. PUT api/user/<id> for TA
         * is taken over by DEACTIVATE.
         *
         * Temporary method to update a user's profile from the TA role ONLY. Will be removed
         *
         * @param callback
         * @returns {*}
         */
        _this.taEditProfile = function (user) {
            var req = {
                method: 'PUT',
                url: 'api/user/update/',
                data: _this.formatToSend(user),
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.format(success.data);
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getManagerSabaReportUrlIRR = function (callback, currentTimezone) {
            var req = {
                method: 'GET',
                url: 'api/user/reportlink/IRN/?currentTimezone=' + currentTimezone,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            /**
             * Let the callback run regardless; we only want the saba manager role
             */
            return $http(req).then(function (success) {
                return success.data;
            }, function () {
                callback();
            });
        };

        _this.getManagerSabaReportUrl = function (reportCalled, callback) {
            var req = {
                method: 'GET',
                url: 'api/user/reportlink/' + reportCalled + '/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            /**
             * Let the callback run regardless; we only want the saba manager role
             */
            return $http(req).then(function (success) {
                return success.data;
            }, function () {
                callback();
            });
        };

        _this.getLatitudeStateList = function (countryId) {
            var req = {
                method: 'GET',
                url: 'api/user/states/' + countryId + '/'
            };

            return $http.get(req.url).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getAllUsersForTa = function () {
            var req = {
                method: 'GET',
                url: 'api/user/search',
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;
                // DON'T delete allUsersData., its being used for people groups and rules
                _this.allUsersData = success.data;
                /*
                for (var i = 0; i < _this.response.length; i += 1) {
                    _this.response[i].nodes = [];
                }
                */
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.deactivateUser = function (userId) {
            /* When deactivating a user without specifying a date
             * use today's date so the user is deactivated
             * as soon as this function is called */
            var data;
            var deactivationDate = new Date();
            data = deactivationDate.toISOString();

            var req = {
                method: 'PUT',
                url: 'api/User/' + userId,
                data: '"' + data + '"',
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.activateUser = function (userId) {
            /* When deactivating a user without specifying a date
             * use today's date so the user is deactivated
             * as soon as this function is called */

            var req = {
                method: 'PUT',
                url: 'api/User/Activate/' + userId,
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.batchUpdateUsers = function (users, updatedPropertiesAndValues) {
            var userIds = [];
            _.each(users, function (u) {
                return userIds.push(u.id);
            });
            var key = userIds.join(',');
            var requestMsg = {};
            requestMsg[key] = updatedPropertiesAndValues;

            var req = {
                method: 'PUT',
                url: 'api/user/batchupdate',
                timeout: window.timeout,
                data: requestMsg,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            return $http(req).then(function (success) {
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getUserTrainingAudit = function (id) {
            var req = {
                method: 'GET',
                url: 'api/user/' + id + '/trainingaudit/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.format(success.data.response);

                return _this.response;
            }, function (failure) {
                /**
                 * throw failure; no longer works. Need to throw for some reason.
                 *
                 * @TODO: talk to appropriate people on what happened here.
                 */
                throw failure;
            });
        };


        _this.getIdesignUrl = function () {
            var req = {
                method: 'GET',
                url: 'api/user/idesign',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: 100000
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * End of HTTP requests
         */

        /**
         * Generate navigation per user
         *
         * @returns {*[]}
         */
        _this.getNavigation = function () {
            var userType = _this.getUserType();

            switch (userType) {
                case 1:
                    return [{
                        id: 1,
                        display: 'myDashboard',
                        label: 'My Dashboard',
                        url: null // this needs to be set to null and NOT 'student/home' to make sure the logic in NavigationController.changeUserScope() works correctly.
                    }, {
                        id: 3,
                        display: 'transcripts',
                        label: 'Transcript',
                        url: 'users/transcripts/'
                    }, {
                        id: 4,
                        display: 'myProfile',
                        label: 'Profile',
                        url: 'student/profile/'
                    }];
            }
        };

        _this.getUserType = function () {
            return 1;
        };

        /**
         * Format the response; mainly to format all dates.
         *
         * @param response
         * @returns {*}
         */
        _this.format = function (user) {
            var dates = ['hireDate', 'endDate', 'assignmentAddedDate', 'assignmentRemovedDate'];
            var current = null;

            for (var userNode in user) {
                current = user[userNode];

                for (var node in current) {
                    if (typeof current[node] !== 'undefined' && current[node] !== null && $.inArray(node, dates) !== -1) {
                        if (!_.isObject(current[node].moment)) {
                            current[node] = dateUtil.formatDate(current[node]);
                        }
                    }
                    if (typeof current[node] !== 'undefined' && current[node] !== null && node === "timeZone") {
                        current[node] = parseInt(current[node], 10);
                    }
                }
            }

            if (user.hasOwnProperty('alternateManagers')) {
                if (typeof user.alternateManagers === 'undefined' || user.alternateManagers === null) {
                    user.alternateManagers = [];
                }
            }

            user.droppingStudent = false;
            user.error = messagingDisplayerUtil.create();
            user.isLoading = false;
            user.showCheck = false; // @TODO: remove this potentially?
            user.showEx = false; // @TODO: remove this potentially?
            user.success = messagingDisplayerUtil.create();

            return user;
        };

        _this.formatToSend = function (data) {
            var dates = ['endDate', 'startDate', 'displayStart', 'displayEnd', 'hireDate'];

            if (data.constructor !== Array) {
                for (var node in data) {
                    if (typeof data[node] !== 'undefined' && data[node] !== null && $.inArray(node, dates) !== -1) {

                        if (data[node].moment && _.isObject(data[node].moment)) {
                            data[node] = data[node].moment.toISOString();
                        } else {
                            data[node] = null;
                        }

                    }

                    if (typeof data[node] !== 'undefined' && data[node] !== null && data[node].constructor === Array) {
                        _this.formatToSend(data[node]);
                    }
                }
            }

            if (data.constructor === Array) {
                _.each(data, function (dataPoint) {
                    _this.formatToSend(dataPoint);
                });
            }

            return data;
        };

        _this.formatSingleUser = function (response) {

            response = _this.response = typeof _this.response !== 'undefined' ? _this.response : JSON.parse(String.fromCharCode.apply(null, new Uint8Array(pako.inflate(atob(decodeURIComponent(Cookies.get('user')))))));

            if (response !== null) {
                response = _this.format(response);
            }

            return response;
        };

        _this.formatMultipleUsers = function (response) {
            if (response.length > 0) {
                for (var i in response) {
                    response[i] = _this.format(response[i]);
                }
            }

            return response;
        };

        /**
         * Set up all credentials for the UsersService to be easily accessed throughout the application
         */
        _this.setUpCreds = function () {
            _this.originalResponse = (typeof _this.originalResponse !== 'undefined' && _this.originalResponse !== null) ? _this.originalResponse : JSON.parse(String.fromCharCode.apply(null, new Uint8Array(pako.inflate(atob(decodeURIComponent(Cookies.get('user')))))));
            _this.originalResponse = _this.format(_this.originalResponse);
            _this.response = (typeof _this.response !== 'undefined' && _this.response !== null) ? _this.response : _this.originalResponse;
            _this.auth.isLoggedIn = true;
            _this.user = _this.auth.authedUser = _this.response.user;
            _this.auth.roles = _this.response.roles;
            _this.userApplicationType = _this.auth.userApplicationType = _this.response.userApplicationType;
            _this.auth.isTopLevel = _this.response.isTopLevel;
            _this.auth.taDomains = _this.response.taDomains;
            _this.auth.activeDomain = _this.response.activeDomain;
            _this.impersonate = _this.response.impersonate;
            _this.pausePortal = _this.response.pausePortal;
            localStorage.setItem("userInfo", JSON.stringify(_this.response));


            /**
             Get a role if set from saba and set the current role
             This will ensure we go to manager or instructor screen if selected in Saba
             */
            if (localStorage.getItem("sabaRole") === "Manager") {
                //make sure the user has manager role available
                if (_this.hasRole("manager")) {
                    _this.auth.currentRole = _this.getRoleObjectByName("manager");
                    _this.roleObj = _this.auth.currentRole;
                    _this.setCurrentRoleCookie();

                    localStorage.removeItem("sabaRole");
                } else {
                    _this.roleObj = _this.response.currentRole;
                    _this.setCurrentRoleCookie();
                }
            } else if (localStorage.getItem("sabaRole") === "Instructor") {
                //make sure the user has instructor role available
                if (_this.hasRole("instructor")) {
                    _this.auth.currentRole = _this.getRoleObjectByName("instructor");
                    _this.roleObj = _this.auth.currentRole;
                    _this.setCurrentRoleCookie();

                    localStorage.removeItem("sabaRole");
                } else {
                    _this.roleObj = _this.response.currentRole;
                    _this.setCurrentRoleCookie();
                }
            } else {
                _this.auth.currentRole = _this.roleObj = typeof $cookies.getObject('userRole') !== 'undefined' && $cookies.getObject('userRole') !== 'null' ? $cookies.getObject('userRole') : _this.response.currentRole;

                _this.setCurrentRoleCookie();
            }
            _this.refreshReactCookieStore();
        };

        /**
         * Run setUpCreds if the user is already logged in, and returning from an external site/reopening a browser/etc.
         */
        _this.setFromCookies = function () {
            var cookie = Cookies.get('user');
            if (typeof cookie !== 'undefined') {
                var userCookie = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(pako.inflate(atob(decodeURIComponent(Cookies.get('user')))))));
                if (userCookie) {
                    _this.response = _this.formatSingleUser(userCookie);



                    _this.setUpCreds();
                }
            }
        };

        /**
         * Return if user is logged in
         *
         * @returns {boolean}
         */
        _this.isLoggedIn = function () {
            return _this.auth.isLoggedIn;
        };

        /**
         * Generate array of view ready data for the FE to consumer in between SABA and Latitude transition
         *
         * @returns array roles
         */
        _this.getUrlsForRoles = function () {
            var role = null;
            var roles = [];

            for (var i in _this.auth.roles) {
                /**
                 * Go through each possible role; add links and view changers;
                 * Required for SABA vs Latitude work;
                 * Links required to redirect to Sabara;
                 * View changers required for switching between user contexts
                 */
                switch (_this.auth.roles[i].type) {
                    case 'student':
                        {
                            role = {
                                'display': 'student',
                                'label': 'student',
                                'value': 'student',
                                'roleName': 'student'
                            };

                            roles.push(role);

                            break;
                        }
                    case 'instructor':
                        {
                            role = {
                                'display': 'instructor',
                                'label': 'Instructor',
                                'roleName': 'instructor'
                            };

                            roles.push(role);

                            break;
                        }
                    case 'manager':
                        {
                            role = {
                                'display': 'teamManagement',
                                'label': 'Team Management',
                                'value': 'manager',
                                'roleName': 'manager'
                            };

                            roles.push(role);

                            break;
                        }
                    case 'admin':
                        {
                            role = {
                                'display': 'administration',
                                'label': 'Administration',
                                'roleName': 'admin'
                            };

                            roles.push(role);

                            break;
                        }
                    case 'ta':
                        {
                            // This is for latitude specifically
                            role = {
                                'display': 'premiumAdmin',
                                'label': 'Administration',
                                'value': 'admin',
                                'roleName': 'ta'
                            };

                            roles.push(role);

                            break;
                        }
                    case 'premiumAdmin':
                        {
                            role = {
                                'display': 'premiumAdmin',
                                'label': 'Premium Admin',
                                'value': 'admin',
                                'roleName': 'admin'
                            };

                            roles.push(role);

                            break;
                        }
                    case 'decisionMaker':
                        {
                            role = {
                                'display': 'decisionMaker',
                                'label': 'Decision Map',
                                'value': 'decisionMaker',
                                'roleName': 'decisionMaker'
                            };

                            roles.push(role);

                            break;
                        }
                }
            }

            return roles;
        };

        /**
         * Generally bool-ery
         *
         * @param role string Name of search string
         * @returns boolean Returns if user has current role in list
         */
        _this.hasRole = function (role) {
            var obj = typeof _this.getRoleObjectByName(role) === 'object' ? true : false;

            return obj;
        };

        /**
         * See if user is more than just a student
         *
         * @returns {boolean}
         */
        _this.isUserAboveStudent = function () {
            var roleCount = _this.auth.roles.length;

            return roleCount > 1;
        };

        /**
         * All user getters/setters can be found below
         */
        _this.setUserContext = function (role) {
            if (_this.hasRole(role)) {
                _this.roleObj = _this.getRoleObjectByName(role);
                if (_this.roleObj.name === "TA" && !_this.isSaba() && _this.auth.isTopLevel === false) {
                    if (_this.taDomains()[0] !== undefined) {
                        var switchProfilePromiseTa = _this.switchProfile(_this.taDomains()[0].id);
                        return switchProfilePromiseTa.then(function (success) {
                            _this.auth.activeDomain = _this.taDomains()[0];
                            _this.setCurrentRoleCookie();
                        }, function (failure) {
                            throw failure;
                        });
                    }
                } else if (_this.roleObj.name === "Instructor" && !_this.isSaba()) {
                    var switchProfilePromiseInstructor = _this.switchProfile(_this.auth.authedUser.topLevelOrganizationId);
                    return switchProfilePromiseInstructor.then(function (success) {
                        _this.setCurrentRoleCookie();
                    }, function (failure) {
                        throw failure;
                    });
                } else {
                    return $q(function (resolve) {
                        _this.setCurrentRoleCookie();
                        resolve();
                    });
                }
            } else {
                // @TODO: @sfrohm figure out what to do when user is mucking around.
            }
        };

        _this.isSubDomainAdmin = function () {
            if (_this.auth.isTopLevel !== null) {
                return _this.auth.isTopLevel === false && _this.auth.currentRole.type === 'ta';
            } else {
                return false;
            }
        };

        _this.taDomains = function () {
            return _this.auth.taDomains;
        };

        _this.activeDomain = function () {
            return _this.auth.activeDomain;
        };

        _this.topLevelOrganizationId = function () {
            return _this.auth.authedUser.topLevelOrganizationId;
        };

        /**
         * Set the current role cookie to ensure application knows what permissions a user has
         *
         * @param role
         */
        _this.setCurrentRoleCookie = function (role) {
            _this.auth.currentRole = _this.roleObj;

            var baseUri = document.querySelector('base').href.split('/')[3];

            $cookies.putObject('userRole', _this.auth.currentRole, {
                path: '/' + baseUri
            });
            _this.refreshReactCookieStore();
        };

        /**
         * Return a role, found by name, if role exists in current user's roles
         * @param role
         * @returns {*}
         */
        _this.getRoleObjectByName = function (role) {
            var roles = _this.auth.roles;

            if (role !== null && roles.length > 0) {
                for (var i in roles) {
                    if (roles[i].type === role) {
                        return roles[i];
                    }
                }
            }

            return false;
        };

        /**
         * Return user's current role
         *
         * @returns {null}
         */
        _this.getCurrentRole = function () {
            return _this.auth.currentRole;
        };

        /**
         * Return user's current role type
         *
         * @returns {null}
         *
         * This is similar to getCurrentRole
         * except it returns the string
         * representing the current role
         * instead of a dictionary
         */
        _this.getCurrentRoleType = function () {
            return _this.auth.currentRole.type;
        };

        /**
         * Return if a user is a admin
         *
         * @returns {boolean}
         */
        _this.isAdmin = function () {
            return _this.auth.currentRole.type === 'ta';
        };

        /**
         * Return if a user is a manager
         *
         * @returns {boolean}
         */
        _this.isManager = function () {
            return _this.auth.currentRole.type === 'manager';
        };

        /**
         * Return if a user is an instructor
         *
         * @returns {boolean}
         */
        _this.isInstructor = function () {
            return _this.auth.currentRole.type === 'instructor';
        };

        _this.customLogoUrl = function () {
            return _this.response.customLogoUrl;
        };

        /**
         * Return if a user is a student
         *
         * @returns {boolean}
         */
        _this.isStudent = function () {
            return _this.auth.currentRole.type === 'student';
        };

        /**
         * Return a users full name
         *
         * @returns {string}
         */
        _this.getName = function () {
            return _this.user.firstName + ' ' + _this.user.lastName;
        };

        /**
         * Return users job position
         *
         * @returns {string}
         */
        _this.getJobPosition = function () {
            return _this.user.jobType;
        };

        /**
         * Return if current user is a SABA user
         *
         * @returns {boolean}
         */
        _this.isSaba = function () {
            return _this.userApplicationType.toLowerCase() === 'saba';
        };

        /**
         * Return a users BAI referential ID
         *
         * @returns {int}
         */
        _this.getId = function () {
            return _this.user.id;
        };

        /**
        * Return a users BAI username/ loginid
        *
        * @returns {string}
        */
        _this.getUserName = function () {
            return _this.user.userName;
        };

        /**
         * Return a user's Structure Id (for Latitude API's of getting the countries and states
         *
         * @returns {int}
         */
        _this.getPortalId = function () {
            return _this.user.portalId;
        };

        _this.getCountryId = function () {
            if (_this.user.homeAddress !== null) {
                return _this.user.homeAddress.countryId;
            }
        };

        _this.getStateId = function () {
            if (_this.user.homeAddress !== null) {
                return _this.user.homeAddress.stateId;
            }

        };

        /**
         * Return a users portal code
         *
         * @returns {int}
         */
        _this.getPortalCode = function () {
            var portalCode = typeof _this.user !== 'undefined' && _this.user.portalCode !== null && _this.user.portalCode !== '' ? _this.user.portalCode : null;

            return portalCode;
        };

        /**
         *
         * @returns {string}
         */
        _this.getAddress = function () {
            var homeAddress = _this.user.homeAddress;
            if (homeAddress !== null) {
                _this.user.formattedAddress = addressUtil.formatAddress(homeAddress);
            }
        };

        /**
         *
         * @returns {string}
         */
        _this.getHireDate = function () {
            return _this.user.hireDate.fullDate;
        };

        /* Sample advanced search struct
         * See the UsersService.advancedSearch for complete list of fields
         */
        var advancedSearch = {};
        advancedSearch.firstName = 'Sean';
        advancedSearch.lastName = 'Neilan';

        /* Returns a list of user objects */
        _this.advancedSearch = function (advancedSearchStruct) {
            // See if the user entered anything in the date fields
            var dateFields = ['terminatedAfter',
                'terminatedBefore',
                'startedOnOrBefore',
                'startedOnOrAfter'
            ];
            for (i = 0; i < dateFields.length; i += 1) {
                if (advancedSearchStruct[dateFields[i]]) {
                    var dateObj = advancedSearchStruct[dateFields[i]];
                    if (typeof dateObj === "undefined" || typeof dateObj.moment === "undefined") {
                        advancedSearchStruct[dateFields[i]] = null;
                    } else if (typeof dateObj.moment !== "undefined" && dateObj.moment !== null && _.isObject(dateObj.moment)) {

                        if (dateFields[i] === "startedOnOrAfter" || dateFields[i] === "terminatedAfter") {
                            var newdt1 = dateUtil.setStartTime(dateObj.moment.toDate());
                            dateObj.moment.set({ hour: newdt1.getHours(), minute: newdt1.getMinutes(), second: newdt1.getSeconds(), millisecond: newdt1.getMilliseconds() });
                        } else if (dateFields[i] === "startedOnOrBefore" || dateFields[i] === "terminatedBefore") {
                            var newdt2 = dateUtil.setEndTime(dateObj.moment.toDate());
                            dateObj.moment.set({ hour: newdt2.getHours(), minute: newdt2.getMinutes(), second: newdt2.getSeconds(), millisecond: newdt2.getMilliseconds() });
                        }

                        var newDate = new Date(dateObj.moment.format('L LT'));
                        advancedSearchStruct[dateFields[i]] = (newDate.toISOString());
                    }
                }
            }

            var req = {
                method: 'GET',
                url: 'api/user/search/?' + $httpParamSerializer(advancedSearchStruct),
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return _this.format(success.data);
            }, function (failure) {
                throw failure;
            });
        };

        _this.format_advancedSearch = function () {

        };

        _this.getSabaManagerReportUriFromCookie = function () {
            var url = typeof Cookies.get('managerReportSabaURI') !== 'undefined' && _this.getCurrentRoleType() === 'manager' ? Cookies.get('managerReportSabaURI') : null;

            return url;
        };

        /* Creates a user */
        _this.createUser = function (userObj) {
            var req = {
                method: 'POST',
                url: 'api/user/',
                timeout: window.timeout,
                data: [_this.formatToSend(userObj)]
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         *
         */
        _this.getAllInstructors = function () {
            var req = {
                method: 'GET',
                url: 'api/user/instructors/',
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return _this.formatMultipleUsers(success.data);
            }, function (failure) {
                throw failure;
            });
        };

        _this.isInFuture = function (user) {
            var today = new Date();
            var startDate = user.hireDate.jsDate;
            /* User hasn't started yet */
            if (today < startDate) {
                return true;
            } else {
                return false;
            }
        };

        /* Takes a user object and returns true
         * if the user is active. False if inactive */
        _this.isUserActive = function (user) {
            var today = new Date();

            if (typeof user.hireDate !== "undefined" && user.hireDate.jsDate <= today && (typeof user.endDate === "undefined" || user.endDate === null || user.endDate.jsDate > today)) {
                return true;
            } else {
                return false;
            }
        };

        _this.isSSO = function () {
            var req = {
                method: 'GET',
                url: 'api/user/isSSO/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;
                return _this.response;
            }, function (failure) {
                throw failure;
            });

        };

        _this.getManagerTree = function () {
            var req = {
                method: 'GET',
                url: 'api/user/managers/',
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return (success.data);
            }, function (failure) {
                throw failure;
            });
        };

        _this.getUsersResponsibilities = function (userId) {
            var req = {
                method: 'GET',
                url: 'api/user/responsibilities/' + userId,
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return (success.data);
            }, function (failure) {
                throw failure;
            });
        };

        _this.hasCareerPathAccess = function () {
            var req = {
                method: 'GET',
                url: 'api/user/hascareerpath/',
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return (success.data);
            }, function (failure) {
                throw failure;
            });
        };

        _this.hasAdminRole = function () {
            var results = _this.auth.roles.filter(function (r) { return r.name === "TA"; });
            return results.length > 0;
        };

        _this.displayTime = function () {
            return _this.user.displayDueDateTime;
        };

        _this.getSignedJwt = function (payload) {
            _this.payload = payload;
            var req = {
                method: 'POST',
                url: 'api/user/getsignedjwt',
                data: _this.payload,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return (success);
            }, function (failure) {
                throw failure;
            });
        };

        _this.getPlmAlerts = function () {
            var req = {
                method: 'GET',
                url: 'api/user/getplmalerts',
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return (success.data);
            }, function (failure) {
                throw failure;
            });
        };

        _this.navigateToHome = function () {
            var currentUserRole = _this.getCurrentRoleType();
            // in the React codebase, index.tsx, the home path for ta, manager, and instructor is /users/home
            if (!currentUserRole) {
                return; // not sure if this could happen
            }
            switch (currentUserRole.toLowerCase()) {
                case 'student':
                case 'decisionMaker'.toLowerCase():
                    $location.url('student/home/');
                    break;
                case 'instructor':
                case 'manager':
                case 'ta':
                    this.setUserContext(currentUserRole);
                    if (currentUserRole.toLowerCase() === 'ta' && SettingsService.isILTEnabled()) {
                        $location.url('admin/dashboard/');
                    } else {
                        $location.url('users/home/');
                    }
                    break;
                default:
                    $location.url('student/home/');
                    break;
            }
        };
    }
]);