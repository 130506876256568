app.directive("onScroll", [function () {
    var previousScroll = 0;
    var link = function ($scope, $element, attrs) {
        $element.on('scroll', function (evt) {
            var currentScroll = $element.scrollTop();
            $scope.$eval(attrs.onScroll, {$event: evt, $direct: currentScroll > previousScroll ? 1 : -1});
            previousScroll = currentScroll;
        });
    };
    return {
        restrict: "A",
        link: link
    };
}]);
