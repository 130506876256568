app.service('DocumentService', ['$http', '$q', 'dateUtil', 'Upload', function($http, $q, dateUtil, Upload) {
    var _this = this;

    _this.data = null;

    _this.fetch = function(filterBy) {
        // Return the old data if this function is called twice.
        //I commenteded out this code because it looked like the pointer to the cached data was alive, but the data set was empty (PV 6/15/16)
        //if (_this.data !== null) {
        //    return $q(function (resolve) {
        //        _this.data = _this._format(filterBy);
        //        resolve(_this.data);
        //    });
        //}

        var url = 'api/asset';

        var req = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return $http(req).then(function(success) {
            _this.data = success.data;
            _this._format(filterBy);

            return _this.data;
        }, function(failure) {
            throw failure;
        });
    };

    _this._format = function(filterBy) {

        return _this._formatDocuments([_this.data])[0];
    };

    /*
    _this.filterBy = function () {
        var documents = _this.data;

        var nesting = _this.filter;
        if (nesting !== null) {
            for (var i in documents) {
                if (documents[i].nodeId === nesting) {
                    _this.data = documents[i].children;

                    return;
                }
            }
        }

        _this.data = documents;
    };
    */

    _this._findObjectById = function(id, children) {
        var data = typeof children === 'undefined' ? _this.data : children;
        var final = null;

        if (id !== null) {
            for (var i in data) {
                if (data[i].nodeId === parseInt(id, 10)) {
                    _this.data = data[i];
                } else {
                    if (typeof data[i].children !== 'undefined') {
                        final = _this._findObjectById(id, data[i].children);
                    }
                }
            }

            return final;
        } else {
            _this.data = data;
        }
    };

    // Formats the datetimes in the documents
    // retrieved from the server
    _this._formatDocuments = function(documents) {
        var child = null;

        for (var _document in documents) {
            if (documents[_document].isParent === undefined) {
                continue;
            }
            if (documents[_document].isParent === true) {
                documents[_document].children = _this._formatDocuments(documents[_document].children);
            } else {
                documents[_document].postedDate = dateUtil.formatDate(documents[_document].postedDate);
            }
        }

        return documents;
    };

    /* Takes a url as a string, puts it
     * in the document repository
     * and returns an id of that url */
    _this.uploadUrlToRepository = function(url) {
        var data = { 'Url': url };
        var req = {
            method: 'POST',
            url: 'api/asset/url/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
            timeout: window.timeout
        };
        return $http(req).then(function(success) {
            return success;
        }, function(failure) {
            throw failure;
        });
    };

    /* Takes a url as a string, attaches it as a resource
     * to a course specified by courseId
     * returns nothing of importance */
    _this.attachUrlToCourse = function (url, courseId, launchWithContent) {
        if (launchWithContent === undefined) { launchWithContent = false; }
        var data = { 'Url': url };

        var req = {
            method: 'POST',
            url: 'api/asset/url/' + courseId + '/' + launchWithContent,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
            timeout: window.timeout
        };
        return $http(req).then(function(success) {
            return success;
        }, function(failure) {
            throw failure;
        });
    };

    _this.uploadDocumentToStorage = function (file, courseId) {
        var mimeGroup = _getDocumentMimeGroup(file.type);
        return Upload.upload({
            url: 'api/course/document/' + courseId + '/' + mimeGroup,
            data: { file: file },
        }).then(function (success) {
            return success;
        }, function (error) {
            throw error;
        });
    };

    _this.uploadDocumentUrl = function (url, courseId) {
        var data = { 'Url': url };

        var req = {
            method: 'POST',
            url: 'api/course/document/url/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
            timeout: window.timeout
        };
        return $http(req).then(function (success) {
            return success;
        }, function (failure) {
            throw failure;
        });
    };

    /* Takes a file object from ngf-select and uploads it
     * to the document repository
     */
    _this.uploadDocumentToRepository = function(file) {
        var mimeGroup = _getDocumentMimeGroup(file.type);
        return Upload.upload({
            url: 'api/asset/' + mimeGroup,
            data: { file: file },
        }).then(function(success) {
            return success;
        }, function(error) {
            throw error;
        });
    };

    /* Takes a file object from ngf-select and a courseId
     * and attaches it to the course specified by that ID
     */
    _this.uploadDocument = function (file, courseId, launchWithContent) {
        if (launchWithContent === undefined) { launchWithContent = false;}

        var mimeGroup = _getDocumentMimeGroup(file.type);

        return Upload.upload({
            url: 'api/asset/doc/' + courseId + '/' + mimeGroup + '/' + launchWithContent,
            data: { file: file },
        }).then(function(success) {
            return success;
        }, function(error) {
            throw error;
        });
    };

    _this.attachDocumentFromRepositoryToCourse = function(resourceId, courseId) {
        return $http.put('api/asset/' + courseId + '/' + resourceId + '/').then(function(success) {
            return success;
        }, function(error) {
            throw error;
        });
    };

    _this.getDocumentRepositoryList = function() {
        var url = 'api/asset/';

        var req = {
            method: 'GET',
            url: url
        };

        return $http.get(req.url).then(function(success) {
            _this.response = success.data;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getDocumentsForCourse = function(courseId) {

        var url = 'api/asset/' + courseId + '/course';
        var req = {
            method: 'GET',
            url: url
        };

        return $http(req).then(function(success) {
            _this.response = success.data;
            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getSingleDocumentInfo = function(id) {
        var url = 'api/asset/' + id + '/';

        var req = {
            method: 'GET',
            url: url
        };

        return $http.get(req.url).then(function(success) {
            _this.response = success.data;
            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };
    _this.getAllCoursesByResource = function (id) {
        var url = 'api/course/document/' + id;

        var req = {
            method: 'GET',
            url: url
        };

        return $http.get(req.url).then(function (success) {
            _this.response = success.data;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };
    _this.deleteDocumentById = function (Id) {

        var req = {
            method: 'DELETE',
            url: 'api/asset/document/' + Id,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = success.data;
            return _this.response;
        }, function (failure) {
            throw failure;
        });

    };

    _this.updateSingleAsset = function(asset) {

        var req = {
            method: 'PUT',
            url: 'api/asset/update/',
            data: asset,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = success.data;
            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.replaceAsset = function(file, assetId, fileType) {
        var mimeGroup;
        
        if (fileType === 'file') {
            mimeGroup = _getDocumentMimeGroup(file.type);
            return Upload.upload({
                url: 'api/asset/' + assetId + '/' + mimeGroup + '/'+0,
                data: { file: file },
            }).then(function(success) {
                return success;
            }, function(error) {
                throw error;
            });
        } else if (fileType === 'url') {
            var data = { 'Url': file };
            var req = {
                method: 'PUT',
                url: 'api/asset/' + assetId,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data,
                timeout: window.timeout
            };
            return $http(req).then(function(success) {
                return success;
            }, function(failure) {
                throw failure;
            });
        }
    };

    _this.deleteAsset = function (courseId, fileId) {

        var req = {
            method: 'DELETE',
            url: 'api/asset/' + courseId + '/' + fileId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = success.data;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.uploadDocumentToStorage = function (file, courseId) {
        var mimeGroup = _getDocumentMimeGroup(file.type);
        return Upload.upload({
            url: 'api/course/document/' + courseId + '/' + mimeGroup,
            data: { file: file },
        }).then(function (success) {
            return success;
        }, function (error) {
            throw error;
        });
    };

    _this.uploadDocumentUrl = function (url, courseId) {
        var req = {
            method: 'POST',
            url: 'api/course/document/url/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            data: url,
            timeout: window.timeout
        };
        return $http(req).then(function (success) {
            return success;
        }, function (failure) {
            throw failure;
        });
    }; 

    _this.getDocumentPath = function (courseId) {
        var req = {
            method: 'GET',
            url: 'api/course/document/path/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.isDirectLaunchType = function (type) {
        var typeLower = type.toLowerCase();
        if (typeLower === 'word' || typeLower === 'excel' || typeLower === 'other' || typeLower === 'url') {
            return true;
        }
        return false;
    };

    _this.getDocumentTypeByExt = function (ext) {
        var result;
        switch (ext.toLowerCase()) {
            case ".mp3":
                result = "mp3";
                break;
            case ".mp4":
                result = "mp4";
                break;
            case ".pdf":
                result = "pdf";
                break;
            case ".doc":
            case ".docx":
            case ".dot":
            case ".dotx":
                result = "word";
                break;
            case ".xls":
            case ".xlsx":
            case ".xlt":
            case ".xltx":
                result = "excel";
                break;
            default:
                result = "other";
                break;
        }

        return result;
    };

    var _getDocumentMimeGroup = function(mimeType) {
        var typeMap = {};
        /* pdf, word,powerpoint,excel, text, audio,video, picture, url, zip
         * Server accepts those types. If not found, return text type.
         * @TODO technical debt Move this to C# side.
         */

        typeMap['application/vnd.ms-word.document.macroEnabled.12'] = 'word';
        typeMap['application/msword'] = 'word';
        typeMap['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = 'word';
        typeMap['text/rtf'] = 'word';

        typeMap['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = 'excel';

        typeMap['application/zip'] = 'zip';
        typeMap['application/pdf'] = 'pdf';

        typeMap['audio/mp3'] = 'audio';
        typeMap['audio/3gpp'] = 'audio';
        typeMap['audio/3gpp2'] = 'audio';
        typeMap['audio/mp4'] = 'audio';
        typeMap['audio/ogg'] = 'audio';
        typeMap['audio/mpeg4-generic'] = 'audio';
        typeMap['audio/mpeg'] = 'audio';
        typeMap['audio/rtp-midi'] = 'audio';
        typeMap['audio/vorbis'] = 'audio';
        typeMap['audio/ac3'] = 'audio';

        typeMap['text/html'] = 'text';
        typeMap['text/csv'] = 'text';

        typeMap['image/jpeg'] = 'picture';
        typeMap['image/png'] = 'picture';
        typeMap['image/tiff'] = 'picture';

        typeMap['image/gif'] = 'video';
        typeMap['video/quicktime'] = 'video';
        typeMap['video/ogg'] = 'video';
        typeMap['video/mpeg4-generic'] = 'video';
        typeMap['video/mp4'] = 'video';
        typeMap['video/VP8'] = 'video';
        typeMap['video/jpeg2000'] = 'video';
        typeMap['video/JPEG'] = 'video';
        typeMap['video/H264'] = 'video';
        typeMap['video/3gpp'] = 'video';

        typeMap['application/vnd.openxmlformats-officedocument.presentationml.presentation'] = 'powerpoint';
        typeMap['application/vnd.ms-powerpoint'] = 'powerpoint';
        if (mimeType in typeMap) {
            return typeMap[mimeType];
        }

        return 'text';
    };

}]);
