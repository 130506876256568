(function() {
    app.factory('PathFormatter', pathFormatter);

    function pathFormatter() {
        var service = {
            formatPathParams: formatPathParams,
            formatPathParamsWithEncode: formatPathParamsWithEncode
        };
        return service;


        function formatPathParams(parentPath, name) {
            //replace whitespace and / with dashes and encode the rest
            var nameFormatted = name.toLowerCase().replace(/(\s|\/)/g, '-');
            return parentPath === "" ? nameFormatted : parentPath + '/' + nameFormatted;
        }

        function formatPathParamsWithEncode(parentPath, name) {
            //replace whitespace and / with dashes and encode the rest
            var nameFormatted = encodeURI(name.toLowerCase().replace(/(\s|\/)/g, '-'));
            return parentPath === "" ? nameFormatted : parentPath + '/' + nameFormatted;
        }
    }
})();