app.service('SkillService', ['$http', '$q', function ($http, $q) {
    var _this = this;

    _this.skills = null;

    _this.fetch = function () {

        var url = 'api/skill/';

        var req = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.skills = success.data;
            return _this.skills;
        }, function (failure) {
            throw failure;
        });
    };

    _this.fetchUserSkills = function(userId) {
        var url = 'api/skill/user/' + userId;

        var req = {
            method: 'GET',
            url: url,
            headers: {
                "Content-Type": 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function(failure) {
            throw failure;
        });
    };

    _this.fetchSkillsForCourses = function (courseIds) {
        var url = 'api/skill/courses';

        var req = {
            method: 'POST',
            url: url,
            data: courseIds,
            headers: {
                "Content-Type": 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

}]);