/**
 * Small validation directive to validate dates.
 */
app.directive('baiDateChecker', function () {
    return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModel) {
            ngModel.$validators.dateChecker = function (modelValue) {
                if (typeof $scope.transcriptAdd !== 'undefined') {
                    var startDate = $scope.transcriptAdd.startDate.$viewValue;
                    var completionDate = $scope.transcriptAdd.completionDate.$viewValue;

                    if (typeof startDate !== 'undefined' && typeof completionDate !== 'undefined') {
                        if (startDate > completionDate) {
                            return false;
                        } else {
                            $scope.transcriptAdd.completionDate.$setValidity('dateChecker', true);
                            $scope.transcriptAdd.startDate.$setValidity('dateChecker', true);

                            jQuery('.md-datepicker-input-container.md-datepicker-invalid').removeClass('md-datepicker-invalid');
                        }
                    }
                } else if (typeof $scope.batchAddTranscript !== 'undefined') {
                    var batchStartDate = $scope.batchAddTranscript.startDate.$viewValue;
                    var batchCompletionDate = $scope.batchAddTranscript.completionDate.$viewValue;
                    if (typeof batchStartDate !== 'undefined' && typeof batchCompletionDate !== 'undefined') {
                        if (batchStartDate > batchCompletionDate) {
                            return false;
                        } else {
                            $scope.batchAddTranscript.completionDate.$setValidity('dateChecker', true);
                            $scope.batchAddTranscript.startDate.$setValidity('dateChecker', true);

                            jQuery('.md-datepicker-input-container.md-datepicker-invalid').removeClass('md-datepicker-invalid');
                        }
                    }
                }


                return true;
            };
        }
    };
});

/**
 * Small validation directive to validate passwords based on BAI requirements
 *
 * NOTE: If you use this in a form, your form MUST be named baiform
 * Ex: <form name="baiform"></form>
 */
app.directive('baiValidatorsPasswords', function() {
    return {
        require: 'ngModel',
        link: function($scope, $element, $attrs, ngModel) {
            ngModel.$validators.passwordMatch = function (modelValue) {
                var form = $scope.passwordReset || $scope.baiform;
                if (typeof form.password !== 'undefined' && typeof form.passwordmatch !== 'undefined') {
                    var password = form.password.$viewValue;
                    var passwordmatch = form.passwordmatch.$viewValue;

                    if (typeof password !== 'undefined' && typeof passwordmatch !== 'undefined' && password !== null && passwordmatch !== null) {
                        if (password !== passwordmatch) {
                            return false;
                        }
                    }
                }

                return true;
            };

            ngModel.$validators.passwordRules = function (password) {
                if (typeof password !== 'undefined' && password !== null && password !== '') {
                    var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/;

                    if (!passwordRegex.test(password)) {
                        return false;
                    }
                }

                return true;
            };
        }
    };
});


app.directive('baiValidatorsMatchPasswords', function() {
    return {
        require: 'ngModel',
        link: function($scope, $element, $attrs, ngModel) {
            ngModel.$validators.passwordMatch = function (modelValue) {
                var form = $scope.passwordReset || $scope.baiform;
                if (typeof form.password !== 'undefined' && typeof form.passwordmatch !== 'undefined') {
                    var password = form.password.$viewValue;
                    var passwordmatch = form.passwordmatch.$viewValue;

                    if (typeof password !== 'undefined' && typeof passwordmatch !== 'undefined' && password !== null && passwordmatch !== null) {
                        if (password !== passwordmatch) {
                            return false;
                        }
                    }
                }
                return true;
            };
        }
    };
});


app.directive('baiValidatorsRulesPasswords', function() {
    return {
        require: 'ngModel',
        link: function($scope, $element, $attrs, ngModel) {
            ngModel.$validators.passwordRules = function (password) {
                var form = $scope.passwordReset || $scope.baiform;
                if (typeof password !== 'undefined' && password !== null && password !== '') {
                    var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/;

                    if (!passwordRegex.test(password)) {
                        return false;
                    }
                }
                form.passwordmatch.$validate();
                return true;
            };
        }
    };
});

/**
 * Small validation directive to validate integers
 */
app.directive('baiValidatorsInteger', function() {
    return {
        require: 'ngModel',
        link: function($scope, $element, $attrs, ngModel) {
            ngModel.$validators.integer = function(value) {
                // Return true if value is null or empty.
                if(value === null || value === '') {
                    return true;
                }

                return /(\d{9}$)/.test(value);
            };
        }
    };
});

/**
 * Will reset and clear a form that is marked as optional. These fields will be controlled by another field
 * on the page.
 *
 * There will be several validations.
 */
app.directive('baiOptionalField', function() {
    return {
        require: 'ngModel',
        link: function($scope, $element, $attrs, ngModel) {
            var _this = this;

            /**
             * Deals with pattern based in puts
             *
             * @param value
             * @returns {boolean}
             */
            ngModel.$validators.patternBased = function(value) {
                var pattern = $attrs.pattern;

                var valid = _this.checkForRequiredStatus();

                /**
                 * If field is not required, continue. Field will have been reset.
                 */
                if(valid === true) {
                    return true;
                }

                pattern = RegExp(pattern);

                return pattern.test(value) === true;
            };

            _this.checkForRequiredStatus = function() {
                if($attrs.required === false) {
                    // clear and fully reset the field
                    ngModel.$setViewValue(null);
                    ngModel.$render();

                    return true;
                }

                return false;
            };
        }
    };
});

/**
 * Small validation directive to check for default values; patterned to fit in Angular's model for
 * dropdowns
 */
app.directive('baiNoDefault', function() {
    return {
        require: 'ngModel',
        link: function($scope, $element, $attrs, ngModel) {
            ngModel.$validators.noDefault = function(value) {
                var DEFAULT_VALUE = '--default--';
                
                if(ngModel.$touched === true) {
                    return value !== DEFAULT_VALUE;
                }

                return true;
            };
        }
    };
});

/**
 * Checks if the value in ng-model exists in an array of values. 
 * If so, makes the input field attached to it invalid
 */
app.directive('baiInvalidIfValueExistsIn', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, elm, attr, model) {
            model.$validators.invalidIfValueExistsIn = function(ngModelValue) {
                /* Get the value of the scope variable passed to this validator
                 * It should be an array
                 * (Slight angular hack to make this work.)
                 * http://stackoverflow.com/a/26817720/761726
                 *
                 * The reason we have to use eval here is because
                 * the attribute passed to the validator becomes a string.
                 * That string corresponds to a scope variable.
                 * So evaluate the string in the angular evaluator to get the scope variable!
                 */
                var arrayOfValues = scope.$eval(attr.baiInvalidIfValueExistsIn);
                var currentValue = model.$viewValue;

                if (_.indexOf(arrayOfValues, currentValue) === -1) {
                    model.$setValidity('valueExistsAlready', true);
                }
                else {
                    model.$setValidity('valueExistsAlready', false);
                }

                return ngModelValue;
            };
        }
    };
});


/**
 * Directive is exactly the same as ng-pattern except
 * also checks if field it's operating on is disabled. If it's 
 * disabled, then all checks pass automagically
 */
app.directive('baiNgPatternIfFieldNotDisabled', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, elm, attr, model) {
            model.$validators.baiNgPatternIfFieldNotDisabled = function(ngModelValue) {
                // If model is disabled, remmove validity check
                var disabled = false;
                if (typeof scope.$eval(attr.ngDisabled) !== 'undefined' && scope.$eval(attr.ngDisabled)) {
                    disabled = true;
                }

                /* Exit and mark everything as okay
                 * if the model is disabled */
                if (disabled) {
                    model.$setValidity('pattern', true);
                    return ngModelValue;
                }


                var ngPattern = attr.baiNgPatternIfFieldNotDisabled;
                /* If the user put /'s in the front / back of the regex,
                 * Remove them because we can't create a Regexp object with those
                 * in there */
                if (ngPattern[0] === '/' && ngPattern[ngPattern.length-1]) {
                    ngPattern = ngPattern.slice(1, ngPattern.length-1);
                }

                ngPattern = RegExp(ngPattern);
                /* If the regexp doesn't match the view value
                 * trigger a pattern issue. Otherwise remove the pattern issue */
                if (!ngPattern.test(ngModelValue)) {
                    // Trigger the validation issue
                    model.$setValidity('pattern', false);
                } else {
                    // Untrigger the validation issue
                    model.$setValidity('pattern', true);
                }

                /* Make sure you return this
                 * otherwise you'll have issues with errors on other
                 * validators */
                return ngModelValue;
            };
        }
    };
});

app.directive('dateBefore', function() {
    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ctrl) {
            var isInclusive = attrs.dateOrEquals ? scope.$eval(attrs.dateOrEquals) : false,
                validate = function (val1, val2) {

                    var isValid = true;
                    if (typeof val1 === "undefined" || typeof val2 === "undefined" || val1 === null || val1 === "" || val2 === null || val2 === "") {
                        ctrl.$setValidity("dateBefore", isValid);
                        return;
                    }
                    val1 = replaceAll(val1, "-", "/");
                    
                    var date1 = new Date(val1);
                    var date2;

                    if (val2 !== "undefined") {
                        date2 = new Date(val2);
                        isValid = isInclusive ? date1 <= date2 : date1 < date2;
                    }

                    ctrl.$setValidity("dateBefore", isValid);
                };
            // Watch the value to compare - trigger validate()
            scope.$watch(attrs.dateBefore, function() {
                validate(ctrl.$viewValue, scope.$eval(attrs.dateBefore));
            });

            ctrl.$parsers.unshift(function (value) {
                validate(value, scope.$eval(attrs.dateBefore));
                return value;
            });

            function replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, "g"), replace);
            }
        }
    };
});

app.directive('dateAfter', function() {
    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ctrl) {
            var isInclusive = attrs.dateOrEquals ? scope.$eval(attrs.dateOrEquals) : false,
                validate = function (val1, val2) {
                    var isValid = true;

                    if (typeof val1 === "undefined" || typeof val2 === "undefined" || val1 === null || val1 === "" || val2 === null || val2 === "") {
                        ctrl.$setValidity("dateAfter", isValid);
                        return;
                    }
                    val1 = replaceAll(val1,"-", "/");
                    var date1 = new Date(val1);
                    var date2;

                    if (val2 !== 'undefined') {
                        date2 = new Date(val2);
                        isValid = isInclusive ? date1 >= date2 : date1 > date2;
                    }

                    ctrl.$setValidity("dateAfter", isValid);
                };
            // Watch the value to compare - trigger validate()
            scope.$watch(attrs.dateAfter, function () {
                validate(ctrl.$viewValue, scope.$eval(attrs.dateAfter));
            });

            ctrl.$parsers.unshift(function (value) {
                validate(value, scope.$eval(attrs.dateAfter));
                return value;
            });

            function replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, "g"), replace);
            }
        }
    };
});

/* Directive that validates a max number on a text input field.
The build in AngularJS ng-max directive works for input type=number but IE 11 doesn't support this input type so we need our own */
app.directive('baiMax', function() {
    return {
        require: 'ngModel',
        link: function (scope, el, attrs, ctrl) {
            ctrl.$validators.baiMax = function (value) {
                if (value > parseInt(attrs.baiMax)) {
                    return false;
                }
                return true;
            };
        }
    };
});

/**
 * Checks if the value in ng-model exists in an array of values. 
 * If so, makes the input field attached to it invalid
 * This is specific to subcategory in Management
 */
app.directive('baiSubcategoryNameUnique', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attr, model) {
            model.$validators.invalidIfValueExistsInSubcategoryNames = function (ngModelValue) {
                /* Get the value of the scope variable passed to this validator
                 * It should be an array
                 * (Slight angular hack to make this work.)
                 * http://stackoverflow.com/a/26817720/761726
                 *
                 * The reason we have to use eval here is because
                 * the attribute passed to the validator becomes a string.
                 * That string corresponds to a scope variable.
                 * So evaluate the string in the angular evaluator to get the scope variable!
                 */
                var arrayOfValues = scope.$eval(attr.baiSubcategoryNameUnique);
                //var currentValue = model.$viewValue;

                var subcategoryName = scope.$parent.subcategoryForm.subcategoryName.$viewValue;
                var parentCatID = scope.$parent.subcategoryForm.category.$viewValue;

                if (parentCatID === undefined || parentCatID === null) {
                    //parent not selected yet - cannot compare
                    scope.$parent.subcategoryForm.$setValidity('valueExistsAlready', true);
                    scope.$parent.subcategoryForm.valueExistsAlready = false;
                } else if ((parentCatID !== undefined || parentCatID !== null) && subcategoryName !== undefined) {
                    //Parent is selected - now compare the subcategory name 
                    var existsAlready = _.filter(arrayOfValues, function (category) {
                        if (category.categoryID === parentCatID.categoryID && category.subcategoryName.toLowerCase() === subcategoryName.toLowerCase()) {
                            return category;
                        }
                    });
                    if (existsAlready.length > 0) {
                        //Subcategory name belongs to the selected parent - does not pass validation
                        scope.$parent.subcategoryForm.$setValidity('valueExistsAlready', false);
                        scope.$parent.subcategoryForm.valueExistsAlready = true;
                    } else {
                        //Subcategory name does not belongs to the selected parent - passes validation
                        scope.$parent.subcategoryForm.$setValidity('valueExistsAlready', true);
                        scope.$parent.subcategoryForm.valueExistsAlready = false;
                    }
                }

                return ngModelValue;
            };
        }
    };
});