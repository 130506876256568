app.directive('templateSwitcher', function() {
    return {
        replace: true,
        link: function(scope, el, attr) {
            scope.views = [{
                label: 'tiles',
                name: 'Tiles',
                template: 'app/templates/users/partials/tiles.html',
                icon: '',
                class: "assignmentFilter selected"
            }, {
                label: 'list',
                name: 'List',
                template: 'app/templates/users/partials/list.html',
                icon: '',
                class: "assignmentFilter"
            }, {
                label: 'calendar',
                name: 'Calendar',
                template: 'app/templates/users/partials/calendar.html',
                icon: '',
                class: "assignmentFilter"
            }];

            jQuery('.assignmentFilter').click(function() {
                jQuery(this).siblings('.assignmentFilter').removeClass('selected');

                jQuery(this).addClass('selected');
            });

        },
        controller: ['$scope', function($scope){
            
        }]
    };
});