app.factory('Users_StudentOverride', ['$rootScope', '$route', 'AssignmentsService', 'BreadcrumbsService', 'OfferingsService', 'TranscriptsService', 'UsersService', 'errorDisplayerUtil', 'SettingsService', 'dateUtil', 'TeamService', 'CoursesService', 'CareerPathService', '$q', '$location', 'MessagesService', '$filter', 'SkillService', 'MyDocumentsService','SearchServices',
    function ($rootScope, $route, AssignmentsService, BreadcrumbsService, OfferingsService, TranscriptsService, UsersService, errorDisplayerUtil, SettingsService, dateUtil, TeamService, CoursesService, CareerPathService, $q, $location, MessagesService, $filter, SkillService, MyDocumentsService, SearchServices) {
    return function($scope) {
        var _this = this;

        $scope.role = 'student';
        $scope.isNMLSGroup = false;
        $scope.isNMLSProvisioned = false;
        $scope.showCareerPaths = false;
        $scope.currentRoute = "";
        $scope.careerPathsLoading = false;
        $scope.messagesCount = 0;
        $scope.userSkills = [];
        $scope.careerPathsCompleted = [];
        $scope.requiredAssignments = [];
        $scope.recommendedAssignments = [];
        $scope.electives = [];
        $scope.myDocuments = [];
        $scope.skillsLoading = false;
        $scope.displayTime = false;
        $scope.showLDConnect = false;

        // student dashboard
        // get single student summary for dashboard
        $scope.summaryExpanded = true;
        $scope.toggleSummary = function () {
            $scope.summaryExpanded = !$scope.summaryExpanded;
        };
        
        UsersService.setHomeRole();

        /* Set up pie chart defaults */
        // See old docs for help with canvas charts
        // https://github.com/chartjs/Chart.js/tree/v1.1.1/docs
        // NOT Chart.js 2.0 docs!!
        // Angular charts uses the old version of chart.js
        /*
         This code corresponds to a chart like this in
         the HTML
         <canvas id="summaryPieChartCanvas" class="chart chart-pie"
         chart-data="data" chart-labels="pieChartDefaults.labels" chart-colours="pieChartDefaults.colours" chart-options="pieChartDefaults.options" width="195" height="195">
         </canvas>
         */
       
        $scope.defaultDoughnutData = [
	        {
	            value: 0,
	            color: "#009CE6",
	            label: "Complete",
	        },
	        {
	            value: 100,
	            color: "#d3d3d3",
	            label: "Incomplete",
	        },
            {
                value: 0,
                color: "#F63F3F",
                label: "Overdue",
            }
        ];

        $scope.oldDoughnutData = [
	        {
	            value: 0,
	            color: "#009CE6",
	            label: "Complete"
	        },
	        {
	            value: 100,
	            color: "#808080",
	            label: "Incomplete"
	        },
            {
                value: 0,
                color: "#F63F3F",
                label: "Overdue"
            }
        ];

        $scope.defaultDoughnutOptions = {
            showTooltips: false,
            animation: true,
            percentageInnerCutout: 60,
            segmentStrokeColor: "#00375d"
        };

        $scope.oldDoughnutOptions = {
            showTooltips: false,
            animation: true,
            percentageInnerCutout: 60
        };

        $scope.setDoughnutData = function (defaults, complete, total, overdue) {
            var data = angular.copy(defaults);
            data[0].value = complete;
            data[1].value = (total - complete) - overdue;
            data[2].value = overdue;
            return data;
        };

        $scope.setupDoughnut = function (defaults, complete, total, options, overdue) {
            var setup = {};
            setup.data = $scope.setDoughnutData(defaults, complete, total, overdue);
            setup.options = options;
            return setup;
        };

        // Initialize tabbed nav variables
        // Represents css class so 'active' will add active class to element, '' will make it not active
        // Default to myAssignmentsActive
        $scope.myAssignmentsActive = "active portalBackground portalBorder";
        $scope.myCareerPathsActive = "";
        $scope.assignmentPaddingClass = "assignmentPadding";
        
        /**
         * Secondary routing;
         *
         * This will help us create more of an MVC approach
         */
        $scope.transcriptsAction = function () {
            $scope.homeTemplate = 'app/templates/users/transcripts/student.html';

            $scope.maxDate = new Date();

            $scope.user = UsersService.user;

            $scope.pageOptions.isLoading = true;

            $scope.studentId = UsersService.getId();

            $scope.isStudent = true;
            
            var assignments = TranscriptsService.fetch($scope.studentId);

            assignments.then(function (success) {
                $scope.assignments = success;

                $scope.pageOptions.isLoading = false; // We're no longer loading!

                $scope.sortBy = 'status';
            }, function (response) {
                $scope.assignments = null;

                $scope.pageOptions.isLoading = false; // We're no longer loading!

                $scope.pageOptions.error.setError(response.StatusCode, response.ErrorMessage);
            });

            BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
            BreadcrumbsService.pushCrumb("transcripts");
        };

        $scope.homeAction = function () {
            if ($route.current.params.param !== undefined && $route.current.params.param !== null) {
                var params = $route.current.params.param.split('/');
                if (params.length > 0) {
                    $scope.currentRoute = params[0].toLowerCase();
                }
            }

            $scope.displayTime = UsersService.displayTime();
            
            if ($scope.currentRoute === "careerpaths") {
                $scope.homeTemplate = 'app/templates/users/home/studentCareerPath.html';
                $scope.filterView = 'app/templates/users/partials/filters/studentCareerPaths.html';
                $scope.assignmentFilter = null;
                $scope.role = 'student';
                $scope.myAssignmentsActive = "";
                $scope.myCareerPathsActive = "active portalBackground portalBorder";
                $scope.assignmentPaddingClass = "careerpath-assignmentPadding";
                $scope.orderProperty = 'name';
                BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
                BreadcrumbsService.pushCrumb("career paths");
                var careerPaths = CoursesService.getCoursesByType("careerPath");
                careerPaths.then(function(success) {
                    $scope.assignments = success;
                    var isAssigned = false;
                    _.each($scope.assignments, function(assignment) {
                        assignment.isLoading = false;
                        assignment.addCareerPathError = errorDisplayerUtil.create();
                        if ((assignment.status === 'inProgress' || assignment.status === 'new' || assignment.status === 'pendingApproval') && isAssigned === false) {
                            isAssigned = true;
                        }
                    });

                    if (isAssigned === true) {
                        $scope.assignmentFilter = $scope.getNewestAssignmentsCareerPath;
                        jQuery('#assignedFilter').siblings('.filterItem').removeClass('selected');
                        jQuery('#assignedFilter').addClass('selected');
                    } else {
                        $scope.assignmentFilter = null;
                        jQuery('#allFilter').siblings('.filterItem').removeClass('selected');
                        jQuery('#allFilter').addClass('selected');
                    }

                    $scope.inProgress = _this.filterInProgress($scope.assignments);

                    if ($scope.inProgress.length > 0) {
                        var childPromises = [];
                        $scope.inProgress.forEach(function (careerPath) {
                            var id = careerPath.courseGroupId || careerPath.id;
                            var childPromise = AssignmentsService.getCourseGroupCourses(careerPath.studentId, careerPath.type, id);
                            childPromises.push(childPromise);
                        });
                        $q.all(childPromises).then(function (success) {
                            $scope.inProgress = _this.summarizeCareerPaths($scope.inProgress, success);
                            $scope.apiError = false;
                            $scope.isLoading = false;
                            $scope.responseComplete = true;

                        }, function (failure) {
                            $scope.apiError = true;
                            $scope.errorMessage = failure.ErrorMessage;
                            $scope.isLoading = false;
                            $scope.responseComplete = true;
                        });
                    }
                    else {
                        $scope.apiError = false;
                        $scope.isLoading = false;
                        $scope.responseComplete = true;
                    }
                }, function(failure) {
                    $scope.apiError = true;
                    $scope.isLoading = false;
                    $scope.responseComplete = true;
                    $scope.errorMessage = failure.ErrorMessage;
                });
            }
        };

        $scope.assignmentDueStatusToggle = function(assignment) {
            assignment.dueDate.within24Hours = false;
            assignment.dueDate.overDue = true;
        };

        _this.filterInProgress = function (careerPaths) {
            var inProgress = [];
            inProgress = _.where(careerPaths, {status: "inProgress"});
            return inProgress;
        };

        _this.summarizeCareerPaths = function (careerPaths, children) {
            
            var i;
            for (i = 0; i < careerPaths.length; i++) {
                careerPaths[i].summary = CareerPathService.createSummary(careerPaths[i], children[i]);
                careerPaths[i].newDoughnut = $scope.setupDoughnut($scope.defaultDoughnutData, careerPaths[i].summary.courses.completed, careerPaths[i].summary.courses.total, $scope.defaultDoughnutOptions, 0);
                careerPaths[i].doughnut = $scope.setupDoughnut($scope.oldDoughnutData, careerPaths[i].summary.courses.completed, careerPaths[i].summary.courses.total, $scope.oldDoughnutOptions, 0);
                careerPaths[i].doughnutMessage = careerPaths[i].summary.courses.percentComplete + '%';
            }
            return careerPaths;
        };

        $scope.changePassword = function () {
            UsersService.setChangingPwFlag(true);
            $location.url('student/profile/change-password/');
        };

        $scope.logout = function () {
            var portalCode = UsersService.getPortalCode();
            var logout = UsersService.clearSession();
            logout.then(function (success) {

                // If a user's portal code existed while being logged in, persist it on the logged out state.
                if (portalCode !== '' && portalCode !== null) {
                    portalCode = '?portal=' + portalCode;
                }

                $location.url('/' + portalCode);
            }, function (failure) {

            });

        };

        $scope.editProfile = function () {
            UsersService.setEditProfileFlag(true);
            $location.url('student/profile/edit-profile/');
        };

        $scope.viewCertificate = function (id) {
            $location.url('courses/certificate/assignments/' + id);
        };

        $scope.openCatalog = function () {
            $location.url('catalog');
        };

        $scope.openDocumentsCatalog = function () {
            $location.url('documents-catalog');
        };

        return _this;
    };
}]);
