app.factory('Messages_AdminOverride', ['$http', '$injector', '$rootScope', '$route', 'AssignmentsService', 'BreadcrumbsService', 'MessagesService', 'TasksService', 'TeamService', 'UsersService', 'errorDisplayerUtil',
    function ($http, $injector, $rootScope, $route, AssignmentsService, BreadcrumbsService, MessagesService, TasksService, TeamService, UsersService, errorDisplayerUtil) {
        return function($scope) {
            var _this = this;

            $scope.defaultHeader = false; // This Sets the page to full width of the screen.
            $scope.hideCatalogSearch = false;

            $scope.messaging.tasks = {};
            $scope.messaging.tasks.isLoading = false;
            $scope.messaging.tasks.error = errorDisplayerUtil.create();

            $scope.messaging.assignments = {};
            $scope.messaging.assignments.isLoading = false;
            $scope.messaging.assignments.error = errorDisplayerUtil.create();

            /**
             * Action to display all approval requests a TA has to deal with
             *
             * @void
             */
            $scope.requestsAction = function() {
                $scope.homeTemplate = 'app/templates/messages/requests/admin.html';

                $scope.messaging.assignments.isLoading = true;
                $scope.messaging.assignments.error.reset();

                $scope.messaging.tasks.isLoading = true;
                $scope.messaging.tasks.error.reset();

                _this.getAssignmentsToApprove();
                _this.getTasksToApprove();

                BreadcrumbsService.reset();
                BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
                BreadcrumbsService.pushCrumb('messages', 'student/messages/inbox/');
                BreadcrumbsService.pushCrumb('requests', 'student/messages/requests/');
            };

            /**
             * Action to get all enrollments, and display them for TA management.
             *
             * @void
             */
            $scope.enrollmentsAction = function() {
                $scope.homeTemplate = 'app/templates/messages/enrollments/admin.html';

                $scope.messaging.assignments.error.reset();
                $scope.messaging.assignments.isLoading = true;

                _this.getAssignmentsToApprove();

                BreadcrumbsService.reset();
                BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
                BreadcrumbsService.pushCrumb('messages', 'student/messages/inbox/');
                BreadcrumbsService.pushCrumb('requests', 'student/messages/requests/');
                BreadcrumbsService.pushCrumb('approvals', 'student/messages/approvals/');
            };

            /**
             * Action to get all tasks, and display them for TA management.
             *
             * @void
             */
            $scope.tasksAction = function() {
                $scope.homeTemplate = 'app/templates/messages/tasks/admin.html';

                $scope.messaging.error.reset();
                $scope.messaging.isLoading = true;

                _this.getTasksToApprove();

                BreadcrumbsService.reset();
                BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
                BreadcrumbsService.pushCrumb('messages', 'student/messages/inbox/');
                BreadcrumbsService.pushCrumb('requests', 'student/messages/requests/');
                BreadcrumbsService.pushCrumb('tasks', 'student/messages/tasks/');
            };

            /**
             * Trigger the call to the API to either approve or deny the request
             *
             * @param assignment {object} Contains the event target, which contains all assignment info to send to the API
             * @param status {boolean} Whether to approve or deny the approval request
             *
             * @void
             */
            $scope.approveOrDenyRequestCall = function(assignment, status) {
                assignment.error.reset();
                assignment.isLoading = true;

                var assignmentCall = AssignmentsService.approveDenyEnrollment(assignment.studentId, assignment.offeringId, assignment.transcriptId, assignment.assignmentType, status);
                assignmentCall.then(function(success) {
                    assignment.isLoading = false;

                    var index = $scope.assignments.indexOf(assignment);

                    $scope.assignments.splice(index, 1);
                    if($scope.assignments.length === 0) {
                        $scope.messaging.error.assignments.setError(404, 'There no requests at this time!');
                    }

                    $rootScope.$broadcast('requestApprovals:action:success');
                }, function(failure) {
                    assignment.isLoading = false;

                    assignment.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            $scope.approveOrDenyTaskCall = function(task, status) {
                var taskResponse = TasksService.taskApproval(task.userId, task.id, status);
                taskResponse.then(function() {
                    task.isLoading = false;

                    var index = $scope.tasks.indexOf(task);

                    $scope.tasks.splice(index, 1);
                    if($scope.tasks.length === 0) {
                        $scope.messaging.tasks.error.setError(404, 'There no requests at this time!');
                    }

                    $rootScope.$broadcast('requestApprovals:action:success');
                }, function(failure) {
                    task.isLoading = false;

                    task.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            /**
             * Generic method to get list of approval requests to be used within the actions
             *
             * @return {obj} returns promise for the list of assignments
             */
            _this.getAssignmentsToApprove = function() {
                $scope.assignments = [];

                /**
                 * Get all the approval requests
                 */
                var assignments = TeamService.getListOfAssignmentsToApprove();
                return assignments.then(function(success) {
                    $scope.messaging.assignments.isLoading = false;

                    $scope.assignments = success;
                }, function(failure) {
                    $scope.messaging.assignments.isLoading = false;

                    $scope.messaging.assignments.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            /**
             * Generic method to get list of tasks to be used within the actions
             *
             * @return {object} returns promise for the list of tasks
             */
            _this.getTasksToApprove = function() {
                $scope.tasks = [];

                $scope.messaging.tasks.isLoading = true;
                                
                //Check if user is an Admin and the current role is not admin, 
                //give them the admin cookie so that the API call can be made
                if (UsersService.hasRole("ta") && !UsersService.isAdmin()) {
                    UsersService.setUserContext("ta");
                }

                /**
                 * Get all the task approvals
                 */
                var tasks = TasksService.getTasksApprovalTA();
                tasks.then(function (success) {
                    $scope.messaging.tasks.isLoading = false;

                    $scope.tasks = success;

                }, function (failure) {
                    $scope.messaging.tasks.isLoading = false;

                    $scope.messaging.tasks.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };
        };
    }
]);
