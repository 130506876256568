
app.directive("insightsSeeComments", ['$rootScope', '$sce', '$route', 'InsightsService', 'UsersService', function ($rootScope, $sce, $route, InsightsService, UsersService) {
    // returns the Directive Definition Object
    return {
        restrict: 'E',
        templateUrl: 'app/templates/insights/mappingdecisions/partials/comments.html',
        link: function (scope, element, attrs, ctrl) {
            scope.savingComment = false;
            scope.comments = [];
            /* Comments will start as the active tab */
            scope.commentsActive = true;
            scope.loadingHistory = false;

            var currentUserRole = UsersService.getCurrentRoleType();
            if (currentUserRole === 'ta') {
                scope.currentRoleTA = true;
            }

            /* Get all comments for the decision map/comment type */
            scope.getComments = function () {
                scope.noComment = false;
                /* Get the comments for the decision Map */
                var commentResult = InsightsService.getComments(scope.decisionMapId, scope.commentType);
                commentResult.then(function (success) {
                    scope.comments = success;
                    scope.commentRowsAreLoading = false;
                    scope.showComments = true;
                }, function (failure) {
                    scope.commentRowsAreLoading = false;
                    scope.commentErrorMessage = 'Error - ' + failure.ErrorMessage;
                    scope.commentErrorThrown = true;
                    scope.noComment = true;
                });
            };

            /* DELETE a comment */
            scope.deleteComment = function (commentID) {

                scope.savingComment = true;
                scope.showComments = false;
                var deleteComm = InsightsService.deleteComment(commentID);
                deleteComm.then(function (success) {
                    scope.savingComment = false;
                    /* if the comment save was successful, fade out the comment for a fun effect */                   
                    $("#comment"+commentID).fadeOut("slow");

                    var comIdx = _.findIndex(scope.comments, { "commentID": commentID });
                    if (comIdx > -1) {//remove the comment from the comments array
                        scope.comments.splice(comIdx, 1);
                    }

                }, function (failure) {
                    scope.savingComment = false;
                    scope.commentErrorMessage = 'Error - ' + failure.ErrorMessage;
                    scope.commentErrorThrown = true;

                });
           
            };

            scope.toggleDeletePrompts = function (commentID) {               
                _.each(scope.comments, function (c) {
                    if (c.commentID === commentID) {
                        c.open = !c.open;
                    } else {
                        c.open = false;
                    }

                });
            };


            /* Function to save the comment and reload the comments on the page */
                    /* currently, only used on the decision report page */
            scope.saveComment = function (newCommentText) {              
                if (newCommentText !== undefined && newCommentText !== '') {
                    scope.savingComment = true;
                    var commentSave = InsightsService.saveComment(scope.decisionMapId, scope.newCommentText, scope.commentType);
                    commentSave.then(function (success) {
                        //Comment was saved, show the comment on the page by calling getComments
                        scope.getComments();
                        scope.savingComment = false;
                        scope.newCommentText = '';
                    }, function (error) {
                        scope.savingComment = false;
                    });
                }
            };

            /* show / hide the comments or history tabs */
            scope.toggleComments = function (showComments) {
                scope.commentsActive = showComments;

                if (!showComments && scope.history === undefined) {
                    scope.history = [];
                    scope.loadingHistory = true;
                    var hist = InsightsService.getHistory(scope.decisionMapId, scope.currentWizardStep);
                    hist.then(function (success) {
                        scope.history = success;
                        scope.loadingHistory = false;
                    }, function (failure) {
                        scope.loadingHistory = false;
                    });

                }
            };

            /* load the comments on the directive load */
            scope.getComments();

        }
    };
}]);