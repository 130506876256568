(function () {
    app.directive('documentHeart', [
        function () {
            return {
                restrict: 'E',
                scope: {
                    course: "=",
                    refreshMyDocumentsSignal: '=',
                    showText: '='
                },
                templateUrl: 'App/templates/components/documentHeart.html',
                controller: ['$scope', '$timeout', 'MyDocumentsService', documentHeartController],
            };
        }
    ]);

    function documentHeartController($scope, $timeout, MyDocumentsService) {
        var inProgress = false;

        $scope.haveDocumentHeart = (typeof $scope.course.ProductType !== 'undefined' && $scope.course.ProductType.toLowerCase() === "document") ||
            (typeof $scope.course.CourseType !== 'undefined' && $scope.course.CourseType.toLowerCase() === "document");

        setHeartToAdd();
        if ($scope.course.isInMyDocuments) {
            setHeartToRemove();
        }

        function addToMyDocuments(courseId) {
            if (!inProgress) {
                setHeartToRemove();
                if ($scope.refreshMyDocumentsSignal !== undefined) {
                    $scope.refreshMyDocumentsSignal(courseId, false);
                }
                inProgress = true;

                MyDocumentsService.add(courseId).then(function (success) {
                    $timeout(function () { inProgress = false; }, 100);
                }, function (failure) {
                        console.log('Unable to add document.  CourseID: ' + courseId);
                });
            }
        }

        function removeFromMyDocuments(courseId) {
            if (!inProgress) {
                setHeartToAdd();
                if ($scope.refreshMyDocumentsSignal !== undefined) {
                    $scope.refreshMyDocumentsSignal(courseId, true);
                }
                inProgress = true;

                MyDocumentsService.remove(courseId).then(function (success) {
                    $timeout(function () { inProgress = false; }, 100);
                }, function (failure) {
                        console.log('Unable to remove document.  CourseID: ' + courseId);
                });
            }
        }

        function setHeartToAdd() {
            $scope.myDocumentsActionFunction = addToMyDocuments;
            $scope.imageSource = "app/images/heart-outline.png";
            $scope.myDocumentsText = "Add to My Documents";
        }

        function setHeartToRemove() {
            $scope.myDocumentsActionFunction = removeFromMyDocuments;
            $scope.imageSource = "app/images/heart-filled.png";
            $scope.myDocumentsText = "Remove from My Documents";
        }
    }
})();