app.service('GroupsService', ['$http', '$q', '$filter', 'dateUtil', 'UsersService', 'LocationsService', function ($http, $q, $filter, dateUtil, UsersService, locationsService) {

    var _this = this;
    _this.response = null;
    _this.UsersService = UsersService;
    _this.LocationsService = locationsService;

    _this.tempStateData = {};
    _this.setData = function(data) {
        _this.tempStateData = data;
    };
    _this.getData = function () {
        return _this.tempStateData;
    };
    _this.getCurrentNode = function() {
        if (_this.tempStateData !== null && typeof _this.tempStateData.currentnode !== "undefined") {
            return _this.tempStateData.currentnode;
        }

        return "active";
    };


    _this.peopleGroupStructure = function (groupId) {
        //using json file locally for timebeing will toggle it later to end point
        var req = {
            method: 'GET',
            url: 'api/PeopleGroup?groupId=' + groupId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format_peopleGroupStructure(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getPeopleGroupFilters = function () {
        var req = {
            method: 'GET',
            url: 'api/peoplegroup/filters',
            cache: true,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format_getPeopleGroupFilters(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getContainerOperands = function () {

        var req = {
            method: 'GET',
            url: 'api/peoplegroup/operands',
            cache: true,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout

        };

        return $http(req).then(function (success) {
            _this.response = _.each(success.data, function (dataPoint) { _this.formatDisplayNameForOperands(dataPoint); });

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getPeopleGroups = function (active) {
        var url = '';
        if (typeof active !== "undefined" && active !== null) {
            url = 'api/user/groups/?isactive=' + active;
        } else {
            url = 'api/user/groups';
        }

        var req = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.formatForPeopleGroups(success.data);
            // DON'T delete allGroupsData., its being used for people groups and rules
            _this.allGroupsData = success;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getPeopleGroupUsers = function (groupId) {
        var req = {
            method: 'GET',
            url: 'api/groups/' + groupId + '/users',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = success.data;

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };
    _this.queueSpot = 0;
    _this.getQueueStatus = function (groupId) {
        var req = {
            method: "GET",
            url: "api/peoplegroup/" + groupId + "/queuestatus",
            headers: {
                "Content-Type": "application/json"
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            if (success.data.lastProcessedDate !== null) {
                success.data.lastProcessedDate = new Date(success.data.lastProcessedDate);
            }
            if (success.data.dateQueued !== null) {
                success.data.dateQueued = new Date(success.data.dateQueued);
            }
            if (success.data.processedEndDate !== null) {
                success.data.processEndDate = new Date(success.data.processEndDate);
            }
            if (success.data.processStartDate !== null) {
                success.data.processStartDate = new Date(success.data.processStartDate);
            }
            return success.data;
        });
    };

    _this.addGroupToProcessingQueue = function (groupId) {
        var req = {
            method: "POST",
            url: "api/peoplegroup/" + groupId + "/addToQueue",
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            if (success.data.lastProcessedDate !== null) {
                success.data.lastProcessedDate = new Date(success.data.lastProcessedDate);
            }
            if (success.data.dateQueued !== null) {
                success.data.dateQueued = new Date(success.data.dateQueued);
            }
            if (success.data.processedEndDate !== null) {
                success.data.processEndDate = new Date(success.data.processEndDate);
            }
            if (success.data.processStartDate !== null) {
                success.data.processStartDate = new Date(success.data.processStartDate);
            }
            return success.data;
        });
    };

    _this.getPeoplePreview = function (GroupData) {
        var dataCopy = angular.copy(GroupData);
        var dataToSend = _this.formatGroupDataToSend(dataCopy);        

        var req = {
            method: 'POST',
            url: 'api/peoplegroup/preview',
            data: dataToSend,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = success.data;

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getUsersByGroupId = function (groupId) {
        var req = {
            method: "GET",
            url: "api/peoplegroup/" + groupId,
            timeout: window.timeout
        };
        console.log("GroupId = " + groupId);
        return $http(req).then(function (success) {
            _this.response = _this.formatPGUsers(success.data);
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.formatPGUsers = function (data) {
        var dates = ['endDate', 'startDate', 'displayStart', 'displayEnd', 'hireDate'];
        
        if (data.constructor !== Array) {
            for (var userNode in data) {
                if (typeof data[userNode] !== 'undefined' && data[userNode] !== null && $.inArray(userNode, dates) !== -1) {
                    data[userNode] = dateUtil.formatDate(data[userNode]);
                }
            }
        }
        if (data.constructor === Array) {
            _.each(data, function (dataPoint) {
                _this.formatPGUsers(dataPoint);
            });
        }

        return data;
    };
    _this.getPageOfUsers = function (ar, page, pageSize, totalUserCount) {
        var currentPage = page - 1;
        var result = [];
        for (var i = 0; i < pageSize; i++) {
            var currentIndex = currentPage * pageSize + i;
            if (currentIndex >= totalUserCount) {
                break; // Don't go past total size of array
            }
            result.push(ar[currentIndex]);
        }
        return result;
    };

    _this.setEndDate = function (status) {
        var endDate;
        if (status === 'deactivate') {
            endDate = dateUtil.formatDate(new Date());
        } else if (status === 'activate' || status === 'update' || status === 'save') {
            endDate = null;
        }

        return endDate;
    };

    _this.savePeopleGroup = function (groupData, status) {
        var dataCopy = angular.copy(groupData);

        var endDateRootContainer;
        
        if (typeof status !== 'undefined' && status === "deactivate") {
            endDateRootContainer = _this.setEndDate(status);
            dataCopy.endDate = endDateRootContainer;
        } else if (typeof status !== 'undefined' && status === "activate") {
            endDateRootContainer = _this.setEndDate(status);
            dataCopy.endDate = endDateRootContainer;
        }

        var dataToSend = _this.formatGroupDataToSend(dataCopy);
        dataToSend.status = status;
        // make the time 5 minutes earlier to solve the small date issue
        dataToSend.startDate.setMinutes(dataToSend.startDate.getMinutes()-5);
        var method = '';
        //todo
        if (status.includes("update") || status.includes("activate")) {
            method = 'PUT';
        } else if (status.includes("create")) {
            method = 'POST';
        }

        var req = {
            method: method,
            url: 'api/peoplegroup',
            data: dataToSend,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format_peopleGroupStructure(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.formatDisplayNameForOperands = function (obj) {
        var displayNames = ["equals", "greater than", "less than", "less than or equals to", "greater than or equals to"];
        var iChars = "!&=-<>?";

        var operandName = obj.name || obj.operandValue;
        for (var i = 0; i < operandName.length; i++) {
            if (iChars.indexOf(operandName.charAt(i)) !== -1) {
                if (operandName === "=") {
                    obj.displayName = displayNames[0];
                } else if (operandName === ">") {
                    obj.displayName = displayNames[1];
                } else if (operandName === "<") {
                    obj.displayName = displayNames[2];
                } else if (operandName === "<=") {
                    obj.displayName = displayNames[3];
                } else if (operandName === ">=") {
                    obj.displayName = displayNames[4];
                }
            } else {
                obj.displayName = obj.name || obj.operandValue;
            }
        }

        return obj;
    };

    _this.containerOperandsRequired = function (data) {
        var arr = ["AND", "OR", "NOT"];
        var sendArr = [];
        _.each(data, function (dataPoint) {
            _.each(arr, function (elem) {
                if (elem === dataPoint.operandValue) {
                    sendArr.push(dataPoint);
                }
            });
        });

        return sendArr;
    };

    _this.format_peopleGroupStructure = function (data) {

        var dates = ['startDate', 'endDate'];

        if (data.subContainers.length > 0) {
            var subContainersData = data.subContainers;
            _.each(subContainersData, function (subContainerData) {
                _this.format_peopleGroupStructure(subContainerData);
            });
        }

        if (data.criteria.length > 0) {
            var criteriasData = data.criteria;
            _.each(criteriasData, function (criteria) {
                if (criteria.criteriaName.includes("Date")) {
                    criteria.value = dateUtil.formatDate(criteria.value);
                    criteria.isDate = true;
                    criteria.isString = false;
                    criteria.isBool = false;
                } else if (criteria.criteriaName === "Is Manager") {
                    criteria.valueLabel = _this.formatBool(criteria);
                    criteria.isDate = false;
                    criteria.isString = false;
                    criteria.isBool = true;
                } else {
                    criteria.isDate = false;
                    criteria.isString = true;
                    criteria.isBool = false;
                }
            });
        }

        for (var node in data) {
            if (data[node] !== null && $.inArray(node, dates) !== -1) {
                data[node] = dateUtil.formatDate(data[node]);
            }

        }

        return data;
    };

    _this.formatForPeopleGroups = function (data) {
        var groupsList = data.groups;
        _.each(groupsList, function (group) {
            group.nodes = [];
            group.name = group.groupName;
            if (group.recordDateTime !== null) {
                group.recordDateTime = new Date(group.recordDateTime);
            }

            group.startDate = dateUtil.formatDate(group.startDate);
            if (group.endDate !== null) {
                group.endDate = dateUtil.formatDate(group.endDate);
            }
        });

        return data;
    };

    _this.formatGroupDataToSend = function (data) {

        if (data.startDate !== 'undefined' && data.startDate !== null) {
            data.startDate = data.startDate.jsDate;
        }

        if (typeof(data.endDate) !== 'undefined' && data.endDate !== null) {
            data.endDate = data.endDate.jsDate;
        }

        if (data.criteria.length > 0 || data.subContainers.length > 0) {
            var criteriaArr = data.criteria;
            _.each(criteriaArr, function (criteria) {

                if (criteria.criteriaName.includes("Date") && criteria.value !== 'undefined' && criteria.value !== null) {
                    criteria.value = criteria.value.moment.toISOString(); 
                }

                if (criteria.criteriaName.includes("By Name")) {
                    if (criteria.valueLabel !== null && typeof criteria.valueLabel !== 'undefined') {
                        if (typeof criteria.valueLabel.name !== 'undefined' && criteria.valueLabel.name !== null) {
                            criteria.value = criteria.valueLabel.name; //if autocomplete/typeahead selection used
                        }
                        else {
                            criteria.value = criteria.valueLabel; //typed with no selection
                        }
                    }
                    else {
                        criteria.value = ''; //empty field
                    }
                }
                if (criteria.criteriaName.includes("Custom") || (criteria.criteriaName.includes("Location"))) {


                    if (criteria.valueLabel !== null && typeof criteria.valueLabel !== 'undefined') {
                        if (typeof criteria.valueLabel.name !== 'undefined' && criteria.valueLabel.name !== null) {
                            criteria.value = criteria.valueLabel.name; //if autocomplete/typeahead selection used
                        }
                        else {
                            criteria.value = criteria.valueLabel; //typed with no selection
                        }
                    }
                    else {
                        criteria.value = ''; //empty field
                    }
                }
                delete criteria.operatorsData;
                delete criteria.autoCompleteData;
                delete criteria.isString;
                delete criteria.isDropdown;
                delete criteria.isDate;
                delete criteria.isBool;

            });

            _.each(data.subContainers, function (subContainerData) {
                _this.formatGroupDataToSend(subContainerData);
            });
        }

        return data;
    };

    _this.format_getPeopleGroupFilters = function (data) {

        _.each(data, function (dataPoint) {
            if (dataPoint.operands) {
                _.each(dataPoint.operands, function (operand) {
                    _this.formatDisplayNameForOperands(operand);
                });
            }
        });

        return data;
    };

    // Use this method to return list of active positions for portal
    _this.activePositions = function (positions) {
        var output = $filter('filter')(positions, function (position) {
            if (position.isActive === true) {
                return position;
            }
        });

        return output;
    };

    _this.secondaryFormatGroupStructure = function (orgData, groupData, jobsData, countriesData, stateData) {
        _this.secondaryFormatGroupStructue_orgData = orgData;
        _this.secondaryFormatGroupStructue_groupData = groupData;
        _this.secondaryFormatGroupStructue_jobsData = _this.activePositions(jobsData);
        _this.secondaryFormatGroupStructue_countriesData = countriesData;
        _this.secondaryFormatGroupStructue_userData = stateData;
    };

    _this.secondaryFormatGroupStructure_groupStructure = function (groupStructure) {
        _this.secondaryFormatGroupStructue_groupStructure = groupStructure;
        var groupStructures = [_this.secondaryFormatGroupStructue_groupStructure];
        _this.secondaryFormatGroupStructue_01(groupStructures);

        return _this.peopleGroupCleanUpBeforeRender([groupStructure]);
    };

    _this.peopleGroupCleanUpBeforeRender = function (secondaryFormatGroupStructure) {

        _.each(secondaryFormatGroupStructure, function (groupStructure) {
            if (groupStructure.criteria.length > 0) {
                _.each(groupStructure.criteria, function (criteria) {
                    if (typeof criteria.valueLabel === 'undefined' || criteria.valueLabel === null) {
                        criteria.valueLabel = criteria.value;
                    }
                });
            }
            if (groupStructure.subContainers.length > 0) {
                _this.peopleGroupCleanUpBeforeRender(groupStructure.subContainers);
            }
        });

        return secondaryFormatGroupStructure[0];

    };

    _this.secondaryFormatGroupStructue_01 = function (groupStructures) {
        _.each(groupStructures, function (groupStructure) {
            if (groupStructure.criteria.length > 0) {
                _this.secondaryFormatGroupStructue_02(groupStructure.criteria);
            }
            if (groupStructure.subContainers.length > 0) {
                _this.secondaryFormatGroupStructue_01(groupStructure.subContainers);
            }
        });

        return groupStructures;
    };

    _this.secondaryFormatGroupStructue_02 = function (criterias) {
        var primaryNodes = ['People Group', 'Manager', 'Organization', 'Position', 'Person', 'Country', 'State/Province'];
        var current = null;
        var node;

        if (criterias.length > 0) {
            for (var criteria in criterias) {
                current = criterias[criteria];

                for (node in current) {
                    if (typeof current[node] !== 'undefined' && current[node] !== null && $.inArray(current[node], primaryNodes) !== -1) {
                        _this.secondaryFormatGroupStructue_03(current);
                    }
                }
            }
        }

        return criterias;
    };

    _this.secondaryFormatGroupStructue_03 = function (criteria) {
        var selectedItem;

        if (criteria.criteriaName === "Person" || criteria.criteriaName === "Manager") {
            //selectedItem = $filter('filter')(_this.secondaryFormatGroupStructue_userData, { id: criteria.value });
            //var userpromise = UsersService.fetch(criteria.value);
            //userpromise.then(function (success) {
            //    selectedItem = success;

            //    if (typeof selectedItem !== 'undefined' && selectedItem !== null) {
            //        criteria.valueLabel = selectedItem.fullName;
            //    } else {
            //        criteria.valueLabel = criteria.value;
            //    }
            //});
            criteria.valueLabel = criteria.additionalDetails;

        }

        if (criteria.criteriaName === "Position") {
            selectedItem = $filter('filter')(_this.secondaryFormatGroupStructue_jobsData, { id: criteria.value });
            if (typeof selectedItem !== 'undefined' && selectedItem !== null && selectedItem.length !== 0) {
                criteria.valueLabel = selectedItem[0].name;
            } else {
                criteria.valueLabel = criteria.value;
            }
        }

        if (criteria.criteriaName === "People Group") {
            selectedItem = $filter('filter')(_this.secondaryFormatGroupStructue_groupData, { id: criteria.value });
            if (typeof selectedItem !== 'undefined' && selectedItem !== null && selectedItem.length !== 0) {
                criteria.valueLabel = selectedItem[0].groupName;
            } else {
                criteria.valueLabel = criteria.value;
            }
        }

        if (criteria.criteriaName === "Organization") {
            selectedItem = $filter('filter')(_this.secondaryFormatGroupStructue_orgData, { id: criteria.value });
            if (typeof selectedItem !== 'undefined' && selectedItem !== null && selectedItem.length !== 0) {
                criteria.valueLabel = selectedItem[0].name;
            } else {
                criteria.valueLabel = criteria.value;
            }
        }

        if (criteria.criteriaName === "Country") {
            selectedItem = $filter('filter')(_this.secondaryFormatGroupStructue_countriesData, { countryId: criteria.value });
            if (typeof selectedItem !== 'undefined' && selectedItem !== null && selectedItem.length !== 0) {
                criteria.valueLabel = selectedItem[0].countryName;
            } else {
                criteria.valueLabel = criteria.value;
            }
        }

        if (criteria.criteriaName === "State/Province") {
            selectedItem = $filter('filter')(_this.secondaryFormatGroupStructue_userData, { stateAbbreviation: criteria.value });
            if (typeof selectedItem !== 'undefined' && selectedItem !== null && selectedItem.length !== 0) {
                criteria.valueLabel = selectedItem[0].stateName;
            } else {
                criteria.valueLabel = criteria.value;
            }
        }

        return criteria;
    };

    _this.filterDataForAutoComplete = function (criteria, searchTerm) {
        var selectedCriteria = criteria;
        var fieldName;

        //LMS-7986 Add Custom Fields 0.6-9 as Criteria in People Groups and Rules
        if (selectedCriteria.criteriaName === 'Custom0') {
            fieldName = 'custom0';
        } else if (selectedCriteria.criteriaName === 'Custom1') {
            fieldName = 'custom1';
        } else if (selectedCriteria.criteriaName === 'Custom2') {
            fieldName = 'custom2';
        } else if (selectedCriteria.criteriaName === 'Custom3') {
            fieldName = 'custom3';
        } else if (selectedCriteria.criteriaName === 'Custom4') {
            fieldName = 'custom4';
        } else if (selectedCriteria.criteriaName === 'Custom5') {
            fieldName = 'custom5';
        } else if (selectedCriteria.criteriaName === 'Custom6') {
            fieldName = 'custom6';
        } else if (selectedCriteria.criteriaName === 'Custom7') {
            fieldName = 'custom7';
        } else if (selectedCriteria.criteriaName === 'Custom8') {
            fieldName = 'custom8';
        } else if (selectedCriteria.criteriaName === 'Custom9') {
            fieldName = 'custom9';
        } else if (selectedCriteria.criteriaName === 'Person Status') {
            fieldName = 'personStatus';
        } else if (selectedCriteria.criteriaName === 'Location') {
            fieldName = 'location';
        } else if (selectedCriteria.criteriaName === 'Person') {
            fieldName = 'person';
        } else if (selectedCriteria.criteriaName === 'Country') {
            fieldName = 'country';
        } else if (selectedCriteria.criteriaName === 'State/Province') {
            fieldName = 'stateName';
        } else if (selectedCriteria.criteriaName === 'City') {
            fieldName = 'city';
        } else if (selectedCriteria.criteriaName === 'Person Type') {
            fieldName = 'personType';
        } else if (selectedCriteria.criteriaName === 'Username') {
            fieldName = 'userName';
        } else if (selectedCriteria.criteriaName === 'First Name') {
            fieldName = 'firstName';
        } else if (selectedCriteria.criteriaName === 'Last Name') {
            fieldName = 'lastName';
        } else if (selectedCriteria.criteriaName === 'Manager') {
            fieldName = 'manager';
        } else if (selectedCriteria.criteriaName === 'Position') {
            fieldName = 'positionId';
        } else if (selectedCriteria.criteriaName === 'Organization') {
            fieldName = 'organizationId';
        } else if (selectedCriteria.criteriaName === 'Email Address') {
            fieldName = 'email';
        } else if (selectedCriteria.criteriaName === 'People Group') {
            fieldName = 'groupId';
        } else if (selectedCriteria.criteriaName === 'Organization By Name') {
            fieldName = 'organizationName';
        } else if (selectedCriteria.criteriaName === 'Position By Name') {
            fieldName = 'positionName';
        } else if (selectedCriteria.criteriaName === 'Is Manager') {
            fieldName = 'isManager';
        }

        return _this.skinnedAutoCompleteData(fieldName, searchTerm);
    };

    _this.skinnedAutoCompleteData = function (fieldName, searchTerm) {
        var output = [];
        var searchAttribute;
        var searchUserPromise;

        //LMS-7986 Add Custom Fields 0.6-9 as Criteria in People Groups and Rules
        var otherFields = ['firstName', 'lastName', 'userName', 'custom0', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5', 'custom6', 'custom7', 'custom8', 'custom9', 'email', 'stateName', 'city', 'location'];
        var boolOptions = [{ "name": 'True', "value": "1" }, { "name": 'False', "value": "0" }];

        if (typeof fieldName !== 'undefined' && fieldName !== null && $.inArray(fieldName, otherFields) !== -1) {
            if (fieldName === "stateName") {
                _.each(_this.secondaryFormatGroupStructue_userData, function (state) {
                    if (state.stateName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                        var skinnedItem = {};
                        skinnedItem.id = state.stateAbbreviation;
                        skinnedItem.name = state.stateName;
                        output.push(skinnedItem);
                    }
                });
                return $q(function (resolve, reject) { resolve(output); });
            }
            else {
                searchAttribute = {};
                searchAttribute[fieldName] = searchTerm;
                searchAttribute.pageNumber = 1;
                searchAttribute.pageSize = 25;
                searchUserPromise = UsersService.advancedSearch(searchAttribute);

                return searchUserPromise.then(function (success) {
                    var filteredFromUsers = _.uniq(_.compact(_.pluck(success, fieldName)));
                    _.each(filteredFromUsers, function (item) {
                        var skinnedItem = {};
                        skinnedItem.id = item;
                        skinnedItem.name = item;
                        output.push(skinnedItem);
                    });
                    return output;
                });

            }

        } else {

            if (fieldName === "person" || fieldName === "manager") {

                searchAttribute = {};
                searchAttribute.fullNameContains = searchTerm;

                if (fieldName === "manager") {
                    searchAttribute.roleId = 11;
                }

                searchUserPromise = UsersService.advancedSearch(searchAttribute);

                return searchUserPromise.then(function (success) {
                    _.each(success, function (user) {
                        if (user.fullName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                            var skinnedItem = {};
                            skinnedItem.id = user.id;
                            skinnedItem.name = user.fullName;
                            output.push(skinnedItem);
                        }
                    });
                    return output;
                });
            }

            if (fieldName === "groupId") {
                _.each(_this.secondaryFormatGroupStructue_groupData, function (group) {
                    if (group.groupName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                        var skinnedItem = {};
                        skinnedItem.id = group.id;
                        skinnedItem.name = group.groupName;
                        output.push(skinnedItem);
                    }
                });
                return $q(function (resolve, reject) { resolve(output); });
            }

            if (fieldName === "organizationId" || fieldName === 'organizationName') {
                _.each(_this.secondaryFormatGroupStructue_orgData, function (org) {
                    //LMS-6623 Added another condition to bring up only active organisation in the Sub Container in Rules
                    if (org.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 && org.enabled) {
                        var skinnedItem = {};
                        if (fieldName === 'organizationName') {
                            skinnedItem.id = org.name;
                        }
                        else {
                            skinnedItem.id = org.id;
                        }

                        skinnedItem.name = org.name;
                        output.push(skinnedItem);
                    }

                });
                return $q(function (resolve, reject) { resolve(output); });
            }

            if (fieldName === "positionId" || fieldName === 'positionName') {
                _.each(_this.secondaryFormatGroupStructue_jobsData, function (job) {
                    if (job.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                        var skinnedItem = {};
                        if (fieldName === 'positionName') {
                            skinnedItem.id = job.name;
                        }
                        else {
                            skinnedItem.id = job.id;
                        }
                        skinnedItem.name = job.name;
                        output.push(skinnedItem);
                    }
                });
                return $q(function (resolve, reject) { resolve(output); });
            }

            if (fieldName === "country") {
                _.each(_this.secondaryFormatGroupStructue_countriesData, function (country) {
                    if (country.countryName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                        var skinnedItem = {};
                        skinnedItem.id = country.countryName;
                        skinnedItem.name = country.countryName;
                        output.push(skinnedItem);
                    }
                });
                return $q(function (resolve, reject) { resolve(output); });
            }

            if (fieldName === "personType") {
                var personTypes = _this.UsersService.personTypes;

                _.each(personTypes, function (typ) {
                    if (typ.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                        var skinnedItem = {};
                        skinnedItem.id = typ.value;
                        skinnedItem.name = typ.name;
                        output.push(skinnedItem);
                    }
                });
                return $q(function (resolve, reject) { resolve(output); });
            }

            if (fieldName === "personStatus") {
                var personStatuses = _this.UsersService.personStatuses;

                _.each(personStatuses, function (status) {
                    if (status.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                        var skinnedItem = {};
                        skinnedItem.id = status.value;
                        skinnedItem.name = status.name;
                        output.push(skinnedItem);
                    }
                });
                return $q(function (resolve, reject) { resolve(output); });
            }

            if (fieldName === "isManager") {
                _.each(boolOptions, function (typ) {
                    if (typ.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                        var skinnedItem = {};
                        skinnedItem.id = typ.value;
                        skinnedItem.name = typ.name;
                        output.push(skinnedItem);
                    }
                });
                return $q(function (resolve, reject) { resolve(output); });
            }

        }
    };

    _this.mapUsersForPreview = function (userIds) {
        var output = [];
        _.each(userIds, function (userId) {
            _.each(_this.secondaryFormatGroupStructue_userData, function (user) {
                if (user.id === userId) {
                    output.push(user);
                }
            });
        });

        return output;
    };

    _this.formatBool = function (pgCriteria) {
        if (pgCriteria.value === "1") {
            return "True";
        } else return "False";
    };

    // Generates GUID using sqlguid utility function that prevents collisions
    _this.generateGuid = sqlguid;

    _this.generateRootContainer = function (isSuccessful) {

        var deferred = $q.defer();

        var rootContainerObj = {
            "data": {
                "containerId": _this.generateGuid(),
                "groupId": null,
                "name": "",
                "startDate": dateUtil.formatDate(moment()),
                "endDate": null,
                "subContainers": [],
                "criteria": [],
                "operandId": 1,
                "operandName": "AND (1)",
                // "structureId": UsersService.getPortalId(),
                "followHierarchy": false,
                "parentContainerId": null,
                "hasChildren": false,
                "isRoot": true
            }

        };

        if (isSuccessful === true) {
            deferred.resolve(rootContainerObj.data);
        } else {
            deferred.reject("No Object available!!");
        }

        return deferred.promise;
    };

    _this.generateSubContainer = function (isSuccessful) {

        var deferred = $q.defer();

        var subContainerObj = {
            "data": {
                "containerId": _this.generateGuid(),
                "groupId": "",
                "name": "Container",
                "startDate": null,
                "endDate": null,
                "subContainers": [],
                "criteria": [{
                    "groupCriteriaId": _this.generateGuid(),
                    "criteriaId": "",
                    "criteriaName": "",
                    "operandId": "",
                    "operandName": "",
                    "value": "",
                    "isString": true,
                    "folowHierarchy": true
                }],
                "operandId": 2,
                "operandName": "OR (2)",
                "followHierarchy": false,
                "parentContainerId": null,
                "hasChildren": false,
                "isRoot": false
            }

        };

        if (isSuccessful === true) {
            deferred.resolve(subContainerObj.data);
        } else {
            deferred.reject("No Object available!!");
        }

        return deferred.promise;
    };

    _this.generateCriteria = function () {
        var criteriaObj = {
            "groupCriteriaId": _this.generateGuid(),
            "criteriaId": "",
            "criteriaName": "",
            "operandId": "",
            "operandName": "",
            "value": "",
            "isString": true,
            "folowHierarchy": false
        };

        return criteriaObj;
    };


    _this.formatPeopleGroupsForCopy = function (data) {
        var output_arr = [];

        _.each(data, function (dataPoint) {
            var newPeopleGroupObj = {};
            newPeopleGroupObj.id = dataPoint.id;
            newPeopleGroupObj.groupName = dataPoint.groupName;
            newPeopleGroupObj.newName = dataPoint.name + '_copy';
            output_arr.push(newPeopleGroupObj);
        });

        return output_arr;
    };

    _this.saveCopiedGroup = function (data) {
        var req = {
            method: 'POST',
            url: 'api/peoplegroup/copy',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        console.warn(req.data);
        return $http(req).then(function (success) {
            _this.response = (success.data);
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    var ChangeTimeZoneToUTC = function (dateTime) {
        var year = dateTime.getFullYear();
        var month = dateTime.getMonth();
        var date = dateTime.getDate();
        var hour = dateTime.getHours();
        var minute = dateTime.getMinutes();
        var second = dateTime.getSeconds();

        var result = new Date(Date.UTC(year, month,date,hour,minute,second));
        return result;
    };
}]);
