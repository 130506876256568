app.directive("calendarAssignmentList", function () {
    return {
        restrict: "E",
        templateUrl : 'app/templates/components/calendar/assignmentList.html',
        scope: false,
        link: function (scope, element, attrs) {

            scope.$on("assignmentFilter", function (event, args) {
                scope.assignmentFilter = args;
            });

        }
    };
});
