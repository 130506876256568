app.directive('displayTimeToggle',['$cookies', function ($cookies) {

    var template = 'app/templates/components/displayTimeToggle.html';

    return {
        restrict: 'AEC',
        templateUrl: template,
        controller: [
            '$scope', function($scope) {

                $scope.displayTime = false;

                function init() {
                    var displayTimeUserSetting = $cookies.getObject('userSetting.displayTime');
                    if (typeof displayTimeUserSetting !== 'undefined' && displayTimeUserSetting !== 'null') {
                        $scope.displayTime = displayTimeUserSetting;
                    }
                }

                $scope.toggleDisplayTime = function(displayTime) {
                    $cookies.put('userSetting.displayTime', displayTime);
                };

                init();

            }
        ]
    };
}]);