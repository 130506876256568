app.directive('baiInsightsDecisionMapReport', [function () {
        return {
            restrict: 'E',
            templateUrl: 'App/templates/insights/mappingdecisions/report/baiInsightsDecisionMapReport.html',
            scope: { decisionMapId: '@' },
            controller: ['$scope', '$q', '$location', '$filter', 'InsightsService', 'InsightsDecisionMapService','errorDisplayerUtil','dateUtil', 'messagingDisplayerUtil','RulesService', 'GroupsService', 'UsersService', 'CssService',
                function ($scope, $q, $location, $filter, InsightsService, InsightsDecisionMapService, errorDisplayerUtil, dateUtil, messagingDisplayerUtil, RulesService, GroupsService, UsersService, CssService) {
                
                $scope.getDecisionMapReportError = errorDisplayerUtil.create();
                $scope.isLoading = true;
                $scope.commentType = 'decisionFinalReportComment';
                $scope.commentLayout = 'col-md-6';

                var currentUserRole = UsersService.getCurrentRoleType();
                if (currentUserRole === 'ta') {
                    $scope.currentRoleTA = true;
                }

                var decisionMapPromise = InsightsService.getDecisionMapById($scope.decisionMapId);
                var commentsPromise = InsightsService.getAllComments($scope.decisionMapId);
                var promises = [];
                promises.push(decisionMapPromise);
                promises.push(commentsPromise);

                $q.all(promises).then(function(success) {
                    var decisionMapResult = success[0];
                    var commentsResults = success[1];
                    $scope.decisionMap = decisionMapResult;
                    InsightsDecisionMapService.setDecisionMap(decisionMapResult);
                    $scope.decisionMapComments = commentsResults;
                    $scope.isLoading = false;                    

                    //see if the logged in user is a decision maker
                    var dmIndex = _.findIndex($scope.decisionMap.decisionMakers, { "id": UsersService.getId() });                  
                    if (dmIndex > -1 && $scope.decisionMap.decisionMakers[dmIndex].confirmed) {
                        //if they have already confirmed the report, don't let them do it again
                        $scope.decisionMapStatusConfirmed = true;
                    } else if ($scope.currentRoleTA && $scope.decisionMap.confirmedDateTime !== null && dmIndex === -1) {
                        //if the user is a TA and is not a DM on the report
                        //This enables a TA who is not a dm to confirm the report if a different TA confirmed the report
                        $scope.decisionMapStatusConfirmed = true;
                    }

                    if ($scope.decisionMap.rule.key > 0) { $scope.ruleBtnText = 'Edit Rule'; } else { $scope.ruleBtnText = 'Create Draft Rule'; $scope.ruleName = $scope.decisionMap.name; }
                }, function(failure) {
                    $scope.getDecisionMapReportError.setError(failure.StatusCode, failure.ErrorMessage);
                    $scope.isLoading = false;
                });

                $scope.goBackInWizard = function () {
                    $location.path("insights/decisionmapcourses/" + $scope.decisionMapId);
                };

                $scope.decisionMapChanged = {};
                $scope.decisionMapChanged.success = messagingDisplayerUtil.create();
                $scope.decisionMapChanged.error = messagingDisplayerUtil.create();

                // Assumption: Status parameter is only 'Confirmed' or undefined. If undefined, decision map status is being set to 'Rule Created'
                // after a successful API call.
                $scope.updateDecisionMap = function (status) {
                    /* if the status is confirmed, then update the Decision Report based on confirmation rules */
                    /* else - we are updating rule information and the api will update the decision map status */
                    var oldStatus = $scope.decisionMap.status;
                    $scope.decisionMap.status = status;
                    if (status === 'Confirmed') {
                       
                        //see if I am in the list of decision makers
                        var userInd = _.findIndex($scope.decisionMap.decisionMakers, { "id": UsersService.getId() });
                        if (userInd > -1) {
                            //if I am, then set my confirmed flag
                            $scope.decisionMap.decisionMakers[userInd].confirmed = true;
                        }

                        //If the person logged in is the ta, set the confirmed date time
                        if ($scope.currentRoleTA) {
                            $scope.decisionMap.confirmedDateTime = dateUtil.formatDate(Date());
                        } else if(oldStatus !== 'Confirmed'){
                            //I am not a ta
                            var numDecisionMakersConfirmed = $filter('filter')($scope.decisionMap.decisionMakers, { "confirmed": "true" }).length;
                            //Check if all DM's confirmed the report, if so, set the confirmed datetime
                            if ($scope.decisionMap.decisionMakers.length === numDecisionMakersConfirmed && $scope.decisionMap.confirmedDateTime === null) {
                                $scope.decisionMap.confirmedDateTime = dateUtil.formatDate(Date());
                            } else {
                                //if the DM is setting confimed but not all the DM's confirmed the report, the status should remain as 'Started'
                                $scope.decisionMap.status = 'Started';
                            }
                        }                       
                    }
                    var insightUpd = InsightsService.updateDecisionMap($scope.decisionMap);
                    insightUpd.then(function (success) {
                        if ($scope.decisionMap.rule !== undefined && $scope.decisionMap.rule.key > 0) {
                            $scope.decisionMap.status = 'Rule Created'; // Set this on response because requests can't update a decision map with status = 'Rule Created'
                        } else {
                            //always set to true so that the confirmed button is inactive
                            $scope.decisionMapStatusConfirmed = true;
                            //The report is only officially confirmed if the confirmedDateTime is set
                            if ($scope.decisionMap.confirmedDateTime !== null && $scope.decisionMap.confirmedDateTime !== '') {
                            
                                $scope.decisionMapChanged.success.setError(200, 'Decision Report is Confirmed', true);
                            } else {
                                $scope.decisionMapChanged.success.setError(200, 'You have successfully confirmed the Decision Report', true);
                            }
                        }
                        $scope.decisionMap.statusChanged = dateUtil.formatDate(Date());

                        //reset the decision map with the new status and status changed date
                        InsightsDecisionMapService.setDecisionMap($scope.decisionMap);
                        
                    }, function (failure) {
                        if (status === 'Confirmed') {
                            $scope.decisionMap.status = 'Started';
                            $scope.decisionMap.confirmedDateTime = null;
                        } else {
                            $scope.decisionMap.status = 'Confirmed';
                        }
                        $scope.decisionMapChanged.error.setError(failure.StatusCode, failure.ErrorMessage, true);
                    });
                };

                $scope.editRule = function () {
                    $location.path("rules/edit/" + $scope.decisionMap.rule.key);
                };

                /* Call to create the draft rule for the decision map */
                $scope.createDraftRule = function () {
                    /* Get the base rule object */
                    $scope.newRule = {};
                    var newRuleObj = RulesService.createRuleObj(true);
                    $scope.ruleCreateIsLoading = true;
                    newRuleObj.then(function (success) {

                        /* add the decision map info to the rule object */
                        $scope.newRule = success;
                        $scope.newRule.name = $scope.ruleName;
                        $scope.newRule.startDate = dateUtil.formatDate(Date());

                        /* call the API to create the rule*/
                        var createRule = RulesService.ruleGeneralCall($scope.newRule, 'create');
                        createRule.then(function (success) {
                            $scope.draftRule = success;
                            /* create the people group */
                            addPeopleGroupToRule();
                            /* add the courses */
                            addCoursesToRule();
                            /* update the decision map status */
                            $scope.decisionMap.rule = { key: $scope.draftRule.id, value: $scope.ruleName };
                            $scope.decisionMap.ruleCreatedDateTime = dateUtil.formatDate(Date());
                            $scope.updateDecisionMap();
                            $scope.ruleCreateIsLoading = false;
                            /* update the button */
                            $scope.ruleBtnText = 'Edit Rule';
                            $scope.decisionMapChanged.success.setError(200, 'The Draft Rule ' + $scope.ruleName + ' was successfully created', true);
                        }, function (failure) {
                            $scope.ruleCreateIsLoading = false;
                            if (failure.ErrorMessage.indexOf("already exists") > -1)
                            {
                                $scope.ruleName = $scope.ruleName + '_map_' + $scope.newRule.startDate;
                                $scope.createDraftRule();
                            }else{
                            /* rule creation failed */
                                $scope.decisionMapChanged.error.setError(failure.StatusCode, failure.ErrorMessage, true);
                            }
                        });

                    });
         
                };

                /* function to add the selected Organization to the draft rule */
                addPeopleGroupToRule = function () {

                    if ($scope.decisionMap.businessOrganizationId !== null || $scope.decisionMap.peopleGroupId !== null) {

                        var pgCriteriaContainer = {};
                        var criteriaContainerCall = GroupsService.generateRootContainer(true);

                        criteriaContainerCall.then(function (success) {
                            /* Create the People Group Container */
                            pgCriteriaContainer = success;
                            pgCriteriaContainer.groupId = $scope.draftRule.groupId;
                            pgCriteriaContainer.status = 'update';
                            pgCriteriaContainer.IsRuleCaller = true;
                            pgCriteriaContainer.name = "   ";

                            /* Create the Organization Criteria */
                            var pgCriteria = GroupsService.generateCriteria();
                            if ($scope.decisionMap.businessOrganizationId !== null) {
                                pgCriteria.criteriaName = 'Organization';
                            } else if ($scope.decisionMap.peopleGroupId !== null) {
                                pgCriteria.criteriaName = 'People Group';
                            }
                            

                            /* Get the ID of the operand to use for organization */
                            var operandCall = GroupsService.getPeopleGroupFilters();

                            operandCall.then(function (success) {

                                var orgIndex = -1;
                                if ($scope.decisionMap.businessOrganizationId !== null) {
                                    orgIndex =  _.findIndex(success, { "name": "Organization" });
                                } else if ($scope.decisionMap.peopleGroupId !== null) {
                                    orgIndex = _.findIndex(success, { "name": "People Group" });
                                }

                                if (orgIndex > -1) {
                                    var operaIndex = _.findIndex(success[orgIndex].operands, { "displayName": "equals" });
                                    if (operaIndex > -1) {
                                        /* get the people group filter Id's */
                                        pgCriteria.criteriaId = success[orgIndex].id;
                                        pgCriteria.operandId = success[orgIndex].operands[operaIndex].id;
                                        pgCriteria.operandName = success[orgIndex].operands[operaIndex].name;

                                        if ($scope.decisionMap.businessOrganizationId !== null) {
                                            pgCriteria.value = $scope.decisionMap.businessOrganizationId;
                                            pgCriteria.followHierarchy = $scope.decisionMap.includeOrganizationHierarchy;

                                        } else if ($scope.decisionMap.peopleGroupId !== null) {
                                            pgCriteria.value = $scope.decisionMap.peopleGroupId;
                                        }                                       

                                        if (pgCriteria.value !== undefined) {
                                            pgCriteriaContainer.Criteria = [];
                                            pgCriteriaContainer.Criteria.push(pgCriteria);

                                            var addOrganizationPG = GroupsService.savePeopleGroup(pgCriteriaContainer, 'update');
                                            addOrganizationPG.then(function (success) {
                                                //silent success
                                            }, function (failure) {
                                                /* people group creation failed */
                                                setRuleCreateFailure('people group criteria');
                                            });
                                        }
                                    }
                                } else { setRuleCreateFailure('people group criteria');}
                            }, function (failure) {
                                /* people group creation failed */
                                setRuleCreateFailure('people group criteria');
                            });

                            
                        });
                    }
                };

                setRuleCreateFailure = function (error) {
                    $scope.decisionMapChanged.success.reset();
                    $scope.decisionMapChanged.error.setError(failure.StatusCode, "The draft rule was created but an error occured while adding the " + error + " to the rule.", true);
                };

                /* function to add the selected courses to the draft rule */
                addCoursesToRule = function () {

                    /* make sure there are courses in the map */
                    if ($scope.decisionMap.courses !== null) {
                        /* format the courses */
                        var formattedCourses = RulesService.formatCourseObj($scope.decisionMap.courses);

                        /* Assign the current date to the assign on date for validation since creating a rule with insights does not require a user to do so */
                        _.each(formattedCourses, function (row) {
                            row.assignOnDate = moment().format();
                        });

                        /* call the api to add the courses to the rule */
                        var addCourses = RulesService.updateRuleAssignments($scope.draftRule.id, formattedCourses);
                        addCourses.then(function (success) {
                            //silent success
                        }, function (failure) {
                            /* adding courses failed */
                            setRuleCreateFailure('course(s)');
                        });
                    }
                };

                $scope.draftRuleConfirmationAction = function () {
                    if ($scope.decisionMap.rule.key > 0) {
                        $scope.editRule();
                    } else {
                        $scope.createDraftRule();
                    }
                };

                // Used to submit the export report form.
                // This is because the export needs to bypass angular's $http service to return a downloaded file
                // so this posts the form to the server directly from the template instead of going through the insights service.
                $scope.exportDecisionMap = function () {
                    $scope.currentTimezone = moment.tz.guess();
                    $scope.portalBackgroundColor = CssService.extractColorFromCSS(CssService.currentcss);
                    if ($scope.portalBackgroundColor === null) {
                        $scope.headerTextColor = "#FFFFFF";
                    }
                    else {
                        if (CssService.isColorTooLight($scope.portalBackgroundColor)) {
                            $scope.headerTextColor = "#000000";
                        } else {
                            $scope.headerTextColor = "#FFFFFF";
                        }
                    }
                };

            }]
        };
    }
]);