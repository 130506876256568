app.directive('nestedContainer', ['$filter', '$compile', 'GroupsService', 'UsersService', 'dateUtil', 'sortService', 
    function ($filter, $compile, GroupsService, UsersService, dateUtil, sortService) {

        return {
            restrict: 'E',
            scope: {
                'data': '=',
                'selectOptionsData': '=',
                'containerOperandsData': '=',
                'parentData': '=',
                'options': '='
            },
            templateUrl: 'app/templates/components/nestedcontainer.html',
            link: function(scope, element, attrs) {
                var _this = this;

                scope.containerOperands = scope.containerOperandsData;
                // When viewing/updating a people group, the end-point will return list of criteris.
                // Since, we are iterating thorugh criterias and have a cascading dropdown
                // We need to format the data in such a way that each criteria has its own operands data
                // based on the returning field value.
                scope.criterias = scope.data.criteria;
                scope.typeaheadMinLength = 2;
                scope.typeaheadWaitTime = 800;
                if (!scope.parentData)
                {
                    scope.data.level = 0;
                    scope.data.matchParent = false;
                }
                else {
                    scope.data.level = scope.$parent.$parent.data.level + 1;
                    if (scope.$parent.$parent.data.operandId === scope.data.operandId)
                        scope.data.matchParent = true;
                    else {
                        scope.data.matchParent = false;
                    }
                }

                if (scope.data.level > 14) {
                    scope.containerLimit = true;
                }
                

                _this.singleCriteriaOperands = function(singleCriteria) {
                    _.each(scope.selectOptionsData, function(datapoint) {
                        if (singleCriteria.criteriaId === datapoint.id) {
                            singleCriteria.operatorsData = datapoint.operands;
                        }
                    });

                    return singleCriteria;
                };

                _this.setOperandsDataInCriteria = function(data) {
                    if (data.length > 0) {
                        _.each(data, function(singleCriteria) {
                            _this.singleCriteriaOperands(singleCriteria);
                        });

                        return data;
                    }
                };

                _this.setOperandsDataInCriteria(scope.data.criteria);


                if (angular.isArray(scope.data.subContainers)) {

                    scope.dataForSubcontainers = scope.data.subContainers;

                    var template = "<div id='childContainer' class='row childContainer' ng-repeat='datapoint in dataForSubcontainers'><nested-container data='datapoint' parent-data = 'dataForSubcontainers' select-options-data='selectOptionsData' container-operands-data = 'containerOperandsData' options = 'options' ></nested-container></div>";
                    var linkFn = $compile(template);
                    var content = linkFn(scope);
                    element.append(content);

                }

                scope.noResults = '';

                scope.updateNgModel = function(datapoint) {
                    var selectedOperandId = datapoint.operandId;

                    _.each(scope.selectOptionsData, function(option) {
                        _.each(option.operands, function(operand) {
                            if (operand.id === selectedOperandId) {
                                datapoint.operandName = operand.name;
                            }
                        });

                    });
                };

                scope.selectedItem = function($item, $model, $label, $event, criteriaDataPoint) {
                    criteriaDataPoint.value = $item.id;
                };

                scope.drilldownOperatorSelect = function(datapoint, $index) {

                    var selectedCriteriaId = datapoint.criteriaId;
                    datapoint.value = "";
                    _.each(scope.selectOptionsData, function(option) {
                        if (option.id === selectedCriteriaId) {
                            datapoint.operatorsData = option.operands;
                            // Since, we are unable to update both id and name on select., we are
                            // forcing from JS to update the model
                            datapoint.criteriaName = option.name;
                        }
                    });

                    //datapoint.autoCompleteData = GroupsService.filterDataForAutoComplete(datapoint);

                    if (datapoint.criteriaName.includes("Date")) {
                        datapoint.isDate = true;
                        datapoint.isString = false;
                        datapoint.isDropdown = false;
                        datapoint.isBool = false;
                        datapoint.value = dateUtil.formatDate(dateUtil.setPortalStartTime(new Date()));
                    } else if (datapoint.criteriaName === "Group Name") {
                        datapoint.isDropdown = true;
                        datapoint.isDate = false;
                        datapoint.isString = false;
                        datapoint.isBool = false;
                    }
                    else if (datapoint.criteriaName === 'Is Manager') {
                        datapoint.isDropDown = false;
                        datapoint.isDate = false;
                        datapoint.isString = false;
                        datapoint.isBool = true;
                    }
                    else {
                        datapoint.isDate = false;
                        datapoint.isString = true;
                        datapoint.isDropdown = false;
                        datapoint.isBool = false;
                    }
                };

                scope.formattingDate = function(criteriaDataPoint) {
                    //no changes required at this point
                };

                scope.setContainerOperandName = function(data) {
                    var operandId = data.operandId;

                    _.each(scope.containerOperandsData, function(operand) {
                        if (operand.operandId === operandId) {
                            data.operandName = operand.operandValue;
                        }
                    });
                    if (scope.parentData && scope.$parent.$parent.data.operandId === operandId) {
                        scope.matchParent = true;
                        if (scope.$parent.$parent.data.matchParent === true && scope.data.operandId === 2)
                        { scope.invalidPeople('The way you are creating this People Group may result in poor processing performance.  There also are less time consuming ways for you to create it.  If you need assistance please contact BAI Customer Support at (800)264-7600.', 'Warning'); }
                    }
                    else scope.matchParent = false;
                    for (index = 0; index < scope.data.subContainers.length; ++index) {
                        if (scope.data.subContainers[index].operandId === operandId){
                            scope.data.subContainers[index].matchParent = true;
                            if (scope.matchParent === true && scope.data.operandId === 2)
                            { scope.invalidPeople('The way you are creating this People Group may result in poor processing performance.  There also are less time consuming ways for you to create it.  If you need assistance please contact BAI Customer Support at (800)264-7600.', 'Warning'); }
                            else {
                                for (subind = 0; subind < scope.data.subContainers[index].subContainers.length; ++subind) {
                                    if (scope.data.subContainers[index].subContainers[subind].matchParent)
                                    { scope.invalidPeople('The way you are creating this People Group may result in poor processing performance.  There also are less time consuming ways for you to create it.  If you need assistance please contact BAI Customer Support at (800)264-7600.', 'Warning'); }
                                
                                }
                            }
                        }
                        else scope.data.subContainers[index].matchParent = false;
                    }

                };

                // Root container shouldn't have a trash icon and sibling button.
                scope.addMoreActions = false;
                if (scope.data.isRoot === false) {
                    scope.addMoreActions = true;
                }

                scope.addFilterRule = function() {
                    var newCriteria = GroupsService.generateCriteria();
                    newCriteria.criteriaId = undefined;
                    newCriteria.operandId = undefined;
                    scope.data.criteria.push(newCriteria);
                };

                scope.addChild = function () {
                    if (scope.parentData && scope.$parent.$parent.data.matchParent === true && scope.data.matchParent === true && scope.data.operandId === 2)
                    {
                        scope.invalidPeople('The way you are creating this People Group may result in poor processing performance.  There also are less time consuming ways for you to create it.  If you need assistance please contact BAI Customer Support at (800)264-7600.', 'Warning');
                    }
                    var newSubContainer = GroupsService.generateSubContainer(true);
                    newSubContainer.then(function(success) {
                        scope.data.subContainers.push(success);
                        success.parentContainerId = scope.data.containerId;
                        success.groupId = scope.data.groupId;
                        success.criteria[0].criteriaId = undefined;
                        success.criteria[0].operandId = undefined;
                        success.level = scope.data.level + 1;
                        if (scope.data.subContainers.length > 0) {
                            scope.data.hasChildren = true;
                        }

                    }, function(failure) {
                        // TODO Mani
                    });

                };

                scope.addSibling = function() {
                    var newSubContainer = GroupsService.generateSubContainer(true);
                    newSubContainer.then(function(success) {
                        scope.parentData.push(success);
                        success.parentContainerId = scope.parentData[0].parentContainerId;
                        success.groupId = scope.parentData[0].groupId;
                        success.criteria[0].criteriaId = undefined;
                        success.criteria[0].operandId = undefined;
                        success.level = scope.data.level;
                    }, function(failure) {
                        // TODO Mani
                    });

                };
                scope.invalidPeople = function(message, title) {
                    scope.$emit('invalidPeopleModalOpen', [message, title]);
                };

                scope.removeFilterRule = function(index) {
                    // if (scope.data.criteria.length > 1) 
                        scope.data.criteria.splice(index, 1);
                };

                scope.removeChild = function(datapoint) {
                    scope.parentData.splice(scope.parentData.indexOf(datapoint), 1);
                };

                scope.containerPopover = {};
                scope.containerPopover.isOpen = false;

                /*
                 While user interacting with a particular container that particular container will have a hover effect
                 and user will notice a border across that container.
                 Used Angular jqLite to manupulate the DOM.
                 ngMouseenter and ngMouseLeave didnt work because of nesting.
                 */
                function enterHandler(ev) {
                    var target = angular.element(ev.target);
                    var elId = target.attr('id');
                    if (target.is(".childContainer")) {
                        angular.element(this).css("border", "2px solid #7f7f7f");
                        angular.element(this.parentElement.parentNode).css("border", "none");
                    }
                }

                var mouseEnterHandler = angular.element(document.getElementsByClassName("childContainer"));
                mouseEnterHandler.mouseenter(enterHandler);

                function leaveHandler(ev) {
                    var target = angular.element(ev.target);
                    var elId = target.attr('id');
                    if (target.is(".childContainer")) {
                        angular.element(this).css("border", "none");
                        if (this.parentElement.className === "parentContainer ng-isolate-scope") {
                            angular.element(this.parentElement).css("border", "none");
                        } else {
                            angular.element(this.parentElement.parentNode).css("border", "2px solid #7f7f7f");
                        }
                    }
                }

                angular.element(document.getElementsByClassName("childContainer")).mouseleave(leaveHandler);

                /*
                * Search for users
                */
                scope.userTypeahead = {};
                scope.userTypeahead.search = function (fieldName, value) {
                    scope.userTypeahead.isLoading = true;
                    return GroupsService.filterDataForAutoComplete(fieldName, value).then(function (success) {
                        // The object returned from filterDataForAutoComplete always contains id and name so always using 'name' property is OK
                        var sortedResult = sortService.sortByPropertyContains(success, value, 'name');
                        return sortedResult;
                    });
                };
            }
        };
    }
]);