app.directive('loginFormSwitcher',['UsersService' , function(UsersService) {
    var pages = ['.signin', '.forgotpassword', '.loading', '.enterpin', '.loading', '.resetpassword', '.loading'];

    var template = 'app/templates/users/partials/signin.html';

    return {
        link: function($scope) {
            $scope.forgotForm = function(value) {
                if(value === 0) {
                    jQuery(pages[value]).toggle();

                    jQuery(pages[value + 1]).toggle();
                } else {
                    jQuery(pages[value - 1]).toggle();

                    jQuery(pages[value]).toggle();
                }
            };

            $scope.reset = function() {
                for (var i in pages) {
                    if (pages[i] === '.resetpassword') {
                        $scope.loading = false;
                        UsersService.clearSession();
                    }
                    jQuery(pages[i]).hide();
                }

                jQuery(pages[0]).show();
            };
        },
        restrict: 'A',
        templateUrl: template
    };
}]);