(function () {
    app.directive('playlistHeart', [
        function () {
            return {
                restrict: 'E',
                scope: {
                    course: "=",
                    refreshPlaylistSignal: '=',
                    showText: '='
                },
                templateUrl: 'App/templates/components/playlistHeart.html',
                controller: ['$scope', '$timeout', 'PlaylistServices', playlistHeartController],
            };
        }
    ]);

    function playlistHeartController($scope, $timeout, PlaylistServices) {
        var inProgress = false;

        $scope.haveHeart = $scope.course.CourseType.toLowerCase() !== "documents" && ($scope.course.ProductType === undefined || $scope.course.ProductType.toLowerCase() !== "document");
        setHeartToAdd();
        if ($scope.course.isInPlaylist) {
            setHeartToRemove();
        }

        function addToPlaylist(courseId) {
            if (!inProgress) {
                setHeartToRemove();
                if ($scope.refreshPlaylistSignal !== undefined) {
                    $scope.refreshPlaylistSignal($scope.course.CourseId, true);
                }
                inProgress = true;

                PlaylistServices.addCourseToPlaylist(courseId).then(function (success) {
                    $timeout(function () { inProgress = false; }, 100);
                }, function (failure) {
                    // TODO: Handle error, should it even show anything?
                });
            }
        }

        function removeFromPlaylist(courseId) {
            if (!inProgress) {
                setHeartToAdd();
                if ($scope.refreshPlaylistSignal !== undefined) {
                    $scope.refreshPlaylistSignal($scope.course.CourseId, false);
                }
                inProgress = true;

                PlaylistServices.deleteCourseFromPlaylist(courseId).then(function (success) {
                    $timeout(function () { inProgress = false; }, 100);
                }, function (failure) {
                    // TODO: Handl error, should it even show anything?
                });
            }
        }

        function setHeartToAdd() {
            $scope.playlistActionFunction = addToPlaylist;
            $scope.imageSource = "app/images/heart-outline.png";
            $scope.playlistText = "Add to Electives";
        }

        function setHeartToRemove() {
            $scope.playlistActionFunction = removeFromPlaylist;
            $scope.imageSource = "app/images/heart-filled.png";
            $scope.playlistText = "Remove from Electives";
        }
    }
})();