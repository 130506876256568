// Returns 

app.filter('assignmentAssetTypeName', function(){
    return function(courseTypeName) {
        var courseTypeFullName;

        switch (courseTypeName) {
            case 'wbt':
            case 'Web Based':
                courseTypeFullName = 'Web Based Training';
                break;
            case 'ilt':
            case 'Instructor Led':
                courseTypeFullName = 'Instructor Led Training';
                break;
            case 'vle':
                courseTypeFullName = 'Virtual Classroom';
                break;
            case 'task':
                courseTypeFullName = 'Task';
                break; 
            case 'survey':
                courseTypeFullName = 'Survey';
                break;
            case 'evaluation':
                courseTypeFullName = 'Evaluation';
                break;
            case 'assessment':
                courseTypeFullName = 'Assessment';
                break;
            case 'courseGroup':
                courseTypeFullName = 'Course Group';
                break;
            case 'courseGroupCurricula':
            case 'Curricula':
                courseTypeFullName = 'Curricula';
                break;
            case 'courseGroupCertification':
            case 'Certification':
                courseTypeFullName = 'Certification';
                break;
            //Adding Career Path to filter
            case 'careerPath':
                courseTypeFullName = 'Career Path';
                break;
            case 'mp3':
            case 'mP3':
                courseTypeFullName = 'MP3';
                break;
            case 'mp4':
            case 'mP4':
                courseTypeFullName = 'MP4';
                break;
            case 'word':
                courseTypeFullName = 'Word';
                break;
            case 'pdf':
                courseTypeFullName = 'PDF';
                break;
            case 'excel':
                courseTypeFullName = 'Excel';
                break;
            case 'url':
                courseTypeFullName = 'URL';
                break;
            case 'other':
                courseTypeFullName = 'Other';
                break;
            default:
                courseTypeFullName = 'Unknown';
                break;
        }       
        return courseTypeFullName;
    };
});