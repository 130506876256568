/* This page is a demo page for various components related to the premiumAdmin */

app.factory('Assignments_AdminOverride', ['BreadcrumbsService', 'UsersService', 'OrganizationsService', 'CatalogService', '$http', '$routeParams', 'RoleMapsMixin', '$injector', '$filter', 'errorDisplayerUtil', '$route', 'CoursesService', 'OrgFunctions', 'AssignmentsService', '$location', 'TypeMapsMixin', 'SettingsService', '$rootScope', 'DocumentService', '$sce', 'WizardHandler',
    function (BreadcrumbsService, UsersService, OrganizationsService, CatalogService, $http, $routeParams, RoleMapsMixin, $injector, $filter, errorDisplayerUtil, $route, CoursesService, OrgFunctions, AssignmentsService, $location, TypeMapsMixin, SettingsService, $rootScope, DocumentService, $sce, WizardHandler) {
        return function ($scope) {
            var _this = this;

            $scope.defaultHeader = true; // This Sets the page to full width of the screen.
            $scope.hideCatalogSearch = true;
            /* This will make the navigation tab active on certain page */
            $scope.activeTab = 'tab-active-class';
            //Need an obj reference to pass scope to ng-include attr in html.
            $scope.tab2_Options = {};
            $scope.contentSearch = "assignmentsSearch.name";
            
            /* TODO: Training Insights set to false originally, will not be addressed until we tackle Go-Live stories */
            $scope.showTrainingInsights = false;

            $scope.rows = Array();

            _this.allCoursesData = Array(); // Stores a list of all courses from the server

            $scope.tab2_Options.baiCatalogTreeFilter = "";
            $scope.baiCatalogIsLoading = true;
            $scope.customCatalogIsLoading = true;
            $scope.rowsAreLoading = true;

            $scope.homeAction = function () {
                $scope.homeTemplate = 'app/templates/assignments/home/admin.html';

                _this.baiCatalogCta();
                _this.customCatalogCta();
            };

            $scope.baiCatalogError = {};
            $scope.baiCatalogOptions = {};
            $scope.baiCatalogOptions.initiallyOpened = true;
            $scope.baiCatalogError.error = errorDisplayerUtil.create();
            $scope.baiCatalogTreeTitle = "BAI Catalog";

            /* Used to display organization rows / selected row in the dropdown.
             * These exist because the dropdown selected organization
             * needs to display it's name differently than the list
             * of orgs in the dropdown list.
             *
             * Normally we can just use obj.name like every other dropdown.
             */
            $scope.orgRowDisplayer = function (org) {
                var orgName = "";

                for (var orgPathIndex in org) {
                    orgName = org[orgPathIndex].name;
                }

                return orgName;
            };

            $scope.selectedOrgDisplayer = function (org) {
                if (!org) {
                    return '';
                }

                // get the last org path in the org array because it's a path
                // See OrgFunctions create org hierarchy list

                return org[org.length - 1].name;
            };


            _this.baiCatalogCta = function () {
                $scope.baiCatalogError.error.reset();

                _this.allCourses();
                var baiCatalogPromise = CatalogService.getBaiCatalogData();

                return baiCatalogPromise.then(function (success) {
                    _this.baiCatalogPromiseData = success;
                    $scope.baiCatalogData = success.formattedCatalogTreeData;
                    $scope.baiCatalogIsLoading = false;

                }, function (failure) {
                    $scope.baiCatalogIsLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.baiCatalogError.error.setError(errorCode, message);
                });
            };

            $scope.assignmentTableError = {};
            $scope.assignmentTableError.error = errorDisplayerUtil.create();

            _this.allCourses = function () {
                $scope.assignmentTableError.error.reset();
                var allCoursesData = CoursesService.getCoursesForOrg();
                return allCoursesData.then(function (success) {
                    $scope.rowsAreLoading = false;
                    $scope.rows = success;

                    _this.allCoursesData = success;

                    if (typeof $scope.edit === 'undefined') {
                        $scope.edit = {};
                    }
                    $scope.manageCourse.categoryRows = _this.allCoursesData;

                    /* Compose a list of course codes so that
                     * users don't create courses with codes
                     * that already exist. on step 2 of the wizard
                     */
                    $scope.courseCodes = _.uniq(_.map(_this.allCoursesData, function (row) { return row.code; }));
                }, function (failure) {
                    $scope.baiCatalogIsLoading = false;
                    $scope.rowsAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.assignmentTableError.error.setError(errorCode, message);
                });
            };


            /* This gets called when you click on any node in the tree.
             * Filter the items on the right when you click on the node
             */
            $scope.baiCatalogNodeClick = function (item) {
                if (item === "root") {
                    $scope.rows = _this.allCoursesData;
                } else {
                    _this.coursesArray = [];
                    $scope.rows = [];
                    var filteredCoursesFromCatalog = filterRowsByCatalogId(item);
                    /* There might be some courses which may be associated to multiple sub-categories.
                     * Its better to remove all duplicates and pass it to UI or else ng-repeat will
                     * throw errors. (Error: ngRepeat:dupes Duplicate Key in Repeater)
                     */
                    $scope.rows = CatalogService.removeDuplicates(mapCatalogCoursesToCourseDetails(filteredCoursesFromCatalog), "id");
                }
            };

            var filterRowsByCatalogId = function (node) {
                var catalogCoursesData = _this.baiCatalogPromiseData.formattedCoursesData;
                var nodeId = node.nodeId;
                _.each(catalogCoursesData, function (course) {
                    if (course.parentId === nodeId) {
                        _this.coursesArray.push(course);
                    }
                });

                if (node.nodes) {
                    _.each(node.nodes, function (nodeSubset) {
                        filterRowsByCatalogId(nodeSubset);
                    });
                }

                return _this.coursesArray;
            };

            /* the baiCatalog returns the catalog Taxanomy and courses related 
             * to the sub categories too. But the course object doesnt contain
             * all the required properties. so inorder to map the courses from 
             * catalog api to get all courses api. we run this method to map.
             */
            var mapCatalogCoursesToCourseDetails = function (arr) {
                var displayCourseList = [];

                _.each(arr, function (elem) {
                    _.each(_this.allCoursesData, function (course) {
                        if (course.id === elem.courseId) {
                            displayCourseList.push(course);
                        }
                    });
                });

                return displayCourseList;
            };

            $scope.customCatalogError = {};
            $scope.customCatalogOptions = {};
            $scope.customCatalogError.error = errorDisplayerUtil.create();
            $scope.customCatalogTreeTitle = "Custom Catalog";
            $scope.customCatalogOptions.initiallyOpened = false;

            _this.customCatalogCta = function () {
                $scope.customCatalogIsLoading = false;
                var errorCode = '404';
                var message = 'The method or operation is not implemented.';
                $scope.customCatalogError.error.setError(errorCode, message);
            };

            /* This makes coloumns of a table sortables */
            $scope.propertyName = 'name';
            $scope.reverse = false;

            $scope.sortBy = function (propertyName) {
                $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                $scope.propertyName = propertyName;
            };

            $scope.property = "$";
            $scope.changeFilter = function (property) {
                if (property === "all") {
                    $scope.property = "$";
                } else {
                    $scope.property = property;
                }
            };

            /* This will check all the rows in the table in that instance */
            /// TO DO //
            $scope.selectAll = {};
            $scope.checkAll = function () {
                _.each($scope.rows, function (row) {
                    row.select = $scope.selectAll.checked;
                });
            };

            /********* EDIT ASSIGNMENT *******/
            $scope.edit = {};
            $scope.edit.course = {};
            $scope.edit.error = errorDisplayerUtil.create();
            $scope.edit.isLoading = false;
            $scope.edit.selectedCourseCategory = null;


            /* Used to display category rows / selected category in the dropdown.
             * These exist because the dropdown selected organization
             * needs to display it's name differently than the list
             * of orgs in the dropdown list.
             *
             * Normally we can just use obj.name like every other dropdown.
             */
            $scope.edit.rowDisplayer = function (category) {
                var categoryPathArray = [];

                for (var categoryPathIndex in category) {
                    categoryPathArray.push(category[categoryPathIndex].name);
                }

                return categoryPathArray.join('>');
            };

            $scope.edit.selectedDisplayer = function (category) {
                if (!category) {
                    return '';
                }

                // get the last category path in the category array because it's a path
                // See OrgFunctions create category hierarchy list
                return category[category.length - 1].name;
            };

            // $scope.edit.form = {};

            // $scope.edit.submit

            $scope.edit.changeCommonDates = function () {
                var startDate = $scope.edit.course.displayStart;
                var endDate = $scope.edit.course.displayEnd;

                if ((startDate !== null && endDate !== null) || (startDate === null && endDate === null)) {
                    if (startDate > endDate) {
                        $scope.edit.form.dateRangeError = true;

                        return;
                    } else {
                        $scope.edit.form.dateRangeError = false;
                    }
                }
                if (endDate === null) {
                    $scope.edit.form.dateRangeError = false;
                }
            };

            $scope.editAction = function () {
                $scope.homeTemplate = 'app/templates/assignments/edit/admin.html';

                $scope.edit.isLoading = true;
                $scope.edit.error.reset();

                var courseId = parseInt($route.current.params.param.split('/')[0]);
                // Load the catalog data & then try to find the course to edit
                _this.allCourses().then(function (success) {
                    $scope.edit.course = _.filter(_this.allCoursesData, function (courseObj) {
                        if (courseObj.id === courseId) {
                            return true;
                        }
                        return false;
                    });

                    if ($scope.edit.course.length === 0) {
                        // trigger error here
                        var errorCode = '404';
                        var message = "There's no course under that ID";
                        $scope.edit.error.setError(errorCode, message);
                        $scope.edit.isLoading = false;
                        return;
                    }

                    $scope.edit.course = $scope.edit.course[0];
                    $scope.edit.course.typeFullName = TypeMapsMixin.getFullAssignmentTypeName($scope.edit.course.type);

                    $scope.edit.course.BAIOnly = true;

                    /* If this course already has a catalogId, try to find that category
                     * corresponding to the catalogId in the list of courses.
                     * If we don't find it, make the selected course category null */
                    if ($scope.edit.course.catalogId !== 0) {
                        var potentialCourseCategory = _.filter($scope.manageCourse.categoryRows, function (categoryRow) {
                            return (categoryRow.id === $scope.edit.course.catalogId);
                        });
                        if (potentialCourseCategory.length > 0) {
                            $scope.edit.selectedCourseCategory = potentialCourseCategory[0];
                        }
                    } else {
                        $scope.edit.selectedCourseCategory = null;
                    }

                    //var course = AssignmentsService.asdf();
                    var course = AssignmentsService.fetchSingleCourse($scope.edit.course.id);
                    course.then(function (success) {
                        var extra = success;
                        $scope.edit.course.maxEnrollmentAttempts = extra.maxEnrollmentAttempts;

                        if ($scope.edit.course.displayEnd) {
                            $scope.edit.course.displayEnd = moment(extra.displayEnd).toDate();
                        } else
                            $scope.edit.course.displayEnd = null;

                        $scope.edit.course.displayStart = moment(extra.displayStart).toDate();
                        $scope.edit.course.isManagerApprovalRequired = extra.isManagerApprovalRequired;
                        $scope.edit.course.BAIOnly = true;

                        $scope.edit.isLoading = false;
                    }, function (error) {
                        var errorCode = error.ErrorCode;
                        var message = error.ErrorMessage;
                        $scope.edit.error.setError(errorCode, message);

                        $scope.edit.isLoading = false;
                    });
                }, function (error) {
                    var errorCode = error.ErrorCode;
                    var message = error.ErrorMessage;
                    $scope.edit.error.setError(errorCode, message);

                    $scope.edit.isLoading = false;
                });
                _this.customCatalogCta();
            };

            $scope.edit.save = function (course) {
                var tempCourse = angular.copy(course);

                /* If the user selected a course category
                 * put the course we're editing under that ID */
                if ($scope.edit.selectedCourseCategory) {
                    tempCourse.catalogId = $scope.edit.selectedCourseCategory.id;
                }

                tempCourse.displayStart = moment(tempCourse.displayStart).toDate();

                if (tempCourse.displayEnd) {
                    tempCourse.displayEnd = moment(tempCourse.displayEnd).toDate();
                } else {
                    tempCourse.displayEnd = null;
                }
                $scope.customCatalogOptions.initiallyOpened = false;

                $scope.edit.isLoading = true;

                CoursesService.editCourse(tempCourse).then(function (success) {
                    $scope.baiCatalogTreeFilter = "";
                    $scope.baiCatalogIsLoading = true;
                    $scope.customCatalogIsLoading = true;
                    $scope.rowsAreLoading = true;

                    $scope.homeAction();

                    $location.path('assignments/home/');
                    $scope.edit.isLoading = false;
                }, function (error) {
                    $scope.edit.isLoading = false;

                    var errorCode = error.ErrorCode;
                    var message = error.ErrorMessage;
                    $scope.edit.error.setError(errorCode, message);
                });
            };
            /********* CREATE ASSIGNMENT ACTION **********/
            ///////// GLOBAL VARIABLES
            // you're a wizard harry!
            $scope.create = {};

            $scope.create.ASSIGNMENT_TYPE_ENUM = {
                'WBT': 'wbt',
                'ILT': 'ilt',
                'COURSEGROUP': 'courseGroup',
                'TASK': 'task',
                'EVALUATION': 'evaluation',
                'SURVEY': 'survey',
                'ASSESSMENT': 'assessment'
            };

            $scope.create.ASSIGNMENT_TYPE_TITLE_ENUM = {
                'wbt': 'Web Based Training',
                'ilt': 'Instructor Led Training',
                'courseGroup': 'Course Group',
                'task': 'Task',
                'evaluation': 'Evaluation',
                'survey': 'Survey',
                'assessment': 'Assessment'
            };

            $scope.create.currentAssignmentType = null;
            $scope.create.formData = {};
            $scope.create.error = errorDisplayerUtil.create();
            $scope.create.isLoading = false;
            /* stores the course returned by the server
             * when we create a course on step 2 */
            $scope.create.createdCourse = null;
            $scope.create.documentList = [];

            //////// WATCH STATEMENTS
            /* Disable the validation on the max enrollment attempts field
             * if enrollment attempts are unlimited */
            $scope.$watch('create.maxEnrollmentAttemptsIsUnlimited', function (maxEnrollmentAttemptsIsUnlimited) {
                if (maxEnrollmentAttemptsIsUnlimited) {
                    $scope.create.enrollmentAttemptsRegex = new RegExp('');
                    $scope.create.formData.maxEnrollmentAttempts = 0;
                } else {
                    $scope.create.enrollmentAttemptsRegex = /^\d+$/;
                }
            });

            $scope.$watch('create.nonScoring', function (nonScoring) {
                if ($scope.create.nonScoring) {
                    $scope.create.formData.score = null;
                }
            });

            $scope.create.selectAssignment = function (assignmentType) {
                // Assigning this twice. Should probably refactor.
                $scope.create.currentAssignmentType = assignmentType;
                $scope.create.formData.type = assignmentType;

                $scope.create.currentAssignmentTypeFullName = $scope.create.ASSIGNMENT_TYPE_TITLE_ENUM[assignmentType];

                // Set a default value for the course code, format like 'wbt20170714141522923' with time including ms to encourage uniqueness
                $scope.create.formData.code = ($scope.create.currentAssignmentType + (new Date()).toISOString().replace(/[^0-9]/g,''));

                WizardHandler.wizard('Course').next();
            };

            $scope.create.goBackToStep2 = function () {
                delete $scope.create.formData.id;
                WizardHandler.wizard('Course').previous();
            };

            $scope.create.hideModal = function () {
                jQuery('#courseCreateSuccessModal').modal('hide');
                $location.url('assignments/home/');
            };

            _this._attachFiles = function (courseId, courseType, scormPackages, documentList) {
                var filesToUpload = [];

                for (var i = 0; i < scormPackages.length; i += 1) {
                    filesToUpload.push(DocumentService.uploadScormPackage(scormPackages[i], courseId));
                }

                for (var j = 0; j < documentList.length; j += 1) {
                    // Uploading a document from browser
                    if (documentList[j].fileOrUrl === 'file' && !documentList[j].fromRepo) {
                        filesToUpload.push(DocumentService.uploadDocument(documentList[j].fileObject, courseId, false));
                        }

                        // Attaching document from repository
                        if (documentList[j].fileOrUrl === 'file' && documentList[j].fromRepo) {
                            filesToUpload.push(DocumentService.attachDocumentFromRepositoryToCourse(documentList[j].id, courseId));
                        }

                    // Attaching a URL
                    if (documentList[j].fileOrUrl === 'url') {
                        filesToUpload.push(DocumentService.attachUrlToCourse(documentList[j].name, courseId, false));
                    }
                }

                return Promise.all(filesToUpload);
            };

            $scope.create.addScormPackage = function ($files) {
                $scope.isNotZipFile = false;

                if ($files.length === 0) {
                    return;
                }

                var file = $files[0];

                if (file.type !== 'application/zip') {
                    $scope.isNotZipFile = true;
                    return;
                }

                file.uniqueId = Date.now();
                $scope.create.scormPackages.push(file);
            };

            /* Called when you press the X button on the gray box
             * in the scorm package list */
            $scope.create.removeScormPackage = function (fileToRemove) {
                $scope.create.scormPackages = _.filter($scope.create.scormPackages, function (file) {
                    return (file.uniqueId !== fileToRemove.uniqueId);
                });
            };

            /* Called by $scope.createAction */
            _this._clearCourseCreateForm = function () {
                $scope.create.formData = {};
                $scope.create.scormPackages = [];
                $scope.create.documentList = [];
                $scope.create.formData.courseList = [];
                $scope.create.formData.displayStart = new Date();

                // angular hack to make form on detail page validate on page load
                $scope.create.formData.passingScore = null;

                /* This will get set once because we load sysSettings in app.run
                 * make sure we default make enrollment attempts to the system setting */
                $rootScope.$watch('sysSettings', function (sysSettings) {
                    if (typeof sysSettings.enrollmentAttempts !== 'undefined') {
                        if (sysSettings.enrollmentAttempts === 0) {
                            $scope.create.formData.maxEnrollmentAttempts = null;
                            $scope.create.maxEnrollmentAttemptsIsUnlimited = true;
                        } else {
                            $scope.create.formData.maxEnrollmentAttempts = sysSettings.enrollmentAttempts;
                            $scope.create.maxEnrollmentAttemptsIsUnlimited = false;
                        }
                    }
                });

                $scope.create.enrollmentAttemptsRegex = /^\d+$/;
            };

            _this._showCreateError = function (serverErrorResponse) {
                var errorCode = serverErrorResponse.StatusCode;
                var message = serverErrorResponse.ErrorMessage;
                $scope.create.error.setError(errorCode, message);
            };

            _this._showWizardSuccessScreen = function () {
                $scope.create.isLoading = false;

                $scope.create.error.reset();

                jQuery('#courseCreateSuccessModal').modal();

                _this._clearCourseCreateForm();
            };

            _this._showWizardErrorScreen = function (serverErrorResponse) {
                $scope.create.isLoading = false;
                _this._showCreateError(serverErrorResponse);
            };

            /* Used on course group detail page
             * when the user presses enter on the input field
             * adds an extra course to the course groups list */
            $scope.create.addCourseToCourseGroupList = function () {
                var course = _.filter(_this.allCoursesData, function (course) {
                    return course.name === $scope.create.selectedCourse;
                });

                if (course.length > 0) {
                    $scope.create.selectedCourse = '';

                    course = course[0];
                    var alreadyExists = _.filter($scope.create.formData.courseList, function (_course) {
                        return (course.id === _course.id);
                    });

                    if (alreadyExists.length > 0) {
                        return;
                    }

                    course.uniqueId = Date.now();

                    $scope.create.formData.courseList.push(course);
                }
            };

            /* Called when you press the X button on the gray box
             * in the course group list */
            $scope.create.removeCourseFromCourseGroupList = function (courseToRemove) {
                $scope.create.formData.courseList = _.filter($scope.create.formData.courseList, function (course) {
                    return (course.uniqueId !== courseToRemove.uniqueId);
                });
            };

            // Used on the common properties pagge
            $scope.create.createCourse = function () {
                $scope.create.isLoading = true;
                $scope.create.error.reset();

                var newCourse = CoursesService.createCourse($scope.create.formData);
                newCourse.then(function (success) {
                    $scope.create.isLoading = false;
                    $scope.create.formData = success;
                    WizardHandler.wizard('Assignment').next();

                    /* Create these here so angular can validate
                     * forms on step3 */
                    if ($scope.create.formData.type === 'courseGroup') {
                        $scope.create.formData.courseList = [];
                    }
                    if ($scope.create.formData.type === 'wbt') {
                        $scope.create.formData.score = null;
                    }
                }, function (error) {
                    $scope.create.isLoading = false;
                    _this._showCreateError(error);
                });
            };

            // Only used on the details page
            $scope.create.addExtraCourseProperties = function () {
                // create the course object
                // var courseObj = {};

                $scope.create.isLoading = true;

                $scope.create.error.reset();
                var updatedCourse = CoursesService.editCourse($scope.create.formData);
                updatedCourse.then(function (success) {
                    var filesToUpload = _this._attachFiles($scope.create.formData.id, $scope.create.currentAssignmentType, $scope.create.scormPackages, $scope.create.documentList);

                    filesToUpload.then(function (success) {
                        _this._showWizardSuccessScreen();
                    }, function (error) {
                        _this._showWizardErrorScreen(error);
                    });
                }, function (error) {
                    _this._showWizardErrorScreen(error);
                });
            };

            $scope.createAction = function () {
                $scope.homeTemplate = 'app/templates/assignments/create/admin.html';
                $scope.wizardTitle = "Add New Assignment";

                _this._clearCourseCreateForm();

                _this.baiCatalogCta();
                _this.customCatalogCta();
            };

        };
    }
]);
