/* This is a mixin to contain any function
 * that maps any type string from the database
 * To an english name
 * For instance, mapping assignment types like 'wbt' to 'Web Based'
 */

function TypeMapsMixin() {}

TypeMapsMixin.prototype.getFullAssignmentTypeName = function(type) {
    // Maps an assignment.status field to an english name
    // See any page that uses a Course Type for an example
    // Ex: The assignments list page /lms-web/users/list/
    var types = {
        'assessment': 'Assessment',
        'survey': 'Survey',
        'evaluation': 'Evaluation',
        'ext': 'External',
        'ilt': 'Instructor Led Training',
        'task': 'Task',
        'vle': 'Video Learning Elective',
        'wbt': 'Web Based',
        'courseGroup': 'Course Group',
        'courseGroupCurricula': 'Course Group Curricula',
        'courseGroupCertification': 'Course Group Certification'
    };

    return types[type];
};

app.factory('TypeMapsMixin', [function() {
    return new TypeMapsMixin();
}]);
