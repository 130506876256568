app.service('TranscriptsService', ['$http', '$q', 'courseFormatterUtil', 'dateUtil', 'SettingsService', 'UsersService', 
    function ($http, $q, courseFormatterUtil, dateUtil, SettingsService, UsersService) {
        var _this = this;

        _this.response = null;

        _this.fetch = function(userId) {
            var req = {
                method: 'GET',
                url: 'api/transcript/' + userId + '/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function(success) {
                _this.response = _this.format(success.data);

                return _this.response;
            }, function(failure) {
                throw failure;
            });
        };
        
        _this.rangeFetch = function(userId, startDate, endDate) {
            var url = 'api/transcript/' + userId + '/';
            if(startDate !== null && endDate !== null) {
                url += startDate + '/' + endDate + '/';
            }

            var req = {
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function(success) {
                _this.response = _this.format(success.data);

                return _this.response;
            }, function(failure) {
                throw failure;
            });
        };

        _this.create = function(data) {
            // If user is adding transcript for himself then data.userId will be null
            // if user(manager) is adding a transcript for a student then data.userId will not be null.
            var req = {};
            if (data.userId !== null && data.userId !== undefined) {
                req = {
                    method: 'POST',
                    data: _this.formatToSendForCreate(data),
                    url: 'api/transcript/user/' + data.userId + '/',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: window.timeout
                };
            } else {
                req = {
                    method: 'POST',
                    data: _this.formatToSendForCreate(data),
                    url: 'api/transcript/',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: window.timeout
                };
            }
            
            return $http(req).then(function(success) {
                _this.response = success.data;

                return _this.response;
            }, function(failure) {
                throw failure;
            });
        };

        _this.batchCreate = function (data) {
            
            var req = {
                method: 'PUT',
                url: 'api/transcript/batchadd/',
                timeout: 300000,
                data: _this.formatToSendForCreate(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            return $http(req).then(function (success) {
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.formatToSendForCreate = function(data) {

            if (data.constructor !== Array) {
                for (var node in data) {
                    if (node === "duration") {
                        if (data[node] !== null) {
                            data[node] = data[node] / 60; // Convert duration from minutes to hours for API, if it has a value.
                        }
                        break;
                    }
                }
            }

            if (data.constructor === Array) {
                _.each(data, function(dataPoint) {
                    _this.formatToSendForCreate(dataPoint);
                });
            }

            return data;
        };

        /**
         * Format all data that comes in
         *
         * Currently, nothing needs to be formatted immediately on response
         */
        _this.format = function (response) {
            
            var dates = ['assignedOn', 'completedDate', 'dueDate', 'startDate', 'enrolledDate'];

            var current = null;
            var maxEAA = null;
            if (UsersService.isSaba()) { maxEAA = parseInt(SettingsService.maxEnrollmentAttemptsAllowed, 10); }

            if (response !== null) {
                for (var assignment in response) {
                    current = response[assignment];

                    // calc and add enrollmentAttemptsAllowed and enrollmentAttemptsRemaining properties to each assignment
                    if (!UsersService.isSaba()) { maxEAA = current.allowedEnrollments; }
                    if (maxEAA > 0) {
                        current.enrollmentAttemptsAllowed = maxEAA;
                        current.enrollmentAttemptsRemaining = (maxEAA - current.totalEnrollmentAttempts) > 0 ? maxEAA - current.totalEnrollmentAttempts : 0;
                    }
                    else {
                        current.enrollmentAttemptsAllowed = -1;
                        current.enrollmentAttemptsRemaining = -1;
                    }
                    for (var node in current) {
                        if (current[node] !== null && $.inArray(node, dates) !== -1) { current[node] = dateUtil.formatDate(current[node]);
                        }
                    }
                }

                // Sort the transcripts in descending order
                var compare = function (a, b) {
                    // completedDate.original is a string 
                    // "2016-06-27T15:38:52.4-05:00"
                    // and javascript > <'s can
                    // be used to compare datestrings.
                    if (a.completedDate.original < b.completedDate.original) return 1;
                    if (a.completedDate.original > b.completedDate.original) return -1;
                    return 0;
                };

                response = response.sort(compare);

                // Detect which completed transcripts are editable.
                // Only the last updated transcript for each course is editable
                // At this point the transcripts are sorted by completedDate descending so the first one for each course should be marked editable
                var uniqueIds = [];
                for (var i = 0; i < response.length; i++) {
                    if (response[i].status === "successful" || response[i].status === "unsuccessful") {
                        if ($.inArray(response[i].id, uniqueIds) === -1) {
                            response[i].editable = true;
                            uniqueIds.push(response[i].id);
                        }
                    }
                    if (response[i].isNMLS) {
                        response[i].editable = false;
                    }
                }
            }

            return response;
        };

        _this.getSingleTranscript = function (userId, id) {
            var req = {
                method: 'GET',
                url: 'api/transcript/' + userId + '/' + id,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.format([success.data])[0];

                _this.response.codeForImage = courseFormatterUtil.getCourseCodeForImage(_this.response.code, _this.response.tileImage);

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /* Format a single transcript that goes out
         */

        _this.formatToSend = function (data) {
            var dates = ['assignedOn', 'dueDate', 'endDateTime', 'startDate', 'startDateTime', 'enrolledDate', 'completedDate'];

            if (data.constructor !== Array) {
                for (var node in data) {

                    if (typeof data[node] !== 'undefined' && data[node] !== null && typeof data[node] === 'object' && $.inArray(node, dates) !== -1) {
                        if (typeof data[node].moment !== "undefined" && _.isObject(data[node].moment)) {
                            data[node] = data[node].moment.toISOString();
                        } else {
                            data[node] = null;
                        }
                    }

                    if (node === "duration") {
                        if (data[node] !== null) {
                            data[node] = data[node] / 60; // Convert duration from minutes to hours for API, if it has a value.
                        }
                    }
                }
            }

            if (data.constructor === Array) {
                _.each(data, function (dataPoint) {
                    _this.formatToSend(dataPoint);
                });
            }

            return data;
        };

        _this.updateTranscript = function(assignment) {
            var req = {
                method: 'PUT',
                url: 'api/transcript/user/' + assignment.studentId + '/',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: _this.formatToSend(assignment),
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };
		
		
        _this.updateSingle = function (stuId, assignment) {
            var req = {
                method: 'PUT',
                url: 'api/transcript/user/' + stuId + '/',
                data: _this.formatToSend(assignment),
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                //return success.data;
            }, function (failure) {
                throw failure;
            });
		};

        _this.fetchCompletionCounts = function (userId) {

            var req = {
                method: 'GET',
                url: 'api/transcript/completioncounts/' + userId + '/',
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });

        };

        _this.fetchLatest = function (userId) {
            var req = {
                method: 'GET',
                url: 'api/transcript/Latest/' + userId + '/',
                timeout: window.timeout
            };
            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });

        };

}]);
