(function() {
    app.directive('baiCatalogSearchResults', [
        function() {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/catalog/baiCatalogSearchResults.html',
                scope: {},
                controller: ['$scope', '$route', '$attrs', '$location', '$rootScope', '$q', 'SearchServices', 'CatalogSearchParameters', 'errorDisplayerUtil', 'courseFormatterUtil', 'BreadcrumbsService', 'PathFormatter', 'CategoriesService', 'PlaylistServices', 'MyDocumentsService', CatalogSearchController]
            };
        }
    ]);

    function CatalogSearchController($scope, $route, $attrs, $location, $rootScope, $q, SearchServices, CatalogSearchParameters, errorDisplayerUtil, courseFormatterUtil, BreadcrumbsService, PathFormatter, CategoriesService, PlaylistServices, MyDocumentsService) {
        $scope.searchResults = { results: [], count: 0, facets: [] };
        $scope.searchResultError = errorDisplayerUtil.create();
        $scope.viewAll = viewAll;
        $scope.clearAll = clearAll;
        $scope.selectFacetFilter = selectFacetFilter;
        $scope.selectFacetFilterAnd = selectFacetFilterAnd;
        $scope.expandCollapseFacet = expandCollapseFacet;
        $scope.selectProduct = selectProduct;
        $scope.selectedFacetValues = [];
        $scope.pagination = { page: 0, itemsPerPage: CatalogSearchParameters.getSearchParameters().top, maxPagesDisplayed: 10, nextPage: nextPage };
        $scope.fromWeb = $rootScope.fromWeb;
        if ($scope.fromWeb === true) {
            $rootScope.metaTags.title = "BAI Training Catalog Search, Compliance, Learning Management, Professional Development Financial Services";
            $rootScope.metaTags.description = "BAI gives financial services leaders confidence in managing compliance and a passion for professional development by providing powerful tools and subject matter expertise you can rely on. Search our courseware catalog!";
            $rootScope.metaTags.keywords = "BSA, AML, eLearning, Role-based, Microlearning, Regulation-based, FDIC, CFPB, Banking, Credit Union, Mortgage, Fintech, Regtech, HMDA, OFAC, TILA";
        }
        $scope.showCatalogDownloadLinks = false;

        $scope.catalogType = typeof $attrs.catalogtype !== 'undefined' ? $attrs.catalogtype : 'Libraries';

        var maxFacetValuesDisplayed = 5;
        var constantViewAllText = "View All";
        var constantViewLessText = "View Less";
        var routeParams = $route.current.params.param;
        var resultsToken = $location.$$search.token;
        $scope.catalog = {};
        $scope.catalog.name = $scope.catalogType;
        $scope.catalog.path = { pipeDelimited: "", params: "" };
        $scope.catalog.error = errorDisplayerUtil.create();
        $scope.catalog.baseRoute = fromWeb ? "browse" : "catalog";
        if ($scope.catalogType === "Documents") { $scope.catalog.baseRoute = "documents-catalog";}
        $scope.shareLink = "";
        var currentPlaylistCourseIds = [];
        var currentMyDocumentsCourseIds = [];
        var needPlaylistRefresh = true;
        var needMyDocumentsRefresh = true;

        $scope.enablePlaylistRefresh = function () {
            needPlaylistRefresh = true;
        };

        $scope.enableMyDocumentsRefresh = function () {
            needMyDocumentsRefresh = true;
        };

        function getShareLink() {
            var result = "";
            var shareResults = {};
            shareResults.searchTerm = CatalogSearchParameters.getSearchParameters().searchTerm;
            shareResults.filterText = CatalogSearchParameters.getSearchParameters().filter;
            shareResults.filters = "";
            shareResults.filters = JSON.stringify(getFilterList());
            var token = btoa(JSON.stringify(shareResults));
            result = $location.$$absUrl.split("?")[0] + "?token=" + token;
            function getFilterList() {
                var filterList = [];
                var i = 0;
                for (i = 0; i < $scope.selectedFacetValues.length; i++) {
                    var currentIndex = _.findIndex(filterList, { name: $scope.selectedFacetValues[i].name });
                    if (currentIndex === -1) {
                        filterList.push({ name: $scope.selectedFacetValues[i].name, values: [] });
                        filterList[filterList.length - 1].values.push($scope.selectedFacetValues[i].filterValue);
                    } else {
                        filterList[currentIndex].values.push($scope.selectedFacetValues[i].filterValue);
                    }
                }
                return filterList;
            }

            return result;
        }

        var setBreadcrumbsAndFilter = function (path) {
            CatalogSearchParameters.browseFilter = createFilter(path.pipeDelimited);
            CatalogSearchParameters.addQueryText("");
            if ($scope.fromWeb === true) {
                CatalogSearchParameters.clearBreadcrumbs();
                CatalogSearchParameters.breadcrumbs.push({ name: "Home", link: "/" });
                CatalogSearchParameters.breadcrumbs.push({ name: "Libraries", link: $scope.catalog.baseRoute });
                CatalogSearchParameters.breadcrumbs = CatalogSearchParameters.breadcrumbs.concat(BreadcrumbsService.createBreadcrumbs(path.pipeDelimited, $scope.fromWeb, $scope.catalog.baseRoute));
            } else {
                CatalogSearchParameters.breadcrumbs = BreadcrumbsService.createBreadcrumbs(path.pipeDelimited, $scope.fromWeb, $scope.catalog.baseRoute, $scope.catalogType);
            }
            CatalogSearchParameters.setPage(1);
            CatalogSearchParameters.fromBrowse = true;
            CatalogSearchParameters.catalogPDFName = path.params;
        };

        function createFilter(path) {
            var filters = [];
            var filter = {};
            filter.name = "Paths";
            filter.showFacet = false;
            filter.values = [];
            var value = {};
            value.value = path;
            value.selected = true;
            filter.values.push(value);
            filters.push(filter);
            if (CatalogSearchParameters.fromBrowse) {
                var typeFilter = {};
                typeFilter.name = "ProductType";
                typeFilter.showFacet = false;
                typeFilter.values = [];
                typeFilter.isString = true;
                var typeValue = {};
                typeValue.value = "Document";
                typeValue.selected = true;
                typeFilter.negate = false;
                if ($scope.catalogType !== "Documents")
                {
                    typeFilter.negate = true;
                }
                typeFilter.values.push(typeValue);
                filters.push(typeFilter);
            }
            return filters;
        }

        

        var path = {};
        
        if (noResultsTokenOrCatalogParams()) {
            activate();
        } else if (resultsTokenNoCatalogParams()) {
            parseToken();
            activate();
        } else if (noResultsTokenCatalogParams()) {
            parseCatalogPath().then(function(success) {
                activate();
            });
        } else if (resultsTokenAndCatalogParams()) {
            parseCatalogPath().then(function (success) {
                parseToken();
                activate();
            });
        }

        function noResultsTokenOrCatalogParams() {
            return resultsToken === undefined && routeParams === undefined;
        }

        function resultsTokenNoCatalogParams() {
            return resultsToken !== undefined && routeParams === undefined;
        }

        function noResultsTokenCatalogParams() {
            return resultsToken === undefined && routeParams !== undefined;
        }

        function resultsTokenAndCatalogParams() {
            return resultsToken !== undefined && routeParams !== undefined;
        }

        function parseToken() {
            try {
                var parsed = JSON.parse(atob(resultsToken));

                parsed.filters = JSON.parse(parsed.filters);
                var i = 0;
                var j = 0;
                for (i = 0; i < parsed.filters.length; i++) {
                    for (j = 0; j < parsed.filters[i].values.length; j++) {
                        if (parsed.filters[i].name === "Paths" && CatalogSearchParameters.fromBrowse) {
                            continue;
                        }
                        //if (parsed.filters[i].name !== "Paths") {
                            $scope.selectedFacetValues.push({ name: parsed.filters[i].name, filterValue: parsed.filters[i].values[j] });
                       // }
                    }
                }
                CatalogSearchParameters.addQueryText(parsed.searchTerm);
                CatalogSearchParameters.setFilters(parsed.filterText);
                CatalogSearchParameters.setPage(1);
                $location.$$search = {};
            }
            catch(e) {
                $scope.isLoading = false;
                console.log(e);
            }
        }

        function parseCatalogPath() {
            $scope.isLoading = true;
            $scope.searchResultError.reset();
            var catalog = null;
            if ($scope.catalogType === "Documents") {
                catalog = SearchServices.getDocumentCatalog();
            }
            else { catalog = SearchServices.getCatalog(); }
            return catalog.then(function (success) {
                _.forEach(success, function (c) {
                    var formattedParams = PathFormatter.formatPathParams("", c.value);
                    formattedParams = formattedParams.replace(/\|/g, '/');
                    if (formattedParams === routeParams) {
                        path.params = routeParams;
                        path.pipeDelimited = c.value;
                        setBreadcrumbsAndFilter(path);
                    }
                });
            }, function (failure) {
                $scope.isLoading = false;
                console.log("Failed", failure);
                $scope.searchResultError.setError(failure.StatusCode, failure.ErrorMessage);
            });
        }

        function viewAll(index) {
            var i = 0;

            if ($scope.searchResults.facets[index].viewAllText === constantViewAllText) {
                for (i = 0; i < $scope.searchResults.facets[index].values.length; i++) {
                    $scope.searchResults.facets[index].values[i].hidden = false;
                }
                $scope.searchResults.facets[index].viewAllText = constantViewLessText;
            } else {
                for (i = 0; i < $scope.searchResults.facets[index].values.length; i++) {
                    if (i < maxFacetValuesDisplayed) {
                        $scope.searchResults.facets[index].values[i].hidden = false;
                    } else {
                        $scope.searchResults.facets[index].values[i].hidden = true;
                    }
                }
                $scope.searchResults.facets[index].viewAllText = constantViewAllText;
            }
        }

        function clearAll() {
            $scope.selectedFacetValues = [];
            CatalogSearchParameters.clearFilters();
            if (CatalogSearchParameters.fromBrowse) {
                addBrowseFilter();
            }
            $scope.pagination.page = 0;
            CatalogSearchParameters.setPage(1);
            activate();
        }

        function expandCollapseFacet(index) {
            var i = 0;
            $scope.searchResults.facets[index].isCollapsed = !$scope.searchResults.facets[index].isCollapsed;
            $("#facetSection-" + index).slideToggle("fast");
        }


        function activate() {
            if (shouldSearch()) {
                $scope.isLoading = true;
                $scope.searchResultError.reset();
                $scope.searchParameters = CatalogSearchParameters.getSearchParameters();
                if (CatalogSearchParameters.fromBrowse && $scope.selectedFacetValues.length === 0) {
                    addBrowseFilter();
                    if (CatalogSearchParameters.browseFilter.length > 0 && CatalogSearchParameters.browseFilter[0].name === 'Paths' && CatalogSearchParameters.browseFilter[0].values[0].value.indexOf('|') < 0 && CatalogSearchParameters.browseFilter[0].values[0].value.indexOf('Custom') < 0 && CatalogSearchParameters.browseFilter[0].values[0].value.indexOf('NMLS') < 0 && routeParams !== undefined && $scope.catalogType !== "Documents") {
                        $scope.showCatalogDownloadLinks = true;
                        GetPDFLinkValues();
                    }
                } else {
                    $scope.showCatalogDownloadLinks = false;
                }
               
                var promises = [];
                promises.push(SearchServices.executeProductSearch(CatalogSearchParameters.getSearchParameters()));

                var playlistPromisePosition = null;
                if (needPlaylistRefresh === true && !$scope.fromWeb) {
                    promises.push(PlaylistServices.getPlaylist());
                    playlistPromisePosition = promises.length - 1;
                }

                var myDocumentsPromisePosition = null;
                if (needMyDocumentsRefresh === true && !$scope.fromWeb) {
                    promises.push(MyDocumentsService.getAll());
                    myDocumentsPromisePosition = promises.length - 1;
                }
               
                $q.all(promises).then(function (allResult) {
                    var success = allResult[0];
                    $scope.searchResults.results = success.results;
                    $scope.searchResults.count = success.count;

                    if (needPlaylistRefresh === true && !$scope.fromWeb) {
                        currentPlaylistCourseIds = allResult[playlistPromisePosition];
                        needPlaylistRefresh = false;
                    }

                    setPlaylistCourses();

                    if (needMyDocumentsRefresh === true && !$scope.fromWeb) {
                        currentMyDocumentsCourseIds = allResult[myDocumentsPromisePosition];
                        needMyDocumentsRefresh = false;
                    }

                    setMyDocumentsCourses();

                    var libraries = [];
                    success.facets.Paths.forEach(function (item) {
                        /* libraries should be the top node with no pipe */
                        if (item.value.indexOf('|') < 0) {
                            libraries.push(item);
                        }
                    });
                    var hideLibraries = (CatalogSearchParameters.fromBrowse || !$scope.fromWeb);

                    addFacet("Libraries", "Paths", libraries, hideLibraries);
                    addFacet("Roles", "Roles", success.facets.Roles);
                    addFacet("Skills", "Skills", success.facets.Skills);
                    addFacet("Topics", "Topics", success.facets.Topics);
                    addFacet("Expected Duration", "DurationRange", success.facets.DurationRange);
                    addFacet("Interactivity", "InteractivityTypes", success.facets.InteractivityTypes);
                    addFacet("Content Type", "CourseType", success.facets.CourseType, $scope.fromWeb);
                    addFacet("Product Type", "ProductType", success.facets.ProductType);
                    addFacet("CE", "CEAccreditationOrg", success.facets.CEAccreditationOrg);
                    BreadcrumbsService.crumbs = CatalogSearchParameters.breadcrumbs;
                    $scope.shareLink = getShareLink();
                    $scope.isLoading = false;
                }, function (failure) {
                   console.log("failed");
                    $scope.isLoading = false;
                    $scope.searchResultError.setError(failure.StatusCode, failure.ErrorMessage);
                });
            } else {
                $scope.isLoading = false;
            }
        }

        function setPlaylistCourses() {
            var i = 0;
            _.each($scope.searchResults.results, function (r) {
                r.showPlaylistHeart = !$scope.fromWeb && r.ProductType !== "Document";
                if (r.CourseType === "Course Group" || r.CourseType === "Course Group Curricula" || r.CourseType === "Course Group Certification" || r.CourseType === "Career Path") {
                    r.showPlaylistHeart = false;
                }

                r.isInPlaylist = false;
                for (i = 0; i < currentPlaylistCourseIds.length; i++) {
                    if (currentPlaylistCourseIds[i].toString() === r.CourseId) {
                        r.isInPlaylist = true;
                        break;
                    }
                }
            });
        }

        function setMyDocumentsCourses() {
            var i = 0;
            _.each($scope.searchResults.results, function (r) {
                r.showMyDocumentsHeart = !$scope.fromWeb && r.ProductType === "Document";
                r.isInMyDocuments = false;
                for (i = 0; i < currentMyDocumentsCourseIds.length; i++) {
                    if (currentMyDocumentsCourseIds[i].courseID.toString() === r.CourseId || (currentMyDocumentsCourseIds[i].parentBAICourseID !== null && currentMyDocumentsCourseIds[i].parentBAICourseID.toString() === r.CourseId)) {
                        r.isInMyDocuments = true;
                        break;
                    }
                }
            });
        }

        function shouldSearch() {
            var minimumSearchCharactersWithoutQuotes = 3;
            return CatalogSearchParameters.getSearchParameters().searchTerm.length >= minimumSearchCharactersWithoutQuotes ||
                CatalogSearchParameters.fromBrowse;
        }

        function addFacet(label, name, values, hidden) {
            appendPreviouslySelectedValues(values, name);
            values = sortValues(values, name);
            var existingFacetIndex = _.findIndex($scope.searchResults.facets, { "name": name });
            var facetValues = getFacetValuesFor(values, name, existingFacetIndex);

            if (existingFacetIndex === -1) {
                var newFacet = createDefaultFacet(name, label, facetValues);
                if (hidden) {
                    newFacet.showFacet = false;
                }
                else {
                    newFacet.showFacet = showFacet(newFacet);
                }
                newFacet.selectedCount = _.where($scope.selectedFacetValues, { "name": name }).length;
                if (newFacet.values.length > maxFacetValuesDisplayed) {
                    newFacet.showViewAll = true;
                    newFacet.viewAllText = constantViewAllText;
                }
                $scope.searchResults.facets.push(newFacet);
                var newFacetIndex = $scope.searchResults.facets.length - 1;
                if ($scope.searchResults.facets[newFacetIndex].selectedCount > 0 && name === 'Paths') {
                    console.log("in paths if");
                    $scope.searchResults.facets[newFacetIndex].values.forEach(function (val) {
                        if (!val.selected) {
                            val.hidden = true;
                        }
                    });
                }
            } else {
                $scope.searchResults.facets[existingFacetIndex].values = facetValues;

                if ($scope.searchResults.facets[existingFacetIndex].values.length <= maxFacetValuesDisplayed) {
                    $scope.searchResults.facets[existingFacetIndex].showViewAll = false;
                } else {
                    $scope.searchResults.facets[existingFacetIndex].showViewAll = true;
                }
                if (hidden) {
                    $scope.searchResults.facets[existingFacetIndex].showFacet = false;
                }
                else {
                    $scope.searchResults.facets[existingFacetIndex].showFacet = showFacet($scope.searchResults.facets[existingFacetIndex]);
                }
                $scope.searchResults.facets[existingFacetIndex].selectedCount = _.where($scope.selectedFacetValues, { "name": name }).length;

                /* For libraries only, if a library is selected then hide the other libraries */
                if ($scope.searchResults.facets[existingFacetIndex].selectedCount > 0 && name === 'Paths') {
                    $scope.searchResults.facets[existingFacetIndex].values.forEach(function (val) {
                        if (!val.selected) {
                            val.hidden = true;
                        }
                    });
                }
            }
        }

        function appendPreviouslySelectedValues(values, name) {
            var i = 0;
            var subSelectedFacetFilters = _.where($scope.selectedFacetValues, { "name": name });
            for (i = 0; i < subSelectedFacetFilters.length; i++) {
                var selectedIndex = _.findIndex(values, { "value": subSelectedFacetFilters[i].filterValue });
                if (selectedIndex === -1) {
                    values.push({
                        count: 0,
                        value: subSelectedFacetFilters[i].filterValue,
                        selected: true,
                        hidden: false,
                        disabled: false
                    });
                }
            }
        }

        function sortValues(values, name) {
            if (name === 'DurationRange') {
                var durationRangeOrdered = [];
                var shortIndex = _.findIndex(values, { value: '< 15 mins' });
                var mediumIndex = _.findIndex(values, { value: '15 mins - 30 mins' });
                var longIndex = _.findIndex(values, { value: '> 30 mins' });
                if (shortIndex > -1) {
                    durationRangeOrdered.push(values[shortIndex]);
                }
                if (mediumIndex > -1) {
                    durationRangeOrdered.push(values[mediumIndex]);
                }
                if (longIndex > -1) {
                    durationRangeOrdered.push(values[longIndex]);
                }
                return durationRangeOrdered;
            } else {
                return _.sortBy(values, 'value');
            }
        }

        function getFacetValuesFor(values, name, existingFacetIndex) {
            var facetValues = [];
            var i = 0;
            for (i = 0; i < values.length; i++) {
                if (isValidFacetValue(values[i].value) === true) {
                    values[i].selected = selectFacetValue(values, i, name);
                    values[i].hidden = hideFacetValue(existingFacetIndex, i);
                    facetValues.push(values[i]);
                }
            }
            return facetValues;
        }

        function createDefaultFacet(name, label, facetValues) {
            return {
                name: name,
                label: label,
                values: facetValues,
                showViewAll: false,
                isString: name === 'ProductType' || name === 'DurationRange' || name === 'CourseType' ? true : false,
                showFacet: false,
                isCollapsed: false,
                selectedCount: 0
            };
        }

        function showFacet(facet) {
            if (facet.values.length > 0) {
                return true;
            } else {
                return false;
            }
        }

        function isValidFacetValue(value) {
            return value !== null && value !== "";
        }

        function selectFacetValue(values, i, name) {
            if (_.findIndex($scope.selectedFacetValues, { "name": name, "filterValue": values[i].value }) !== -1) {
                return true;
            } else {
                return false;
            }
        }

        function hideFacetValue(existingFacetIndex, i) {
            if ((i >= maxFacetValuesDisplayed && existingFacetIndex === -1) ||
                        (i >= maxFacetValuesDisplayed && $scope.searchResults.facets[existingFacetIndex].viewAllText === constantViewAllText)) {
                return true;
            } else {
                return false;
            }
        }

        function selectFacetFilter(facetName, filterValue, facetIndex, filterIndex) {
            var chosenFilter = $scope.searchResults.facets[facetIndex].values[filterIndex];
            var existingIndex = _.findIndex($scope.selectedFacetValues, { "name": facetName, "filterValue": chosenFilter.value });
            if (chosenFilter.selected === true) {
                if (existingIndex === -1) {
                    $scope.selectedFacetValues.push({ "name": facetName, "filterValue": chosenFilter.value });
                }
            } else {
                if (existingIndex !== -1) {
                    $scope.selectedFacetValues.splice(existingIndex, 1);
                }
            }
            CatalogSearchParameters.addFacetFilters($scope.searchResults.facets);

            $scope.pagination.page = 0;
            CatalogSearchParameters.setPage(1);
            activate();
        }

        function selectFacetFilterAnd(facetName, filterValue, facetIndex, filterIndex) {
            var chosenFilter = $scope.searchResults.facets[facetIndex].values[filterIndex];
            var existingIndex = _.findIndex($scope.selectedFacetValues, { "name": facetName, "filterValue": chosenFilter.value });
            if (chosenFilter.selected === true) {
                if (existingIndex === -1) {
                    $scope.selectedFacetValues.push({ "name": facetName, "filterValue": chosenFilter.value });
                }
            } else {
                if (existingIndex !== -1) {
                    $scope.selectedFacetValues.splice(existingIndex, 1);
                }
            }
            CatalogSearchParameters.addFacetFiltersAnd($scope.searchResults.facets);

            $scope.pagination.page = 0;
            CatalogSearchParameters.setPage(1);
            activate();
        }

        function nextPage() {
            CatalogSearchParameters.setPage($scope.pagination.page);
            activate();
        }

        function addBrowseFilter() {
            var browseFilter = CatalogSearchParameters.browseFilter[0];
            $scope.selectedFacetValues.push({ "name": browseFilter.name, "filterValue": browseFilter.values[0].value });
            CatalogSearchParameters.addFacetFilters(CatalogSearchParameters.browseFilter);
        }

        function selectProduct(product) {
            CatalogSearchParameters.productBreadcrumbs = [];
            var backCrumbLink = $scope.fromWeb === false ? "catalog/search" : "search";
            if (path.params !== undefined) {
                backCrumbLink = backCrumbLink + "/" + path.params;
            }
            if ($scope.fromWeb === true) {
                var homeCrumb = { name: "Home", link: "/" };
                CatalogSearchParameters.productBreadcrumbs.push(homeCrumb);
            }
            var backCrumb = { name: "Return to Results", link: backCrumbLink };
            var productNameCrumb = { name: product.Name, link: "" };

            CatalogSearchParameters.productBreadcrumbs.push(backCrumb);
            CatalogSearchParameters.productBreadcrumbs.push(productNameCrumb);
        }

        $scope.downloadCatalogId = "";
        function GetPDFLinkValues() {
            var categories = CategoriesService.fetch(null, false, false);
            categories.then(function (success) {

                var results = _.filter(success, function (catalog) {return catalog.name === path.pipeDelimited;});
                if (results.length > 0) {
                    $scope.downloadCatalogId = results[0].id;

                } else {
                    $scope.showCatalogDownloadLinks = false;
                }

            }, function (failure) {
                $scope.showCatalogDownloadLinks = false;
            });

        }
    }

})();