app.service('RosterService', ['$http', 'dateUtil', 'errorDisplayerUtil', 'sessionFormatterUtil', function ($http, dateUtil, errorDisplayerUtil, sessionFormatterUtil) {
    var _this = this;

    _this.response = null;
    _this.attendingStudents = null;
    _this.waitlistStudents = null;
    _this.pendingApprovalStudents = null;

    /**
     * HTTP requests section
     */
    /**
     * Returns you a promise which will contain the single offering data from the GET call.
     *
     * @param offeringId id of the single offering we are going to
     * @returns {*} promise for the single offering GET
     */
    _this.fetch = function (offeringId, type) {
        var req = {
            method: 'GET',
            url: 'api/roster/' + offeringId + '/' + type,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {

        });
    };

    /* Format a single transcript that goes out */

    _this.formatToSend = function (data) {
        var dates = ['completionDate'];

        if (data.constructor !== Array) {
            for (var node in data) {
                if (typeof data[node] !== 'undefined' && data[node] !== null && typeof data[node] === 'object' && $.inArray(node, dates) !== -1) {
                    data[node] = dateUtil.formatDate(data[node].jsDate).utcDate;
                }
            }
        }

        if (data.constructor === Array) {
            _.each(data, function (dataPoint) {
                _this.formatToSend(dataPoint);
            });
        }

        return data;
    };

    /**
     * api/roster/updatestatus/{studentId}/{offeringId}/{registrationStatus}
     *
     * Update the roster on the OFFERING level
     *
     * @param studentId
     * @param offeringId
     * @param registrationStatus
     * @param data
     * @returns {*}
     */
    _this.updateSinglePersonOnRoster = function(studentId, offeringId, registrationStatus, offeringType, data) {
        var req = {
            method: 'PUT',
            url: 'api/roster/updatestatus/' + studentId + '/' + offeringId + '/' + registrationStatus + '/' + offeringType,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        if(typeof data !== "undefined" && data !== null) {
            req.data = _this.formatToSend(data);
        }

        return $http(req);
    };

    _this.substituteUser = function(oldUser, newUser, offeringId, offeringType) {
        var req = {
            method: 'PUT',
            url: 'api/roster/substitute/' + offeringId + '/' + oldUser + '/' + newUser + '/' + offeringType,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req);
    };

    /**
     * Update the roster on the SESSION level
     *
     * @TODO: Should this be moved into the user or the session service?
     *
     * @param studentId
     * @param offeringId
     * @param data
     * @returns {*}
     */
    _this.updateSessionByUserOnOffering = function(studentId, offeringId, data) {
        var req = {
            method: 'PUT',
            data: data,
            url: 'api/roster/updatesession/' + studentId + '/' + offeringId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req);
    };

    _this.format = function(response) {
        if(response !== null) {
            var formattableDates = ['completionDate', 'registrationDate'];
            var member, student, studentSessions = null;

            for(var single in response) {
                if(response[single].length > 0) {
                    member = response[single];

                    for(var memNode in member) {
                        /**
                         * Have to add this in for now
                         * @TODO: find out if there's a proper pattern for looping over objects
                         */
                        if(typeof member[memNode] === 'function') {
                            continue;
                        }

                        student = member[memNode];

                        for(var node in student.student) {
                            if (student.student[node] !== null && $.inArray(node, formattableDates) !== -1) {
                                student.student[node] = dateUtil.formatDate(student.student[node]);
                            }
                        }

                        // Keep track of highest session end date to pass to student completion service
                        student.highestSessionDateForOffering = null;
                        // Keep track of lowest session start date for display purposes
                        student.lowestSessionDateForOffering = null;

                        student.cancellingPerson = false;
                        student.error = errorDisplayerUtil.create();
                        student.isLoading = false;
                        student.sessionUpdateIsLoading = false;
                        student.success = false;

                        /**
                         * Due to angular's lack of reasonable dropdown logic, this has to exist. We set the
                         * statusForDropdown attribute to either default (Change Status label in the drop down), or the
                         * appropriate completion status.
                         *
                         * hasCompletedTheOffering attribute contains logic because switching between completion status and
                         * default status causes dropdowns to disappear, which we can't have.
                         */
                        student.statusForDropdown = _this.getDropDownStatus(student.student.registrationStatus);
                        student.hasCompletedTheOffering = student.statusForDropdown !== 'default';

                        student = sessionFormatterUtil.formatSessions(student);
                    }

                    _this[single] = response[single];
                }
            }

            _this.set('attendingStudents', response.attendingStudents);
            _this.set('droppedStudents', response.droppedStudents);
            _this.set('pendingApprovalStudents', response.pendingApprovalStudents);
            _this.set('waitlistStudents', response.waitlistStudents);
        }

        return response;
    };

    /**
     * Format the user objects that come back in the roster object from the offerings service.
     *
     * @TODO: migrate the offerings service's roster object to be identical to roster object from roster service
     *
     * @param response Object roster object to filter
     * @returns {*}
     */
    _this.formatFromOfferingService = function(response) {
        if(typeof response !== 'undefined' && response !== null) {
            var formattableDates = ['completionDate', 'registrationDate'];
            var member, student, studentSessions = null;

            // Loop over each roster type (attending, waitlisted, etc.)
            for(var single in response) {
                if(response[single].length > 0) {
                    member = response[single];

                    // Loop over each roster type's student
                    for(var memNode in member) {
                        /**
                         * Have to add this in for now
                         * @TODO: find out if there's a proper pattern for looping over objects
                         */
                        if(typeof member === 'function' || typeof member[memNode] === 'function' || typeof member[memNode] !== 'undefined') {
                            continue;
                        }

                        student = member[memNode];

                        for(var node in student.student) {
                            /**
                             * Have to add this in for now
                             * @TODO: find out if there's a proper pattern for looping over objects
                             */
                            if(typeof student.student[i] === 'function') {
                                continue;
                            }

                            if (student.student[node] !== null && $.inArray(node, formattableDates) !== -1) {
                                student.student[node] = dateUtil.formatDate(student.student[node]);
                            }
                        }

                        student.cancellingPerson = false;
                        student.error = errorDisplayerUtil.create();
                        student.isLoading = false;
                        student.sessionUpdateIsLoading = false;
                        student.success = false;

                        /**
                         * Due to angular's lack of reasonable dropdown logic, this has to exist. We set the
                         * statusForDropdown attribute to either default (Change Status label in the drop down), or the
                         * appropriate completion status.
                         *
                         * hasCompletedTheOffering attribute contains logic because switching between completion status and
                         * default status causes dropdowns to disappear, which we can't have.
                         */
                        student.statusForDropdown = _this.getDropDownStatus(student.registrationStatus);
                        student.hasCompletedTheOffering = student.statusForDropdown !== 'default';
                    }

                    _this[single] = response[single];
                }
            }
        } else {
            response = [];
        }

        return response;
    };

    _this.getDropDownStatus = function(status) {
        var completionStatuses = ['cancelledNoShow', 'successful', 'unsuccessful'];

        if(jQuery.inArray(status, completionStatuses) > -1) {
            return completionStatuses[jQuery.inArray(status, completionStatuses)];
        }

        return 'default';
    };

    _this.getIncompleteUserCount = function() {
        var userCount = 0;

        if(_this.attendingStudents.length === 0) {
            return userCount - 1;
        }

        for(var i in _this.attendingStudents) {
            if(_this.attendingStudents[i].completionDate === null) {
                userCount++;
            }
        }

        return userCount;
    };

    _this.incompleteUsersInAttendingRoster = function() {
        return _this.getIncompleteUserCount() === 0;
    };

    _this.get = function(key) {
        return _this[key];
    };

    _this.set = function(key, value) {
        _this[key] = value;

        return _this;
    };

    _this.setFromOfferingsService = function(key, value) {
        var data = _this.formatFromOfferingService(value);

        _this[key] = data;

        return _this;
    };

    _this.getAttendingStudents = function() {
        return _this.attendingStudents;
    };

    _this.getWaitlistedStudents = function() {
        return _this.waitlistStudents;
    };

    _this.getPendingApprovalStudents = function() {
        return _this.pendingApprovalStudents;
    };

    _this.generateMailToFromUsers = function(key) {
        var emails = [];
        var single = null;

        var students = _this.get(key);

        for(var i in students) {
            single = students[i];

            if(single.email !== null && single.email !== '') {
                emails.push(single.email);
            }
        }

        return emails;
    };

    _this.getCorrectSessionFromUser = function(personId, sessionId) {
        var attendingStudents = _this.getAttendingStudents();

        for(var student in attendingStudents) {
            if(attendingStudents[student].student.id === personId) {
                for(var session in attendingStudents[student].sessions) {
                    var curSession = attendingStudents[student].sessions[session];

                    if(curSession.id === sessionId) {
                        return curSession;
                    }
                }
            }
        }
    };

    _this.updateMultiplePersonOnRoster = function (data) {
        var req = {
            method: 'PUT',
            url: 'api/roster/updatestatus/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout,
            data: data
        };

        return $http(req).then(function (success) {
            return success.data;
        },function(failure) {
            throw failure;
        });
    };

    _this.enrollSingle = function (studentId, offeringId) {
        var req = {
            method: 'POST',
            url: 'api/roster/' + studentId + '/ilt/' + offeringId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req);
    };
}]);
