app.directive('dropdown', function() {
    return {
        restrict: 'E',
        scope: {
            'objects': '=',
            'selectedObj': '=',
            'optionalRowDisplayer': '=?',
            'optionalSelectedRowDisplayer': '=?',
            'optionalOnClick': '=?'
        },
        link: function(scope, element, attrs) {
            scope.pickerVisible = false;

            scope.togglePicker = function() {
                /* Toggle picker is the entry to the dropdown
                 * so if it's disabled only need to disable here */
                if (scope.disabled) {
                    return ;
                }
                scope.pickerVisible = !scope.pickerVisible;
            };

            scope.hidePicker = function() {
                scope.pickerVisible = false;
            };

            scope.chooseObj = function(obj) {
                scope.selectedObj = obj;
                scope.hidePicker();
                if (scope.optionalOnClick) {
                    scope.optionalOnClick(obj);
                }
            };
        },
        templateUrl: 'app/templates/components/dropdown.html'
    };
});
