(function () {
    app.directive('studentSectionSubHeader', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/studentui/studentSectionSubHeader.html',
                scope: true,
                link: studentSectionSubHeaderLink
            };
        }
    ]);

    function studentSectionSubHeaderLink(scope, element, attrs) {
        if (attrs !== undefined) {
            scope.title = attrs.title;
            console.log(scope.title);
        }
    }
})();