app.factory('Users_InstructorOverride', ['OfferingsService', 'errorDisplayerUtil', 'UsersService', function (OfferingsService, errorDisplayerUtil, UsersService) {
    return function($scope) {
        var _this = this;

        UsersService.setHomeRole();

        $scope.isFutureSelected = true;
        $scope.isPastSelected = false;

        $scope.options = {};
        $scope.options.isLoading = false;
        $scope.options.error = errorDisplayerUtil.create();

        _this.fetchInstructorOfferingsList = function (tense) {
            $scope.assignments = null;
            $scope.options.isLoading = true;

            $scope.role = 'instructor';
            $scope.filterView = 'app/templates/users/partials/filters/' + $scope.role + '.html';

            $scope.options.error.reset();

            var assignments = OfferingsService.fetchInstructorAssignments(tense);

            assignments.then(
                function (success) {
                    var data = success;

                    $scope.assignments = data;
                    $scope.options.isLoading = false; // We're no longer loading!

                    // Set for the slide down
                    $scope.responseComplete = true;
                },
                function (failure) {
                    $scope.options.isLoading = false; // We're no longer loading!

                    $scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                }
            );
        };

        $scope.homeAction = function (tense) {
            $scope.homeTemplate = 'app/templates/users/home/instructor.html';

            $scope.isLoading = true;

            $scope.orderProperty = tense === 'past' ? '-lowestSessionDateForOffering.original' : 'lowestSessionDateForOffering.original';
            $scope.role = 'instructor';

            tense = typeof tense !== 'undefined' && tense !== null ? tense : 'future';

            $scope.isFutureSelected = tense === 'future';
            $scope.isPastSelected = tense === 'past';

            _this.fetchInstructorOfferingsList(tense);
        };
    };
}]);
