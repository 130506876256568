app.controller('proxyController', ['$location', '$q', '$route', '$rootScope', '$scope', '$window', 'errorDisplayerUtil', 'ProxyService', 'MessagesService', 'UsersService', 'SettingsService', 'GroupsService', 'dateUtil',
    function ($location, $q, $route, $rootScope, $scope, $window, errorDisplayerUtil, ProxyService, MessagesService, UsersService, SettingsService, GroupsService, dateUtil) {
        var _this = this;
        var currentMethod = $route.current.params.name;
        
        $scope.currentRoute = "";
        $scope.myManagementsActive = "";
        $scope.myAccessActive = "";
        $scope.proxyListError = errorDisplayerUtil.create();
        $scope.sortPropertyName = 'fullDateWithTime';
        $scope.currentRoleTA = false;
        $scope.currentRoleManager = false;
        $scope.userSelected = false;
        $scope.managerSelected = false;
        $scope.isLoading = true;

        var sett = SettingsService.getSettings().then(function (success) {
            /* Proxy Mode determined by the organization settings */
            $scope.showProxyMode = SettingsService.isProxyModeEnabled();
        });
        $scope.impersonate = UsersService.impersonate;
        /* establish scope variable to determine if the user is a TA */
        /* proxy list differ for TA and Manager */
        //var currentUserRole = UsersService.getCurrentRoleType();
        var isManager = UsersService.hasRole('manager');
        var isTA = UsersService.hasRole('ta');
        if (isTA === true) {
            $scope.currentRoleTA = true;
        }
        else if (isManager === true) {
            $scope.currentRoleManager = true;
        }

        $scope.loggedInUserId = UsersService.getId();

        $scope.setStartTime = function (dateObj) {
            if (typeof dateObj !== "undefined" && dateObj !== null &&
                typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                    var portalStartTime = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                    dateObj = dateUtil.formatDate(portalStartTime);
                } else {
                    dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                }
                $scope.proxy.StartDate = dateUtil.formatDate(dateObj.jsDate);
            } else {
                $scope.proxy.StartDate = null;
                dateObj = null;
            }
            return dateObj;
        };

        $scope.setEndTime = function (dateObj) {
            if (typeof dateObj !== "undefined" && dateObj !== null &&
                typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                    var portalEndTime = dateUtil.setPortalEndTime(dateObj.moment.toDate());
                    dateObj = dateUtil.formatDate(portalEndTime);
                } else {
                    dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                }
                $scope.proxy.EndDate = dateUtil.formatDate(dateObj.jsDate);
            } else {
                $scope.proxy.EndDate = null;
                dateObj = null;
            }
            return dateObj;
        };

        $scope.setEditProxyEndTime = function (dateObj) {
            if (typeof dateObj !== "undefined" && dateObj !== null &&
                typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                    var portalEndTime = dateUtil.setPortalEndTime(dateObj.moment.toDate());
                    dateObj = dateUtil.formatDate(portalEndTime);
                } else {
                    dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                }
                $scope.proxy.endDate = dateUtil.formatDate(dateObj.jsDate);
            } else {
                $scope.proxy.endDate = null;
                dateObj = null;
            }
            return dateObj;
        };


        $scope.initNewProxyStartDate = function () {
            if (typeof $scope.proxy !== "undefined" && (typeof $scope.proxy.StartDate === 'undefined' || $scope.proxy.StartDate === null)) {
                var startDateTime = dateUtil.setPortalStartTime(new Date());
                $scope.proxy.StartDate = dateUtil.formatDate(startDateTime);
            }
        };

        /* This makes coloumns of a table sortables */
        $scope.propertyName = 'endDate';
        $scope.reverse = false;

        $scope.sortBy = function (propertyName) {
            $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
            $scope.propertyName = propertyName;
        };

        $scope.property = "$";

        $scope.homeAction = function () {
            $scope.proxy = {};
            $scope.proxyList = {};
            //$scope.proxyList.isLoading = false;
            $scope.proxyList.options = {};
            $scope.errorMessage = "";

            if ($route.current.params.param !== undefined && $route.current.params.param !== null) {
                var params = $route.current.params.param.split('/');
                if (params.length > 0) {
                    $scope.currentRoute = params[0].toLowerCase();
                }
            }

            if ($scope.currentRoute === "management") {

                //$scope.proxyList.isLoading = true;
                $scope.isLoading = true;

                var proxies = ProxyService.getAllProxy();
                proxies.then(function (success) {
                    //$scope.proxyList.isLoading = false;
                    $scope.isLoading = false;
                    $scope.proxyList.data = success;

                }, function (failure) {
                    //$scope.proxyList.isLoading = false;
                    $scope.isLoading = false;
                    $scope.proxyListError.setError(failure.ErrorCode, failure.ErrorMessage);
                });

                $scope.homeTemplate = 'app/templates/proxy/management.html';
                $scope.myManagementsActive = true;
            }
            else {

                if ($scope.impersonate.impersonatorID > 0) {
                    UsersService.navigateToHome();
                }
                $scope.views = ProxyService.view;
                $scope.isLoading = true;

                var managers = ProxyService.getManagerList();
                managers.then(function (success) {
                    $scope.managerList = success;
                    $scope.isLoading = false;
                }, function (failure) {
                    $scope.isLoading = false;
                });

                $scope.homeTemplate = 'app/templates/proxy/access.html';
                $scope.myManagementsActive = false;
            }
        };

        $scope.noResults = '';
        $scope.userTypeahead = {};
        $scope.userTypeahead.search = function (value) {

            if ($scope.proxy.iHR_ImpersonatingUserID === null || $scope.proxy.iHR_ImpersonatingUserID === undefined) {
                $scope.userSelected = false;
            }

            if (value === null) {
                /* validation - if the value is null, then just return after setting validation variable */
                return;
            }

            var fieldName = 'fullNameContains';
            searchAttribute = {};
            searchAttribute[fieldName] = value;
            searchUserPromise = UsersService.advancedSearch(searchAttribute);

            return searchUserPromise.then(function (success) {
                //$scope.resultsLoading = false;
                var output = [];
                var filteredFromUsers = _.uniq(_.compact(success));
                _.each(filteredFromUsers, function (item) {
                    var skinnedItem = {};
                    skinnedItem.id = item.id;
                    skinnedItem.name = item.fullName;
                    output.push(skinnedItem);
                });
                return output;
            });
        };

        $scope.noResultsMgrs = '';
        $scope.managerTypeahead = {};
        $scope.managerTypeahead.search = function (value) {

            if ($scope.proxy.iHR_ImpersonatedUserID === null || $scope.proxy.iHR_ImpersonatedUserID === undefined) {
                $scope.managerSelected = false;
            }

            if (value === null) {
                return;
            }
            var fieldName = 'fullNameContains';
            searchAttribute = {};
            searchAttribute[fieldName] = value;
            searchManagerPromise = UsersService.advancedSearch(searchAttribute);

            return searchManagerPromise.then(function (success) {
                //$scope.resultsLoading1 = false;
                var output = [];
                var filteredFromUsers = _.uniq(_.compact(success));
                _.each(filteredFromUsers, function (item) {
                    if (item.isManager === true) {
                        var skinnedItem = {};
                        skinnedItem.id = item.id;
                        skinnedItem.name = item.fullName;
                        output.push(skinnedItem);
                    }
                });
                return output;
            });
        };


        $scope.managerUserTypeahead = {};
        $scope.managerUserTypeahead.search = function (value) {

            if ($scope.proxy.iHR_ImpersonatingUserID === null || $scope.proxy.iHR_ImpersonatingUserID === undefined) {
                $scope.userSelected = false;
            }

            if (value === null) {
                /* validation - if the value is null, then just return after setting validation variable */
                return;
            }

            searchManagerUserPromise = ProxyService.advancedSearch(value);

            return searchManagerUserPromise.then(function (success) {
                var output = [];
                var filteredFromUsers = _.uniq(_.compact(success));
                _.each(filteredFromUsers, function (item) {
                    var skinnedItem = {};
                    skinnedItem.id = item.id;
                    skinnedItem.name = item.fullName;
                    output.push(skinnedItem);
                });
                return output;
            });
        };

        $scope.setProxyUser = function (selectedUser) {           
            $scope.proxy.iHR_ImpersonatingUserID = selectedUser.id;
            $scope.userSelected = true;
        };

        $scope.setProxyManager = function (selectedManager) {
            $scope.proxy.iHR_ImpersonatedUserID = selectedManager.id;
            $scope.managerSelected = true;
        };

        /* Call to the API to create a new decision map */
        $scope.createProxy = function () {
            $scope.savingProxy = true;
            $scope.isLoading = true;
           
            var proxyData = angular.copy($scope.proxy);

            if (typeof $scope.proxy.StartDate !== "undefined" && typeof $scope.proxy.StartDate.moment !== "undefined") {
                proxyData.StartDate = proxyData.StartDate.moment.toISOString();
            } else {
                proxyData.StartDate = null;
            }

            if (typeof $scope.proxy.EndDate !== "undefined" && typeof $scope.proxy.EndDate.moment !== "undefined") {
                proxyData.EndDate = proxyData.EndDate.moment.toISOString();
            } else {
                proxyData.EndDate = null;
            }


            var createProxy = ProxyService.createProxy(proxyData);
            $scope.errorMessage = "";
            createProxy.then(function (success) {
                $scope.savingProxy = false;
                //Save the returned decision map ID to the decisionMap
                //$scope.proxy.id = success;
                $scope.errorThrown = false;
                $scope.isLoading = false;
                $window.location.reload();
            }, function (failure) {
                $scope.savingProxy = false;
                $scope.errorMessage = failure.ErrorMessage;
                $scope.errorThrown = true;
                $scope.isLoading = false;
            });
        };

        $scope.submitView = function () {
           // var accessData = angular.copy($scope.proxy);
            $scope.isLoading = true;
            var olduserId = UsersService.getUserName();
            var username = $scope.proxy.proxyName;

            var context = UsersService.changeUserContext(username);

            context.then(function (success) {
                var role = UsersService.setUserContext('manager');

                role.then(function () {
                    if ($scope.proxy.viewType === 0) {
                        UsersService.navigateToHome();
                    }
                    else {
                        $location.url('reports/');
                    }
                    $scope.isLoading = false;
                });
            }, function (failure) {
                $scope.errorMessage = failure.ErrorMessage;
                $scope.errorThrown = true;
                $scope.isLoading = false;
            });
        };

        $scope.getSingleProxy = function (impersonationID) {
            $scope.errorMessage = "";
            $scope.id = impersonationID;
            var getProxyByID = ProxyService.getSingle($scope.id);

            getProxyByID.then(function (success) {
                $scope.row = success;
                $scope.errorThrown = false;
            }, function (failure) {
                $scope.errorMessage = failure.ErrorMessage;
                $scope.errorThrown = true;
            });
            //-------------------
            angular.element(document.querySelector('#editProxyModal')).modal();

        };

        $scope.closeEditProxyModal = function () {
            angular.element(document.querySelector('#editProxyModal')).modal('hide');
            $scope.errorMessage = "";
            $scope.errorThrown = "";
        };

        $scope.editProxy = function (deactivate) {
            angular.element(document.querySelector('#editProxyModal')).modal('hide');
            $scope.isLoading = true;
            $scope.udpatingProxy = true;
            var proxyData = angular.copy($scope.row);

            if ($scope.row.endDate !== null) {  
                var endDateTime = dateUtil.setPortalEndTime(proxyData.endDate.moment.toDate());
                proxyData.endDate = endDateTime.toISOString();
            }

            proxyData.startDate = proxyData.startDate.moment.toISOString();

            if (typeof deactivate !== 'undefined') {
                if (deactivate === true) {
                    proxyData.endDate = new Date();
                    proxyData.endDate.setMinutes(proxyData.endDate.getMinutes() - 1);
                }
            }

            var udpateProxy = ProxyService.editProxy(proxyData);

            udpateProxy.then(function (success) {
                $scope.udpatingProxy = false;
                $scope.errorThrown = false;
                $scope.isLoading = false;
                $window.location.reload();
            }, function (failure) {
                $scope.udpatingProxy = false;
                $scope.errorMessage = failure.ErrorMessage;
                $scope.errorThrown = true;
                $scope.isLoading = false;
            });
        };

        $scope.exportLoading = false;
        $scope.getProxyListCSV = function () {
            $scope.currentTimezone = moment.tz.guess();
            $scope.exportLoading = true;
        };

        $scope[currentMethod + 'Action']();

    }]);