app.directive('lmsDatePicker', ['dateUtil', function (dateUtil) {

    var template = 'app/templates/components/lmsDatepicker.html';

    return {
        restrict: 'E',
        templateUrl: template,
        scope: {
            dateObj: '=date',
            minDate: '=?',
            maxDate: '=?',
            showTimeToggle: '=?',
            isEditable: "=",
            onChange: "&?",
            isRequired: "=?",
            id: "@",
            placeholderOverride: "@?",
            displayInline: "=?",
            onAssignonChange: "&?"
        },
        link: function (scope, element) {

            scope.displayInline = (angular.isDefined(scope.displayInline) && scope.displayInline === true) ? scope.displayInline : undefined;

            scope.showTimeToggle = angular.isDefined(scope.showTimeToggle) ? scope.showTimeToggle : true;

            scope.onDateTimeChange = function (newValue, oldValue) {
                
                //to ensure portal default time is set only the first time a date is selected. (assuming portal default time is set in the controllers on-change method)
                //(have to find a better way to do this)
                if (typeof scope.dateObj !== "undefined" && scope.dateObj !== null) {
                    if (typeof newValue === "undefined" || typeof scope.dateObj.moment === "undefined") {
                        scope.dateObj.displayTime = null;
                    }
                }
                

                if (typeof scope.onChange !== "undefined") {
                    if (typeof scope.dateObj !== "undefined" && typeof scope.dateObj.moment !== "undefined") {
                        var retObj = scope.onChange({ dateObj: scope.dateObj });
                        if (typeof retObj !== "undefined") {
                            scope.dateObj = retObj;
                        }
                    }
                } else if (typeof scope.onAssignonChange !== "undefined") {
                    // the only difference from above is that we don't check if the moment object is undefined - didn't want to make this change to ALL date pickers
                    //the function that is being called for assign on handles null moment and won't break, can't say the same for all other date pickers
                    if (typeof scope.dateObj !== "undefined") {
                        var retObjAssignOn = scope.onAssignonChange({ dateObj: scope.dateObj });
                        if (typeof retObjAssignOn !== "undefined") {
                            scope.dateObj = retObjAssignOn;
                        }
                    }
                } else {
                    if (typeof newValue !== "undefined" && newValue !== null && _.isObject(newValue)) {
                        var currentTime = new Date();
                        if (typeof scope.dateObj !== "undefined" && typeof scope.dateObj.moment !== "undefined" && _.isObject(scope.dateObj.moment)) {
                                scope.dateObj.moment.set({ hour: currentTime.getHours(), minute: currentTime.getMinutes(), second: currentTime.getSeconds(), millisecond: currentTime.getMilliseconds() });
                                scope.dateObj = dateUtil.formatDate(scope.dateObj.moment.toDate(), scope.dateObj.displayTime);
                        }
                        else {
                            newValue.set({ hour: currentTime.getHours(), minute: currentTime.getMinutes(), second: currentTime.getSeconds(), millisecond: currentTime.getMilliseconds() });
                            scope.dateObj = dateUtil.formatDate(newValue.toDate());
                        }
                    }
                }
            };

            scope.toggleDateTimedisplay = function (dateObj) {
                if (typeof dateObj === "undefined" || dateObj === null || typeof dateObj.moment === "undefined" || typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null) {
                    return;
                }

                dateObj.displayTime = !dateObj.displayTime;
            };


        }
    };
}]);