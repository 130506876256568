/**
 * @TODO: @sfrohm - migrate this to generate messaging utility object
 *
 * Class to generalize and abstract how all errors should be piped to the front-end.
 */
app.factory('messagingDisplayerUtil', [function() {
    return {
        create: function() {
            var messagingDisplayer = function() {
                this.code = 0;
                this.message = null;
                this.neverHide = false;
                this.thrown = false;

                this.reset = function() {
                    this.code = 0;
                    this.message = null;
                    this.neverHide = false;
                    this.thrown = false;
                };

                this.setError = function(code, message, neverHide) {
                    this.code = code;
                    this.message = message;
                    this.neverHide = neverHide;
                    this.thrown = true;
                };
            };

            return new messagingDisplayer();
        }
    };
}]);