(function () {
  catalog.directive("applicationLibraryMenu", [
    function () {
      return {
        restrict: "E",
        templateUrl: "App/templates/components/applicationLibraryMenu.html",
        controller: "applicationLibraryMenuController",
      };
    },
  ]);
})();
