
app.directive("bioSigModal", ['$rootScope', '$sce', '$route', 'UsersService', 'NMLSService', function ($rootScope, $sce, $route, UsersService, NMLSService) {
    // returns the Directive Definition Object
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'app/templates/users/home/modals/BioSigModal.html',
        link: function (scope, element, attrs, ctrl) {

            scope.isError = false;
            scope.isbioSigModalLoading = true;
            scope.bioSigLoader = true;
            scope.showBioSigFrame = false;
            scope.reloadAssignments = false;

            scope.openBioSigModal = function (context) {
                if (document.getElementById("bioSigFrameOpen") !== null) {
                    document.getElementById("bioSigFrameOpen").src = "";
                    document.getElementById("bioSigFrameOpen").id = "bioSigFrame";
                }
                var user = UsersService.fetch(UsersService.getId());
                user.then(function (success) {
                    //Set the scoped NMLS values
                    scope.user = success;
                    if (typeof scope.user.nmlsEmail === 'undefined' || scope.user.nmlsEmail === "") {
                        scope.user.nmlsEmail = scope.user.email;
                    }
                    
                    scope.isbioSigModalLoading = false;
                    scope.bioSigLoader = false;
                    scope.showBioSigFrame = false;
                }, function (failure) {
                    scope.isbioSigModalLoading = false;
                    scope.bioSigLoader = false;
                });

                document.getElementById(context + "bioSig").querySelector('#bioSigFrame').id = "bioSigFrameOpen";
                angular.element(document.getElementById(context + "bioSig").querySelector('#bioSigModal')).modal();
            };

            scope.callBioSig = function (user) {
                scope.isbioSigModalLoading = true;
                var nmlsID = user.nmlsID;
                var nmlsEmail = user.nmlsEmail;
                scope.bioSigLoader = true;
                //No redirectUrl set for enrollment, inbound BSI call will add url to redirect to
                var redirectUrl = "";
                
                //Make a call to get BioSig URL  (redirectUrl, courseId, courseItemIdentifier, activity, nmlsID, email, d4, d5)
                var bioSigCall = NMLSService.getBioSigUri(redirectUrl, '0', 'Enrollment', 'enroll', nmlsID, nmlsEmail, 'Enroll Course', '0');
                bioSigCall.then(function (success) {
                    scope.bioSigLoader = false;
                    scope.showBioSigFrame = true;
                    scope.reloadAssignments = true;
                    document.getElementById("bioSigFrameOpen").src = success;
                }, function () {
                   scope.isError = true;
                    scope.errorMessage = 'We were unable to get a connection to BioSig, please try again.';
                    scope.isbioSigModalLoading = false;
                    scope.bioSigLoader = false;
                });
            };

            scope.refresh = function (reloadAssignments) {
                
                if (document.getElementById("bioSigFrameOpen") !== null) {
                    document.getElementById("bioSigFrameOpen").src = "";
                    document.getElementById("bioSigFrameOpen").id = "bioSigFrame";
                }
                scope.isError = false;
                scope.isbioSigModalLoading = true;
                scope.bioSigLoader = true;
                scope.showBioSigFrame = false;

                if (reloadAssignments) {
                    $route.reload();
                }

            };

        } // end link object
}; // end directive return
}]);
