/***** This mixin maps roles and pages to page/role specific override mixins. *****
 *
 * The mixins are used on a page by page basis
 * to override certain parts of the page for specific roles.
 *
 * You call the mapRoleAndPageToOverride function with a copy of the UsersService and $route
 * objects. (UsersService is from /BAI.LMS.Web/App/js/services/user.js) ($route is from
 * https://docs.angularjs.org/api/ngRoute/service/$route)
 *
 * The function returns a mixin (dictionary)
 *
 * *********** HOW MIXINS ARE STRUCTURED AND STORED
 *
 * Mixins in this case are dictionaries returned by factories with this naming convention
 * <ControllerName>_<Role>Override
 * Think of a mixin as a named dictionary containing some methods and variables.
 *
 * Where <ControllerName> is the name of the controller without the word
 * Controller in it. The first letter is always capitalized.
 * For the usersController, it would be Users
 *
 * If there's more than one capital letter in the controller name, that capital is left as-is.
 * The first letter is auto-capitalized.
 *
 * <Role> is the value in UsersService.auth.currentRole with a capital first letter.
 * For a student, it will be Student.
 *
 * Ex: /users/home/ could have an override called Users_InstructorOverride
 *
 * These mixins are only for overriding controllers. Not services. Not other mixins. Not factories.
 * Just controllers.
 *
 * They're stored in BAI.LMS.Web/App/js/controllers/<controllername>/<role>.js
 *
 * Where <controllername> is the name of the controller without the word controller in it
 * UsersController will be users
 *
 * And <role> is the name of the role in lowercase. Instructor will be instructor.
 *
 * So an Instructor override for the UsersController will be in
 * BAI.LMS.Web/App/js/controllers/users/instructor.js
 *
 * *********** WHAT TO PUT INTO A MIXIN
 *
 * You can put any method or variable you want into the mixin.
 *
 * The idea is that you override specific functions like homeAction or iltCalendarAction
 * and then those pages will do something different. homeAction/iltCalendarAction in this case
 * correspond to the homeAction/iltCalendarAction in the UsersController.
 * Then, the /users/home (homeAction) and the /users/ilt-calendar/ (iltCalendarAction)
 * pages will be slightly different for an Instructor if you do the below:

 app.factory('Users_InstructorOverride', [function() {
    return {
        function($scope) {

        },
        homeAction: function() {
            // your specific functionality here
        },
        iltCalendarAction: function() {
            // your specific functionality here
        },
        homeTemplate: 'app/templates/users/home/instructor.html'
    }
}]);

 * If you have a template override, put that as a variable. The controller your working
 * with should have a variable that specifies what template you should use.
 *
 * *********** HOW THE MIXIN WORKS
 *
 * In your controller, inject RoleMapsMixin near the top.
 *
 * Then, near the bottom of your controller (but before you call any route specific functions like
 * homeAction or iltCalendarAction), call
 *
 * var override = RoleMapsMixin.overrideScope();
 * Store the value of that and use angular.merge to merge it with the scope
 *
 * angular.merge($scope, override);
 *
 * Then call your page specific setup function like homeAction.
 *
 * Now your page will be overridden for a specific role.
 *
 * Only pages which are different depending on the role need a mixin.
 *
 * If there isn't a mixin for a page and there's a student mixin, the page
 * Will use the student mixin instead.
 *
 * *********** IMPORTANT NOTES
 *
 * MAKE SURE YOU USE THE PROPER CASING AND SYNTAX FOR NAMING YOUR OVERRIDES
 * OTHERWISE THE SYSTEM WONT FIND YOUR OVERRIDE.
 *
 * There are some built in logging functions to help with this.
 * If you call RoleMapsMixin.overrideScope() in your controller
 * and the system doesn't find the override, it will log the controllers it tried to find.
 *
 * See HOW MIXINS ARE STORED AND STRUCTURED for information on how mixins are named.
 *
 */


/* Utility function for capitalizing the first letter of a string */
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

/* Returns a factory name for a controller and role */
function getPotentialFactoryName(controllerName, currentUserRole) {
    // @TODO: @sfrohm [must talk to @adass to get standardization] get API to return admin, instead of TA
    currentUserRole = currentUserRole === 'ta' ? 'admin' : currentUserRole;

    var potentialFactoryName = '';

    controllerName = controllerName.capitalize();
    potentialFactoryName += controllerName + '_';

    currentUserRole = currentUserRole.capitalize();
    potentialFactoryName += currentUserRole + "Override";

    return potentialFactoryName;
}

/* Returns true if an angular provider exists */
function findProvider(providerName) {
    var foundIt = false;

    angular.module('baiApp')._invokeQueue.forEach(function(value) {
        // These magic numbers [2][0] are angular black magic.
        var angularProviderName = value[2][0];
        if (providerName === angularProviderName) {
            foundIt = true;
        }
    });

    return foundIt;
}

/* The meat of the role overrider is here
* Maps the controller/user role to the override and calls the override
*/
function mapPageAndRoleToOverride(controllerName, currentUserRole, $injector, $scope) {
    // Used to hold name of factory for overriding a page.
    // Marked as potential because we might not find one.
    var potentialFactoryName = getPotentialFactoryName(controllerName, currentUserRole);

    // Check the list of existing angular providers
    // That way we don't try to create a provider that doesn't exist.
    var foundTheProvider = findProvider(potentialFactoryName);
    // If we didn't find a provider, look for the student version of that provider.
    if (!foundTheProvider) {
        potentialFactoryName = getPotentialFactoryName(controllerName, 'student');
        foundTheProvider = findProvider(potentialFactoryName);
    }

    // If we found the provider, instantiate it and override the page.
    if (foundTheProvider) {
        var myServiceFunction = $injector.get(potentialFactoryName);

        console.log("using: " + potentialFactoryName + " for override");

        new myServiceFunction($scope);
    }
}


/* The exposed part of this helper. Rest of helper is internal functions.
* Map the controller name, route name and user role to an override */
app.factory('RoleMapsMixin', ['$route', 'UsersService', '$injector', function($route, UsersService, $injector) {
    return {
        findProvider: findProvider, // used in the UsersAdminOverride factory
        overrideScope: function($scope) {
            var controllerName = $route.current.params.controller;
            var currentUserRole = UsersService.getCurrentRoleType();

            return mapPageAndRoleToOverride(controllerName, currentUserRole, $injector, $scope);
        },
        overrideScopeByRole: function ($scope, userRole) {
            var controllerName = $route.current.params.controller;

            return mapPageAndRoleToOverride(controllerName, userRole, $injector, $scope);
        }
    };
}]);
