app.service('OfferingsService', ['$http', 'addressUtil', 'dateUtil', 'messagingDisplayerUtil', 'sessionFormatterUtil', 'RosterService',
    function ($http, addressUtil, dateUtil, messagingDisplayerUtil, sessionFormatterUtil, RosterService) {
        var _this = this;

        _this.formatableDates = ['endDateTime', 'registrationEndDateTime', 'startDateTime'];

        _this.offerings = _this.response = null;

        /**
         * Fetch an offering for a single STUDENT
         *
         * @param userId student's user ID
         * @param type type of course this will be: either VLE or ILT
         * @returns $http offering call promise
         */
        _this.fetch = function (userId, type) {
            var req = {
                method: 'GET',
                url: 'api/course/' + userId + '/' + type + '/',
                headers: {'Content-Type': 'application/json'},
                timeout: 300000  //5 minutes to allow for loading large number of ilts (SLY-6454)
            };

            return $http(req).then(function (success) {
                _this.offerings = _this.format(success.data);
                }, function (failure) {
                throw failure;
            });
        };

        /**
         * Gets a single offering only. Will contain sessions and roster
         *
         * @param offeringId
         * @param type
         */
        _this.fetchSingle = function (offeringId, type) {
            var req = {
                method: 'GET',
                url: 'api/offering/' + offeringId + '/' + type + '/',
                header: {'Content-Type': 'application/json'},
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.formatOfferingsOnly([success.data])[0];

                return _this.response;
            }, function(failure) {
                throw failure;
            });
        };

        _this.fetchInstructorAssignments = function (tense) {
            var req = {
                method: 'GET',
                url: 'api/offering/' + tense + '/',
                headers: {'Content-Type': 'application/json'},
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.formatOfferingsOnly(success.data);

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.cancelOffering = function (offeringId, type) {
            var req = {
                method: 'PUT',
                url: 'api/offering/cancel/' + offeringId + '/' + type + '/',
                headers: {'Content-Type': 'application/json'},
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.completeOffering = function (offeringId, type) {
            var req = {
                method: 'PUT',
                url: 'api/offering/deliver/' + offeringId + '/' + type + '/',
                headers: {'Content-Type': 'application/json'},
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Format all data that comes in
         *
         * This is specific to when we get FULL course data, but need to pull offerings out
         */
        _this.format = function (response) {
            var current, curOffering, meetings = null;

            if (response !== null) {

                for (var course in response) {
                    current = response[course];
                    for (var offering in current.offerings) {
                        curOffering = current.offerings[offering];

                        if(curOffering.hasOwnProperty(offering)) {
                            continue;
                        }

                        /**
                         * Merge data base obj with generic obj for view goodies
                         */
                        generic = _this.createGenericOffering(curOffering.id, curOffering.courseId);

                        _.extend(generic, curOffering);

                        curOffering.assignmentId = response[course].id;
                        curOffering.status = response[course].status;

                        if (typeof curOffering.sessions !== 'undefined' && curOffering.sessions !== null && curOffering.sessions.length > 0) {
                            //
                            curOffering.closestSession = null;
                            curOffering.closestSessionKey = null;
                            // Keep track of highest session end date to pass to student completion service
                            curOffering.highestSessionDateForOffering = null;
                            // Keep track of lowest session start date for display purposes
                            curOffering.lowestSessionDateForOffering = null;

                            curOffering.courseTypeLabel = _this.getCourseTypeLabel(curOffering.type);
                            // curOffering.address.cityStateZipLabel = _this.getCityStateZipLabel(curOffering.address);

                            curOffering.roster = JSON.parse(JSON.stringify(_this._generateRosterObjects(curOffering.roster)));

                            curOffering.type = response[course].type;

                            curOffering = sessionFormatterUtil.formatSessions(curOffering);
                            curOffering.instructor = curOffering.sessions[0].instructor;
                            curOffering.locationName = curOffering.location;
                        }
                    }
                }

                response = _this.extractOfferings(response);
                         
            }

            return response;
        };
        /**
         * Format all data that comes in
         *
         * This is specific to when we get FULL course data, but need to pull offerings out
         */
        _this.formatOfferingsOnly = function (response) {
            var current, curOffering, meetings = null;

            if (response !== null) {
                var generic = null;

                for (var i = 0; i < response.length; i++) {
                    curOffering = response[i];

                    for (var node in curOffering) {
                        if (curOffering[node] !== null && $.inArray(node, _this.formatableDates) !== -1) {
                            curOffering[node] = dateUtil.formatDate(curOffering[node]);
                        }
                    }

                    /**
                     * Merge data base obj with generic obj for view goodies
                     */
                    generic = _this.createGenericOffering(curOffering.id, curOffering.courseId);

                    _.extend(generic, curOffering);

                    //
                    curOffering.closestSession = null;
                    curOffering.closestSessionKey = null;
                    // Keep track of highest session end date to pass to student completion service
                    curOffering.highestSessionDateForOffering = null;
                    // Keep track of lowest session start date for display purposes
                    curOffering.lowestSessionDateForOffering = null;

                    curOffering.addressExists = addressUtil.isThereAnAddress(curOffering.address);
                    curOffering.courseTypeLabel = _this.getCourseTypeLabel(curOffering.type);
                    curOffering.roster = _this._generateRosterObjects(curOffering.roster);
                    curOffering.type = curOffering.assignmentType;

                    curOffering.address.cityStateZipLabel = addressUtil.getCityStateZipLabel(curOffering.address);

                    if (curOffering.sessions || curOffering.studentSessions) {
                        curOffering = sessionFormatterUtil.formatSessions(curOffering);
                    }
                    
                }
            }

            return response;
        };

        _this.extractOfferings = function (courses) {
            var single = null;

            var offerings = [];

            for (var course in courses) {
                for (var offering in courses[course].offerings) {
                   
                    single = courses[course].offerings[offering];

                    //exclude offerring that have a past end date
                    if (single.registrationEndDateTime !== null && new Date(single.registrationEndDateTime) >= new Date()) {
                        single.isAssigned = false;

                        if (_this.sessionsExist(courses[course]) && courses[course].assignedOffering.id === single.id) {
                            single.isAssigned = true;
                        }

                        offerings.push(single);
                    }                 
                }
            }

            return offerings;
        };

        _this.sessionsExist = function (course) {
            return typeof course.assignedOffering !== 'undefined' && course.assignedOffering !== null && typeof course.assignedOffering.sessions !== 'undefined' && course.assignedOffering.sessions.length > 0;
        };

        _this.getCourseTypeLabel = function (type) {
            switch (type) {
                case 'ilt':
                    return 'Instructor Led Training';
                case 'vle':
                    return 'Virtually Led Elective';
            }

            return 'Instructor Led Training';
        };

        /**
         * Roster area!
         */

        /**
         * Set all the different rosters; this way we can have one concentrated area for rosters with methods
         * on the offerings class.
         *
         * @param roster Object containing the roster children
         * @returns {RosterService} instance of the roster service
         */
        _this._generateRosterObjects = function (roster) {
            RosterService.setFromOfferingsService('attendingStudents', roster.attendingStudents);
            RosterService.setFromOfferingsService('droppedStudents', roster.droppedStudents);
            RosterService.setFromOfferingsService('pendingApprovalStudents', roster.pendingApprovalStudents);
            RosterService.setFromOfferingsService('waitlistStudents', roster.waitlistedStudents);

            return RosterService;
        };

        _this.hasAttendingStudents = function () {
            return _this.getAttendingStudents().length === 0;
        };

        _this.getAttendingStudents = function () {
            return _this.response.attendingStudents;
        };

        _this.getIncompleteUserCount = function () {
            return _this.response.roster.getIncompleteUserCount();
        };

        _this.isOfferingCancellable = function() {
            var students = _this.response.roster.getAttendingStudents();

            var possibleCompletions = ['cancelledNoShow', 'successful', 'unsuccessful'];

            for(var i in students) {
                if(jQuery.inArray(students[i].registrationStatus, possibleCompletions) > -1) {
                    return false;
                }
            }

            return true;
        };

        _this.getAllSession = function() {
            return _this.response.sessions;
        };

        _this.getLowestSessionIdByDate = function() {
            var lowestSession = null;
            var lowestSessionId = null;
            var sessions = _this.response.sessions;

            if(sessions.length > 0) {
                for(var i = 0; i < sessions.length; i++) {
                    if(sessions[i].startDateTime.original < lowestSession || lowestSession === null) {
                        lowestSession = sessions[i].startDateTime.original;
                        lowestSessionId = sessions[i].id;
                    }
                }
            }

            return lowestSessionId;
        };

        _this.removeStudentFromWaitList = function (person, offeringId) {
            var req = {
                method: 'PUT',
                url: 'api/roster/updatestatus/' + person.student.id + '/' + offeringId + '/Cancelled/ilt/',
                headers: { 'Content-Type': 'application/json' },
                timeout: window.timeout
            };

            /**
             * @TODO: @sfrohm: figure out why you return original response, not the response coming back from the
             * server.
             */
            return $http(req).then(function (success) {
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         * Create the offerings and respective sessions on the DS
         *
         * @param offerings {obj} Object containing all offerings and sessions to be created
         */
        _this.createOfferings = function (offerings) {
            var req = {
                method: 'POST',
                url: 'api/offering/multi',
                data: _this.formatForSend(offerings),
                headers: { 'Content-Type': 'application/json' },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                /**
                 * Response will contain full offering/session structures. Re-format to guarantee accuracy and not
                 * lose any information the view needs.
                 */
                //for(var i in success.data) {
                //    success.data[i] = success.data[i].offering;
                //}

                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        /**
         *
         * @param offerings
         */
        _this.formatForSend = function(offerings) {
            return _this.formatOfferingsForSend(offerings);
        };

        /**
         *
         */
        _this.formatOfferingsForSend = function(offerings) {
            var dateFields = ['requestedOn', 'registrationEndDateTime'];
            var removableFields = ['error', 'instructorLabel', 'instructorPills', 'instructorReader', 'locationId', 'accordionOpen', 'rosterUser'];

            var offering = null;

            for(var i = 0; i < offerings.length; i++) {
                offering = offerings[i];

                for(var node in offering) {
                    if (typeof offering[node] !== 'undefined' && offering[node] !== null && jQuery.inArray(node, dateFields) > -1) {
                        if (offering[node].jsDate) {
                            offering[node] = offering[node].jsDate.toISOString();
                        } else {
                            offering[node] = null;
                        }
                    } else if(jQuery.inArray(node, removableFields) > -1) {
                        delete offering[node];
                    }
                }

                if (typeof offering.sessions !== 'undefined' && offering.sessions !== null) {
                    if (offering.sessions.length > 0)
                        offering.sessions = _this.formatSessionsForSend(offering.sessions);
                }

                /**
                 * These values are defaults that are require by Latitude; do not remove unless user driven
                 * data will exist.
                 */
                offering.enrollmentRestrictions = '';
                offering.instructionalLanguageId = 9;
                offering.languageId = 9;
                offering.maxStudentsPerDealer = offering.maxAllowedSize;
                offering.trainingType = 3;
            }

            return offerings;
        };

        _this.formatSessionsForSend = function(sessions) {
            var dateFields = ['startDateTime', 'endDateTime'];
            var parseIntFields = ['id'];
            var removableFields = ['instructorPills', 'error', 'instructorReader', 'offeringId', 'startDate', 'endDate', 'startTime', 'endTime'];

            var session = null;

            for(var i = 0; i < sessions.length; i++) {
                session = sessions[i];

                /**
                 * Clean up the session
                 */
                for(var node in session) {
                    if(jQuery.inArray(node, dateFields) > -1) {
                        session[node] = session[node].jsDate.toISOString();
                    } if(jQuery.inArray(node, parseIntFields) > -1) {
                        session[node] = parseInt(session[node], 10);
                    } else if(jQuery.inArray(node, removableFields) > -1) {
                        delete session[node];
                    }
                }
            }

            return sessions;
        };

        /**
         * Generate a default offering object to help with view logic
         *
         * @param id {int} Generic value for angular's two way binding
         * @param courseId {int} ID of the course that is being tied to the offering
         * @returns {object}
         */
        _this.createGenericOffering = function(id, courseId) {
            var offering = {
                accordionOpen: false,
                assignmentType: 'ilt',
                courseId: courseId,
                localId: id,
                instructorIds: [],
                instructorPills: [], // used to keep track of cutesy pills
                search: {
                    isLoading: false,
                    error: messagingDisplayerUtil.create()
                },
                rosterUser: '',
                roster: {
                    attendingStudents: []
                },
                sessions: []
            };

            return offering;
        };

        _this.updateOffering = function (offering) {
            var req = {
                method: 'PUT',
                url: 'api/offering/updateoffering',
                data: _this.formatForSend([offering])[0],
                headers: { 'Content-Type': 'application/json' },
                timeout: window.timeout
            };

            /**
             * @TODO: @sfrohm: figure out why you return original response, not the response coming back from the
             * server.
             */
            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };


        _this.updateOfferingsBatch = function (offerings) {
            var req = {
                method: 'PUT',
                url: 'api/offering/batchupdate',
                data: _this.formatForSend(offerings),
                headers: { 'Content-Type': 'application/json' },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };
    }
]);
