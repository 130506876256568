app.factory('urlParamsUtil', ['$location', function urlParamsUtil($location) {
    var _this = this;

    _this.queries = [];

    /**
     * Add a key value pair to be generated into a GET param
     *
     * @param key
     * @param value
     * @private
     */
    _this._add = function(key, value) {
        var query = key + '=' + value;

        if(jQuery.inArray(query, _this.queries) > -1) {
            return;
        }

        _this.queries.push(query);
    };

    /**
     * Create the final query string
     *
     * @returns {*}
     * @private
     */
    _this._create = function() {
        var query = '';

        if(_this.queries.length === 0) {
            return query;
        }

        query = _this.queries.join('&');

        return '?' + query;
    };

    return {
        add: _this._add,
        create: _this._create,
        delete: _this._delete,
        request: _this._request
    };
}]);