/*
    Directive to handle a minimum time as a string. Parses the time string and compares it to the value. Invalid if the time entered is less than the minimum value
*/
app.directive('minTimeString', ['dateUtil', function (dateUtil) {

    return {
        // restrict to an attribute type.
        restrict: 'A',

        // element must have ng-model attribute.
        require: 'ngModel',

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            ctrl.$validators.minTimeString = function(modelValue) {
                var valid = true;
                var minTime = dateUtil.parseTime(attr.minTimeString);
                // If it can't parse the time, it's the wrong format so don't even bother trying to validate against the min time.
                // But we want to assume this is valid if the format is wrong, otherwise, this validation could cover up a regex validation that validates the time format.
                if (minTime !== null) {
                    var valueTime = dateUtil.parseTime(modelValue);
                    if (valueTime !== null) {
                        if (valueTime < minTime) {
                            valid = false;
                        }
                    }
                }
                return valid;
            };
        } // end link function
    }; // end return
}]);