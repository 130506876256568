app.directive('doughnutChart', [function () {
    return {
        restrict: 'E',
        scope: {
            config: '=',
            circleMessage: '='
        },
        templateUrl: 'app/templates/components/doughnutChart.html',
        link: function (scope, element, attr) {
            var canvas = element.find('canvas')[0];
            var ctx = canvas.getContext("2d");
            
            scope.$watch('config', function (newVal) {
                scope.config = newVal;
                if (scope.config !== undefined) {
                    new Chart(ctx).Doughnut(scope.config.data, scope.config.options);
                }
            });
        }
    };
}]);
