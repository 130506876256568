(function() {
    app.directive('baiDocumentActionButton', [
        function() {
            return {
                restrict: 'E',
                scope: {
                    promises: '=',
                    productId: '='
                },
                templateUrl: 'App/templates/components/baiDocumentActionButton.html',

                controller: ['$scope', '$q', DocumentActionButtonController]
            };
        }
    ]);

    function DocumentActionButtonController($scope, $q) {
        $scope.document = {};

        $q.all($scope.promises).then(function (success) {
            $scope.document = success[0];
        });
    }

})();