app.controller("adminHeaderController", [
  "$rootScope",
  "$location",
  "SettingsService",
  "UsersService",
  "BillingService",
  function ($rootScope, $location, SettingsService, UsersService, BillingService) {
    $rootScope.settingsIsLoading = true;
    $rootScope.isDashboardDisabledBecauseOfTraffic = sessionStorage.getItem('disableDashboard') === 'true';
    $rootScope.invoices = {};
    $rootScope.overdueBannerLoading = true;
    $rootScope.isAdmin = UsersService.hasAdminRole();

    var getOverdueBanner = function(){
      {
        var overdueInvoices = BillingService.getOverdueInvoices();
        overdueInvoices.then(function (success) {
          $rootScope.invoices = success;
          if($rootScope.invoices.totalOverdue >= 100){
            $rootScope.overdueBannerLoading = false;
          }
        }, function(failure){
          throw failure;
        });
      }
    };

    SettingsService.getSettings().then(function (success) {
      $rootScope.enableAdminDashboardTab = SettingsService.isILTEnabled();
      $rootScope.enableCareerPathingTab = SettingsService.isCareerPathEnabled();
      $rootScope.enableTrainingInsightsTab =
        SettingsService.isTrainingInsightsEnabled();
      $rootScope.isOverdue = SettingsService.isOverdueBannerEnabled();
      $rootScope.settingsIsLoading = false;

      var path = $location.$$path.toLowerCase();
      if (
        (path.startsWith("/insights/") &&
          !$rootScope.enableTrainingInsightsTab) ||
        (path.startsWith("/dashboard/") && !$rootScope.enableCareerPathingTab)
      ) {
        if ($rootScope.enableAdminDashboardTab) {
          $location.url("admin/dashboard");
        } else {
          $location.url("users/home");
        }
      }

      if($rootScope.isOverdue && !$rootScope.settingsIsLoading && $rootScope.isAdmin){
        getOverdueBanner(); 
      }
    });

    $rootScope.goToHomeTab = function () {
      if ($rootScope.enableAdminDashboardTab && !$rootScope.isDashboardDisabledBecauseOfTraffic) {
        $location.url("admin/dashboard");
      } else {
        $location.url("users/home");
      }
    };

    var originatorEv;

    $rootScope.openMenu = function ($mdMenu, ev) {
      originatorEv = ev;
      $mdMenu.open(ev);
    };

    $rootScope.logout = function () {
      var portalCode = UsersService.getPortalCode();
      var logout = UsersService.clearSession();
      logout.then(
        function (success) {
          // If a user's portal code existed while being logged in, persist it on the logged out state.
          if (portalCode !== "" && portalCode !== null) {
            // alert('logging out with portalCode');
            portalCode = "?portal=" + portalCode;
          }

          $location.url("/" + portalCode);
        },
        function (failure) {}
      );
    };

    $rootScope.adminTabIds = {
      people: "people",
      content: "content",
      rules: "rules",
      management: "management",
      insights: "insights",
      careerPathing: "careerPathing",
    };

    Object.freeze($rootScope.adminTabIds);

    $rootScope.getSelectedAdminTab = function () {
      var path = $location.$$path.toLowerCase();
      if (path.includes("/users/")) {
        return $rootScope.adminTabIds.people;
      } else if (path.startsWith("/courses/")) {
        return $rootScope.adminTabIds.content;
      } else if (path.startsWith("/rules/")) {
        return $rootScope.adminTabIds.rules;
      } else if (path.startsWith("/management/")) {
        return $rootScope.adminTabIds.management;
      } else if (path.startsWith("/insights/")) {
        return $rootScope.adminTabIds.insights;
      } else if (path.startsWith("/dashboard/")) {
        return $rootScope.adminTabIds.careerPathing;
      } else {
        return $rootScope.adminTabIds.people;
      }
    };

    $rootScope.selectedAdminTab = $rootScope.getSelectedAdminTab();

    $rootScope.redirectByActiveTab = function () {
      switch ($rootScope.selectedAdminTab) {
        case "dashboard":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Dashboard",
            "_blank"
          );
          break;
        case "people":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#People",
            "_blank"
          );
          break;
        case "content":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Content",
            "_blank"
          );
          break;
        case "rules":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Rules",
            "_blank"
          );
          break;
        case "management":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Management",
            "_blank"
          );
          break;
        case "recommendations":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Recommendations",
            "_blank"
          );
          break;
        case "insights":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Training-Insights",
            "_blank"
          );
          break;
        case "careerPathing":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Create-Career-Path",
            "_blank"
          );
          break;
        default:
          break;
      }
    };

    $rootScope.helpMenuClick = function (menuItem) {
      switch (menuItem) {
        case "usermanuals":
          $rootScope.redirectByActiveTab();
          break;
        case "trainingvideos":
          console.log("clicked");
          window.open(
            "https://ld-connect.bai.org/learning-manager/watch-instructional-videos/",
            "_blank"
          );
          break;
        case "faqs":
          window.open(
            "https://ld-connect.bai.org/learning-manager/frequently-asked-questions/",
            "_blank"
          );
          break;
        case "walkthroughs":
          window.open(
            "https://ld-connect.bai.org/learning-manager/walkthrough-videos/",
            "_blank"
          );
          break;
        case "ldconnect":
          window.open("https://ld-connect.bai.org/", "_blank");
          break;
        case "contactus":
          window.open(
            "https://ld-connect.bai.org/learning-manager/technical-support/",
            "_blank"
          );
          break;
        default:
          break;
      }
    };

    $rootScope.getPDF = function(invoiceId) {
      $rootScope.pdfError = { occurred: false, invoiceId: invoiceId};
      var pdf = BillingService.getInvoicePDF(invoiceId);
      pdf.then(function (success) {
        var pdf = new Blob([success], {type: 'application/pdf'});
        var pdfURL = window.URL.createObjectURL(pdf);
        window.open(pdfURL);
      }, function(failure){
        $rootScope.pdfError.occurred = true;
      });
    };
  },
]);
