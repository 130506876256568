app.factory('dateUtil', ['$rootScope', 'SettingsService', function ($rootScope, SettingsService) {

    var _this = this;

    _this.portalTz = {};

    _this.localTz = {};
    _this.localTz.zoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
    _this.localTz.name = moment.tz.guess();

    function getPortalTimeZoneSettings() {
        if (typeof $rootScope.sysSettings !== "undefined" && $rootScope.sysSettings !== null) {
            if (typeof $rootScope.sysSettings.features !== "undefined" && typeof $rootScope.sysSettings.features['orgProp.timezone'] !== "undefined") {
                _this.portalTz = $rootScope.sysSettings.features['orgProp.timezone'].details;
            }
        }
        else {
            var portalSettings = SettingsService.getSettings();
            portalSettings.then(function (settings) {
                _this.portalTz = settings.timezone.details;
            });
        }

        if (typeof _this.portalTz.ianaName === "undefined" || _this.portalTz.ianaName === null) {
            _this.portalTz.startTimeHours = "03";
            _this.portalTz.startTimeMinutes = "00";
            _this.portalTz.endTimeHours = "20";
            _this.portalTz.endTimeMinutes = "59";
            return;
        }
        
        var portalEndTime = moment(new Date()).tz(_this.portalTz.ianaName).set({ hour: 20, minute: 59, second: 0, millisecond: 0 });
        var portalStartTime = moment(new Date()).tz(_this.portalTz.ianaName).set({ hour: 3, minute: 0, second: 0, millisecond: 0 });

        var localStartTime = moment(portalStartTime).tz(_this.localTz.name);
        var localEndTime = moment(portalEndTime).tz(_this.localTz.name);

        _this.portalTz.startTimeHours = localStartTime.format("HH");
        _this.portalTz.startTimeMinutes = localStartTime.format("mm");
        _this.portalTz.endTimeHours = localEndTime.format("HH");
        _this.portalTz.endTimeMinutes = localEndTime.format("mm");
    }

    

    function testTimeStringFormat(timeString) {
        var regex = new RegExp('^\\b((1[0-2]|0[1-9]):([0-5][0-9]) ([AaPp][Mm]))$');
        return regex.test(timeString);
    }

    return {
        formatDate: function(date, displayTime) {
            var final = {};
            var mmt = moment(date);
            final.original = date;
            final.apiDate = mmt.format("YYYY-MM-DD");
            final.day = parseInt(mmt.format("D"), 10);
            final.dayName = mmt.format("ddd");
            final.fullDate = mmt.format("L");
            final.fullDateWithTime = mmt.format("L LT");
            final.month = mmt.format("MMM");
            final.monthNumber = parseInt(mmt.format("M"), 10);
            final.monthForCalender = mmt.month();
            final.year = parseInt(mmt.format("YYYY"), 10);
            final.time = mmt.format("LT");
            final.moment = mmt;

            final.utcDate = moment(date).utc();

            final.dateAsObj = {};
            final.dateAsObj.day = final.day;
            final.dateAsObj.month = final.monthNumber;
            final.dateAsObj.year = final.year;

            final.jsDate = new Date(date);

            final.displayTime = typeof displayTime === "undefined" ? false : displayTime; // default

            return final;
        },

        setStartTime: function(dateTime) {
            if (dateTime !== undefined && dateTime !== null) {
                var newdt = new Date(dateTime);
                newdt.setHours(0, 0, 0, 0);
                return newdt;
            } else
                return dateTime;
        },
        setEndTime: function(dateTime) {
            if (dateTime !== undefined && dateTime !== null) {
                var newdt = new Date(dateTime);
                newdt.setHours(23, 59, 0, 0);
                return newdt;
            } else
                return dateTime;
        },
        /**
         *
         * @param dateTime
         */
        generateNormalTimeFormat: function(dateTime) {
            var hours = dateTime.getHours();
            var minutes = this._convertMinutesToDoubleValue(dateTime.getMinutes());

            return parseFloat(hours + minutes);
        },
        /**
         * Convert minutes into a decimal of an hour.
         *
         * @param minutes Minutes of an hour
         * @returns {number} Calculated percentage of an hour
         */
        _convertMinutesToDoubleValue: function(minutes) {
            return minutes / 60;
        },
        /**
         * Get a decimal (1.5) as time (1:30:00)
         *
         * @param value Minutes to convert to minutes
         * @returns {number} Calculated string containing full time
         */
        convertDoubleToMinutes: function(value) {
            var str = parseFloat(value).toFixed(2).toString().split('.');

            //var minutes = (parseInt(str[1], 10) * 60) / 100;

            return (parseInt(str[0], 10) + ((parseInt(str[1], 10) * 60) / 10000)).toFixed(2).toString().replace(/\./g, ':') + ':00';
        },

        /**
         * Return a default date with the time parsed from the string
         *
         * @param timeString time to be converted
         * @returns {date} JS Date object of 1/1/1970 with time set to parsed parameter or null if timeString is not in the correct format
         */
        parseTime: function(timeString) {
            if (!testTimeStringFormat(timeString)) {
                return null;
            }
            var splits = timeString.split(':');
            var hours = splits[0];
            var leftover = splits[1];
            var leftoverSplits = leftover.split(' ');
            var minutes = leftoverSplits[0];
            var ampm = leftoverSplits[1];
            hours = parseInt(hours);
            // Convert hours to 24 hour clock
            if (ampm.toLowerCase() === "pm" && hours < 12) {
                hours += 12; // If its pm add 12 hours to it
            }

            if (ampm.toLowerCase() === "am" && hours === 12) {
                hours = 0; // If its 12 am, that's 0 in the 24 clock
            }

            var result = new Date(1970, 0, 1);
            result.setHours(hours, minutes, 0, 0);

            return result;
        },

        /**
         * Test if the time string matches the consistent input format: hh:mm AM/PM
         *
         * @param timeString time to be tested
         * @returns true if timeString matches format, false otherwise
         */
        isTimeFormatValid: function(timeString) {
            return testTimeStringFormat(timeString);
        },

        validMonth: function(month) {
            if (month > 12)
                month = 12;
            return month;
        },

        validDate: function(month, date) {
            var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            if (date > daysInMonth[month - 1])
                date = daysInMonth[month - 1];

            return date;
        },

        setPortalStartTime: function (dateTime) {
            if (typeof dateTime !== 'undefined' && dateTime !== null) {
                
                getPortalTimeZoneSettings();
                var newdt = new Date(dateTime);
                newdt.setHours(_this.portalTz.startTimeHours, _this.portalTz.startTimeMinutes, 0, 0);
                return newdt;
            } else
                return dateTime;
        },
        setPortalEndTime: function (dateTime) {
            if (typeof dateTime !== 'undefined' && dateTime !== null) {
                
                getPortalTimeZoneSettings();
                var newdt = new Date(dateTime);
                newdt.setHours(_this.portalTz.endTimeHours, _this.portalTz.endTimeMinutes, 0, 0);
                return newdt;

            } else
                return dateTime;
        }

    };
   
}]);