/**
 * Service "class" that contains all logic for any team related calls to the API.
 */
app.service('TeamService', ['$http', 'UsersService', 'dateUtil', 'courseFormatterUtil', 'errorDisplayerUtil', '$q', function ($http, UsersService, dateUtil, courseFormatterUtil, errorDisplayerUtil, $q) {
    var _this = this;

    _this.getSummary = function (startDate, endDate, fullyRecursive, dueDate) {
        var req = {
            method: 'GET',
            url: 'api/team/summary/' + startDate + '/' + endDate + '/' + fullyRecursive + '/' + dueDate,
            headers: {
                'Content-Type': 'application/json'
            },
            //5 minutes to allow summary query on extremely large manager hierarchies
            timeout: 300000 
        };

        return $http(req).then(function(response) {
            response = response.data;

            // Insert the pie chart fields manually
            response.pieChartData = [response.complete, (response.incomplete - response.overdue), response.overdue];

            return response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getCareerPathSummary = function (fullyRecursive) {
        var req = {
            method: 'GET',
            url: 'api/team/careerpath/summary/' + fullyRecursive,
            headers: {
                'Content-Type': 'application/json'
            },
            //5 minutes to allow summary query on extremely large manager hierarchies
            timeout: 300000
        };

        return $http(req).then(function (response) {
            response = response.data;

            // Insert the pie chart fields manually
            //response.pieChartData = [response.complete, (response.incomplete - response.overdue), response.overdue];

            return response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getCareerPathStudentSummary = function (fullyRecursive, teamType) {
        var req = {
            method: 'GET',
            url: 'api/team/careerpath/studentsummary/' + fullyRecursive + '/' + teamType,
            headers: {
                'Content-Type': 'application/json'
            },
            //5 minutes to allow summary query on extremely large manager hierarchies
            timeout: 300000
        };

        return $http(req).then(function (response) {
            response = response.data;
            return response;
        }, function (failure) {
            throw failure;
        });
    };


    /* Internal function which returns a promise which grabs the team list
     * Takes two required parameters:
     * 1) Alternate is a boolean which if true, returns the alternate team
     * If alternate is false, returns direct team
     * 2) managerUserId,
     */
    _this.getTeamList = function(alternate, managerUserId, startDate, endDate, fullyRecursive, dueDate) {
        // Returns team list in this format
        /*
         * See /api/team/list/{userId}/{teamType} in swagger
         * for return format
         * pieChartData noted below is added to each entry
         pieChartData:{
         "complete":10,
         "incomplete":10
         }
         */

        // Specify direct or alternate team
        var teamType = "direct";
        if (alternate) {
            teamType = "alternate";
        }

        if (typeof fullyRecursive === 'undefined' || fullyRecursive === null) { fullyRecursive = false; }

        var req = {
            method: 'GET',
            url: 'api/team/list/' + managerUserId + '/' + teamType + '/' + startDate + '/' + endDate + '/' + fullyRecursive + '/' + dueDate,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (response) {
            return _this.addChartDataToTeamList(response.data);
        }, function(failure) {
            throw failure;
        });
    };

    _this.addChartDataToTeamList = function(teamList) {
        // Takes a team list from the ManagerService
        // and adds the chart data to it.
        //
        // Inserts
        /*
         pieChartData:{
         "complete":10,
         "incomplete":10
         }
         For every row of data.
         */
        var tempTeamList = teamList.slice();

        for (var i = 0; i < tempTeamList.length; i += 1) {
            // the 0 is for overdue in pie chart of bottom portion in manager page.
            // may be modified if there is any requirment in the future.
            tempTeamList[i].pieChartData = [tempTeamList[i].complete, tempTeamList[i].incomplete, 0];

            tempTeamList[i].error = errorDisplayerUtil.create();
            tempTeamList[i].isLoading = false;
            tempTeamList[i].profileExpanded = false;
        }

        return tempTeamList;
    };

    _this.getTeamMgrAssignmentSummary = function (managerId, dueDate) {
        var req = {
            method: 'GET',
            url: 'api/team/list/assignmentSummary/' + managerId + '/' + dueDate,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (response) {
            response = response.data;
            return response;
        }, function (failure) {
            throw failure;
        }
    );};


    /** Exposed functions for getting the team list **/
    // These two functions are for the initial team selectors
    // on the top left of the team list
    _this.getDirectTeamList = function (startDate, endDate, fullyRecursive, dueDate) {
        var currentUserId = UsersService.getId();
        return _this.getTeamList(false, currentUserId, startDate, endDate, fullyRecursive, dueDate);
    };

    _this.getAlternateTeamList = function (startDate, endDate, fullyRecursive, dueDate) {
        var currentUserId = UsersService.getId();
        return _this.getTeamList(true, currentUserId, startDate, endDate, fullyRecursive, dueDate);
    };

    // Used for drilling down by manager
    _this.getDirectTeamListByManagerId = function (managerId, startDate, endDate, fullyRecursive, dueDate) {
        return _this.getTeamList(false, managerId, startDate, endDate, fullyRecursive, dueDate);
    };

    /* Allows the user to search for a list of assignments.
     * Used in the addAssignment modal on the manager page
     * Takes a keyword that's not encoded and an assignmentType which is one of
     * 'wbt'
     * 'ilt'
     * 'vle'
     * 'coursegroup'
     * 'unknown' for all
     *
     * See /api/team/courses/{assignmentType} in swagger for return value
     */
    _this.searchAssignments = function(keyword, assignmentType) {
        var req = {
            method: 'GET',
            url: 'api/team/courses/' + assignmentType + '?q=' + encodeURIComponent(keyword),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            var response = _this.formatAssignments(success.data);

            return response;
        }, function (failure) {
            throw failure;
        });
    };

    /* Gets the tasks that need manager approval
     * Returns data in this format.
     * see  api/team/tasks/ for response format.
     */
    _this.getTasksForApproval = function() {
        var req = {
            method: 'GET',
            url: 'api/team/tasks/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.formatTask(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    /**
     * Gets the enrollments that need approval
     *
     * @return {obj} Object which contains HTTP promise
     */
    _this.getListOfAssignmentsToApprove = function () {
        var req = {
            method: 'GET',
            url: 'api/team/enrollments/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    /**
     * Gets the team summary data from the rollup API
     * adds "pieChartData": [10, 0] manually
     * Where the first value in "pieChartData" is complete count and the last is incomplete count
     *
     * @param userId {int} ID of the user who's team roll up we want to get
     * @param startDate {date} start date of the range of assignments to get
     * @param endDate {date} end date of the range of assignments to get
     *
     * @return {obj} Object which contains HTTP promise
     */
    _this.getRollup = function (userId, startDate, endDate) {
        var req = {
            method: 'GET',
            url: 'api/team/rollup/' + userId + '/direct/' + startDate + '/' + endDate,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(response) {
            response = response.data;

            // Insert the pie chart fields manually
            response.pieChartData = [response.complete, response.incomplete];

            return response;
        }, function(failure) {
            throw failure;
        });
    };

    // Recursive promise tree to create
    // a hierarchy of teams on the frontend
    /* Returns a tree of subordinates @TODO @sneilan return type
     * First level is []
     */
    _this.recursiveTeamList = function (managerUserId, startDate, endDate, dueDate) {

        var req = {
            method: 'GET',
            url: 'api/team/list/' + managerUserId + '/direct/' + startDate + '/' + endDate + '/false/' + dueDate,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(response) {
            var teamList = response.data;

            return $q.all(teamList.map(function(teamMember) {
                if (teamMember.isManager) {
                    _this.recursiveTeamList(teamMember.studentId, startDate, endDate, dueDate).then(function(teamList) {
                        teamMember.checked = false;
                        teamMember.hidden = true;

                        teamMember.teamList = teamList;
                    }, function(failure) {
                        throw failure;
                    });
                }

                return teamMember;
            }));
        }, function(failure) {
            throw failure;
        });
    };

    _this.getSummariesByUserId = function(ids, startDate, endDate) {
        var req = {
            method: 'POST',
            url: 'api/team/list/' + startDate + '/' + endDate,
            data: JSON.stringify(ids),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return _this.addChartDataToTeamList(success.data);          
        }, function(failure) {
            throw failure;
        });
    };

    /* Formats each task in Tasks by adding
     * error and isLoading
     */
    _this.formatTask = function(data) {
        _this.tasks = _this.format(data);

        _this.tasks.forEach(function(task) {
            task.isLoading = false;
            task.error = errorDisplayerUtil.create();
        });

        return _this.tasks;
    };

    _this.formatAssignments = function(data) {
        for(var i in data) {
            data[i].checked = false;

            data[i].error = errorDisplayerUtil.create();
        }

        return data;
    };

    // the dreaded format function.. it appears in different places
    // like those monsters in the Akrham Horror board game.
    _this.format = function (response) {
        var dates = ['assignedOn', 'completedDate', 'dueDate', 'endDateTime', 'startDate', 'startDateTime', 'requestedDate', 'endDate'];

        var current = null;

        if (response !== null) {
            for (var assignment in response) {
                current = response[assignment];

                for (var node in current) {
                    if (current[node] !== null && $.inArray(node, dates) !== -1) {
                        current[node] = dateUtil.formatDate(current[node]);
                    } else if (node === 'offerings' || node === 'sessions' || node === 'assignedOffering') {
                        if (node === 'assignedOffering') {
                            current[node] = [current[node]];

                            current[node] = _this.format(current[node])[0];
                        } else {
                            current[node] = _this.format(current[node]);
                        }
                    }
                }

                current.error = errorDisplayerUtil.create();
                current.isLoading = false;

                if (current !== null && typeof current.code !== 'undefined') {
                    current.codeForImage = courseFormatterUtil.getCourseCodeForImage(current.code,current.tileImage);
                }
            }
        }

        return response;
    };

    // get the summary of a single student
    _this.getStudentSummary = function () {
        var req = {
            method: 'GET',
            url: 'api/assignments/studentdash/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(
            function (success) {
                success = success.data;
                // manually insert pie chart data
                success.pieChartData = [success.complete, success.incomplete, success.totals.overdue];
                return success;
            }, function (failure) {
                throw failure;
            }
        );
    };

    _this.getOverdueAssignments = function (startDate, endDate, fullyRecursive, dueDate) {
        var req = {
            method: 'GET',
            url: 'api/team/overdue/' + startDate + '/' + endDate + '/' + fullyRecursive + '/' + dueDate,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(
            function (success) {
                return _this.format(success.data);
            }, function (failure) {
                throw failure;
            }
        );
    };


}]);