app.factory('addressUtil', [function () {
    return {
        /**
         * Format an address into a full string
         *
         * @param addressObj
         * @returns {string}
         */
        formatAddress: function(addressObj) {
            var address = '';

            if(typeof(addressObj.addressLine1) !== 'undefined' && addressObj.addressLine1 !== '' && addressObj.addressLine1 !== null && addressObj.addressLine1 !== '') {
                address = addressObj.addressLine1;

                if(typeof(addressObj.addressLine2) !== 'undefined' && addressObj.addressLine2 !== '' && addressObj.addressLine2 !== null && addressObj.addressLine2 !== '') {
                    address += ' ' + addressObj.addressLine2;

                    if(typeof(addressObj.addressLine3) !== 'undefined' && addressObj.addressLine3 !== '' && addressObj.addressLine3 !== null && addressObj.addressLine3 !== '') {
                        address += ' ' + addressObj.addressLine3;
                    }
                } else if(typeof(addressObj.addressLine3) !== 'undefined' && addressObj.addressLine3 !== '' && addressObj.addressLine3 !== null && addressObj.addressLine3 !== '') {
                    address += ' ' + addressObj.addressLine3;
                }
            } else if(typeof(addressObj.addressLine2) !== 'undefined' && addressObj.addressLine2 !== '' && addressObj.addressLine2 !== null && addressObj.addressLine2 !== '') {
                address = addressObj.addressLine2;

                if(typeof(addressObj.addressLine3) !== 'undefined' && addressObj.addressLine3 !== '' && addressObj.addressLine3 !== null && addressObj.addressLine3 !== '') {
                    address += ' ' + addressObj.addressLine3;
                }
            } else if(typeof(addressObj.addressLine3) !== 'undefined' && addressObj.addressLine3 !== '' && addressObj.addressLine3 !== null && addressObj.addressLine3 !== '') {
                address = addressObj.addressLine3;
            }

            address += " " + this.getCityStateZipLabel(addressObj);

            return address;
        },
        getCityStateZipLabel: function(address) {
            var final = '';

            // Ensure there is an address object
            if(typeof address !== 'undefined' && address !== null) {
                // Ensure there is a city string, ifso, append it to the final return value
                if(typeof address.city !== 'undefined' && address.city !== null && address.city !== '') {
                    final += address.city;

                    // Ensure there is a state string; ifso, append it to the final return value
                    if(typeof address.stateName !== 'undefined' && address.stateName !== null && address.stateName !== '') {
                        final += ', ' + address.stateName;

                        // Ensure there is a zip string; ifso, append it to the final return value
                        if(typeof address.postalCode !== 'undefined' && address.postalCode !== null && address.postalCode !== '') {
                            final += ' ' + address.postalCode;
                        }
                    }
                } else if(typeof address.stateName !== 'undefined' && address.stateName !== null && address.stateName !== '') {
                    final += ', ' + address.stateName;

                    // Ensure there is a zip string; ifso, append it to the final return value
                    if(typeof address.postalCode !== 'undefined' && address.postalCode !== null && address.postalCode !== '') {
                        final += ' ' + address.postalCode;
                    }
                } else if(typeof address.postalCode !== 'undefined' && address.postalCode !== null && address.postalCode !== '') {
                    final += address.postalCode;
                }
            }

            return final;
        },
        isThereAnAddress: function(address) {
            if(typeof address === 'undefined' || address === null) {
                return false;
            }

            address = this.formatAddress(address);

            return address !== null;
        }
    };
}]);