app.service('NMLSService', ['$http', '$q', function($http, $q) {
    var _this = this;

    _this.response = null;

    _this.getBioSigUri = function (redirectUrl, courseId, courseItemIdentifier, activity, nmlsID, email, d4, d5) {
        var data = {
            'Id': nmlsID,
            'Email': email,
            'RedirectUrl': redirectUrl,
            'CourseId': courseId,
            'CourseItemIdentifier': courseItemIdentifier,
            'Activity': activity,
            'd4': d4,
            'd5':d5
        };

        var req = {
            method: 'POST',
            url: 'api/NMLS/BSIoutbound',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
            timeout: 100000
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getBioSigHelpPage = function () {
     
        var req = {
            method: 'GET',
            url: 'api/NMLS/GetBSIHelpPage',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

}]);
