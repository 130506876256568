app.directive('adminOverlay', ['$compile', function ($compile) {
    return {
        scope: true,
        restrict: 'A',
        link: function (scope, element, attrs) {

            var issublevelAdmin = scope.isSubDomainAdmin;

            //UsersService.isSubDomainAdmin().then(function (success) {
            //    issublevelAdmin = success;
            //},function(failure) {
            //    issublevelAdmin = false;
            //});

            if (issublevelAdmin === true) {
                var overlayDiv = angular.element('<div id="adminoverlay" class="row adminoverlay"><div id="text"></div></div>');
                overlayDiv.appendTo(element);
                $compile(overlayDiv)(scope);
            }
            
        }
    };
}]);