/* Directive to only accept numbers in an inpupt field */
app.directive('baiNumbersOnlyInput', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9]/g, '');

                    if (transformedInput !== text) {
                        ctrl.$setViewValue(transformedInput);
                        ctrl.$render();
                    }
                    return transformedInput;
                }
                return null;
            }
            ctrl.$parsers.push(fromUser);
        }
    };
});