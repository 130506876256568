app.directive('baiInsightsDecisionMapCourses', [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/templates/insights/mappingdecisions/partials/decisionMapCourses.html',
        scope: { decisionMapId: '@' },
        controller: ['$scope', '$q', '$location', '$timeout','InsightsService', 'InsightsDecisionMapService','errorDisplayerUtil', 'CoursesService','UsersService',
            function ($scope, $q, $location, $timeout, InsightsService, InsightsDecisionMapService, errorDisplayerUtil, CoursesService, UsersService) {
             
                var _this = this;
                $scope.rowsAreLoading = true;
                $scope.isNextLoading = true;
                _this.selectedMinutes = 0;
                $scope.selectedDuration = 0 + ' Hours ' + 0 + ' Minutes';
                $scope.errorThrown = false;
                $scope.commentType = 'decisionMapComment';
                $scope.commentLayout = 'col-md-12';
                $scope.newCommentText = '';
                $scope.commentsClass = 'insightsComments';
                $scope.stepTips = 'Clicking course titles reveals course descriptions. Course descriptions include regulations covered and estimated duration. Including comments to explain selections is recommended. If you are looking for courses not displayed in the listings, you can click <b>See More</b> for a complete course list. There may be other risks or training requirements of your specific regulator, state, or financial institution that BAI Training Insights does not address. It is recommended you also reference those and what steps you are taking to address them in the comments.';
                $scope.unrelatedModal = [];
                $scope.removedSelections = [];
                $scope.currentWizardStep = 'courses';
                $scope.peerPopoverFlags = [];
                $scope.relatedPopoverFlags = [];
                $scope.previewCourseLoading = false;
                $scope.previewCourseError = false;

                var currentUserRole = UsersService.getCurrentRoleType();
                if (currentUserRole === 'ta') {
                    $scope.currentRoleTA = true;
                }

                $scope.notApplyText = "The following Courses that you previously selected are not typically associated with the Decision Report's current Function, Role, Responsibilities, Experiences and Risks selections.";
                $scope.currentWizardStepPretty = 'Courses';
                /*
                    Calls to get the Decision Map object, the DM Course List and Peer Assignments Course List
                */
                var decMapGetCourses = InsightsService.getDecisionMapComputedCourses($scope.decisionMapId);
                var peerCourses = InsightsService.getDecisionMapPeerCourses($scope.decisionMapId);
                var promises = [];
                promises.push(decMapGetCourses);
                promises.push(peerCourses);

                $scope.decisionMap = InsightsDecisionMapService.getDecisionMap();
                // If we don't have a decision map yet, make API call to get it
                if (_.isEmpty($scope.decisionMap)) {
                    var decMap = InsightsService.getDecisionMapById($scope.decisionMapId);
                    promises.push(decMap);
                } else {
                    // If the decision map is not empty and the status is rule created, redirect to report because course selection cannot be changed
                    if ($scope.decisionMap.status === 'Rule Created' || $scope.decisionMap.status === 'Archived') {
                        $location.path('insights/decisionmapreport/' + $scope.decisionMap.id);
                    }
                }

                $q.all(promises).then(function (success) {
                    $scope.rowsAreLoading = false;
                    $scope.isNextLoading = false;
                    $scope.relatedCourses = success[0];
                    _.each($scope.relatedCourses, function (dm) {
                        $scope.relatedPopoverFlags.push(false);
                    });
                    $scope.peerCourses = success[1];
                    _.each($scope.peerCourses, function (dm) {
                        $scope.peerPopoverFlags.push(false);
                    });
                    if (success.length === 3) {
                        $scope.decisionMap = success[2];
                        InsightsDecisionMapService.setDecisionMap($scope.decisionMap);
                        // If the decision map status is rule created, redirect to report because course selection cannot be changed
                        if ($scope.decisionMap.status === 'Rule Created' || $scope.decisionMap.status === 'Archived') {
                            $location.path('insights/decisionmapreport/' + $scope.decisionMap.id);
                        }
                    }
                    $scope.preSelectCourses();

                    $scope.showModal = $scope.decisionMap.confirmCourseSelection;
                    $scope.decisionMapName = $scope.decisionMap.name;
                    if ($scope.showModal && $scope.unrelatedModal.length > 0) {
                        $scope.unrelatedModal = _.sortBy($scope.unrelatedModal, 'value');
                        angular.element(document.querySelector('#confirmModal')).modal();
                    } else {
                        InsightsDecisionMapService.resetModalFlag('courses');
                    }

                }, function (failure) {
                    $scope.rowsAreLoading = false;
                    if (failure.StatusCode === 404) {
                        $scope.errorMessage = "No Courses Found";
                    } else {
                        $scope.errorMessage = "Error - "+failure.ErrorMessage;
                    }
                    $scope.errorThrown = true;
                });


                /*
                   Pre-select any courses that were selected for a draft rule 
                */
                $scope.preSelectCourses = function () {                    
                    var preSelectedCourses = $scope.decisionMap.courses;
                    _.each(preSelectedCourses, function (c) {
                        //Pre select the courses in the decision map course list
                        var relatedIndex = _.findIndex($scope.relatedCourses, { "id": c.id });
                        var relatedIndexP = _.findIndex($scope.peerCourses, { "id": c.id });
                        if (relatedIndex > -1) {
                            $scope.relatedCourses[relatedIndex].select = true;
                            $scope.pushInArray($scope.relatedCourses[relatedIndex]);
                        } else {
                            c.select = true;   
                            if (relatedIndexP === -1) {
                                $scope.relatedCourses.push(c);
                                $scope.pushInArray(c);
                                $scope.unrelatedModal.push({ 'key': c.id, 'value': c.name });
                            }
                        }
                        //Pre select the courses in the peer assignment course list
                        if (relatedIndexP > -1) {
                            $scope.peerCourses[relatedIndexP].select = true;
                            $scope.pushInArray($scope.peerCourses[relatedIndexP]);
                        }
                    });
                };


                /*
                    Course list table sorting 
                */
                $scope.propertyName = 'rank';
                $scope.peerPropertyName = 'rank';
                $scope.reverse = false;
                $scope.peerReverse = false;

                $scope.sortBy = function (propertyName) {
                    $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                    $scope.propertyName = propertyName;
                };

                $scope.sortByPeers = function (peerPropertyName) {
                    $scope.peerReverse = ($scope.peerPropertyName === peerPropertyName) ? !$scope.peerReverse : false;
                    $scope.peerPropertyName = peerPropertyName;
                };

               /* 
                    Course search
                */
                $scope.openCourseSearch = function () {
                    closeAllPopovers();
                    // Send 'openCourseSearch' "event" to the child directives
                    $scope.$broadcast('openAddAssignmentsModal', { displaytype: 1, studentId: null });
                };

                $scope.selectCourseInList = function(selectedCourses) {
                    _.each(selectedCourses, function (course) {

                        //For selected courses from the modal, set the canPreview flag to true for wbt courses
                        if (course.type === 'wbt') {
                            course.canPreview = true;
                        } else {
                            course.canPreview = false;
                        }
                        // Find if course is already in related list
                        var relatedIndex = _.findIndex($scope.relatedCourses, { "id": course.id });
                        // If not in related list, check peer course list
                        if (relatedIndex === -1) {
                            var peerIndex = _.findIndex($scope.peerCourses, { "id": course.id });
                            if (peerIndex > -1) {
                                // If in peer course list, select it
                                $scope.peerCourses[peerIndex].select = true;
                                $scope.pushInArray($scope.peerCourses[peerIndex]);
                            } else {
                                // If not in related or peer lists, add it to related list
                                var relatedCourse = angular.copy(course);
                                relatedCourse.select = true;
                                $scope.relatedCourses.push(relatedCourse);
                                $scope.pushInArray(relatedCourse);
                            }
                        } // end not in related list
                        else { // In related list, check it there
                            $scope.relatedCourses[relatedIndex].select = true;
                            $scope.pushInArray($scope.relatedCourses[relatedIndex]);
                        }
                    });
                };

                /*
                    Select Courses for Draft Rule
                */
                $scope.selectedCourses = [];
                $scope.selectAll = {};
                $scope.selectAll.checked = false;
                $scope.pushInArray = function (course) {

                    if ($scope.selectAll.checked)
                        $scope.selectAll.checked = false;

                    var index = _.findIndex($scope.selectedCourses, { "id": course.id });
                    //Get the indexes from the relatedCourses and peerCourses
                    var relIndex = _.findIndex($scope.relatedCourses, { "id": course.id });
                    var pIndex = _.findIndex($scope.peerCourses, { "id": course.id });

                    if (course.select) {
                        if (index === -1) {
                            //If the course is being selected and does not already exist in the selected courses array
                            //  add it and add the hours
                             $scope.selectedCourses.push({ "id": course.id, "name": course.name, "description": course.description, "duration": course.duration });
                            _this.selectedMinutes += course.duration;
                        }

                        //If the course selected exists in both lists then check the course in the 'other' list
                        if (relIndex !== -1 && $scope.relatedCourses[relIndex].select !== true) {
                            $scope.relatedCourses[relIndex].select = true;
                        }
                        if (pIndex !== -1 && $scope.peerCourses[pIndex].select !== true) {
                            $scope.peerCourses[pIndex].select = true;
                        }
                    } else {
                        //if the course is being removed and is in the selected courses array
                        if (index !== -1) {
                            //if the course is being removed and is in the selected courses array
                            // remove it and get rid of the hours
                            $scope.selectedCourses.splice(index, 1);
                            _this.selectedMinutes = _this.selectedMinutes - course.duration;
                        }


                        //If the course selected exists in both lists then uncheck the course in the 'other' list
                        if (relIndex !== -1 && $scope.relatedCourses[relIndex].select === true) {
                            $scope.relatedCourses[relIndex].select = false;
                        }
                        if (pIndex !== -1 && $scope.peerCourses[pIndex].select === true) {
                            $scope.peerCourses[pIndex].select = false;
                        }
                    }
                    var hours = Math.floor(_this.selectedMinutes / 60);
                    var minutes = _this.selectedMinutes % 60;
                    $scope.selectedDuration = hours + ' Hours ' + minutes + ' Minutes';
                };


                /* Remove all unassociated results in the confirm selections modal */
                $scope.removeAllSelections = function () {

                    //Add all modal results to removedSelections to be used to update the main page
                    _.each($scope.unrelatedModal, function (u) {
                        $scope.removedSelections.push({ "key": u.key });
                    });
                    //wipe out the unrelatedModal array
                    $scope.unrelatedModal = [];
                };

                /* Remove one unassociated result in the confirm selections modal */
                $scope.removeSelection = function (key) {

                    var relatedIndex = _.findIndex($scope.unrelatedModal, { "key": key });
                    if (relatedIndex > -1) {
                        //Find the selection in the unrelatedModal - delete 
                        //add the selection to removedSelections to be used to update the main page
                        $scope.unrelatedModal.splice(relatedIndex, 1);
                        $scope.removedSelections.push({ "key": key });
                    }
                };

                /* Confirm Selections modal Save */
                $scope.saveModalSelectionsOnPage = function () {
                    //Hide the modal and reset the flag for the step
                    angular.element(document.querySelector('#confirmModal')).modal('hide');
                    InsightsDecisionMapService.resetModalFlag('courses');

                    //remove selections on main page
                    _.each($scope.removedSelections, function (s) {
                        var relatedCoursesIndex = _.findIndex($scope.relatedCourses, { "id": s.key });
                        if (relatedCoursesIndex > -1) {
                            //Set select to false for the course being removed and reset the total duration by calling pushInArray
                            $scope.relatedCourses[relatedCoursesIndex].select = false;
                            $scope.pushInArray($scope.relatedCourses[relatedCoursesIndex]);
                            $scope.relatedCourses.splice(relatedCoursesIndex, 1);
                        }
                    });

                };

                $scope.openPopover = function (index, courseList) {
                    closeAllPopovers();
                    if (courseList === 'peerCourses') {
                        $scope.peerPopoverFlags[index] = true;
                    } else if (courseList === 'relatedCourses') {
                        $scope.relatedPopoverFlags[index] = true;
                    }
                    
                    $scope.previewCourseError = false;
                };

                var closeAllPopovers = function () {
                    var i = 0;
                    for (i = 0; i < $scope.peerPopoverFlags.length; i++) {
                        $scope.peerPopoverFlags[i] = false;
                    }
                    for (i = 0; i < $scope.relatedPopoverFlags.length; i++) {
                        $scope.relatedPopoverFlags[i] = false;
                    }
                };

                $scope.previewCourse = function (courseId) {
                    $scope.previewCourseLoading = true;

                    var coursePreviewCall = CoursesService.getCoursePreviewUrl(courseId);
                    coursePreviewCall.then(function (success) {
                        window.open(success, '_blank');
                        $scope.previewCourseLoading = false;
                    }, function (failure) {
                        $scope.previewCourseLoading = false;
                        $scope.previewCourseError = true;
                        $scope.previewCourseErrorMessage = "Error - " + failure.ErrorMessage;
                    });
                };

                /* 
                    Next button
                */
                //Save the decision map and then load the report
                $scope.gotToNextStep = function () {
                    $scope.isNextLoading = true;
                    //set the selected courses at the service level
                    InsightsDecisionMapService.setSelectedCourses($scope.selectedCourses);
                    var decMap = InsightsDecisionMapService.getDecisionMap();
                    var decisionMapUpdate = InsightsService.updateDecisionMap(decMap);

                    var savePromises = [];
                    savePromises.push(decisionMapUpdate);

                    if ($scope.newCommentText !== undefined && $scope.newCommentText !== '') {
                        var saveComment = InsightsService.saveComment($scope.decisionMapId, $scope.newCommentText, $scope.commentType);
                        savePromises.push(saveComment);
                    }

                    $q.all(savePromises).then(function (success) {
                        $scope.isNextLoading = false;
                        // Go to the decision map report page
                        $location.path("insights/decisionmapreport/" + decMap.id);
                    }, function (failure) {

                        $scope.rowsAreLoading = false;
                        $scope.errorMessage = failure.ErrorMessage;
                        $scope.errorThrown = true;
                    });

                };


                /* 
                    Back button
                */
                $scope.goBackInWizard = function () {
                    InsightsDecisionMapService.setSelectedCourses($scope.selectedCourses);
                    $location.path("insights/wizard/risks");
                };

            }]
    };
}]);