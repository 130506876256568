app.service('CoursesService', ['$http', '$q', '$filter', 'courseFormatterUtil', 'dateUtil', 'Upload', function ($http, $q, $filter, courseFormatterUtil, dateUtil, Upload) {
    var _this = this;

    _this.response = null;
    _this.searchableCatalog = [];
    /**
     * Format all data that comes in
     *
     * Currently, nothing needs to be formatted immediately on response
     */
    _this.format = function (response) {
        var dates = ['assignedOn', 'completedDate', 'dueDate', 'startDate', 'endDate', 'endDateTime', 'startDateTime', 'displayStart', 'displayEnd', 'requestedOn', 'registrationEndDateTime'];

        var current, meetings = null;
        var nmlsCourseInResults = false;

        if (response !== null) {
            for (var assignment in response) {
                current = response[assignment];
                for (var node in current) {
                    if (current[node] !== null && $.inArray(node, dates) !== -1) {
                        current[node] = dateUtil.formatDate(current[node]);
                    }
                    // It's possible this way will find sessions during some api responses but not all
                    else if (node === 'offerings') {
                        for (var session in current.offerings) {
                            if (typeof current.offerings[session].sessions !== 'undefined' && current.offerings[session].sessions.length > 0) {
                                current.offerings[session].location = current.offerings[session].sessions[0].locationName;
                                meetings = current.offerings[session].sessions;
                                for (var offeringMeeting in meetings) {
                                    for (var meetingNode in meetings[offeringMeeting]) {
                                        if (meetings[offeringMeeting][meetingNode] !== null && $.inArray(meetingNode, dates) !== -1) {
                                            meetings[offeringMeeting][meetingNode] = dateUtil.formatDate(meetings[offeringMeeting][meetingNode]);
                                        }
                                    }
                                }
                            }
                        }
                    } else if (node === 'assignedOffering') {
                        if (typeof current.assignedOffering !== 'undefined' && current.assignedOffering !== null && typeof current.assignedOffering.sessions !== 'undefined' && current.assignedOffering.sessions.length > 0) {
                            meetings = current.assignedOffering.sessions;
                            for (var assignedOfferingMeeting in meetings) {
                                for (var assignedOfferingMeetingNode in meetings[assignedOfferingMeeting]) {
                                    if (meetings[assignedOfferingMeeting][assignedOfferingMeetingNode] !== null && $.inArray(assignedOfferingMeetingNode, dates) !== -1) {
                                        meetings[assignedOfferingMeeting][assignedOfferingMeetingNode] = dateUtil.formatDate(meetings[assignedOfferingMeeting][assignedOfferingMeetingNode]);
                                    }
                                }
                            }
                        }
                    }
                        // This finds sessions coming back from api/offering/courseid/{courseId}/
                    else if (node === 'sessions') {
                        var sessions = current[node];
                        for (var sessionIndex in sessions) {
                            var currentSession = sessions[sessionIndex];
                            for (var sessionNode in sessions[sessionIndex]) {
                                if (currentSession[sessionNode] !== null && $.inArray(sessionNode, dates) !== -1) {
                                    currentSession[sessionNode] = dateUtil.formatDate(currentSession[sessionNode]);
                                }
                            }

                        }
                    }
                }


                current.codeForImage = courseFormatterUtil.getCourseCodeForImage(current.code, current.tileImage);

                if (typeof current.displayEnd === "undefined" || current.displayEnd === null) {
                    current.isActive = true;
                } else {
                    current.isActive = current.displayEnd.jsDate > new Date();
                }
                current.catalogId = parseInt(current.catalogId, 10) > 0 ? current.catalogId : null;
                
                if (current !== null && typeof current.isNMLS !== 'undefined' && current.isNMLS) {
                    nmlsCourseInResults = true;
                }
                if (current.duration !== null) {
                    current.duration = Math.round(current.duration * 60); // Convert hours to minutes for display
                }

                if (current.description !== null && current.description !== undefined && current.description.length > 50) {
                    current.shortDescription = current.description.substring(0, 82) + ' ...';
                } else {
                    current.shortDescription = current.description;
                }
            }

            //Set the NMLS course in results flag to true if there are any courses in the assignment that are NMLS
            response.containsNMLSCourse = nmlsCourseInResults;
        }

        return response;
    };

    /**
     * Format all data that comes in for a 'simple' course
     *
     */
    _this.formatSimple = function (response) {
        var dates = ['displayStart', 'displayEnd'];

        if (response !== null) {
            for (var assignment in response) {
                current = response[assignment];
                for (var node in current) {
                    if (current[node] !== null && $.inArray(node, dates) !== -1) {
                        current[node] = dateUtil.formatDate(current[node]);
                    }

                    current.catalogId = parseInt(current.catalogId, 10) > 0 ? current.catalogId : null;
                    var catalogEntry = {};
                    catalogEntry.name = current.name;
                    catalogEntry.code = current.code;
                    _this.searchableCatalog.push(catalogEntry);




                    if (current.description !== null && current.description !== undefined && current.description.length > 50) {
                        current.shortDescription = current.description.substring(0, 82) + ' ...';
                    } else {
                        current.shortDescription = current.description;
                    }
                }
                if (current.type === "mP4") {
                    current.type = "mp4";
                }
                if (current.type === "mP3") {
                    current.type = "mp3";
                }
            }
        }

        return response;
    };

    _this.formatToSend = function(data) {
        var dates = ['endDate', 'startDate', 'displayStart', 'displayEnd', 'lastProcessedOn', 'lastUpdatedOn', 'acceptPassingScoreDate', 'dueDateFixed'];

        if (data.constructor !== Array) {
            for (var node in data) {
                if (typeof data[node] !== 'undefined' && data[node] !== null && $.inArray(node, dates) !== -1) {

                    if (typeof data[node].moment !== "undefined" && data[node].moment !== null && _.isObject(data[node].moment)) {
                        data[node] = data[node].moment.toISOString();
                    } else {
                        data[node] = null;
                    }
                }
                if (node === "duration") {
                    if (data[node] !== null) {
                        data[node] = data[node] / 60; // Convert duration from minutes to hours for API, if it has a value.
                    }
                }
                if (typeof data[node] !== 'undefined' && data[node] !== null && data[node].constructor === Array) {
                    _this.formatToSend(data[node]);
                }
            }
        }

        if (data.constructor === Array) {
            _.each(data, function(dataPoint) {
                _this.formatToSend(dataPoint);
            });
        }

        return data;
    };

    _this.format_skinned = function(data) {
        var dates = ['assignedOn', 'completedDate', 'dueDate', 'startDate', 'endDate', 'endDateTime', 'startDateTime', 'displayStart', 'displayEnd'];

        if (data.constructor !== Array) {
            for (var node in data) {
                if (typeof data[node] !== 'undefined' && data[node] !== null && $.inArray(node, dates) !== -1) {
                    if (data[node]) {
                        data[node] = dateUtil.formatDate(data[node]);
                    } 
                }
                if (node === "duration") {
                    if (data[node] !== null) {
                        data[node] = Math.round(data[node] * 60); // Convert duration from hours to minutes for UI
                    }
                }
                if (typeof data[node] !== 'undefined' && data[node] !== null && data[node].constructor === Array) {
                    _this.format_skinned(data[node]);
                }
            }
        }

        if (data.constructor === Array) {
            _.each(data, function(dataPoint) {
                _this.format_skinned(dataPoint);
            });
        }

        return data;
    };

    _this.getSingle = function(userId, courseId, offeringId) {
        offeringId = (typeof offeringId !== 'undefined' && offeringId !== null) ? (offeringId + '/') : '';
        var url = 'api/course/' + courseId + '/' + offeringId;

        var req = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.singleResponse = _this.format([success.data]);

            return _this.singleResponse;
        }, function(failure) {
            throw failure;
        });
    };
    //[Route("~/api/course/courseurl/{courseId}/{assignmentType}")]
    _this.getCourseUrl = function(assignmentId, type, userId) {
        var req = {
            method: 'GET',
            url: 'api/course/courseurl/' + assignmentId + '/' + type + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.courseResponse = success.data;
            return _this.courseResponse;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getCoursePreviewUrl = function (courseId) {
        var req = {
            method: 'GET',
            url: 'api/course/coursepreviewurl/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    //[Route("~/api/course/schedule/{userId}/{courseId}/{offeringId}/{type}")]
    _this.scheduleOffering = function(assignmentId, userId, type, offeringId) {
        var req = {
            method: 'POST',
            url: 'api/course/schedule/' + userId + '/' + assignmentId + '/' + offeringId + '/' + type + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            return success.data;
        }, function(failure) {
            throw failure;
        });
    };

    _this.completeTask = function(taskId, userId) {
        var req = {
            method: 'POST',
            url: 'api/user/' + userId + '/task/' + taskId + '/',
            headers: {
                'Content-type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = success.data;

            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.fetchMany = function(userId, courseIds) {
        var req = {
            method: 'POST',
            url: 'api/course/' + userId + '/',
            data: courseIds,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.requestManagerialApproval = function(courseId, type, userId, offeringId) {
        var url = 'api/course/approval/' + userId + '/' + courseId + '/' + offeringId + '/' + type + '/';

        //if(typeof offeringId !== 'undefined' && offeringId !== null && type !== 'wbt') {
        //    url += '/user/' + userId + '/' + type + '/requestapproval/' + offeringId;
        //} else {
        //    url += '/requestapprovalwbt';
        //}

        var req = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            return success.data;
        }, function(failure) {
            throw failure;
        });
    };

    ///api/Courses/{courseId}
    _this.getSingleCourse = function(courseId) {
        var url = 'api/course/' + courseId;

        var req = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.singleCourseResponse = _this.format([success.data])[0];
            return _this.singleCourseResponse;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getContentTypes = function () {
        var url = 'api/course/courseContentTypes/';

        var req = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    /* This creates a course and returns a course ID
     * Needs a course object in this format
     * var course = {};
     *
     * Route:https://localhost/lms-web/api/course/add
     { "type":"task",
     "name":"New CourseAfter Refactor",
     "Enabled":"true",
     "DisplayStart":"12-03-2016",
     "DisplayEnd":"12-13-2016",
     "code":"tsk322",
     "uri":"http://www.bai.org",
     "description":"Bai Task Desscription.." }
     */
    _this.createCourse = function(courseObj) {
        var req = {
            method: 'POST',
            url: 'api/course/',
            data: _this.formatToSend(courseObj),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            var response = _this.format_skinned(success.data);

            return response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.checkLatestCourse = function () {
        var latestmatch = false;
        var equal = {
            method: 'GET',
            url: 'api/course/latestCourse',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(equal).then(function (success) {
            var newlatest = success.data;
            if (_this.latestCourse !== undefined && newlatest === _this.latestCourse) {
                _this.latestCourse = newlatest;
                return true;
            }
            else {
                _this.latestCourse = newlatest;
                return false;
            }
        }, function (failure) {
            throw failure;
        });
    };

    _this.getActiveCoursesForOrg = function (refresh, formatForTA) {
            if (refresh === undefined) {
            refresh = true;
            }
                if (_this.allActiveCoursesForOrg !== undefined && _this.allActiveCoursesForOrg !== null && !refresh) {
                    if (formatForTA && _this.allActiveCoursesForOrg.sortedList === undefined) {
                        //if the course data is cached from calls other than from TA - make sure there is the TA formatted data - if not, add it
                        _this.formatForTACoursesTab(_this.allActiveCoursesForOrg);
                    }
                    return $q.resolve(_this.allActiveCoursesForOrg);


                } else {

                    _this.allActiveCategories = [];
                    _this.allActiveLibraries = [];
                    _this.searchableCatalog = [];
                    var req = {
                        method: 'GET',
                        url: 'api/course/Activecourses',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        timeout: window.timeout
                    };

                    return $http(req).then(function (success) {
                        var formattedResponse = _this.formatSimple(success.data);


                        _this.allActiveCoursesForOrg = _this.courseFormattedDates(formattedResponse);

                        if (formatForTA) {
                            _this.formatForTACoursesTab(_this.allActiveCoursesForOrg);
                        }

                        return _this.allActiveCoursesForOrg;
                    }, function (failure) {
                        throw failure;
                    });
                }
            
    };


    _this.getCoursesForOrg = function() {
        var req = {
            method: 'GET',
            url: 'api/course/courses',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            var formattedResponse = _this.format(success.data);


            _this.response = _this.courseFormattedDates(formattedResponse);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };


    _this.formatForTACoursesTab = function () {

        /**
         * Format the course data to use on the TA courses tab
         */
        _this.allActiveCoursesForOrg.sortedList = $filter('orderBy')(_this.allActiveCoursesForOrg, 'name');
        _this.allActiveCoursesForOrg.currentCourseSet = angular.copy(_this.allActiveCoursesForOrg.sortedList);
        _this.allActiveCoursesForOrg.customCourses = $filter('filter')(_this.allActiveCoursesForOrg, { baiOnly: false, displayEnd: null });
        var copy = angular.copy(_this.allActiveCoursesForOrg.sortedList);
        _this.allActiveCoursesForOrg.topOneHundred =  copy.splice(0, 100);
    };

    _this.getActiveCoursesForOrgFromCache = function () {
        return _this.allActiveCoursesForOrg;
    };


    _this.getSearchableCatalog = function () {
        return _this.searchableCatalog;
    };

    _this.getInactiveCoursesForOrg = function () {
        var req = {
            method: 'GET',
            url: 'api/course/inactivecourses',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            var formattedResponse = _this.formatSimple(success.data);


            _this.response = _this.courseFormattedDates(formattedResponse);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getCoursesByType = function (type, userId) {
        var userIdParam = userId === undefined ? 0 : userId;
        var req = {
            method: 'GET',
            url: 'api/course/bytype/'+type+'/'+userIdParam,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            var formattedResponse = _this.format(success.data);

            return formattedResponse;
        }, function (failure) {
            throw failure;
        });
    };

    _this.updateCourse = function(data) {
        var req = {
            method: 'PUT',
            url: 'api/course/',
            data: _this.formatToSend(data),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(function(success) {
            return _this.format_skinned(success.data);
        }, function(failure) {
            throw failure;
        });
    };

    _this.updateCoursePrerequisits = function (courseId, prereqsToUpdate, newPrereqList) {
        var pdata = { Key: prereqsToUpdate, Value: newPrereqList };

        var req = {
            method: 'POST',
            url: 'api/course/prerequpdate/' + courseId,
            data: JSON.stringify(pdata),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });



    };

    _this.courseFormattedDates = function(data) {
        _.each(data, function(dataPoint) {
            if (dataPoint.displayStart !== null && dataPoint.displayStart !== undefined)
                dataPoint.startDateFormatted = dataPoint.displayStart.fullDate;
            if (dataPoint.displayEnd !== null && dataPoint.displayEnd !== undefined)
                dataPoint.endDateFormatted = dataPoint.displayEnd.fullDate;
        });

        return data;
    };

    _this.getCourseGroup = function(courseGroupId, courseGroupType) {
        var req = {
            method: 'GET',
            url: 'api/coursegroup/' + courseGroupId + '/' + courseGroupType,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            return success.data;
        }, function(failure) {
            throw failure;
        });
    };

    _this.formatCourseIds = function(data) {
        if (data.length > 0) {
            return _.pluck(data, 'id');
        }
    };

    _this.getSingleCourseOfferings = function(courseId) {
        
        var req = {
            method: 'GET',
            url: 'api/offering/courseid/' + courseId + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);
            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getSingleCourseOfferingsOpen = function (courseId) {

        var req = {
            method: 'GET',
            url: 'api/offering/courseid/' + courseId + '/open/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getImodifyUrl = function (courseId) {
        var req = {
            method: 'GET',
            url: 'api/course/imodify/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.CreateIModifyCourse = function (courseId) {
        var req = {
            method: 'POST',
            url: 'api/course/imodify/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.resetIModifyCourseContent = function (courseId) {
        var req = {
            method: 'POST',
            url: 'api/course/resetimodify/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.resetCourseStatus = function(courseId, userId) {
        var req = {
            method: 'POST',
            url: 'api/course/reset/' + courseId + '/' + userId,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return $http(req).then(function(success) {
            return success.data;
        }, function(failure) {
            throw failure;
        });
    };


    _this.getSummaryCounts = function () {
        var req = {
            method: 'GET',
            url: 'api/course/summary',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getSummaryCountsByCourse = function () {
        var req = {
            method: 'GET',
            url: 'api/course/summarybycourse',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 100000
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getUserSummariesByCourse = function (courseID) {
        var req = {
            method: 'GET',
            url: 'api/course/usersummariesbycourse/' + courseID,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return _this.formatResponse(success.data);
        }, function (failure) {
            throw failure;
        });
    };

    _this.getCoursePrereqs = function (courseID) {
        var req = {
            method: 'GET',
            url: 'api/course/prereq/' + courseID,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.formatResponse = function (data) {
        _.each(data, function (summary) {
            summary.assignment.enrolledDate = _this.formatDate(summary.assignment.enrolledDate);
            summary.assignment.assignedOn = _this.formatDate(summary.assignment.assignedOn);
            summary.assignment.completedDate = _this.formatDate(summary.assignment.completedDate);
            summary.assignment.status = _this.formatStatus(summary.assignment.status);
        });
        return data;
    };

    _this.formatDate = function (date) {
        return date === null ? null : dateUtil.formatDate(date);
    };

    _this.formatStatus = function (status) {
        var result = {};
        switch (status) {
            case 'new':
                result = 'New';
                break;
            case 'inProgress':
                result = 'In Progress';
                break;
            case 'successful':
                result = 'Succesful';
                break;
            default:
                result = 'Unknown';
                break;
        }
        return result;
    };

    _this.getCourseWithAssignment = function(courseId) {
        var req = {
            method: 'GET',
            url: 'api/course/' + courseId + '/assignment',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            console.log("failed");
            throw failure;
        });
    };

    _this.getPreviewStatus = function (courseId) {
        var req = {
            method: 'GET',
            url: 'api/course/preview/status/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.uploadScormPackage = function (scormPackage, courseId, shellType) {
        return Upload.upload({
            url: 'api/course/content/' + courseId + '/' + shellType + '/',
            data: { file: scormPackage },
        }).then(function (success) {
            return success;
        }, function (error) {
            throw error;
        });
    };

    _this.getDocumentDetailsforAttachedLaunch = function (resourceId) {
        var req = {
            method: 'GET',
            url: 'api/course/attachedDocumentLaunch/' + resourceId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });

    };

}]);
