app.directive("courseGroupSlideDown", ['$rootScope', '$sce', 'AssignmentsService', 'CoursesService', 'TasksService', 'UsersService', 'EvaluationService', 'SurveyService', 'CareerPathService', function ($rootScope, $sce, AssignmentsService, CoursesService, TasksService, UsersService, EvaluationService, SurveyService, CareerPathService) {
    // returns the Directive Definition Object
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'app/templates/users/partials/courseGroupSlideDown.html',
        link: function (scope, element, attrs, ctrl) {
            
            scope.managerView = typeof attrs.managerview !== 'undefined' && attrs.managerview === 'true' ? true : false;

            scope.$on('slider:view:close', function (event, data) {
                scope.closeSlider();
            });

            var lastIndex = -1;
            var showInfoLinkText = "Read More";
            var hideInfoLinkText = "Read Less";

            element = element.children('#courseGroupList');

            var openCloseInfo = function ($event, assignment, showInfoLinkText, hideInfoLinkText) {
                jQuery('.more').html(showInfoLinkText);
                jQuery('.courseGroupMore').html(showInfoLinkText);

                if (jQuery(element).data('state') === 'opened') {
                    jQuery('#offeringSlide').slideUp();

                    if (jQuery('.arrow').length > 0) {
                        jQuery('.arrow').hide(1, function () {
                            jQuery('#courseGroupList').slideUp();

                        });
                    } else {
                        jQuery(element).slideUp();
                    }

                    jQuery(element).data('state', 'closed');
                }

                //Close any single course slide downs
                var singleCourseSlideDown = jQuery('#singlecourse');
                if (jQuery(singleCourseSlideDown).data('state') === 'opened') {
                    jQuery('#offeringSlide').slideUp();
                    if (jQuery('.arrow').length > 0) {
                        jQuery('.arrow').hide(1, function () {
                            jQuery('#singlecourse').slideUp();
                        });
                    } else {
                        jQuery(singleCourseSlideDown).slideUp();
                    }

                    jQuery(singleCourseSlideDown).data('state', 'closed');
                }

                //Look for any View Resources list open and close them
                var viewResourceElem = jQuery('#viewResources');
                if (jQuery(viewResourceElem).data('state') === 'opened') {
                    if (jQuery('.arrow').length > 0) {
                        jQuery('.arrow').hide(1, function () {
                            jQuery('#viewResources').slideUp();
                        });
                    } else {
                        jQuery(viewResourceElem).slideUp();
                    }

                    jQuery(viewResourceElem).data('state', 'closed');
                    jQuery('.resource').html('View Resources');
                }

                scope.apiError = false;
                scope.singleIsLoading = false;

                var children, lastChild, mover, parent = null;
                var currentIndex, endOfRow, modIndex, modulo = 0;
                var found = false;
                var target = $event.currentTarget;

                currentIndex = 0;

                children = jQuery(target).parents('#courseList').children('.col-md-4');
                if (children.length > 0) {
                    parent = jQuery(target).parents('.col-md-4')[0];

                    modulo = jQuery(window).width() > 768 ? 3 : 1;

                    children.each(function (index) {
                        var id = jQuery(parent).prop('id');

                        jQuery(parent).prop('id', null);

                        if (jQuery(this)[0] === parent) {
                            currentIndex = index + 1;

                            found = true;
                        }

                        jQuery(parent).prop('id', id);
                        if (found === true) {
                            found = false;

                            jQuery('.col-md-4').removeClass('selected');
                            jQuery(parent).addClass('selected');

                            return;
                        }
                    });

                    if (currentIndex === lastIndex) {
                        lastIndex = -1;

                        return;
                    }

                    jQuery(parent).children('.arrow').show(1, function () {
                        jQuery(element).slideDown();
                    });

                    /* Create the dimensions of the arrow on the slide down menu */
                    var assignmentContainerWidth = jQuery(parent).width();
                    // Set the sides of the triangle 
                    var triangleSideWidth = assignmentContainerWidth / 2;
                    jQuery(parent).children('.arrow').children('.point').css('border-left',
                                                      triangleSideWidth + "px solid transparent");
                    jQuery(parent).children('.arrow').children('.point').css('border-right',
                                                      triangleSideWidth + "px solid transparent");

                    // Set the height of the triangle and move it a bit down so it doesn't
                    // overlap anything
                    var triangleHeight = 40;
                    jQuery(parent).children('.arrow').children('.point').css('border-top',
                                                      triangleHeight + "px solid #e5e5e5");

                    // .75 is based on the height of .arrowTopBorder.
                    // So if the triangle is 40px high, needs to be shifted by 30px to not overlap
                    // if triangle is 20px high, needs to be shifted by 20px to not overlap.
                    // To make this clearer, replace .75 with something based on .arrowTopBorder height
                    var triangleShift = triangleHeight * 0.75; // .75 is arbitrary right meow.
                    jQuery(parent).children('.arrow').children('.point').css('top',
                                                      triangleShift + "px");

                    // Set endOfRow as current index - 1, as
                    endOfRow = currentIndex - 1;
                    modIndex = currentIndex % modulo; // modulo is 4 3 or 1 depending on columns

                    if (modIndex > 0) {
                        endOfRow = (currentIndex - modIndex) + (modulo - 1); // Subtract one from module because we are accessing array
                    }

                    lastChild = children[endOfRow];
                    if (typeof lastChild === 'undefined') {
                        lastChild = children[children.length - 1];
                    }

                    mover = jQuery(lastChild);
                } else {
                    target = jQuery($event.currentTarget);
                    parent = target.parents('.col-md-12');

                    currentIndex = parseInt(parent.prop('id').split('-')[1], 10) + 1;

                    if (currentIndex === lastIndex) {
                        lastIndex = -1;

                        return;
                    }

                    jQuery(element).slideDown();
                    jQuery(parent).children('.arrow').slideDown();

                    mover = jQuery(parent);
                }

                scope.courseGroup = assignment;
                scope.courseGroup.skillsCount = 0;
                scope.courseGroup.achievedSkillsCount = 0;
                scope.getCourseGroupData(assignment);

                // Assign english name to assignment
                if (assignment.type === 'wbt') {
                    scope.courseGroup.fullTypeName = 'Web Based';
                } else if (assignment.type === 'courseGroupCertification') {
                    scope.courseGroup.fullTypeName = 'Certification';
                } else if (assignment.type === 'courseGroupCurricula') {
                    scope.courseGroup.fullTypeName = 'Curricula';
                }
                else {
                    scope.courseGroup.fullTypeName = assignment.type;
                }

                if (jQuery($event.currentTarget).children().length > 0) {
                    jQuery($event.currentTarget).children('.more').html(hideInfoLinkText);
                } else {
                    jQuery($event.currentTarget).html(hideInfoLinkText);
                }

                jQuery(element).data('state', 'opened');

                jQuery(mover).after(jQuery(element));

                lastIndex = currentIndex;
            };

            scope.openCareerPathInfo = function ($event, assignment) {
                scope.childCourseOrderProperty = null;
                showInfoLinkText = "Course List";
                hideInfoLinkText = "Hide Course List";

                openCloseInfo($event, assignment, showInfoLinkText, hideInfoLinkText);
            };

            scope.openCourseGroupInfo = function ($event, assignment) {
                scope.childCourseOrderProperty = scope.orderProperty;
                openCloseInfo($event, assignment, showInfoLinkText, hideInfoLinkText);
            };

            scope.closeSlider = function() {
                jQuery('#courseGroupList').slideUp(500, function () {
                    jQuery('.arrow').hide();

                    jQuery('.more').html(showInfoLinkText);
                });

                lastIndex = -1;
            };

            scope.getCourseGroupData = function (assignment) {
                scope.apiError = false;
                scope.singleIsLoading = true;

                var courseGroupID = assignment.courseGroupId || assignment.id;
                var courseType = assignment.type;
                var student = assignment.studentId;
                var courseGroupType = courseType;

                if (courseType === 'courseGroupCurricula') { courseGroupType = 'Curricula'; }
                else if (courseType === 'courseGroupCertification') { courseGroupType = 'Certification'; }

                var courseGroups = AssignmentsService.getCourseGroupCourses(student, courseType, courseGroupID);

                courseGroups.then(
                    function (success) {
                        success.isScheduled = false;
                        var courseGroupdata = success;
                        if (courseType === 'careerPath') {
                            for (i = courseGroupdata.length - 1; i >= 0; --i) {
                                current = courseGroupdata[i];
                                current.skillsList = "";
                                if (current.skills !== null) {
                                    for (s = 0; s < current.skills.length; s++) {
                                        if (s === 0) {
                                            current.skillsList = current.skills[s].name;
                                        } else {
                                            current.skillsList += '; ' + current.skills[s].name;
                                        }
                                    }
                                }
                            }

                            var summary = CareerPathService.createSummary(assignment, courseGroupdata);
                            scope.courseGroup.skillsCount = summary.skills.total;
                            scope.courseGroup.achievedSkillsCount = summary.skills.completed;
                            scope.courseGroup.totalCourses = summary.courses.total;
                            scope.courseGroup.totalCompletedCourses = summary.courses.completed;
                        } else {
                            var completedCourses = 0;
                            //Get the amount of courseGroup courses before we take out successful records
                            scope.courseGroup.totalCourses = courseGroupdata.length;
                            for (i = courseGroupdata.length - 1; i >= 0; --i) {
                                current = courseGroupdata[i];
                                //Remove any successful results, student does not see successful courses
                                if (current.status === 'successful') {
                                    courseGroupdata.splice(i, 1);
                                    completedCourses++;
                                }
                            }

                            scope.courseGroup.totalCompletedCourses = completedCourses;
                        }
                        scope.courseGroupCourses = courseGroupdata;
                        scope.singleIsLoading = false;
                    }, function (failure) {
                        scope.totals = false;
                        scope.courseGroupCourses = [];
                        if (failure.StatusCode !== 404) {
                            scope.apiError = true;
                        }
                        scope.singleIsLoading = false;
                    }
                );
            };        
        }
    };
}]);
