app.factory('Dashboard_AdminOverride', ['$httpParamSerializer', '$injector', '$timeout', 'CoursesService', 'errorDisplayerUtil', 'SettingsService',
function ($httpParamSerializer, $injector, $timeout, CoursesService, errorDisplayerUtil, SettingsService) {
    return function ($scope) {

        var _this = this;

        $scope.defaultHeader = true; // This Sets the page to full width of the screen.
        $scope.hideCatalogSearch = true;
        /* This will make the navigation tab active on certain page */
        $scope.activeTab = 'tab-active-class';

        /*This will route users to the appropiate user manual based on active tab*/
        $scope.userManualHref = 'https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Create-Career-Path';

        $scope.currentFocus='list';

        var sett = SettingsService.getSettings().then(function (success) {
            /* Career Path access determined by the organization settings */
            $scope.showTrainingInsights = SettingsService.isTrainingInsightsEnabled();
            $scope.showCareerPath = SettingsService.isCareerPathEnabled();
            if ($scope.showCareerPath) {
                $scope.homeTemplate = 'app/templates/dashboard/partials/admin.content.html';
            }
        });

        
        $scope.homeAction = function () {
            $scope.contentTemplate = 'app/templates/dashboard/home/admin.html';
            $scope.showList();
            $scope.dashboardLoading = true;
            $scope.dashboardSummaryError = false;
            $scope.listLoading = true;
            $scope.listErrorThrown = false;
            $scope.showCareerPathWelcomeMessage = false;

            /* Get Summary Counts */
            var summarycountsCall = CoursesService.getSummaryCounts();
            summarycountsCall.then(function (success) {
                $scope.dashboardLoading = false;
                $scope.dashboardSummaryCounts = success;
            }, function (failure) {
                $scope.dashboardLoading = false;
                $scope.dashboardSummaryError = true;
                $scope.dashboardSummaryErrorMessage = "There was an error loading Career Path summary data";
            });

            var summarycountsByCourseCall = CoursesService.getSummaryCountsByCourse();
            summarycountsByCourseCall.then(function (success) {
                $scope.listLoading = false;
                $scope.careerPathList = success;
                $scope.showCareerPathWelcomeMessage = success.length > 0 ? false : true;
                createCareerPathDoughnut(success);
            }, function(failure) {
                $scope.listLoading = false;
                $scope.listErrorThrown = true;
                $scope.listErrorMessage = "There was an error loading the Career Path list data";
            });
        };

        /* sort and group career path data for the doughnut */
        var createCareerPathDoughnut = function (cpData) {

            /* filter out any CPs with 0 enrollments and sort on enrollmentscount to get top 5 */
                cpData = cpData.filter(function (e) { return e.totalStudentEnrolledCount > 0; });
                var dataOrdered = _.sortBy(cpData, 'totalStudentEnrolledCount');
                
                var totalCPs = dataOrdered.length;
                if (totalCPs === 0) {
                    //create a greyed out doughnut
                    $scope.careerPathDoughnutData = [{
                        value: 1,
                        color: "#808080",
                        label: "",
                        displayName: "abcdefghijklmnop"
                    }];
                } else {

                    var i = (dataOrdered.length > 5) ? dataOrdered.length - 5 : dataOrdered.length;
                    var count = 0;
                    var doughnutCounter = 0;
                    $scope.extraCPs = {
                        assignmentsCount: 0,
                        completionsCount: 0,
                        enrollmentsCount: 0,
                        inProgressCount: 0,
                        notStartedCount: 0,
                        skillsDevelopingCount: 0,
                        totalStudentEnrolledCount: 0
                    };
                    /* put each career path either in the top five list or the 'other' list (and add up all values for 'others') */
                    dataOrdered.forEach(function (x) {
                        if (count < i && (totalCPs > 5)) {
                            $scope.extraCPs.assignmentsCount += x.assignmentsCount;
                            $scope.extraCPs.completionsCount += x.completionsCount;
                            $scope.extraCPs.enrollmentsCount += x.enrollmentsCount;
                            $scope.extraCPs.inProgressCount += x.inProgressCount;
                            $scope.extraCPs.notStartedCount += x.notStartedCount;
                            $scope.extraCPs.skillsDevelopingCount += x.skillsDevelopingCount;
                            $scope.extraCPs.totalStudentEnrolledCount += x.totalStudentEnrolledCount;
                        } else {
                            $scope.careerPathDoughnutData[doughnutCounter].value = x.totalStudentEnrolledCount;
                            $scope.careerPathDoughnutData[doughnutCounter].label = createTooltip(x.course.name, x);
                            $scope.careerPathDoughnutData[doughnutCounter].displayName = x.course.name;
                            doughnutCounter++;
                        }
                        count++;
                    });

                    if ($scope.extraCPs.totalStudentEnrolledCount > 0) {
                        $scope.careerPathDoughnutData[5].value = $scope.extraCPs.totalStudentEnrolledCount;
                        /* If there are 6 CPs then the sixth CP should display the name of the CP (not 'Other') */
                        var sixthCPLabel = totalCPs === 6 ? dataOrdered[0].course.name : 'Other';
                        $scope.careerPathDoughnutData[5].label = createTooltip(sixthCPLabel, $scope.extraCPs);
                        $scope.careerPathDoughnutData[5].displayName = sixthCPLabel;
                    }
                }

                $scope.cpdnut = $scope.setupDoughnut($scope.careerPathDoughnutData);
        };

        /* Creates the formatted tool tip for the doughnut */
        var createTooltip = function (cpName, path) {
            return '<b>' + cpName + '</b></br>Assigned ' + path.assignmentsCount +
                                    '</br>Enrollments ' + path.enrollmentsCount + '</br>Completions ' + path.completionsCount +
                                    '</br>In Progress ' + path.inProgressCount + '</br>Not Started ' + path.notStartedCount +
                                    '</br>Skills Developing ' + path.skillsDevelopingCount;
        };

        /* set up default doughnut data */
        $scope.careerPathDoughnutData = [
         {
             value: 0,
             color: "#80379B",
             label: "Career Path One",
             displayName: "abcdefghijklmnop"
         },
         {
             value: 0,
             color: "#00A1DE",
             label: "Career Path Two",
             displayName: "abcdefghijklmnop"
         },
          {
              value: 0,
              color: "#E87722",
              label: "Career Path Three",
              displayName: "abcdefghijklmnop"
          },
         {
             value: 0,
             color: "#EBB700",
             label: "Career Path Four",
             displayName: "abcdefghijklmnop"
         },
         {
             value: 0,
             color: "#92D400",
             label: "Career Path Five",
             displayName: "abcdefghijklmnop"
         },
         {
             value: 0,
             color: "#00A599",
             label: "Career Path Six",
             displayName: "abcdefghijklmnop"
         }
        ];

        /* set up the default doughnut options - with special function for the tooltip styling */
        $scope.defaultDoughnutOptions = {
            showTooltips: true,
            customTooltips: function (tooltip) {
                var tooltipEl = $('#chartjs-tooltip');

                if (!tooltip) {
                    tooltipEl.css({
                        opacity: 0
                    });
                    return;
                }

                // split out the label and value, don't show value
                var parts = tooltip.text.split(":");
                var innerHtml = '<span>' + parts[0].trim() + '</span>';
                tooltipEl.html(innerHtml);

                tooltipEl.css({
                    opacity: 1,
                    left: tooltip.chart.canvas.offsetLeft + tooltip.x + 'px',
                    top: tooltip.chart.canvas.offsetTop + tooltip.y + 'px',
                });
            },
            animation: true,
            percentageInnerCutout: 60
        };

        $scope.setupDoughnut = function (careerpathData) {
            var setup = {};
            setup.data = careerpathData;
            setup.options = $scope.defaultDoughnutOptions;
            return setup;
        };

        // Used to submit the export report form.
        // This is because the export needs to bypass angular's $http service to return a downloaded file
        // so this posts the form to the server directly from the template instead of going through the insights service.
        $scope.exportLoading = false;
        $scope.exportFullReport = function () {
            $scope.currentTimezone = moment.tz.guess();
            $scope.exportLoading = true;

            $timeout(function () {
                $scope.exportLoading = false;
            }, 3000);
        };

        $scope.sortBy = function (propertyName) {
            $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
            $scope.propertyName = propertyName;
        };

        $scope.loadDetails = function (course) {
            $scope.selectedCourse = course;
            $scope.showDetails();
            $scope.detailsLoading = true;
            $scope.detailsErrorThrown = false;

            var courseDetailsCall = CoursesService.getUserSummariesByCourse(course.id);
            courseDetailsCall.then(function (success) {
                $scope.detailRows = success;
                $scope.detailsLoading = false;
            }, function (failure) {
                $scope.detailsLoading = false;
                $scope.detailsErrorThrown = true;
                $scope.detailsErrorMessage = 'There was an error loading career path details data.';
            });
        };

        $scope.showDetails = function () {
            $scope.currentFocus = 'details';
            $scope.sortBy('assignment.studentLastName');
            
        };

        $scope.showList = function () {
            $scope.currentFocus = 'list';
            $scope.sortBy('course.name');
            $scope.detailRows = null;
        };


        $scope.openVideo = function (src, new_page_title) {

            var new_window = window.open('', '_blank', 'location=1,status=1,toolbar=1,scrollbars=1,resizeable=1,width=1000,height=800');
            new_window.document.write('<!doctype html><html><head><title>' + new_page_title + '</title><meta charset="UTF-8" /></head><body><video controls style="width: 100%; height: auto;"><source src="' + src + '" target="_blank" type="video/mp4" /></video></body></html>');

        };

    };
}]);
