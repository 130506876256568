/**
 * Service "class" that contains all logic for any insights related calls to the API.
 */
app.service('InsightsService',
    ['$http', '$q', 'dateUtil', 'UsersService', 'SettingsService', 'errorDisplayerUtil', 'courseFormatterUtil', function (
      $http, $q, dateUtil, UsersService, SettingsService, errorDisplayerUtil, courseFormatterUtil) {
        var _this = this;

        var formatSend = function (decisionMap) {
            var apiDecisionMap = angular.copy(decisionMap);
            var i = 0;
            // The API needs risks to have an id and name. The UI needs risks to have a key, value property so this transforms key, value to id, name to pass to api
            var apiRisks = [];
            for (i = 0; i < decisionMap.risks.length; i++) {
                apiRisks.push({ id: decisionMap.risks[i].key, name: decisionMap.risks[i].value, riskRating: decisionMap.risks[i].riskRating });
            }
            apiDecisionMap.risks = apiRisks;
            apiDecisionMap.created = apiDecisionMap.created === null ? null : apiDecisionMap.created.jsDate;
            apiDecisionMap.statusChanged = apiDecisionMap.statusChanged === null ? apiDecisionMap.statusChanged : apiDecisionMap.statusChanged.jsDate;
            apiDecisionMap.confirmedDateTime = apiDecisionMap.confirmedDateTime === null ? apiDecisionMap.confirmedDateTime : apiDecisionMap.confirmedDateTime.jsDate;
            apiDecisionMap.ruleCreatedDateTime = apiDecisionMap.ruleCreatedDateTime === null ? apiDecisionMap.ruleCreatedDateTime : apiDecisionMap.ruleCreatedDateTime.jsDate;
            return apiDecisionMap;
        };

        _this.response = null;
        _this.format = function (response) {

            angular.forEach(response,
                    function (value, key)
                    {
                        /* assign a rank based on the order the API put the courses in */
                        value.rank = key + 1;
                        /*convert the duration to minutes for easy addition with the 'unrelated' course selections */
                        value.duration = Math.round(value.duration * 60); 
                    });
            return response;
        };

        _this.formatDecisionMap = function (response) {
            response.created = dateUtil.formatDate(response.created);
            response.statusChanged = dateUtil.formatDate(response.statusChanged);
            if (response.confirmedDateTime !== null) {
                response.confirmedDateTime = dateUtil.formatDate(response.confirmedDateTime);
            }
            if (response.ruleCreatedDateTime !== null) {
                response.ruleCreatedDateTime = dateUtil.formatDate(response.ruleCreatedDateTime);
            }

            //Change the risks to a key/value pair with and extra rating attached for Client side work
            var clientRisks = [];
            angular.forEach(response.risks, function (r) {
                clientRisks.push({ key: r.id, value: r.name, riskRating: r.riskRating});
            });
            response.risks = clientRisks;

            angular.forEach(response.courses, function(c) {
                c.duration = Math.round(c.duration * 60);
            });

            return response;
        };

        _this.getDecisionMapShell = function () {
            var req = {
                method: 'GET',
                url: 'api/insights/decisionmap/',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        _this.createDecisionMap = function (decisionMap, copyComments) {
            var decisionMapToSend = formatSend(decisionMap);
            if (typeof copyComments === "undefined") {
                copyComments = true;
            }

            var req = {
                method: 'POST',
                url: 'api/insights/decisionmap/' + copyComments,
                data: decisionMapToSend,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        _this.updateDecisionMap = function (decisionMap) {
            var decisionMapToSend = formatSend(decisionMap);
            var req = {
                method: 'PUT',
                url: 'api/insights/decisionmap/',
                data: decisionMapToSend,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getPeerGroups = function () {
            var req = {
                method: 'GET',
                url: 'api/insights/peerGroups',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });

        };

		_this.getDecisionMapById = function(decisionMapID) {
            var req = {
                method: 'GET',
                url: 'api/insights/' + decisionMapID,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function(success) {
                _this.formatDecisionMap(success.data);
                return success.data;
            }, function(failure) {
                throw failure;
            });
		};

        _this.deleteDecisionMap = function(decisionMapID) {
            var req = {
                method: 'DELETE',
                url: 'api/insights/' + decisionMapID,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function(success) {
                return success.data;
            }, function(failure) {
                throw failure;
            });
        };

		_this.getDecisionMapSummaries = function (viewArchived) {

		    if (viewArchived === undefined) {
		        viewArchived = false;
		    }

		    var req = {
		        method: 'GET',
		        url: 'api/insights/decisionmaps/' + viewArchived,
		        headers: {
		            'Content-Type': 'application/json'
		        },
		        timeout: window.timeout
		    };

		    return $http(req).then(function (success) {
		        _.each(success.data, function(dm) {
		            dm.created = dateUtil.formatDate(dm.created);
		        });
		        return success.data;
		    }, function (failure) {
		        throw failure;
		    });
		};
		
        _this.getDecisionMapComputedCourses = function (decisionMapID) {
            var req = {
                method: 'GET',
                url: 'api/insights/' + decisionMapID + '/courses',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.format(success.data);

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getDecisionMapPeerCourses = function (decisionMapID) {
            var req = {
                method: 'GET',
                url: 'api/insights/' + decisionMapID + '/peercourses',
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = _this.format(success.data);

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getComments = function (decisionMapID, commentType) {
            var req = {
                method: 'GET',
                url: 'api/insights/comments/' + decisionMapID + '/' + commentType,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                angular.forEach(success.data, function (value, key) {
                    if (value.createDate !== null) { value.createDate = dateUtil.formatDate(value.createDate); }// new Date(value.createDate).toLocaleString(); } //dateUtil.formatDate(value.createDate); }//new Date(retakeLimitDetails[0].lastUpdateOn).toLocaleString();
                });
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getAllComments = function (decisionMapID) {
            var req = {
                method: 'GET',
                url: 'api/insights/comments/' + decisionMapID,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                angular.forEach(success.data, function (value, key) {
                    angular.forEach(value, function (v, k) {
                        if (v.createDate !== null) {
                            v.createDate = dateUtil.formatDate(v.createDate);
                        } // new Date(value.createDate).toLocaleString(); } //dateUtil.formatDate(value.createDate); }//new Date(retakeLimitDetails[0].lastUpdateOn).toLocaleString();
                    });
                });
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };


        _this.saveComment = function (decisionMapID, comment, commentType) {
            var commentObj = { 'comment': comment };
            var req = {
                method: 'PUT',
                url: 'api/insights/comments/' + decisionMapID + '/' + commentType,
                headers: {
                    'Content-Type': 'application/json'
                },
                data:  commentObj,
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.deleteComment = function (commentID) {
            var req = {
                method: 'DELETE',
                url: 'api/insights/comments/' + commentID,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        // getUnrelated is a boolean
        _this.getDecisionMapWizardOptions = function (wizardStep, decisionMapId, getUnrelated) {
            var requestEndPoint = "api/insights/" + wizardStep + "/" + decisionMapId;
            if (getUnrelated !== undefined) {
                requestEndPoint += "/" + getUnrelated;
            }
            var req = {
                method: 'GET',
                url: requestEndPoint,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getResponsibilityCategories = function () {
            var requestEndPoint = "api/insights/responsibilityCategories";
            var req = {
                method: 'GET',
                url: requestEndPoint,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        _this.renameDecisionReport = function (decisionMapID, newReportName) {
            var nameObj = { 'reportName': newReportName };
            var req = {
                method: 'PUT',
                url: 'api/insights/rename/' + decisionMapID,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: nameObj,
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _this.response = success.data;

                return _this.response;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getHistory = function (decisionMapId, currentStep) {

            var req = {
                method: 'GET',
                url: 'api/insights/history/' + decisionMapId + '/' + currentStep,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                _.each(success.data, function (h) {
                    h.updated = dateUtil.formatDate(h.updated);
                });
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

        _this.getRiskDetails = function () {
            var requestEndPoint = "api/insights/riskdetails";
            var req = {
                method: 'GET',
                url: requestEndPoint,
                headers: {
                    'Content-Type': 'application/json'
                },
                timeout: window.timeout
            };

            return $http(req).then(function (success) {
                return success.data;
            }, function (failure) {
                throw failure;
            });
        };

    }]);