/**
 * Location service to communicate with the locations API
 *
 * NOTE: on the API, locations are called facilities. This should be the only instance of the word facility in the
 * front end code, for sake of consistency, based on business requirements.
 */
app.service('LocationsService', ['$http', 'dateUtil',  function($http, dateUtil) {
    var _this = this;

    /**
     * Contains all possible values for location types
     *
     * Note: Super-default value (--default--) exists to ensure that dropdowns are always populated, and we
     * can force validation and no empty spaces
     *
     * @type {array|objects}
     */
    _this.types = [{
        'name': 'Branch',
        'value': 'branch'
    },
    {
        'name': 'Classroom',
        'value': 'classroom'
    },
    {
        'name': 'City',
        'value': 'city'
    },
    {
        'name': 'Online',
        'value': 'online'
    },
    {
        'name': 'Other',
        'value': 'other'
    },
    {
        'name': 'Technical',
        'value': 'technical'
    }, {
        'name': 'Unknown',
        'value': 'unknown'
    }, {
        'name': 'Vendor',
        'value': 'vendor'
    }, {
        'name': 'Webinar',
        'value': 'webinar'
    },
    ];

    /**
     * Creates a location on the api
     *
     * @param {Object} location - Location to be created on API
     * @returns {Object} $http - angular promise
     */
    _this.create = function(location) {
        var req = {
            method: 'POST',
            url: 'api/facility',
            headers: {
                'Content-Type': 'application/json'
            },
            data: location,
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            return success;
        }, function(failure) {
            throw failure;
        });
    };

    _this.edit = function(location) {
        var req = {
            method: 'PUT',
            url: 'api/facility/' + location.id,
            headers: {
                'Content-Type': 'application/json'
            },
            data: location,
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            return success;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getSingle = function(id) {
        var req = {
            method: 'GET',
            url: 'api/facility/' + id + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format([success.data])[0];

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getAll = function() {
        var req = {
            method: 'GET',
            url: 'api/facility/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getAllActive = function () {
        var req = {
            method: 'GET',
            url: 'api/facilityActive/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.format = function(data) {
        for(var i = 0; i < data.length; i++) {
            data[i].startDate = dateUtil.formatDate(data[i].startDate);
        }

        return data;
    };
}]);
