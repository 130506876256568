
/* This controller displays the successful/unsuccessful certificates */
app.controller('messagesController', ['$sce', '$scope', '$route', '$window', 'RoleMapsMixin', 'MessagesService', 'UsersService', 'errorDisplayerUtil','BreadcrumbsService',
    function ($sce, $scope, $route, $window, RoleMapsMixin, MessagesService, UsersService, errorDisplayerUtil, BreadcrumbsService) {
        var currentMethod = $route.current.params.name;

        $scope.dump = function(data) { console.warn(data); };

        $scope.messaging = {};
        $scope.messaging.error = errorDisplayerUtil.create();
        $scope.messaging.isLoading = false;
        $scope.role = UsersService.hasRole("ta") === true ? 'admin' : 'student';

        
        BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
        BreadcrumbsService.pushCrumb('messages');
        
        $scope.inboxAction = function() {

            $scope.homeTemplate = 'app/templates/messages/inbox/' + $scope.role + '.html';

            $scope.messaging.isLoading = true;

            var message = MessagesService.fetch();
            message.then(function (success) {
                $scope.messaging.isLoading = false;

                $scope.messages = success;
                $scope.tooMany = false;
                $scope.messageCount = $scope.messages.length;

                if ($scope.messageCount > 2) {
                    var messages = [];

                    messages.push($scope.messages[0]);

                    $scope.messages = messages;
                    $scope.tooMany = true;
                }
            }, function (failure) {
                $scope.isLoading = false;

                $scope.messaging.error.setError(failure.ErrorCode, failure.ErrorMessage);
            });
        };

        /**** Load role specific functionality ****/
        /* This function takes the scope object
         * and overrides anything specific to the current page
         * or role.
         */
        RoleMapsMixin.overrideScopeByRole($scope, $scope.role);

        /* Call route action like homeAction here.
         * Return 404 if action doesn't exist after
         * injecting mixin. For example, if a certain role
         * doesn't have an ILT calendar, they will get a 404
         * Not every role needs every page.
         * Please follow the routing structure; non-alphanumeric characters should not be used.
         */
        $scope[currentMethod + 'Action']();
    }
]);
