(function () {
    app.directive('baiCopyLink', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/catalog/baiCopyLink.html',
                scope: { copyUrl: '@' },
                controller: ['$scope', '$timeout', copyLinkController]
            };
        }
    ]);

    function copyLinkController($scope, $timeout) {
        $scope.openPopover = function() {
            var el = document.createElement("input");
            el.setAttribute("type", "text");
            el.setAttribute("id", "copyLink");
            el.setAttribute("value", $scope.copyUrl);
            document.body.appendChild(el);
            document.getElementById("copyLink").select();
            el.select();
            document.execCommand("copy");
            // Create Element.remove() function if not exist
            if (!('remove' in Element.prototype)) {
                Element.prototype.remove = function () {
                    if (this.parentNode) {
                        this.parentNode.removeChild(this);
                    }
                };
            }
            document.getElementById("copyLink").remove();

            jQuery("#copyPopover").popover('show');
            $timeout(function() {
                jQuery("#copyPopover").popover('hide');
            }, 2000);
        };
    }

})();