app.factory('Users_ManagerOverride', ['$rootScope', '$http', '$location', '$timeout', '$q', '$route', '$filter', 'AssignmentsService', 'BreadcrumbsService', 'TeamService', 'TranscriptsService', 'UsersService', 'TasksService', 'dateUtil', 'errorDisplayerUtil', '$anchorScroll', 'SettingsService', function ($rootScope, $http, $location, $timeout, $q, $route, $filter, AssignmentsService, BreadcrumbsService, TeamService, TranscriptsService, UsersService, TasksService, dateUtil, errorDisplayerUtil, $anchorScroll, SettingsService) {
    return function($scope) {
        /***************************************************** MAIN MANAGER HOMEPAGE (modals near bottom of file)  ******************************************/
        var _this = this;

        $scope.role = 'manager';
        $scope.showCareerPaths = 'false';
        UsersService.setHomeRole();

        /**
         * General page options to errors and loading for the person look on the home page
         */
        $scope.pageOptions.dashboardSummary = {};
        $scope.pageOptions.dashboardSummary.error = errorDisplayerUtil.create();
        $scope.pageOptions.dashboardSummary.isLoading = true;

        $scope.pageOptions.teamSummary = {};
        $scope.pageOptions.teamSummary.error = errorDisplayerUtil.create();
        $scope.pageOptions.teamSummary.isLoading = false;

        $scope.pageOptions.enrollmentRequests = {};
        $scope.pageOptions.enrollmentRequests.error = errorDisplayerUtil.create();
        $scope.pageOptions.enrollmentRequests.isLoading = false;

        $scope.pageOptions.managerApprovalRequests = {};
        $scope.pageOptions.managerApprovalRequests.error = errorDisplayerUtil.create();
        $scope.pageOptions.managerApprovalRequests.isLoading = false;

        $scope.homeTemplate = 'app/templates/users/home/manager.html';
        $scope.role = 'manager';

        $scope.cssDoneLoading = false;

        $scope.requiredOptions = [{
            "name": "Yes",
            "value": true
        }, {
            "name": "No",
            "value": false
        }];

        $rootScope.$watch('cssDoneLoading', function(cssDoneLoading) {
            if(cssDoneLoading !== true) {
                return;
            }

            /**
             * The pie charts cannot be sent the finalized colors until we finish getting this request. The issue being
             * that on page load (clean page refresh), there is nothing rendered, and we never display the correct pie chart
             * color. If the defaults are inserted after the call comes back, the pie charts get rendered correctly.
             *
             * To get around this, we wait until the custom css call is done, loop over all stylesheets, and find the
             * correct CSS rule to get the value. #workaround #hackmeamadeus
             *
             * @type {string}
             */
            var defaultColor = '#009ce6';

            var stylesheet = null;

            for(var i in document.styleSheets) {
                stylesheet = document.styleSheets[i];

                if(stylesheet.ownerNode.id === 'portalStyles') {
                    var rules = stylesheet.rules || stylesheet.cssRules;

                    for(var j in rules) {
                        if(rules[j].selectorText === '.portalSvgFill') {
                            defaultColor = rgb2hex(rules[j].style.fill);

                            break;
                        }
                    }

                    break;
                }
            }

            $scope.cssDoneLoading = true;
        }, true);

        var firstDayOfYear = new Date();
        firstDayOfYear.setDate(1);
        firstDayOfYear.setMonth(0);
        firstDayOfYear.setFullYear(firstDayOfYear.getFullYear());
        firstDayOfYear = dateUtil.setPortalStartTime(firstDayOfYear);

        // set default start date as 1/1/1950 to get all the data
        var defaultStartDate = new Date();
        defaultStartDate.setDate(1);
        defaultStartDate.setMonth(0);
        defaultStartDate.setFullYear(1950);
        defaultStartDate = dateUtil.setPortalStartTime(defaultStartDate);

        $scope.currentDateRange = dateUtil.formatDate(defaultStartDate);

        $scope.scrollBarConfig = {
            autoHideScrollbar: false,
            theme: 'bai',
            advanced: {
                updateOnContentResize: true
            },
            setHeight: '50vh',
            scrollInertia: 0,
            scrollButtons: {
                scrollAmount: 'auto', // scroll amount when button pressed
                enable: true // enable scrolling buttons by default
            }
        };

        /* Global scope variables */
        $scope.summaryExpanded = true;
        $scope.toggleSummary = function() {
            $scope.summaryExpanded = !$scope.summaryExpanded;
        };
        $scope.fullyRecursive = false;
        $scope.fullyRecursiveBottom = false;
        $scope.dueDate = null;
        $scope.apiDueDate = null;

        $scope.teamContextMenuExpanded = false;
        $scope.toggleTeamContextMenu = function() {
            /* wrapped in timeout so when the code for clicking outside
             * the team context menu runs, it doesn't immediately close the menu */
            $timeout(function() {
                $scope.teamContextMenuExpanded = !$scope.teamContextMenuExpanded;
            }, 1);
        };

        _this.closeAllContextMenus = function() {
            $scope.lookupContextMenuExpanded = false;
            $scope.teamContextMenuExpanded = false;
        };

        /* I don't know how to do this in angular yet but this works
         * Lets you close out a flyout menu when you click outside of it
         * without breaking event propagation.
         */
        $(document).on('click', function(event) {
            if ($scope.teamContextMenuExpanded && !$(event.target).closest('#directTeamFlyout').length) {
                $scope.$apply(function () {
                    $scope.teamContextMenuExpanded = false;
                });
            }

            if ($scope.lookupContextMenuExpanded && !$(event.target).closest('#searchBarFlyout').length) {
                $scope.$apply(function () {
                    $scope.lookupContextMenuExpanded = false;
                });
            }
        });

        // Storing whether direct or alternate team is selected
        $scope.selectedTeamEnum = {
            DEFAULT: '',
            DIRECT_TEAM: "directTeam",
            ALTERNATE_TEAM: "alternateTeam"
        };
        // We're selecting the direct team by default
        $scope.selectedTeam = $scope.selectedTeamEnum.DIRECT_TEAM;

        // Stores a list of managers directly under this user
        // Initialized in $scope.homeAction after displayDirectTeam is called.
        // Used in the direct manager flyout
        // Only users which are managers, not other users
        $scope.directTeamManagerList = null;

        // Stores the ID of the currently selected direct team manager
        // This gets set when you select a manager from the direct
        // team list dropdown. It gets cleared when you request the full direct/alternate
        // teams or search for something.
        // Also used when you drill down by managers via breadcrumbs and the team selectors
        // next to each manager name in the team list
        $scope.selectedDirectTeamManagerId = null;

        // Search filter stuff
        $scope.SEARCH_FILTER_ENUM = {
            // these values correspond to what goes into the
            // api/searchuser/<type>?q=<query> api
            ALL: 'all',
            PEOPLE: 'people',
            ROLES: 'role',
            GROUPS: 'group'
        };
        $scope.searchFilter = $scope.SEARCH_FILTER_ENUM.ALL;
        // filter is assumed to be a value from the search filter enum
        $scope.changeSearchFilter = function(filter) {
            $scope.searchFilter = filter;
            _this.closeAllContextMenus();
        };

        /* Set up pie chart defaults */
        // See old docs for help with canvas charts
        // https://github.com/chartjs/Chart.js/tree/v1.1.1/docs
        // NOT Chart.js 2.0 docs!!
        // Angular charts uses the old version of chart.js
        /*
         This code corresponds to a chart like this in
         the HTML
         <canvas id="summaryPieChartCanvas" class="chart chart-pie"
         chart-data="data" chart-labels="pieChartDefaults.labels" chart-colours="pieChartDefaults.colours" chart-options="pieChartDefaults.options" width="195" height="195">
         </canvas>
         */

        $scope.defaultDoughnutData = [
           {
               value: 0,
               color: "#009CE6",
               label: "Complete"
           },
           {
               value: 100,
               color: "#808080",
               label: "Incomplete"
           },
           {
               value: 0,
               color: "#F63F3F",
               label: "Overdue"
           }
        ];

        $scope.defaultDoughnutOptions = {
            showTooltips: false,
            animation: true,
            percentageInnerCutout: 60
        };

        $scope.setDoughnutData = function (defaults, complete, total, overdue) {
            var data = angular.copy(defaults);
            data[0].value = complete;
            data[1].value = (total - complete) - overdue;
            data[2].value = overdue;
            return data;
        };

        $scope.setupDoughnut = function (defaults, complete, total, options, overdue) {
            var setup = {};
            setup.data = $scope.setDoughnutData(defaults, complete, total, overdue);
            setup.options = options;
            return setup;
        };

        function rgb2hex(rgb) {
            if (/^#[0-9A-F]{6}$/i.test(rgb)) return rgb;

            rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

            return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
        }

        function hex(x) {
            return ("0" + parseInt(x).toString(16)).slice(-2);
        }

        // Getters and setters for managing the team list
        // This veriable stores exactly the team members displayed on the page
        // currently.
        // I'm using this otherwise I'd have several functions for
        // different features with access to the same variable
        $scope.teamList = null;
        _this.getTeamList = function() {
            return $scope.teamList;
        };

        _this.setTeamList = function (teamList) {

            $scope.teamList = teamList;
                $scope.teamList.forEach(function (x) {
                    var totalCourse = x.complete + x.incomplete;
                    x.teamdoughnut = $scope.setupDoughnut($scope.defaultDoughnutData, x.complete, (totalCourse > 0 ? totalCourse : 1), $scope.defaultDoughnutOptions, 0);
                    var percentage = totalCourse > 0 ? Math.round(100 * (x.complete / totalCourse)) : 0;
                    x.teamdoughnutMessage = '<small>' + percentage + "%<br/>Complete</small>";
                });
        };


        // Getters and setters for managing breadcrumbs
        // allows you to clear all breadcrumb and add a breadcrumb
        // Drilldown of breadcrumbs is handled in the drillDirectTeamByBreadCrumb function
        $scope.breadCrumbs = Array();
        $scope.breadCrumbsVisible = false;
        $scope.currentUserName = UsersService.getName(); // used as the title of the first breadcrumb

        _this.pushBreadCrumb = function(managerName, managerId) {
            $scope.breadCrumbs.push({managerName: managerName, managerId: managerId});

            $scope.breadCrumbsVisible = true;
        };

        _this.clearBreadCrumbs = function() {
            $scope.breadCrumbs = Array();
            $scope.breadCrumbsVisible = false;
        };

        // Holds the data stored in the teamSummary
        // Loaded only once in $scope.homeAction
        $scope.teamSummary = Array();

        $scope.toggleProfile = function (member) {
            member.error = errorDisplayerUtil.create();
            member.error.reset();

            if (member.profileExpanded) {
                member.profileExpanded = !member.profileExpanded;

                return;
            }

            member.isLoading = false;
            member.profileExpanded = true;

        };

        // Loads and displays the direct team list
        // Side effects:
        //   1) Removes all breadcrumbs
        //   2) Switches between direct/alternate team selectors
        //   3) Clears the direct team flyout
        //   4) Hides team summary
        //   5) toggles loading / errors
        $scope.displayDirectTeam = function () {
            $scope.teamList = null;
            var teamList = null;
            if ($scope.apiDueDate !== null)
            {
                teamList = TeamService.getDirectTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, $scope.apiDueDate);
            }    
            else
            {
                teamList = TeamService.getDirectTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, null);
            }        

            $scope.breadCrumbsVisible = false;

            _this.closeAllContextMenus();

            $scope.selectedTeam = $scope.selectedTeamEnum.DIRECT_TEAM;

            $scope.pageOptions.teamSummary.error.reset();
            $scope.pageOptions.teamSummary.isLoading = true;

            return teamList.then(function(teamList) {
                // **** See TeamService._getTeamList for format of teamList ****
                _this.setTeamList(teamList);

                $scope.selectedDirectTeamManagerId = null;

                _this.clearBreadCrumbs();

                /**
                 * getTeamList() returns undefined if there is no data... Therefore, there is no object, but an
                 * undefined value. Therefore, JS errors are thrown.
                 */
                var dtl = _this.getTeamList();
                if(typeof dtl !== 'undefined' && dtl.lenght > 0) {
                    $scope.directTeamManagerList = dtl.filter(function(e) { return e.isManager === true && e.teamCount > 0; });
                }

                $scope.pageOptions.teamSummary.isLoading = false;
            }, function(failure) {
                $scope.pageOptions.teamSummary.isLoading = false;

                $scope.pageOptions.teamSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        // Loads and displays the alternate team list
        // Side effects:
        //   1) Removes all breadcrumbs
        //   2) Switches between direct/alternate team selectors
        //   3) Clears the direct team flyout
        //   4) Hides team summary
        //   5) toggles loading / errors
        $scope.displayAlternateTeam = function () {
            $scope.teamList = null;
            var teamList = null;
            if ($scope.apiDueDate !== null)
            {
                teamList = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, $scope.apiDueDate);
            }    
            else
            {
                teamList = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, null);
            }  

            $scope.breadCrumbsVisible = false;

            _this.closeAllContextMenus();

            $scope.selectedTeam = $scope.selectedTeamEnum.ALTERNATE_TEAM;

            $scope.pageOptions.teamSummary.isLoading = true;

            teamList.then(function(teamList) {
                teamList = teamList;

                // **** See TeamService._getTeamList for format of teamList ****
                // Setting the value of isManager to false
                // Such that alternate team won't show the subordinates of the student

                $scope.selectedDirectTeamManagerId = null;

                _this.clearBreadCrumbs();

                $scope.pageOptions.teamSummary.isLoading = false;

                if(teamList.length === 0) {
                    _this.setTeamList([]);

                    $scope.pageOptions.teamSummary.error.setError(404, 'There were no alternate team members found');

                    return;
                }

                _.each(teamList, function (student) {
                    student.isManager = false;
                });

                _this.setTeamList(teamList);
            }, function(failure) {
                _this.setTeamList([]);

                $scope.pageOptions.teamSummary.isLoading = false;

                $scope.pageOptions.teamSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };


        // PROMISE QUEUE executes 2 calls in order
        $scope.getTeamMgrDirectSummary = function (managerId) {
            //console.log(managerId);
            var teamMgrAssignmentSummary = null;
            if ($scope.apiDueDate !== null)
            {
                teamMgrAssignmentSummary = TeamService.getTeamMgrAssignmentSummary(managerId, $scope.apiDueDate);
            }    
            else
            {
                teamMgrAssignmentSummary = TeamService.getTeamMgrAssignmentSummary(managerId, null);
            }   

            var displayDirectTeamByManager = $scope.displayDirectTeamByManagerId(managerId);

            var promises = [];
            promises.push(teamMgrAssignmentSummary);

            promises.push(displayDirectTeamByManager);

            $q.all(promises).then(function (success) {
                $scope.rowsAreLoading = false;
                $scope.testTeamList = success[0];
                $scope.getTeamMgrAssignmentSummary($scope.testTeamList);
               
                $scope.displayDirectTeamByManager = success[1];

            }, function (failure) {
                $scope.pageOptions.teamSummary.error.setError(null, "There was an issue rendering the page.");
            });
        };

        // Loads and displays the direct team when you click on a minion under the current user
        // in the direct team flyout
        // Side effects:
        //   1) adds a breadcrumb so we can go back up the hierarchy
        //   2) Switches between direct/alternate team selectors
        //   3) Puts a check mark on the direct team flyout
        //   4) Loads/shows the team summary
        //   5) toggles loading / errors
        $scope.displayDirectTeamByManagerId = function(managerId) {
            try {

                var managerObj = _this.getTeamList().filter(function(e) { return e.studentId === managerId; })[0];

                // If we didn't find the managerObj in the current team list
                // That means we drilled down and clicked the flyout
                // So get the managerObj from the flyout data
                if (typeof managerObj === 'undefined') {
                    managerObj = $scope.directTeamManagerList.filter(function(e) { return e.studentId === managerId; })[0];
                    // We're going to recreate the breadcrumbs if they selected a manager
                    // from the directTeam flyout. Otherwise the breadcrumb will just get added to the end.
                    // Perhaps this function is getting too complicated??
                    var recreateBreadCrumbs = true;
                }

                // We're gonna push the current manager to the end
                // of the breadcrumb trail
                var breadCrumbName = managerObj.firstName + ' ' + managerObj.lastName;
                var teamList = null;
                if (typeof $scope.fullyRecursiveBottom === 'undefined' || $scope.fullyRecursiveBottom === null) { $scope.fullyRecursiveBottom = false; }
                if ($scope.apiDueDate !== null)
                {
                    teamList = TeamService.getDirectTeamListByManagerId(managerId, $scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, $scope.apiDueDate);
                }    
                else
                {
                    teamList = TeamService.getDirectTeamListByManagerId(managerId, $scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, null);
                }   

                $scope.breadCrumbsVisible = false;

                _this.closeAllContextMenus();

                $scope.pageOptions.teamSummary.isLoading = true;
                $scope.pageOptions.teamSummary.error.reset();

                teamList.then(function(teamList) {
                    teamList = teamList;

                    // **** See TeamService._getTeamList for format of teamList ****
                    _this.setTeamList(teamList);

                    $scope.selectedTeam = $scope.selectedTeamEnum.DIRECT_TEAM;
                    $scope.selectedDirectTeamManagerId = managerId;

                    // Always recreate the breadcrumbs if they select a manager from the direct team flyout
                    if (recreateBreadCrumbs) {
                        _this.clearBreadCrumbs();
                    }
                    // push the bread crumb as normal.
                    _this.pushBreadCrumb(breadCrumbName, managerId);
                    // After we've loaded the team list, get the team summary

                    var teamSummary = {
                        onlineCourseIncomplete: 0,
                        onlineCourseDueSoon: 0,
                        onlineCourseCompleted: 0,
                        onlineCourseOverdue: 0,
                        onlineCourseCount: 0,
                        instructorLedIncomplete: 0,
                        instructorLedDueSoon: 0,
                        instructorLedCompleted: 0,
                        instructorLedOverdue: 0,
                        instructorLedCount: 0,
                        courseGroupsIncomplete: 0,
                        courseGroupsDueSoon: 0,
                        courseGroupsCompleted: 0,
                        courseGroupsOverdue: 0,
                        courseGroupsCount: 0,
                        tasksIncomplete: 0,
                        tasksDueSoon: 0,
                        tasksCompleted: 0,
                        tasksOverdue: 0,
                        tasksCount: 0,
                        totalsIncomplete: 0,
                        totalsDueSoon: 0,
                        totalsCompleted: 0,
                        totalsOverdue: 0,
                        totalsCount: 0,
                        pieChartData: [0, 0]
                    };

                    for (i = 0; i < teamList.length; i++) {
                        teamSummary.onlineCourseIncomplete += teamList[i].onlineCourse.incomplete;
                        teamSummary.onlineCourseDueSoon += teamList[i].onlineCourse.dueSoon;
                        teamSummary.onlineCourseCompleted += teamList[i].onlineCourse.completed;
                        teamSummary.onlineCourseOverdue += teamList[i].onlineCourse.overdue;
                        teamSummary.onlineCourseCount += teamList[i].onlineCourseCount;

                        teamSummary.instructorLedIncomplete += teamList[i].instructorLed.incomplete;
                        teamSummary.instructorLedDueSoon += teamList[i].instructorLed.dueSoon;
                        teamSummary.instructorLedCompleted += teamList[i].instructorLed.completed;
                        teamSummary.instructorLedOverdue += teamList[i].instructorLed.overdue;
                        teamSummary.instructorLedCount += teamList[i].instructorLedCount;

                        teamSummary.courseGroupsIncomplete += teamList[i].courseGroups.incomplete;
                        teamSummary.courseGroupsDueSoon += teamList[i].courseGroups.dueSoon;
                        teamSummary.courseGroupsCompleted += teamList[i].courseGroups.completed;
                        teamSummary.courseGroupsOverdue += teamList[i].courseGroups.overdue;
                        teamSummary.courseGroupsCount += teamList[i].courseGroupsCount;

                        teamSummary.tasksIncomplete += teamList[i].tasks.incomplete;
                        teamSummary.tasksDueSoon += teamList[i].tasks.dueSoon;
                        teamSummary.tasksCompleted += teamList[i].tasks.completed;
                        teamSummary.tasksOverdue += teamList[i].tasks.overdue;
                        teamSummary.tasksCount += teamList[i].tasksCount;

                        teamSummary.totalsIncomplete += teamList[i].totals.incomplete;
                        teamSummary.totalsDueSoon += teamList[i].totals.dueSoon;
                        teamSummary.totalsCompleted += teamList[i].totals.completed;
                        teamSummary.totalsOverdue += teamList[i].totals.overdue;
                        teamSummary.totalsCount += teamList[i].totalsCount;
                    }

                    teamSummary.pieChartData[0] = teamSummary.totalsCompleted;
                    teamSummary.pieChartData[1] = teamSummary.totalsIncomplete;
                    teamSummary.pieChartData[2] = 0;

                    if (typeof $scope.teamSummary2 !== 'undefined' && $scope.teamSummary2 !== null)
                    {
                        teamSummary.onlineCourseIncomplete += $scope.teamSummary2.onlineCourseIncomplete;
                        teamSummary.onlineCourseDueSoon += $scope.teamSummary2.onlineCourseDueSoon;
                        teamSummary.onlineCourseCompleted += $scope.teamSummary2.onlineCourseCompleted;
                        teamSummary.onlineCourseOverdue += $scope.teamSummary2.onlineCourseOverdue;
                        teamSummary.onlineCourseCount += $scope.teamSummary2.onlineCourseCount;

                        teamSummary.instructorLedIncomplete += $scope.teamSummary2.instructorLedIncomplete;
                        teamSummary.instructorLedDueSoon += $scope.teamSummary2.instructorLedDueSoon;
                        teamSummary.instructorLedCompleted += $scope.teamSummary2.instructorLedCompleted;
                        teamSummary.instructorLedOverdue += $scope.teamSummary2.instructorLedOverdue;
                        teamSummary.instructorLedCount += $scope.teamSummary2.instructorLedCount;

                        teamSummary.courseGroupsIncomplete += $scope.teamSummary2.courseGroupsIncomplete;
                        teamSummary.courseGroupsDueSoon += $scope.teamSummary2.courseGroupsDueSoon;
                        teamSummary.courseGroupsCompleted += $scope.teamSummary2.courseGroupsCompleted;
                        teamSummary.courseGroupsOverdue += $scope.teamSummary2.courseGroupsOverdue;
                        teamSummary.courseGroupsCount += $scope.teamSummary2.courseGroupsCount;

                        teamSummary.tasksIncomplete += $scope.teamSummary2.tasksIncomplete;
                        teamSummary.tasksDueSoon += $scope.teamSummary2.tasksDueSoon;
                        teamSummary.tasksCompleted += $scope.teamSummary2.tasksCompleted;
                        teamSummary.tasksOverdue += $scope.teamSummary2.tasksOverdue;
                        teamSummary.tasksCount += $scope.teamSummary2.tasksCount;

                        teamSummary.totalsIncomplete += $scope.teamSummary2.totalsIncomplete;
                        teamSummary.totalsDueSoon += $scope.teamSummary2.totalsDueSoon;
                        teamSummary.totalsCompleted += $scope.teamSummary2.totalsCompleted;
                        teamSummary.totalsOverdue += $scope.teamSummary2.totalsOverdue;
                        teamSummary.totalsCount += $scope.teamSummary2.totalsCount;

                        teamSummary.pieChartData[0] = teamSummary.totalsCompleted + $scope.teamSummary2.totalsCompleted;
                        teamSummary.pieChartData[1] = teamSummary.totalsIncomplete + $scope.teamSummary2.totalsIncomplete;
                    }
                    //subManagerRollUp Donut
                    var totalCourse = teamSummary.totalsCompleted + teamSummary.totalsIncomplete;
                    teamSummary.subMdoughnut = $scope.setupDoughnut($scope.defaultDoughnutData, teamSummary.totalsCompleted, (totalCourse > 0 ? totalCourse : 1), $scope.defaultDoughnutOptions, 0);
                    var percentage = totalCourse > 0 ? Math.round(100 * (teamSummary.totalsCompleted / totalCourse)) : 0;
                    teamSummary.subdoughnutMessage = '<small>' + percentage + '%<br />Complete</small>';

                    $scope.teamSummary = teamSummary;
                    $scope.teamSummary.managerName = breadCrumbName;
                    $scope.breadCrumbsVisible = true;

                    $scope.pageOptions.teamSummary.isLoading = false;

                    $scope.pageOptions.teamSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
            } catch(err) {
                console.log(err);
                var stackTrace = new Error();
                console.log(stackTrace.stack);

                $scope.pageOptions.teamSummary.error.setError(null, "There was an issue rendering the page.");
            }
      
        };

        $scope.getTeamMgrAssignmentSummary = function (teamList) {

                    var teamSummary2 = {
                        onlineCourseIncomplete: 0,
                        onlineCourseDueSoon: 0,
                        onlineCourseCompleted: 0,
                        onlineCourseOverdue: 0,
                        onlineCourseCount: 0,
                        instructorLedIncomplete: 0,
                        instructorLedDueSoon: 0,
                        instructorLedCompleted: 0,
                        instructorLedOverdue: 0,
                        instructorLedCount: 0,
                        courseGroupsIncomplete: 0,
                        courseGroupsDueSoon: 0,
                        courseGroupsCompleted: 0,
                        courseGroupsOverdue: 0,
                        courseGroupsCount: 0,
                        tasksIncomplete: 0,
                        tasksDueSoon: 0,
                        tasksCompleted: 0,
                        tasksOverdue: 0,
                        tasksCount: 0,
                        totalsIncomplete: 0,
                        totalsDueSoon: 0,
                        totalsCompleted: 0,
                        totalsOverdue: 0,
                        totalsCount: 0,
                        pieChartData: [0, 0]
                    };


                        teamSummary2.onlineCourseIncomplete += teamList.onlineCourse.incomplete;
                        teamSummary2.onlineCourseDueSoon += teamList.onlineCourse.dueSoon;
                        teamSummary2.onlineCourseCompleted += teamList.onlineCourse.completed;
                        teamSummary2.onlineCourseOverdue += teamList.onlineCourse.overdue;
                        teamSummary2.onlineCourseCount += teamList.onlineCourseCount;

                        teamSummary2.instructorLedIncomplete += teamList.instructorLed.incomplete;
                        teamSummary2.instructorLedDueSoon += teamList.instructorLed.dueSoon;
                        teamSummary2.instructorLedCompleted += teamList.instructorLed.completed;
                        teamSummary2.instructorLedOverdue += teamList.instructorLed.overdue;
                        teamSummary2.instructorLedCount += teamList.instructorLedCount;

                        teamSummary2.courseGroupsIncomplete += teamList.courseGroups.incomplete;
                        teamSummary2.courseGroupsDueSoon += teamList.courseGroups.dueSoon;
                        teamSummary2.courseGroupsCompleted += teamList.courseGroups.completed;
                        teamSummary2.courseGroupsOverdue += teamList.courseGroups.overdue;
                        teamSummary2.courseGroupsCount += teamList.courseGroupsCount;

                        teamSummary2.tasksIncomplete += teamList.tasks.incomplete;
                        teamSummary2.tasksDueSoon += teamList.tasks.dueSoon;
                        teamSummary2.tasksCompleted += teamList.tasks.completed;
                        teamSummary2.tasksOverdue += teamList.tasks.overdue;
                        teamSummary2.tasksCount += teamList.tasksCount;

                        teamSummary2.totalsIncomplete += teamList.totals.incomplete;
                        teamSummary2.totalsDueSoon += teamList.totals.dueSoon;
                        teamSummary2.totalsCompleted += teamList.totals.completed;
                        teamSummary2.totalsOverdue += teamList.totals.overdue;
                        teamSummary2.totalsCount += teamList.totalsCount;

                        $scope.teamSummary2 = teamSummary2;

        };

        // PROMISE QUEUE executes 2 calls in order
        $scope.getTeamMgrDirectBreadcrumb = function (breadCrumbIndex) {

            var managerIdx = $scope.breadCrumbs[breadCrumbIndex].managerId;

            //console.log(managerId);
            var teamMgrAssignmentSummary = null;
            if ($scope.apiDueDate !== null)
            {
                teamMgrAssignmentSummary = TeamService.getTeamMgrAssignmentSummary(managerIdx, $scope.apiDueDate);            }    
            else
            {
                teamMgrAssignmentSummary = TeamService.getTeamMgrAssignmentSummary(managerIdx, null);
            }   

            var displayDirectTeamByManager = $scope.drillDirectTeamByBreadCrumb(breadCrumbIndex);

            var promises = [];
            promises.push(teamMgrAssignmentSummary);

            promises.push(displayDirectTeamByManager);

            $q.all(promises).then(function (success) {
                $scope.rowsAreLoading = false;
                $scope.testTeamList = success[0];
                $scope.getTeamMgrAssignmentSummary($scope.testTeamList);

                $scope.displayDirectTeamByManager = success[1];

            }, function (failure) {
                $scope.pageOptions.teamSummary.error.setError(null, "There was an issue rendering the page.");
            });
        };

        // Drills into a direct team by breadcrumb
        // Side effects:
        //   1) Removes breadcrumbs up until the one we're drilling into
        //   2) Switches between direct/alternate team selectors to the direct team
        //   3) Removes check mark on the direct team flyout
        //   4) Loads/shows the team summary
        //   5) toggles loading / errors
        $scope.drillDirectTeamByBreadCrumb = function(breadCrumbIndex) {
            var managerId = $scope.breadCrumbs[breadCrumbIndex].managerId;

            // We're gonna make whatever manager they clicked on
            // Be the tail end of the bread crumb
            var breadCrumbName = $scope.breadCrumbs[breadCrumbIndex].managerName;
            var teamList = null;
            if ($scope.apiDueDate !== null)
            {
                teamList = TeamService.getDirectTeamListByManagerId(managerId, $scope.startDate.apiDate, $scope.endDate.apiDate, $scope.apiDueDate);
            }    
            else
            {
                teamList = TeamService.getDirectTeamListByManagerId(managerId, $scope.startDate.apiDate, $scope.endDate.apiDate, null);
            }   
            $scope.breadCrumbsVisible = false;
            _this.closeAllContextMenus();

            $scope.pageOptions.teamSummary.isLoading = true;

            teamList.then(function(teamList) {
                teamList = teamList;

                // **** See TeamService._getTeamList for format of teamList ****
                _this.setTeamList(teamList);

                $scope.selectedTeam = $scope.selectedTeamEnum.DIRECT_TEAM;
                $scope.selectedDirectTeamManagerId = null;

                // Remove all extra breadcrumbs after the one we're drilling into
                var originalBreadCrumbLength = $scope.breadCrumbs.length;
                for (var i = breadCrumbIndex; i < originalBreadCrumbLength; i += 1) {
                    $scope.breadCrumbs.pop();
                }

                // Need to get the manager name here
                _this.pushBreadCrumb(breadCrumbName, managerId);

                // After we've loaded the team list, get the team summary
 
                var teamSummary = {
                    onlineCourseIncomplete: 0,
                    onlineCourseDueSoon: 0,
                    onlineCourseCompleted: 0,
                    onlineCourseOverdue: 0,
                    onlineCourseCount: 0,
                    instructorLedIncomplete: 0,
                    instructorLedDueSoon: 0,
                    instructorLedCompleted: 0,
                    instructorLedOverdue: 0,
                    instructorLedCount: 0,
                    courseGroupsIncomplete: 0,
                    courseGroupsDueSoon: 0,
                    courseGroupsCompleted: 0,
                    courseGroupsOverdue: 0,
                    courseGroupsCount: 0,
                    tasksIncomplete: 0,
                    tasksDueSoon: 0,
                    tasksCompleted: 0,
                    tasksOverdue: 0,
                    tasksCount: 0,
                    totalsIncomplete: 0,
                    totalsDueSoon: 0,
                    totalsCompleted: 0,
                    totalsOverdue: 0,
                    totalsCount: 0,
                    pieChartData: [0, 0]
                };

                for (i = 0; i < teamList.length; i++) {
                    teamSummary.onlineCourseIncomplete += teamList[i].onlineCourse.incomplete;
                    teamSummary.onlineCourseDueSoon += teamList[i].onlineCourse.dueSoon;
                    teamSummary.onlineCourseCompleted += teamList[i].onlineCourse.completed;
                    teamSummary.onlineCourseOverdue += teamList[i].onlineCourse.overdue;
                    teamSummary.onlineCourseCount += teamList[i].onlineCourseCount;

                    teamSummary.instructorLedIncomplete += teamList[i].instructorLed.incomplete;
                    teamSummary.instructorLedDueSoon += teamList[i].instructorLed.dueSoon;
                    teamSummary.instructorLedCompleted += teamList[i].instructorLed.completed;
                    teamSummary.instructorLedOverdue += teamList[i].instructorLed.overdue;
                    teamSummary.instructorLedCount += teamList[i].instructorLedCount;

                    teamSummary.courseGroupsIncomplete += teamList[i].courseGroups.incomplete;
                    teamSummary.courseGroupsDueSoon += teamList[i].courseGroups.dueSoon;
                    teamSummary.courseGroupsCompleted += teamList[i].courseGroups.completed;
                    teamSummary.courseGroupsOverdue += teamList[i].courseGroups.overdue;
                    teamSummary.courseGroupsCount += teamList[i].courseGroupsCount;

                    teamSummary.tasksIncomplete += teamList[i].tasks.incomplete;
                    teamSummary.tasksDueSoon += teamList[i].tasks.dueSoon;
                    teamSummary.tasksCompleted += teamList[i].tasks.completed;
                    teamSummary.tasksOverdue += teamList[i].tasks.overdue;
                    teamSummary.tasksCount += teamList[i].tasksCount;

                    teamSummary.totalsIncomplete += teamList[i].totals.incomplete;
                    teamSummary.totalsDueSoon += teamList[i].totals.dueSoon;
                    teamSummary.totalsCompleted += teamList[i].totals.completed;
                    teamSummary.totalsOverdue += teamList[i].totals.overdue;
                    teamSummary.totalsCount += teamList[i].totalsCount;
                }

                teamSummary.pieChartData[0] = teamSummary.totalsCompleted;
                teamSummary.pieChartData[1] = teamSummary.totalsIncomplete;
                teamSummary.pieChartData[2] = 0;

                if (typeof $scope.teamSummary2 !== 'undefined' && $scope.teamSummary2 !== null) {
                    teamSummary.onlineCourseIncomplete += $scope.teamSummary2.onlineCourseIncomplete;
                    teamSummary.onlineCourseDueSoon += $scope.teamSummary2.onlineCourseDueSoon;
                    teamSummary.onlineCourseCompleted += $scope.teamSummary2.onlineCourseCompleted;
                    teamSummary.onlineCourseOverdue += $scope.teamSummary2.onlineCourseOverdue;
                    teamSummary.onlineCourseCount += $scope.teamSummary2.onlineCourseCount;

                    teamSummary.instructorLedIncomplete += $scope.teamSummary2.instructorLedIncomplete;
                    teamSummary.instructorLedDueSoon += $scope.teamSummary2.instructorLedDueSoon;
                    teamSummary.instructorLedCompleted += $scope.teamSummary2.instructorLedCompleted;
                    teamSummary.instructorLedOverdue += $scope.teamSummary2.instructorLedOverdue;
                    teamSummary.instructorLedCount += $scope.teamSummary2.instructorLedCount;

                    teamSummary.courseGroupsIncomplete += $scope.teamSummary2.courseGroupsIncomplete;
                    teamSummary.courseGroupsDueSoon += $scope.teamSummary2.courseGroupsDueSoon;
                    teamSummary.courseGroupsCompleted += $scope.teamSummary2.courseGroupsCompleted;
                    teamSummary.courseGroupsOverdue += $scope.teamSummary2.courseGroupsOverdue;
                    teamSummary.courseGroupsCount += $scope.teamSummary2.courseGroupsCount;

                    teamSummary.tasksIncomplete += $scope.teamSummary2.tasksIncomplete;
                    teamSummary.tasksDueSoon += $scope.teamSummary2.tasksDueSoon;
                    teamSummary.tasksCompleted += $scope.teamSummary2.tasksCompleted;
                    teamSummary.tasksOverdue += $scope.teamSummary2.tasksOverdue;
                    teamSummary.tasksCount += $scope.teamSummary2.tasksCount;

                    teamSummary.totalsIncomplete += $scope.teamSummary2.totalsIncomplete;
                    teamSummary.totalsDueSoon += $scope.teamSummary2.totalsDueSoon;
                    teamSummary.totalsCompleted += $scope.teamSummary2.totalsCompleted;
                    teamSummary.totalsOverdue += $scope.teamSummary2.totalsOverdue;
                    teamSummary.totalsCount += $scope.teamSummary2.totalsCount;

                    teamSummary.pieChartData[0] = teamSummary.totalsCompleted + $scope.teamSummary2.totalsCompleted;
                    teamSummary.pieChartData[1] = teamSummary.totalsIncomplete + $scope.teamSummary2.totalsIncomplete;
                }


                $scope.teamSummary = teamSummary;
                $scope.teamSummary.managerName = breadCrumbName;
                $scope.breadCrumbsVisible = true;

                $scope.pageOptions.teamSummary.isLoading = false;
            }, function(failure) {
                _this.setTeamList([]);

                failure = failure;

                $scope.breadCrumbsVisible = false;

                $scope.pageOptions.teamSummary.isLoading = false;

                $scope.pageOptions.teamSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        /* Executes a search query and */
        // $scope.search = function(terms) {}

        var getManagerCareerPathSummary = function () {
            var summaryPromise = TeamService.getCareerPathSummary($scope.fullyRecursive);
            $scope.pageOptions.dashboardSummary.error.reset();
            $scope.pageOptions.dashboardSummary.isLoading = true;
            summaryPromise.then(function (success) {
                $scope.summary = success;
                $scope.pageOptions.dashboardSummary.isLoading = false;
                $scope.finalDoughnut = $scope.setupDoughnut($scope.defaultDoughnutData, $scope.summary.assignedUsersCount, $scope.summary.totalReports, $scope.defaultDoughnutOptions,0);
                var percentage = Math.round($scope.summary.assignedUsersCount / $scope.summary.totalReports * 100);
                $scope.doughnutMessage = percentage + '%<br />usage';
            }, function (failure) {
                $scope.pageOptions.dashboardSummary.isLoading = false;
                $scope.pageOptions.dashboardSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        $scope.showViewByUser = true;
        var getManagerCareerPathStudentSummary = function (teamType, fullyRecursive) {
            $scope.cpStudentError = false;
                 
            var stuSummaryPromise = TeamService.getCareerPathStudentSummary(fullyRecursive, teamType);
            $scope.cpStudentSummaryIsLoading = true;
            stuSummaryPromise.then(function (success) {
                if (success.length === 0) {
                    $scope.cpStudentError = true;
                    $scope.cpStudentErrorMessage = "There are no items to display";
                    $scope.careerPathDetailsForOtherFilter = [];
                    if (!$scope.showViewByUser) { $scope.showByCareerPath(); }
                } else {
                    $scope.careerPathDetailsForOtherFilter = success;
                    success.forEach(function (x) {
                        //SET the career path completed amount and total assigned amount               
                        if (x.careerPathDetails !== null) {
                            x.assigned = x.careerPathDetails.length;
                            x.completed = x.careerPathDetails.length > 0 ? $filter('filter')(x.careerPathDetails, { "careerPathComplete": true }, true).length : 0;
                            x.inProgress = x.careerPathDetails.length > 0 ? $filter('filter')(x.careerPathDetails, { "careerPathInProgress": true }, true).length : 0;
                            x.careerPathDetails.forEach(function (cpI) {
                                if (cpI.careerPathInProgress === true) {
                                    //format each in progress career path for the doughnut
                                    cpI.doughnutDetails = $scope.setupDoughnut($scope.defaultDoughnutData, cpI.completedCourses, (cpI.courses > 0 ? cpI.courses : 1), $scope.defaultDoughnutOptions, 0);
                                    var percentage = cpI.courses > 0 ? Math.round(cpI.completedCourses / cpI.courses * 100) : 0;
                                    cpI.doughnutMessage = percentage + "%";
                                }
                            });
                        }
                    });
                   if (!$scope.showViewByUser) { $scope.showByCareerPath(); }
                }
                
                $scope.studentSummary = success;
                $scope.cpStudentSummaryIsLoading = false;
            }, function (failure) {
                $scope.cpStudentSummaryIsLoading = false;

                $scope.cpStudentError = true;
                $scope.cpStudentErrorMessage = failure.ErrorMessage;

                $scope.careerPathDetailsForOtherFilter = [];
                if (!$scope.showViewByUser) { $scope.showByCareerPath(); }
            });
        };

        $scope.showByCareerPath = function () {
            $scope.byCareerPathView = [];
            $scope.showViewByUser = false;

            $scope.careerPathDetailsForOtherFilter.forEach(function (person) {
                if (person.careerPathDetails !== null) {
                    person.careerPathDetails.forEach(function (cpath) {
                        var cpIndex = _.findIndex($scope.byCareerPathView, { 'careerPathId': cpath.careerPathId });
                        if (cpIndex >= 0) {
                            addUserToCareerPath(cpath, person, $scope.byCareerPathView[cpIndex]);
                        } else {
                            var c = {
                                'careerPathName': cpath.name,
                                'careerPathId': cpath.careerPathId,
                                'careerPathImage': 'grab_bag\/' + cpath.careerPathImage + '.png',
                                'studentsCompleted': [],
                                'studentsInProgress': [],
                                'studentsEnrolled': []
                            };
                            addUserToCareerPath(cpath, person, c);
                            $scope.byCareerPathView.push(c);
                        }
                    }); }
            });

            if ($scope.byCareerPathView.length === 0) {
                $scope.cpStudentError = true;
                $scope.cpStudentErrorMessage = "There are no items to display";
            }
        };

        $scope.showByEmployee = function () {
            /* Display by the Employee View - remove the error message if it was added because there were no assigned CPs*/
            if ($scope.studentSummary.length > 0) {
                $scope.cpStudentError = false;
            }
            $scope.showViewByUser = true;
        };

        var addUserToCareerPath = function (careerPathData, person, arrayToAddTo) {
            if (careerPathData.careerPathComplete) {
                arrayToAddTo.studentsCompleted.push({ 'name': person.firstName + ' ' + person.lastName, 'lastName': person.lastName, 'firstName': person.firstName, 'jobType': person.jobType, 'userId': person.userId });
            } else if (careerPathData.careerPathInProgress) {
                arrayToAddTo.studentsInProgress.push({ 'name': person.firstName + ' ' + person.lastName, 'lastName': person.lastName });
            } else {
                arrayToAddTo.studentsEnrolled.push({ 'name': person.firstName + ' ' + person.lastName, 'lastName': person.lastName });
            }
        };

        /**
         * List of actions for "routing" purposes.
         */
        $scope.homeAction = function (skipAction) {

            var currentRoute = "";
            if ($route.current.params.param !== undefined && $route.current.params.param !== null) {
                var params = $route.current.params.param.split('/');
                if (params.length > 0) {
                    currentRoute = params[0].toLowerCase();
                }
            }

            if (typeof skipAction === 'undefined' || skipAction === false) {
                if (typeof skipAction === 'undefined') {
                    $scope.endDate = dateUtil.formatDate(new Date());
                    $scope.startDate = dateUtil.formatDate(defaultStartDate);
                }
            }
            
            var careerPathAccess = UsersService.hasCareerPathAccess();
            careerPathAccess.then(function (success) {
                $scope.showCareerPaths = success;
            }, function (failure) {
            });

            if (currentRoute === "careerpaths") {
                $scope.currentRoute = currentRoute;
                $scope.homeTemplate = 'app/templates/users/home/managerCareerPath.html';
                $scope.myAssignmentsActive = "";
                $scope.myCareerPathsActive = "active portalBackground portalBorder";
                getManagerCareerPathSummary();
                getManagerCareerPathStudentSummary('direct', false);

            } else {
                $scope.homeTemplate = 'app/templates/users/home/manager.html';
                $scope.myAssignmentsActive = "active portalBackground portalBorder";
                $scope.myCareerPathsActive = "";
                /* Get the summary data.
                 * Prepopulate the list of managers for the direct team dropdown.
                 */
                $scope.showme = false;
                $scope.pageOptions.dashboardSummary.error.reset();
                $scope.pageOptions.dashboardSummary.isLoading = true;

                $scope.pageOptions.teamSummary.error.reset();
                $scope.pageOptions.teamSummary.isLoading = true;

                $scope.getManagerSummary();

                /* Get the users's name */
                $scope.teamListTitle = UsersService.getName();

                /* The breadcrumb is used for going up the hierarchy of managers */
                // Anything added to the breadcrumb
                // will be displayed
                $scope.breadcrumb = Array();

                /* Load the direct team list by default.
                 * Also populates the direct team flyout
                 */
                $scope.displayDirectTeam();
            }
        };

        $scope.updateDueDate = function (dateObj){
            if (typeof dateObj !== "undefined" && dateObj !== null &&
                    typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                        $scope.dueDate = dateUtil.formatDate(dateObj.moment.toDate());
                        $scope.dueDate = $scope.setDueDateEndTime($scope.dueDate);
                        $scope.apiDueDate = moment.utc($scope.dueDate.moment).format('YYYYMMDDHHmmss');
                    } else {
                        $scope.dueDate = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                        $scope.dueDate = $scope.setDueDateEndTime($scope.dueDate);
                        $scope.apiDueDate = moment.utc($scope.dueDate.moment).format('YYYYMMDDHHmmss');
                    }
                } else {
                    $scope.dueDate=  $scope.endDate;
                }
            $scope.getManagerSummary();
            $scope.displayDirectTeam();
        };

        $scope.toggleFullyRecursive = function () {
            $scope.fullyRecursive = !$scope.fullyRecursive;
            $scope.getManagerSummary();
        };

        $scope.toggleFullyRecursiveBottom = function () {
            $scope.fullyRecursiveBottom = !$scope.fullyRecursiveBottom;

            $scope.displayDirectTeam();

        };

        $scope.toggleFullyRecursiveCareerPath = function() {
            $scope.fullyRecursive = !$scope.fullyRecursive;
            getManagerCareerPathSummary();
        };

        //
        $scope.getManagerSummary = function () {
            $scope.pageOptions.dashboardSummary.isLoading = true;
            var summary = null;
            if ($scope.apiDueDate !== null)
            {
                summary = TeamService.getSummary($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursive, $scope.apiDueDate);
            }    
            else
            {
                summary = TeamService.getSummary($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursive, null);

            }   
            summary.then(function (summaryData) {
                // $scope.summary contains
                // directReports, subordinates, altTeamMembers, enrollmentReqeusts, taskApprovalRequired, webBased, instructorLed, courseGroups, tasks, overdue, complete, incomplete, pieChartData: [complete, incomplete]
                // The pie chart data field is inserted manually by the service

                $scope.summary = summaryData;
                $scope.pageOptions.dashboardSummary.isLoading = false;
                var totalCourse = $scope.summary.complete + $scope.summary.incomplete;
                $scope.finalDoughnut = $scope.setupDoughnut($scope.defaultDoughnutData, $scope.summary.complete, (totalCourse > 0 ? totalCourse : 1) , $scope.defaultDoughnutOptions, $scope.summary.overdue);
                var percentage = totalCourse > 0 ? Math.round(100 * ($scope.summary.complete / totalCourse)) : 0;
                $scope.doughnutMessage = percentage + '%<br />Complete';

            }, function (failure) {
                $scope.pageOptions.dashboardSummary.isLoading = false;
                $scope.pageOptions.dashboardSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
            });

        };

        /**
         * This method allows us to see the current assignments of a single user
         */
        $scope.listAction = function () {
            $scope.homeTemplate = 'app/templates/users/list/manager.html';

            _this.fetchStudentAssignments();

            BreadcrumbsService.pushCrumb("Dashboard", 'student/home/');
            BreadcrumbsService.pushCrumb("List");
        };

        /**
         * Perform a search to find all users that exist below a manager in an organization
         */
        $scope.searchForUnderlings = function() {
            var underlings = this.underlings;

            $scope.pageOptions.teamSummary.error.reset();

            $scope.pageOptions.teamSummary.isLoading = true;

            var users = UsersService.search(underlings, $scope.searchFilter);
            users.then(function(success) {
                var allIds = [];
                var users = success;

                for(var i = 0; i < users.length; i++) {
                    allIds.push(users[i].id);
                }

                users = TeamService.getSummariesByUserId(allIds, $scope.startDate.apiDate, $scope.endDate.apiDate);
                users.then(function(success) {
                    $scope.pageOptions.teamSummary.isLoading = false;

                    $scope.selectedTeam = $scope.selectedTeamEnum.DEFAULT;

                    _this.setTeamList(success);
                }, function(failure) {
                    $scope.pageOptions.teamSummary.isLoading = false;

                    _this.setTeamList([]);

                    $scope.pageOptions.teamSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
            }, function(failure) {
                $scope.pageOptions.teamSummary.isLoading = false;

                _this.setTeamList([]);

                $scope.pageOptions.teamSummary.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        /***************************************************** ENROLLMENT REQUESTS MODAL ******************************************/
        $scope.enrollmentRequestsAction = function () {
            jQuery('#enrollmentRequestsModal').modal();
            var approvalAssignments = TeamService.getListOfAssignmentsToApprove();
            approvalAssignments.then(function (assignmentData) {
                $scope.approvalAssignments = assignmentData;

                if (assignmentData.length === 0) {
                    $scope.pageOptions.enrollmentRequests.error.setError(404, 'There were no requests found');
                }
            }, function (failure) {
                // Hide the summary and display the error if it didn't load.
                $scope.apiError = true;
                $scope.responseComplete = true;

                $scope.pageOptions.enrollmentRequests.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        //.studentId, assignment.offeringId, assignment.transcriptId, assignment.assignmentType
        $scope.ApproveRequest = function (assignment) {
            var offeringId = assignment.offeringId;
            var studentId = assignment.studentId;
            var transcriptId = assignment.transcriptId;
            var type = assignment.assignmentType;

            assignment.error.reset();
            assignment.isLoading = true;

            var approveAction = AssignmentsService.approveDenyEnrollment(studentId, offeringId, transcriptId, type, true);
            approveAction.then(function() {
                $scope.enrollmentRequestsAction();
            }, function (failure) {
                assignment.isLoading = false;

                assignment.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        //assignment.studentId, assignment.offeringId, assignment.transcriptId, assignment.assignmentType
        $scope.DenyRequest = function (assignment) {
            var offeringId = assignment.offeringId;
            var studentId = assignment.studentId;
            var transcriptId = assignment.transcriptId;
            var type = assignment.assignmentType;

            assignment.error.reset();
            assignment.isLoading = true;

            var denyAction = AssignmentsService.approveDenyEnrollment(studentId, offeringId, transcriptId, type, false);
            denyAction.then(function (sucess) {
                $scope.enrollmentRequestsAction();
            }, function (failure) {
                assignment.isLoading = false;

                assignment.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };


        /***************************************************** APPROVALS MODAL ******************************************/
        $scope.approvalsAction = function() {
            jQuery('#approvalsModal').modal();

            $scope.pageOptions.managerApprovalRequests.error.reset();

            var managerApprovalTasks = TeamService.getTasksForApproval();
            managerApprovalTasks.then(function(taskData) {
                $scope.approvalTasks = taskData;
            }, function(failure) {
                $scope.pageOptions.managerApprovalRequests.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        $scope.CompletedTask = function(userId, id, task) {
            task.isLoading = true;

            //Reset the error
            task.error.reset();

            var completeAction = TasksService.taskApproval(userId, id, true);
            completeAction.then(function () {
                $scope.approvalsAction();
            }, function (failure) {
                task.isLoading = false;

                task.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        $scope.NotCompletedTask = function(userId, id, task) {
            task.isLoading = true;

            //Reset the error
            task.error.reset();

            var completeAction = TasksService.taskApproval(userId, id, false);

            completeAction.then(function () {
                $scope.approvalsAction();
            }, function (failure) {
                task.isLoading = false;

                task.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        /* Function refreshs the page on close of modal*/
        $scope.refresh = function() {
            $scope.homeAction();
        };

        /***************************************************** ADD ASSIGNMENTS MODAL ******************************************/
        /* Create functions to handle business logic of error checking and switching tabs */
        $scope.addAssignments = {};

        $scope.addAssignments.error = errorDisplayerUtil.create();

        /**
         * Fix for 2403. Since JS is loose, not defining an attribute on an object returns undefined, which resolves to false,
         * which is bad for us, since we need it to resolve to true later.
         */
        $scope.addAssignments.noUsersSelectedError = false;

        /* Stores which step of the add assignments modal to track */
        $scope.addAssignments.STEP_ENUM = {
            ASSIGNMENTS: 1,
            DUE_DATE: 2,
            AUDIENCE: 3
        };

        /* Stores what's currently displayed on the search tab */
        $scope.addAssignments.SEARCH_TAB_ENUM = {
            PLEASE_BEGIN: 0, /* Haven't typed anything yet or deleted search term */
            LOADING: 1,
            ERROR: 2,
            NO_RESULTS: 3,
            RESULTS_FOUND: 4
        };
        $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.PLEASE_BEGIN;

        /* Stores what's currently displayed on the audience tab */
        $scope.addAssignments.AUDIENCE_TAB_ENUM = {
            SELECT_USERS: 0,
            LOADING: 1,
            ERROR: 2,
            SUCCESS: 3
        };
        $scope.addAssignments.audienceTabState = $scope.addAssignments.AUDIENCE_TAB_ENUM.SELECT_USERS;

        /* Stores assignments */
        // This one stores the list of assignments in the
        // search page
        $scope.addAssignments.searchResults = Array();
        // This one stores assignments indexed by ID
        // we can add assignments with
        // $scope.addAssignments.queuedAssignments[assignment.id] = assignment;
        // and delete them with
        // delete $scope.addAssignments.queuedAssignments[assignment.id];
        // and check if an assignment exists with
        // if (assignment.id in $scope.addAssignments.queuedAssignments)
        $scope.addAssignments.queuedAssignments = {};

        // Used on due date tab. keeps list of assignments that have been unchecked
        // If the user goes to the first or last tabs, this variable will be used to remove assignments
        // from the list of queued assignments
        // Used in same manner as $scope.addAssignments.queuedAssignments except it's the opposite
        $scope.addAssignments.assignmentsToggledForRemoval = {};

        $scope.addAssignments.clearAddAssignmentsModal = function() {
            $scope.addAssignments.queuedAssignments = {};
            $scope.addAssignments.assignmentsToggledForRemoval = {};
            $scope.addAssignments.selectedUsers = {};
            $scope.addAssignments.keyword = '';
            $scope.addAssignments.noUsersSelectedError = false;
            $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.ASSIGNMENTS;
            $scope.addAssignments.searchResults = Array();
            $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.PLEASE_BEGIN;
            $scope.addAssignments.audienceTabState = $scope.addAssignments.AUDIENCE_TAB_ENUM.SELECT_USERS;
            $scope.addAssignments.autoOpenedList = false;

            $scope.addAssignments.error.reset();

            activateAssignmentsPill();
        };

        // Stores the list of selected users
        // Used same way as $scope.addAssignments.queuedAssignments
        // See that $scope.addAssignments.queuedAssignments for details
        $scope.addAssignments.selectedUsers = {};

        // On audience tab, stores whether the Everyone, Alternate or Direct Team boxes are checked
        // When these are checked, when the user is submitting in tab 3, these will be checked
        // and will change the content / override $scope.addAssignments.selectedUsers

        /* Used for storing search data for filtering assignments at top of modal */
        $scope.addAssignments.keyword = '';

        $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.ASSIGNMENTS;

        // Keeps track of whether we should disable the next/submit button
        $scope.addAssignments.buttonDisabled = false;

        // Flag to automatically open the team list in the add assignment modals.
        $scope.addAssignments.autoOpenedList = false;

        // In addition to revealing the select assignments tab
        // this function checks the list of assignments toggled for removal
        // and removes those assignments from the queue
        $scope.addAssignments.selectAssignments = function() {
            // reset the last tab in case the user wants to assign more assignments
            $scope.addAssignments.audienceTabState = $scope.addAssignments.AUDIENCE_TAB_ENUM.SELECT_USERS;

            /* Check if any assignments are in the list of assignments toggled for removal
             * Remove those assignments */
            deleteAssignmentsToggledForRemoval($scope.addAssignments.queuedAssignments, $scope.addAssignments.assignmentsToggledForRemoval);
            $scope.addAssignments.assignmentsToggledForRemoval = {}; // need to figure out how to clear this inside the function

            $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.ASSIGNMENTS;
        };

        /* These three functions are necessary
         * because there isn't a clear way to override how bootstrap pills work
         * If there's a way to do it, override these functions
         */
        var activateAssignmentsPill = function() {
            setTimeout(function() {
                angular.element('#assignmentsTabSelector').addClass('active');
                angular.element('#dueDateTabSelector').removeClass('active');
                angular.element('#audienceTabSelector').removeClass('active');

                angular.element('#assignmentsTabSelector > a').addClass('portalBackground');
                angular.element('#assignmentsTabSelector > .nav-arrow').addClass('portalBorderLeft');

                angular.element('#dueDateTabSelector > a').removeClass('portalBackground');
                angular.element('#dueDateTabSelector > .nav-arrow').removeClass('portalBorderLeft');
                angular.element('#dueDateTabSelector > .nav-wedge').removeClass('portalBorderTop portalBorderRight portalBorderBottom');

                angular.element('#audienceTabSelector > a').removeClass('portalBackground');
                angular.element('#audienceTabSelector > .nav-arrow').removeClass('portalBorderLeft');
                angular.element('#audienceTabSelector > .nav-wedge').removeClass('portalBorderTop portalBorderRight portalBorderBottom');
            }, 1);
        };

        var activateDueDatePill = function() {
            setTimeout(function() {
                angular.element('#assignmentsTabSelector').removeClass('active');
                angular.element('#dueDateTabSelector').addClass('active');
                angular.element('#audienceTabSelector').removeClass('active');

                // Clear extra colors coming from custom CSS call for first tab
                angular.element('#assignmentsTabSelector > a').removeClass('portalBackground');
                angular.element('#assignmentsTabSelector > .nav-arrow').removeClass('portalBorderLeft');

                // Add extra colors coming from custom CSS call for second tab
                angular.element('#dueDateTabSelector > a').addClass('portalBackground');
                angular.element('#dueDateTabSelector > .nav-arrow').addClass('portalBorderLeft');
                angular.element('#dueDateTabSelector > .nav-wedge').addClass('portalBorderTop portalBorderRight portalBorderBottom');
            }, 1);
        };

        var activateAudiencePill = function() {
            setTimeout(function() {
                angular.element('#assignmentsTabSelector').removeClass('active');
                angular.element('#dueDateTabSelector').removeClass('active');
                angular.element('#audienceTabSelector').addClass('active');

                // Clear colors coming from custom CSS call for second tab
                angular.element('#dueDateTabSelector > a').removeClass('portalBackground');
                angular.element('#dueDateTabSelector > .nav-arrow').removeClass('portalBorderLeft');
                angular.element('#dueDateTabSelector > .nav-wedge').removeClass('portalBorderTop portalBorderRight portalBorderBottom');

                // Add extra colors coming from custom CSS call for third tab
                angular.element('#audienceTabSelector > a').addClass('portalBackground');
                angular.element('#audienceTabSelector > .nav-wedge').addClass('portalBorderTop portalBorderRight portalBorderBottom');
            }, 1);
        };

        // This function goes through every assignment toggled for removal
        // in the assignmentsToggledForRemoval variable
        // and removes it from the list of queuedAssignments

        var deleteAssignmentsToggledForRemoval = function(queuedAssignments, assignmentsToggledForRemoval) {
            var assignmentsToRemoveIds = Object.keys(assignmentsToggledForRemoval);

            for (var i = 0; i < assignmentsToRemoveIds.length; i += 1) {
                if (assignmentsToRemoveIds[i] in queuedAssignments) {
                    delete queuedAssignments[assignmentsToRemoveIds[i]];
                }
            }
        };

        // This function also checks if we've selected some assignments,
        // if not, it puts the user back on the select assignments tab.
        $scope.addAssignments.selectDueDate = function() {
            // reset the last tab in case the user wants to assign more assignments
            $scope.addAssignments.audienceTabState = $scope.addAssignments.AUDIENCE_TAB_ENUM.SELECT_USERS;

            // If the user hasn't selected any assignments, don't let them go to the due date tab
            if (Object.keys($scope.addAssignments.queuedAssignments).length === 0) {
                $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.ASSIGNMENTS;
                activateAssignmentsPill();
                $scope.addAssignments.noAssignmentsSelectedError = true;
                return;
            }

            // otherwise let the user eat cake!
            $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.DUE_DATE;
            $scope.addAssignments.noAssignmentsSelectedError = false;

            // Mark career paths recommended and all other course types recommended
            _.each($scope.addAssignments.queuedAssignments, function (assignment) {
                if (assignment.type === "careerPath") {
                    assignment.isRequired = false;
                } else {
                    assignment.isRequired = true;
                }
            });

            activateDueDatePill();
        };

        $scope.addAssignments.nextButton = function() {
            switch($scope.addAssignments.step) {
                case $scope.addAssignments.STEP_ENUM.ASSIGNMENTS:
                    $scope.addAssignments.selectDueDate();

                    break;
                case $scope.addAssignments.STEP_ENUM.DUE_DATE:
                    $scope.addAssignments.selectAudience();

                    break;
                // Assign the selected assignments to all selected users
                case $scope.addAssignments.STEP_ENUM.AUDIENCE:
                    $scope.addAssignments.submitAssignmentsForSelectedUsers();

                    break;
            }
        };

        /*** search assignments tab logic */
        /* On the select assignments tab, this function allows the user to
         * search for assignments by assignment type. It handles calling the service
         * and displaying the data.
         * Also checks if assignments were already queued. if so, premarks them as checked
         */
        $scope.addAssignments.searchAssignments = function (keyword, assignmentType) {
            if (assignmentType === "unknown" && keyword.length < 2) return;
            // If you do another action, clear the error
            $scope.addAssignments.noAssignmentsSelectedError = false;
            $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.LOADING;

            $scope.addAssignments.error.reset();

            var assignments = TeamService.searchAssignments(keyword, assignmentType);
            assignments.then(function(assignmentsData) {
                assignmentsData = assignmentsData;

                for (var i = 0; i < assignmentsData.length; i += 1) {
                    if (assignmentsData[i].id in $scope.addAssignments.queuedAssignments) {
                        assignmentsData[i].checked = true;
                    }
                }

                $scope.addAssignments.searchResults = assignmentsData;
                if (assignmentsData.length === 0) {
                    $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.NO_RESULTS;
                } else {
                    $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.RESULTS_FOUND;
                }
            }, function(failure) {
                $scope.addAssignments.error.setError(failure.StatusCode, failure.ErrorMessage);

                /* I should be checking this in the service end somehow */
                if (failure.StatusCode === 404) {
                    if ($scope.addAssignments.keyword === '') {
                        $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.PLEASE_BEGIN;
                    } else {
                        $scope.addAssignments.searchResults = [];
                        $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.NO_RESULTS;
                    }
                } else {
                    $scope.addAssignments.searchTabState = $scope.addAssignments.SEARCH_TAB_ENUM.ERROR;
                }
            });
        };

        // When you select an assignment in the search tab, it gets
        // added to the list of queued assignments here
        $scope.addAssignments.queueAssignment = function(assignment) {
            if (assignment.id in $scope.addAssignments.queuedAssignments) {
                delete $scope.addAssignments.queuedAssignments[assignment.id];

                if (Object.keys($scope.addAssignments.queuedAssignments).length === 0) {
                    $scope.addAssignments.noAssignmentsSelectedError = true;
                }
            } else {
                $scope.addAssignments.queuedAssignments[assignment.id] = assignment;

                // if you've selected something then you shouldn't see this error
                $scope.addAssignments.noAssignmentsSelectedError = false;
            }
        };

        /*** due date tab stuff */
            // When you click on a check box in the due date tab,
            // this function toggles whether or not an assignment should be removed
        $scope.addAssignments.toggleAssignmentForRemoval = function(assignment) {
            if (assignment.id in $scope.addAssignments.assignmentsToggledForRemoval) {
                delete $scope.addAssignments.assignmentsToggledForRemoval[assignment.id];
            } else {
                $scope.addAssignments.assignmentsToggledForRemoval[assignment.id] = assignment;
            }
        };

        /*** audience tab stuff */
            // Stores the list of trees in the audience tab
        $scope.addAssignments.audienceData = [];

        /* Handles group checking in the audience tab */
        var selectUsersRecursively = function(item, checked) {
            item.checked = checked;

            // if the item is checked, add it to list of selected users
            if (item.checked) {
                $scope.addAssignments.selectedUsers[item.studentId] = item;
            } else {
                // @TODO @sneilan check if any other trees of users are checked
                // don't delete the user if it's checked somewhere else
                delete $scope.addAssignments.selectedUsers[item.studentId];
            }

            if (item.teamList && item.teamList.length > 0) {
                for (var i = 0; i < item.teamList.length; i+=1) {
                    selectUsersRecursively(item.teamList[i], checked);
                }
            }

            if (item.alternateTeamList && item.alternateTeamList.length > 0) {
                for (var j = 0; j < item.alternateTeamList.length; j+=1) {
                    selectUsersRecursively(item.alternateTeamList[j], checked);
                }
            }
        };

        $scope.addAssignments.toggleUser = function(item, recursive) {
            // By default remove the error
            // We'll renable the no users selected error near the bottom
            // If the user hasn't selected any users
            $scope.addAssignments.noUsersSelectedError = false;

            // toggle the checkbox
            item.checked = !item.checked;

            // if the item is checked, add it to list of selected users
            if (item.checked) {
                $scope.addAssignments.selectedUsers[item.studentId] = item;
            } else {
                // @TODO @sneilan check if any other trees of users are checked
                // don't delete the user if it's checked somewhere else
                delete $scope.addAssignments.selectedUsers[item.studentId];
            }

            if (recursive === true && item.teamList && item.teamList.length > 0) {
                for (var i = 0; i < item.teamList.length; i += 1) {
                    selectUsersRecursively(item.teamList[i], item.checked);
                }
            }

            // if no users are selected, display an error
            /**
             * This code is never run unless this method is called
             */
            if (Object.keys($scope.addAssignments.selectedUsers).length === 0) {
                $scope.addAssignments.noUsersSelectedError = true;
            }
        };

        /* Handles visibility of team member lists in the audience tab */
        $scope.addAssignments.toggle = function(item) {
            item.hidden = !item.hidden;
        };

        /* list, object, property.. */
        _this.setPropertyOnAllNodes = function(data, name, value) {
            for (var i = 0; i < data.length; i += 1) {
                data[i][name] = value;

                // if there are subnodes,
                // set them to hidden also
                /**
                 * This never recurses.
                 */
                if (typeof data[i].teamList !== 'undefined' && data[i].teamList.length > 0) {
                    _this.setPropertyOnAllNodes(data[i].teamList, name, value);
                }
            }
        };

        // This function gets the assignments from
        // $scope.addAssignments.queuedAssignments = {}
        // and users from
        // $scope.addAssignments.selectedUsers = {};
        // and submits the assignments
        // it's called by the next button but only on the audience tab
        $scope.addAssignments.submitAssignmentsForSelectedUsers = function() {
            // don't continue if the user hasn't selected any users
            /**
             * This check must be performed instead of the noUsersSelectedError check because
             * toggleUsers (the only place that is changed to true) is not inherently run, as it relies
             * on an user's action. We have to check how many
             */
            if(Object.keys($scope.addAssignments.selectedUsers).length === 0) {
                $scope.addAssignments.noUsersSelectedError = true;

                return;
            }

            $scope.addAssignments.audienceTabState = $scope.addAssignments.AUDIENCE_TAB_ENUM.LOADING;

            var assignmentIds = Object.keys($scope.addAssignments.queuedAssignments);

            var userIds = Object.keys($scope.addAssignments.selectedUsers);

            var promises = [];

            var createPromiseToSubmitAssignment = function(userId, assignmentId) {
                return AssignmentsService.submitAssignment(userId, assignmentId);
            };

            for (var i = 0; i < assignmentIds.length; i += 1) {
                for (var j = 0; j < userIds.length; j += 1) {
                    var assignmentKey = Object.keys($scope.addAssignments.queuedAssignments)[i];

                    var assignment = angular.copy($scope.addAssignments.queuedAssignments[assignmentKey]);

                    if (typeof assignment.dueDate !== 'undefined' && assignment.dueDate !== null && typeof assignment.dueDate.moment !== "undefined" && _.isObject(assignment.dueDate.moment) )
                        assignment.dueDate = assignment.dueDate.moment.utc().format();
                    else assignment.dueDate = null;

                    var userKey = Object.keys($scope.addAssignments.selectedUsers)[j];

                    var user = $scope.addAssignments.selectedUsers[userKey];
                    promises.push(createPromiseToSubmitAssignment(user.studentId, assignment));
                }
            }

            $q.all(promises).then(function(values) {
                $scope.addAssignments.audienceTabState = $scope.addAssignments.AUDIENCE_TAB_ENUM.SUCCESS;

                jQuery('#addAssignmentsModal').modal('hide');

                jQuery('#addAssignmentsModal').on('hidden.bs.modal', function () {
                    $scope.homeAction();

                    $scope.addAssignments.clearAddAssignmentsModal();
                });

                $scope.cpFilter.direct = true;
                $scope.cpFilter.alterate = false;
                $scope.cpFilter.sub = false;

            }, function(failure) {
                $scope.addAssignments.audienceTabState = $scope.addAssignments.AUDIENCE_TAB_ENUM.ERROR;

                $scope.addAssignments.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        };

        // In addition to revealing the select audience tab
        // this function checks the list of assignments toggled for removal
        // and removes those assignments from the queue
        $scope.addAssignments.selectAudience = function() {
            // If the user hasn't selected any assignments, don't let them go to the audience tab
            if (Object.keys($scope.addAssignments.queuedAssignments).length === 0) {
                $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.ASSIGNMENTS;

                activateAssignmentsPill();

                $scope.addAssignments.noAssignmentsSelectedError = true;

                return;
            }

            /* Check if any assignments are in the list of assignments toggled for removal
             * Remove those assignments.
             * If there are no assignments in the queue, send us back to the select assignments tab
             * trigger a tooltip on the select audience tab stating an error.
             */

            var numQueuedAssignments = Object.keys($scope.addAssignments.queuedAssignments).length;
            var numAssignmentsMarkedForDeletion = Object.keys($scope.addAssignments.assignmentsToggledForRemoval).length;

            // if the user has marked all assignments for deletion, don't let them continue
            if (numQueuedAssignments === numAssignmentsMarkedForDeletion) {
                $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.DUE_DATE;

                activateDueDatePill();

                $scope.addAssignments.noAssignmentsSelectedError = true;

                return;
            }

            deleteAssignmentsToggledForRemoval($scope.addAssignments.queuedAssignments, $scope.addAssignments.assignmentsToggledForRemoval);

            $scope.addAssignments.assignmentsToggledForRemoval = {};

            $scope.addAssignments.step = $scope.addAssignments.STEP_ENUM.AUDIENCE;

            activateAudiencePill();
        };

        /*** Create the modal here */
        // Creates the add assignments modal
        // called by the Add Assignment button
        // next to each team member
        /**
         * Opens the add assignments modal for a specific user within a manager's team. They can be at any level of
         * the team tree.
         *
         * @param teamMember Object
         */
        $scope.addAssignmentsAction = function(teamMember) {
            _this.getTeamRecursiveList(teamMember);

            $scope.stepThreeView = 'app/templates/users/home/modals/addAssignmentViews/step3.audience.subTeam.html';

            jQuery('#addAssignmentsModal').modal();
        };

        /*** Create the modal here */
        // Creates the add assignments modal
        // called by the Add Assignment button
        // next to each team member
        /**
         * Opens the add assignments modal for the entire team of the logged in manager.
         *
         * @param teamMember Object
         */
        $scope.addAssignmentsActionForAllTeams = function() {
            var teamMember = UsersService.user;

            teamMember.studentId = UsersService.getId();

            _this.getTeamRecursiveList(teamMember);

            var alternateTeam = null;
            if ($scope.apiDueDate !== null)
            {
                alternateTeam = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, $scope.apiDueDate);
            }    
            else
            {
                alternateTeam = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, null);
            }  
            alternateTeam.then(function(teamTree) {
                teamTree = teamTree;

                var audienceRoot = {};

                audienceRoot.firstName = teamMember.firstName;
                audienceRoot.lastName = teamMember.lastName;
                audienceRoot.studentId = teamMember.studentId;
                audienceRoot.teamList = teamTree;

                $scope.addAssignments.alternateDataList = audienceRoot; // needs to be in array because
                // end result is list of lists

                // add my team (direct) list to audienceData

                // Make all team members hidden by default
                _this.setPropertyOnAllNodes($scope.addAssignments.alternateDataList, 'hidden', true);

                // uncheck all team members by default
                _this.setPropertyOnAllNodes($scope.addAssignments.alternateDataList, 'checked', false);
            }, function(failure) {
                // @TODO do something if we can't load the modal
                console.log(failure);
            });

            $scope.stepThreeView = 'app/templates/users/home/modals/addAssignmentViews/step3.audience.fullTeam.html';

            jQuery('#addAssignmentsModal').modal();

            $scope.setDefaultAssignmentType();
        };

        $scope.addAssignmentsWithLibraryActionForAllTeams = function () {
            $scope.assignmentTeamModalhide = false;
            $scope.$broadcast('openAddAssignmentsModal', { displaytype: 3, studentId: null });
        };

        $scope.setDueDateEndTime = function (dateObj) {
            if (typeof dateObj !== 'undefined' && dateObj !== null && typeof dateObj.moment !== 'undefined' && _.isObject(dateObj.moment)) {
                var newdt = dateUtil.setPortalEndTime(dateObj.moment.toDate());
                dateObj = dateUtil.formatDate(newdt);
            } else {
                dateObj = null;
            }
            return dateObj;
        };

        
        $scope.mgrUpdateDueDate = function (dateObj, assignment) {
            assignment.dueDate = $scope.getEndDateTimeFromPicker(dateObj);
            $scope.editAssignment(assignment);
        };


        /**
         * Method for a manager to change the due date for a single individual
         *
         * @param assignment Object selected assigned which will have its due date changed.
         */
        $scope.editAssignment = function (assignment) {
            var params = $route.current.params.param.split('/');
            var studentId = params[0];
            var courseId = assignment.assignmentCourseId;

            var finalDate = null;
            if (assignment.dueDate !== null && assignment.dueDate.moment !== null && assignment.dueDate.moment.isValid()) {
                finalDate = assignment.dueDate.moment.toISOString();
            }

            assignment.error.reset();
            assignment.openCalendar = false;
            assignment.changingDueDate = true;
            
            var change = AssignmentsService.updateDueDate(UsersService.getId(), studentId, courseId, finalDate);
            change.then(function (success) {
                assignment.changingDueDate = false;
            }, function(error) {
                assignment.error.setError(error.status, error.message);
                assignment.changingDueDate = false;
            });
        };



        /**
         * Generic method to set start and end dates to pass to the appropriate APIs
         *
         * @param skipAction Boolean determines if we should skip calling this method; only set to true on page load
         */
        $scope.updateDatesForSummaries = function (skipAction, dateObj) {

            if (typeof dateObj === 'undefined' || typeof dateObj.moment === 'undefined' || !_.isObject(dateObj.moment)) {
                $scope.startDate = dateUtil.formatDate(defaultStartDate);
            } else {
                var newdt = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                dateObj.moment.set({ hour: newdt.getHours(), minute: newdt.getMinutes(), second: newdt.getSeconds(), millisecond: newdt.getMilliseconds() });
                $scope.startDate = dateUtil.formatDate(newdt);
            }

            $scope.homeAction(skipAction);
        };


        /* User lands on to the student transcript page */
        $scope.transcriptsAction = function () {
            $scope.homeTemplate = 'app/templates/users/transcripts/manager.html';

            $scope.maxDate = new Date();

            $scope.pageOptions.isLoading = true; // We're no longer loading!

            var param = $route.current.params.param;

            if (param) {
                var params = param.split('/');
                var studentId = params[0];
                _this.getTranscriptAction(studentId);
            }
        };

        $scope.studentId = UsersService.getId();
        _this.getTranscriptAction = function (studentId) {

            // Gets Full Name to display on transcript
            // Gets StudentId for datepickers on transcript
            var singleUser = UsersService.fetch(studentId);

            singleUser.then(function (student) {
                $scope.fullName = student.fullName;
                $scope.studentId = student.id;
            }, function(error) {
                //TODO based on feedback.
                alert('Error displaying Full Name!');
            });

            // Gets the list of assignments based on studentId
            var assignments = TranscriptsService.fetch(studentId);

            assignments.then(function (success) {
                $scope.assignments = success;

                $scope.pageOptions.isLoading = false; // We're no longer loading!

                $scope.sortBy = 'status';
            }, function (response) {
                $scope.assignments = null;

                $scope.pageOptions.isLoading = false; // We're no longer loading!

                $scope.pageOptions.error.setError(response.StatusCode, response.ErrorMessage);
            });

            BreadcrumbsService.pushCrumb("my team", 'users/home');
            BreadcrumbsService.pushCrumb("transcripts");

        };

        /**
         * Delete a user's assignment
         *
         * @param assignment Object the assignment that will be dropped
         */
        $scope.removeAssignment = function (assignment) {
            var confirmRemove;

            if (assignment.type.toLowerCase() === 'coursegroupcurricula' || assignment.type.toLowerCase() === 'coursegroupcertification' || assignment.type.toLowerCase() === 'coursegroup') {
                confirmRemove = confirm("This course was assigned by a course group. If you click OK, you will remove the entire course group. Are you sure you want to remove this course group?");
            } else {
                confirmRemove = confirm("Are you sure you want to remove this content?");
            }

            if (confirmRemove) {
                var assignmentRemove = AssignmentsService.removeAssignment(assignment);

                assignmentRemove.then(function (success) {
                    $scope.apiMessage = 'The course ' + assignment.name + ' has been successfully dropped.';
                    jQuery('#apiMessage-' + assignment.id).toggle();
                    setTimeout(function () {
                        _this.fetchStudentAssignments();
                    }, 2000);


                }, function (response) {
                    $scope.apiMessage = 'Sorry! There was an issue removing the course. Please try again!';
                    jQuery('#apiMessage-' + assignment.id).toggle();
                    setTimeout(function () {
                        jQuery('#apiMessage-' + assignment.id).toggle();
                    }, 2000);

                });
            } else {
                $scope.apiActionLoader = false;
            }
        };

        $scope.Math = window.Math;
        $scope.dump = function(value) { console.warn(value); };

        /* Defined here temporarily for list view. Will need to be factored out into an override */
        _this.fetchStudentAssignments = function () {
            $scope.isLoading = true;

            //Get input paramters from the url
            var params = $route.current.params.param.split('/');

            var currentDateRange = $location.search().type;

            var endDate = dateUtil.formatDate(new Date());
            var startDate = dateUtil.formatDate(new Date(currentDateRange));

            var courseState = params[2];
            var courseType = params[1];
            var studentId = params[0];
            var certCurrId = params[3];

            $scope.pageOptions.error.reset();

            var assignments = null;

            //If the page coming in is CourseGroupCert or CourseGroupCurr then call the service to get assignments based on the given course group
            if (courseType.toLowerCase() === 'coursegroupcurricula' || courseType.toLowerCase() === 'coursegroupcertification') {
                assignments = AssignmentsService.getCourseGroupCourses(studentId, courseType, certCurrId);
            } else {
                assignments = AssignmentsService.getAssignmentsBystateAndType(studentId, courseType, courseState, startDate.apiDate, endDate.apiDate);
            }

            var studentCall = UsersService.fetch(studentId);

            assignments.then(
                function (success) {
                    $scope.isLoading = false;
                    $scope.courseGroup = false;

                    var data = success;

                    // Assign english name to assignment
                    if (courseType.toLowerCase() === 'wbt') {
                        $scope.managerListViewHeader = 'Web Based Course Assignments';
                    } else if (courseType.toLowerCase() === 'ilt') {
                        $scope.managerListViewHeader = 'Instructor Led Course Assignments';
                    } else if (courseType.toLowerCase() === 'coursegroup') {
                        $scope.managerListViewHeader = 'Course Group Assignments';
                        $scope.courseGroup = true;
                    } else if (courseType.toLowerCase() === 'coursegroupcurricula' || courseType.toLowerCase() === 'coursegroupcertification') {
                        $scope.managerListViewHeader = data[0].certificationName + data[0].curriculumName + ' Courses';
                    } else {
                        $scope.managerListViewHeader = data.type;
                    }
                    
                    // Kick out the Elective/NotRequired Courses Start
                    var x = data.length-1;

                    for (x3 = data.length-1; x3 >= 0; x3--) {
                        if (data[x3].assignedById === data[x3].studentId || data[x3].isRequired === false || data[x3].parentCourseGroupId > 0)
                        {
                            data.splice(x, 1);
                        }
                        x--;
                    }
                    // Kick out the Elective/NotRequired Courses End




                    // Kick out the Duplicate Courses Start
                    var dataSortedById = data.sort(
                    function (a, b) {
                        if (a.id === b.id) {
                            return b.assignmentId - a.assignmentId;
                        }
                        return a.id > b.id ? 1 : -1;
                    });

                    var x2 = 0;

                    for (x2 = 0; x2 < dataSortedById.length; x2++)
                    {
                        for (y = x2+1; y < dataSortedById.length; y++) {
                            if (dataSortedById[x2].id === dataSortedById[y].id)
                            {
                                dataSortedById.splice(y, 1);
                            }
                        }
                    }
                    // Kick out the Duplicate Courses End

                    $scope.apiError = false;
                    $scope.assignments = data;
                    $scope.isLoading = false; // We're no longer loading!
                    $scope.responseComplete = true;
                },
                function (failure) {
                    $scope.apiError = true;
                    $scope.isLoading = false;
                    $scope.responseComplete = true;

                    $scope.pageOptions.error.setError(failure.StatusCode, failure.ErrorMessage);
                }
            );

            studentCall.then(function (student) {
                var apostrophe = student.fullName.slice(-1) === 's' ? '\'' : '\'s';

                $scope.studentFullName = student.fullName + apostrophe;
            }, function() {
                /**
                 * @TODO: What happens on a failure?
                 */
            });
        };

        /**
         * Get a full user tree structure
         *
         * @param managerId Integer ID, on which, to retrieve appropriate direct and subordinate team members
         */
        _this.getTeamRecursiveList = function(teamMember) {
            var audienceRoot = {};

            audienceRoot.firstName = teamMember.firstName;
            audienceRoot.lastName = teamMember.lastName;
            audienceRoot.studentId = teamMember.studentId;
            audienceRoot.hidden = false;

            // Preload the audience tab data
            if ($scope.apiDueDate !== null){
            TeamService.recursiveTeamList(teamMember.studentId, $scope.startDate.apiDate, $scope.endDate.apiDate, $scope.apiDueDate).then(function(teamTree) {
                audienceRoot.teamList = teamTree;

                $scope.addAssignments.audienceData = [audienceRoot]; // needs to be in array because
            }, function() {
                /**
                 * If there's a failure, let's at least show one person.
                 */
                $scope.addAssignments.audienceData = [audienceRoot]; // needs to be in array because
            });
            }
            else
            {
                TeamService.recursiveTeamList(teamMember.studentId, $scope.startDate.apiDate, $scope.endDate.apiDate, null).then(function(teamTree) {
                    audienceRoot.teamList = teamTree;
    
                    $scope.addAssignments.audienceData = [audienceRoot]; // needs to be in array because
                }, function() {
                    /**
                     * If there's a failure, let's at least show one person.
                     */
                    $scope.addAssignments.audienceData = [audienceRoot]; // needs to be in array because
                });
            }
        };

        $scope.toggleThisAssignmentsCalender = function($event, assignment) {
            assignment.openCalendar = !assignment.openCalendar;
        };

        /******************* OVERDUE MODAL *************************************************/

        $scope.overdueAssignments = [];
        $scope.overdueAssignments.assignments = [];
        $scope.overdueAssignments.error = errorDisplayerUtil.create();
        $scope.overdueAssignments.isLoading = false;
        $scope.callExport = false;
        $scope.openOverdueModal = function () {
          
            jQuery('#overdueModal').modal();
            $scope.overdueAssignments.isLoading = true;
            $scope.overdueAssignments.error.reset();
            var overdueCall = null;
            if ($scope.apiDueDate !== null)
            {
                
                overdueCall = TeamService.getOverdueAssignments($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursive, $scope.apiDueDate);
            }
            else
            {
                overdueCall = TeamService.getOverdueAssignments($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursive, null);
            }
            overdueCall.then(function (success) {
                $scope.overdueAssignments.assignments = success;
                $scope.overdueAssignments.isLoading = false;
            }, function (failure) {
                $scope.overdueAssignments.error.setError(failure.StatusCode, failure.ErrorMessage);
                $scope.overdueAssignments.isLoading = false;
            });
    
        };

        $scope.closeOverdueModal = function () {
            jQuery('#overdueModal').modal('hide');
        };

        // Used to submit the export overdue form.
        // This is because the export needs to bypass angular's $http service to return a downloaded file
        // so this posts the form to the server directly from the template instead of going through the service.
        $scope.exportOverdueAssignments = function () {
         
            $scope.callExport = true;
            $scope.currentTimezone = moment.tz.guess();
            $timeout(function () {
                $scope.callExport = false;
            }, 2000);

        };

        /******************* OVERDUE MODAL - END*********************************************/

        $scope.setDefaultAssignmentType = function () {
            //$scope.addAssignments.assignmentType = $scope.currentRoute === 'careerpaths' ? 'careerPath' : 'unknown';

            if ($scope.currentRoute === 'careerpaths') {
                $scope.addAssignments.assignmentType = 'careerPath';
                $scope.addAssignments.searchAssignments($scope.addAssignments.keyword, $scope.addAssignments.assignmentType);
            }
            else {
                $scope.addAssignments.assignmentType = 'unknown';
            }
        };

        $scope.isLoadImmediate = false;
        $scope.openSubordinateProfileModal = function (user) {
            $scope.user = { name: user.firstName + ' ' + user.lastName, job: user.jobType };
            $scope.userId = user.userId;
            $scope.$broadcast("openUserProfileModal");
        };
        
        $scope.cpFilter = {};
        $scope.cpFilter.direct = true;
        $scope.cpFilter.alterate = false;
        $scope.cpFilter.sub = false;
        $scope.changeCPStudentSummaryFilter = function (teamType, includeSubordinates) {
            $scope.cpFilter.direct = (teamType === 'direct' && !includeSubordinates) ? true : false;
            $scope.cpFilter.alterate = (teamType === 'alternate') ? true : false;
            $scope.cpFilter.sub = (teamType === 'direct' && includeSubordinates) ? true : false;

            getManagerCareerPathStudentSummary(teamType, includeSubordinates);
        };
    };
}]);

