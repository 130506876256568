app.service('MyDocumentsService', ['$http', 'dateUtil', 'courseFormatterUtil', function ($http, dateUtil, courseFormatterUtil) {

    var _this = this;

    _this.getAll = function () {
        var req = {
            method: 'GET',
            url: 'api/mydocuments',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getAllAssignments = function () {
        var req = {
            method: 'GET',
            url: 'api/mydocuments/assignments',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(function (success) {
            var returnSuccess = _this.format(success.data);
            return returnSuccess;
        }, function (failure) {
            throw failure;
        });
    };

    _this.add = function (courseId) {
        var req = {
            method: 'POST',
            url: 'api/mydocuments/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.remove = function (courseId) {
        var req = {
            method: 'DELETE',
            url: 'api/mydocuments/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.format = function (response) {
        var dates = ['assignedOn'];

        if (response !== null) {
            for (var document in response) {
                currDocument = response[document];

                for (var node in currDocument) {
                    if (currDocument[node] !== null && $.inArray(node, dates) !== -1) {
                        currDocument[node] = dateUtil.formatDate(currDocument[node]);
                    }
                }

                if (currDocument !== null && typeof currDocument.code !== 'undefined') {
                    currDocument.codeForImage = courseFormatterUtil.getCourseCodeForImage(currDocument.code, currDocument.tileImage);
                }

            }
        }

        return response;

    };

}]);