app.service('SearchServices', ['$http', '$q', '$rootScope', 'AssignmentsService', 'dateUtil', 'CatalogSearchParameters', 'courseFormatterUtil', function ($http, $q, $rootScope, AssignmentsService, dateUtil, CatalogSearchParameters, courseFormatterUtil) {
    var _this = this;
    var synonymsMap = null;
    var catalogPaths = null;
    var documentCatalogPaths = null;
    _this.currentDocumentCount = -1;

    _this.executeProductSearch = function (query) {
        var queryToSend = CatalogSearchParameters.getQueryToSend();

        console.log("query to send");
        console.log(queryToSend);
        var endpoint = 'api/productsearch';
        if ($rootScope.fromWeb === true) {
            endpoint = 'api/public/productsearch';
        }
        var req = {
            method: 'POST',
            url: endpoint,
            data: $.param(queryToSend),
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            if (success.data === "") {
                throw { StatusCode: 0, ErrorMessage: "Unable to retrieve search results at this time" };
            } else {
                var data = JSON.parse(success.data);
                if (data.error !== undefined) {
                    throw { StatusCode: 0, ErrorMessage: "Invalid search request" };
                } else {
                    var results = {
                        count: data['@odata.count'],
                        facets: data['@search.facets'],
                        results: _this.formatSearchResults(data.value)
                    };
                    // Left this in to debug queries
                    console.log("Azure results");
                    console.log(results);
                    return results;
                }
            }
        }, function (failure) {
            throw failure;
        });
    };

    _this.formatSearchResults = function(azureResults) {
        var result = [];
        _.each(azureResults, function(r) {
            var formatted = _this.formatDocument(r);
            result.push(formatted);
        });

        return result;
    };


    _this.getSynonymsMap = function () {
        var endpoint = 'api/productsearch/synonyms';
        if ($rootScope.fromWeb === true) {
            endpoint = 'api/public/productsearch/synonyms';
        }
        if (synonymsMap === null) {
            var req = {
                method: 'GET',
                url: endpoint,
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'max-age=86400'
                },
                timeout: window.timeout
            };

            return $http.get(req.url).then(function (success) {
                var synonyms = JSON.parse(success.data).synonyms;
                synonymsMap = _this.formatSynonyms(synonyms);
                return synonymsMap;
            }, function (failure) {
                throw failure;
            });
        }
        else {
            return $q.resolve(synonymsMap);
        }

        
    };

    _this.formatSynonyms = function (synonymsString) {
        var result = {};
        var synonymsArray = synonymsString.split('\n');

        var i;
        for (i = 0; i < synonymsArray.length - 1; i++) {

            var synonymArray = synonymsArray[i].split(", ");
            var term = synonymArray[0].toLowerCase().trim();
            result[term] = {synonyms: []};
               
            var j;
            for (j = 1; j < synonymArray.length; j++) {
                result[term].synonyms.push(synonymArray[j]);
            }
        }
        return result;
    };


    _this.getSuggestions = function (searchTerm) {
        var endpoint = 'api/productsearch/suggestions?q=';
        if ($rootScope.fromWeb === true) {
            endpoint = 'api/public/productsearch/suggestions?q=';
        }
        var req = {
            method: 'GET',
            url: endpoint + encodeURI(searchTerm),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http.get(req.url).then(function (success) {
            var suggestions = JSON.parse(success.data).value;
            return _this.formatSuggestions(suggestions);
        }, function (failure) {
            throw failure;
        });
    };

    _this.formatSuggestions = function (azureResults) {
        var results = [];
        azureResults.forEach(function (course) {
            var x = {
                name: course.Name,
                searchText: course["@search.text"],
                courseId: course.CourseId,
                courseCode: course.CourseCode,
                detailsUri: _this.getDetailsUri(course)
            };

            if ($rootScope.fromWeb === true) {
                x.library = _this.getShortLibraryName(_this.getTopLevelPath(course.Paths));
            }
            results.push(x);
        });
        return results;
    };

    _this.getProductById = function (productId) {
        var endpoint = 'api/getproduct/' + productId;
        if ($rootScope.fromWeb === true) {
            endpoint = 'api/public/getproduct/' + productId;
        }
        var req = {
            method: 'GET',
            url: endpoint,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http.get(req.url).then(function (success) {
            if ($rootScope.fromWeb === false) {
                if (success.data === "") {
                    throw { StatusCode: 0, ErrorMessage: "Product Not Found" };
                }
            }
            var result = {
                document: JSON.parse(success.data)
            };
            if ($rootScope.fromWeb === true) {
                if (result.document.value.length === 0) {
                    throw { StatusCode: 0, ErrorMessage: "Product Not Found" };
                } else {
                    result.document = result.document.value[0];
                }
            }
            result.document = _this.formatDocument(result.document);

            return result;
        }, function (failure) {
            throw failure;
        });
    };

    _this.formatDocument = function (azureDocument) {
        if (azureDocument.Description.length > 152) {
            azureDocument.shortDescription = azureDocument.Description.substring(0, 148) + ' ...';
        } else {
            azureDocument.shortDescription = azureDocument.Description;
        }
        if (azureDocument.Duration === null) {
            azureDocument.displayDuration = "";
        } else {
            azureDocument.displayDuration = Math.round(azureDocument.Duration * 60) + " minutes"; // Convert hours to minutes for display
        }
        azureDocument.displayImageCode = courseFormatterUtil.getCourseCodeForImage(azureDocument.CourseCode, azureDocument.ImageCode);
        azureDocument.displayInteractivity = azureDocument.InteractivityTypes.join(', ');
        azureDocument.displayNotes = azureDocument.Notes.join(', ');
        azureDocument.displayRoles = azureDocument.Roles.join(', ');
        azureDocument.displaySkills = azureDocument.Skills.join(', ');
        azureDocument.displayInteractivity = azureDocument.InteractivityTypes.join(', ');
        azureDocument.displayTopics = azureDocument.Topics.join(', ');
        azureDocument.detailsUri = _this.getDetailsUri(azureDocument);
        return azureDocument;
    };

    _this.getDetailsUri = function (product) {
        var detailsUriBase = "";
        var detailsUriSuffix = "";
        var detailsUri = "";

        if ($rootScope.fromWeb === false) {
            detailsUriBase = "catalog/product/";
            detailsUriSuffix = product.CourseId;
        } else {
            detailsUriBase = "product/";
            detailsUriSuffix = product.CourseCode.replace(/(\s|\/)/g, '-') +
                "-" + product.Name.toLowerCase().replace(/(\s|\/)/g, '-').replace(/&/g, 'and');
        }
        detailsUri = detailsUriBase + detailsUriSuffix;
        return detailsUri;
    };

    _this.getCatalog = function () {
        var endpoint = 'api/productsearch/catalog';
        if ($rootScope.fromWeb === true) {
            endpoint = 'api/public/productsearch/catalog';
        }
        if (catalogPaths === null) {
            var req = {
                method: 'GET',
                url: endpoint,
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'max-age=86400'
                },
                timeout: window.timeout
            };

            return $http.get(req.url).then(function (success) {
                var paths = JSON.parse(success.data)["@search.facets"].Paths;
                catalogPaths = paths;
                return catalogPaths;
            }, function (failure) {
                throw failure;
            });
        }
        else {
            return $q.resolve(catalogPaths);
        }
    };

    _this.getDocumentCatalog = function () {
        var endpoint = 'api/productsearch/documentcatalog';
        if (documentCatalogPaths === null) {
            var req = {
                method: 'GET',
                url: endpoint,
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'max-age=86400'
                },
                timeout: window.timeout
            };

            return $http.get(req.url).then(function (success) {
                var paths = JSON.parse(success.data)["@search.facets"].Paths;
                documentCatalogPaths = paths;
                return documentCatalogPaths;
            }, function (failure) {
                throw failure;
            });
        }
        else {
            return $q.resolve(documentCatalogPaths);
        }
    };

    _this.formatCatalogPaths = function (azurePaths) {
        var taxonomy = {};
        taxonomy.libraries = [];
        taxonomy.paths = [];
        //get the top levels
        azurePaths.forEach(function (azurePath) {
            if (azurePath.value.indexOf('|') === -1) {
                taxonomy.libraries.push(azurePath);
            }
            else {
                taxonomy.paths.push(azurePath);
            }
        });
        return taxonomy;
    };



    _this.getDocumentCount = function (refresh) {
        if (refresh === undefined) {
            refresh = false;
        }

        if (_this.currentDocumentCount !== undefined && _this.currentDocumentCount > -1 && !refresh) {
            return $q.resolve(_this.currentDocumentCount);
        } else {
            var req = {
                method: 'GET',
                url: 'api/productsearch/documentcount/',
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            return $http(req).then(function (success) {
                var data = JSON.parse(success.data);
                _this.currentDocumentCount = data['@odata.count'];
                return _this.currentDocumentCount;
            }, function (failure) {
                throw failure;
            });
        }
    };

    _this.getTopLevelPath = function (azurePaths) {
        return azurePaths[0].split("|")[0];
    };

    _this.getShortLibraryName = function (library) {
        var short = {};
        switch (library.toLowerCase()) {
            case "banking series":
                short = "Bank";
                break;
            case "credit union series":
                short = "Credit Union";
                break;
            case "mortgage series":
                short = "Mortgage";
                break;
            case "nonbank series":
                short = "Nonbank";
                break;
            default:
                short = "";
        }
        return short;
    };
}]);
