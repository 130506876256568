app.directive('subContainer', ['$filter', function($filter) {
    return {
        restrict: 'E',
        scope: {
            'data': '='
        },
        templateUrl: 'app/templates/users/editpeoplegroup/partials/peopleGroupContainer.html',
        link: function(scope, element, attrs) {
        	scope.criterias = scope.data.criteria;
          // console.log(scope.criterias);
         console.log(scope.data);

        }
    };
}
]);