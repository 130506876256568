app.directive("resizer", function($document) {

	return function($scope, $element, $attrs) {

		$element.on('mousedown', function(event) {
			event.preventDefault();

			$document.on('mousemove', mousemove);
			$document.on('mouseup', mouseup);
		});

		function mousemove(event) {

			if ($attrs.resizer === 'vertical') {
				// Handle vertical resizer

				// There's no pre-calculated percentage property so
				// We get the pageX and convert to percentage wrt width of page

				var x = event.pageX / $(window).width() * 100;

				if ($attrs.resizerMax && x > $attrs.resizerMax) {
					x = parseInt($attrs.resizerMax);
				} else if ($attrs.resizerMax && x < $attrs.resizerMin) {
					x = parseInt($attrs.resizerMin);
				}

				$element.css({
					left: x + '%'
				});

				$($attrs.resizerLeft).css({
					width: x + '%'
				});
				
				$($attrs.resizerRight).css({
					width: (100 - x) + '%'
				});

			} else {
				// Handle horizontal resizer - Not in use as of now in this application.
				var y = window.innerHeight - event.pageY;

				$element.css({
					bottom: y + 'px'
				});

				$($attrs.resizerTop).css({
					bottom: (y + parseInt($attrs.resizerHeight)) + 'px'
				});
				$($attrs.resizerBottom).css({
					height: y + 'px'
				});
			}
		}

		function mouseup() {
			$document.off('mousemove', mousemove);
			$document.off('mouseup', mouseup);
		}
	};
});