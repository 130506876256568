app.directive("loadingSpinner", function () {
    return {
        restrict: "E",
        scope: {
            activator: '='
        },
        link: function(scope, element, attrs) {
        },
        templateUrl: 'app/templates/components/spinner.html'
    };
});
