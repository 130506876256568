/* The login page */
app.controller('indexController', ['$rootScope', '$scope', '$timeout', '$location', '$route', 'UsersService', 'SearchServices', 'loginBackgroundUtil', 'messagingDisplayerUtil', function ($rootScope, $scope, $timeout, $location, $route, UsersService, SearchServices, loginBackgroundUtil, messagingDisplayerUtil) {
    $scope.forgotPassTemplateSwitcher = false;
    $scope.loading = false;
    $scope.iframeLoaded = false;
    $scope.lockOut = false;
    $scope.copyrightYear = moment().format('YYYY');

    var failures = 0;

    $scope.loginstate = {};
    $scope.loginstate.error = messagingDisplayerUtil.create();

    window.iframeLoadedCallBack = function () {
        $scope.iframeLoaded = true;
    };

    // added to show the IE message on login page 
    var ieWarningMessage = document.createElement("div");
    ieWarningMessage.style.backgroundColor = "#fcbdbd";
    ieWarningMessage.style.width = "100%";
    ieWarningMessage.style.fontSize = "1em !important";
    ieWarningMessage.style.padding = "0.5em";
    ieWarningMessage.innerHTML = "<p><span style=\"font-size: 2em\">⚠</span> Warning! Microsoft is <a href=\"https://docs.microsoft.com/en-us/lifecycle/announcements/m365-ie11-microsoft-edge-legacy\">ending web application support for Internet Explorer</a> soon. This site is following Microsoft's support lifecycle for Internet Explorer. If you still need IE for certain sites or applications, we recommend using <a href=\"https://docs.microsoft.com/en-us/deployedge/edge-ie-mode\">Microsoft Edge with IE Mode enabled</a> for only those sites. If you do not need IE any longer, you can choose any <em>modern</em> browser such as Firefox, Chrome, or Edge.</p>";
    if (window.navigator.userAgent.indexOf("Trident/") > 0) {
        document.body.insertBefore(ieWarningMessage, document.body.firstChild);
    }

    $scope.login = function () {
        if(failures >= 10) {
            return;
        }

        var username = this.username;
        var password = this.password;

        $scope.loading = true;

        UsersService.login(username, password).then(
            function (success) {
                var userLoginStatus = success.loginStatus;
                if (userLoginStatus === 'resetPassword') {
                    $scope.password = "";

                    jQuery('.signin').toggle();
                    jQuery('.resetpassword').toggle();
                }
                else {
                    $location.url('/student/home/?welcome=true');
                }

            }, function (failure) {
                $scope.loading = false;

                if (failure.StatusCode === 401 && failure.ErrorMessage === "Access to this account is restricted at this time\r\n") {
                    $location.path('/portal/pause/');
                }
                else {
                    $scope.loginstate.error.setError(failure.StatusCode, failure.ErrorMessage);
                }
            }
        );
    };

    $scope.submitPasswordReset = function () {
        var username = this.username;

        UsersService.requestTemporaryPassword(username).then(
            function (success) {
                $scope.forgotForm(2);

                // Clear the password box to prevent autofill
                // This password box shouldn't autofilled
                // because it's a temporary password
                // I would do autocomplete="off" but browsers/plugins
                // Don't respect that as much anymore.
                // http://stackoverflow.com/a/23234498/761726
                $scope.password = "";

                setTimeout(function () {
                    $scope.forgotForm(3);
                }, 1000);
            }, function (failure) {
                $scope.loading = false;

                var errorMessage = failure;
                var slideTime = 500;
                if (errorMessage.indexOf('Invalid User') > 0) {
                    $scope.errorMessage = 'Username Not Found';
                } else {
                    $scope.errorMessage = 'This is a SSO Username. Please contact your Training Administrator if you are experiencing any error while attempting to Log In using SSO.';
                    slideTime =8000;
                }

                jQuery('#username-error').slideDown(500, function () {
                    var _this = jQuery(this);

                    setTimeout(function () {
                        _this.slideUp(parseInt(500));
                    }, slideTime);
                });
            }
        );
    };

    $scope.checkNewPassword = function () {
        var password = this.password;
        var username = this.username;

        /**
         * Runs login, not reset. Password will already have been reset.
         */
        UsersService.validatePassword(username, password).then(
            function () {
                $scope.password = "";
                $scope.forgotForm(4);

                setTimeout(function () {
                    $scope.forgotForm(5);
                }, 1000);
            }, function () {
                $scope.loading = false;

                jQuery('#reset-error').slideDown(500, function () {
                    var _this = jQuery(this);

                    setTimeout(function () {
                        _this.slideUp(500);
                    }, 1000);
                });
            }
        );
    };

    $scope.resetPassword = function () {
        var password = this.password;
        var username = this.username;

        /**
         * Runs login, not reset. Password will already have been reset.
         */
        UsersService.resetPassword(password).then(function() {
            $scope.forgotForm(6);
            UsersService.login(username, password).then(
                function () {
                    $location.url('/student/home/');
                }, function () {

                }
            );
        }, function(failure) {
            $scope.loading = false;

            jQuery('#reset-error').slideDown(500, function () {
                var _this = jQuery(this);

                setTimeout(function () {
                    _this.slideUp(500);
                }, 1000);
            });
        });
    };

    $scope.forgotPassClick = function (value) {
        $scope.forgotPassTemplateSwitcher = value;
    };

}]);
