app.factory('Insights_DecisionMakerOverride', ['$route', '$location', 'BreadcrumbsService', 'InsightsDecisionMapService', function ($route, $location, BreadcrumbsService, InsightsDecisionMapService) {
    return function ($scope) {

        $scope.homeTemplate = 'app/templates/insights/decisionMaker/decisionmaker.html';
        $scope.homeAction = function () {
            $scope.decisionMakerContent = 'app/templates/insights/decisionMaker/decisionmapsummaries.html';
            BreadcrumbsService.pushCrumb("Training Insights", "insights/home");
            BreadcrumbsService.pushCrumb("Decision Reports", "");
        };

        $scope.createdecisionmapAction = function () {
            $scope.decisionMakerContent = 'app/templates/insights/mappingdecisions/createandstart.html';
            BreadcrumbsService.pushCrumb("Training Insights", "insights/home");
            BreadcrumbsService.pushCrumb("Create Decision Report", "");
        };

        $scope.wizardAction = function () {
            var params = $route.current.params.param.split('/');
            $scope.currentWizardStep = params[0];
            $scope.decisionMakerContent = "app/templates/insights/mappingdecisions/wizard/wizardContainer.html";
            BreadcrumbsService.pushCrumb("Training Insights", "insights/home");
            BreadcrumbsService.pushCrumb("Decision Report Wizard", "");
        };

        $scope.decisionmapcoursesAction = function () {
            //Add get the decisionmapID from the URL and populate the course page with it
            var params = $route.current.params.param.split('/');
            $scope.decisionMapID = params[0];

            $scope.decisionMakerContent = 'app/templates/insights/mappingdecisions/courses.html';

            BreadcrumbsService.pushCrumb("Training Insights", "insights/home");
            BreadcrumbsService.pushCrumb("Decision Report Courses", "");
        };

        $scope.decisionmapreportAction = function () {
            var params = $route.current.params.param.split('/');
            $scope.decisionMapID = params[0];
            $scope.decisionMakerContent = 'app/templates/insights/mappingdecisions/report.html';
            BreadcrumbsService.pushCrumb("Training Insights", "insights/home");
            BreadcrumbsService.pushCrumb("Decision Report Report", "");
        };

        $scope.createNewDecisionMap = function () {
            InsightsDecisionMapService.reset();
            $location.path("insights/createdecisionmap");
        };

        $scope.decisionmapreport = function() {
            $location.path('insights/home');
        };
        $scope.openVideo = function (src, new_page_title) {

            var new_window = window.open('', '_blank', 'location=1,status=1,toolbar=1,scrollbars=1,resizeable=1,width=1000,height=800');
            new_window.document.write('<!doctype html><html><head><title>' + new_page_title + '</title><meta charset="UTF-8" /></head><body><video controls style="width: 100%; height: auto;"><source src="' + src + '" target="_blank" type="video/mp4" /></video></body></html>');

        };
    };
}]);