app.directive('viewAddAssignmentsModal', [function () {
    return {
        restrict: 'E',
        templateUrl: 'App/templates/users/partials/admin.viewAssignmentsModal.html',
        controller: ['$scope', '$timeout', '$filter','$q','errorDisplayerUtil', 'UsersService', 'AssignmentsService', 'CoursesService', 'dateUtil', 'TranscriptsService', 'RosterService',
            function ($scope, $timeout, $filter, $q, errorDisplayerUtil, UsersService, AssignmentsService, CoursesService, dateUtil, TranscriptsService, RosterService) {


            $scope.defer = null;
            $scope.requiredOptions = [{
                "name": "Yes",
                "value": true
            }, {
                "name": "No",
                "value": false
            }];

            /* establish scope variable to determine if the user is a TA */
            /* rules differ for TA and Manager */
            $scope.currentRoleTA = false;
            var currentUserRole = UsersService.getCurrentRoleType();
            if (currentUserRole === 'ta') {
                $scope.currentRoleTA = true;
            }

            $scope.loggedInUserId = UsersService.getId();

            /* Called on initial load of the modal*/
            $scope.viewAssignments = function (firstName, userId) {
                $scope.userName = firstName;
                $scope.studentId = userId;

                angular.element(document.querySelector('#viewAssignmentsModal')).modal();
            };

            $scope.viewAssignmentsError = {};
            $scope.viewAssignmentsError.error = errorDisplayerUtil.create();
            /* displays all assignments in the modal */
            var viewAssignmentsCta = function (userId) {

                $scope.assignmentsAreLoading = true;
                //Initial pageload - this will set the ascending sorting on coursename
                $scope.propertyName = 'name';

                // Reset the Error
                $scope.viewAssignmentsError.error.reset();
                $scope.assignmentsData = null;

                // API Call to get assignments
                var assignmentsPromise = AssignmentsService.fetch(userId);

                assignmentsPromise.then(function (success) {
                    $scope.assignmentsData = formatViewAssignments(success);
                    $scope.assignmentsAreLoading = false;

                }, function (failure) {
                    $scope.assignmentsAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.viewAssignmentsError.error.setError(errorCode, message);
                });
            };

            /* date, pretty status, etc formatting for Assignments*/  
            var formatViewAssignments = function (data) {
                _.each(data, function (datapoint) {
                    datapoint.errorSpinner = false;
                    if (datapoint.isDroppable && datapoint.prescriptiveRuleId !== null)
                    { datapoint.isDroppable = false; }
                    datapoint.displayStatus = (datapoint.status === 'inProgress') ? 'In Progress' : capitalize(datapoint.status);
                    datapoint.typeToDisplay = $filter("assignmentAssetTypeName")(datapoint.type);

                    if (datapoint.dueDate !== null && typeof datapoint.dueDate.jsDate !== 'undefined') {
                        datapoint.originalDueDate = datapoint.dueDate.jsDate.getTime();
                    }
                    else {
                        datapoint.originalDueDate = null;
                    }

                    datapoint.disableRequiredDropdown = false;
                    datapoint.requiredDropdownTitleText = "";
                    datapoint.disableDueDatePicker = false;
                    datapoint.noDueDateText = "";
                    datapoint.dueDateTitleText = "";
                    if ($scope.currentRoleTA) {
                        if (datapoint.prescriptiveRuleId !== null) {
                            datapoint.disableRequiredDropdown = true;
                            datapoint.requiredDropdownTitleText = "Required status for Rule assignments cannot be modified";
                        } else if (datapoint.studentId === datapoint.assignedById) {
                            datapoint.disableRequiredDropdown = true;
                            datapoint.requiredDropdownTitleText = "Required status for ad hoc enrollments cannot be modified";
                        }

                        if (datapoint.dueDate !== null && (datapoint.prescriptiveRuleId !== null || datapoint.assignedById === datapoint.studentId)) {
                            datapoint.disableDueDatePicker = true;
                            if (datapoint.prescriptiveRuleId !== null) {
                                datapoint.dueDateTitleText = "Due dates for Rule assignments cannot be modified.";
                            } else {
                                if (datapoint.assignedById === datapoint.studentId) {
                                    datapoint.dueDateTitleText = "Due dates for ad hoc enrollments cannot be modified.";
                                }
                            }
                        } else if (datapoint.dueDate === null && (datapoint.prescriptiveRuleId !== null || datapoint.assignedById === datapoint.studentId)) {
                            datapoint.disableDueDatePicker = true;
                            datapoint.noDueDateText = "No Due Date";
                            if (datapoint.prescriptiveRuleId !== null) {
                                datapoint.dueDateTitleText = "Due dates for Rule assignments cannot be modified.";
                            } else {
                                if (datapoint.assignedById === datapoint.studentId) {
                                    datapoint.dueDateTitleText = "Due dates for ad hoc enrollments cannot be modified.";
                                }
                            }
                        }
                    } else {
                        if (datapoint.assignedById !== $scope.loggedInUserId) {
                            datapoint.disableRequiredDropdown = true;
                            datapoint.requiredDropdownTitleText = "Required status can only be updated for courses you assigned";
                            datapoint.disableDueDatePicker = true;
                            datapoint.dueDateTitleText = "Due dates can only be updated for courses you assigned";
                            if (datapoint.dueDate === null) {
                                datapoint.noDueDateText = "No Due Date";
                            }
                        } else {
                            if (datapoint.dueDate !== null && (datapoint.prescriptiveRuleId !== null || datapoint.assignedById === datapoint.studentId)) {
                                datapoint.disableDueDatePicker = true;
                                if (datapoint.prescriptiveRuleId !== null) {
                                    datapoint.dueDateTitleText = "Due dates for Rule assignments cannot be modified.";
                                } else {
                                    if (datapoint.assignedById === datapoint.studentId) {
                                        datapoint.dueDateTitleText = "Due dates for ad hoc enrollments cannot be modified.";
                                    }
                                }
                            } else if (datapoint.dueDate === null && (datapoint.prescriptiveRuleId !== null || datapoint.assignedById === datapoint.studentId)) {
                                datapoint.disableDueDatePicker = true;
                                datapoint.noDueDateText = "No Due Date";
                                if (datapoint.prescriptiveRuleId !== null) {
                                    datapoint.dueDateTitleText = "Due dates for Rule assignments cannot be modified.";
                                } else {
                                    if (datapoint.assignedById === datapoint.studentId) {
                                        datapoint.dueDateTitleText = "Due dates for ad hoc enrollments cannot be modified.";
                                    }
                                }
                            }
                        }
                    }

                    datapoint.originalIsRequired = datapoint.isRequired; // Necessary to keep track of which assignments changed for the update function 
                });

                return data;
            };

            /* Switches the modal view to the add assignments modal */
            $scope.addNewAssignmentCta = function () {
                angular.element(document.querySelector('#viewAssignmentsModal')).modal('hide');
                $scope.$broadcast('openAddAssignmentsModal', { displaytype: 2, studentId: $scope.studentId });
                $scope.assignmentModalhide = false;
              
            };


            jQuery('#viewAssignmentsModal').on('shown.bs.modal', function () {
                $scope.closeNewAssignmentModal();
            });
        /* Switches the modal view to the view assignments modal */
            $scope.closeNewAssignmentModal = function () {

                $scope.assignmentModalhide = true;

                viewAssignmentsCta($scope.studentId);
                $scope.courseModal.courseSearch = '';
                $scope.modalBodyShow = false;
            };


            /* add assignments course search */
            $scope.courseModal = {};
            $scope.courseModal.courseSearch = '';
            $scope.courseModal.selectAll = false;
            $scope.viewCoursesError = {};
            $scope.viewCoursesError.error = errorDisplayerUtil.create();
            $scope.coursesAreLoading = true;
            $scope.assignmentsAddedSuccessfully = false;
            $scope.getCoursesCta = function (keyword) {
                $scope.modalBodyShow = true;
                $scope.selectedCourseCount = 0;
                $scope.viewCoursesError.error.reset();
                $scope.courseModal.selectAll = false;
                $scope.coursesAreLoading = true;

                // API Call to get assignments
                var addCoursePromise = CoursesService.getActiveCoursesForOrg(false, false);
                addCoursePromise.then(function (success) {
                    var formattedCourseData = formatCoursesData(success);

                    if (typeof keyword !== 'undefined' && keyword !== null) {

                        formattedCourseData = $filter('filter')(formattedCourseData, keyword);
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });


                    } else {
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });
                    }

                    $scope.coursesAreLoading = false;
                }, function (failure) {
                    $scope.coursesAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.viewCoursesError.error.setError(errorCode, message);
                });
            };

            var formatCoursesData = function (data) {
                _.each(data, function (datapoint) {
                    datapoint.select = false;
                });

                return data;
            };

            $scope.addAllCourses = function (value) {
                _.each($scope.courseData, function (course) {
                    course.select = value;
                });
                if (value === true) {
                    $scope.selectedCourseCount = $scope.courseData.length;
                } else {
                    $scope.selectedCourseCount = 0;
                }
            };

            $scope.selectedCourseCount = 0;
            $scope.checkForLength = function (value) {
                if (value === true) {
                    $scope.selectedCourseCount++;
                } else {
                    $scope.selectedCourseCount--;
                }
            };

            /* Add the selected courses to the students assigments */
            $scope.applyCourseError = {};
            $scope.applyCourseError.error = errorDisplayerUtil.create();
            $scope.coursesAreApplying = false;
            $scope.applyNewAssignmentsCta = function (data) {
                var selectedCourses = getSelectedCourses(data);

                $scope.assignmentsAddedSuccessfully = true;
                $scope.coursesAreApplying = true;
                $scope.applyCourseError.error.reset();

                var applyCoursesPromise = AssignmentsService.assignAssignment(selectedCourses);
                applyCoursesPromise.then(function (success) {
                    $scope.coursesAreApplying = false;
                    $scope.assignmentsAddedSuccessfully = true;
                    $scope.courseModal.courseSearch = '';

                    $timeout(function () {
                        $scope.assignmentsAddedSuccessfully = false;
                        $scope.closeNewAssignmentModal();
                    }, 3000);
                }, function (failure) {
                    $scope.coursesAreApplying = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.viewCoursesError.error.setError(errorCode, message);
                });

            };

            var getSelectedCourses = function (data) {
                var selectedCoursesArray = [];
                var userId = $scope.studentId;

                _.each(data, function (datapoint) {
                    if (datapoint.select === true) {
                        datapoint.StudentId = userId;
                        if (datapoint.type === "careerPath" || ($scope.currentRoleTA && $scope.loggedInUserId === userId)) {
                            datapoint.isRequired = false;
                        } else {
                            datapoint.isRequired = true;
                        }
                        selectedCoursesArray.push(datapoint);
                    }
                });

                return selectedCoursesArray;
            };


            $scope.changeDueDate = function (dateObj, assignment) {
                assignment.dueDate = $scope.getEndDateTimeFromPicker(dateObj);
            };

            /* Get all assignments that were edited */
            var changedAssignments = [];
            $scope.getChangedAssignments = function () {
                var assignments = $scope.assignmentsData;
                for (var i in assignments) {
                    if (_.isObject(assignments[i])) {
                        if ((assignments[i].dueDate === null && assignments[i].originalDueDate !== null) ||
                            (assignments[i].dueDate !== null && assignments[i].originalDueDate === null)) {
                            changedAssignments.push(assignments[i]);
                            continue; // Only add the assignment once
                        }
                        else if (assignments[i].originalDueDate !== null && assignments[i].dueDate !== null) {
                            if (_.isObject(assignments[i].dueDate)) {
                                if (assignments[i].dueDate.moment.toDate().getTime() !== assignments[i].originalDueDate) {
                                    changedAssignments.push(assignments[i]);
                                    continue; // Only add the assignment once
                                }
                            }
                        }

                        // TODO: How do we know if Required dropdown changed?
                        if (assignments[i].isRequired !== assignments[i].originalIsRequired) {
                            console.log("Required changed");
                            changedAssignments.push(assignments[i]);
                        }
                    }
                }
            };

            /* save any changes to assignments and close the modal */
            $scope.applyCtaViewAssignments = function () {
                $scope.getChangedAssignments();
                var assignmentsData = changedAssignments;

                _.each(assignmentsData, function (assignment) {
                    updateAssignmentsCta(assignment);
                });

                changedAssignments = [];
                angular.element(document.querySelector('#viewAssignmentsModal')).modal('hide');

                if (!$scope.currentRoleTA) {
                    $scope.homeAction();
                }
            };

            var updateAssignmentsCta = function (assignment) {
                assignment.errorSpinner = true;

                var userId = UsersService.getId();
                var studentId = $scope.studentId;
                var assignmentCourseId = assignment.assignmentCourseId;
                var finalDate = null;
                if (assignment.dueDate !== null && assignment.dueDate.moment.isValid()) {
                    finalDate = assignment.dueDate.moment.toISOString();
                }

                var changePromise = AssignmentsService.updateAssignments(assignment);
                changePromise.then(function (success) {
                    assignment.errorSpinner = false;
                }, function (failure) {
                    assignment.errorSpinner = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    assignment.error.setError(errorCode, message);
                });
            };

            $scope.toggleRemoveAssignmentConfirmation = function (assignment, closePopup) {
                jQuery('.removeCourseContainer .confirmSave.confirmRemove').toggle(false);

                if (typeof closePopup !== 'undefined' && closePopup === true) {
                    jQuery('#removeConfirmation-' + assignment.assignmentCourseId).toggle(false);
                } else {
                    jQuery('#removeConfirmation-' + assignment.assignmentCourseId).toggle(true);
                }

            };

            $scope.toggleWaiveAssignmentConfirmation = function (assignment, closePopup) {
                jQuery('.removeCourseContainer .confirmSave.confirmRemove').toggle(false);
                if (typeof closePopup !== 'undefined' && closePopup === true) {
                    jQuery('#waiveConfirmation-' + assignment.assignmentCourseId).toggle(false);
                } else {
                    jQuery('#waiveConfirmation-' + assignment.assignmentCourseId).toggle(true);
                }

            };

            $scope.toggleReactivateAssignmentConfirmation = function (assignment, closePopup) {
                jQuery('.removeCourseContainer .confirmSave.confirmRemove').toggle(false);
                if (typeof closePopup !== 'undefined' && closePopup === true) {
                    jQuery('#reactivateConfirmation-' + assignment.assignmentCourseId).toggle(false);
                } else {
                    jQuery('#reactivateConfirmation-' + assignment.assignmentCourseId).toggle(true);
                }

            };

            /**
             * Remove Assignments - Delete a user's assignment
             *
             * @param assignment Object the assignment that will be dropped
             */
            $scope.adminRemoveAssignment = function (assignment) {

                if (assignment.type === 'ilt')
                {
                    //get offerings to remove if unloaded
                    if (!assignment.offeringsLoaded) 
                    {
                        $scope.defer = $q.defer();
                        $scope.getRosteredOfferings(assignment);
                        $scope.defer.promise.then(
                            function(){ $scope.removeAssignmentOfferings(assignment); });
                    }
                    else
                    { 
                        $scope.removeAssignmentOfferings(assignment); 
                    }
                }
                $scope.removeSingleAssignment(assignment);
            };

            $scope.removeSingleAssignment = function (assignment) 
            { 
                $scope.assignmentsAreLoading = true;

                var adminAssignmentRemove = AssignmentsService.removeAssignment(assignment);
                adminAssignmentRemove.then(
                    function (success)
                    {
                        viewAssignmentsCta($scope.studentId);
                        setTimeout(function ()
                        {
                            $scope.assignmentsAreLoading = false;
                            jQuery('#removeConfirmation').show();
                            setTimeout(function ()
                            {
                                jQuery('#removeConfirmation').hide();
                            }, 3000);
                        }, 2000);
                    },
                    function (failure)
                    {
                        $scope.assignmentsAreLoading = false;
                        jQuery('#removeAssignmentError').show();

                        setTimeout(function ()
                        {
                            jQuery('#removeAssignmentError').hide();
                        }, 2000);
                    });
            };
            /**
             * Waive or reactivate a user's assignment
             *
             * @param assignment Object the assignment that will be dropped
             */
            $scope.adminWaiveorReactivateAssignment = function (assignment, waived) {

                $scope.assignmentsAreLoading = true;
                var adminAssignmentReactivate = AssignmentsService.waiveAssignment(assignment, waived);

                adminAssignmentReactivate.then(
                    function (success) {
                        viewAssignmentsCta($scope.studentId);
                    },
                    function (failure) {
                        $scope.assignmentsAreLoading = false;
                    });
            };

            /*******  MARK ASSIGNMENT SUCCESSFUL ********/
            $scope.invalidScore = false;
            $scope.updateFailed = false;
            $scope.studentId = {};
            $scope.successfulAssignment = {};

            $scope.markSuccessful = function (assignment) {
                if ($scope.currentRoleTA) {
                    $scope.completionDate = moment();
                    $scope.completionMaxDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
                    $scope.completionMinDate = assignment.startDate.moment;
                    $scope.completionMinDate.set({hour: 0, minute: 0});

                    angular.element(document.querySelector('#viewAssignmentsModal')).modal('hide');
                    angular.element(document.querySelector('#markSuccessfulModal')).modal();

                    $scope.successfulAssignment = assignment;
                }
            };

            $scope.closeMarkSuccessfulModal = function () {
                angular.element(document.querySelector('#markSuccessfulModal')).modal('hide');
                angular.element(document.querySelector('#viewAssignmentsModal')).modal();
                $scope.successfulAssignment = null;
            };

            $scope.updateSuccessfulAssignment = function (userId, assignment, date) {

                $scope.invalidScore = false;
                $scope.markingSuccessful = true;
                $scope.scopeError = "";
                //check valid score
                if (typeof assignment.score === 'undefined' || assignment.score === null || assignment.score < 0 || assignment.score > 100 || $.trim(assignment.score) === "") {
                    assignment.score = 0;
                }

                var originalStatus = assignment.status; //retain old values in case of failure
                var originalScore = assignment.score;
                assignment.status = "successful";
                assignment.completedDate = dateUtil.formatDate(dateUtil.setPortalEndTime(date.moment.toDate()));

                var taUpdateTranscript = TranscriptsService.updateSingle(userId, assignment);

                taUpdateTranscript.then(function (success) {

                    $scope.markingSuccessful = false; //stop spinner
                    $scope.closeMarkSuccessfulModal(); //close modal
                    viewAssignmentsCta($scope.studentId); //refresh assignments

                }, function (failure) {
                    assignment.status = originalStatus; //assign original values
                    assignment.score = originalScore;
                    assignment.completedDate = null;

                    $scope.markingSuccessful = false; //stop spinner
                    $scope.updateFailed = true; // show error
                    $scope.transcripterror = failure.ErrorMessage;
                    setTimeout(function () {
                        $scope.updateFailed = false; // hide error
                    }, 2000);
                });

            };

            $scope.resetAssignment = {};
            $scope.resetAssignmentStatus = function (assignment) {
                
                if ($scope.currentRoleTA) {
                    angular.element(document.querySelector('#viewAssignmentsModal')).modal('hide');
                    angular.element(document.querySelector('#resetStatusModal')).modal();

                    $scope.resetAssignment = assignment;
                }
            };

            $scope.closeResetStatusModal = function () {
                angular.element(document.querySelector('#resetStatusModal')).modal('hide');
                angular.element(document.querySelector('#viewAssignmentsModal')).modal();
                $scope.resetAssignment = null;
            };

            $scope.resetCourseStatus = function (assignment, userId) {
                $scope.markingSuccessful = true;
                var resetCoursePromise = CoursesService.resetCourseStatus(assignment.id, userId);

                resetCoursePromise.then(function (success) {
                    $scope.resettingCourseStatus = false; //stop spinner
                    $scope.closeResetStatusModal(); //close modal
                    viewAssignmentsCta($scope.studentId); //refresh assignments
                }, function (failure) {
                    $scope.markingSuccessful = false; //stop spinner
                    $scope.updateFailed = true; // show error
                    setTimeout(function () {
                        $scope.updateFailed = false; // hide error
                    }, 2000);
                });
            };

            $scope.sortBy = function (propertyName) {
                $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                $scope.propertyName = propertyName;
            };

            function capitalize(s) {

                return s && s[0].toUpperCase() + s.slice(1);

            }


            /*******  OFFERINGS  ********/
            $scope.getRosteredOfferings = function (assignment) {

                if (!assignment.offerings && !assignment.offeringsLoaded)
                {
                    var offerings = [];
                    assignment.offeringsLoaded = false;

                    var getOfferings = CoursesService.getSingleCourseOfferings(assignment.id);
                    getOfferings.then(
                        function (success)
                        {
                            assignmentOfferings = success;
                            
                            //return only rostered offerings
                            if (assignmentOfferings.length > 0)
                            {
                                _.each(assignmentOfferings, function (offering)
                                {
                                    if (offering.roster.attendingStudents.length > 0)
                                    {
                                        var attending = offering.roster.attendingStudents;
                                        _.each(attending, function (student)
                                        {
                                            if (student.id === $scope.studentId && student.completionDate === null) { offerings.push(offering); }
                                        });
                                    }

                                    if (offering.roster.waitlistedStudents.length > 0)
                                    {
                                        var waitlist = offering.roster.waitlistedStudents;
                                        _.each(waitlist, function (student)
                                        {
                                            if (student.id === $scope.studentId && student.completionDate === null) { offerings.push(offering); }
                                        });
                                    }

                                    if (offering.roster.pendingApprovalStudents.length > 0)
                                    {
                                        var pending = offering.roster.pendingApprovalStudents;
                                        _.each(pending, function (student)
                                        {
                                            if (student.id === $scope.studentId && student.completionDate === null) { offerings.push(offering); }
                                        });
                                    }
                                    
                                    assignment.offerings = offerings.sort(function (a, b) 
                                    {
                                        return new Date(a.sessions[0].startDateTime.fullDateWithTime) - new Date(b.sessions[0].startDateTime.fullDateWithTime);
                                    });
                                });
                            }
                            
                            //offerings now loaded
                            assignment.offeringsLoaded = true;
                            $scope.defer.resolve();
                        },
                        function (error)
                        {
                            assignment.offeringsLoaded = true;

                            jQuery('#getOfferingsError').show();
                            setTimeout(function ()
                            {
                                jQuery('#getOfferingsError').hide();
                            }, 3000);
                            $scope.defer.resolve();
                        }
                    );
                }
            };

            $scope.viewRosteredOfferings = function (assignment) {

                assignment.viewOfferings = true;
                $scope.defer = $q.defer();
                $scope.getRosteredOfferings(assignment);
            };

            $scope.toggleRemoveOfferingConfirmation = function (offering, assignment, closePopup) {

                //Close any existing modals
                jQuery('.removeCourseContainer .confirmSave.confirmRemove').toggle(false);

                //Open or Close current modal
                if (closePopup === true) {
                    jQuery('#removeConfirmation-' + offering.id + '-' + assignment.assignmentId).toggle(false);
                }
                if (closePopup === false)
                {
                    jQuery('#removeConfirmation-' + offering.id + '-' + assignment.assignmentId).toggle(true);
                }
            };

            $scope.removeSingleOffering = function (offering, assignment) {

                assignment.offeringsLoaded = false;
                var offeringRemovePromise = RosterService.updateSinglePersonOnRoster($scope.studentId,offering.id,'cancelled','ilt');

                offeringRemovePromise.then(
                    function (success) {
                        assignment.offerings = _.filter(assignment.offerings, function (removed) { return removed.id !== offering.id; });
                        
                        /* Remove assignment if...
                            1. no other offerings 
                            2. is elective 
                        */
                        if (assignment.offerings.length === 0 && assignment.isUserAssigned) 
                        {
                            assignment.offeringsLoaded = true;
                            $scope.removeSingleAssignment(assignment);
                        }
                        else
                        {
                            assignment.offeringsLoaded = true;
                            jQuery('#removeOfferingSuccess').show();
                            setTimeout(function ()
                            {
                                jQuery('#removeOfferingSuccess').hide();
                            }, 3000);
                        }
                    },
                    function (failure) {
                        assignment.offeringsLoaded = true;
                        jQuery('#removeOfferingError').show();
                        setTimeout(function () {
                            jQuery('#removeOfferingError').hide();
                        }, 3000);
                    }
                );
            };

            $scope.removeAssignmentOfferings = function (assignment) 
            {
                if (assignment.offerings.length > 0)
                {
                    var removeList = [];
                    _.each(assignment.offerings, function (offering)
                    {

                        var node = {};
                        node.StudentId = $scope.studentId;
                        node.OfferingId = offering.id;
                        node.RegistrationStatus = 'cancelled';
                        node.AssignmentType = 'ilt';

                        removeList.push(node);
                    });

                    var offeringsRemovePromise = RosterService.updateMultiplePersonOnRoster(removeList);
                    offeringsRemovePromise.then(
                        function (success) 
                        {
                           console.log(success);
                        }, 
                        function (failure) 
                        {
                            console.log(failure);
                        }
                    );
                }
            };
       }]
    };
}
]);