app.factory('popupcheckUtil', [function () {
    return {
        openPopUp: function () {
            var expireObj = null;
            var popup_window = null;

            if (!localStorage.popupsAllowed) {
                // local storage popup field does not exist - so check to see if popup blocker is up
                popup_window = window.open("../App/images/spacer.gif", "myWindow", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=1, height=1");
                try {
                    popup_window.focus();
                    popup_window.close();
                }
                catch (e) {
                    alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                }

                //     if the browser popup blocker is turned off - set the cookie anyway to be consistent
                expireObj = new Date((new Date()).getTime() + (12 * 60 * 60 * 1000));
                localStorage.setItem("popupsAllowed", expireObj);
            }
            else
            {
                // local storage popup field does exist so check to see if it is out of date and if not skip the checks
                var dater = Date.parse(localStorage.popupsAllowed);

                if (dater < Date.parse(new Date()))
                {
                    popup_window = window.open("../App/images/spacer.gif", "myWindow", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=1, height=1");
                    try {
                        popup_window.focus();
                        popup_window.close();

                        expireObj = new Date((new Date()).getTime() + (12 * 60 * 60 * 1000));
                        localStorage.setItem("popupsAllowed", expireObj);
                    }
                    catch (e) {
                        alert("Pop-up Blocker is enabled! Please add this site to your exception list.");

                        expireObj = new Date((new Date()).getTime() + (12 * 60 * 60 * 1000));
                        localStorage.setItem("popupsAllowed", expireObj);
                    }
                }
            }
        }
    }
    ;
}]);