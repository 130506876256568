/* This directive creates an autocomplete field that lets you select a single object
 * from a list of objects.
 * It gives you an input field and a toggleable
 * dropdown to select something from rows.
 *
 * You'll need a list of objects where each object is a dictionary
 * with ONE required parameter called 'name'
 * Example:
 * $scope.objects = [];
 * $scope.objects.push({'name': 'blah'});
 * $scope.objects.push({'name': 'blah2'});
 * $scope.objects.push({'name': 'blah3'});
 *
 * You'll also need a variable to store the selected object.
 * This can be defined as so
 *
 * $scope.selectedObj = null;
 *
 * If the user selects 'blah', $scope.selectedObj will become
 * {'name': 'blah'}
 * 
 * If the user deselects 'blah', $scope.selectedObj will become null
 *
 * You can inject this into your template like so
 * <single-auto-complete objects="objects" selected-obj="selectedObj"></single-auto-complete>
 *
 * You can add an id to it so that you can add a label
 * <label for="autocomplete">Autocomplete!</label>
 * <single-auto-complete id="autocomplete" objects="objects" selected-obj="selectedObj"></single-auto-complete>
 *
 * Here is the full example
 * $scope.objects = [];
 * $scope.objects.push({'name': 'blah'});
 * $scope.objects.push({'name': 'blah2'});
 * $scope.objects.push({'name': 'blah3'});
 * $scope.selectedObj = null;
 * // In your template put this
 * <single-auto-complete id="autocomplete" objects="objects" selected-obj="selectedObj" ></single-auto-complete>
 *
 * You can also optionally run a function when the user selects an object
 *
 * pass a parameter called opt-run-on-select which takes a function name
 * $scope.doSomething = function() {
 *  console.log($scope.selectedObj);
 * };
 * <single-auto-complete id="autocomplete" objects="objects" selected-obj="selectedObj" opt-run-on-select="doSomething"></single-auto-complete>
 *
 */
app.directive('singleAutoComplete', function() {
    return {
        restrict: 'E',
        scope: {
            objects: '=',
            selectedObj: '=',
            optRunOnSelect: '=?'
        },
        link: function(scope, element, attrs) {
            /* Global vars and utility functions */
            scope.autocompleteVisible = false;
            scope.query = '';
            scope.selectedObj = null;
            scope.filterRows = function(query) {
                return function( item ) {
                    return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
                };
            };

            /* If someone sets the selected object
             * to null outside the directive,
             * set the query to blank.
             * Otherwise forms will have 
             * stale data. */
            scope.$watch('selectedObj', function(newValue, oldValue) {
                if (newValue === null) {
                    scope.query = '';
                }
            });

            scope.chooseObj = function(obj) {
                scope.hideAutocomplete();
                scope.query = obj.name;
                scope.selectedObj = obj;
                if (scope.optRunOnSelect)
                    scope.optRunOnSelect(scope.selectedObj);
            };

            scope.showAutocomplete = function() {
                scope.autocompleteVisible = true;
            };

            scope.hideAutocomplete = function() {
                scope.autocompleteVisible = false;

                query = scope.query.trim();

                /* If they deleted everything in the search box,
                 * that means they deselected the object */
                if (query === '') {
                    scope.selectedObj = null;
                    scope.query = '';

                    if (scope.optRunOnSelect)
                        scope.optRunOnSelect(null);
                }

                /* If the user deleted part of the query but not the whole query
                 * that doesn't count as a deselection. Restore the full query 
                 * In other words, you can hide the autocomplete
                 * without selecting a query, so check that first.
                 */
                if (scope.selectedObj) {
                    var objName = scope.selectedObj.name;
                    if (query !== '' && scope.selectedObj !== null && query !== objName) {
                        scope.query = objName;
                    }
                }

            };

        },
        templateUrl: 'app/templates/components/singleAutoComplete.html'
    };
});
