/** @license Copyright 2016 BAI, all rights reserved */
/* Generate a GUID in string form from JS that is in a 
performance-friendly format for MS SQL Server */
var sqlguid = (function () {
	/*right six bytes are derived from millisecond since 1970-01-01 UTC, will wrap
	to zero in year 10889 so who cares I will be dead
	this is the primary sort portion of the uniqueidentifier data type for MS SQL Server, so GUIDs will
	be "mostly sorted" so long as clocks are reasonably accurate. Prevents index
	fragmentation in the SQL Server database*/
	var rndhex,
	webcrypto;
	/*use cryptographic random number generator if available*/
	/*check for browser crypto first
	/* use standard web Crypto API if possible */
	if ((typeof window === "object") && (typeof window.crypto === "object") && (typeof window.crypto.getRandomValues === "function")) {
		webcrypto = window.crypto;
	}
	/*look for msCrypto to handle IE11 */
	if ((typeof window === "object") && (typeof window.msCrypto === "object") && (typeof window.msCrypto.getRandomValues === "function")) {
		webcrypto = window.msCrypto;
	}
	if ((typeof webcrypto === "object") && (typeof webcrypto.getRandomValues === "function")) {
		rndhex = function (bytecount) {
			var a = new Uint8Array(bytecount);
			var o = "";
			var i = 0;
			webcrypto.getRandomValues(a);
			/* convert to hex string output */
			for (i = 0; i < bytecount; i++) {
				o += ("0" + a[i].toString(16)).slice(-2);
			}
			return o;
		};
	}
	/* check for node.js */
	else if ((typeof process === "object") && (typeof process.release === "object") && (process.release.name === "node")) {
		var nodecrypto;
		try {
			nodecrypto = require('crypto');
			rndhex = function (bytecount) {
				var a = nodecrypto.randomBytes(bytecount);
				return a.toString("hex");
			};
		} catch (err) {
			/*we'll fall back to using Math.random */
		}

	} else if (typeof rndhex !== "function") {
		/* if no crypto RNG available, fallback to Math.random, which can't be trusted to
		return more than 32 bits of entropy on each call in some browsers or runtimes.
		This isn't great for creating GUIDs, but the time-based component of the SQL Server
		optimized GUIDs will save us from collisions here */
		if((typeof console === "object") && (typeof console.warn === "function")) {
			console.warn("Cryptographic APIs unavailable, falling back to Math.random");
		}
		rndhex = function (bytecount) {
			var o = "";
			while (o.length < 2 * bytecount) {
				o = ("00000000" + Math.floor(Math.random() * 0x100000000).toString(16)).slice(-8);
			}
			return o.slice( - (2 * bytecount));
		};
	}
	/* now set up the generating function which we will export */
	var genguid = function () {
		var guid = ("000000000000" + (new Date()).getTime().toString(16)).slice(-12);
		/* build up rest of guid (10 bytes) as random to prevent collisions within miliseconds */
		guid = rndhex(2) + "-" + guid;
		guid = rndhex(2) + "-" + guid;
		guid = rndhex(2) + "-" + guid;
		guid = rndhex(4) + "-" + guid;
		return guid.toUpperCase();
	};
	return genguid;
})();

//unit test code, uncomment to run in browser console or node.js
// var sqlguid_test = function () {
	// var testcnt = 100000,
	// errcount = 0,
	// testdata = (new Array(testcnt)),
	// x = "",
	// i = 0,
	// st = (new Date()).getTime(),
	// et;
	// for (i = 0; i < testcnt; i++) {
		// x = sqlguid();
		// testdata[i] = x; 
	// }
	// et = (new Date()).getTime();
	// testdata.sort();
	// for (i = 1; i < testcnt; i++) {
		// if (testdata[i] === testdata[i - 1]) {
			// console.error("ERROR duplicate GUID encountered: " + testdata[i]);
			// errcount += 1;
		// }
		// if (testdata[i].length !== 36) {
			// console.error("ERROR GUID not 36 characters: " + testdata[i]);
			// errcount += 1;
		// }
	// }
	// if (errcount === 0) {
		// console.log("SUCCESS, generated "+testcnt+" unique GUIDs at " + Math.round((testcnt * 1000 / (et - st)), 0).toString() + " GUID/sec");
	// }
	// return errcount;
// };
// console.log("one time test : " + sqlguid());
// sqlguid_test();
