/**
 * Service "class" that contains all logic for any assignment related calls to the API.
 */
app.service('AssignmentsService',
    ['$http', 'dateUtil', 'UsersService', 'errorDisplayerUtil', 'courseFormatterUtil', function(
      $http, dateUtil,   UsersService,  errorDisplayerUtil, courseFormatterUtil)
{
    var _this = this;

    _this.response = null;

    _this.UsersService = UsersService;
    _this.courseGroupID = null;

    _this.fetch = function(userId) {
        var req = {
            method: 'GET',
            url: 'api/assignments/' + userId + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.fetchSingle = function(id, type) {

        //  ~/api/Assignments/Single/{assignmentId}/{assignmentType}
        var req = {
            method: 'GET',
            //url: 'api/user/' + user_id + '/course/' + id, // Old single assignment get
            url: 'api/assignments/single/' + id + '/' + type,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format([success.data])[0];

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    /**
     *
     * url: '/api/assignments/updateduedate/{userId}/{studentId}/{assignmentCourseId}',
     */
    _this.updateDueDate = function(managerId, studentId, courseId, dueDate) {
        var req = {
            method: 'PUT',
            url: 'api/assignments/updateduedate/' + managerId + '/' + studentId + '/' + courseId,
            data: '"' + dueDate + '"',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = success.data;
            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    /**
     *
     * @param studentId
     * @param assignmentType
     * @param courseState
     * @returns {*}
     */
    _this.getAssignmentsBystateAndType = function (studentId, assignmentType, courseState, startDate, endDate) {
        var data = {
            'StudentId': studentId,
            'Type': assignmentType,
            'CourseState': courseState,
            'StartDate': startDate,
            'EndDate': endDate
        };

        var req = {
            method: 'POST',
            url: 'api/assignments/query',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getCourseGroupCourses = function (studentId, courseType, certCurrId) {
        //pi/assignments/{userId}/coursegroup/{type}/list/courses/{courseGroupCourseId}"
        var req = {
            method: 'GET',
            url: 'api/assignments/' + studentId + '/coursegroup/' + courseType + '/list/courses/'+certCurrId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.courseGroupID = certCurrId;
            _this.response = _this.format(success.data);
            _this.courseGroupID = null;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getCareerPathsSummary = function (userId) {
        var req = {
            method: 'GET',
            url: 'api/course/careerpathssummary/' + userId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
        };

    _this.getCareerPathCourses = function (studentId, careerPathCourseId) {
        //api/assignments/{userId}/coursegroup/{type}/list/courses/{courseGroupCourseId}" 
        var req = {
            method: 'GET',
            url: 'api/assignments/' + studentId + '/careerpath/courses/' + careerPathCourseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(function (success) {
            _this.courseGroupID = careerPathCourseId;
            _this.response = _this.format(success.data);
            _this.courseGroupID = null;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    // No return value, just a 200 response code if successful
    // See /api/assignments/add in swagger for details
    _this.submitAssignment = function(studentId, assignment) {
       
        var assignmentCopy = angular.copy(assignment);

        assignmentCopy.studentId = studentId;
        assignmentCopy = [assignmentCopy];

        var req = {
            method: 'POST',
            url: 'api/assignments/add/',
            data: assignmentCopy,
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.assignCourseGroupCourses = function (studentId, certCurrId) {
        var req = {
            method: 'POST',
            url: 'api/assignments/' + studentId + '/coursegroup/' + certCurrId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.courseGroupID = certCurrId;
            _this.response = _this.format(success.data);
            _this.courseGroupID = null;
            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

        //open resource flaged to launch with content type URL at the same time 
        _this.checkForCuncurrentOpen = function (assignment) {
            var concurrentOpen = false;
            var resourceLaunchWithContent = null;
            if (assignment.resourceLaunchWithContent !== 0 && typeof assignment.courseAssets !== "undefined" && assignment.courseAssets !== null) {
                assignment.courseAssets.forEach(function (asset) {
                    if (asset.id === assignment.resourceLaunchWithContent) {
                        resourceLaunchWithContent = asset;
                    }
                });
            }
            if (resourceLaunchWithContent !== null && resourceLaunchWithContent.type.toLowerCase() === "url") {
                concurrentOpen = true;
            }
            return concurrentOpen;
        };
    /* Gets the enrollments that need manager approval
     * Returns data in this format.
     * { "studentId": 0, "firstName": null, "lastName": null, "assignmentName": "", "assignmentType": "", "offeringId": , "transcriptId": 0, "startDate": null, "endDate": null, "location": null, "requestedDate": ""}
     *
     */
    _this.approveDenyEnrollment = function (studentId, offeringId, transcriptId, assignmentType, approveDeny) {
        var req = {
            method: 'PUT',
            url: 'api/team/enroll/'+ studentId + '/course/'+ offeringId + '/' + transcriptId + '/' + assignmentType + '/' + approveDeny,
            timeout: window.timeout
        };

        return $http.put(req.url).then(function (success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    _this.removeAssignment = function (assignment) {
        var data = {
            'id': assignment.id,
            'assignmentCourseId': assignment.assignmentCourseId,
            'name': assignment.name,
            'code': assignment.code,
            'type': assignment.type,
            'status': assignment.status,
            'offeringId': assignment.offeringId,
            'studentId': assignment.studentId,
            'transcriptId': assignment.transcriptId,
            'productType': assignment.productType,
            'ruleId': assignment.ruleId,
            'assignmentId': assignment.assignmentId
        };
        var stuCourse = { studentId: assignment.studentId, AssignmentCourseId: assignment.assignmentCourseId };
        var req = {
            method: 'POST',
            url: 'api/assignments/',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {

        }, function (failure) {

        });
    };

    _this.waiveAssignment = function (assignment, waived) {
        var req = {
            method: 'PUT',
            url: 'api/assignments/' + waived,
            data: JSON.stringify(_this.unformat(assignment)),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {

        }, function (failure) {

        });
    };

    _this.updateAssignments = function (assignment) {
        var req = {
            method: 'PUT',
            url: 'api/assignments/update',
            data: _this.unformat(assignment),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            console.log("Success");
        }, function (failure) {
            console.log("Failed");
        });
    };

    _this.unformat = function (assignment) {
        // unformat the same set of dates that are formated in the format function below.
        var copy = angular.copy(assignment);

        if (copy.assignedOn !== null && typeof copy.assignedOn !== 'undefined' && copy.assignedOn.utcDate !== null && typeof copy.assignedOn.utcDate !== 'undefined')
            copy.assignedOn = copy.assignedOn.utcDate.format();
        if (copy.completedDate !== null && typeof copy.completedDate !== 'undefined' && copy.completedDate.utcDate !== null && typeof copy.completedDate.utcDate !== 'undefined')
            copy.completedDate = copy.completedDate.utcDate.format();
        if (copy.dueDate !== null && typeof copy.dueDate !== 'undefined' && copy.dueDate.utcDate !== null && typeof copy.dueDate.utcDate !== 'undefined')
            copy.dueDate = copy.dueDate.utcDate.format();
        // this is for the scenario where due date was just set and the page hasn't been refreshed yet
        if (copy.dueDate !== null && typeof copy.dueDate !== 'undefined' && copy.dueDate.jsDate !== null && typeof copy.dueDate.jsDate !== 'undefined')
            copy.dueDate = moment(copy.dueDate.jsDate).utc().format();


        if (copy.endDateTime !== null && typeof copy.endDateTime !== 'undefined' && copy.endDateTime.utcDate !== null && typeof copy.endDateTime.utcDate !== 'undefined')
            copy.endDateTime = copy.endDateTime.utcDate.format();
        if (copy.endDate !== null && typeof copy.endDate !== 'undefined' && copy.endDate.utcDate !== null && typeof copy.endDate.utcDate !== 'undefined')
            copy.endDate = copy.endDate.utcDate.format();
        if (copy.startDate !== null && typeof copy.startDate !== 'undefined' && copy.startDate.utcDate !== null && typeof copy.startDate.utcDate !== 'undefined')
            copy.startDate = copy.startDate.utcDate.format();
        if (copy.startDateTime !== null && typeof copy.startDateTime !== 'undefined' && copy.startDateTime.utcDate !== null && typeof copy.startDateTime.utcDate !== 'undefined')
            copy.startDateTime = copy.startDateTime.utcDate.format();

        if (copy.enrolledDate !== null && typeof copy.enrolledDate !== 'undefined' && copy.enrolledDate.utcDate !== null && typeof copy.enrolledDate.utcDate !== 'undefined')
            copy.enrolledDate = copy.enrolledDate.utcDate.format();

        return copy;
    };

    /**
     * Format all data that comes in
     *
     * Currently, nothing needs to be formatted immediately on response
     */
    _this.format = function (response) {
        var dates = ['assignedOn', 'completedDate', 'dueDate', 'endDateTime', 'startDate', 'startDateTime', 'enrolledDate'];

        var currAssignment = null;
        var nmlsCourseInResults = false;
        var cutOffTime = new Date();
        cutOffTime.setHours(cutOffTime.getHours() + 24);

        if(response !== null) {
            for(var assignment in response) {
                currAssignment = response[assignment];

                if (currAssignment.curriculumName !== null && currAssignment.curriculumName !== "") {
                    currAssignment.CourseGroupName = currAssignment.curriculumName;
                    currAssignment.courseGroupId = currAssignment.curriculumId;
                }
                else if (currAssignment.certificationName !== null && currAssignment.certificationName !== "") {
                    currAssignment.CourseGroupName = currAssignment.certificationName;
                    currAssignment.courseGroupId = currAssignment.certificationId;
                } else {

                }

                if (_this.courseGroupID !== null) {
                    currAssignment.isInCourseGroup = _this.courseGroupID;
                }

                if (currAssignment.allowedEnrollments > 0 && currAssignment.totalEnrollmentAttempts >= 0) {
                    currAssignment.enrollmentAttemptsRemaining = (currAssignment.allowedEnrollments - currAssignment.totalEnrollmentAttempts) < 0 ? 0 : (currAssignment.allowedEnrollments - currAssignment.totalEnrollmentAttempts);
                }
                else {
                    currAssignment.enrollmentAttemptsAllowed = -1;
                    currAssignment.enrollmentAttemptsRemaining = -1;
                }

                for(var node in currAssignment) {
                    if(currAssignment[node] !== null && $.inArray(node, dates) !== -1) {
                        currAssignment[node] = dateUtil.formatDate(currAssignment[node]);
                    } else if(node === 'offerings' || node === 'sessions' || node === 'assignedOffering') {
                        if(currAssignment[node] !== null  && node === 'assignedOffering') {
                            currAssignment[node] = [currAssignment[node]];

                            currAssignment[node] = _this.format(currAssignment[node])[0];
                        } else {
                            currAssignment[node] = _this.format(currAssignment[node]);
                        }

                        if (node === 'offerings') {
                            for (var offer in currAssignment[node]) {
                                if (currAssignment[node][offer].sessions !== undefined && currAssignment[node][offer].sessions.length > 0)
                                {
                                    currAssignment[node][offer].location = currAssignment[node][offer].sessions[0].locationName;
                                    currAssignment[node][offer].instructor = currAssignment[node][offer].sessions[0].instructor;
                                }
                            }
                        }
                    }
                }

                if (typeof currAssignment.dueDate !== 'undefined' && currAssignment.dueDate !== null) {
                    if (currAssignment.dueDate.jsDate < new Date()) {
                        currAssignment.dueDate.overDue = true;
                    } else {
                        currAssignment.dueDate.within24Hours = currAssignment.dueDate.jsDate <= cutOffTime ? true : false;
                    }
                    
                }

                if(currAssignment !== null && typeof currAssignment.code !== 'undefined') {
                    currAssignment.codeForImage = courseFormatterUtil.getCourseCodeForImage(currAssignment.code,currAssignment.tileImage);
                }

                if (currAssignment !== null && typeof currAssignment.isNMLS !== 'undefined' && currAssignment.isNMLS)
                {
                    nmlsCourseInResults = true;
                }

                currAssignment.error = errorDisplayerUtil.create();
            }
            //Set the NMLS course in results flag to true if there are any courses in the assignment that are NMLS
            response.containsNMLSCourse = nmlsCourseInResults;
        }

        return response;
    };

    _this.formatToSend = function(data) {
        var dates = ['endDate', 'startDate', 'displayStart', 'displayEnd', 'newStartDate', 'newEndDate', 'lastProcessedOn', 'lastUpdatedOn', 'acceptPassingScoreDate', 'dueDateFixed', 'enrolledDate'];

        if (data.constructor !== Array) {
            for (var node in data) {
                if (typeof data[node] !== 'undefined' && data[node] !== null && typeof data[node] === 'object' && $.inArray(node, dates) !== -1) {
                    data[node] = dateUtil.formatDate(data[node].jsDate).utcDate;
                }
            }
        }

        if (data.hasOwnProperty('course') && data.course.constructor === Object ) {
            _this.formatToSend(data.course);
        }

        if (data.constructor === Array) {
            _.each(data, function(dataPoint) {
                _this.formatToSend(dataPoint);
            });
        }

        return data;
    };

    _this.isCourseRelaunchable = function (assignment) {
        if (assignment && (assignment.status === 'inProgress' || (assignment.totalEnrollmentAttempts < assignment.allowedEnrollments || assignment.totalEnrollmentAttempts === null || assignment.allowedEnrollments === null)))
        {
            return true;
        } else {
            return false;
        }
    };

    _this.assignAssignment = function(assignments) {
        /**
         * @TODO: @sneilan You do not have to do this. data:data will pass this along correctly, as long as assignment
         * is an object. That logic should be in the controller, regardless.
         */
        var assignmentCopy = angular.copy(assignments);

        var req = {
            method: 'POST',
            url: 'api/assignments/add/',
            data: _this.formatToSend(assignmentCopy),
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getPrereqs = function (courseId) {
        var req = {
            method: 'GET',
            url: 'api/assignments/prereqs/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            var prereqData = success.data;
            var searchUrl = "";
            //If there are prereqs - create the search Url to link to and set the scoped variable for the prereq details
            prereqData.forEach(function (p) {
                if (p.code.substring(0, 5) === 'IDSGN' && p.code.split("-").length - 1 === 2) {
                    searchUrl += (searchUrl.length > 0 ? ',' : '') + p.code.substring(p.code.lastIndexOf("-") + 1);
                } else {
                    searchUrl += (searchUrl.length > 0 ? ',' : '') + p.code;
                }

            });
            prereqData.searchUrl = "search/?q=" + searchUrl;
            return prereqData;

        }, function (failure) {
            throw failure;
        });

    };

    _this.submitSimpleAssignment = function (courseId, duedate, required, userIds) {
        var data = {
            'CourseId': courseId,
            'DueDate': duedate,
            'Required': required,
            'userIds': userIds
        };
        var req = {
            method: 'POST',
            url: 'api/assignments/addSimple',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };
        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    

}]);
