app.service('InsightsDecisionMapService', [function () {
    var _this = this;
    var decisionMap = {};
    var clearRole = function() {
        decisionMap.role.key = 0;
        decisionMap.role.value = null;
    };

    var clearResponsibilities = function () {
        var emptyResponsibilty = [];
        decisionMap.responsibilities = emptyResponsibilty;
    };

    var clearExperiences = function () {
        var emptyExperiences = [];
        decisionMap.experiences = emptyExperiences;
    };

    var clearRisks = function () {
        decisionMap.risks = [];
    };

    var clearSelectedCourses = function () {
        decisionMap.courses = [];
    };

    var resetDecisionMapConfirmation = function () {

        //reset all the decision makers confirmations 
        _.each(decisionMap.decisionMakers, function (dm) {
            dm.confirmed = 'false';
        });
                
        if (decisionMap.status === 'Confirmed') {
            decisionMap.status = 'Started';
            decisionMap.confirmedDateTime = null;
        }
    };

    _this.reset = function() {
        decisionMap = {};
    };

    _this.getDecisionMap = function () { return decisionMap; };

    _this.setFunction = function (functionValue) {
        // If we change selected function and already have a role, set the flags to confirm previous selections
        if (decisionMap.role.key > 0 && decisionMap.function.key !== functionValue.key) {
            decisionMap.confirmRoleSelection = true;
            decisionMap.confirmResponsibilitiesSelection = true;
            decisionMap.confirmRisksSelection = true;
            decisionMap.confirmCourseSelection = true;
        }

        resetDecisionMapConfirmation();
        decisionMap.function = functionValue;
    };

    _this.setRole = function (roleValue) {
        // If we change the role and we already have responsibilities selected, set the flags to confirm previous selections
        if (decisionMap.responsibilities.length > 0 && decisionMap.role.key !== roleValue.key) {
            decisionMap.confirmResponsibilitiesSelection = true;
            decisionMap.confirmRisksSelection = true;
            decisionMap.confirmCourseSelection = true;
        }

        resetDecisionMapConfirmation();
        decisionMap.role = roleValue;
    };

    _this.clearFunction = function() {
        decisionMap.function.key = 0;
        decisionMap.function.value = null;
    };

    _this.setDecisionMap = function(dm) {
        decisionMap = dm;
    };

    _this.setResponsibilites = function (choices) {
        if (decisionMap.responsibilities === undefined) {
            decisionMap.responsibilities = [];
        }
        //check the length of resp before and after, if the new selection has less, set the flags to confirm previous selections
        //if the responsibilities were only added to, then risks remain the same
        if (choices.length < decisionMap.responsibilities.length) {
            decisionMap.confirmRisksSelection = true;
            decisionMap.confirmCourseSelection = true;
        }

        resetDecisionMapConfirmation();
        decisionMap.responsibilities = choices;
    };

    _this.setExperiences = function (choices) {
        if (decisionMap.experiences === undefined) {
            decisionMap.experiences = [];
        }
        resetDecisionMapConfirmation();
        decisionMap.experiences = choices;
    };

    _this.setRisks = function (choices) {
        if (decisionMap.risks === undefined) {
            decisionMap.risks = [];
        }

        if (choices.length < decisionMap.risks.length) {
            decisionMap.confirmCourseSelection = true;
        }

        resetDecisionMapConfirmation();
        decisionMap.risks = choices;
    };

    _this.setSelectedCourses = function (courses) {
        if (decisionMap.courses === null) {
            decisionMap.courses = [];
        }

        //If the length of the arrays are different, then the course list changed
        if (decisionMap.courses.length !== courses.length) {
            resetDecisionMapConfirmation();
        } else {
            //if they are the same, loop through each to determine if courses were added/deleted
            _.each(courses, function (c) {
                if (c.id > 0) {
                    var relatedIndex = _.findIndex(decisionMap.courses, { "id": c.id });
                    if (relatedIndex === -1) {
                        resetDecisionMapConfirmation();
                        return;
                    }
                }
            });
        }

        decisionMap.courses = courses;

    };

    /* Returns should show the confirm modal flag for the given step */
    _this.shouldShowModal = function (step) {
        if (step === 'role')
            return decisionMap.confirmRoleSelection;

        if (step === 'responsibilities')
            return decisionMap.confirmResponsibilitiesSelection;

        if (step === 'risks')
            return decisionMap.confirmRisksSelection;

        if (step === 'courses')
            return decisionMap.confirmCourseSelection;

    };


    /* For the given step, set the should show confirm modal flag to false */
    _this.resetModalFlag = function (step) {
        if (step === 'role')
            decisionMap.confirmRoleSelection = false;

        if (step === 'responsibilities')
            decisionMap.confirmResponsibilitiesSelection = false;

        if (step === 'risks')
            decisionMap.confirmRisksSelection = false;

        if (step === 'courses')
            decisionMap.confirmCourseSelection = false;
    };

}]);