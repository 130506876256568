/* Same as ng-blur but with a 200 ms delay.
 * For form elements like autocomplete and pickers.
 * That way if a user picks something in an autocomplete but
 * clicks off the input, the pick will be fired first and then the
 * autocomplete will hide
 */
app.directive('ngBlurDelay',['$timeout',function($timeout){
    return {
        scope:{
            ngBlurDelay:'&'
        },
        link:function(scope, element, attr){
        element.on('blur',function(){
           $timeout(scope.ngBlurDelay,200);
        });
        }
    };
}]);

