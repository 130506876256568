app.directive("viewResourcesSlideDown", ['$rootScope', '$sce', 'AssignmentsService', 'CoursesService', 'TasksService', 'UsersService', 'EvaluationService', 'SurveyService', function ($rootScope, $sce, AssignmentsService, CoursesService, TasksService, UsersService, EvaluationService, SurveyService) {
    // returns the Directive Definition Object
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'app/templates/users/partials/viewResourceSlideDown.html',
        link: function (scope, element, attrs, ctrl) {

            scope.$on('slider:view:close', function (event, data) {
                scope.closeSlider();
            });

            var lastIndex = -1;

            element = element.children('#viewResources');

            scope.openResourceInfo = function ($event, assignment) {

                scope.assignment = assignment;
                var isInACourseGroup = (typeof assignment.CourseGroupName !== 'undefined') ? assignment.CourseGroupName : assignment.isInCourseGroup;
                var resources = assignment.courseAssets;
                for (var r in resources) {
                    current = resources[r];
                    if (current.fileFormat === 'pdf') { resources[r].fileFormatName = '.PDF'; }
                    else if (current.fileFormat === 'excel') { resources[r].fileFormatName = '.XLS'; }
                    else if (current.fileFormat === 'url') { resources[r].fileFormatName = 'URL'; }
                    else if (current.fileFormat === 'word') { resources[r].fileFormatName = '.DOCX'; }
                    else if (current.fileFormat === 'powerpoint') { resources[r].fileFormatName = '.PPT'; }
                    else if (current.fileFormat === 'text') { resources[r].fileFormatName = '.TXT'; }
                    else if (current.fileFormat === 'audio') { resources[r].fileFormatName = '.MP3'; }
                    else if (current.fileFormat === 'video') { resources[r].fileFormatName = '.MP4'; }
                    else if (current.fileFormat === 'picture') { resources[r].fileFormatName = '.PNG'; }
                    else if (current.fileFormat === 'zip') { resources[r].fileFormatName = '.ZIP'; }
                }

                scope.resources = resources;
                var eventName = $event.target.innerHTML;

                jQuery('.resource').html('View Resources');

                if (jQuery(element).data('state') === 'opened') {
                    if (isInACourseGroup === undefined || isInACourseGroup === '') {
                        if (jQuery('#courseGroupArrow').length > 0) {
                            jQuery('#courseGroupArrow').hide(1, function () {
                                jQuery('#viewResources').slideUp();

                            });
                        } else {
                            jQuery(element).slideUp();
                        }

                        jQuery(element).data('state', 'closed');
                    }
                    else
                    {
                        jQuery('#courseGroupList .arrow').hide();
                        jQuery('#viewResources').slideUp();
                    }
                }

                //Close any read more course details
                var singleCourseSlideDown = jQuery('#singlecourse');
                if (jQuery(singleCourseSlideDown).data('state') === 'opened') {
                    jQuery('#offeringSlide').slideUp();
                    if (jQuery('.arrow').length > 0) {
                        jQuery('.arrow').hide(1, function () {
                            jQuery('#singlecourse').slideUp();
                        });
                    } else {
                        jQuery(singleCourseSlideDown).slideUp();
                    }

                    jQuery('.more').html('Read More');
                    jQuery(singleCourseSlideDown).data('state', 'closed');
                }

                //Look for any course group lists open and close them
                var courseGroupList = jQuery('#courseGroupList');

                if (jQuery(courseGroupList).data('state') === 'opened' && (isInACourseGroup === undefined || isInACourseGroup === '')) {
                    jQuery('#offeringSlide').slideUp();
                    jQuery('.courseGroupMore').html('Read More');

                    if (jQuery('.arrow').length > 0) {
                        jQuery('.arrow').hide(1, function () {
                            jQuery('#courseGroupList').slideUp();

                        });
                    } else {
                        jQuery(courseGroupList).slideUp();
                    }

                    jQuery(courseGroupList).data('state', 'closed');
                }

                scope.apiError = false;
                scope.singleIsLoading = false;

                var children, lastChild, mover, parent = null;
                var currentIndex, endOfRow, modIndex, modulo = 0;
                var found = false;
                var target = $event.currentTarget;

                currentIndex = 0;

                children = jQuery(target).parents('#courseList').children('.col-md-4');
                if (children.length > 0) {
                    parent = jQuery(target).parents('.col-md-4')[0];

                    modulo = jQuery(window).width() > 768 ? 3 : 1;

                    children.each(function (index) {
                        var id = jQuery(parent).prop('id');

                        jQuery(parent).prop('id', null);

                        if (jQuery(this)[0] === parent) {
                            currentIndex = index + 1;

                            found = true;
                        }

                        jQuery(parent).prop('id', id);
                        if (found === true) {
                            found = false;

                            jQuery('.col-md-4').removeClass('selected');
                            jQuery(parent).addClass('selected');

                            return;
                        }
                    });

                    if (currentIndex === lastIndex || (isInACourseGroup && eventName === 'Hide Resources')) {
                        lastIndex = -1;

                        return;
                    }

                    jQuery(parent).children('.arrow').show(1, function () {
                        jQuery(element).slideDown();
                    });

                    /* Create the dimensions of the arrow on the slide down menu */
                    var assignmentContainerWidth = jQuery(parent).width();
                    // Set the sides of the triangle 
                    var triangleSideWidth = assignmentContainerWidth / 2;
                    jQuery(parent).children('.arrow').children('.point').css('border-left',
                                                      triangleSideWidth + "px solid transparent");
                    jQuery(parent).children('.arrow').children('.point').css('border-right',
                                                      triangleSideWidth + "px solid transparent");

                    // Set the height of the triangle and move it a bit down so it doesn't
                    // overlap anything
                    var triangleHeight = 40;
                    jQuery(parent).children('.arrow').children('.point').css('border-top',
                                                      triangleHeight + "px solid #e5e5e5");

                    // .75 is based on the height of .arrowTopBorder.
                    // So if the triangle is 40px high, needs to be shifted by 30px to not overlap
                    // if triangle is 20px high, needs to be shifted by 20px to not overlap.
                    // To make this clearer, replace .75 with something based on .arrowTopBorder height
                    var triangleShift = triangleHeight * 0.75; // .75 is arbitrary right meow.
                    jQuery(parent).children('.arrow').children('.point').css('top',
                                                      triangleShift + "px");

                    // Set endOfRow as current index - 1, as
                    endOfRow = currentIndex - 1;
                    modIndex = currentIndex % modulo; // modulo is 4 3 or 1 depending on columns

                    if (modIndex > 0) {
                        endOfRow = (currentIndex - modIndex) + (modulo - 1); // Subtract one from module because we are accessing array
                    }

                    lastChild = children[endOfRow];
                    if (typeof lastChild === 'undefined') {
                        lastChild = children[children.length - 1];
                    }

                    if (isInACourseGroup !== undefined && isInACourseGroup !== '') {
                        target = jQuery($event.currentTarget);
                        parent = target.parents('.col-md-12');
                        currentIndex = parseInt(parent.prop('id').split('-')[1], 10) + 1;

                        jQuery(element).slideDown();
                        jQuery(parent).children('.arrow').slideDown();

                        mover = jQuery(parent);

                    } else {
                        mover = jQuery(lastChild);
                    }

                } else {
                    target = jQuery($event.currentTarget);
                    parent = target.parents('.col-md-12');

                    currentIndex = parseInt(parent.prop('id').split('-')[1], 10) + 1;

                    if (currentIndex === lastIndex) {
                        lastIndex = -1;

                        return;
                    }

                    jQuery(element).slideDown();

                    mover = jQuery(parent);
                }

                scope.task = null;

                if (jQuery($event.currentTarget).children().length > 0) {
                    jQuery($event.currentTarget).children('.resource').html('Hide Resources');
                } else {
                    jQuery($event.currentTarget).html('Hide Resources');
                }

                jQuery(element).data('state', 'opened');

                jQuery(mover).after(jQuery(element));

                lastIndex = currentIndex;
            };

            scope.closeSlider = function () {
                jQuery('#viewResources').slideUp(500, function () {
                    jQuery('.arrow').hide();

                    jQuery('.resource').html('View Resources');
                });

                lastIndex = -1;
            };


        }
    };
}]);
