app.controller('publicNavigationController', ['$location', '$q', '$route', '$rootScope', '$scope', '$window', 'BreadcrumbsService',
    function ($location, $q, $route, $rootScope, $scope, $window, BreadcrumbsService) {
      
        $scope.$watch(function () {
            return BreadcrumbsService.getCrumbs();
        }, function (newVal, oldVal) {
            $scope.breadcrumbs = newVal;
        }, true);

        $scope.displayChromeWarning = false;

            var versionStrCompare = function (v1, v2) {
                /* get an array of integers for each version string component */
                /* don't use Array.prototype.map for number conversion as it is not supported on older browsers */
                a1 = v1.split(".");
                a2 = v2.split(".");
                /* compare each component of the versions as an integer */
                for (i = 0; i < a1.length; i++) {

                    if (Number(a1[i]) < Number(a2[i])) {
                        return -1;
                    }
                    if (Number(a1[i]) > Number(a2[i])) {
                        return 1;
                    }
                }
                /* strings are equal to this point, but if a2 has more version components then it is greater */
                if (a1.length < a2.length) {
                    return 1;
                } else {
                    return 0;
                }
            };

        var badChromeVersion = "56.0.0.0";
        var goodChromeVersion = "57.0.2987.88"; //fixed version see https://jira.bai.org/jira/browse/LMS-319

        // Check the version of chrome for
        var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9].+) /);
        var chromeVersion = raw ? raw[2] : false;
        if (chromeVersion && chromeVersion.trim() && (versionStrCompare(chromeVersion, badChromeVersion) >= 0) && (versionStrCompare(chromeVersion, goodChromeVersion) < 0)) {
            $scope.displayChromeWarning = true;
        }
    }
]);