app.directive('baiInsightsCourseSearchModal', [function () {
        return {
        restrict: 'E',
        templateUrl: 'app/templates/insights/mappingdecisions/partials/baiInsightsCourseSearchModal.html',
        scope: true,
        controller: [
            '$scope', '$filter', '$q', '$timeout', 'errorDisplayerUtil', 'CoursesService', 'CatalogService',
            function ($scope, $filter, $q, $timeout, errorDisplayerUtil, CoursesService, CatalogService) {
                $scope.viewCoursesError = {};
                $scope.viewCoursesError.error = errorDisplayerUtil.create();
                $scope.modalBodyShow = false;
                $scope.coursesAreLoading = false;
                $scope.coursesApiAreLoading = false;
                $scope.fromFilter = false;
                $scope.courseData = [];
                var formattedCourseData = null;
                $scope.courseModal = {};
                $scope.catalogDropdownValues = [];
                $scope.courseTypeDropdownValues = [];
                $scope.selectedCatalog = null;
                $scope.selectedCourseType = null;

                var formatCoursesData = function (data) {
                    _.each(data, function (datapoint) {
                        datapoint.select = false;
                    });

                    return data;
                };

                var getSelectedCourses = function (data) {
                    var selectedCoursesArray = [];
                    
                    _.each(data, function (datapoint) {
                        if (datapoint.select === true) {
                            selectedCoursesArray.push(datapoint);
                        }
                    });

                    return selectedCoursesArray;
                };

                var filterCourses = function (keyword) {
                    return $q(function (resolve, reject) {
                        $scope.deselectAllCourses();
                        $scope.courseModal.selectAll = false;
                        var checkKeyword = false;
                        var checkCatalog = false;
                        var checkType = false;

                        // If no search criteria, return nothing
                        if ((typeof keyword === 'undefined' || keyword === null) ||
                        ($scope.selectedCatalog === null || typeof $scope.selectedCatalog === 'undefined') ||
                        ($scope.selectedCourseType === null || typeof $scope.selectedCourseType === 'undefined')) {
                            $scope.courseData.length = 0;
                            resolve(null); // Don't return anything
                        }

                        if (typeof keyword !== 'undefined' && keyword !== null) {
                            var result = formattedCourseData;
                            result = $filter('filter')(result, keyword);
                            $scope.courseData = $filter('filter')(result, { 'productType': '!Document' });
                            checkKeyword = true;
                        }
                        
                        if ($scope.selectedCatalog !== null && typeof $scope.selectedCatalog !== 'undefined') {
                            checkCatalog = true;
                            if (checkKeyword) {
                                $scope.courseData = $filter('filter')($scope.courseData, function(course) {
                                    if (course.rootNodeId === $scope.selectedCatalog.nodeId) {
                                        return course;
                                    }
                                });
                            } else {
                                var filterDocCatalog = formattedCourseData;
                                filterDocCatalog = $filter('filter')(filterDocCatalog, { 'productType': '!Document' });
                                $scope.courseData = $filter('filter')(filterDocCatalog, function (course) {
                                    if (course.rootNodeId === $scope.selectedCatalog.nodeId) {
                                        return course;
                                    }
                                });
                            }
                        }
                        if ($scope.selectedCourseType !== null && typeof $scope.selectedCourseType !== 'undefined') {
                            checkType = true;
                            if (checkKeyword || checkCatalog) {
                                $scope.courseData = $filter('filter')($scope.courseData, function(course) {
                                    if (course.type === $scope.selectedCourseType.type) {
                                        return course;
                                    }
                                });
                            } else {
                                var filterDocCourse = formattedCourseData;
                                filterDocCourse = $filter('filter')(filterDocCourse, { 'productType': '!Document' });
                                $scope.courseData = $filter('filter')(filterDocCourse, function (course) {
                                    if (course.type === $scope.selectedCourseType.type) {
                                        return course;
                                    }
                                });
                            }
                        }
                        resolve(null); // Don't return anything
                    });
                };

                // "Listen" for 'openCourseSearch' sent by the parent
                $scope.$on('openCourseSearch', function (e) {
                    $scope.modalBodyShow = false;
                    $scope.fromFilter = false;
                    $scope.selectedCatalog = null;
                    $scope.selectedCourseType = null;
                    // Cache the course list client side so we don't make the expensive api call every time. Filtering is done client side anyway
                    // If client already has course list, show the modal ready to search
                    if (formattedCourseData !== null) {
                        angular.element(document.querySelector('#addNewAssignmentModal')).modal();
                    } else { 
                        angular.element(document.querySelector('#addNewAssignmentModal')).modal();
                        $scope.getCoursesCta(null);
                    }
                });

                var assignCatalog = function (node, rootId, rootDescription) {
                    var nodeId = node.nodeId;
                    _.each(formattedCourseData, function (course) {
                        if (course.catalogId === nodeId) {
                            course.rootNodeId = rootId;
                            course.rootNodeDescription = rootDescription;
                        } else if (course.catalogId === null) {
                            course.rootNodeId = 0;
                            course.rootNodeDescription = "Custom Catalog";
                        }
                    });

                    if (node.nodes) {
                        _.each(node.nodes, function (childNode) {
                            assignCatalog(childNode, rootId, rootDescription);
                        });
                    }
                };

                $scope.getCoursesCta = function (keyword) {
                    if (keyword === "") {
                        keyword = null;
                    }
                    if (formattedCourseData === null) {
                        $scope.catalogDropdownValues.length = 0;
                        $scope.courseTypeDropdownValues.length = 0;
                        $scope.coursesApiAreLoading = true;
                        $scope.viewCoursesError.error.reset();

                        // API Call to get courses
                        // var addCoursePromise = CoursesService.getActiveCoursesForOrg();
                        var coursePromises = [];
                        coursePromises.push(CatalogService.getBaiCatalogData()); // Used for catalog info
                        coursePromises.push(CatalogService.getCustomCatalog()); // Used for course types and custom courses
                        coursePromises.push(CoursesService.getActiveCoursesForOrg(false)); // Used as course list, filtered using 2 above

                        $q.all(coursePromises).then(function (success) {
                            var catalogTree = success[0];
                            var customCourses = success[1];
                            
                            formattedCourseData = formatCoursesData(success[2]);
                            _.each(catalogTree.formattedCatalogTreeData, function(catalog) {
                                assignCatalog(catalog, catalog.nodeId, catalog.name);
                            });

                            // All the custom courses that were classified as catalog custom courses, flip to regular custom courses so there is only 1 option for custom courses
                            _.each(formattedCourseData, function (c) {
                                _.each(customCourses, function (ct) {
                                    var index = -1;
                                    index = _.findIndex(ct.courses, { "id": c.id });
                                    if (index > -1) {
                                        if (c.rootNodeId > 0) {
                                            c.rootNodeId = 0;
                                            c.rootNodeDescription = "Custom Catalog";
                                        }
                                    }
                                });
                                // Assign friendly course type
                                c.friendlyTypeName = CoursesService.getFullAssignmentAssetTypeName(c.type);
                            });

                            // Create unique lists of catalogs and course types for dropdowns
                            _.each(formattedCourseData, function (c) {
                                if (_.findIndex($scope.catalogDropdownValues, { "nodeId": c.rootNodeId }) === -1) {
                                    if (c.productType !== "Document") {
                                        $scope.catalogDropdownValues.push({ "nodeId": c.rootNodeId, "name": c.rootNodeDescription });
                                    }
                                }
                                if (_.findIndex($scope.courseTypeDropdownValues, { "friendlyName": c.friendlyTypeName }) === -1) {
                                    if (c.productType !== "Document") {
                                        $scope.courseTypeDropdownValues.push({ "type": c.type, "friendlyName": c.friendlyTypeName });
                                    }
                                }
                            });
                            $scope.catalogDropdownValues = _.sortBy($scope.catalogDropdownValues, "name");
                            $scope.courseTypeDropdownValues = _.sortBy($scope.courseTypeDropdownValues, "type");
                            $scope.courseTypeDropdownValues = $scope.courseTypeDropdownValues.reverse();

                            $scope.sortBy('name');
                            $scope.coursesApiAreLoading = false;
                        }, function (failure) {
                            $scope.coursesApiAreLoading = false;
                            var errorCode = failure.StatusCode;
                            var message = failure.ErrorMessage;
                            $scope.viewCoursesError.error.setError(errorCode, message);
                        });
                    } else {
                        $scope.coursesAreLoading = true;
                        $scope.modalBodyShow = true;
                        $scope.fromFilter = true;
                        // Run this in a $timeout to give the UI a chance to show the loading bars before filtering.
                        // Filtering can take a few ticks client side and this makes it look like it's thinking, not frozen
                        $timeout(function () {
                            filterCourses(keyword).then(function(success) {
                                $scope.coursesAreLoading = false;
                            });
                        }, 500);
                    }
                };


                $scope.addAllCourses = function (value) {
                    _.each($scope.courseData, function (course) {
                        course.select = value;
                    });
                };

                $scope.deselectAllCourses = function() {
                    _.each(formattedCourseData, function(c) {
                        c.select = false;
                    });
                };

                $scope.addAdditionalCourses = function (courseData) {
                    $scope.coursesAreLoading = true;
                    // Another UI trick using $timeout to show loading bars while the client does work
                    $timeout(function() {
                        var selectedCourses = getSelectedCourses(courseData);
                        $scope.selectCourseInList(selectedCourses);
                        // Reset search modal
                        $scope.courseModal.courseSearch = '';
                        $scope.courseModal.selectAll = false;
                        $scope.fromFilter = false;
                        $scope.courseData.length = 0;
                        $scope.deselectAllCourses();
                        angular.element(document.querySelector('#addNewAssignmentModal')).modal('hide');
                        $scope.coursesAreLoading = false;
                    }, 500);
                };

                $scope.closeAdditionalCourseSearchModal = function () {
                    $scope.coursesAreLoading = true;
                    $timeout(function () {
                        // Reset search modal
                        $scope.courseModal.courseSearch = '';
                        $scope.courseModal.selectAll = false;
                        $scope.fromFilter = false;
                        $scope.deselectAllCourses();
                        $scope.courseData.length = 0;
                        angular.element(document.querySelector('#addNewAssignmentModal')).modal('hide');
                        $scope.coursesAreLoading = false;
                    }, 500);
                };

                $scope.sortBy = function (propertyName) {
                    $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                    $scope.propertyName = propertyName;
                };
            }
        ]};
}]);