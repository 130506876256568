app.directive('baiInsightsDecisionMapCreate', [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/templates/insights/mappingdecisions/newDecisionMap.html',
        scope: { decisionMapId: '@' },
        controller: ['$scope', '$q', '$location', 'InsightsService', 'InsightsDecisionMapService', 'errorDisplayerUtil', 'OrganizationsService', 'UsersService', 'BreadcrumbsService','GroupsService',
            function ($scope, $q, $location, InsightsService, InsightsDecisionMapService, errorDisplayerUtil, OrganizationsService, UsersService, BreadcrumbsService, GroupsService) {

                var _this = this;
                $scope.canSelectOrg = true;
                $scope.canSelectPG = true;
                $scope.showPreview = false;
                $scope.showCommentChk = false;
                $scope.copyWithComments = true;
                $scope.previewPeopleError = {};
                $scope.previewPeopleError.error = errorDisplayerUtil.create();
                $scope.previewPeopleIsLoading = false;
                // These 2 are used for sorting the preview
                $scope.reverse = false;
                $scope.propertyName = "lastName";
                // Create vs. copy mode
                $scope.modeTitle = "Create Decision Report";
                $scope.modeButtonTxt = "Create";
                $scope.orgCutoff = 100; // cutoff point for dropdown vs uib-typeahead
                $scope.loggedInUserId = UsersService.getId();

                var loadCreateDecisionMap = function () {
                    $scope.savingDecisionMap = false;
                    $scope.userNotSelected = true;
                    var currentUserRole = UsersService.getCurrentRoleType();

                    if (currentUserRole === 'decisionMaker') {
                        $scope.decisionMakerRole = true;
                    }
                    var dm = InsightsDecisionMapService.getDecisionMap();
                    $scope.contentTemplate = 'app/templates/insights/mappingdecisions/create.html';
                    $scope.stepTips = "Decision report names must be unique. It is recommended you standardize the naming convention. Consider naming them the same as the corresponding draft rule name, should you choose to create a draft rule. Many people include the department, role, and year in the name. Each report can only have one function and one role.<br /><br />Peer group is determined based on your organization's type and estimated asset size. (See Help for specific peer group definitions). Peer group selection helps determine what comparative data is displayed in this decision report's course selection list.<br /><br />Optionally, an organization or people group can be specified but not both. If one is selected, it will be used as criteria in the draft rule.";
                    // If we already have a decision map, like coming from edit or copy
                    if (!_.isEmpty(dm)) {
                        // Coming from edit, go to start
                        if (dm.id && dm.id > 0 && dm.status === 'Created') {
                            $scope.decisionMap = dm;
                            $scope.selectedPeerGroup = $scope.decisionMap.peerGroup;

                            //Set the Decision Maker names
                            _.each($scope.decisionMap.decisionMakers, function (m) {
                                m.name = m.fullName + " (" + m.userName + ")";
                            });

                            $scope.loadStartTemplate();
                            return;
                        } else {
                            if (dm.sourceDecisionMapId !== null) { // copy
                                // Setup copy mode
                                $scope.modeTitle = "Copy Decision Report";
                                $scope.modeButtonTxt = "Copy";
                                $scope.showCommentChk = true;
                                $scope.decisionMap = dm;
                                $scope.selectedPeerGroup = $scope.decisionMap.peerGroup;
                                $scope.decisionMakerName = dm.userName;
                                InsightsService.getPeerGroups().then(function (success) {
                                    $scope.peerGroups = success;
                                }, function (failure) {
                                    $scope.peerGroups = [];
                                    $scope.peerGroups.push($scope.selectedPeerGroup);
                                });

                                //Set the Decision Makers
                                _.each($scope.decisionMap.decisionMakers, function (m) {
                                    m.name = m.fullName + " (" + m.userName + ")";
                                    //if we are in here, that means there is at least one decision maker
                                    $scope.userNotSelected = false;
                                });

                                /* make a call to get the organization hierarchy and people groups */
                                /* right now, only get it for TA's */
                                if (!$scope.decisionMakerRole) {
 
                                    // Get org data and pre-pop selected org
                                    _this.getOrganizationHierarchyData().then(function (success) {
                                            var copiedOrg = _.where($scope.orgArray, { id: $scope.decisionMap.businessOrganizationId });
                                            if (copiedOrg.length > 0) {
                                                $scope.selectedOrganization = copiedOrg[0];
                                                $scope.selectOrganization(copiedOrg[0]);
                                            }
                                        }, function(failure) {
                                            // Leave blank. If it fails once, they can continue without it. If it fails more than once, probably a bigger system problem going on
                                        });
                                    // Get people group data and pre-pop selected people group
                                    _this.getPeopleGroupData().then(function(success) {
                                        if ($scope.decisionMap.peopleGroupId !== null && $scope.decisionMap.peopleGroupId !== undefined) {
                                            $scope.selectedPeopleGroup = { id: $scope.decisionMap.peopleGroupId, name: $scope.decisionMap.peopleGroupName };
                                            $scope.selectPeopleGroup($scope.selectedPeopleGroup);
                                        }
                                    }, function (failure) {
                                        // If call fails and decision map has a people group, still pre-pop it
                                        if ($scope.decisionMap.peopleGroupId !== null && $scope.decisionMap.peopleGroupId !== undefined) {
                                            var pgGroup = { id: $scope.decisionMap.peopleGroupId, name: $scope.decisionMap.peopleGroupName };
                                            $scope.pgArray.push(pgGroup);
                                            $scope.selectedPeopleGroup = pgGroup;
                                            $scope.selectPeopleGroup(pgGroup);
                                        }
                                    });
                                } else {

                                    /* For Decision Maker - People Group should only contain the copied People Group with the option to deselect it */
                                    if ($scope.decisionMap.peopleGroupId !== null) {
                                        $scope.showPeopleGroupSelector = true;
                                        $scope.pgArray = [];
                                        var pgGroup = { id: $scope.decisionMap.peopleGroupId, name: $scope.decisionMap.peopleGroupName };
                                        $scope.pgArray.push(pgGroup);
                                        $scope.selectedPeopleGroup = pgGroup;
                                        $scope.selectPeopleGroup(pgGroup);
                                    }

                                    /* For Decision Maker - Organization should only contain the copied Organization with the option to deselect it */
                                    if ($scope.decisionMap.businessOrganizationName !== null && $scope.decisionMap.businessOrganizationName !== '') {
                                        $scope.showOrganizationSelector = true;
                                        $scope.orgArray = [];
                                        $scope.selectedOrg = { id: $scope.decisionMap.businessOrganizationId, name: $scope.decisionMap.businessOrganizationName, pathToOrg: $scope.decisionMap.businessOrganizationName, enabled: true };
                                        $scope.orgArray.push($scope.selectedOrg);
                                        $scope.selectedOrganization = $scope.selectedOrg;
                                    }
                                }
                                return;
                            }
                        } // end setup copy
                    } // end decision map is not empty

                    // If we get here it means the client decision map is empty, which means we need a new one
                    // or it somehow got an id > 0 and status != Created or no id and no source id. Either way these aren't valid states so throw it out and create a new one

                    //Get the values to pre-populate the decisionMap with org properties
                    var shellGet = InsightsService.getDecisionMapShell();
                    shellGet.then(function (success) {
                        $scope.decisionMap = success;
                        $scope.rowsAreLoading = false;
                        $scope.selectedPeerGroup = $scope.decisionMap.peerGroup;
                        InsightsService.getPeerGroups().then(function (success) {
                            $scope.peerGroups = success;
                        }, function (failure) {
                            $scope.peerGroups = [];
                            $scope.peerGroups.push($scope.selectedPeerGroup);
                        });
                        /* make a call to get the organization hierarchy */
                        /* right now, only get it for TA's */
                        if (!$scope.decisionMakerRole) {
                            _this.getOrganizationHierarchyData();
                            _this.getPeopleGroupData();
                        } else {
                            /* Assign the decision maker to the map */
                            var user = {};
                            user.id = UsersService.getId();
                            user.name = UsersService.getName();
                            $scope.setDecisionMapUser(user);
                        }
                    }, function (failure) {
                        $scope.rowsAreLoading = false;
                        $scope.errorMessage = failure.ErrorMessage;
                        $scope.errorThrown = true;
                    });
                };

                _this.getOrganizationHierarchyData = function () {
                    $scope.disableHierarchyCheckbox = true;
                    var orgDataPromise = OrganizationsService.getOrganizationHierarchy();
                    return orgDataPromise.then(function (success) {
                        $scope.orgArray = [];
                        for (var i = 0; i < success.length; i++) {
                            var org = success[i];
                            var nameAndOrgObjOnly = {};
                            nameAndOrgObjOnly.pathToOrg = org.pathToOrg;
                            nameAndOrgObjOnly.name = org.name;
                            nameAndOrgObjOnly.id = org.id;
                            nameAndOrgObjOnly.enabled = org.enabled;
                            $scope.orgArray.push(nameAndOrgObjOnly);
                        }
                    });
                };


                /* Org selector */
                $scope.selectOrganization = function (selectedOrg) {
                    $scope.decisionMap.businessOrganizationId = selectedOrg === null ? null : selectedOrg.id;
                    $scope.decisionMap.businessOrganizationName = selectedOrg === null ? null : selectedOrg.name;
                    $scope.disableHierarchyCheckbox = selectedOrg === null ? true : false;
                    if (selectedOrg === null) {
                        $scope.decisionMap.includeOrganizationHierarchy = false;
                        $scope.canSelectPG = true;
                        $scope.canSelectOrg = true;
                    }else
                    {
                        $scope.canSelectPG = false;
                    }
                };

                /*
                * Decision Maker user search typeahead
                */
                $scope.noResults = '';
                $scope.userTypeahead = {};
                $scope.userTypeahead.search = function (value) {

                    if ($scope.decisionMap.decisionMakers === null || $scope.decisionMap.decisionMakers.length === 0) {
                        $scope.userNotSelected = true;
                    }

                    if (value === null) {
                        /* validation - if the value is null, then just return after setting validation variable */
                        return;
                    }

                    var fieldName = 'fullNameContains';
                    searchAttribute = {};
                    searchAttribute[fieldName] = value;
                    searchAttribute.pageNumber = 1;
                    searchAttribute.pageSize = 25;
                    searchUserPromise = UsersService.advancedSearch(searchAttribute);

                    return searchUserPromise.then(function (success) {
                        $scope.resultsLoading = false;
                        var output = [];
                        var filteredFromUsers = _.uniq(_.compact(success));
                        _.each(filteredFromUsers, function (item) {
                            var skinnedItem = {};
                            skinnedItem.id = item.id;
                            skinnedItem.name = item.fullName + ' (' + item.userName + ')';
                            output.push(skinnedItem);
                        });
                        return output;
                    });
                };

                $scope.setDecisionMapUser = function (selectedUser) {

                    //create the dmaker array if it does not exist
                    if ($scope.decisionMap.decisionMakers === null) {
                        $scope.decisionMap.decisionMakers = [];
                    }

                    //Add the decision maker to the array if it is not already there
                    var dmIndex = _.findIndex($scope.decisionMap.decisionMakers, { "id": selectedUser.id });
                    if (dmIndex === -1) {
                        $scope.decisionMap.decisionMakers.push(selectedUser);
                    }

                    $scope.userNotSelected = false;
                    $scope.resultsLoading = false;
                };

                $scope.removeDecisionMaker = function (userID) {

                    // A Decision Maker cannot remove themselves from the Decision Report
                    if(!$scope.decisionMakerRole || userID !== $scope.loggedInUserId){
                        var dmIndex = _.findIndex($scope.decisionMap.decisionMakers, { "id": userID });
                        if (dmIndex > -1) {
                            $scope.decisionMap.decisionMakers.splice(dmIndex, 1);
                        }
                    }

                    if ($scope.decisionMap.decisionMakers.length === 0) {
                        $scope.userNotSelected = true;
                    }
                };

                /*
                * END Decision Maker user search typeahead
                */

                /*
                * START People Group typeahead search
                */

                _this.getPeopleGroupData = function () {
                    $scope.pgArray = [];
                    var pgDataPromise = GroupsService.getPeopleGroups(true);
                    return pgDataPromise.then(function (success) {     
                        for (var i = 0; i < success.groups.length; i++) {
                            var pg = success.groups[i];
                            var pgNameandID = {};
                            pgNameandID.name = pg.groupName;
                            pgNameandID.id = pg.id;
                            $scope.pgArray.push(pgNameandID);
                        }
                    });
                };
                
                /* People Group Selector */
                $scope.selectPeopleGroup = function (selectedPG) {
                    $scope.decisionMap.peopleGroupId = selectedPG === null ? null : selectedPG.id;
                    $scope.decisionMap.peopleGroupName = selectedPG === null ? null : selectedPG.name;
                    if (selectedPG === null) {
                        $scope.canSelectPG = true;
                        $scope.canSelectOrg = true;
                    } else {
                        $scope.canSelectOrg = false;
                    }
                };

                /*
                * END People Group typeahead search
                */


                /* set the decisionMap peer group to what was selected in the peer group ddl */
                $scope.ChangeSelectedPeerGroup = function (selectedVal) {
                    $scope.decisionMap.peerGroup = selectedVal;
                };

                /* set the copy with comments value for copying decision reports */
                $scope.toggleCopyWithComments = function () {
                    $scope.copyWithComments = !$scope.copyWithComments;
                };

                /* Call to the API to create a new decision map */
                $scope.createDecisionMap = function () {
                    $scope.decisionMap.status = "Created";
                    $scope.savingDecisionMap = true;
                    var createDecisionMap = InsightsService.createDecisionMap($scope.decisionMap, $scope.copyWithComments);
                    createDecisionMap.then(function (success) {
                        $scope.decisionMapConfirmScreen = true;
                        $scope.savingDecisionMap = false;
                        //Save the returned decision map ID to the decisionMap
                        $scope.decisionMap.id = success;
                        $scope.errorThrown = false;
                        $scope.loadStartTemplate();
                    }, function (failure) {
                        $scope.savingDecisionMap = false;
                        $scope.errorMessage = failure.ErrorMessage;
                        $scope.errorThrown = true;
                    });

                };

                /* Set the decision map status to 'started' and redirect to the first page in the wizard */
                $scope.setDecisionMapStartStatus = function () {
                    $scope.savingDecisionMap = true;
                    $scope.decisionMap.status = "Started";
                    InsightsService.updateDecisionMap($scope.decisionMap).then(function (success) {
                        InsightsDecisionMapService.setDecisionMap($scope.decisionMap);
                        $location.path('insights/wizard/function');
                    }, function (failure) {
                        $scope.savingDecisionMap = false;
                        $scope.errorMessage = failure.ErrorMessage;
                        $scope.errorThrown = true;
                    });
                };

                $scope.loadStartTemplate = function () {
                    $scope.stepTips = 'Both the Decision Maker and Training Administrator now have access to this decision report. It is recommended you coordinate how best to fill out the information prior to starting.';
                    if ($scope.decisionMakerRole === true) {
                        BreadcrumbsService.reset();
                        BreadcrumbsService.pushCrumb("Training Insights", "insights/home");
                        BreadcrumbsService.pushCrumb("Start Decision Report", "");
                    }
                    $scope.contentTemplate = 'app/templates/insights/mappingdecisions/start.html';
                };

                $scope.previewMembers = function() {
                    $scope.previewPeopleIsLoading = true;
                    $scope.previewPeopleError.error.reset();
                    GroupsService.getUsersByGroupId($scope.decisionMap.peopleGroupId).then(function (success) {
                        $scope.people_rows = success;
                        $scope.showPreview = true;
                        $scope.previewPeopleIsLoading = false;
                    }, function(failure) {
                        $scope.previewPeopleIsLoading = false;
                        $scope.showPreview = false;
                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        $scope.previewPeopleError.error.setError(errorCode, message);
                    });
                };

                $scope.sortBy = function (propertyName) {
                    $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                    $scope.propertyName = propertyName;
                };

                loadCreateDecisionMap();
            }]
    };
}
]);