/* This directive creates a collapsable, searchable tree with onclick events
 * on every node
* Create tree directive like so
* <tree on-click="nodeClick" node-becomes-hidden="nodeNowHidden" data="data" search="search" options="options" title="title"></tree>
*
* where nodeClick is a function like
* where item is the currently clicked node
* item.nodes is filtered if you have already filtered the tree
* $scope.nodeClick = function(item) {
*   // do something with item.name, item._id
* }
* data is like $scope.data below
* search is an input box tied to ng-model
* So create an input field like this near your tree
 <input type="text" ng-model="search" />
 And write $scope.search = ""; in your controller
 Then pass search to the tree

 node-now-hidden takes a function which gets called if the currently selected node becomes hidden after the user starts filtering stuff
 something like 
 node-now-hidden="nodeNowHidden" where
 $scope.nodeNowHidden = function() {
    $scope.rows = [];
 }
 In this case scope.rows on the people page becomes null if the currently selected node becomes hidden

 search-update-callback is an OPTIONAL function that takes the currently highlighted node or the root org if there's no highlighted row and does whatever you need to do
 when the search updates
 $scope.options.searchUpdateCallback = function(node) {
    // if something isn't active, do something
 }

 title is just a string specified something like
 $scope.title = "Managers"

 Options are specified like so
 $scope.options = {};
 $scope.options.initiallyOpened = true;
 $scope.options.rootNodeIsTopLevel = false;
 $scope.options.initiallySelectedNodeName = "";
 $scope.options.searchUpdateCallback = function(node) {
    // do something when the user updates their search variable
 }
 $scope.options.showLocks = false;

 initiallyOpened will make the tree opened by default. The tree is still openable and closable

 rootNodeIsTopLevel makes the highest level node in the tree also the large title row. Otherwise the large title row won't be a clickable node.

 showLocks will show a lock on the left of every row if its set to true
*
* initiallySelectedNodeName finds a node with a certain name and then makes that node selected when the tree loads.
* Searches through nodes via name & then selects a node via name
*
* That will create a searchable stylable highlightable tree.
*/

/*
 * Example data for this tree object
  $scope.data = [{
    "name": "Node-1",
    "color": "blue",
    "nodes": [
      {
        "name": "A",
        "color": "red",
        "nodes": [
          {
            "name": "B",
            "nodes": [ ]
          }
        ]
      },
      {
        "name": "C",
        "nodes": [
          {
            "name": "D",
            "nodes": [ ]
          },
          {
            "name": "E",
            "nodes": [ ]
          },
          {
            "name": "F",
            "nodes": [ ]
          }
        ]
      }
    ]
  },
  {
    "name": "Node-2",
    "nodes": [
      {
        "name": "G",
        "nodes": [
          {
            "name": "H",
            "nodes": [ ]
          },
          {
            "name": "I",
            "nodes": [ ]
          }
        ]
      },
      {
        "name": "J",
        "nodes": [
          {
            "name": "K",
            "nodes": [ ]
          },
          {
            "name": "L",
            "nodes": [ ]
          },
          {
            "name": "M",
            "nodes": [ ]
          }
        ]
      },
      {
        "name": "N",
        "nodes": [
          {
            "name": "O",
            "nodes": [ ]
          },
          {
            "name": "P",
            "nodes": [
              {
                "name": "Q",
                "nodes": [ ]
              },
              {
                "name": "R",
                "nodes": [ ]
              }
            ]
          },
          {
            "name": "S",
            "nodes": [ ]
          }
        ]
      },
      {
        "name": "T",
        "nodes": [
          {
            "name": "U",
            "nodes": [
              {
                "name": "V",
                "nodes": [ ]
              }
            ]
          }
        ]
      },
      {
        "name": "W",
        "nodes": [
          {
            "name": "X",
            "nodes": [ ]
          }
        ]
      }
    ]
  },
  {
    "name": "Node-3",
    "nodes": [ ]
  }
];
*/

app.directive("tree", ['$location', function ($location) {
    return {
        restrict: "E",
        scope: {
            inputData: '=',
            onClick: '=',
            nodeNowHidden: '=?',
            search: '=',
            title: '=',
            options: '=',
            treeError: '='
        },
        link: function(scope, element, attrs) {
            var _this = this;

            // GLOBAL VARIABLES
            // Stores the currently highlighted node.
            // starts at null by default.
            // Stores an ID not a node reference
            // The template html checks every
            // node to see if it has the ID
            // stored in this variable.
            // If so, it highlights the node
            scope.currentlyHighlightedNode = null;

            /* list, object, property.. */
            function setPropertyOnAllNodes(data, name, value) {
                for (var i = 0; i < data.length; i += 1) {
                    data[i][name] = value;
                    // if there are subnodes, 
                    // set them to hidden also
                    if (data[i].nodes) {
                        setPropertyOnAllNodes(data[i].nodes, name, value);
                    }
                }
            }


            // This is used for retaining state after filtering
            function setPropertyOnSpecificId(data, _id, name, value) {
                for (var i = 0; i < data.length; i += 1) {
                    if (data[i]._id === _id) {
                        data[i][name] = value;
                        break;
                    }
                    // if there are subnodes, 
                    // set them to hidden also
                    if (data[i].nodes) {
                        setPropertyOnSpecificId(data[i].nodes, _id, name, value);
                    }
                }
            }

            // Assign ID's to all the objects
            // Used for keeping track of highlighting etc etc
            var tempId = 0;
            function addIds(data) {
                for (var i = 0; i < data.length; i += 1) {
                    data[i]._id = tempId;
                    tempId += 1;
                    // if there are subnodes, 
                    // set them to hidden also
                    if (data[i].nodes) {
                        addIds(data[i].nodes);
                    }
                }
            }

            // Returns a node by its id if it exists in the tree
            function getNodeIfItExistsInTreeByInternalId(nodeId, data) {
                // if the node we're looking for exists in the top level list of nodes, return it
                for (var i = 0; i < data.length; i += 1) {
                    if (data[i]._id === nodeId) {
                        return data[i];
                    }
                }

                // otherwise recursively look for the node
                var potentialNode = false;
                for (var j = 0; j < data.length; j += 1) {
                    if (data[j].nodes) {
                        for (var k = 0; k < data[j].nodes.length; k += 1) {
                            potentialNode = getNodeIfItExistsInTreeByInternalId(nodeId, data[j].nodes);
                        }
                    }
                }
                return potentialNode;
            }

            // Exactly the same as getNodeIfItExistsInTreeByInternalId except uses a search predicate
            function getNodeIfItExistsInTreeByPredicate(predicate, data) {
                // if the node we're looking for exists in the top level list of nodes, return it
                for (var i = 0; i < data.length; i += 1) {
                    if (predicate(data[i])) {
                        return data[i];
                    }
                }

                // otherwise recursively look for the node
                var potentialNode = false;
                for (var j = 0; j < data.length; j += 1) {
                    if (data[j].nodes) {
                        for (var k = 0; k < data[j].nodes.length; k += 1) {
                            potentialNode = getNodeIfItExistsInTreeByPredicate(predicate, data[j].nodes);
                        }
                    }
                }
                return potentialNode;
            }

            // make every top level node (you can have multiple)
            // have a group Id. Make every node above those top level nodes have the same group ID
            // The group ID of the top level nodes will just be the id
            // the group ID of the nodes below it will be _group_id
            // This function recursively adds groupIds
            // If groupId isn't passed, call itself with the groupId of each top level element
            // the recursive calls will pass in group id
            function addGroupIds(data, groupId) {
                if (groupId === undefined) {
                    for (var i = 0; i < data.length; i += 1) {
                        addGroupIds(data[i], data[i]._id);
                    }
                } else {
                    data._group_id = groupId;

                    if(typeof data.nodes !== 'undefined') {
                        for (var nodeIndex = 0; nodeIndex < data.nodes.length; nodeIndex += 1) {
                            addGroupIds(data.nodes[nodeIndex], groupId);
                        }
                    }
                }
            }
            // This stores an ID not a node reference
            // @TODO @sneilan Most likely should be initialized to null.
            scope.selected_group = "";

            scope.toggleTreeVisibility = function() {
                scope.treeClosed = !scope.treeClosed;
            };

            // Called by tree html.
            // This function does any background related tasks
            // when we click on a node.
            scope.backgroundTasks = function(item) {
                // Highlight the currently clicked node
                // For now it just highlights the node we clicked on
                scope.currentlyHighlightedNode = item._id;

                // set the top level group to be highlighted
                scope.selected_group = item._group_id;
            };

            scope.toggle = function(item) {
                item.hidden = !item.hidden;
            };

            // For every leaf of the tree, check if it matches the
            // function predicate
            // If it doesn't match, don't delete the node from the tree
            // otherwise, delete the node from the tree
            _this.predicate = function(object, search) {
                var lowerCaseName = object.name.toLowerCase();
                var lowerCaseSearch = search.toLowerCase();

                return lowerCaseName.indexOf(lowerCaseSearch) !== -1;
            };

            // Prunes a tree via predicate
            function filter(data, search) {
                var newTree = Array();
                for (var i = 0; i < data.length; i += 1) {
                    // If this particular node matches,
                    // just toss the whole thing onto the stack
                    if (_this.predicate(data[i], search)) {
                        newTree.push(data[i]);
                    } else {
                        // If any of the children match
                        // toss the whole thing onto the stack
                        var newVal = filter(data[i].nodes, search);
                        if (newVal.length > 0) {
                            data[i].nodes = newVal;
                            newTree.push(data[i]);
                        }
                        // if none of the children match
                        // and this node doesn't match
                        // don't return anything
                    }
                }
                return newTree;
            }

            /**
             * @TODO: @sneilan: what is this method for? It looks like it should be running whenever search is update,
             * but it is not.
             */
            var runSearch = function() {
                // when the tree is first created, it may not have data loaded
                // so check if we've loaded data, then do the internal stuff
                if (!scope.originalData) {
                    return;
                }

                scope.data = filter(angular.copy(scope.originalData), scope.search);
                /*
                if (scope.currentlyHighlightedNode !== null) {
                    setPropertyOnSpecificId(scope.data, scope.currentlyHighlightedNode, 'highlight', true);
                }
                */

                // also need to call the onclick function to recalculate anything on the right hand side
                // lets say you click on a node, the filtering function runs and filters the list of users on the right hand side by organization
                // if an organization was filtered away, all users in that organization shouldn't show up
                var node = getNodeIfItExistsInTreeByInternalId(scope.currentlyHighlightedNode, scope.data);
                if (node) {
                    if (scope.options && scope.options.searchUpdateCallback) {
                        scope.options.searchUpdateCallback(node);
                    }
                    // scope.onClick(node);
                } else {
                    // if there's no highlighted node, that means the user hasn't started clicking around yet
                    // so don't highlight the root node
                    // but load the data for it
                    // creates a more seamless experience
                    // only applies if the root node is the top level node.
                    if (scope.currentlyHighlightedNode === null && scope.options.rootNodeIsTopLevel) {
                        if (scope.options && scope.options.searchUpdateCallback)
                            scope.options.searchUpdateCallback(scope.data[0]);
                        // scope.onClick(scope.data[0]);
                    } else {
                        if (scope.nodeNowHidden) 
                        scope.nodeNowHidden();
                    }
                }
            };

            // Commeneted By Mani -- Don't know what it does but throwing erros on UI., Need to look into it.Tahnks
            scope.$watch('search', runSearch);

            // set a watch on the data so when it comes in from the API
            // we re-run everything
            
            scope.$watch('inputData', function(newValue, oldValue) {
                // when the tree is first created, it may not have data loaded
                // so check if we've loaded data, then do the internal stuff

                // inputData -> data -> originalData
                if (scope.inputData) {
                    // every node should have a unique ID for internal purposes
                    scope.data = angular.copy(scope.inputData);

                    addIds(scope.data);

                    // Make all nodes hidden by default
                    setPropertyOnAllNodes(scope.data, 'hidden', true);

                    // make every node below a top level root node have a group ID
                    addGroupIds(scope.data);

                    // make a copy of the original data so that when we filter
                    // it doesn't permanently destroy data
                    scope.originalData = angular.copy(scope.data);

                    if (scope.options.initiallySelectedNodeName) {
                        node = getNodeIfItExistsInTreeByPredicate(function(node) {
                            if (scope.options.initiallySelectedNodeName === node.name) {
                                return true;
                            }
                            return false;
                        }, scope.data);
                        if (node)  {
                            scope.currentlyHighlightedNode = node._id;
                        }

                        // Unset this variable so this loop doesn't run again
                        scope.options.initiallySelectedNodeName = false;
                    }
                }
            });

            // read through options and set default values
            if (scope.options) {
                if (scope.options.initiallyOpened) {
                    scope.treeClosed = false;
                } else {
                    scope.treeClosed = true;
                }

                if (scope.options.showLocks === undefined) {
                    scope.options.showLocks = true;
                }

                if (scope.options.canDrillDown === undefined) {
                    scope.options.canDrillDown = true;
                }

            } else {
                // By default we show locks right now.
                scope.options = {};
                scope.options.showLocks = true;
            }

            scope.iconClick = function(item) {

                var peopleGroupId = item.id;
                $location.path('users/peoplegroup/' + peopleGroupId + '/');
            };

            scope.iconClickCourseEdit = function(item) {
                if (item.api_name === 'courseGroups' && typeof item.id !== 'undefined' & typeof item.id !== null) {
                    $location.path('courses/edit/' + item.id + '/');
                }
            };

        },
        templateUrl: 'app/templates/components/tree.html'
    };
}
]);
