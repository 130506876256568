(function () {
    app.directive('baiProductDetails', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/catalog/baiProductDetails.html',
                
                controller: ['$scope', '$location', '$route', '$q', '$rootScope', 'SearchServices', 'CoursesService', 'errorDisplayerUtil', 'BreadcrumbsService', 'CatalogSearchParameters', 'DocumentService', 'PlaylistServices', 'MyDocumentsService', ProductDetailsController]
            };
        }
    ]);

    function ProductDetailsController($scope, $location, $route, $q, $rootScope, SearchServices, CoursesService, errorDisplayerUtil, BreadcrumbsService, CatalogSearchParameters, DocumentService, PlaylistServices, MyDocumentsService) {
       $scope.productLink = $location.$$absUrl;

        var params = $route.current.params.param.split('/');
        var productId = params[0];
        if ($rootScope.fromWeb === true) {
            productId = params[0].split('-')[0];
        }
        var productType = "course";
        if ($rootScope.fromWeb === false) {
            productType = productId < 0 ? "document" : "course";
        }

        $scope.productId = productId;
        $scope.outputPromises = [];
        $scope.document = {};
        $scope.course = {};
        $scope.isLoading = true;
        $scope.productError = errorDisplayerUtil.create();
        $scope.productType = productType;
        $scope.fromWeb = $rootScope.fromWeb;
        $scope.showPlaylistHeart = !$scope.fromWeb;
        $scope.showDocumentHeart = !$scope.fromWeb;
        var needMyDocumentsRefresh = true;

        $scope.enableMyDocumentsRefresh = function () {
            needMyDocumentsRefresh = true;
        };

        var promises = [];

        if ($rootScope.fromWeb === true) {
            SearchServices.getProductById(productId).then(function (success) {
                $scope.document = success.document;
                setMetadataAndBreadcrumbs();
                $scope.isLoading = false;
            }, function (failure) {
                $scope.productError.setError(failure.ErrorNumber, failure.ErrorMessage);
                $scope.isLoading = false;
            });
        }
        else {
            if (productType === "course") {
                SearchServices.getProductById(productId).then(function (success) {
                    $scope.document = success.document;
                    if ($scope.document.ProductType === "Document") {
                        initDocumentCourse();
                    }
                    else { // assume course
                        initCourse();
                    }
                }, function (failure) {
                    $scope.productError.setError(failure.ErrorNumber, failure.ErrorMessage);
                    $scope.isLoading = false;
                });
            } else {
                initCustomDocument();
            }
        }

        function initDocumentCourse() {
            promises.push(CoursesService.getCourseWithAssignment(productId));
            var emptyOfferingsPromise = $q.defer();
            emptyOfferingsPromise.resolve({ response: [], status: null, links: null });
            promises.push(emptyOfferingsPromise.promise);
            promises.push(MyDocumentsService.getAll());

            var deferredDocumentPromise = $q.defer();
            deferredDocumentPromise.resolve(promises[0]);
            $scope.outputPromises.push(deferredDocumentPromise.promise);

            var deferredEmptyOfferingsPromise = $q.defer();
            deferredEmptyOfferingsPromise.resolve(promises[1]);
            $scope.outputPromises.push(deferredEmptyOfferingsPromise.promise);

            $q.all(promises).then(function (success) {
                $scope.course = success[0];
                if (promises.length === 3) {
                    var myDocuments = success[2];
                    var i = 0;
                    for (i = 0; i < myDocuments.length; i++) {
                        var myDocumentsId = myDocuments[i].parentBAICourseID !== null ? myDocuments[i].parentBAICourseID : myDocuments[i].courseID;
                        if (myDocumentsId.toString() === $scope.document.CourseId) {
                            $scope.document.isInMyDocuments = true;
                            break;
                        }
                    }
                }
                setMetadataAndBreadcrumbs();
                $scope.isLoading = false;
            }, function (failure) {
                $scope.productError.setError(failure.ErrorNumber, failure.ErrorMessage);
                $scope.isLoading = false;
            });
        }

        function initCourse() {
            promises.push(CoursesService.getCourseWithAssignment(productId));
            promises.push(CoursesService.getSingleCourseOfferings(productId));
            promises.push(PlaylistServices.getPlaylist());
            var deferredCoursePromise = $q.defer();
            deferredCoursePromise.resolve(promises[0]);
            $scope.outputPromises.push(deferredCoursePromise.promise);
            var deferredOfferingsPromise = $q.defer();
            deferredOfferingsPromise.resolve(promises[1]);
            $scope.outputPromises.push(deferredOfferingsPromise.promise);

            $q.all(promises).then(function (success) {
                $scope.course = success[0];
                if ($scope.course !== undefined && typeof $scope.course !== "undefined") {
                    if ($scope.course.type === "courseGroup" || $scope.course.type === "courseGroupCurricula" || $scope.course.type === "courseGroupCertification" || $scope.course.type === "careerPath") {
                        $scope.showPlaylistHeart = false;
                    }
                }
                if (promises.length === 3) {
                    var playlist = success[2];
                    var i = 0;
                    for (i = 0; i < playlist.length; i++) {
                        if (playlist[i].toString() === $scope.document.CourseId) {
                            $scope.document.isInPlaylist = true;
                            break;
                        }
                    }
                }

                setMetadataAndBreadcrumbs();

                $scope.isLoading = false;
            }, function (failure) {
                $scope.productError.setError(failure.ErrorNumber, failure.ErrorMessage);
                $scope.isLoading = false;
            });
        }

        function initCustomDocument() {
            promises.push(SearchServices.getProductById(productId));
            // Need to flip productId to positive number to get document from lms api
            promises.push(DocumentService.getSingleDocumentInfo(productId * -1));
            var deferredDocumentPromise = $q.defer();
            deferredDocumentPromise.resolve(promises[1]);
            $scope.outputPromises.push(deferredDocumentPromise.promise);
            $q.all(promises).then(function (success) {
                $scope.document = success[0].document;
                $scope.course = success[1];
                setMetadataAndBreadcrumbs();
                $scope.isLoading = false;
            }, function (failure) {
                $scope.productError.setError(failure.ErrorNumber, failure.ErrorMessage);
                $scope.isLoading = false;
            });
        }

        function setMetadataAndBreadcrumbs() {
            if ($rootScope.fromWeb === true) {
                $rootScope.metaTags.title = $scope.document.Name + " - " + $scope.document.ProductType;
                $rootScope.metaTags.description = $scope.document.Description;
                $rootScope.metaTags.keywords = $scope.document.displayTopics;
                if (CatalogSearchParameters.productBreadcrumbs.length === 0) {
                    BreadcrumbsService.crumbs.push({ name: "Home", link: "/" });
                    BreadcrumbsService.crumbs.push({ name: $scope.document.Name, link: "" });
                } else {
                    BreadcrumbsService.crumbs = CatalogSearchParameters.productBreadcrumbs;
                }
            } else {
                BreadcrumbsService.crumbs = CatalogSearchParameters.productBreadcrumbs;
            }
        }
    }
})();