/* Services file for the premium admin page */
app.service('OrganizationsService', ['$http', '$q', 'OrgFunctions', 'UsersService', function($http, $q, OrgFunctions, UsersService) {

    var _this = this;

    /* Creates a new organization. Needs a portalId too.
     * portalId should be the portalId from
     * UsersService.getPortalId(); */
    _this.updateOrganization = function(orgData, method) {
        
        var req = {
            method: method,
            url: 'api/organization/',
            data: orgData,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(response) {
            return response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.format_AddOrgFields = function(orgData) {

        if (orgData !== 'undefined' && orgData !== null) {
            _.each(orgData, function(org){
                _.each(orgData, function(pOrg){
                    if (org.parentId === pOrg.id) {
                        org.parentOrgName = pOrg.name;
                    }
                });

            if (org.enabled === true) {
                org.status = 'Enabled';
            } else if (org.enabled === false) {
                org.status = 'Disabled';
            }

            });
        }
        
        return orgData;
    };

    _this.getOrganizationHierarchy = function() {
        var req = {
            method: 'GET',
            url: 'api/organizationHierarchy/',
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = (success.data);
            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getOrgTree = function () {
        var req = {
            method: 'GET',
            url: 'api/organizationTree/',
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getSingleOrg = function(orgId) {
        var req = {
            method: 'GET',
            url: 'api/organization/' +  orgId,
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = success.data;
            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.createOrgObj = function(isSuccessful) {

        var deferred = $q.defer();

        var orgObj = {
            "data": {
              "id": 0,
              "name": "",
              "typeId": null,
              "type": "dealer",
              "parentId": null,
              "typeName": "",
              "announcements": "",
              "description": "",
              "enabled": null,
              "updateDateTime": moment().format(),
              "updateUserId": null,
              "sourceCode": "",
              "abbreviation": "",
              "portalId": UsersService.getPortalId(),
              "portalCode": "",
              "isTopLevelOrg": null
            }
        };


        if (isSuccessful === true) {
            deferred.resolve(orgObj.data);
        } else {
            deferred.reject("No Object available!!");
        }

        return deferred.promise;
    };


}]);
