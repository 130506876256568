app.factory('httpUtil', ['$rootScope', 'urlParamsUtil', function httpUtil($rootScope, urlParamsUtil) {
    var _this = this;

    _this._headers = {};

    /**
     * Returns a header value if it exists; if not, it returns false.
     *
     * @param header Header name
     * @returns {*}
     * @private
     */
    _this._getHeader = function(header) {
        if(typeof _this._headers[header] !== 'undefined') {
            return _this._headers[header];
        }

        return false;
    };

    /**
     * Returns all headers
     *
     * @returns {{}|*}
     * @private
     */
    _this._getHeaders = function() {
        return _this._headers;
    };

    /**
     * Set a header
     *
     * @param key Name of key in headers object
     * @param value Value to map of the key
     * @private
     */
    _this._setHeader = function(key, value) {
        _this._headers[key] = value;
    };

    /**
     * Method called internally by angular. This method is called by the HTTP interceptor. This method
     * will attach all headers necessary to refresh call on certain calls.
     *
     * @param config Object containing XHR request paramaters
     * @returns {*}
     * @private
     */
    _this._request = function(config) {
        config.url += urlParamsUtil.create();

        if($rootScope.resetCache === true) {
            _this._setHeader('Cache-Control', 'no-cache');

            angular.extend(config.headers, _this._headers);

            $rootScope.resetCache = false;

        }

        return config;
    };

    return {
        getHeader: _this._getHeader,
        getHeaders: _this._getHeaders,
        request: _this._request,
        setHeader: _this._setHeader
    };
}]);