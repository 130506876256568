app.service('TimezoneService', ['$http', '$q', function ($http, $q) {
    var _this = this;

    _this.timezones = [];
    _this.localTimeZone = null;
    
    /**
     * Unfortunate this has to exist. This files must exist because there is no valid api, but we need api data.
     * When we get an api, we can move this away "gold" and leverage the api, but only the data source will be changing.
     */
    _this.getAllTimezones = function() {
        var req = {
            method: 'GET',
            // url: 'api/Catalog/',
            url: 'app/json/latitude_countries.json',
            timeout: window.timeout,
            cache: true,
        };

        return $http.get(req.url).then(function (success) {
            _this.timezones = success.data;
            return success.data;
        }, function(failure) {
            throw failure;
        });
    };

    //initializes _this.timezones
    _this.getAllTimezones();

    _this.getMyTimeZoneAbbreviation = function () {
        if (_this.localTimeZone === null) {
            var d = new Date();
            _this.localTimeZone = d.toLocaleString("en", { timeZoneName: "short" }).split(' ').pop();
        }
        return _this.localTimeZone;
    };

    _this.getTimeZoneById = function (id) {
        return _this.timezones.find(function (tz) {
            return tz.id === id;
        });
    };

}]);
