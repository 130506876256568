app.directive('baiUserStats', [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/templates/users/partials/account/baiUserStats.html',       
        controller: ['$scope', '$filter', 'SettingsService', 'UsersService', 'TranscriptsService', 'CoursesService', 'AssignmentsService', 'SkillService',
            function ($scope, $filter, SettingsService, UsersService, TranscriptsService, CoursesService, AssignmentsService, SkillService) {
                /* Training Insights and Career Path access determined by the organization settings */
                var sett = SettingsService.getSettings().then(function (success) {
                    $scope.showResponsibilities = SettingsService.isTrainingInsightsEnabled();
                    $scope.hasCareerPaths = SettingsService.isCareerPathEnabled();
                });

                var openUserProfileStats = function(e) {
                    $scope.transcriptCountsLoaded = false;
                    $scope.transcriptLoadedError = false;
                    $scope.responsibilitiesLoaded = false;
                    $scope.showAchievementsError = false;
                    $scope.achievementsError = '';
                    $scope.careerPathsLoading = true;
                    $scope.skillsLoading = true;
                    $scope.coursesCompleted = 0;
                    $scope.eventsCompleted = 0;
                    $scope.courseGroupsCompleted = 0;
                    $scope.usersResponsibilities = [];
                    $scope.careerPathsCompleted = [];
                    $scope.usersSkills = [];

                    /* Retrieve the user's transcripts to count the number of Course Groups/ILTs/WBTs completed */
                    var transCallAllTime = TranscriptsService.fetchCompletionCounts($scope.userId);
                    transCallAllTime.then(function(success) {
                        var cc = $filter('filter')(success, { "key": "courses" }, true);
                        $scope.coursesCompleted = cc.length > 0 ? cc[0].value : 0;
                        var ec = $filter('filter')(success, { "key": "events" }, true);
                        $scope.eventsCompleted = ec.length > 0 ? ec[0].value : 0;
                        var cgc = $filter('filter')(success, { "key": "coursegroups" }, true);
                        $scope.courseGroupsCompleted = cgc.length > 0 ? cgc[0].value : 0;
                        $scope.transcriptCountsLoaded = true;
                    }, function(failure) {
                        $scope.transcriptCountsLoaded = true;
                        $scope.transcriptLoadedError = true;
                    });

                    /**********************************************************/
                    /** Responsibilities                                     **/
                    /**********************************************************/

                    var userResp = UsersService.getUsersResponsibilities($scope.userId);
                    userResp.then(function(success) {
                        $scope.usersResponsibilities = success;
                        $scope.responsibilitiesLoaded = true;
                    }, function(failure) {
                        $scope.responsibilitiesLoaded = true;
                        $scope.showAchievementsError = true;
                        $scope.achievementsError += 'Responsibilities failed to load </br>';
                    });

                    /**********************************************************/
                    /** Skills                                               **/
                    /**********************************************************/

                    var userSkills = SkillService.fetchUserSkills($scope.userId);
                    userSkills.then(function (success) {
                        $scope.usersSkills = success;
                        $scope.skillsLoading = false;
                    }, function (failure) {
                        $scope.skillsLoading = false;
                        $scope.showAchievementsError = true;
                        $scope.achievementsError += 'Skills failed to load </br>';
                    });

                    /**********************************************************/
                    /** Career Paths                                         **/
                    /**********************************************************/

                    var careerPaths = CoursesService.getCoursesByType("careerPath", $scope.userId);
                    //var careerPaths = AssignmentsService.fetch($scope.userId);
                    careerPaths.then(function(success) {
                        $scope.careerPathsLoading = false;
                        $scope.careerPathsCompleted = $filter('filter')(success, { "status": "successful" }, true);
                    }, function(failure) {
                        $scope.careerPathsLoading = false;
                        if (failure.StatusCode === 404) {
                            $scope.careerPathsCompleted = [];
                        } else {
                            $scope.achievementsError += 'Career paths failed to load';
                            $scope.showAchievementsError = true;
                        }
                    });
                };

                if ($scope.isLoadImmediate === true) {
                    openUserProfileStats();
                } else {
                    $scope.$on('openUserProfileStats', openUserProfileStats);
                }
            }]
    };
}]);