app.service('TasksService', ['$http', 'dateUtil', 'errorDisplayerUtil', function($http, dateUtil, errorDisplayerUtil) {
    var _this = this;

    _this.response = null;

    _this.fetchSingle = function(taskId, userId) {
        var req = {
            method: 'GET',
            url: 'api/user/' + userId + '/task/' + taskId + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getTasksApprovalTA = function() {
        // api/tasks/requireapproval
        var req = {
            method: 'GET',
            url: 'api/tasks/requireapproval',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.taskApproval = function (userId, taskId, isComplete) {
        var req = {
            method: 'POST',
            url: 'api/team/user/'+ userId + '/task/'+ taskId + '/' + isComplete,
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });
    };

    // the dreaded format function.. it appears in different places
    // like those monsters in the Akrham Horror board game.
    _this.format = function (response) {
        var dates = ['assignedOn', 'completedDate', 'dueDate', 'endDateTime', 'startDate', 'startDateTime', 'requestedDate', 'endDate', 'createdDate'];

        var current = null;

        if (response !== null) {
            for (var assignment in response) {
                current = response[assignment];

                for (var node in current) {
                    if (current[node] !== null && $.inArray(node, dates) !== -1) {
                        current[node] = dateUtil.formatDate(current[node]);
                    } else if (node === 'offerings' || node === 'sessions' || node === 'assignedOffering') {
                        if (node === 'assignedOffering') {
                            current[node] = [current[node]];

                            current[node] = _this.format(current[node])[0];
                        } else {
                            current[node] = _this.format(current[node]);
                        }
                    }
                }

                current.error = errorDisplayerUtil.create();
                current.isLoading = false;
            }
        }

        return response;
    };
}]);
