(function () {
  catalog.controller("applicationLibraryMenuController", [
    "$scope",
    "$http",
    "UsersService",
    "SettingsService",
    "CssService",
    applicationLibraryMenuController,
  ]);

  function applicationLibraryMenuController(
    $scope,
    $http,
    UsersService,
    SettingsService
  ) {
    $scope.isMenuOpen = false;

    $scope.toggleMenu = function () {
      $scope.isMenuOpen = !$scope.isMenuOpen;
    };

    SettingsService.getSettings().then(function (success) {
      $scope.isPlmEnabled = success.features.enableLmsPlmIntegration;
    });

    $scope.hasNotifications = false;
    $scope.initiated = false;
    $scope.alertCount = 0;

    $scope.fetchNotifications = function () {
      UsersService.getPlmAlerts().then(
        function (response) {
          $scope.alertCount = response;
          $scope.hasNotifications = $scope.alertCount > 0;
          $scope.initiated = true;
        },
        function error(error) {
          console.error("Error fetching notifications: ", error);
          $scope.initiated = true;
        }
      );
    };

    $scope.formatUserParams = function () {
      var payload = {};

      //handle user id with context service
      if (UsersService.user.firstName) {
        payload.givenName = UsersService.user.firstName.toString();
      }
      if (UsersService.user.lastName) {
        payload.surname = UsersService.user.lastName.toString();
      }
      if (UsersService.user.email) {
        payload.email = UsersService.user.email.toString();
      }
      if (UsersService.user.userName) {
        payload.username = UsersService.user.userName.toString();
      }
      //handle guid in api service

      var loc = window.location.hostname.toLowerCase();
      var redirectUrl = "https://policy.bai.org/lms_router";
      if (loc.includes("dev")) {
        redirectUrl = "https://plm-dev-app1.bai.org/lms_router";
      }
      if (loc.includes("qa")) {
        redirectUrl = "https://plm-qa-app1.bai.org/lms_router";
      }
      if (loc.includes("uat")) {
        redirectUrl = "https://plm-qa-app1.bai.org/lms_router";
      }
      if (loc.includes("local")) {
        redirectUrl = "https://plm-dev-app1.bai.org/lms_router";
      }

      UsersService.getSignedJwt(payload).then(function (response) {
        var signedToken = response.data;
        var stateUrl = btoa(window.location.hostname.toLowerCase());
        window.location.href = redirectUrl + "?id_token=" + signedToken;
      });
    };

    $scope.fetchNotifications();
  }
})();
