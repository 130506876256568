/* This page is a demo page for various components related to the premiumAdmin */
app.factory('Rules_AdminOverride', ['BreadcrumbsService', 'UsersService', 'RulesService', 'GroupsService', 'CoursesService', 'dateUtil', 'PeopleGroupFunctions', '$q', '$http', '$timeout', '$filter', 'filterFilter', '$route', '$location', '$routeParams', 'WizardHandler', 'RoleMapsMixin', '$injector', 'errorDisplayerUtil', 'SettingsService',
    function (BreadcrumbsService, UsersService, RulesService, GroupsService, CoursesService, dateUtil, PeopleGroupFunctions, $q, $http, $timeout, $filter, filterFilter, $route, $location, $routeParams, WizardHandler, RoleMapsMixin, $injector, errorDisplayerUtil, SettingsService) {
        return function($scope) {
            var _this = this;
            $scope.dateUtil = dateUtil;
            $scope.defaultHeader = true; // This Sets the page to full width of the screen.
            $scope.hideCatalogSearch = true;
            /* This will make the navigation tab active on certain page */
            $scope.activeTab = 'tab-active-class';

            /*This will route users to the appropiate user manual based on active tab*/
            $scope.userManualHref = 'https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Rules';

            /* Training Insights access determined by the organization settings */
            var sett = SettingsService.getSettings().then(function (success) {
                $scope.showTrainingInsights = SettingsService.isTrainingInsightsEnabled();
                $scope.showCareerPath = SettingsService.isCareerPathEnabled();
            });


            /**
             * All controller-level scoped variables should be attached to general for cross-template communication
             */
            $scope.general = {};
            $scope.general.rulesSearch = {};

            if (typeof $route.current !== 'undefined' && typeof $route.current !== null)
                _this.params = $route.current.params;

            /* ************************ Active Rules Tree View Actions ******************* */

            $scope.activeRuleData = [{ 'name': 'Active', 'nodes': [] }];
            $scope.activeRuleOptions = {};
            $scope.activeRuleOptions.initiallyOpened = false;
            $scope.activeRuleOptions.rootNodeIsTopLevel = false;
            $scope.activeRuleOptions.canDrillDown = false;

            /* This veriable is initialized here only to make sure it exists.
             * Otherwise, when you type stuff into the treeFilter bar, it won't search the tree
             */
            $scope.ruleFilter = "";
            $scope.activeRuleTreeIsLoading = true;
            $scope.activeRuleNowHidden = true;
            $scope.activeRuleTitle = "Active";

            $scope.activeRuleError = {};
            $scope.activeRuleError.error = errorDisplayerUtil.create();

            $scope.homeAction = function() {
                $scope.homeTemplate = 'app/templates/rules/home/admin.html';
                $scope.ruleSelected = $scope.activeRuleData[0];
                _this.listAllRules();
            };

            $scope.createAction = function() {
                $scope.isCreate = true;
                $scope.homeTemplate = 'app/templates/rules/create/admin.html';
                $scope.wizardTitle = "Add New Rule";
                $scope.goToPrevious = false;
                _this.createRule();
            };

            $scope.rulesTableError = {};
            $scope.rulesTableError.error = errorDisplayerUtil.create();
            $scope.rulesRowsAreLoading = true;

            _this.listAllRules = function() {
                $scope.rulesTableError.error.reset();

                var allRulesPromise = RulesService.getAllRules();

                allRulesPromise.then(function(success) {
                    $scope.rulesRowsAreLoading = false;
                    _this.allRulesData = success;
                    $scope.rows = _this.filterRows('active');
                }, function(failure) {
                    $scope.rulesRowsAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.rulesTableError.error.setError(errorCode, message);
                });
            };

            _this.createRule = function() {
                var newRuleObj = RulesService.createRuleObj(true);
                newRuleObj.then(function(success) {
                    $scope.rule = success;
                }, function(failure) {
                    // We are bringing the dummy object from
                    // RulesService and bind it to the form.
                });
            };

            // Based on which step you're in rule creation process.
            // The 'Next' click in that step will call the next tab respective function.
            // Eg: If you're in general properties step., the next click in that step
            // will call _this.peopleAction() function., because you see peopleGroup stuff here.
            // Created this pattern so that it works for both create and update rules.
            $scope.ruleCreateError = {};
            $scope.ruleCreateError.error = errorDisplayerUtil.create();
            $scope.ruleCreateIsLoading = false;

            _this.createFailure = function(failure) {
                $scope.ruleCreateIsLoading = false;
                var errorCode = failure.StatusCode;
                var message = failure.ErrorMessage;
                $scope.ruleCreateError.error.setError(errorCode, message);
            };

            $scope.isDisabled = false;
            _this.create_peopleAction = function() {
                $scope.goToPrevious = true;
                $scope.ruleCreateIsLoading = true;
                $scope.ruleCreateError.error.reset();
                
                var data = angular.copy($scope.rule);
                var generalInfoPromise;

                if (typeof data.id !== 'undefined' && data.id !== null) {
                    generalInfoPromise = _this.generalInfo_update(data);
                } else {
                    generalInfoPromise = _this.generalInfo(data);
                }

                generalInfoPromise.then(function(success) {
                    $scope.ruleCreateIsLoading = false;
                    $scope.isDisabled = true;
                    $scope.rule = success;
                    //used for validating assignOn dates
                    $scope.rule.startDateStartOfDay = angular.copy($scope.rule.startDate).moment.startOf('day');
                    $scope.rule.endDateEndOfDay = $scope.rule.endDate === null ? null : angular.copy($scope.rule.endDate).moment.endOf('day');


                    WizardHandler.wizard("Rule").next();
                    // GroupId is created upon succesfull rulecreation
                    // Pass this GroupId to update criteria.
                    var groupId = success.groupId;
                    _this.ruleIdForCreate = success.id;
                    _this.ruleStartDate = success.startDate;
                    _this.ruleCreateInProgress = success;
                    _this.createRule_PeopleGroup(groupId);
                }, function(failure) {
                    _this.createFailure(failure);
                });
            };

            // This is being called upon rule creation
            _this.generalInfo = function(data) {
                var status = 'create';
                var createRule_General = RulesService.ruleGeneralCall(data, status);

                return createRule_General;
            };

            // This is being called when user steps back to general info in 
            // Rule create process and tries to update an General Info
            _this.generalInfo_update = function(data) {
                var status = 'update';
                var updateRule_General = RulesService.ruleGeneralCall(data, status);

                return updateRule_General;
            };

            $scope.createPeopleGroup = {};
            $scope.createPeopleGroup.isHeaderOpen = false;
            $scope.createPeopleGroup.isDisabled = false;
            $scope.createContainerOptions = {};
            $scope.createContainerOptions.showLock = false;

            // $scope.createRulePeopleIsLoading = false;

            _this.createRule_PeopleGroup = function(groupId) {
                $scope.ruleCreateIsLoading = true;
                $scope.ruleCreateError.error.reset();

                var all = $q.all(PeopleGroupFunctions.peopleGroupActions());
                all.then(function(success) {
                    $scope.groupStructure = success[0];
                    $scope.selectOptionsData = success[1];
                    $scope.containerOperandsData = GroupsService.containerOperandsRequired(success[2]);

                    $scope.groupStructure.groupId = groupId;

                    GroupsService.secondaryFormatGroupStructure(success[3], success[4].groups, success[5], success[6], success[7]);

                    $scope.ruleCreateIsLoading = false;
                    $scope.showRulePeopleGroup = true;

                }, function(failure) {
                    $scope.showRulePeopleGroup = false;
                    _this.createFailure(failure);

                });
            };

            _this.create_assignmentsAction = function() {
                $scope.goToPrevious = true;
                $scope.ruleCreateIsLoading = true;
                $scope.ruleCreateError.error.reset();
                $scope.rows = [];

                var peopleGroupPromise = _this.updatePeopleGroup(true);

                peopleGroupPromise.then(function(success) {
                    $scope.ruleCreateIsLoading = false;
                    $scope.peopleGroupStructure = success;
                    WizardHandler.wizard("Rule").next();
                    $scope.rows = [];

                }, function(failure) {
                    _this.createFailure(failure);
                });
            };

            _this.create_datesAction = function() {
                $scope.goToPrevious = true;
                $scope.ruleCreateIsLoading = true;
                $scope.ruleCreateError.error.reset();

                var assignmentsPromise = _this.addAssignments();
                assignmentsPromise.then(function(success) {
                    $scope.ruleCreateIsLoading = false;
                    WizardHandler.wizard("Rule").next();
                    $scope.rows = _this.setDefaults(success);
                }, function(failure) {
                    _this.createFailure(failure);
                });
            };

            _this.addAssignments = function() {
                var data = angular.copy($scope.rows);
                var id = _this.ruleIdForCreate;

                _.each(data, function (course) {
                    //if assign on date is undefined, then default the assign on date to the rule start date
                    if (course.assignOnDate === undefined) {
                        course.assignOnDate = angular.copy(_this.ruleStartDate);
                    }
                });

                var assignmentsPromise;
                if (data !== undefined && data !== 'null')
                    assignmentsPromise = RulesService.updateRuleAssignments(id, data);

                return assignmentsPromise;
            };

            _this.setDefaults = function (courses) {
                var ruleStartDate = angular.copy($scope.rule.startDate);

                _.each(courses, function (course) {
                    course.flagDueDateMethod = 0;
                    //set the default date to the rule start date, have to use angular.copy, otherwise all the assign on dates for courses will be the same object
                    if (course.assignOnDate === undefined) {
                        course.assignOnDate = angular.copy(ruleStartDate);
                    }
                });

                return _this.formatAcceptPriorDates(courses);
            };

            _this.create_previewAction = function() {
                $scope.goToPrevious = true;
                $scope.ruleCreateIsLoading = true;
                $scope.ruleCreateError.error.reset();

                //Check if assign on dates are valid
                var isAssignOnDateValid = true;
                _.each($scope.rows, function (row) {
                    if (typeof row.assignOnDate !== "undefined") {
                        if (row.assignOnDate === null) {
                            isAssignOnDateValid = false;
                        }
                        else if ($scope.rule.startDate.moment > row.assignOnDate.moment || ($scope.rule.endDate !== null && $scope.rule.endDate.moment < row.assignOnDate.moment)) {
                            isAssignOnDateValid = false;
                        }
                    } else {
                        isAssignOnDateValid = false;
                    }
                });

                if (isAssignOnDateValid === false) {
                    $scope.ruleCreateIsLoading = false;
                    var errorCode = '';
                    var message = "'Assign On' dates must be between the start and end date of the rule.";
                    $scope.ruleCreateError.error.setError(errorCode, message);
                } else {

                    var datesPromise = _this.addAssignments();
                    datesPromise.then(function (success) {
                        $scope.ruleCreateIsLoading = false;
                        WizardHandler.wizard("Rule").next();
                        _this.ruleCount();
                    }, function (failure) {
                        _this.createFailure(failure);
                    });
                }

            };

            _this.ruleCount = function() {
                $scope.ruleCreateIsLoading = true;
                $scope.ruleCreateError.error.reset();

                var ruleId = _this.ruleIdForCreate;

                var ruleCount = RulesService.getRuleCounts(ruleId);
                ruleCount.then(function(success) {
                    $scope.ruleCreateIsLoading = false;
                    $scope.count = success;

                }, function(failure) {
                    _this.createFailure(failure);
                });
            };

            _this.create_activateAction = function() {
                $scope.goToPrevious = true;
                $scope.ruleCreateIsLoading = true;
                $scope.ruleCreateError.error.reset();

                $scope.rule.status = "active";

                var activateRulePromise = _this.updateGeneralInfo();
                activateRulePromise.then(function(success) {
                    $scope.ruleCreateIsLoading = false;
                    $scope.rule_Success = true;
                    $timeout(function() { $location.path('/rules/home'); }, 3000);

                }, function(failure) {
                    _this.createFailure(failure);
                });


            };

            $scope.AddAssignmentsOpen = function(){
                $scope.$broadcast('openAddAssignmentsModal', { displaytype: 1, studentId: null });
            };

            ////////// All View/Update Rule Methods Below /////////////////
            $scope.ruleEditTabs = {};
            $scope.editAction = function() {
                $scope.isCreate = false;
                $scope.homeTemplate = 'app/templates/rules/edit/admin.html';
                $scope.ruleEditTabs.selectedTab = 0;
                _this.update_getGeneralAction();
            };

            /**
             * This is called on landing page of edit rule page.
             * Get Rule General Info
             **/
            $scope.editRuleGeneralErrror = {};
            $scope.editRuleGeneralErrror.error = errorDisplayerUtil.create();
            $scope.editRuleGeneralIsLoading = true;

            _this.update_getGeneralAction = function() {
                var ruleId = _this.params.param.split('/')[0];

                var singleRuleInfo = RulesService.getSingleRule(ruleId);
                var ruleStatusOptions = RulesService.getRuleStatusOptions(true);

                var all = $q.all([singleRuleInfo, ruleStatusOptions]);
                all.then(function(success) {
                    $scope.editRuleGeneralIsLoading = false;
                    $scope.showEditRuleGeneral = true;
                    $scope.rule = success[0];
                    $scope.statusOptions = success[1];

                    $scope.rule.startDateStartOfDay = angular.copy($scope.rule.startDate).moment.startOf('day');
                    $scope.rule.endDateEndOfDay = $scope.rule.endDate === null ? null : angular.copy($scope.rule.endDate).moment.endOf('day');

                    _this.ruleCreateInProgress = success[0];

                    _this.beforeDaysList = $scope.rule.beforeDueNotificationDays;
                    var singleRule = success[0];
                    _this.validateStartDate(singleRule);

                    if ($scope.rows === undefined && $scope.rule.status === 'draft') {
                        _this.getAssigmentsForDateValidation();
                    }

                }, function(failure) {
                    $scope.editRuleGeneralIsLoading = false;
                    $scope.showEditRuleGeneral = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.editRuleGeneralErrror.error.setError(errorCode, message);
                });
            };

            $scope.uniqueNotificationBefore = function(int) {
                $scope.duplicateBefore = false;
                if (typeof int !== 'undefined' && int !== null) {

                    var output = [];
                    var beforeDays = $scope.rule.beforeDueNotificationDays;

                    _.each(beforeDays, function(dayInt) {
                        var diff = dayInt - int;
                        if (diff === 0) {
                            output.push(diff);
                        }
                    });

                    if (output.length > 1) {
                        $scope.duplicateBefore = true;
                    }
                }
            };

            $scope.uniqueNotificationAfter = function(int) {
                $scope.duplicateAfter = false;
                if (typeof int !== 'undefined' && int !== null) {

                    var output = [];
                    var beforeDays = $scope.rule.afterDueNotificationDays;

                    _.each(beforeDays, function(dayInt) {
                        var diff = dayInt - int;
                        if (diff === 0) {
                            output.push(diff);
                        }
                    });

                    if (output.length > 1) {
                        $scope.duplicateAfter = true;
                    }
                }
            };

            _this.validateStartDate = function(rule) {
                if(typeof rule.lastProcessedOn === "undefined" || rule.lastProcessedOn === null){
                    $scope.isRuleStartDateEditable = true;

                    if (typeof rule.status !== "undefined" && rule.status !== null && rule.status === 'draft' &&
                        !rule.startDate && !rule.startDate.moment && !rule.startDate.moment._isValid)
                            rule.startDate = dateUtil.formatDate(moment().format());
                }
                else {
                    $scope.isRuleStartDateEditable = false;
                }

                if (typeof rule.status !== "undefined" && rule.status !== null && rule.status === 'closed') {
                    $scope.closedRule = true;
                } else {
                    $scope.closedRule = false;
                }
            };

            $scope.updateStatus = function(rule) {
                _this.validateStartDate(rule);
            };

            /**
             * On click on people tab, in edit rule page.
             * Get Rule People Group Structure.
             **/
            $scope.editPeopleGroup = {};
            $scope.editPeopleGroup.isHeaderOpen = true;
            $scope.editPeopleGroup.isDisabled = false;
            $scope.containerOptions = {};
            $scope.containerOptions.showLock = false;

            $scope.editRulePeopleError = {};
            $scope.editRulePeopleError.error = errorDisplayerUtil.create();
            $scope.editRulePeopleIsLoading = true;

            $scope.update_getPeopleAction = function(groupId) {
                $scope.editRulePeopleError.error.reset();
                $scope.showCourseDateHistory = false;
                $scope.showGeneralHistory = false;
                $scope.showCourseHistory = false;
                $scope.showPeopleGroupHistory = false;

                var all = $q.all(PeopleGroupFunctions.peopleGroupActions('ruleTab'));
                all.then(function(success) {
                    $scope.selectOptionsData = success[1];
                    $scope.containerOperandsData = GroupsService.containerOperandsRequired(success[2]);
                    GroupsService.secondaryFormatGroupStructure(success[3], success[4].groups, success[5], success[6], success[7]);
                    _this.peopleGroupStructure(groupId);

                    $scope.editRulePeopleIsLoading = false;
                    $scope.showPeopleGroup = true;

                }, function(failure) {
                    $scope.editRulePeopleIsLoading = false;
                    $scope.showPeopleGroup = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.editRulePeopleError.error.setError(errorCode, message);
                });
            };

            $scope.peopleGroupError = {};
            $scope.peopleGroupError.error = errorDisplayerUtil.create();
            $scope.peopleGroupIsLoading = false;
            $scope.showPeopleGroup = true;

            _this.peopleGroupStructure = function(groupId) {
                $scope.peopleGroupIsLoading = true;
                $scope.peopleGroupError.error.reset();

                var rule_groupStructure = GroupsService.peopleGroupStructure(groupId);
                rule_groupStructure.then(function(success) {
                    $scope.groupStructure = _this.reFormattingPeopleGroupStructure(GroupsService.secondaryFormatGroupStructure_groupStructure(success));

                    $scope.peopleGroupIsLoading = false;
                    $scope.showPeopleGroup = true;
                }, function(failure) {
                    $scope.peopleGroupIsLoading = false;
                    $scope.showPeopleGroup = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.peopleGroupError.error.setError(errorCode, message);
                });
            };

            _this.reFormattingPeopleGroupStructure = function(groupStructure) {
                if (_this.params.name === "edit") {
                    if (typeof groupStructure.name !== 'undefined' && groupStructure.name === null) {
                        groupStructure.name = "";
                    }

                    if (typeof groupStructure.containerId !== 'undefined' &&
                        groupStructure.containerId !== null &&
                        groupStructure.containerId === "00000000-0000-0000-0000-000000000000" &&
                        groupStructure.operandName === null &&
                        groupStructure.operandId === 0) {
                        groupStructure.containerId = GroupsService.generateGuid();
                        groupStructure.operandId = 1;
                        groupStructure.operandName = "AND (1)";
                    }
                }

                return groupStructure;
            };

            /**
             * On click on Assignments tab, in edit rule page.
             * Get Rule Assignments Data.
             **/
            $scope.editRuleAssignmentsErrror = {};
            $scope.editRuleAssignmentsErrror.error = errorDisplayerUtil.create();
            $scope.editRuleAssignmentsIsLoading = false;

            $scope.update_getAssignmentsAction = function() {
                $scope.editRuleAssignmentsErrror.error.reset();
                $scope.editRuleAssignmentsIsLoading = true;
                $scope.showCourseDateHistory = false;
                $scope.showGeneralHistory = false;
                $scope.showCourseHistory = false;
                $scope.showPeopleGroupHistory = false;

                var ruleId = _this.params.param.split('/')[0];

                if (typeof $scope.rows !== 'undefined' && typeof $scope.rows !== null) {
                    $scope.editRuleAssignmentsIsLoading = false;
                    $scope.showRuleAssignments = true;
                    return $scope.rows;
                } else {
                    _this.getRuleAssignments(ruleId);
                }
            };

            _this.getRuleAssignments = function (ruleId) {
                var rule_assignmentsList = RulesService.getRuleAssignments(ruleId);
                rule_assignmentsList.then(function (success) {
                    $scope.editRuleAssignmentsIsLoading = false;
                    $scope.showRuleAssignments = true;
                    $scope.rows = _this.formatAcceptPriorDates(success);

                    _.each($scope.rows, function (row) {

                        //If the rule is active,and the assign on date is in the past (meaning the assignment went out), then the assign on date it not editable
                        if (typeof row.assignOnDate !== "undefined") {
                            //Active and processed and assign date is less that current date is not editable
                            if (($scope.rule.status === 'active' && $scope.rule.lastProcessedOn !== null) && (row.assignOnDate.moment < moment())) {
                                row.isAssignOnDateEditable = false;
                            } else {
                                row.isAssignOnDateEditable = true;
                            }
                        } else {
                            row.isAssignOnDateEditable = true;
                        }
                    });
                }, function (failure) {
                    $scope.editRuleAssignmentsIsLoading = false;
                    $scope.showRuleAssignments = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.editRuleAssignmentsErrror.error.setError(errorCode, message);
                });
            };

            _this.formatAcceptPriorDates = function (rows) {
                _.each(rows, function (row) {
                    if (row.course.type === 'careerPath') {
                        row.acceptPassingScoreDate = null;
                    }
                });
                return rows;
            };

            $scope.validateCourseDates = function(course) {
                if (course.flagDueDateMethod === 0) {
                    course.dueDateDaysFromKey = "none";
                    course.dueDateDaysFromValue = null;
                } else if (course.flagDueDateMethod === 1) {
                    course.dueDateFixed = null;
                }
            };

            $scope.modalBodyShow = false;
            $scope.modalBodyToggle = function() {
                $scope.modalBodyShow = !$scope.modalBodyShow;
            };

            $scope.selectedCourseCount = 0;
            $scope.checkForLength = function(value) {
                if (value === true) {
                    $scope.selectedCourseCount++;
                } else {
                    $scope.selectedCourseCount--;
                }
            };

            $scope.courseSearchModal = {};
            $scope.courseSearchModal.courseSearch = '';
            $scope.courseSearchModal.selectAll = false;
            $scope.courseSearchModal.coursesAreLoading = true;

            $scope.viewCoursesError = {};
            $scope.viewCoursesError.error = errorDisplayerUtil.create();

            $scope.getAllCoursesCta = function(keyword) {
                $scope.modalBodyShow = true;
                $scope.selectedCourseCount = 0;
                $scope.viewCoursesError.error.reset();
                $scope.courseSearchModal.coursesAreLoading = true;
                $scope.courseSearchModal.selectAll = false;

                var addCoursePromise = CoursesService.getActiveCoursesForOrg(false, false);
                addCoursePromise.then(function(success) {
                    var formattedCourseData = _this.formatCoursesData(success);

                    if (typeof keyword !== 'undefined' && keyword !== null) {

                        formattedCourseData = $filter('filter')(formattedCourseData, keyword);
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });

                    } else {
                        $scope.courseData = $filter('filter')(formattedCourseData, { 'productType': '!Document' });
                    }

                    $scope.courseSearchModal.coursesAreLoading = false;

                }, function(failure) {
                    $scope.courseSearchModal.coursesAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.viewCoursesError.error.setError(errorCode, message);
                });
            };

            // select all will select all courses
            $scope.addAllCourses = function(value) {
                _.each($scope.courseData, function(course) {
                    course.select = value;
                });

                if (value === true) {
                    $scope.selectedCourseCount = $scope.courseData.length;
                } else {
                    $scope.selectedCourseCount = 0;
                }
            };

            $scope.removeCourse = function(course) {
                $scope.rows.splice($scope.rows.indexOf(course), 1);
            };

            $scope.$on('submitSelectedCourses', function (evt, params) {
                $scope.applyNewAssignmentsCta(params.data);
            });

            $scope.applyNewAssignmentsCta = function(data) {
                var selectedCourses = _this.getSelectedCourses(data);

                if ($scope.rows === undefined || $scope.rows === null)
                    $scope.rows = [];

                if ($scope.rows.constructor !== Array)
                    $scope.rows = [];

                $scope.showRuleAssignments = true;

                _.each(selectedCourses, function(course) {
                    if ($scope.rows.indexOf(course) === -1) {
                        if (course.course.productType === "Document"){
                            course.required = false;
                            if (course.assignOnDate === undefined) {
                                course.assignOnDate = $scope.rule.startDate;
                            }
                        }
                        $scope.rows.push(course);
                    }
                });

                $scope.rows = _this.uniqueCourses($scope.rows);

                _this.formatCoursesData(data);
                $scope.courseSearch = '';
            };

            _this.uniqueCourses = function(data) {

                var output = [],
                    keys = [];

                _.each(data, function(datapoint) {
                    var key = datapoint.course.id;
                    if (keys.indexOf(key) === -1) {
                        keys.push(key);
                        output.push(datapoint);
                    }
                });

                return output;
            };

            _this.getSelectedCourses = function(data) {
                var selectedCoursesArray = [];

                _.each(data, function(datapoint) {
                        selectedCoursesArray.push(datapoint);
                });

                return RulesService.formatCourseObj(selectedCoursesArray);
            };

            _this.formatCoursesData = function(data) {
                _.each(data, function(datapoint) {
                    datapoint.select = false;
                });

                return data;
            };

            /***** Add New Courses Modal *****/
            $scope.addNewAssignmentCta = function() {
                $scope.courseSearchModal.courseSearch = '';
                $scope.selectedCourseCount = 0;
                $scope.courseSearchModal.selectAll = false;
                _this.formatCoursesData($scope.courseData);
                angular.element(document.querySelector('#viewAssignmentsModal')).modal('hide');
                $scope.$broadcast('openAddAssignmentsModal', { displaytype: 1, studentId: null });

            };

            /**
             * On click on Dates tab, in edit rule page.
             * Get Rule Assignments Dates Info.
             **/
            $scope.update_getDatesAction = function () {
                $scope.showCourseDateHistory = false;
                $scope.showGeneralHistory = false;
                $scope.showCourseHistory = false;
                $scope.showPeopleGroupHistory = false;
                // If user clicks on dates before clicking Assignments., user
                // wont see any data., but behind the scenes both dates and assignments does the 
                // same actions.
                if (typeof $scope.rows === 'undefined' || $scope.rows.length === 0) {
                    $scope.update_getAssignmentsAction();
                } else {
                    _.each($scope.rows, function (row) {

                        //If the rule is active,and the assign on date is in the past (meaning the assignment went out), then the assign on date it not editable
                        if (typeof row.assignOnDate !== "undefined") {
                            //Active and processed and assign date is less that current date is not editable
                            if (($scope.rule.status === 'active' && $scope.rule.lastProcessedOn !== null) && (row.assignOnDate.moment < moment())) {
                                row.isAssignOnDateEditable = false;
                            } else {
                                row.isAssignOnDateEditable = true;
                            }
                        } else {
                            row.isAssignOnDateEditable = true;
                        }
                    });
                }
            };

            $scope.todaysDate = dateUtil.formatDate(moment().format());

            $scope.batchEditModal = function() {
                $scope.selectedCoursesForEdit = [];
                $scope.batchEditForm = {};
                $scope.batchEditForm.dueDateDaysStatus = false;
                $scope.batchEditForm.dueDateFixedStatus = false;
                $scope.batchEditForm.assignOnDateStatus = false;
                $scope.batchEditForm.assignOnDate = {
                    moment: {}
                };
                $scope.batchEditForm.acceptPassingScoreDateStatus = false;
                $scope.batchEditForm.acceptPassingScoreDate = {};
                $scope.batchEditForm.dueDateFixed = {};
                $scope.isEditableModal_01 = false;
                $scope.isEditableModal_02 = false;

                $scope.selectedCoursesForEdit = filterFilter($scope.rows, { select: 'true' });

                if ($scope.selectedCoursesForEdit.length > 0) {
                    $scope.showEditForm = true;
                } else {
                    $scope.showEditForm = false;
                }
            };

            $scope.saveBatchEditChanges = function() {
                var batchEditForm = $scope.batchEditForm;
                var selectedCoursesForEdit = $scope.selectedCoursesForEdit;

                _.each(selectedCoursesForEdit, function(course) {
                    if (typeof batchEditForm.required !== 'undefined' && batchEditForm.required !== null) {
                        course.required = batchEditForm.required;
                    }

                    if (batchEditForm.assignOnDateStatus === true &&
                        typeof batchEditForm.assignOnDate !== 'undefined' &&
                        typeof batchEditForm.assignOnDate.displayTime !== 'undefined' &&
                        batchEditForm.assignOnDate.displayTime !== null &&
                        (typeof course.isAssignOnDateEditable === 'undefined' || course.isAssignOnDateEditable)) {
                        course.assignOnDate = batchEditForm.assignOnDate;
                    }

                    if (batchEditForm.acceptPassingScoreDateStatus === true &&
                        typeof batchEditForm.acceptPassingScoreDate !== 'undefined' &&
                        batchEditForm.acceptPassingScoreDate !== null &&
                        batchEditForm.acceptPassingScoreDate.moment !== null &&
                        course.course.type !== 'careerPath') {
                        course.acceptPassingScoreDate = dateUtil.formatDate(batchEditForm.acceptPassingScoreDate.moment.format());
                    }

                    if (batchEditForm.dueDateDaysStatus === true &&
                        typeof batchEditForm.dueDateDaysFromKey !== 'undefined' &&
                        batchEditForm.dueDateDaysFromKey !== null &&
                        batchEditForm.dueDateDaysFromKey !== "none") {
                        course.dueDateFixed = null;
                        course.flagDueDateMethod = 1;
                        course.dueDateDaysFromValue = batchEditForm.dueDateDaysFromValue;
                        course.dueDateDaysFromKey = batchEditForm.dueDateDaysFromKey;
                    }

                    if (batchEditForm.dueDateFixedStatus === true &&
                        typeof batchEditForm.dueDateFixed !== 'undefined' &&
                        batchEditForm.dueDateFixed !== null && batchEditForm.dueDateFixed.moment !== null) {
                        course.flagDueDateMethod = 0;
                        course.dueDateFixed = dateUtil.formatDate(batchEditForm.dueDateFixed.moment.format());
                        course.dueDateDaysFromValue = null;
                        course.dueDateDaysFromKey = "none";
                    }
                });
            };

            $scope.toggleDatesSelectionModal_01 = function() {

                if ($scope.batchEditForm.dueDateFixedStatus === true) {
                    $scope.isEditableModal_01 = true;
                } else {
                    $scope.isEditableModal_01 = false;
                }
            };

            $scope.toggleDatesSelectionModal_02 = function() {

                if ($scope.batchEditForm.dueDateDaysStatus === true) {
                    $scope.isEditableModal_02 = true;
                } else {
                    $scope.isEditableModal_02 = false;
                }
            };

            $scope.ruleUpdateError = {};
            $scope.ruleUpdateError.error = errorDisplayerUtil.create();
            $scope.ruleUpdateIsLoading = false;

            $scope.updateRule = function () {
                $scope.ruleUpdateIsLoading = true;
                $scope.ruleUpdateError.error.reset();
                $scope.resetHistory();

                //if groupStructure is not defined, get it
                if (typeof $scope.groupStructure === 'undefined' || $scope.groupStructure === null) {

                    var groupSCall = GroupsService.peopleGroupStructure($scope.rule.groupId);
                    groupSCall.then(function (success) {
                        $scope.groupStructure = _this.reFormattingPeopleGroupStructure(GroupsService.secondaryFormatGroupStructure_groupStructure(success));
                        //once we have the groupStructure, call the api's to update the People Group and Assignments
                        _this.updatePeopleandAssignments();
                    }, function (failure) {
                        _this.fail(failure);
                    });

                } else {
                    _this.updatePeopleandAssignments();
                }


            };

            _this.updatePeopleandAssignments = function () {

                var isAssignOnDateValid = true;
                _.each($scope.rows, function (row) {
                    if (typeof row.assignOnDate !== "undefined") {
                        if (row.course.productType === "Document") {
                            row.assignOnDate = $scope.rule.startDate;
                        }
                        else if (row.assignOnDate === null) {
                            isAssignOnDateValid = false;
                        }
                        else if ($scope.rule.startDate.moment > row.assignOnDate.moment || ($scope.rule.endDate !== null && $scope.rule.endDate.moment < row.assignOnDate.moment)) {
                            isAssignOnDateValid = false;
                        }
                    }
                });

                if (isAssignOnDateValid === false) {
                    $scope.ruleUpdateIsLoading = false;
                    var errorCode = '';
                    var message = "'Assign On' dates must be between the start and end date of the rule.";
                    $scope.ruleUpdateError.error.setError(errorCode, message);
                } else {
                    var all = [];
                    //again, this all depends on if we have the people group object to save and verify
                    if (typeof $scope.groupStructure !== 'undefined' && $scope.groupStructure !== null) {

                        var peoplePromise = _this.updatePeopleGroup(false);
                        if (typeof peoplePromise !== 'undefined' && peoplePromise !== null)
                            all.push(peoplePromise);

                        //Can save the rule without any courses in it currently
                        if (typeof $scope.rows !== 'undefined' && $scope.rows !== null) {
                            var assignmentsPromise = _this.updateAssignments();
                            if (typeof assignmentsPromise !== 'undefined' && assignmentsPromise !== null) {
                                all.push(assignmentsPromise);
                            }
                        }

                        var deferred = $q.defer();

                        $q.all(all).then(function (success) {
                            //in success, we know that people group and assignments were added without error
                            //now we can call the rule update
                            _this.updateRuleGeneralCall();
                        }, function (failure) {
                            _this.fail(failure);
                        });
                    }
                }
            };

            _this.updateRuleGeneralCall = function () {
                var generalPromise = _this.updateGeneralInfo();
                generalPromise.then(function (success) {
                    $scope.ruleUpdateIsLoading = false;
                    $scope.rule = success;
                    $scope.ruleEditTabs.selectedTab = 0;
                }, function (failure) {
                    _this.fail(failure);
                });
            };

            _this.fail = function (failure) {
                $scope.ruleUpdateIsLoading = false;
                var errorCode = failure.StatusCode;
                var message = failure.ErrorMessage;
                $scope.ruleUpdateError.error.setError(errorCode, message);
            };

            _this.updateGeneralInfo = function() {
                var status = 'update';
                var data = angular.copy($scope.rule);
                var updateRule_General = RulesService.ruleGeneralCall(data, status);

                return updateRule_General;
            };

            _this.updatePeopleGroup = function(create) {
                var status = "update";
                // Dont want to do 2 way binding with UI during the call
                var data = angular.copy($scope.groupStructure);
                // Flag to say that PG created from rules tab.
                data.IsRuleCaller = true;
                data.IsRulePreview = create;
                data.name="  ";
                var groupStructurePromise;

                if (typeof data !== 'undefined' && typeof data !== null) {
                    if (data.groupId === 'undefined' && data.groupId === null)
                        data.groupId = $scope.rule.groupId;
                    groupStructurePromise = GroupsService.savePeopleGroup(data, status);
                }


                return groupStructurePromise;
            };

            _this.updateAssignments = function() {
                var data = angular.copy($scope.rows);
                var id = _this.params.param.split('/')[0];

                var assignmentsPromise;
                if (data !== undefined && data !== 'null')
                    assignmentsPromise = RulesService.updateRuleAssignments(id, data);

                return assignmentsPromise;
            };

            $scope.previewPeopleError = {};
            $scope.previewPeopleError.error = errorDisplayerUtil.create();
            $scope.previewPeopleIsLoading = false;

            $scope.previewPeople = function() {
                $scope.previewPeopleError.error.reset();
                $scope.previewPeopleIsLoading = true;
                var data = $scope.groupStructure;

                var previewPeoplePromise = GroupsService.getPeoplePreview(data);
                previewPeoplePromise.then(function(success) {
                    $scope.previewPeopleIsLoading = false;
                    $scope.showPreview = true;
                    //$scope.people_rows = GroupsService.mapUsersForPreview(success);
                    $scope.people_rows = success;

                }, function(failure) {
                    $scope.previewPeopleIsLoading = false;
                    $scope.showPreview = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.previewPeopleError.error.setError(errorCode, message);
                });

            };

            $scope.invalidPeopleError = {};
            $scope.invalidPeopleError.error = errorDisplayerUtil.create();
            $scope.invalidPeopleIsLoading = false;

            $scope.$on('invalidPeopleModalOpen', function (evt, params) {
                if (params[1] === "Warning") {
                    if (!$scope.tripleOr) {
                        $scope.tripleOr = true;
                        $("#invalidPeopleModal").modal();
                    }
                }

                message = params[0];
                $scope.invalidPeopleError.error.reset();
                $scope.invalidPeopleIsLoading = true;
                $scope.invalidPeopleIsLoading = false;
                var errorCode = 0;
                $scope.invalidTitle = params[1];
                $scope.invalidPeopleError.error.setError(errorCode, message);

            });


            // NEEDS MORE REFACTORING IN SOME PLACES 
            // WILL TAKE CARE AS MAKING PROGRESS TODO-@MANI

            $scope.activeRuleNodeClick = function() {
                if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "home") {
                    $scope.rows = _this.filterRows('active');
                } else {
                    $location.path('rules/home');
                }
            };

            $scope.inactiveRuleNodeClick = function() {
                if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "home") {
                    $scope.rows = _this.filterRows('closed');
                } else {
                    $location.path('rules/home');
                }
            };

            $scope.draftRuleNodeClick = function() {
                if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "home") {
                    $scope.rows = _this.filterRows('draft');
                } else {
                    $location.path('rules/home');
                }
            };

            $scope.templateRuleNodeClick = function() {
                if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "home") {
                    $scope.rows = _this.filterRows('template');
                } else {
                    $location.path('rules/home');
                }
            };

            _this.filterRows = function(status) {
                return _.filter(_this.allRulesData, function(row) {
                    return row.status === status;
                });
            };

            $scope.copyRuleError = {};
            $scope.copyRuleError.error = errorDisplayerUtil.create();
            $scope.copyRuleIsLoading = false;

            $scope.copyRuleModal = function() {
                $scope.copyRuleIsLoading = true;
                $scope.copyRuleError.error.reset();
                var selectedRules = RulesService.formatRuleForCopy($filter('filter')($scope.rows, { select: true }));
                if (selectedRules.length > 0) {
                    $scope.copyRuleIsLoading = false;
                    $scope.showCopyTable = true;
                    $scope.rows_In_CopyModal = selectedRules;
                } else {
                    $scope.copyRuleIsLoading = false;
                    $scope.showCopyTable = false;
                    var errorCode = "0";
                    var message = "There are no rules selected for copy!";
                    $scope.copyRuleError.error.setError(errorCode, message);
                }
            };

            $scope.copyRule = function() {
                $scope.copyRuleError.error.reset();
                $scope.copyRuleIsLoading = true;
                var data = angular.copy($scope.rows_In_CopyModal);

                for (var i in data) {
                    if (data[i].isAddYear && data[i].newEndDate !== null) {
                        //since we do not display newEndDate on UI, this condition will never be hit. (unless we default enddate to some value.)
                        //data[i].newEndDate = dateUtil.formatDate(data[i].newEndDate.moment.add(1, 'year'));
                    }
                }

                console.log($scope.rows_In_CopyModal);
                var copyRulePromise = RulesService.saveCopiedRule(data);
                copyRulePromise.then(function(success) {
                    $scope.copyRuleIsLoading = false;
                    $scope.showCopyTable = true;
                    jQuery('#copyRuleModal').modal('hide').on('hidden.bs.modal', $route.reload);
                }, function(failure) {
                    $scope.copyRuleIsLoading = false;
                    $scope.showCopyTable = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.copyRuleError.error.setError(errorCode, message);

                });
            };

            // Used in admin.editRule_People.html template to submit the export preview form.
            // This is because the export preview needs to bypass angular's $http service to return a downloaded file
            // so this posts the form to the server directly from the template instead of going through the group service.
            $scope.exportPeoplePreviewFormSubmit = function () {
                var data = $scope.groupStructure;
                var dataCopy = angular.copy(data);
                $scope.peopleGroupCriteriaToSend = JSON.stringify(GroupsService.formatGroupDataToSend(dataCopy));
                $scope.currentTimezone = moment.tz.guess();
            };


            // NEEDS MORE REFACTORING IN SOME PLACES 
            // WILL TAKE CARE AS MAKING PROGRESS TODO-@MANI

            /* ************************ Inactive Rules Tree View Actions ******************* */

            // Initialize the data to an empty array until we load the rules tress
            $scope.inactiveRuleData = [{ 'name': 'Closed', 'nodes': [] }];
            $scope.inactiveRuleOptions = {};
            $scope.inactiveRuleOptions.initiallyOpened = false;
            $scope.inactiveRuleOptions.rootNodeIsTopLevel = false;
            $scope.inactiveRuleOptions.canDrillDown = false;
            $scope.inactiveRuleTreeIsLoading = true;
            $scope.inactiveRuleNowHidden = true;
            $scope.inactiveRuleTitle = "Closed";

            $scope.inactiveRuleError = {};
            $scope.inactiveRuleError.error = errorDisplayerUtil.create();

            /* ************************ Active Rules Tree View Actions ******************* */

            $scope.draftRuleData = [{ 'name': 'Drafts', 'nodes': [] }];
            $scope.draftRuleOptions = {};
            $scope.draftRuleOptions.initiallyOpened = false;
            $scope.draftRuleOptions.rootNodeIsTopLevel = false;
            $scope.draftRuleOptions.canDrillDown = false;
            $scope.draftRuleTreeIsLoading = true;
            $scope.draftRuleNowHidden = true;
            $scope.draftRuleTitle = "Drafts";

            $scope.draftRuleRuleError = {};
            $scope.draftRuleRuleError.error = errorDisplayerUtil.create();

            /* ************************ Active Rules Tree View Actions ******************* */

            // Initialize the data to an empty array until we load the rules tress
            $scope.templateRuleData = [{ 'name': 'Templates', 'nodes': [] }];
            $scope.templateRuleOptions = {};
            $scope.templateRuleOptions.initiallyOpened = false;
            $scope.templateRuleOptions.rootNodeIsTopLevel = false;
            $scope.templateRuleOptions.canDrillDown = false;
            $scope.templateRuleTreeIsLoading = true;
            $scope.templateRuleNowHidden = true;
            $scope.templateRuleTitle = "Templates";

            $scope.templateRuleError = {};
            $scope.templateRuleError.error = errorDisplayerUtil.create();

            /* This makes coloumns of a table sortables */
            $scope.propertyName = 'name';
            $scope.reverse = false;

            $scope.requiredOptions = [{
                "name": "Yes",
                "value": true
            }, {
                "name": "No",
                "value": false
            }];

            $scope.dueDateOptions = [{
                "name": "Select",
                "value": "none"
            }, {
                "name": "Hire Date",
                "value": "hireDate"
            }, {
                "name": "Assigned On Date",
                "value": "assignedOnDate"
            }];

            $scope.dueDateMethods = [{
                "name": "Due Date",
                "value": 0
            }, {
                "name": "Calculated Due Date",
                "value": 1
            }];

            /* This will check all the rows in the table in that instance */
            /// TO DO //
            $scope.selectAll = {};
            $scope.checkAll = function() {
                _.each($scope.rows, function(row) {
                   row.select = $scope.selectAll.checked;
                });
            };

            $scope.pushInArray = function (user) {
                if ($scope.selectAll.checked) {
                    $scope.selectAll.checked = false;

                    var index = _.findIndex($scope.selectUsers, { "id": user.id });

                    if (user.select) {
                        if (index === -1) {
                            $scope.selectedUsers.push(angular.copy(user));
                        }
                    } else {
                        if (index !== -1)
                            $scope.selectedUsers.splice(index, 1);
                    }
                }
            };

            $scope.sortBy = function(propertyName) {
                $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                $scope.propertyName = propertyName;
            };

            $scope.ruleCreate_Step = function(nextTabName) {
                var nextTabName1 = nextTabName;
                _this['create_' + nextTabName1 + 'Action']();
            };

            $scope.treeOptions = {
                nodeChildren: 'nodes',
                templateUrl: 'tree.html',
                injectClasses: {
                    ul: "a1",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"
                }
            };

            $scope.setStartTime = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null &&
                    typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                        var portalStartTime = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                        dateObj = dateUtil.formatDate(portalStartTime);
                    } else {
                        dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                    }
                    _this.getAssigmentsForDateValidation();
                    return dateObj;
                } else {
                    return null;
                }
            };

            $scope.setEndTime = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null &&
                    typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                        var portalEndTime = dateUtil.setPortalEndTime(dateObj.moment.toDate());
                        dateObj = dateUtil.formatDate(portalEndTime);
                    } else {
                        dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                    }
                    _this.getAssigmentsForDateValidation();
                    return dateObj;
                } else {
                    return null;
                }
            };

            _this.getAssigmentsForDateValidation = function () {
                //If changing the start date or end date, need to make sure that the 
                //courses with their assign on date is in the scope so that we can do the date validation for assign on
                if ($scope.rows === undefined && _this.params.name === 'edit') {
                    var ruleId = _this.params.param.split('/')[0];
                    _this.getRuleAssignments(ruleId);
                }
            };


            // SLY-5342 Sub domain management for rules admin tab
            $scope.isSubDomainAdmin = UsersService.isSubDomainAdmin();
            $scope.taDomains = UsersService.taDomains();
            $scope.activeDomain = UsersService.activeDomain();
            $scope.switchDomains = {};
            $scope.switchDomains.error = errorDisplayerUtil.create();

            $scope.onActiveDomainChanged = function (activeDomain, oldDomain) {
                var switchProfilePromise = UsersService.switchProfile(activeDomain.id);
                switchProfilePromise.then(function (success) {
                    location.reload();
                }, function (failure) {
                    $scope.switchDomains.error.setError(failure.StatusCode, failure.ErrorMessage);
                    jQuery("#switchDomainsError").show();
                    setTimeout(function () {
                        $scope.switchDomains.error.reset();
                        jQuery("#switchDomainsError").hide();
                    }, 2000);
                });
            };

            /******************START - Rule History ***************************************/
       
            $scope.showGeneralHistory = false;
            $scope.generalHistory = [];
            $scope.showCourseHistory = false;
            $scope.courseHistory = [];
            $scope.showCourseDateHistory = false;
            $scope.courseDateHistory = [];
            $scope.showPeopleGroupHistory = false;
            $scope.peopleGroupHistory = [];
            $scope.home = {};
            $scope.home.page = 1;
            $scope.home.limit = 10;
            $scope.home.total = 0;
            $scope.home.maxVisiblePages = 10;

            $scope.home.nextPage = function (ruleStep) {

                switch (ruleStep) {
                    case 'RuleGeneralInformation':
                        $scope.toggleHistory(true, "RuleGeneralInformation");
                        break;
                    case 'RuleCourse':
                        $scope.toggleHistory(true, "RuleCourse");
                        break;
                    case 'RuleCourseDates':
                        $scope.toggleHistory(true, "RuleCourseDates");
                        break;
                    case 'RulePeopleGroup':
                        $scope.toggleHistory(true, "RulePeopleGroup");
                        break;
                }

            };

            _this.calculatePages = function (totalCount) {
                $scope.home.total = totalCount;
            };


            $scope.toggleHistory = function (showHistory, ruleStep) {
                var ruleId = _this.params.param.split('/')[0];
                if (ruleStep === 'RulePeopleGroup') {
                    //People Group gets auditing by the groupID
                    ruleId = $scope.rule.groupId;
                }

                switch (ruleStep) {
                    case 'RuleGeneralInformation':
                        $scope.showGeneralHistory = showHistory;
                        break;
                    case 'RuleCourse':
                        $scope.showCourseHistory = showHistory;
                        break;
                    case 'RuleCourseDates':
                        $scope.showCourseDateHistory = showHistory;
                        break;
                    case 'RulePeopleGroup':
                        $scope.showPeopleGroupHistory = showHistory;
                        break;
                }
                 
                if (showHistory && ((ruleStep === 'RuleGeneralInformation' ) || (ruleStep === 'RuleCourse' ) || (ruleStep === 'RuleCourseDates' ) || (ruleStep === 'RulePeopleGroup'))) {
                 
                    $scope.loadingHistory = true;

                    var hist = RulesService.getRuleHistoryByStep(ruleId, ruleStep, $scope.home.page, $scope.home.limit);
                    hist.then(function (success) {

                        $scope.loadingHistory = false;
                        switch (ruleStep) {
                            case 'RuleGeneralInformation':
                                $scope.generalHistory = success;
                                _this.calculatePages(success[0].totalRecords);
                                break;
                            case 'RuleCourse':
                                $scope.courseHistory = success;
                                _this.calculatePages(success[0].totalRecords);
                                break;
                            case 'RuleCourseDates':
                                $scope.courseDateHistory = success;
                                _this.calculatePages(success[0].totalRecords);
                                break;
                            case 'RulePeopleGroup':
                                $scope.peopleGroupHistory = success;
                                _this.calculatePages(success[0].totalRecords);
                                break;
                        }


                    }, function (failure) {
                        $scope.loadingHistory = false;
                        $scope.home.total = 0;

                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        $scope.editRulePeopleError.error.setError(errorCode, message);
                    });

                }

            };

            $scope.resetHistory = function () {
                $scope.showGeneralHistory = false;
                $scope.generalHistory = [];
                $scope.showCourseHistory = false;
                $scope.courseHistory = [];
                $scope.showCourseDateHistory = false;
                $scope.courseDateHistory = [];
                $scope.showPeopleGroupHistory = false;
                $scope.peopleGroupHistory = [];
            };
            
            /******************END - Rule History ***************************************/

        };
    }
]);
//