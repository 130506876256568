app.directive('imageExists', function() {
    return {
        restrict: 'AEC',
        scope: {
            imageExists: '='
        },
        link: function(scope, element, attrs) {
            element.on('error', function() {
                jQuery(element).prop('src', _getDefaultImage(attrs.imageExists));
            });

            var _getDefaultImage = function(type) {
                switch(type) {
                    case 'catOnly':
                        return 'app/images/cat0000.jpg';
                    case 'icons-white':
                        return 'app/images/icons/default_white.svg';
                    case 'icons-black':
                        return 'app/images/icons/default_black.svg';
                    case 'catalog-tile':
                        return 'app/images/catalog/default.png';
                    default:
                        return 'app/images/00000.png';
                }
            };
        }
    };
});
