/* This controller displays the successful/unsuccessful certificates */
app.controller('assignmentsTaController', ['$sce', '$scope', '$route', '$window', 'RoleMapsMixin', 'UsersService', 'BreadcrumbsService', function ($sce, $scope, $route, $window, RoleMapsMixin, UsersService, BreadcrumbsService) {
    var currentMethod = $route.current.params.name;

    var _this = this;

    $scope.courseFailure = false;
    $scope.isLoading = true;

    $scope.user = UsersService;

    $scope.homeAction = function() {};

    $scope.dump = function(data) { console.warn(data); };


    /**** Load role specific functionality ****/
    /* This function takes the scope object
     * and overrides anything specific to the current page
     * or role.
     */
    RoleMapsMixin.overrideScope($scope);

    /* Call route action like homeAction here.
     * Return 404 if action doesn't exist after
     * injecting mixin. For example, if a certain role
     * doesn't have an ILT calendar, they will get a 404
     * Not every role needs every page.
     * Please follow the routing structure; non-alphanumeric characters should not be used.
     */
    $scope[currentMethod + 'Action']();
}]);
