catalog.directive('catalogLibraryTiles', ['$route', '$q', 'errorDisplayerUtil', 'CategoriesService', 'SearchServices', 'BreadcrumbsService', 'CatalogSearchParameters',
    function ($route, $q, errorDisplayerUtil, CategoriesService, SearchServices, BreadcrumbsService, CatalogSearchParameters) {
    return {
        restrict: 'E',
        templateUrl: 'App/templates/catalog/catalogLibraryTiles.html',
        scope: {
        },
        controller: ['$scope', '$route', '$attrs', '$location', '$q', '$rootScope', 'errorDisplayerUtil', 'CategoriesService', 'SearchServices', 'BreadcrumbsService', 'PathFormatter', 'CatalogSearchParameters',
            function ($scope, $route, $attrs, $location, $q, $rootScope, errorDisplayerUtil, CategoriesService, SearchServices, BreadcrumbsService, PathFormatter, CatalogSearchParameters) {
                $scope.fromWeb = $rootScope.fromWeb;
                var catalogBreadcrumbs = [];

                $scope.catalogType = typeof $attrs.catalogtype !== 'undefined' ? $attrs.catalogtype : 'Libraries';


            $scope.isLoading = false;
            $scope.selected = {};
            $scope.catalog = {};
            $scope.catalog.name = $scope.catalogType;
            $scope.catalog.description = "BAI offers several content libraries to meet your training needs.";
            $scope.catalog.path = { pipeDelimited: "", params: "" };
            $scope.catalog.error = errorDisplayerUtil.create();
            $scope.catalog.baseRoute = $scope.fromWeb ? "browse" : "catalog";
            if ($scope.catalogType === "Documents") { $scope.catalog.baseRoute = "documents-catalog";}

            $scope.level = "";
          
            function loadCatalog() {
               
                $scope.isLoading = true;
                var allPromises = [];
                var categories = CategoriesService.fetch(null, false, false);
                allPromises.push(categories);
                var catalog = null;
                if ($scope.catalogType === "Documents")
                {
                    catalog = SearchServices.getDocumentCatalog();
                }
                else
                {
                    catalog = SearchServices.getCatalog();
                }
                allPromises.push(catalog);
                $q.all(allPromises).then(function (success) {
                    $scope.catalog.children = formatCategories(success[0], { pipeDelimited: "", params: "" }, success[1]);
                    $scope.selected = $scope.catalog;
                    if ($scope.fromWeb === true) {
                        handleRouteParamsWeb($route.current);
                        if ($scope.selected.name.toLowerCase() === "libraries") {
                            $scope.catalog.description = "BAI offers several content libraries to meet your training needs.";
                            $rootScope.metaTags.title = "BAI Training Catalog Libraries, Compliance, Learning Management, Professional Development Financial Services";
                            $rootScope.metaTags.description = "BAI gives financial services leaders confidence in managing compliance and a passion for professional development by providing powerful tools and subject matter expertise you can rely on. Search our courseware catalog!";
                            $rootScope.metaTags.keywords = "BSA, AML, eLearning, Role-based, Microlearning, Regulation-based, FDIC, CFPB, Banking, Credit Union, Mortgage, Fintech, Regtech, HMDA, OFAC, TILA";
                        } else {
                            $rootScope.metaTags.title = "BAI Training Catalog: " + $scope.selected.name;
                            $rootScope.metaTags.description = $scope.selected.description;
                        }
                    } else {
                        handleRouteParams($route.current);
                    }
                    $scope.isLoading = false;
                }, function (failure) {
                    var errorCode;
                    var errorMsg = "Could not retrieve the catalog.";
                    $scope.catalog.error.setError(errorCode, errorMsg);
					$scope.isLoading = false;
                });
            }
            
            loadCatalog();

            function formatCategories(categories, path, catalog) {
                var results = [];
                _.each(categories, function (category) {
                    if(category.endDate === null){
                        category.productCount = 0;
                        category.name = category.name.replace(" (Policies)", "");
                        category.path = formatPath(path, category.name);
                        //always put at the end
                        if (category.name.toLowerCase() === "custom content") {
                            category.ordinal = 999;
                        }
                        
                        //Also putting custom documents at the end
                        if ($scope.catalogType === "Documents" && category.name.toLowerCase() === "custom documents") {
                            category.ordinal = 999;
                        }
                        var azureFacet = _.findWhere(catalog, { value: category.path.pipeDelimited });
                        if (typeof azureFacet !== 'undefined') {
                            category.productCount = azureFacet.count;
                            if (category.productCount > 0) {
                                category.childCount = category.children === null ? 0 : category.children.length;
                                if (category.childCount > 0) {
                                    category.children = formatCategories(category.children, category.path, catalog);
                                    category.childCount = category.children.length;
                                }
                                results.push(category);
                            }
                        }
                    }
                });

                if (path.pipeDelimited.toLowerCase().startsWith("custom content")) {
                    return results.sort(compareByName);
                }
                else {
                    return results.sort(compareByOrdinal);
                }
            }

            function compareByOrdinal(a, b) {
                return a.ordinal > b.ordinal ? 1 : -1;
            }

            function compareByName(a, b) {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            }


            function formatPath(parentPath, name) {
                var path = {};
                path.pipeDelimited = formatPathPipeDelimited(parentPath.pipeDelimited, name);
                path.params = PathFormatter.formatPathParamsWithEncode(parentPath.params, name);
                
                return path;
            }

            function formatPathPipeDelimited(parentPath, name) {
                return parentPath === "" ? name : parentPath + '|' + name;
            }
            
            function handleRouteParamsWeb(route) {
                var selected = $scope.catalog;
               catalogBreadcrumbs = [];
               catalogBreadcrumbs.push({ name: "Home", link: "/" });
               catalogBreadcrumbs.push({ name: $scope.catalogType, link: $scope.catalog.baseRoute });
                BreadcrumbsService.crumbs =catalogBreadcrumbs;
                if (typeof route.params.param === 'undefined') {
                    $scope.level = getLevel(selected);
                    return;
                }

                var params = route.params.param.split("/");

                var path = "";
                _.each(params, function (param) {
                    path = PathFormatter.formatPathParamsWithEncode(path, param);
                    selected = _.filter(selected.children, function (category) { return category.path.params === path; })[0];
                    if (typeof selected === 'undefined') {
                        $scope.catalog.error.setError(404, "Catalog selection not found: " + path);
                        selected = $scope.catalog;
                        return;
                    }
                });
                $scope.selected = selected;
                $scope.level = getLevel(selected);

                catalogBreadcrumbs = catalogBreadcrumbs.concat(BreadcrumbsService.createBreadcrumbs(selected.path.pipeDelimited, $scope.fromWeb, $scope.catalog.baseRoute, $scope.catalogType));
                BreadcrumbsService.crumbs =catalogBreadcrumbs;
            }

            function handleRouteParams(route) {
                var selected = $scope.catalog;
               catalogBreadcrumbs = [];
                if (typeof route.params.name === 'undefined') {
                    $scope.level = getLevel(selected);
                    return;
                }

                var params = [route.params.name];
                if (typeof route.params.param !== 'undefined') {
                    params = params.concat(route.params.param.split("/"));
                }
                
                var path = "";
                _.each(params, function (param) {
                    path = PathFormatter.formatPathParamsWithEncode(path, param);
                    selected = _.filter(selected.children, function (category) { return category.path.params === path; })[0];
                    if (typeof selected === 'undefined') {
                        $scope.catalog.error.setError(404, "Catalog selection not found: " + path);
                        selected = $scope.catalog;
                        return;
                    }
                });
                $scope.selected = selected;
                $scope.level = getLevel(selected);
                catalogBreadcrumbs = BreadcrumbsService.createBreadcrumbs(selected.path.pipeDelimited, $scope.fromWeb, $scope.catalog.baseRoute, $scope.catalogType);
                BreadcrumbsService.crumbs =catalogBreadcrumbs;
            }

            function getLevel(category) {
                if (category.path.pipeDelimited === "") {
                    $scope.showExportLinks = false;
                    return { singular: "Category", plural: "Categories" };
                }
                else {
                    /* only show export links on the parent taxon view */
                    $scope.showExportLinks = ($scope.catalogType === "Documents" || category.path.pipeDelimited.indexOf('|') >= 0 || category.path.pipeDelimited.indexOf('Custom') >= 0 || category.path.pipeDelimited.indexOf('NMLS') >= 0) ? false : true;
                    return { singular: "Subcategory", plural: "Subcategories" };
                }
            }
        }]
    };
}
]);