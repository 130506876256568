app.service("BillingService", [
  "$http",
  function ($http) {
    var _this = this;

    _this.singleResponse = null;

    _this.getOverdueInvoices = function () {
      var url = "api/billing/invoice/overdue";

      var req = {
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        timeout: window.timeout,
      };

      return $http(req).then(
        function (success) {
          return _this.formatInvoices(success.data);
        },
        function (failure) {
          throw failure;
        }
      );
    };

    _this.getInvoicePDF = function(invoiceId) {
      var url = "api/billing/invoice/" + invoiceId + "/pdf";
      var req = {
        method: "GET",
        url: url,
        timeout: window.timeout,
        responseType: 'arraybuffer'
      };

      return $http(req).then(
        function (success) {
          return success.data;
        },
        function (failure) {
          throw failure;
        }
      );

    };

    _this.formatInvoices = function(invoices){
      var totalOverdue = 0;
      for(var i = 0; i < invoices.length; i++){
        totalOverdue += invoices[i].totalDue; 
        invoices[i].dateDue = new Date(invoices[i].dateDue).toLocaleDateString();
        invoices[i].totalDue = _this.currencyFormatter.format(invoices[i].totalDue);
      }
      return { overdueInvoices: invoices, totalOverdue: totalOverdue, totalOverdueFormatted: _this.currencyFormatter.format(totalOverdue) };
    };

    _this.currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  },
]);
