(function () {
    app.directive('assignmentActionButton', [
        function () {
            return {
                restrict: 'E',
                scope: {
                    assignmentPromises: '=',
                    productId: '='
                },
                templateUrl: 'App/templates/studentui/assignmentActionButton.html',
                controller: 'actionButtonController'
            };
        }
    ]);
})();