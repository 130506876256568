app.factory('Insights_AdminOverride', ['$httpParamSerializer', '$injector', '$location', '$q', '$route', '$timeout', '$filter', 'RoleMapsMixin', 'InsightsService', 'CoursesService', 'errorDisplayerUtil', 'UsersService', 'InsightsDecisionMapService', 'OrganizationsService', 'SettingsService',
function ($httpParamSerializer, $injector, $location, $q, $route, $timeout, $filter, RoleMapsMixin, InsightsService, CoursesService, errorDisplayerUtil, UsersService, InsightsDecisionMapService, OrganizationsService, SettingsService) {
    return function ($scope) {

        var _this = this;

        $scope.defaultHeader = true; // This Sets the page to full width of the screen.
        $scope.hideCatalogSearch = true;
        /* This will make the navigation tab active on certain page */
        $scope.activeTab = 'tab-active-class';

        /*This will route users to the appropiate user manual based on active tab*/
        $scope.userManualHref = 'https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Training-Insights';

        var sett = SettingsService.getSettings().then(function (success) {
            /* Training Insights access determined by the organization settings */
            $scope.showTrainingInsights = SettingsService.isTrainingInsightsEnabled();
            $scope.showCareerPath = SettingsService.isCareerPathEnabled();
            if ($scope.showTrainingInsights) {
                $scope.homeTemplate = 'app/templates/insights/partials/admin.content.html';
            }
        });
        

        /* get the decisionMapID from the URL, save in the current scope */
        $scope.decisionMapID = 0;
        _this.commentSection = '';
        $scope.decisionMap = [];
        $scope.criteriaDataPoint = '';
        $scope.decisionMakerName = '';



        $scope.homeAction = function () {
            $scope.contentTemplate = 'app/templates/insights/home/admin.html';
        };

        $scope.createdecisionmapAction = function () {
            $scope.contentTemplate = 'app/templates/insights/mappingdecisions/createandstart.html';
        };

        $scope.decisionmapcoursesAction = function () {
            var params = $route.current.params.param.split('/');
            $scope.decisionMapID = params[0];

            $scope.contentTemplate = 'app/templates/insights/mappingdecisions/courses.html';        
        };

        $scope.wizardAction = function () {
            var params = $route.current.params.param.split('/');
            $scope.currentWizardStep = params[0];
            $scope.contentTemplate = "app/templates/insights/mappingdecisions/wizard/wizardContainer.html";
        };

        $scope.decisionmapreportAction = function() {
            var params = $route.current.params.param.split('/');
            $scope.decisionMapID = params[0];
            $scope.contentTemplate = 'app/templates/insights/mappingdecisions/report.html';
        };

        $scope.createNewDecisionMap = function () {
            InsightsDecisionMapService.reset();
            $location.path("insights/createdecisionmap");
        };
        $scope.openVideo = function (src, new_page_title) {

            var new_window = window.open('', '_blank', 'location=1,status=1,toolbar=1,scrollbars=1,resizeable=1,width=1000,height=800');
            new_window.document.write('<!doctype html><html><head><title>' + new_page_title + '</title><meta charset="UTF-8" /></head><body><video controls style="width: 100%; height: auto;"><source src="' + src + '" target="_blank" type="video/mp4" /></video></body></html>');

        };
    };
}]);

