catalog.directive('downloadCatalogLinks', ['$route', '$q', '$timeout',
    function ($route, $q, $timeout) {
        return {
            restrict: 'E',
            templateUrl: 'App/templates/catalog/downloadCatalogLinks.html',
            scope: {
                catalogId: '@'
            },
            controller: ['$scope', '$route', '$rootScope', '$q',
                function ($scope, $route, $rootScope, $q) {
                    
                    /* for the API URL based on if the user is coming from the web or not */
                    if ($rootScope.fromWeb) {
                        $scope.catalogAction = '/api/public/catalog/download/';
                        $scope.catalogListAction = '/api/public/catalog/download/list/';
                    } else {
                        $scope.catalogAction = '/lms-web/api/catalog/download/';
                        $scope.catalogListAction = '/lms-web/api/catalog/download/list/';
                    }                   

                    /* hide button functionality for catalog export */
                    $scope.exportCatalogLoading = false;
                    $scope.exportCatalog = function () {
                        $scope.exportCatalogLoading = true;

                        $timeout(function () {
                            $scope.exportCatalogLoading = false;
                        }, 3000);
                    };
                    
                    /* hide button functionality for List export */
                    $scope.exportListLoading = false;
                    $scope.exportCatalogList = function () {
                        $scope.exportListLoading = true;

                        $timeout(function () {
                            $scope.exportListLoading = false;
                        }, 9000);
                    };

                }]
        };
    }
]);