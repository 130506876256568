app.directive('documentUpload', [function () {
    return {
        restrict: 'E',
        scope: {
            files: '=',
            courseType: '=',
            launchWithContent: '='
        },
        templateUrl: 'app/templates/components/documentUpload.html',
        controller: ['$scope', '$rootScope', '$filter', '$route', '$q', 'errorDisplayerUtil',  'CoursesService', 'dateUtil', 'CatalogService', 'DocumentService',
            function ($scope, $rootScope, $filter, $route, $q, errorDisplayerUtil, CoursesService, dateUtil, CatalogService, DocumentService) {


                //Document Upload
                var _this = this;

                // Stores a list of files to be uploaded to the server
                $scope.files = [];
                $scope.files.deletedFiles = [];

                $scope.addDocumentToCourseError = {};
                $scope.addDocumentToCourseError.error = errorDisplayerUtil.create();

                $scope.documentCourseList = {};
                $scope.documentCourseList.error = errorDisplayerUtil.create();

                _this.params = $route.current.params;

                if (_this.params.name === "edit" && _this.params.param !== 'undefined' && _this.params.param !== null) {
                    var courseId = _this.params.param.split('/')[0];

                    DocumentService.getDocumentsForCourse(courseId).then(function (success) {
                        $scope.documentCourseList.error.reset();
                        $scope.files = success;
                        $scope.files.deletedFiles = [];

                        //With the given resourceID, set the launchWithContent flag
                        $scope.files.forEach(function (f) {
                            if (f.id === $scope.launchWithContent) {
                                f.launchWithContent = true;
                            }
                        });

                    }, function (failure) {
                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        $scope.documentCourseList.error.setError(errorCode, message);
                    });
                }

                $scope.setResourceLaunchWithContent = function (resource) {
                $scope.launchWithContent = resource.id;
                $scope.files.forEach(function (f) {
                    if (f.launchWithContent === true && resource === f) {
                        f.launchWithContent = false;
                        $scope.launchWithContent = 0;
                    }
                    else
                        f.launchWithContent = resource === f;
                });
            };

            ///////// DELETION
            /* Deletes a file or url when you click the 
             * x button */
            $scope.deleteFile = function (id) {

                for (var i = 0; i < $scope.files.length; i++) {
                    if ($scope.files[i].id === id) { $scope.files.splice(i, 1); i = $scope.files.length + 1; }
                }

                //Add the fileID to the deleted files array to delete upon save
                var delFile = {};
                delFile.id = id;
                $scope.files.deletedFiles.push(delFile);
                if ($scope.hideDropdownIn) { $scope.btnDisabled = false; }

                //If a deleted file was set to launch with content, then remove that content launch flag
                if (id === $scope.launchWithContent) {
                    $scope.launchWithContent = 0;
                }
                };

                $scope.addNewDocumentCta = function () {
                    $scope.$broadcast('openAddAssignmentsModal', { displaytype: 2 });
                };
                    //Document upload finish        
                // asignement model
                $rootScope.addDocumentsWithLibrary = {};

                $rootScope.addDocumentsWithLibrary.error = errorDisplayerUtil.create();

                /**
                 * Fix for 2403. Since JS is loose, not defining an attribute on an object returns undefined, which resolves to false,
                 * which is bad for us, since we need it to resolve to true later.
                 */
                $rootScope.addDocumentsWithLibrary.initialIsLoading = true;
                $rootScope.addDocumentsWithLibrary.initialFailed = false;

                $rootScope.addDocumentsWithLibrary.searchCatalog = Array();
                $rootScope.addDocumentsWithLibrary.searchLibraries = Array();
                $rootScope.addDocumentsWithLibrary.searchCategories = Array();
                $rootScope.addDocumentsWithLibrary.searchFields = Array();
                $rootScope.dueDates = {};

                $scope.$on('openAddAssignmentsModal', function (evt, params) {
                    jQuery('#addAssignmentsWithLibraryModal').modal();
                    
                    if ($rootScope.addDocumentsWithLibrary.searchCatalog.length === 0) {
                        var coursePromises = [];
                        coursePromises.push(CatalogService.getBaiCatalogData()); // Used for catalog info
                        coursePromises.push(DocumentService.getDocumentRepositoryList()); // Used as course list, filtered using 2 above
                        $q.all(coursePromises).then(function (coursesData) {
                            var catalogTree = coursesData[0];
                            $rootScope.addDocumentsWithLibrary.searchCatalog = coursesData[1];
                            _.each(catalogTree.formattedCatalogTreeData, function (catalog) {
                                $scope.assignCatalog(catalog, catalog.nodeId, catalog.name);
                            });
                            // Create unique lists of catalogs and course types for dropdowns
                            _.each($rootScope.addDocumentsWithLibrary.searchCatalog, function (c) {
                                if (c.rootNodeId !== null && typeof c.rootNodeId !== 'undefined' && _.findIndex($rootScope.addDocumentsWithLibrary.searchLibraries, { "nodeId": c.rootNodeId }) === -1 && c.productType === "Document") {
                                    $rootScope.addDocumentsWithLibrary.searchLibraries.push({ "nodeId": c.rootNodeId, "name": c.rootNodeDescription });
                                }
                                if (_.findIndex($rootScope.addDocumentsWithLibrary.searchCategories, { "friendlyName": $filter('assignmentAssetTypeName')(c.type) }) === -1) {
                                    $rootScope.addDocumentsWithLibrary.searchCategories.push({ "type": c.type, "friendlyName": $filter('assignmentAssetTypeName')(c.type) });
                                }
                            });
                            $rootScope.addDocumentsWithLibrary.searchFields = CoursesService.getSearchableCatalog();
                            $rootScope.addDocumentsWithLibrary.initialIsLoading = false;
                            $rootScope.addDocumentsWithLibrary.selectAll = false;
                            $rootScope.catalogDropdownValues = _.sortBy($rootScope.catalogDropdownValues, "name");
                            $rootScope.courseTypeDropdownValues = _.sortBy($rootScope.courseTypeDropdownValues, "type");
                            $rootScope.courseTypeDropdownValues = $rootScope.courseTypeDropdownValues.reverse();
                        }, function (failure) {
                            $rootScope.coursesApiAreLoading = false;
                            var errorCode = failure.StatusCode;
                            var message = failure.ErrorMessage;
                            $rootScope.viewCoursesError.error.setError(errorCode, message);
                        });

                    }
                    else {
                        $rootScope.addDocumentsWithLibrary.initialIsLoading = false;
                    }
                });

                $scope.assignCatalog = function (node, rootId, rootDescription) {
                    var nodeId = node.nodeId;
                    _.each($rootScope.addDocumentsWithLibrary.searchCatalog, function (course) {
                        if (course.catalogId === nodeId) {
                            course.rootNodeId = rootId;
                            course.rootNodeDescription = rootDescription;
                        } else if (course.catalogId === null) {
                            course.rootNodeId = 0;
                            course.rootNodeDescription = "Custom Catalog";
                        }
                    });

                    if (node.nodes) {
                        _.each(node.nodes, function (childNode) {
                            $scope.assignCatalog(childNode, rootId, rootDescription);
                        });
                    }
                };


                /* Stores assignments */
                // This one stores the list of documents in the
                // search page
                $rootScope.addDocumentsWithLibrary.searchResults = Array();
                $rootScope.addDocumentsWithLibrary.queuedDocuments = {};
                $rootScope.addDocumentsWithLibrary.selectAll = false;
                // Used on due date tab. keeps list of documents that have been unchecked
                // If the user goes to the first or last tabs, this variable will be used to remove assignments
                // from the list of queued assignments
                // Used in same manner as $rootScope.addAssignmentsWithLibrary.queuedAssignments except it's the opposite
                $rootScope.addDocumentsWithLibrary.documentsToggledForRemoval = {};

                $rootScope.addDocumentsWithLibrary.clearAddDocumentsModal = function () {
                    for (i = 0; i < $rootScope.addDocumentsWithLibrary.searchResults.length; i++) {
                        $rootScope.addDocumentsWithLibrary.searchResults[i].checked = false;
                    }
                    $rootScope.addDocumentsWithLibrary.queuedDocuments = {};
                    $rootScope.addDocumentsWithLibrary.documentsToggledForRemoval = {};
                    $rootScope.addDocumentsWithLibrary.keyword = '';
                    $rootScope.addDocumentsWithLibrary.library = null;
                    $rootScope.addDocumentsWithLibrary.contentType = null;
                    $rootScope.addDocumentsWithLibrary.selectAll = false;
                    $rootScope.addDocumentsWithLibrary.searchResults = Array();
                    $rootScope.addDocumentsWithLibrary.RESULTS_FOUND = false;
                    $rootScope.addDocumentsWithLibrary.autoOpenedList = false;
                    $rootScope.addDocumentsWithLibrary.noDocumentsSelectedError = false;
                    $rootScope.addDocumentsWithLibrary.anySelected = false;
                    $rootScope.addDocumentsWithLibrary.selectAll = false;
                    $rootScope.addDocumentsWithLibrary.selectedCount = 0;
                    $rootScope.addDocumentsWithLibrary.error.reset();
                };

                /* Used for storing search data for filtering assignments at top of modal */
                $rootScope.addDocumentsWithLibrary.keyword = '';

                // Keeps track of whether we should disable the next/submit button
                $rootScope.addDocumentsWithLibrary.buttonDisabled = false;

                // This function goes through every document toggled for removal
                // in the assignmentsToggledForRemoval variable
                // and removes it from the list of queuedAssignments

                var deleteDocumentsToggledForRemoval = function (queuedDocuments, documentsToggledForRemoval) {
                    var documentsToRemoveIds = Object.keys(documentsToggledForRemoval);

                    for (var i = 0; i < documentsToRemoveIds.length; i += 1) {
                        if (documentsToRemoveIds[i] in queuedDocuments) {
                            delete queuedDocuments[documentsToRemoveIds[i]];
                        }
                    }
                };               

                $rootScope.addDocumentsWithLibrary.searchDocuments = function (keyword) {
                    return $q(function (resolve, reject) {
                        // If no search criteria, return nothing
                        if ((typeof keyword === 'undefined' || keyword === null || keyword === "") &&
                            ($rootScope.addDocumentsWithLibrary.library === null || typeof $rootScope.addDocumentsWithLibrary.library === 'undefined' || $rootScope.addDocumentsWithLibrary.library === "") &&
                            ($rootScope.addDocumentsWithLibrary.contentType === null || typeof $rootScope.addDocumentsWithLibrary.contentType === 'undefined' || $rootScope.addDocumentsWithLibrary.contentType === "")) {
                            $rootScope.addDocumentsWithLibrary.searchResults = null;
                            resolve(null); // Don't return anything
                        }

                        var checkKeyword = false;
                        if (typeof keyword !== 'undefined' && keyword !== null && keyword !== "") {
                            var stubResults = $filter('filter')($rootScope.addDocumentsWithLibrary.searchFields, keyword);
                            $rootScope.addDocumentsWithLibrary.searchResults = $filter('filter')($rootScope.addDocumentsWithLibrary.searchCatalog, function (course) {
                                for (i = 0; i < stubResults.length; i++) {
                                    if (course.code === stubResults[i].code) {
                                        return course;
                                    }
                                }
                            });
                            checkKeyword = true;
                        }

                        var checkCatalog = false;
                        if ($rootScope.addDocumentsWithLibrary.library !== null && typeof $rootScope.addDocumentsWithLibrary.library !== 'undefined' && $rootScope.addDocumentsWithLibrary.library !== "") {
                            checkCatalog = true;
                            if (checkKeyword) {
                                $rootScope.addDocumentsWithLibrary.searchResults = $filter('filter')($rootScope.addDocumentsWithLibrary.searchResults, function (course) {
                                    if (course.rootNodeDescription === $rootScope.addDocumentsWithLibrary.library.name) {
                                        return course;
                                    }
                                });
                            } else {
                                $rootScope.addDocumentsWithLibrary.searchResults = $filter('filter')($rootScope.addDocumentsWithLibrary.searchCatalog, function (course) {
                                    if (course.rootNodeDescription === $rootScope.addDocumentsWithLibrary.library.name) {
                                        return course;
                                    }
                                });
                            }
                        }
                        if ($rootScope.addDocumentsWithLibrary.contentType !== null && typeof $rootScope.addDocumentsWithLibrary.contentType !== 'undefined' && $rootScope.addDocumentsWithLibrary.contentType !== "") {
                            checkType = true;
                            if (checkKeyword || checkCatalog) {
                                $rootScope.addDocumentsWithLibrary.searchResults = $filter('filter')($rootScope.addDocumentsWithLibrary.searchResults, function (course) {
                                    if (course.type === $rootScope.addDocumentsWithLibrary.contentType.type) {
                                        return course;
                                    }
                                });
                            } else {
                                $rootScope.addDocumentsWithLibrary.searchResults = $filter('filter')($rootScope.addDocumentsWithLibrary.searchCatalog, function (course) {
                                    if (course.type === $rootScope.addDocumentsWithLibrary.contentType.type) {
                                        return course;
                                    }
                                });
                            }
                        }
                        if ($rootScope.addDocumentsWithLibrary.searchResults === null || $rootScope.addDocumentsWithLibrary.searchResults.length === 0) {
                            $rootScope.addDocumentsWithLibrary.NO_RESULTS = true;
                        } else {
                            $rootScope.addDocumentsWithLibrary.RESULTS_FOUND = true;
                            $rootScope.addDocumentsWithLibrary.NO_RESULTS = false;
                        }
                    });

                };

                $rootScope.addDocumentsWithLibrary.selectedCount = 0;


                // When you select a document in the search tab, it gets
                // added to the list of queued assignments here
                $rootScope.addDocumentsWithLibrary.queueDocument = function (document) {
                    if (document.id in $rootScope.addDocumentsWithLibrary.queuedDocuments) {
                        delete $rootScope.addDocumentsWithLibrary.queuedDocuments[document.id];
                        $rootScope.addDocumentsWithLibrary.selectedCount--;
                        $rootScope.addDocumentsWithLibrary.selectAll = false;
                        if (Object.keys($rootScope.addDocumentsWithLibrary.queuedDocuments).length === 0) {
                            $rootScope.addDocumentsWithLibrary.noDocumentsSelectedError = true;
                            $rootScope.addDocumentsWithLibrary.anySelected = false;
                        }
                    } else {
                        $rootScope.addDocumentsWithLibrary.queuedDocuments[document.id] = document;
                        $rootScope.addDocumentsWithLibrary.selectedCount++;
                        if ($rootScope.addDocumentsWithLibrary.selectedCount === $rootScope.addDocumentsWithLibrary.searchResults.length) {
                            $rootScope.addDocumentsWithLibrary.selectAll = true;
                        }
                        // if you've selected something then you shouldn't see this error
                        $rootScope.addDocumentsWithLibrary.noDocumentsSelectedError = false;
                        $rootScope.addDocumentsWithLibrary.anySelected = true;
                    }
                };

                /*** due date tab stuff */
                // when you click remove, remove the document from the list
                $rootScope.addDocumentsWithLibrary.toggleDocumentForRemoval = function (document) {
                    if (document.id in $rootScope.addDocumentsWithLibrary.queuedDocuments) {
                        //document is removed so deselect it, if they go back, they can reselect the document if they want
                        document.checked = false;
                        $rootScope.addDocumentsWithLibrary.selectedCount--;
                        $rootScope.addDocumentsWithLibrary.selectAll = false;
                        delete $rootScope.addDocumentsWithLibrary.queuedDocuments[document.id];
                    }

                    if (Object.keys($rootScope.addDocumentsWithLibrary.queuedDocuments).length === 0) {
                        $rootScope.addDocumentsWithLibrary.noDocumentsSelectedError = true;
                    }
                };

                // This function gets the course from
                // $rootScope.addAssignmentsWithLibrary.queuedAssignments = {}
                // and add document to the list
                $rootScope.addDocumentsWithLibrary.submitDocumentsForSelectedUsers = function () {
                    var assignmentIds;
                    assignmentIds = Object.keys($rootScope.addDocumentsWithLibrary.queuedDocuments);
                    _.each(assignmentIds, function (a) {
                        $scope.addFileFromDocRepo(parseInt(a));
                    });
                };   

                $scope.addFileFromDocRepo = function (a) {
                    // Map the document id to a file object
                    var fileToAdd = _.filter($rootScope.addDocumentsWithLibrary.searchCatalog, function (file) {
                        if (file.id === a)
                            return true;
                    });

                    fileToAdd = fileToAdd[0];

                    /* If the file is already in the list
                     * exit the function */
                    for (var i = 0; i < $scope.files.length; i += 1) {
                        if ($scope.files[i].id === a) {
                            jQuery('#addAssignmentsWithLibraryModal').modal('hide');
                            $scope.documentCourseList.error.setError(409, "Docuemnt already present in the list..");
                            return;
                        }
                    }

                    fileToAdd.fromRepo = true;
                    fileToAdd.fileOrUrl = 'file';

                    $scope.files.push(angular.copy(fileToAdd));

                    // Clear the model 
                    jQuery('#addAssignmentsWithLibraryModal').modal('hide');

                    jQuery('#addAssignmentsWithLibraryModal').on('hidden.bs.modal', function () {
                        angular.element(document.querySelector('#viewAssignmentsModal')).modal();
                        $rootScope.addDocumentsWithLibrary.clearAddDocumentsModal();
                    });
                };
                            
                $rootScope.addDocumentsWithLibrary.addAllCourses = function (selected) {
                    for (i = 0; i < $rootScope.addDocumentsWithLibrary.searchResults.length; i++) {
                        if ($rootScope.addDocumentsWithLibrary.searchResults[i].checked === selected || $rootScope.addDocumentsWithLibrary.searchResults[i].checked === undefined) {
                            $rootScope.addDocumentsWithLibrary.searchResults[i].checked = !selected;
                            $rootScope.addDocumentsWithLibrary.queueDocument($rootScope.addDocumentsWithLibrary.searchResults[i]);
                        }
                    }
                };

                //
            }]
    };
}
]);