/* This controller displays the successful/unsuccessful certificates */
app.controller('coursesController', ['$sce', '$scope', '$route', '$window', '$location', 'RoleMapsMixin', 'CoursesService', 'UsersService', 'SettingsService', 'BreadcrumbsService', 'AssignmentsService', 'TranscriptsService', function ($sce, $scope, $route, $window, $location, RoleMapsMixin, CoursesService, UsersService, SettingsService, BreadcrumbsService, AssignmentsService, TranscriptsService) {
    var currentMethod = $route.current.params.name;

    var _this = this;
    var breadcrumbType = "";
    var concurrentOpen = false;

    $scope.courseFailure = false;
    $scope.isLoading = true;
    $scope.requestingApproval = false;
    $scope.attachTriggered = false;
    $scope.hideAttached = function () { jQuery('#attachedModal' + $scope.assignment.id).hide(); };

    $scope.user = UsersService;

    $scope.certificateAction = function () {
        $scope.defaultHeader = false;
        $scope.homeTemplate = "app/templates/courses/components/certHome.html";
        var params = $route.current.params.param.split('/');
        /* By default this page works for an admin user
         * who is able to view the transcript of any student at any time
         * If they're not an admin user and they're just completing a course
         * take the user id from the currently logged in user
         * because this is a student completing courses under their own name */
        breadcrumbType = params[0];
        var transcriptId = params[1];
        var studentId = params[2];
        if (studentId === "") {
            studentId = $scope.user.user.id;
        }
        
        if (SettingsService.maxEnrollmentAttemptsAllowed === -1)
        {
            var settings = SettingsService.getSettings();

            settings.then(function (success) {
                _this.assignmentGet(studentId, transcriptId);
            }, function (failure) {
                _this.assignmentGet(studentId, transcriptId);
            });
        }
        else
        {
            _this.assignmentGet(studentId, transcriptId);
        } 
    };

    _this.assignmentGet = function (studentId, transcriptId) {
        var assignment = TranscriptsService.getSingleTranscript(studentId, transcriptId);        

        assignment.then(function(success) {
                _this.assignmentGetSuccess(success);
            },
            function(error) {
                $scope.courseFailure = true;
                $scope.isLoading = false;
            });
    };

    _this.assignmentGetSuccess = function(success) {
        $scope.completionView = 'app/templates/courses/components/unsuccessful.html';

        $scope.assignment = success;
        $scope.launchButtonText = "Relaunch";
        $scope.requestingApproval = false;
        $scope.isLoading = false;

        $scope.isSaba = UsersService.isSaba();
        
        if (!$scope.isSaba && (success.isManagerApprovalRequired && success.isManagerApprovalRequired === true) && success.status !== "inProgress") {
            $scope.launchButtonText = "Request Approval";
            $scope.requestingApproval = true;
        }

        _this.BreadcrumbsService = BreadcrumbsService;

        // Assign english name to assignment
        if ($scope.assignment.type === 'wbt') {
            $scope.fullTypeName = 'Web Based';
        } else if ($scope.assignment.type === 'ilt') {
            $scope.fullTypeName ='Instructor Led';
        } else {
            $scope.fullTypeName = $scope.assignment.type;
        }

        //NMLS - If bsiAuth is set to true, set it to false. This ensures that the user will be prompted by 
        //biosig if they relaunch the NMLS course
        if (localStorage.getItem('bsiAuthenticated') !== null && localStorage.getItem('bsiAuthenticated') === 'true') {
            localStorage.setItem('bsiAuthenticated', 'false');
        }

        if (success.status === "successful") {
            // format the completion date
            $scope.completionView = 'app/templates/courses/components/successful.html';

            $scope.generateBreadCrumbs('Successful');
        } else {
            $scope.generateBreadCrumbs('Unsuccessful');
        }
    };

    $scope.generateBreadCrumbs = function (CourseStatus) {
        var breadcrumbs = '';

        var homeBreadcrumbName = "My Dashboard";
        var homeBreadcrumbPath = "student/home";
        if (breadcrumbType === "careerpaths") {
            homeBreadcrumbName = "career Paths";
            homeBreadcrumbPath = "users/home/careerpaths";
            _this.BreadcrumbsService.create(homeBreadcrumbName, homeBreadcrumbPath);
        }
        BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
        _this.BreadcrumbsService.pushCrumb("transcripts", 'users/transcripts');

        if (CourseStatus === "unsuccessful") {
            _this.BreadcrumbsService.pushCrumb('Unsuccessful');
        } else if (CourseStatus === "successful") {
            _this.BreadcrumbsService.pushCrumb('Successful');
        } else {
            _this.BreadcrumbsService.pushCrumb(CourseStatus);
        }

        $scope.breadcrumbs = $sce.trustAsHtml(breadcrumbs);
    };

    $scope.selectAll = {};
    $scope.checkAll = function () {
        _.each($scope.rows, function (row) {
            row.select = $scope.selectAll.checked;
        });
    };

    $scope.addOriginInfo = function (uri) {

        /**
         * Origin is undefined in IE, which it then takes and uses as a string to concatenate.
         * We need to ensure that location.origin is set, so we don't get funky URLs.
         */
        var origin = typeof window.location.origin !== 'undefined' ? window.location.origin : '';

        if (uri.indexOf(origin) === -1) {
            uri = origin + uri;
        }

        return uri;
    };

    $scope.launchCourse = function (assignment) {
        if (assignment.type !== 'wbt' && assignment.type !== 'survey' && assignment.type !== 'evaluation') {
            return;
        }

        var isDocument = false;
        var launchedCourse = null;
        var single = {};
        
        $scope.assignment = assignment;
        $scope.launcherUrlTitle = assignment.name;

        $scope.launchCourseLoader = true;
        jQuery('#launchCourseLoader-' + assignment.id).toggle();
        jQuery('#launchCourseLoaderPlayButton-' + assignment.id).toggle();

        if ($scope.requestingApproval === true) {
            jQuery('#launchbutton-' + assignment.id).toggle();
        }
        if ((assignment.resourceLaunchWithContent !== 0 && typeof assignment.courseAssets !== "undefined" && assignment.courseAssets !== null) && !($scope.attachTriggered))
        {
            assignment.courseAssets.forEach(function (asset) {
                if (asset.id === assignment.resourceLaunchWithContent || asset.courseId === assignment.resourceLaunchWithContent) {
                    //asset.type = "other";
                    isDocument = true;
                    single = CoursesService.getCourseUrl(parseInt(asset.courseId), asset.type, UsersService.getId());
                }
            });
        }
        else 
        {
            switch (assignment.type) {
                case 'wbt':
                    single = CoursesService.getCourseUrl(assignment.id, assignment.type, UsersService.getId());
    
                    break;
                case 'evaluation':
                    single = EvaluationService.fetchSingle(UsersService.getId(), assignment.offeringId);
    
                    break;
                case 'survey':
                    single = SurveyService.fetchSingle(UsersService.getId(), assignment.id);
    
                    break;
            }   
        }

        single.then(function (success) {
            var uri = null;

            if (typeof success !== 'undefined' && typeof success.uri !== 'undefined') {
                uri = success.uri;
            } else if (typeof success !== 'undefined' && typeof success.contentLaunchUrl !== 'undefined') {
                uri = success.contentLaunchUrl;
            } else {
                uri = success;
            }

            // If the course is in a course group, make a transcript for the course group as well.
            if (assignment.isInCourseGroup) {
                var enrollCoursePromise = CoursesService.getCourseUrl(assignment.isInCourseGroup, assignment.type, UsersService.getId()); // Getting the URL will also enroll (create transcript) for the course.
                enrollCoursePromise.then(function (success) {
                    // Don't do anything. There is no url for the course group course but we still need to create a transcript
                });
            }

            //NMLS - If the course is not new and it is a NMLS course, set the NMLSResumeCourse value to true 
            //      to be used as a parameter posted to BioSig
            if (assignment.isNMLS && assignment.status.toLowerCase() !== 'new') {
                localStorage.setItem("NMLSResumeCourse", "true");
            } else { localStorage.setItem("NMLSResumeCourse", "false"); }

            var uriAfterOriginCheck = uri.startsWith("http") ? uri : $scope.addOriginInfo(uri);
            var launchedCourse = window.open(uriAfterOriginCheck);

            //open resource flaged to launch with content type URL at the same time
            concurrentOpen = AssignmentsService.checkForCuncurrentOpen(assignment);

            //If there is a resource flaged to launch with content, then open that resource
            if (assignment.resourceLaunchWithContent !== 0 && typeof assignment.courseAssets !== "undefined" && assignment.courseAssets !== null) {
                assignment.courseAssets.forEach(function (asset) {
                    if (asset.id === assignment.resourceLaunchWithContent || asset.courseId === assignment.resourceLaunchWithContent) {
                        //new style launch
                        if (asset.launchUri === null && $scope.attachTriggered === false) {
                            angular.element(document.querySelector('#attachedModal' + $scope.assignment.id)).modal();
                                $scope.attachTriggered = true;
                        }
                    }
                        //old style launch
                        else {
                            setTimeout(function () {
                            $window.open(resourceUri, "popup", "width=900,height=600,left=200,top=100");
                        }, 1500);
                    }
                });
            }

            /**
             * Ensure we don't show certificate page on this type;
             */
            if(assignment.type !== 'wbt') {
                return;
            }

            var curTranscript = null;

            var transcript = AssignmentsService.fetchSingle(assignment.id, assignment.type);
            transcript.then(function(transcript) {
                curTranscript = transcript;
            });

            var timer = setInterval(function () {
                if ((launchedCourse !== null && launchedCourse.closed) || launchedCourse === null || concurrentOpen) {
                    clearInterval(timer);

                    if ($scope.attachTriggered && isDocument)
                    {
                        $scope.hideAttached();
                        $scope.launchCourse(assignment);
                    }
                    else{

                    // set base url as absolute path
                    var url = '/';

                    // get all base tags
                    var bases = document.getElementsByTagName('base');

                    // if there are any reset url to be base 0's href; this way we don't break the Url pattern
                    if (bases.length > 0) {
                        url = bases[0].href;
                    }
                    var breadcrumbParam = "assignments";
                    if (breadcrumbType && breadcrumbType !== "") {
                        breadcrumbParam = breadcrumbType;
                    }
               
                    // combine and enjoy
                    url += 'courses/certificate/'+ breadcrumbParam + '/' + curTranscript.transcriptId + '/';

                    $window.location.href = url;
                }
                }
            }, 500);
        }, function (failure) {
            if ($scope.requestingApproval || failure.ErrorMessage.indexOf("pending manager approval") > 0) {
                UsersService.navigateToHome();
            }
            jQuery('#launchCourseLoader-' + assignment.id).toggle();
            jQuery('#launchCourseLoaderPlayButton-' + assignment.id).toggle();

            $scope.launchCourseLoader = false;
            $scope.mainErrorFailure = true;
            $scope.mainErrorFailureMessage = 'There was a problem launching your course! Please try again later.';

            jQuery('#topErrorMessage').slideDown(function () {
                var _this = jQuery(this);

                setTimeout(function () {
                    _this.slideUp(500);

                    $scope.mainErrorFailure = false;
                }, 2000);
            });
        });
    };
    $scope.dump = function(data) { console.warn(data); };


    /**** Load role specific functionality ****/
    /* This function takes the scope object
     * and overrides anything specific to the current page
     * or role.
     */
    RoleMapsMixin.overrideScope($scope);

    /* Call route action like homeAction here.
     * Return 404 if action doesn't exist after
     * injecting mixin. For example, if a certain role
     * doesn't have an ILT calendar, they will get a 404
     * Not every role needs every page.
     * Please follow the routing structure; non-alphanumeric characters should not be used.
     */
    if (currentMethod === 'ilt-calendar') {
        $scope.iltCalendarAction();
    } else {
        $scope[currentMethod + 'Action']();
    }
}]);
