/* This is for the home, list, calendar ILT view and transcripts page.
* /student/home/ is the home (dashboard) view
* /users/home/ is the home (admin) view
* /users/list/ is the list view
* /users/calendar/ is the calendar view
* /users/ilt-calendar/ is the ilt calendar view
* /users/transcripts/ is the transcripts page
*
* Role specific functionality is at the end.
*/
app.controller('usersController', ['$compile', '$filter', '$location', '$rootScope', '$route', '$scope', '$sce', '$window', '$timeout', 'dateUtil', 'AssignmentsService', 'CoursesService', 'EvaluationService', 'AssessmentService', 'OfferingsService', 'SurveyService', 'TranscriptsService', 'UsersService', 'BreadcrumbsService', 'TypeMapsMixin', 'RoleMapsMixin', 'errorDisplayerUtil', function ($compile, $filter, $location, $rootScope, $route, $scope, $sce, $window, $timeout, dateUtil, AssignmentsService, CoursesService, EvaluationService, AssessmentService, OfferingsService, SurveyService, TranscriptsService, UsersService, BreadcrumbsService, TypeMapsMixin, RoleMapsMixin, errorDisplayerUtil) {
    var currentMethod = $route.current.params.name;
    var added = [];
    var totalChildren = 0;
    var lastIndex = -1;
    var _this = this;
    var cloneSlider = null;

    $scope.addApiError = false;
    $scope.apiActionLoader = false;
    $scope.apiError = false;
    $scope.dateRangeError = false;
    $scope.day = moment();
    $scope.eodToday = moment().set({ hour: 23, minute: 0, second: 0, millisecond: 0 });
    $scope.isFutureSelected = true;
    $scope.isLoading = true;
    $scope.launchCourseLoader = false;
    $scope.mainErrorFailure = false;
    $scope.myDate = new Date();
    $scope.offeringsAreLoading = false;
    $scope.orderProperty = 'dueDate.original';
    $scope.responseComplete = false;
    $scope.role = UsersService.getCurrentRoleType(); // ensure buttons show up for current user, as slidedown is used by two roles but only available should show buttons for student
    $scope.filterView = 'app/templates/users/partials/filters/' + $scope.role + '.html';
    $scope.showCompletionButton = false;
    $scope.singleIsLoading = false;
    $scope.user = UsersService;
    $scope.userAcceptsRisk = false;
    $scope.currentAssignmentFilter = 'getRequiredAssignments';
    $scope.sortBy = 'name';
    $scope.searchEndDate = moment(new Date()).format('YYYY-MM-DD');
    $scope.searchStartDate =new Date();
    $scope.searchStartDate = moment($scope.searchStartDate.setYear($scope.searchStartDate.getFullYear() - 1)).format('YYYY-MM-DD');
    $scope.transcriptSearch = {};
    $scope.transcriptSearch.endDate = dateUtil.formatDate($scope.eodToday.toDate());
    $scope.transcriptSearch.startDate = dateUtil.formatDate(moment().subtract(1, 'years').set({ hour: 01, minute: 0, second: 0, millisecond: 0 }).toDate());

    _this.BreadcrumbsService = BreadcrumbsService;

    /**
     * General page options to show page level loading, errors, etc.
     */
    $scope.pageOptions = {};
    $scope.pageOptions.error = errorDisplayerUtil.create();
    $scope.pageOptions.isLoading = false;

    // Removed per SLY-1962 but, only commented out to show how to put back in

    //if(localStorage.getItem('welcomeVideo') !== 'watched') {
    //    if($rootScope.videoShownThisSession === false) {
    //        $rootScope.videoShownThisSession = true;
    //
    //        // The modal doesn't exist for a split second as it gets
    //        // downloaded; timeout allows for the download to complete
    //        setTimeout(function () {
    //            jQuery('#welcomeVideoModal').modal();
    //        }, 500);
    //    }
    //}

    /**
     * Triggers that come from outside of this scope
     */
    $scope.$on('slideDownDirective:refreshCurrentPage', function () {
        $scope.isLoading = true;

        if (currentMethod === 'ilt-calendar') {
            $scope.iltCalendarAction();
        } else {
            $scope[currentMethod + 'Action']();
        }
    });

    $scope.iltCalendarAction = function () {
        $scope.homeTemplate = 'app/templates/users/ilt-calendar/student.html';

        var context = UsersService.setUserContext('student');
        context.then(function () {
            
        
            /* Unfortunate anti-pattern */
            /* Original system had ILT offerings as a modal
             * Which called the calenderAction first
             * So we have to replicate some of the functionality
             * from the calendar action here */

            /* Begin actual work on iltCalendarAction */
            $scope.offeringsAreLoading = true;

            var offerings = OfferingsService.fetch(UsersService.getId(), 'ilt');
            offerings.then(function (success) {
                $scope.offeringsAreLoading = false;

                $scope.offerings = OfferingsService.offerings;
                $scope.isLoading = false;
            }, function (failure) {
                $scope.offeringsAreLoading = false;

                $scope.apiError = true;
                $scope.isLoading = false;
            });

            if (BreadcrumbsService.getCrumbs().length === 0) {
                BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
                BreadcrumbsService.pushCrumb("instructor led");
            }
        });
    };

    $scope.calendarAction = function (tense) {
        $scope.properFiltering = 'dueDate';

        $scope.assignmentFilter = $scope.getRequiredAssignments;
        $scope.orderProperty = 'dueDate.original';
        $scope.role = 'student';

        $scope.fetchAssignments();

        BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
        _this.BreadcrumbsService.pushCrumb("calendar");
    };

    $scope.editCourse = function () { alert("This is where the manager edits the course"); };

    /* Defined here temporarily for list view. Will need to be factored out into an override */
    $scope.fetchAssignments = function () {
        var assignments = AssignmentsService.fetch(UsersService.getId());

        $scope.filterView = 'app/templates/users/partials/filters/' + $scope.role + '.html';

        assignments.then(
            function (success) {
                var data = success;

                $scope.apiError = false;
                $scope.assignments = data;

                $scope.isLoading = false; // We're no longer loading!
                $scope.responseComplete = true;
            },
            function (failure) {
                $scope.apiError = true;
                $scope.isLoading = false;
                $scope.responseComplete = true;

                //returning the appropriate error
                if (failure.status === 404) {
                    $scope.errorMessage = 'Sorry, there were no results found for your request.';
                } else {
                    $scope.errorMessage = 'There was an error processing your request! Please try again later!';
                }
            }
        );
    };

    $scope.deleteCourseGroup = function (courseGroup, $event) {

        var confirmDrop = confirm("Are you sure you want to drop?");

        if (confirmDrop) {
            
            var deleteCourseGroup = AssignmentsService.removeAssignment(courseGroup);

            deleteCourseGroup.then(function (success) {
                $scope.apiActionLoader = false;

                jQuery('#singlecourse').slideUp(500, function () {
                    $scope.isLoading = true;
                    $window.location.reload();
                });

            },
            function (failure) {
                alert("There was a problem deleting the Course Group");
            });
       }
    };

    $scope.scheduleCourse = function (assignment) {

    };

    $scope.trim = function (value, length) {
        return $filter('trimmer')(value, true, length);
    };

    $scope.addOriginInfo = function (uri) {

        /**
         * Origin is undefined in IE, which it then takes and uses as a string to concatenate.
         * We need to ensure that location.origin is set, so we don't get funky URLs.
         */
        var origin = typeof window.location.origin !== 'undefined' ? window.location.origin : '';
        
        if (uri.indexOf(origin) === -1) {
            uri = origin + uri;
        }

        return uri;
    };

    $scope.hidePrereq = function () { jQuery('#preReqModal').hide(); };

    $scope.requestManagerApprovalCareerPath = function (assignment) {

        assignment.isLoading = true;
        assignment.requestManagerApprovalInProcess = true;

        var assignCareerCourseGroup = AssignmentsService.assignCourseGroupCourses(UsersService.getId(), assignment.id);
        assignCareerCourseGroup.then(function (success) {

            var managerApproval = CoursesService.requestManagerialApproval(assignment.id, assignment.type, UsersService.getId(), 0);
            managerApproval.then(function (success) {
                assignment.isLoading = false;
                //assignment.requestApprovalInProcess = false;

                $route.reload();

            }, function (failure) {
                assignment.isLoading = false;
                //assignment.requestApprovalInProcess = false;
                assignment.addCareerPathError.setError(failure.StatusCode, failure.ErrorMessage);
                $timeout(function () {
                    assignment.addCareerPathError.reset();
                }, 3000);
            });
        }, function (failure) {
            assignment.addCareerPathError.setError(failure.StatusCode, failure.ErrorMessage);
            $timeout(function () {
                assignment.addCareerPathError.reset();
            }, 3000);
        });
    };

    $scope.launchCourse = function (assignment) {
        if (assignment.type !== 'wbt' && assignment.type !== 'survey' && assignment.type !== 'evaluation' && assignment.type !== 'assessment' && assignment.type !== 'careerPath') {
            return;
        }

        if (assignment.type === 'careerPath') {
            assignment.isLoading = true;

            var assignCourseGroups = AssignmentsService.assignCourseGroupCourses(UsersService.getId(), assignment.id);
            assignCourseGroups.then(function (success) {
                // Reload assignments to reflect changes
                var careerPaths = CoursesService.getCoursesByType("careerPath");
                careerPaths.then(function(success) {
                    $scope.assignments = success;

                    _.each($scope.assignments, function(assignment) {
                        assignment.isLoading = false;
                        assignment.addCareerPathError = errorDisplayerUtil.create();
                    });
                    $window.scrollTo(0, 0);
                    $scope.closeSlider();
                    jQuery('span.filterItem').siblings('.filterItem').removeClass('selected');
                    jQuery(jQuery('span.filterItem').siblings('#assignedFilter')[0]).addClass("selected");

                    $scope.currentAssignmentFilter = 'getNewestAssignmentsCareerPath';
                    $scope.assignmentFilter = $scope.getNewestAssignmentsCareerPath;
                    $scope.$broadcast('assignmentFilter', $scope.assignmentFilter);
                }, function (failure) {
                    assignment.isLoading = false;
                    // If we can't refresh the assignments, reload the route. If this call fails they probably didn't see any career paths to add anyway
                    $route.reload();
                });
            }, function (failure) {
                assignment.isLoading = false;
                assignment.addCareerPathError.setError(failure.StatusCode, failure.ErrorMessage);
                $timeout(function() {
                    assignment.addCareerPathError.reset();
                }, 3000);
            });
            return;
        }

        // call Prereq api to see if any are there prereqs that the user needs to take before launching this course
        // if any are there, send message and don't launch the course

        var prereqCall = AssignmentsService.getPrereqs(assignment.id);
        $scope.assignmentLaunched = assignment;

        prereqCall.then(function (success) {
            if (success.length === 0) {
                //if nothing, do nothing, move on
                _this.launchCourse($scope.assignmentLaunched);
            } else {
                //If there are prereqs - set the scoped variable for the prereq details             
                $scope.prereqModalData = success;
                angular.element(document.querySelector('#preReqModal')).modal();
            }

        }, function (failure) {
            _this.showFailure($scope.assignmentLaunched, failure);
        });


    };
      


        
     _this.launchCourse = function(assignment){
        var single = {};

        $scope.assignment = assignment;
        $scope.launchCourseLoader = true;
        $scope.launcherUrlTitle = assignment.name;

        jQuery('#launchCourseLoader-' + assignment.id).toggle();
        jQuery('#launchCourseLoaderPlayButton-' + assignment.id).toggle();

        switch (assignment.type) {
            case 'wbt':
                single = CoursesService.getCourseUrl(assignment.id, assignment.type, UsersService.getId());

                break;
            case 'evaluation':
                single = EvaluationService.fetchSingle(UsersService.getId(), assignment.id);

                break;
            case 'assessment':
                single = AssessmentService.fetchSingle(UsersService.getId(), assignment.id);

                break;
            case 'survey':
                single = SurveyService.fetchSingle(UsersService.getId(), assignment.id);

                break;
        }

        single.then(function (success) {
            var uri = null;

            if (typeof success !== 'undefined' && typeof success.uri !== 'undefined') {
                uri = success.uri;
            } else if (typeof success !== 'undefined' && typeof success.contentLaunchUrl !== 'undefined') {
                uri = success.contentLaunchUrl;
            } else {
                uri = success;
            }

            // If the course is in a course group, make a transcript for the course group as well.
            if (assignment.isInCourseGroup) {
                var enrollCoursePromise = CoursesService.getCourseUrl(assignment.isInCourseGroup, assignment.type, UsersService.getId()); // Getting the URL will also enroll (create transcript) for the course.
                enrollCoursePromise.then(function(success) {
                    // Don't do anything. There is no url for the course group course but we still need to create a transcript
                });
            }

            //NMLS - If the course is not new and it is a NMLS course, set the NMLSResumeCourse value to true 
            //      to be used as a parameter posted to BioSig
            if (assignment.isNMLS && assignment.status.toLowerCase() !== 'new') {
                localStorage.setItem("NMLSResumeCourse", "true");
            } else { localStorage.setItem("NMLSResumeCourse", "false"); }

            var uriAfterOriginCheck = uri.startsWith("http") ? uri : $scope.addOriginInfo(uri);
            var launchedCourse = window.open(uriAfterOriginCheck);

            //If there is a resource flaged to launch with content, then open that resource
            if (assignment.resourceLaunchWithContent !== 0 && typeof assignment.courseAssets !== "undefined" && assignment.courseAssets !== null) {
                assignment.courseAssets.forEach(function (asset) {
                    if (asset.id === assignment.resourceLaunchWithContent) {
                        var resourceUri = asset.fileFormat !== 'url' ? $scope.addOriginInfo(asset.launchUri) : asset.launchUri;
                        setTimeout(function () {
                            $window.open(resourceUri, "popup", "width=900,height=600,left=200,top=100");
                        }, 1500);
                    }
                });
            }

            /**
             * Ensure we don't show certificate page on this type;
             */
            if (assignment.type === 'wbt') {


                var closeID = assignment.id;


                var timer = setInterval(function () {
                    if ((launchedCourse !== null && launchedCourse.closed) || launchedCourse === null) {
                        clearInterval(timer);

                        // set base url as absolute path
                        var url = '/';

                        // get all base tags
                        var bases = document.getElementsByTagName('base');

                        // if there are any reset url to be base 0's href; this way we don't break the Url pattern
                        if (bases.length > 0) {
                            url = bases[0].href;
                        }


                        var transcript = AssignmentsService.fetchSingle(closeID, 'wbt');
                        transcript.then(function (transcript) {
                            var typeParam = $scope.currentRoute === "" ? "assignments" : $scope.currentRoute;
                            url += 'courses/certificate/' + typeParam + '/' + transcript.transcriptId + '/';

                            $window.location.href = url;
                        });
                        // combine and enjoy
                    }
                }, 500);
            }
            else {
                var refreshTimer = setInterval(function () {
                    if (launchedCourse.closed) {
                        clearInterval(refreshTimer);
                        $window.location.reload();
                    }
                }, 500);

            }
        }, function (failure) {
            if (failure.ErrorMessage.indexOf("pending manager approval") > 0) {
                UsersService.navigateToHome();
            }
           
            _this.showFailure(assignment, failure);
        });
        
    };
    
     _this.showFailure = function (assignment, failure) {
         jQuery('#launchCourseLoader-' + assignment.id).toggle();
         jQuery('#launchCourseLoaderPlayButton-' + assignment.id).toggle();

         $scope.launchCourseLoader = false;
         $scope.mainErrorFailure = true;
         $scope.mainErrorFailureMessage = 'There was a problem launching your course! Please try again later.';

         jQuery('#topErrorMessage').slideDown(function () {
             var _this = jQuery(this);

             setTimeout(function () {
                 _this.slideUp(500);

                 $scope.mainErrorFailure = false;
             }, 2000);
         });
     };

    $scope.openTranscriptCreator = function () {
        $scope.isLoading = false;

        $scope.success = false;

        $scope.addApiError = false;

        $scope.transcriptAddModal = {};
        $scope.transcriptAddModal.transcript = null;

        jQuery('#transcriptAddModal').modal();
    };

    $scope.createTranscript = function () {
        // If user is adding transcript for himself then route params will be null
        var userId = '';
        if ($route.current.params.param !== undefined && $route.current.params.param !== null) {
            var params = $route.current.params.param.split('/');
            userId = params[0];
        } else {
            userId = null;
            //Set user context to student if saving transcript for themselves
            UsersService.setUserContext('student');
        }

        var data = angular.copy(this.transcriptAddModal.transcript);
        data.userId = userId;

        $scope.transcriptAddLoader = true;

        data.startDate = data.startDate.moment.toDate();
        data.completedDate = data.completedDate.moment.toDate();
        
        var creation = TranscriptsService.create(data);

        creation.then(function () {
            $window.location.reload();
        }, function () {
            $scope.addApiError = true;
            $scope.transcriptAddLoader = false;
            $scope.isLoading = false;

            jQuery('#transcriptAddError').slideDown(500, function () {
                var _this = jQuery(this);

                setTimeout(function () {
                    _this.slideUp(500);
                }, 1500);
            });
        });
    };

    $scope.openCertificate = function (assignment) {
        $scope.assignment = assignment;
        $scope.user = UsersService;

        jQuery('#certificateModal').modal();
    };

    $scope.getTransriptStatus = function (status) {
        status = status === 'successful' ? 'Successfully' : 'Unsuccessfully';

        return status + ' completed this course';
    };

    $scope.getTranscriptScoreText = function (assignment) {
        /* These types don't have scores.
         * See SLY-2921
         */
        if (assignment.type === 'courseGroup' || 
            assignment.type === 'task' ||
            assignment.type === 'evaluation' ||
            assignment.type === 'survey') {

            return '';
        }

        var score = assignment.score;
        if (typeof score === 'undefined' || score === null || score === 0) {
            return ' with a score of 0';
        }

        return ' with a score of ' + score;
    };

    $scope.getCourseCode = function (courseCode) {
        if (courseCode === null || typeof courseCode === 'undefined') {
            return '00000';
        }
        return courseCode;
    };

    $scope.changeUrl = function (assignment) {
        var typeParam = $scope.currentRoute === "" ? "assignments" : $scope.currentRoute;
        $location.path('/courses/certificate/' + typeParam + '/' + assignment.transcriptId + '/').replace();
    };


    /**
     * User scope level filters
     */

    /**
     *
     * @param item current assignment to be iterated
     * @returns {boolean}
     */
    $scope.getCompletedAssignments = function (item) {
        return item.completedDate !== null && (item.status === 'completed' || item.status === 'successful');
    };

    $scope.getRequiredAssignments = function (item) {
        return item.isRequired === true && item.status !== 'successful';
    };

    $scope.getNewestAssignments = function (item) {
        return item.completedDate === null && item.status === 'new';
    };

    $scope.getNewestAssignmentsCareerPath = function(item) {
        return item.completedDate === null && (item.status === 'new' || item.status === 'pendingApproval' || item.status === 'inProgress');
    };

    $scope.getMyPicks = function (item) {
        return item.isUserAssigned === true;
    };

    $scope.getInProgress = function (item) {
        return item.status === 'inProgress';
    };

    $scope.getSurveysAndEvaluations = function (item) {
        return item.type === 'evaluation' || item.type === 'survey';
    };

    // Toggle highlights on the filter by on the assignments page
    jQuery('.filterItem').click(function () {
        jQuery(this).siblings('.filterItem').removeClass('selected');

        jQuery(this).addClass('selected');
    });

    $scope.clearForm = function () {
        $window.location.reload();
    };

    $scope.resetAddTranscriptModal = function (form) {
        if (typeof form !== 'undefined' && form !== null) {
            form.$setUntouched();
            form.$setPristine();
        }
    };

    $scope.completeAssignment = function (id, $event) {
        var completion = CoursesService.completeTask(id, UsersService.getId());

        $scope.apiActionLoader = true;

        completion.then(function (success) {

            jQuery('#singlecourse').slideUp(1000, function () {
                var courseCode = id;
                var viewTypeId = jQuery('#courseList-' + courseCode).length > 0 ? '#courseList-' + courseCode : '#courseTiles-' + courseCode;
                jQuery(viewTypeId).slideUp(500);
            });

            //Reload the page
            $scope.isLoading = true;
            $scope.homeAction();
            $window.location.reload();
            //$scope.switchFilter($scope.currentAssignmentFilter, $event);
            $scope.currentAssignmentFilter = 'InProgress';
           
        },

        function (failure) {
            jQuery('#offeringSlide').slideUp();
            $scope.apiErrorMessage = 'We\'re sorry! We couldn\'t complete the course. Please try again.';

            $scope.apiActionError = true;

            jQuery('#underButtonError').slideDown(500, function () {
                var _this = jQuery(this);

                setTimeout(function () {
                    _this.slideUp(500);

                    $scope.apiActionError = false;
                }, 1500);
            });
        });
    };

    $scope.dropElectiveCourse = function (assignment, $event) {
        $scope.apiActionError = false;
        $scope.apiActionLoader = true;

        var confirmDrop = confirm("Are you sure you want to drop?");

        if (confirmDrop) {
            var assignmentDrop = AssignmentsService.removeAssignment(assignment);

            assignmentDrop.then(function (success) {
                $scope.apiActionLoader = false;

                jQuery('#singlecourse').slideUp(500, function () {
                    // Force a re-render of the page; can't see another way except to fetch data for now;
                    // couldn't get a refresh to work for whatever reason...
                    $scope.isLoading = true;

                    $scope.homeAction();
                    $scope.switchFilter($scope.currentAssignmentFilter, $event);
                });
            }, function (response) {
                $scope.apiActionError = true;
                $scope.apiErrorMessage = 'Sorry! There was an issue dropping your course. Please try again!';
                $scope.apiActionLoader = false;

                jQuery('#transcriptAddError').slideDown(500, function () {
                    var _this = jQuery(this);

                    setTimeout(function () {
                        _this.slideUp(500);
                    }, 1500);
                });
            });
        } else {
            $scope.apiActionLoader = false;
        }
    };

    $scope.mapStatusLabel = function (status) {
        /* For mapping assignment status to a css class
        * The word new should be colored green for instance on the assignments page.
        * See https://projects.invisionapp.com/share/QS765VMF3#/screens/157758405/comments
        */
        var statuses = {
            'completed': 'Completed',
            'inProgress': 'In Progress',
            'new': 'New',
            'pendingApproval': 'Pending Approval',
            'successful': 'Successful',
            'unsuccessful': 'Unsuccessful'
        };

        if (status in statuses) {
            return statuses[status];
        }

        return status;
    };

    /**
     * Based on the design of the data layer, the correct status for upcoming ILTs must be inferred
     * from the roster counts.
     */
    $scope.mapStatusFromRosterCounts = function (offering) {
        var status = _this.getStatusForOfferingFromRoster(offering);

        switch (status) {
            case 'attending':
                return 'Confirmed';
            case 'waitlist':
                return 'Waitlisted';
            case 'pendingApproval':
                return 'Pending Manager Approval';
            default:
                return 'Not Registered';
        }
    };

    $scope.mapStatusForCssFromRosterCounts = function (offering) {
        return _this.getStatusForOfferingFromRoster(offering);
    };

    _this.getStatusForOfferingFromRoster = function (offering) {
        var defaultStatus = 'notRegistered';
        var loggedinUser = $scope.user.getId();
        //The roster now returns the total counts for attending and waitlisted students
        //If the logged in person is in the roster, their userid will be populated in the roster list
        //loop through the attending/waitlist/pendingApproval students and if a userID is not a dummy ie. 0
        //then return the roster status
        var attendingRoster = offering.roster.attendingStudents;
        for(var attend in attendingRoster)
        {
            var atten = attendingRoster[attend];
            if (typeof atten.id !== 'undefined' && atten.id !== 0 && loggedinUser === atten.id)
            {return atten.rosterStatus;}
        }

        var waitlistRoster = offering.roster.waitlistStudents;
        for(var waitL in waitlistRoster)
        {
            var wait = waitlistRoster[waitL];
            if (typeof wait.id !== 'undefined' && wait.id !== 0 && loggedinUser === wait.id)
            {return wait.rosterStatus;}
        }

        var pendingRoster = offering.roster.pendingApprovalStudents;
        for(var pender in pendingRoster)
        {
            var pend = pendingRoster[pender];
            if (typeof pend.id !== 'undefined' && pend.id !== 0 && loggedinUser === pend.id)
            {return pend.rosterStatus;}
        }
        
        return defaultStatus;
    };

    /**
     * Method to call to check if a user is registered for a course. If getStatusForOfferingFromRoster() returns the
     * string of not registered, that means the user is NOT registered. Hence, we need to check if the string that is
     * return is notRegistered. If it is the person is not registered; else they are.
     *
     * @param offering
     * @returns {boolean}
     */
    $scope.isUserRegisteredInILT = function (offering) {
        return _this.getStatusForOfferingFromRoster(offering) !== 'notRegistered';
    };
    
    $scope.isMaxWaitlistLimitReachedILT = function (offering) {
        var overTheMaxWaitlistLimit = false;
        if ((offering.roster.waitlistStudents.length >= offering.maxWaitlistSize) && (offering.attendingRosterCount >= offering.maxAllowedSize))
			{ overTheMaxWaitlistLimit = true; }

        return overTheMaxWaitlistLimit;
    };

    /**
     * Return a CSS class name that represents due state of a course. This will override all other colors.
     *
     * @param date
        Example @param date
        startDateTime : Object
            apiDate : "YYYY-MM-DD"
            day : 20
            dayName : "Mon"
            fullDate : "06.20.2016"
            fullDateWithTime : "06.20.2016 9:00 AM"
            moment : Moment
            month : "Jun"
            monthForCalender : 5
            monthNumber : 6
            original : "2016-06-20T14:00:00+00:00"
            time : "9:00 AM"
            year : 2016

        where startDateTime.moment can be constructed with
        moment(startDateTime.original)

        Testing for off by one errors can be done via angular inspect of
        an assignment on the calendar view, grabbing an original time
        and playing with
        moment("2016-06-23T13:20:00+00:00").isBetween(moment(), moment().add(7, 'days'))
        where "2016-06-23T13:20:00+00:00" is the date you want to test
        moment.js will convert "2016-06-23T13:20:00+00:00" into GMT time.
        and the return values of moment() will return GMT.

     * @returns {*}
     */
    $scope.getDueState = function (date) {
        if (date === null || typeof date.moment === 'undefined' || date.moment === null || date.moment === true) {
            return false;
        }
        
        if (date.moment.isBefore(moment())) {
            return 'overdue';
        }

        if (date.moment.isBetween(moment(), moment().add(7, 'days'))) {
            return 'duesoon';
        }

        return false;
    };

    $scope.getTranscriptDefaultCompletedOnDate = function () {
        var defaultCompletedOnDate = new Date();
        defaultCompletedOnDate.setYear(defaultCompletedOnDate.getFullYear() - 1);
        return defaultCompletedOnDate;
    };

    $scope.getTranscriptDefaultCompletedBeforeDate = function () {
        var defaultCompletedBeforeDate = new Date();
        return defaultCompletedBeforeDate;
    };

    $scope.closeSlider = function () {
        $rootScope.$broadcast('slider:view:close', _this);
    };

    $scope.changeInstructorCourseTense = function (tense) {
        $scope.homeAction(tense);
    };

    $scope.viewCertificate = function (transcriptId) {
        $location.url('courses/certificate/assignments/' + transcriptId);
    };

    $scope.toggleDescription = function (target) {
        // Get the description of the assignment we requested more info on
        var description = jQuery(target.currentTarget).parents('.title').children('.description');

        // Get all description boxes with data-opened=true
        // Except the one we clicked on
        // open all those mini descriptions on those boxes. and mark them as data-opened=false
        var descriptionBoxes = jQuery('.description[data-opened="true"]');
        descriptionBoxes.each(function (i, e) {
            if ($(e).attr('data-assignment-id') === $(description).attr('data-assignment-id')) {
                return;
            }
            $(e).attr('data-opened', 'false');
            $(e).slideDown();
        });

        // *NOTE* We have a marker called data-opened which is a boolean
        // storing whether the larger description is open or not.
        // So if data-opened is true, the description element that we query for
        // in the first part of the function should be closed. if data-opened is false
        // the description element should be open. it's inverted.

        // Check if we have an open/close marker yet, if not, add one and mark it as open
        // Close the mini description
        var opened = description.attr('data-opened');
        if (opened === undefined) {
            console.log('undefined');
            description.attr('data-opened', 'true');
            description.slideUp();
        } else if (opened === 'true') {
            console.log('close the description');
            description.attr('data-opened', 'false');
            description.slideDown();
        } else if (opened === 'false') {
            console.log('open the description');
            description.attr('data-opened', 'true');
            description.slideUp();
        }
    };

    $scope.switchFilter = function (method, $event) {
        $scope.closeSlider();

        $scope.currentAssignmentFilter = method;

        $scope.assignmentFilter = method !== null ? $scope[method] : null;

        $scope.$broadcast('assignmentFilter', $scope.assignmentFilter);
        jQuery($event.currentTarget).siblings('.filterItem').removeClass('selected');

        jQuery($event.currentTarget).addClass('selected');
    };

    $scope.closeWelcomeVideoModal = function () {
        var neverShowAgain = jQuery('#repeat').is(':checked') ? 'watched' : 'false';

        localStorage.setItem('welcomeVideo', neverShowAgain);

        jQuery('#welcomeVideoModal').modal('hide');
    };

    /* Maps assignment.status to an english name. See TypeMaps mixin */
    $scope.getFullAssignmentTypeName = TypeMapsMixin.getFullAssignmentTypeName;

    /**
     * This must live down here because getRequiredAssignment must exist...Oy.
     */
    $scope.assignmentFilter = $scope.getRequiredAssignments;

    $scope.dump = function (value) { console.warn(value); };

    $scope.openManagerSearchFilters = function () {
        jQuery('#managerSearchFilter').slideToggle();
    };

    $scope.openAdvManagerSearchFilters = function () {
        jQuery('#advManagerSearchFilter').slideToggle();
    };

    $scope.isOpenForEnrollment = function (offering) {
        var openToEnroll = false;
        var today = new Date();

        if (typeof offering.requestedOn.jsDate === "undefined") {
            offering.requestedOn = dateUtil.formatDate(offering.requestedOn);
        }
        
        if (offering.requestedOn.jsDate <= today) {
            openToEnroll = true;
        }

        return openToEnroll;
    };

    $scope.setDefaultStartDateTime = function (dateObj) {
        if (typeof dateObj !== 'undefined' && dateObj !== null && _.isObject(dateObj.moment)) {
            var newdt = dateUtil.setPortalStartTime(dateObj.moment.toDate());
            dateObj = dateUtil.formatDate(newdt);
        } else {
            dateObj = null;
        }
        return dateObj;
    };

    $scope.setDefaultEndDateTime = function (dateObj) {
        if (typeof dateObj !== 'undefined' && dateObj !== null && _.isObject(dateObj.moment)) {
            var newdt = dateUtil.setPortalEndTime(dateObj.moment.toDate());
            dateObj = dateUtil.formatDate(newdt);
        } else {
            dateObj = null;
        }
        return dateObj;
    };

    $scope.setDueDateEndTimeForMomentObj = function (momentObj) {
        if (typeof dateObj !== 'undefined' && dateObj !== null) {
            var newdt = dateUtil.setPortalEndTime(momentObj.toDate());
            momentObj.set({ hour: newdt.getHours(), minute: newdt.getMinutes(), second: newdt.getSeconds(), millisecond: newdt.getMilliseconds() });
        }
    };

    $scope.getEndDateTimeFromPicker = function (dateObj) {
        var result;

        if (typeof dateObj !== 'undefined' && dateObj !== null && _.isObject(dateObj)) {
            if (typeof dateObj.moment !== 'undefined' && dateObj.moment !== null) {
                if (_.isObject(dateObj.moment)) {
                    if (typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null) {
                        dateObj = $scope.setDefaultEndDateTime(dateObj);
                    }
                    result = dateUtil.formatDate(dateObj.moment.toDate());
                    result.displayTime = dateObj.displayTime;
                } else {
                    result = null;
                }
            } else {
                $scope.setDueDateEndTimeForMomentObj(dateObj);
                result = dateUtil.formatDate(dateObj.toDate());
            }
        } else {
            result = null;
        }

        return result;
    };

    $scope.triggerTranscriptSearch = function (studentId) {

        if ($scope.transcriptSearch.startDate === null || $scope.transcriptSearch.endDate === null ||
            !_.isObject($scope.transcriptSearch.startDate.moment) || !_.isObject($scope.transcriptSearch.endDate.moment)) {
            return;
        }
        else if ($scope.transcriptSearch.startDate.moment.toDate() > $scope.transcriptSearch.endDate.moment.toDate() ) {
            $scope.dateRangeError = true;
            return;
        }
        else {

            var startDate2 = $scope.transcriptSearch.startDate.moment.format('YYYY-MM-DD');
            var endDate = $scope.transcriptSearch.endDate.moment.format('YYYY-MM-DD');

            $scope.pageOptions.error.reset();
            $scope.pageOptions.isLoading = true;
            $scope.responseComplete = false;

            var transcript = TranscriptsService.rangeFetch(studentId, startDate2, endDate);

            transcript.then(function (success) {
                $scope.apiError = false;
                $scope.assignments = success;
                $scope.pageOptions.isLoading = false; // We're no longer loading!
                $scope.responseComplete = true;
            }, function (failure) {
                $scope.pageOptions.error.setError(failure.ErrorCode, failure.ErrorMessage);

                $scope.assignments = null;

                $scope.pageOptions.isLoading = false; // We're no longer loading!

            });
        }
    };

    $scope.isCourseGroupType = function (type, includeCareerPath) {
        if (type === 'courseGroupCertification' || type === 'courseGroupCurricula' || type === 'courseGroup' || (includeCareerPath && type === 'careerPath')) {
            return true;
        }
        return false;
    };



    /**** Load role specific functionality ****/
    /* This function takes the scope object
     * and overrides anything specific to the current page
     * or role.
     */
    RoleMapsMixin.overrideScope($scope);

    /* Call route action like homeAction here.
    * Return 404 if action doesn't exist after
    * injecting mixin. For example, if a certain role
    * doesn't have an ILT calendar, they will get a 404
    * Not every role needs every page.
    * Please follow the routing structure; non-alphanumeric characters should not be used.
    */
    if (currentMethod === 'ilt-calendar') {
        $scope.iltCalendarAction();
    } else {
        $scope[currentMethod + 'Action']();
    }
    // @TODO implement 404 action
}]);
