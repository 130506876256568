app.directive("slideDown", ['$rootScope', '$sce', '$route', 'AssignmentsService', 'CoursesService', 'TasksService', 'UsersService', 'EvaluationService', 'SurveyService', function ($rootScope, $sce, $route, AssignmentsService, CoursesService, TasksService, UsersService, EvaluationService, SurveyService) {
    // returns the Directive Definition Object
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'app/templates/users/partials/singleCourseSlideDown.html',
        link: function (scope, element, attrs, ctrl) {
            /**
             * Flag to see if we want to make an assignments scoped search, or a more generally scoped call. This
             * is to get the right data on a per course basis.
             */
            // http://thedailywtf.com/articles/What_Is_Truth_0x3f_
            scope.scoped = typeof attrs.scoped !== 'undefined' && attrs.scoped === 'true' ? true : false;

            scope.$on('slider:view:close', function(event, data) {
                scope.closeSlider();
            });

            var lastIndex = -1;
            var childCourseIndex = -1;
            var passedInAssignment = null;
            var _this = this;

            element = element.children('#singlecourse');

            scope.openCourseInfo = function ($event, assignment, index) {
                childCourseIndex = index;
                passedInAssignment = assignment;
                var eventName = $event.target.innerHTML;
                jQuery('.more').html('Read More');

                var isInACourseGroup = (typeof assignment.CourseGroupName !== 'undefined') ? assignment.CourseGroupName : assignment.isInCourseGroup;

                //Look for any open course info and close them
                if (jQuery(element).data('state') === 'opened') {
                    if (isInACourseGroup === undefined || isInACourseGroup === '') {
                        jQuery('#offeringSlide').slideUp();

                        if (jQuery('.arrow').length > 0) {
                            jQuery('.arrow').hide(1, function () {
                                jQuery('#singlecourse').slideUp();
                            });
                        } else {
                            jQuery(element).slideUp();
                        }

                        jQuery(element).data('state', 'closed');
                    }
                    else if (eventName === 'Read less')
                    {
                        jQuery('#courseGroupList .arrow').hide();
                        jQuery('#singlecourse').slideUp();
                    }
                    else 
                    { jQuery('#courseGroupList .arrow').hide(); }
                }

                //Look for any course group lists open and close them
                var courseGroupList = jQuery('#courseGroupList');
              
                if (jQuery(courseGroupList).data('state') === 'opened' && (isInACourseGroup === undefined ||isInACourseGroup === '')) {
                    jQuery('#offeringSlide').slideUp();
                    jQuery('.courseGroupMore').html('Read More');

                    if (jQuery('.arrow').length > 0) {
                        jQuery('.arrow').hide(1, function () {
                            jQuery('#courseGroupList').slideUp();

                        });
                    } else {
                        jQuery(courseGroupList).slideUp();
                    }

                    jQuery(courseGroupList).data('state', 'closed');
                }

                //Look for any View Resources list open and close them
                var viewResourceElem = jQuery('#viewResources');
                if (jQuery(viewResourceElem).data('state') === 'opened') {
                    if (jQuery('.arrow').length > 0) {
                        jQuery('.arrow').hide(1, function () {
                            jQuery('#viewResources').slideUp();
                        });
                    } else {
                        jQuery(viewResourceElem).slideUp();
                    }

                    jQuery(viewResourceElem).data('state', 'closed');
                    jQuery('.resource').html('View Resources');
                }

                scope.apiError = false;
                scope.singleIsLoading = false;

                var children, lastChild, mover, parent = null;
                var currentIndex, endOfRow, modIndex, modulo = 0;
                var found = false;
                var target = $event.currentTarget;

                currentIndex = 0;

                children = jQuery(target).parents('#courseList').children('.col-md-4');
                 if (children.length > 0) {
                    //This if handles the tile page course read more
                    parent = jQuery(target).parents('.col-md-4')[0];

                    modulo = jQuery(window).width() > 768 ? 3 : 1;

                    children.each(function(index) {
                        var id = jQuery(parent).prop('id');

                        jQuery(parent).prop('id', null);

                        if(jQuery(this)[0] === parent) {
                            currentIndex = index + 1;

                            found = true;
                        }

                        jQuery(parent).prop('id', id);
                        if(found === true) {
                            found = false;

                            jQuery('.col-md-4').removeClass('selected');
                            jQuery(parent).addClass('selected');

                            return;
                        }
                    });

                    if (currentIndex === lastIndex || (isInACourseGroup && eventName === 'Read less')) {
                        lastIndex = -1;

                        return;
                    }

                    jQuery(parent).children('.arrow').show(1, function() {
                        jQuery(element).slideDown();
                    });

                    /* Create the dimensions of the arrow on the slide down menu */
                    var assignmentContainerWidth = jQuery(parent).width();
                    // Set the sides of the triangle 
                    var triangleSideWidth = assignmentContainerWidth / 2;
                    jQuery(parent).children('.arrow').children('.point').css('border-left',
                                                      triangleSideWidth + "px solid transparent");
                    jQuery(parent).children('.arrow').children('.point').css('border-right',
                                                      triangleSideWidth + "px solid transparent");

                    // Set the height of the triangle and move it a bit down so it doesn't
                    // overlap anything
                    var triangleHeight = 40;
                    jQuery(parent).children('.arrow').children('.point').css('border-top',
                                                      triangleHeight + "px solid #e5e5e5");

                    // .75 is based on the height of .arrowTopBorder.
                    // So if the triangle is 40px high, needs to be shifted by 30px to not overlap
                    // if triangle is 20px high, needs to be shifted by 20px to not overlap.
                    // To make this clearer, replace .75 with something based on .arrowTopBorder height
                    var triangleShift = triangleHeight * 0.75; // .75 is arbitrary right meow.
                    jQuery(parent).children('.arrow').children('.point').css('top',
                                                      triangleShift + "px");

                    // Set endOfRow as current index - 1, as
                    endOfRow = currentIndex - 1;
                    modIndex = currentIndex % modulo; // modulo is 4 3 or 1 depending on columns

                    if(modIndex > 0) {
                        endOfRow = (currentIndex - modIndex) + (modulo - 1); // Subtract one from module because we are accessing array
                    }

                    lastChild = children[endOfRow];
                    if(typeof lastChild === 'undefined') {
                        lastChild = children[children.length - 1];
                    }

                    if (isInACourseGroup !== undefined && isInACourseGroup !== '' && isInACourseGroup !== null) {
                        target = jQuery($event.currentTarget);
                        parent = target.parents('.col-md-12');
                        currentIndex = parseInt(parent.prop('id').split('-')[1], 10) + 1;

                        //jQuery(parent).css('border-bottom', '6px solid #e5e5e5');

                        jQuery(element).slideDown();
                        jQuery(parent).children('.arrow').slideDown();

                        mover = jQuery(parent);

                    } else {
                        mover = jQuery(lastChild);
                    }

                    
                } else {
                    //This else handles the list view read more 
                    target = jQuery($event.currentTarget);
                    parent = target.parents('.col-md-12');

                    currentIndex = parseInt(parent.prop('id').split('-')[1], 10) + 1;

                    if(currentIndex === lastIndex) {
                        lastIndex = -1;

                        return;
                    }

                    jQuery(element).slideDown();
                   // jQuery(parent).children('.arrow').slideDown();

                    mover = jQuery(parent);
                }

                 scope.task = null;
                scope.getAssignment(assignment);

                // Assign english name to assignment
                if (assignment.type === 'wbt') {
                    scope.fullTypeName = 'Web Based';
                } else {
                    scope.fullTypeName = assignment.type;
                }

                if(jQuery($event.currentTarget).children().length > 0) {
                    jQuery($event.currentTarget).children('.more').html('Read less');
                } else {
                    jQuery($event.currentTarget).html('Read less');
                }

                jQuery(element).data('state', 'opened');

                jQuery(mover).after(jQuery(element));

                lastIndex = currentIndex;
            };

            scope.closeSlider = function() {
                jQuery('#singlecourse').slideUp(500, function() {
                    jQuery('.arrow').hide();

                    jQuery('.more').html('Read More');
                });

                lastIndex = -1;
            };

            scope.getAssignment = function (assignment) {
                scope.apiError = false;
                scope.singleIsLoading = true;

                var courseId = assignment.courseId || assignment.id;
                var courseType = assignment.type;
                scope.iltNoOfferingsMessage = false;

                /**
                 * This was changed to get single course, not get single assignment. This is not correct.
                 * This method must get a single assignment, to ensure proper retrieval of assignment based
                 * information for the student.
                 */
                // var singleCourse = CoursesService.getSingleCourse(courseId);
                var singleAssignment = AssignmentsService.fetchSingle(courseId, courseType);

                singleAssignment.then(
                    function (success) {
                        var propToCheck = "assignedById";
                        if (success.type === 'ilt') {
                            propToCheck = "assignedOffering";
                        }
                        // If there is an assignment and we're in the student role, use it
                        if (success[propToCheck] !== null && scope.role === 'student') {
                            success.isScheduled = false;
                            if (assignment.isInCourseGroup !== undefined && assignment.isInCourseGroup !== '') {
                                //within the context of a course group, the course is not droppable
                                success.isDroppable = ((success.type === 'ilt' || success.type === 'vle') && success.isDroppable);
                                success.isInCourseGroup = assignment.isInCourseGroup;
                                // EA-5142 If the assignment belongs to a career path and career path is assigned make career path the child course source.
                                // If not assigned, make source blank
                                if (scope.courseGroup !== undefined && typeof scope.courseGroup !== 'undefined') {
                                    if (scope.courseGroup.type === "careerPath") {
                                        if (scope.courseGroup.status !== 'unknown') {
                                            success.source = scope.courseGroup.name;
                                        } else {
                                            success.source = "";
                                        }
                                    }
                                }
                            } else {
                                success.source = passedInAssignment.source;
                            }
                            scope.assignment = success;

                            //LMS-5878 when type is ilt and assignedOffering is not null: overwrite offerings for this course
                            if (success.type === 'ilt' && success.assignedOffering !== null) {
                                var offeringsPromise = CoursesService.getSingleCourseOfferings(courseId);
                                offeringsPromise.then(function (success) {
                                    scope.assignment.offerings = success;
                                }, function (failure) {
                                    scope.apiError = true;
                                    scope.singleIsLoading = false;
                                });
                            }

                            scope.singleIsLoading = false;
                        }
                        // If there is not an assignment, get the course info
                        else {
                            if (success.type === 'ilt' || success.type === 'vle') {
                                // Saba will return offerings with the course, latitude has these in 2 different api calls, so we need to use 1 or the other here.
                                if (UsersService.isSaba()) {
                                    scope.getCourse(assignment);
                                } else {
                                    scope.getOfferings(assignment);
                                }
                            } else if (success.type === 'task') {
                                scope.assignment = assignment;

                                scope.getTask(assignment);
                            } else if (success.type === 'evaluation') {
                                scope.assignment = assignment;

                                scope.getEvaluation(assignment);
                            } else if (success.type === 'assessment') {
                                scope.assignment = assignment;

                                scope.getAssessment(assignment);
                            } else if (success.type === 'survey') {
                                scope.assignment = assignment;

                                scope.getSurvey(assignment);
                            } else {
                                scope.getCourse(assignment);
                            }
                        } // end else no assignedOn date so find info from course
                    },
            function (failure) { // Failed to find assignment
                        scope.apiError = true;
                        scope.singleIsLoading = false;

                        //scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                    }
                );
            };

            scope.showCompleteTaskButton = function () {
                if (scope.role === 'student' && scope.assignment !== 'undefined' && typeof scope.assignment !== 'undefined' && scope.assignment.type === 'task' && (scope.assignment.status === 'new' || scope.assignment.status === 'inProgress' || scope.assignment.status === 'successful')) {
                    if (passedInAssignment !== null && scope.courseGroup !== undefined && typeof scope.courseGroup !== 'undefined' && scope.courseGroup.id === passedInAssignment.parentCourseGroupId) {
                        if (scope.courseGroup.type === "careerPath") {
                            if (passedInAssignment.status === 'successful') {
                                return false;
                            } else {
                                if (scope.courseGroup.enforceOrder === true) {
                                    if (childCourseIndex !== 0) {
                                        return false;
                                    }
                                }
                            }
                        }
                    }

                    // Not career path
                    return true;
                }

                return false;
            };

            scope.showScheduleCourseButton = function () {
                var result = false;
                var flag = false;
                if (scope.assignment) {
                    // If scope.courseGroup eixsts
                    // If scope.courseGroup.id === assignment.parentCourseGroupid
                    //     If scope.courseGroup.enforceOrder === true
                    //         If course group course list index !== 0 (Not first course in list gets button)
                    //              Dont' show schedule button
                    if (passedInAssignment !== null && scope.courseGroup !== undefined && typeof scope.courseGroup !== 'undefined' && scope.courseGroup.id === passedInAssignment.parentCourseGroupId) {
                        if (scope.courseGroup.type === "careerPath") {
                            if (scope.courseGroup.enforceOrder === true) {
                                if (childCourseIndex !== 0) {
                                    return false;
                                }
                            }
                        }
                    }

                    if (scope.assignment.offerings) {                        
                        if (scope.role === 'student' && (!scope.assignment.assignedOffering || scope.assignment.status !== 'inProgress') && (scope.assignment.type === 'ilt' || scope.assignment.type === 'vle') && scope.assignment.offerings !== null && scope.assignment.offerings.length > 0) {
                            var futureOfferings = [];
                            _.each(scope.assignment.offerings, function (offering) {                                
                                if (offering.sessions) {
                                    if (offering.sessions.length > 0 && offering.status === "open") {
                                        var offerStartDate = _.sortBy(offering.sessions, "startDateTime")[0].startDateTime.jsDate;
                                        var today = new Date();
                                        if (offerStartDate > today) {
                                            result = true;
                                            //Add to the array of future offerings
                                            futureOfferings.push(offering);
                                        }
                                    }
                                }
                                
                                // check if there is any available offering that the user have not registered
                                if (!flag && !scope.offeringIsUserRegisteredILT(offering)) {
                                    flag = true;
                                }
                            });
                            scope.assignment.offerings = futureOfferings;
                        }
                    } // end if there is offerings
                } // end if there is an assignment
                return result && flag;
            };

            scope.completeAssignment = function (id, $event) {
                var completion = CoursesService.completeTask(id, UsersService.getId());
                scope.apiActionLoader = true;

                completion.then(function (success) {
                    scope.apiActionError = false;
                    scope.apiActionLoader = false;
                    scope.closeSlider();

                    $route.reload();

                }, function (failure) {
                    scope.apiActionError = true;
                    scope.apiActionLoader = false;
                    scope.closeSlider();

                    scope.apiErrorMessage = 'We\'re sorry! We couldn\'t complete the course. Please try again.';

                    setTimeout(function () {
                        scope.apiActionError = false;
                    }, 1000);
                });
            };

            scope.getCourse = function(assignment, useCourseId) {
                scope.apiError = false;
                scope.singleIsLoading = true;
                
                var courseId = useCourseId === true && typeof assignment.courseId !== 'undefined' ? assignment.courseId : assignment.id;

                var singleAssignment = CoursesService.getSingleCourse(courseId);

                singleAssignment.then(
                    function (success) {
                        success.isScheduled = false;

                        scope.assignment = success;
                            scope.singleIsLoading = false;
                        }, function (failure) {
                        scope.apiError = true;
                        scope.singleIsLoading = false;

                        //scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                    }
                );
            };

            scope.getTask = function(assignment) {
                scope.apiError = false;
                scope.singleIsLoading = true;

                var task = TasksService.fetchSingle(assignment.id, UsersService.getId());

                task.then(function(success) {
                    scope.task = success;

                    scope.singleIsLoading = false;
                }, function() {
                    scope.apiError = true;
                    scope.singleIsLoading = false;

                    //scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
            };

            scope.getEvaluation = function (assignment) {
                scope.apiError = false;
                scope.singleIsLoading = true;

                var evaluation = EvaluationService.fetchSingle(UsersService.getId(), assignment.offeringId);

                evaluation.then(function (success) {
                    scope.evaluation = success;
                    assignment.source = scope.evaluation.assignedBy;
                    scope.assignment.source = scope.evaluation.assignedBy;

                    scope.singleIsLoading = false;
                }, function (failure) {
                    scope.apiError = true;
                    scope.singleIsLoading = false;

                    //scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
            };

            scope.getSurvey = function (assignment) {
                scope.apiError = false;
                scope.singleIsLoading = true;

                var survey = SurveyService.fetchSingle(UsersService.getId(), assignment.offeringId);

                survey.then(function (success) {
                    scope.survey = success;

                    scope.singleIsLoading = false;
                }, function (failure) {
                    scope.apiError = true;
                    scope.singleIsLoading = false;

                    //scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
            };

            scope.getOfferings = function (assignment) {
                scope.apiError = false;
                scope.singleIsLoading = true;
                var courseId = typeof assignment.courseId !== 'undefined' && assignment.courseId !== null ? assignment.courseId : assignment.id;
                var coursePromise = CoursesService.getSingleCourse(courseId);
                var isInCourseGroup = assignment.isInCourseGroup;

                coursePromise.then(function (success) {
                    success.isScheduled = false;
                    scope.assignment = success;
                    scope.assignment.isInCourseGroup = isInCourseGroup;
                    var offeringsPromise = CoursesService.getSingleCourseOfferings(courseId);
                    return offeringsPromise.then(function (success) {
                        scope.assignment.offerings = success;
                        scope.assignment.offeringId = null;
                        scope.assignment.assignedOffering = null;
                        scope.singleIsLoading = false;
                        scope.iltNoOfferingsMessage = false;
                        if (scope.assignment.offerings.length === 0) {
                            scope.iltNoOfferingsMessage = true;
                        }
                    }, function (failure) {
                        scope.singleIsLoading = false;

                        if (failure.StatusCode === 404) {
                            //If the offerings get returns a 404, there is no error, there are just no offerings available
                            scope.iltNoOfferingsMessage = true;
                        } else {
                            scope.apiError = true;
                        }

                        // scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                    });
                }, function (failure) {
                    scope.apiError = true;
                    scope.singleIsLoading = false;
                    //  scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
                });
            };

            scope.requestApproval = function(courseId, type, offeringId) {
                scope.requestApprovalInProcess = true;

                var userId = UsersService.getId();

                jQuery('.managerApprovalRequired-' + courseId).toggle();
                jQuery('#launchCourseLoaderPlayButton-' + courseId).toggle();
                jQuery('#launchCourseLoader-' + courseId).toggle();
                jQuery('#showCourseLoader-' + courseId).toggle();

                var approval = CoursesService.requestManagerialApproval(courseId, type, userId, offeringId);

                scope.assignmentID = courseId;

                approval.then(function(success) {
                    var assignID = scope.assignmentID;
                    scope.apiMessage = 'Request for approval was successfully sent.';                    
                    jQuery('#appReqSubModal').show();
                    jQuery('#AvailableOfferings' + assignID).hide();
                    jQuery('#scheduleSuccess' + assignID).show();
                    scope.requestApprovalInProcess = false;
                    setTimeout(function () {
                        jQuery('#ScheduleCourseModal' + assignID).modal('hide');
                        jQuery('#scheduleSuccess' + assignID).hide();
                        $rootScope.$broadcast('slideDownDirective:refreshCurrentPage');
                    }, 1500);

                }, function (failure) {
                    scope.requestApprovalInProcess = false;
                    jQuery('#launchCourseLoaderPlayButton-' + courseId).toggle();
                    jQuery('#launchCourseLoader-' + courseId).toggle();
                    jQuery('.managerApprovalRequired-' + courseId).toggle();
                    jQuery('#showCourseLoader-' + courseId).toggle();
                    //check for SABA wbt manager approval pending response
                    if (failure.StatusCode === 500 && failure.ErrorMessage === "The person was successfully registered in the given offering but, cannot move forward until their manager approves the action") {
                        scope.apiMessage = 'Request for approval was successfully sent.';
                        jQuery('#fullSuccess-' + scope.assignmentID).show();

                        setTimeout(function () {
                            jQuery('#fullSuccess-' + scope.assignmentID).hide();
                            $rootScope.$broadcast('slideDownDirective:refreshCurrentPage');
                        }, 2000);
                    }
                    else {
                        if (failure.StatusCode === 409) { scope.apiScheduleErrorMessage = 'Sorry! Cannot register because there is a scheduling conflict.'; }
                        else {
                            scope.apiScheduleErrorMessage = 'Sorry! There was a problem requesting approval. Please try again.';
                        }

                        jQuery('#apiScheduleError' + scope.assignmentID).slideDown(500, function () {
                            var _this = jQuery(this);

                            setTimeout(function () {
                                _this.slideUp(500);

                            }, 2500);
                        });
                    }
                    });
            };

            scope.hideappReqSubModal = function () {
                jQuery('#appReqSubModal').hide();
                //$rootScope.$broadcast('slideDownDirective:refreshCurrentPage');
            };

            scope.scheduleOffering = function (assignmentId, assignmentType, sessionId, isInCourseGroup) {
                var offering = CoursesService.scheduleOffering(assignmentId, UsersService.getId(), assignmentType, sessionId);
                scope.assignmentID = assignmentId;

                offering.then(function (success) {

                    // If the course is in a course group, make a transcript for the course group as well.
                    if (isInCourseGroup) {
                        var enrollCoursePromise = CoursesService.getCourseUrl(isInCourseGroup, 'wbt', UsersService.getId()); // Getting the URL will also enroll (create transcript) for the course.
                        enrollCoursePromise.then(function (success) {
                            // Don't do anything. There is no url for the course group course but we still need to create a transcript
                        });
                    }

                    var obj = null;                   
                    var assignID = scope.assignmentID;

                    switch(success) {
                        case 'confirmed':
                        case 'inProgress':
                            obj = jQuery('#scheduleSuccess' + assignID);

                            scope.apiMessage = 'Registration for this course was successful.';
                            
                            break;
                        case 'waitlisted':
                            obj = jQuery('#waitlistedSchedSuccess' + assignID);

                            scope.apiMessage = 'The current course is full. You have been added to the course waitlist.';

                            break;
                    }
                    jQuery('#AvailableOfferings' + assignID).hide();
                    obj.show();

                    setTimeout(function () {
                        jQuery('#ScheduleCourseModal' + assignID).modal('hide');
                        obj.hide();
                        $rootScope.$broadcast('slideDownDirective:refreshCurrentPage');                        
                    }, 1500);
                   
                }, function (failure) {
                    
                    if (failure.ErrorMessage.indexOf('Prereq') > -1) {
                        scope.apiErrorMessage = 'Prerequisites need to be completed for registering for this course. Registration could not be completed.';
                    }
                    else if (failure.ErrorMessage.indexOf('it is full') > -1) {
                        scope.apiScheduleErrorMessage = 'The current course is full. Registration could not be completed.';
                    }
                    else if (failure.StatusCode === 409) { scope.apiScheduleErrorMessage = 'Sorry! Cannot register because there is a scheduling conflict.'; }
                    else {
                        scope.apiScheduleErrorMessage = 'Sorry! There was a problem scheduling your offering. Please try again.';
                    }

                    jQuery('#apiScheduleError' + scope.assignmentID).slideDown(500, function () {
                        var _this = jQuery(this);

                        setTimeout(function () {
                            _this.slideUp(500);
                        }, 2500);
                    });              
                    
                });
            };

            scope.getHtml = function(html, secondary){
                var string = typeof secondary !== 'undefined' ? secondary + html : html;

                if(typeof string === 'string') {
                    return $sce.trustAsHtml(string);
                }
            };

            scope.getMyPicks = function(item) {
                return item.isUserAssigned === true;
            };

            scope.hidePrereq = function () { jQuery('#preReqModal').hide(); };

            scope.offeringListOpener = function ($event, assignment) {

                // call Prereq api to see if any are there prereqs that the user needs to take before launching this course
                // if any are there, open modal and don't launch the course

                var prereqCall = AssignmentsService.getPrereqs(assignment.id);

                prereqCall.then(function (success) {

                    if (success.length === 0) {
                        //if nothing, do nothing, move on, open scheduling modal
                        jQuery('#ScheduleCourseModal' + assignment.id).modal('show');
                    } else {
                        //If there are prereqs - set the scoped variable for the prereq details             
                        scope.prereqModalData = success;
                        angular.element(document.querySelector('#preReqModal')).modal();
                    }

                }, function (failure) {
                    //if failure, show the schedule modal and their scheduling will catch that there is a prereq and show a failure message
                    jQuery('#ScheduleCourseModal' + assignment.id).modal('show');

                });
                
            };

            scope.offeringIsOpenForEnrollment = function(session) {
                var openToEnroll = false;
                var today = new Date();

                if (typeof session.requestedOn.jsDate === "undefined") {
                    session.requestedOn = dateUtil.formatDate(session.requestedOn);
                }

                if (session.requestedOn.jsDate <= today) {
                    openToEnroll = true;
                }

                return openToEnroll;
            };

            scope.isMaxWaitlistLimitReachedILTSearch = function (session) {
                var overTheMaxWaitlistLimit = false;
                if (session.waitlistedRosterCount >= session.maxWaitlistSize && session.attendingRosterCount >= session.maxAllowedSize) 
                { overTheMaxWaitlistLimit = true; }
                return overTheMaxWaitlistLimit;
            };

            _this.getStatusForOfferingFromRoster = function (offering) {
                var defaultStatus = 'notRegistered';
                var loggedinUser = UsersService.getId();
                //The roster now returns the total counts for attending and waitlisted students
                //If the logged in person is in the roster, their userid will be populated in the roster list
                //loop through the attending/waitlist/pendingApproval students and if a userID is not a dummy ie. 0
                //then return the roster status
                var attendingRoster = offering.roster.attendingStudents;
                for (var attend in attendingRoster) {
                    var atten = attendingRoster[attend];
                    if (typeof atten.id !== 'undefined' && atten.id !== 0 && loggedinUser === atten.id)
                    { return atten.rosterStatus; }
                }

                var waitlistRoster = offering.roster.waitlistStudents;
                for (var waitL in waitlistRoster) {
                    var wait = waitlistRoster[waitL];
                    if (typeof wait.id !== 'undefined' && wait.id !== 0 && loggedinUser === wait.id)
                    { return wait.rosterStatus; }
                }

                var pendingRoster = offering.roster.pendingApprovalStudents;
                for (var pender in pendingRoster) {
                    var pend = pendingRoster[pender];
                    if (typeof pend.id !== 'undefined' && pend.id !== 0 && loggedinUser === pend.id)
                    { return pend.rosterStatus; }
                }
                
                return defaultStatus;
            };

            scope.offeringIsUserRegisteredILT = function (session) {
                return _this.getStatusForOfferingFromRoster(session) !== 'notRegistered';
            };

        } // end link object
    }; // end directive return
}]);
