app.directive('baiUserProfileModal', [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/templates/users/home/modals/baiUserProfileModal.html',
        controller: ['$scope',
            function ($scope) {
                $scope.$on('openUserProfileModal', function (e) {
                    angular.element(document.querySelector('#viewUserProfile')).modal();
                    $scope.$broadcast("openUserProfileStats");
                });

                $scope.closeSubordinateProfileModal = function () {
                    angular.element(document.querySelector('#viewUserProfile')).modal('hide');
                };
            }]
    };
}]);