app.factory('courseFormatterUtil', [function () {
    return {
        getCourseCodeForImage: function (courseCode, tileImage) {
            ///regex the tileimage//image start + tileImage
            if (tileImage === null) {
                return 'tile_images/00000';
            }
            else {
                return tileImage;
            }
        }
    };
}]);