(function () {
    app.directive('studentCareerPathList', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/studentui/studentCareerPathList.html',
                transclude: true,
                scope: {
                    list: "="
                } ,
                controller: 'studentTilesController',
            };
        }
    ]);
})();