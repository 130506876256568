app.controller('offeringsController', ['$scope', '$location', '$route', '$window', 'errorDisplayerUtil', 'OfferingsService', 'RoleMapsMixin', 'RosterService', 'UsersService', function ($scope, $location, $route, $window, errorDisplayerUtil, OfferingsService, RoleMapsMixin, RosterService, UsersService) {
    var _this = this;

    var currentMethod = $route.current.params.name;

    $scope.data = $scope.offering = {};
    $scope.cancellingOffering = false;
    $scope.isLoading = false;
    $scope.offeringDate = new Date();

    /**
     * Set loading, error, etc. options onto the generic API action.
     *
     * @type {{}}
     */
    $scope.userSearchOptions = {};

    $scope.userSearchOptions.error = errorDisplayerUtil.create();
    $scope.userSearchOptions.searched = false;
    $scope.userSearchOptions.selected = 0;

    $scope.rosterStatus = 'default';
    $scope.selectedSession = 1;
    $scope.sessions = null;
    $scope.sessionOrder = 'lowestSessionDateForOffering.startDate';
    $scope.statuses = $scope.completionStatuses;


    /**** Load role specific functionality ****/
    /* This function takes the scope object
     * and overrides anything specific to the current page
     * or role.
     */
    RoleMapsMixin.overrideScope($scope);

    $scope[currentMethod + 'Action']();
}]);
