/**
 * Class to generalize and abstract how all errors should be piped to the front-end.
 */
app.factory('successDisplayerUtil', [function() {
    return {
        create: function() {
            var successDisplayer = function() {
                this.code = 200;
                this.message = null;
                this.neverHide = false;
                this.thrown = false;

                this.reset = function() {
                    this.code = 200;
                    this.message = null;
                    this.neverHide = false;
                    this.thrown = false;
                };

                this.set = function(message, neverHide) {
                    this.message = message;
                    this.neverHide = neverHide;
                    this.thrown = true;
                };
            };

            return new successDisplayer();
        }
    };
}]);