(function () {
    app.controller('publicCatalogHomeController', ['$rootScope', 'CatalogSearchParameters', publicCatalogHomeController]);

    function publicCatalogHomeController($rootScope, CatalogSearchParameters) {
        CatalogSearchParameters.productBreadcrumbs = [];
        CatalogSearchParameters.clearBreadcrumbs();
        $rootScope.metaTags.title = "BAI Training Catalog, Compliance, Learning Management, Professional Development, Financial Services";
        $rootScope.metaTags.description = "BAI gives financial services leaders confidence in managing compliance and a passion for professional development by providing powerful tools and subject matter expertise you can rely on. Search our courseware catalog!";
        $rootScope.metaTags.keywords = "BSA, AML, eLearning, Role-based, Microlearning, Regulation-based, FDIC, CFPB, Banking, Credit Union, Mortgage, Fintech, Regtech, HMDA, OFAC, TILA";

        $rootScope.consentCookieExists = function() {
            var cookies = document.cookie.split(';');
            for (var c in cookies) {
                if (cookies[c].includes("tracking_consent")) {
                    return true;
                }
            }
            return false;
        };

        $rootScope.addConsentCookie = function (consent) {
            var cookieEndDate = new Date();
            cookieEndDate.setDate(cookieEndDate.getDate() + 30);
            var cookie;
            if (consent) {
                cookie = "track";
            } else {
                cookie = "do_not_track";
            }
            document.cookie = "tracking_consent=" + cookie + "; expires= " + cookieEndDate.toUTCString();
            dataLayer.push({ 'event': 'consent_asked' });
        };
    }
})();