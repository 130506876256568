(function() {
    app.directive('baiScheduleCourseModal', [
        function() {
            return {
                restrict: 'E',
                scope: {
                    courseId: '='
                },
                templateUrl: 'App/templates/users/home/modals/ScheduleCourse.html',
                controller: ['$scope', '$q', '$route', 'CoursesService', 'UsersService', 'IltRosterUtilities', ScheduleCourseController]
            };
        }
    ]);

    function ScheduleCourseController($scope, $q, $route, CoursesService, UsersService, IltRosterUtilities) {
        $scope.assignment = { id: $scope.courseId };
        $scope.offeringIsUserRegisteredILT = offeringIsUserRegisteredILT;
        $scope.isMaxWaitlistLimitReachedILTSearch = isMaxWaitlistLimitReachedILTSearch;
        $scope.requestApproval = requestApproval;
        $scope.scheduleOffering = scheduleOffering;
        $scope.apiMessage = "";
        $scope.apiScheduleErrorMessage = "";

        $scope.$on('openIltScheduleModal', function (evt, params) {
            $scope.assignment = params.assignment;
            jQuery('#ScheduleCourseModal' + $scope.assignment.id).modal('show');
        });

        function offeringIsUserRegisteredILT(session) {
            return IltRosterUtilities.isUserRegisteredForOffering(session, UsersService.getId());
        }

        function isMaxWaitlistLimitReachedILTSearch(session) {
            var overTheMaxWaitlistLimit = false;
            if (session.waitlistedRosterCount >= session.maxWaitlistSize && session.attendingRosterCount >= session.maxAllowedSize)
            { overTheMaxWaitlistLimit = true; }
            return overTheMaxWaitlistLimit;
        }

        function requestApproval(courseId, type, offeringId) {
            var requestApprovalInProcess = true;

            var userId = UsersService.getId();

            // Loading bars
            jQuery('.managerApprovalRequired-' + courseId).toggle();
            jQuery('#showCourseLoader-' + courseId).toggle();

            var approval = CoursesService.requestManagerialApproval(courseId, type, userId, offeringId);

            var assignmentID = courseId;

            approval.then(function (success) {
                var assignID = assignmentID;
                $scope.apiMessage = 'Request for approval was successfully sent.';
                jQuery('#AvailableOfferings' + assignID).hide();
                jQuery('#scheduleSuccess' + assignID).show();
                requestApprovalInProcess = false;
                setTimeout(function () {
                    jQuery('#ScheduleCourseModal' + assignID).modal('hide');
                    jQuery('#scheduleSuccess' + assignID).hide();
                    $route.reload();
                }, 1500);

            }, function (failure) {
                requestApprovalInProcess = false;
                jQuery('#launchCourseLoaderPlayButton-' + courseId).toggle();
                jQuery('#launchCourseLoader-' + courseId).toggle();
                jQuery('.managerApprovalRequired-' + courseId).toggle();
                jQuery('#showCourseLoader-' + courseId).toggle();

                if (failure.StatusCode === 500 && failure.ErrorMessage === "The person was successfully registered in the given offering but, cannot move forward until their manager approves the action") {
                    $scope.apiMessage = 'Request for approval was successfully sent.';
                    jQuery('#fullSuccess-' + $scope.assignmentID).show();

                    setTimeout(function () {
                        jQuery('#fullSuccess-' + $scope.assignmentID).hide();
                        $route.reload();
                    }, 2000);
                }
                else {
                    if (failure.StatusCode === 409) { $scope.apiScheduleErrorMessage = 'Sorry! Cannot register because there is a scheduling conflict.'; }
                    else {
                        $scope.apiScheduleErrorMessage = 'Sorry! There was a problem requesting approval. Please try again.';
                    }

                    jQuery('#apiScheduleError' + assignmentID).slideDown(500, function () {
                        var _this = jQuery(this);

                        setTimeout(function () {
                            _this.slideUp(500);

                        }, 2500);
                    });
                }
            });
        }

        function scheduleOffering(assignmentId, assignmentType, sessionId, isInCourseGroup) {
            var offering = CoursesService.scheduleOffering(assignmentId, UsersService.getId(), assignmentType, sessionId);
            var assignmentID = assignmentId;

            offering.then(function (success) {

                if (isInCourseGroup) {
                    var enrollCoursePromise = CoursesService.getCourseUrl(isInCourseGroup, 'wbt', UsersService.getId()); // Getting the URL will also enroll (create transcript) for the course.
                    enrollCoursePromise.then(function (success) {
                        // Don't do anything. There is no url for the course group course but we still need to create a transcript
                    });
                }

                var obj = null;
                var assignID = assignmentID;

                switch (success) {
                    case 'confirmed':
                    case 'inProgress':
                        obj = jQuery('#scheduleSuccess' + assignID);

                        $scope.apiMessage = 'Registration for this course was successful.';

                        break;
                    case 'waitlisted':
                        obj = jQuery('#waitlistedSchedSuccess' + assignID);

                        $scope.apiMessage = 'The current course is full. You have been added to the course waitlist.';

                        break;
                }
                jQuery('#AvailableOfferings' + assignID).hide();
                obj.show();

                setTimeout(function () {
                    jQuery('#ScheduleCourseModal' + assignID).modal('hide');
                    obj.hide();
                    $route.reload();
                }, 1500);

            }, function (failure) {

                if (failure.ErrorMessage.indexOf('Prereq') > -1) {
                    $scope.apiScheduleErrorMessage = 'Prerequisites need to be completed for registering for this course. Registration could not be completed.';
                }
                else if (failure.ErrorMessage.indexOf('it is full') > -1) {
                    $scope.apiScheduleErrorMessage = 'The current course is full. Registration could not be completed.';
                }
                else if (failure.StatusCode === 409) { $scope.apiScheduleErrorMessage = 'Sorry! Cannot register because there is a scheduling conflict.'; }
                else {
                    $scope.apiScheduleErrorMessage = 'Sorry! There was a problem scheduling your offering. Please try again.';
                }

                jQuery('#apiScheduleError' + assignmentID).slideDown(500, function () {
                    var _this = jQuery(this);

                    setTimeout(function () {
                        _this.slideUp(500);
                    }, 2500);
                });

            });
        }
    }

})();