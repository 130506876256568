
app.controller('nmlsController', ['$compile', '$filter', '$location', '$rootScope', '$route', '$scope', '$sce', '$window', 'NMLSService', function ($compile, $filter, $location, $rootScope, $route, $scope, $sce, $window, NMLSService) {

    var action = $route.current.params.status;
    $scope.success = false;
    $scope.failure = false;
    
    if (action !== null && action.toLowerCase() === 'success') {
        //success - Show success message
        $scope.success = true;

    } else if (action !== null && action.toLowerCase() === 'openbiosighelppage') {

        var helpCall = NMLSService.getBioSigHelpPage();
        helpCall.then(function(success) {
            $window.location.href = success;
        }, function (failure) {
            $scope.failure = true;
        });
    }
    else {
        //failure - Show failure message
        $scope.failure = true;
    }

    
}]);
