app.service('PlaylistServices', ['$http', '$q', '$rootScope', 'AssignmentsService', 'dateUtil', 'CatalogSearchParameters', 'courseFormatterUtil', function ($http, $q, $rootScope, AssignmentsService, dateUtil, CatalogSearchParameters, courseFormatterUtil) {
    var _this = this;

    _this.getPlaylist = function () {
        var req = {
            method: 'GET',
            url: 'api/playlist',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.addCourseToPlaylist = function (courseId) {
        var req = {
            method: 'POST',
            url: 'api/playlist/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.deleteCourseFromPlaylist = function (courseId) {
        var req = {
            method: 'DELETE',
            url: 'api/playlist/' + courseId,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };
}]);