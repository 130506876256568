(function () {
    app.controller("reportsController", ["$scope", "$window", "UsersService", "SettingsService", reportsController]);

    function reportsController($scope, $window, UsersService, SettingsService) {
        $scope.homeTemplate = "app/templates/reports/home.html";
        $scope.isDocumentsEnabled = false;

        /*This will route users to the appropiate user manual based on active tab*/
        $scope.userManualHref = 'https://ld-connect.bai.org/learning-manager/reports-user-manual/';

        /*Verifies if user is a TA*/
        $scope.isAdmin = UsersService.user.taRoleOrgIds.length > 0 ? true : false;

        SettingsService.getSettings().then(function (success) {
            $scope.isDocumentsEnabled = SettingsService.isDocumentsEnabled();
            $scope.isPremium = SettingsService.isILTEnabled();
        }, function (failure) {
            // Do nothing, worst case, the DDR is not showing but other reports will be.
            // If settings call fails consistently, the app will have a lot of other issues.
        });


        $scope.launchReports = function (reportCalled) {
            var reportLinkCall = UsersService.getManagerSabaReportUrl(reportCalled);
            reportLinkCall.then(
                function (success) {
                    var link = success;
                    var popup = $window.open("about:blank", "_blank");
                    popup.location = link;
                },
                function (failure) {
                }
            );
        };

        $scope.openReportVideo = function (src, new_page_title) {

            var new_window = window.open('', '_blank', 'location=1,status=1,toolbar=1,scrollbars=1,resizeable=1,width=800,height=600');
            new_window.document.write('<!doctype html><html><head><title>' + new_page_title + '</title><meta charset="UTF-8" /></head><body><video controls style="width: 100%; height: auto;"><source src="' + src + '" target="_blank" type="video/mp4" /></video></body></html>');

        };
    }
})();