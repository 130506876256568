app.service('sortService', function() {
    var _this = this;

    // TODO: Make it work for non string types. Possibly convert to a string? Assume viewValue is always a string?
    // Sort in the order that contains the viewValue string first
    _this.sortByPropertyContains = function (arr, viewValue, propToFilter) {
        if (viewValue === undefined) {
            viewValue = "";
        }
        var result = _.sortBy(arr, function (s) {
            var index = s[propToFilter].toLowerCase().indexOf(viewValue.toLowerCase());
            // If the string doesn't exist return arbitrarily long number to put the result at the end
            // Assumption is that list passed in already contains viewValue but just in case it doesn't, this will make sure strings that don't contain it show up last
            return index === -1 ? 1000000 : index;
        });
        return result;
    };
});