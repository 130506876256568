(function () {
    app.controller('actionButtonController', ['$scope', '$route', '$timeout', '$window', '$q', 'CoursesService', 'AssessmentService', 'SurveyService', 'EvaluationService', 'UsersService', 'errorDisplayerUtil', 'AssignmentsService', 'IltRosterUtilities', actionButtonController]);

    function actionButtonController($scope, $route, $timeout, $window, $q, CoursesService, AssessmentService, SurveyService, EvaluationService, UsersService, errorDisplayerUtil, AssignmentsService, IltRosterUtilities) {
        $scope.assignment = {};
        $scope.actionText = "";
        $scope.statusText = "";
        $scope.successMessage = "";
        $scope.isLoading = false;
        $scope.productError = errorDisplayerUtil.create();
        $scope.hidePrereq = function () { jQuery('#preReqModal' + $scope.assignment.id).hide(); };
        $scope.hideAttached = function () { jQuery('#attachedModal' + $scope.assignment.id).hide(); };
        $scope.executeAction = function () { };
        $scope.currentRoute = "";
        $scope.attachTriggered = false;

        var launchCourseCallback = null;
        var courseUrlPromise = {};
        var closeAction = function () { };
        var launchedCourse = null;
        var concurrentOpen = false;

        $q.all($scope.assignmentPromises).then(function (success) {
            $scope.assignment = success[0];
            var openOfferings = _.where(success[1], { status: "open" });
            $scope.assignment.offerings = findFutureOfferingsWithSessions(openOfferings);

            //This assigns the career path status to the same variable that is normally used by all the other course types.
            if ($scope.assignment.type === "careerPath" && $scope.assignment.careerPathStatus !== undefined) {
                $scope.assignment.status = $scope.assignment.careerPathStatus;
            }
            configureActionButton();
        });

        function findFutureOfferingsWithSessions(openOfferings) {
            var futureOfferings = [];
            _.each(openOfferings, function (offering) {
                if (offering.sessions) {
                    if (offering.sessions.length > 0) {
                        var offerStartDate = _.sortBy(offering.sessions, "startDateTime")[0].startDateTime.jsDate;
                        var today = new Date();
                        if (offerStartDate > today) {
                            futureOfferings.push(offering);
                        }
                    }
                }
            });
            return futureOfferings;
        }

        function configureActionButton() {
            if ($route.current.params.param !== undefined && $route.current.params.param !== null) {
                var params = $route.current.params.param.split('/');
                if (params.length > 0) {
                    $scope.currentRoute = params[0].toLowerCase();
                }
            }
            switch ($scope.assignment.type) {
                case "wbt":
                    configureWbt();
                    setActionButtonBehavior();
                    break;
                case "assessment":
                    configureAssessment();
                    setActionButtonBehavior();
                    break;
                case "survey":
                    configureSurvey();
                    setActionButtonBehavior();
                    break;
                case "evaluation":
                    configureEvaluation();
                    setActionButtonBehavior();
                    break;
                case "ilt":
                    configureIlt();
                    setActionButtonLaunchIlt();
                    break;
                case "courseGroupCertification":
                    configureCourseGroupFamily();
                    break;
                case "courseGroup":
                    configureCourseGroupFamily();
                    break;
                case "courseGroupCurricula":
                    configureCourseGroupFamily();
                    break;
                case "careerPath":
                    configureCareerPath();
                    break;
                case "task":
                    configureTask();
                    break;
                case "mP4":
                case "mP3":
                case "pdf":
                case "excel":
                case "word":
                case "url":
                case "other":
                    configureDocument();
                    setActionButtonBehavior();
                    break;
                default:
                    break;
            }
        }

        function configureWbt() {
            if ($scope.assignment.resourceLaunchWithContent === null || $scope.assignment.resourceLaunchWithContent === 0)
            {
                courseUrlPromise = executeWbtPromise;
                closeAction = wbtCloseAction;
                $scope.executeAction = checkPrereqsAndLaunch;
            }
            else
            {
                courseUrlPromise = executeWbtAttachPromise;
                closeAction = attachCloseAction;
                $scope.executeAction = checkPrereqsAndLaunch;
            }
        }

        function executeWbtPromise() {
            CoursesService.getCourseUrl($scope.assignment.id, $scope.assignment.type, UsersService.getId()).then(success, failure);
        }

        function executeWbtAttachPromise() {
            var assignment = $scope.assignment;
            assignment.courseAssets.forEach(function (asset) {
                if (asset.id === assignment.resourceLaunchWithContent || asset.courseId === assignment.resourceLaunchWithContent) {
                    CoursesService.getCourseUrl(parseInt(asset.courseId), asset.type, UsersService.getId()).then(success, failure);
                }
            });
        }

        function configureIlt() {
            $scope.executeAction = checkPrereqsAndLaunch;
        }

        function configureAssessment() {
            courseUrlPromise = executeAssessmentPromise;
            closeAction = nonWbtCloseAction;
            $scope.executeAction = launch;
        }
        function executeAssessmentPromise() {
            AssessmentService.fetchSingle(UsersService.getId(), $scope.assignment.id).then(success, failure);
        }

        function configureSurvey() {
            courseUrlPromise = executeSurveyPromise;
            closeAction = nonWbtCloseAction;
            $scope.executeAction = launch;
        }
        function executeSurveyPromise() {
            SurveyService.fetchSingle(UsersService.getId(), $scope.assignment.id).then(success, failure);
        }

        function configureEvaluation() {
            courseUrlPromise = executeEvaluationPromise;
            closeAction = nonWbtCloseAction;
            $scope.executeAction = launch;
        }
        function executeEvaluationPromise() {
            EvaluationService.fetchSingle(UsersService.getId(), $scope.assignment.id).then(success, failure);
        }

        function configureCareerPath() {
            //For career paths, the course id is either the carreer course id or the id, depending if it is manager approval requested or assigned.
            if ($scope.assignment.id === undefined) {
                $scope.assignment.id = $scope.assignment.careerPathCourseID;
            }

            if ($scope.assignment.isManagerApprovalRequired === false && $scope.assignment.status === undefined) {

                $scope.actionText = "Add Career Path";

                $scope.executeAction = assignCareerPath;
            } else if ($scope.assignment.isManagerApprovalRequired === true || $scope.assignment.status === "pendingApproval") {
                setActionButtonCourseGroupManagerApproval();
            } else {
                $scope.actionText = "";
            }
        }

        function configureCourseGroupFamily() {
            if ($scope.assignment.isManagerApprovalRequired === false && $scope.assignment.status === "unknown") {
                $scope.actionText = "Add to Electives";
                
                $scope.executeAction = assignCourseGroup;

            } else if ($scope.assignment.isManagerApprovalRequired === true || $scope.assignment.status === "pendingApproval") {
                setActionButtonCourseGroupManagerApproval();
            } else {
                $scope.actionText = "";
            }

        }

        function configureTask() {
            $scope.actionText = "Complete Task";
            $scope.executeAction = completeTask;
        }

        function configureDocument() {
            $scope.actionText = "Launch";
            courseUrlPromise = executeDocumentPromise;
            closeAction = nonWbtCloseAction;
            $scope.executeAction = launch;
        }

        function executeDocumentPromise() {
            CoursesService.getCourseUrl($scope.assignment.id, $scope.assignment.type, UsersService.getId()).then(success, failure);
        }

        function success(success) {
            var assignment = $scope.assignment;
            var uri = null;

            if (typeof success !== 'undefined' && typeof success.uri !== 'undefined') {
                uri = success.uri;
            } else if (typeof success !== 'undefined' && typeof success.contentLaunchUrl !== 'undefined') {
                uri = success.contentLaunchUrl;
            } else {
                uri = success;
            }
            var uriAfterOriginCheck = uri.startsWith("http") ? uri : addOriginInfo(uri);
            launchedCourse = window.open(uriAfterOriginCheck);

            //open resource flaged to launch with content type URL at the same time
            concurrentOpen = AssignmentsService.checkForCuncurrentOpen(assignment);

            //If there is a resource flaged to launch with content, then open that resource
            if (assignment.resourceLaunchWithContent !== 0 && typeof assignment.courseAssets !== "undefined" && assignment.courseAssets !== null) {
                assignment.courseAssets.forEach(function (asset) {
                    if (asset.id === assignment.resourceLaunchWithContent || asset.courseId === assignment.resourceLaunchWithContent) {
                        //new style launch
                        if (asset.launchUri === null && $scope.attachTriggered === false) {
                            $scope.attachTriggered = true;
                            angular.element(document.querySelector('#attachedModal' + $scope.assignment.id)).modal();
                        }
                    }
                        //old style launch
                    else {
                        setTimeout(function () {
                            $window.open(resourceUri, "popup", "width=900,height=600,left=200,top=100");
                        }, 1500);
                    }
                    
                });
            }
            closeAction();
        }

        function failure(failure) {
            setErrorMessage(failure);
        }

        function wbtCloseAction() {
            var closeID = $scope.assignment.id;
            $scope.attachTriggered = false;

            var timer = setInterval(function () {
                if ((launchedCourse !== null && launchedCourse.closed) || launchedCourse === null) {
                    clearInterval(timer);

                    // set base url as absolute path
                    var url = '/';

                    // get all base tags
                    var bases = document.getElementsByTagName('base');

                    // if there are any reset url to be base 0's href; this way we don't break the Url pattern
                    if (bases.length > 0) {
                        url = bases[0].href;
                    }


                    var transcript = AssignmentsService.fetchSingle(closeID, 'wbt');
                    transcript.then(function (transcript) {
                        var typeParam = "assignments";
                        url += 'courses/certificate/' + typeParam + '/' + transcript.transcriptId + '/';

                        $window.location.href = url;
                    });
                }
            }, 500);
        }

        function attachCloseAction() {
            var timer = setInterval(function () {
                if ((launchedCourse !== null && launchedCourse.closed) || launchedCourse === null || concurrentOpen) {
                    clearInterval(timer);
                    $scope.hideAttached();
                    courseUrlPromise = executeWbtPromise;
                    closeAction = wbtCloseAction;
                    $scope.executeAction = checkPrereqsAndLaunch;
                    $scope.executeAction();
                }
            }, 500);
        }

        function nonWbtCloseAction() {
            var refreshTimer = setInterval(function () {
                if (launchedCourse.closed) {
                    clearInterval(refreshTimer);
                    $window.location.reload();
                }
            }, 500);
        }

        function setActionButtonBehavior() {
            //add NMLS enrollment case
            if ($scope.assignment.isManagerApprovalRequired === true || $scope.assignment.status === "pendingApproval") {
                setActionButtonManagerApproval();
            } else {
                setActionButtonLaunchCourse();
            }
        }

        function setActionButtonManagerApproval() {
            if ($scope.assignment.status === "pendingApproval") {
                $scope.actionText = "";
                $scope.statusText = "Pending Approval";
            } else if ($scope.assignment.status === 'new' || $scope.assignment.status === 'unsuccessful' || $scope.assignment.status === 'successful' || $scope.assignment.status === 'unknown' || $scope.assignment.status === 'waived') {
                $scope.actionText = "Request";
                $scope.executeAction = requestManagerApprovalWbt;
            } else {
                setActionButtonLaunchCourse();
            }
        }

        function setActionButtonLaunchCourse() {
            if ($scope.assignment.type === "courseGroup" || $scope.assignment.type === "courseGroupCertification" || $scope.assignment.type === "courseGroupCurricula") {
                $scope.actionText = "";
            } else {
                $scope.actionText = "Launch";
                launchCourseCallback = launchCourse;
            }
        }

        function setActionButtonLaunchIlt() {
            if ($scope.assignment.status === "pendingApproval") {
                $scope.statusText = "Pending Approval";
            } else if (isHideActionButtonIlt()) {
                $scope.actionText = "";
                $scope.statusText = "";
            } else {
                $scope.actionText = "Schedule Course";
                launchCourseCallback = showIltSchedule;
            }
        }

        function isHideActionButtonIlt() {
            var i = 0;
            var result = true;
            for (i = 0; i < $scope.assignment.offerings.length; i++) {
                if (!offeringIsUserRegisteredILT($scope.assignment.offerings[i])) {
                    result = false;
                }
            }
            return result || $scope.assignment.offerings.length === 0 || ($scope.assignment.assignedOffering && hasAssignmentStarted());
        }

        function hasAssignmentStarted() {
            return $scope.assignment.status === "inProgress" || $scope.assignment.status === "waitlisted";
        }

        function offeringIsUserRegisteredILT(session) {
            return IltRosterUtilities.isUserRegisteredForOffering(session, UsersService.getId());
        }

        function showIltSchedule() {
            $scope.isLoading = false;
            $scope.$broadcast("openIltScheduleModal", { assignment: $scope.assignment });
        }

        function launch() {
            $scope.isLoading = true;
            launchCourseCallback();
        }

        function checkPrereqsAndLaunch() {
            var prereqCall = AssignmentsService.getPrereqs($scope.assignment.id);
            var assignmentLaunched = $scope.assignment;

            $scope.isLoading = true;
            prereqCall.then(function (success) {
                if (success.length === 0) {
                    launchCourseCallback();
                } else {
                    $scope.prereqModalData = success;
                    angular.element(document.querySelector('#preReqModal'+$scope.assignment.id)).modal();
                    $scope.isLoading = false;
                }
            }, function (failure) {
                setErrorMessage(failure);
            });
        }

        function launchCourse() {
            var assignment = $scope.assignment;
            if (assignment.isInCourseGroup) {
                var enrollCoursePromise = CoursesService.getCourseUrl(assignment.isInCourseGroup, assignment.type, UsersService.getId()); // Getting the URL will also enroll (create transcript) for the course.
                enrollCoursePromise.then(function (success) {
                    var temp = success;
                });
            }
            $scope.launcherUrlTitle = assignment.name;
            courseUrlPromise();
        }

        function assignCourseGroup() {
            assignCourseGroupOrCareerPath("Course successfully added to Electives");
        }

        function assignCareerPath() {
            assignCourseGroupOrCareerPath("Course successfully added to Career Paths");
        }

        function assignCourseGroupOrCareerPath(successMessage) {
            $scope.isLoading = true;
            var assignCourseGroups = AssignmentsService.assignCourseGroupCourses(UsersService.getId(), $scope.assignment.id);
            assignCourseGroups.then(function (success) {
                $scope.assignment.status = "new";
                $scope.actionText = "";
                $scope.successMessage = successMessage;
                $scope.isLoading = false;
                $timeout(function () {
                    $scope.successMessage = "";
                }, 1500);
            }, function (failure) {
                setErrorMessage(failure);
            });
        }

        function requestManagerApprovalCourseGroup() {
            $scope.isLoading = true;

            var assignCourseGroups = AssignmentsService.assignCourseGroupCourses(UsersService.getId(), $scope.assignment.id);
            assignCourseGroups.then(function (success) {

                var approval = CoursesService.requestManagerialApproval($scope.assignment.id, $scope.assignment.type, UsersService.getId(), 0);
                approval.then(function (success) {
                    $scope.isLoading = false;
                    $scope.actionText = "";
                    if ($scope.assignment.type.toLowerCase() === "careerpath") {
                        $scope.assignment.careerPathStatus = "pendingApproval";
                    } else {
                        $scope.assignment.status = "pendingApproval";
                    }
                    $scope.statusText = "Pending Approval";
                }, function (failure) {
                    setErrorMessage(failure);
                });
                $timeout(function () {
                    $scope.successMessage = "";
                }, 1500);
            }, function (failure) {
                setErrorMessage(failure);
            });
        }

        function setActionButtonCourseGroupManagerApproval() {
            if ($scope.assignment.status === "pendingApproval") {
                $scope.actionText = "";
                $scope.statusText = "Pending Approval";
            } else if ($scope.assignment.status === 'new' || $scope.assignment.status === 'unsuccessful' || $scope.assignment.status === 'successful' || $scope.assignment.status === 'unknown' || $scope.assignment.status === 'waived' || $scope.assignment.status === undefined) {
                $scope.actionText = "Request";
                $scope.executeAction = requestManagerApprovalCourseGroup;
            }
        }

        function setErrorMessage(failure) {
            $scope.isLoading = false;
            $scope.statusText = "";
            $scope.productError.setError(failure.ErrorCode, failure.ErrorMessage);
            $timeout(function () {
                $scope.productError.reset();
            }, 3000);
        }

        function addOriginInfo(uri) {
            /**
             * Origin is undefined in IE, which it then takes and uses as a string to concatenate.
             * We need to ensure that location.origin is set, so we don't get funky URLs.
             */
            var origin = typeof window.location.origin !== 'undefined' ? window.location.origin : '';

            if (uri.indexOf(origin) === -1) {
                uri = origin + uri;
            }

            return uri;
        }

        function requestManagerApprovalWbt() {
            $scope.isLoading = true;
            var approval = CoursesService.requestManagerialApproval($scope.assignment.id, $scope.assignment.type, UsersService.getId(), 0);
            approval.then(function (success) {
                $scope.isLoading = false;
                $scope.actionText = "";
                $scope.statusText = "Pending Approval";
                $scope.assignment.status = "pendingApproval";
            }, function (failure) {
                setErrorMessage(failure);
            });
        }

        function completeTask() {
            var completion = CoursesService.completeTask($scope.assignment.id, UsersService.getId());
            $scope.isLoading = true;

            completion.then(function (success) {
                $scope.actionText = "";
                $scope.successMessage = "Task completed successfully";
                $scope.isLoading = false;
                $timeout(function () {
                    $scope.successMessage = "";
                    window.location.reload();
                }, 1500);

            }, function (failure) {
                setErrorMessage(failure);
            });
        }
    }

})();