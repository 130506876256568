app.service('CategoriesService', ['$http', '$q', '$rootScope', '$filter', 'dateUtil', function ($http, $q, $rootScope, $filter, dateUtil) {
    var _this = this;

    _this.response = null;
    _this.filterName = null;
    _this.allCustomCategoriesForPortal = null;
    _this.fullCatalog = null;

    _this.fetch = function (filterName, refresh, shouldFormatCustom) {

        _this.filterName = filterName || null;

        if (refresh === undefined) {
            refresh = true;
        }

        if (_this.allCustomCategoriesForPortal !== undefined && _this.allCustomCategoriesForPortal !== null && !refresh && _this.filterName === null && shouldFormatCustom) {
            return $q.resolve(_this.allCustomCategoriesForPortal);
        } else if (_this.fullCatalog !== undefined && _this.fullCatalog !== null && !refresh && !shouldFormatCustom) {
            return $q.resolve(_this.fullCatalog);
        } else {
            var endpoint = 'api/category/';
            if ($rootScope.fromWeb === true) {
                endpoint = 'api/public/category/';
            }
            var req = {
                method: 'GET',
                url: endpoint,
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'max-age=86400'
                }
            };

            return $http(req).then(function (success) {
                _this.response = _this.filterByName(success.data);
                if (shouldFormatCustom) {
                    _this.response = _this.formatForManage(_this.response);
                }
                return _this.response;
            }, function (failure) {
                throw failure;
            });
        }
    };

    _this.filterByName = function (data) {
        if (_this.filterName !== null) {
            var filteredResponse = [];
            for (var i in data) {
                if (data[i].name === _this.filterName) {
                    filteredResponse.push(data[i]);
                    break;
                }
            }
            return filteredResponse;
        }

        _this.fullCatalog = data;
        return data;
    };

    /* Formatter for allCustomCategoriesForPortal service layer object - from categories get */
    _this.formatForManage = function (data) {
        var cats = [];
        var displayCategories = [];

        data.forEach(function (s) {
            if (s.name === "Custom Content" || s.name === "Custom Documents" || s.name === "Policies") {
                s.children.forEach(function (e) {
                    e.contentType = (s.name === "Custom Content" ? "Course" : "Document");
                    e.name = (s.name === "Policies" ? (e.name + " (Policies)") : e.name);
                    cats.push(e);
                });
            }
        }); 

        if (cats !== null) {
            cats.forEach(function (m) {
                if (m.children !== null) {
                    m.children.forEach(function (child) {
                        var lineItem = { 'subcategoryName': child.name, 'categoryname': m.name, 'description': child.description, 'categoryID': m.id, 'categoryNodeID': child.parentNodeId, 'canEdit': child.isEditable, 'isActive': child.active, 'subcategoryID': child.id, 'imageName': child.image, 'endDate': (child.endDate === null ? null : dateUtil.formatDate(child.endDate)), 'categoryType': m.contentType }; 
                        displayCategories.push(lineItem);
                    });
                }
            });
        }

        _this.allCustomCategoriesForPortal = displayCategories;
        return displayCategories;
    };

    _this.newSubcategory = null;
    _this.createCategory = function (subcategory) {

        //Create new subcategory object that matches subcategories in the UI - to be used on success to add to this service's allCustomCategoriesForPortal
        _this.newSubcategory = _this.formatForSubcategory(subcategory);

        var req = {
            method: 'POST',
            url: 'api/category/',
            data: _this.formatToSend(subcategory),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return $http(req).then(function (success) {
            _this.newSubcategory.subcategoryID = success.data.id;
            /* add the category type */
            _this.addCategoryType(_this.newSubcategory); 
            //Add this new subcategory to allCustomCategoriesForPortal so that it appears on the Custom Content view
            _this.allCustomCategoriesForPortal.push(_this.newSubcategory);
            return success;
        }, function (failure) {
            throw failure;
        });

    };

    _this.editCategory = function (subcategory) {

        //Create new subcategory object that matches subcategories in the UI - to be used on success to add to this service's allCustomCategoriesForPortal
        _this.newSubcategory = _this.formatForSubcategory(subcategory);

        var req = {
            method: 'PUT',
            url: 'api/category/',
            data: _this.formatToSend(subcategory),
            headers: {
                'Content-Type': 'application/json' 
            }
        };

        return $http(req).then(function (success) {
            //update the service layer variable to reflect the changes
            if (_this.allCustomCategoriesForPortal !== undefined && _this.allCustomCategoriesForPortal !== null) {

                //On successful update - going to remove the edited subcategory and re-add it to allCustomCategoriesForPortal
                var idxCat = _.findIndex(_this.allCustomCategoriesForPortal, { 'subcategoryID': parseInt(success.data.id) });
                if (idxCat >= 0) { _this.allCustomCategoriesForPortal.splice(idxCat, 1); }
                _this.addCategoryType(_this.newSubcategory);
                _this.allCustomCategoriesForPortal.push(_this.newSubcategory);
            }
            return success;
        }, function (failure) {
            throw failure;
        });

    };

    _this.addCategoryType = function (subcategory) {
        /* get the category type based off of the sibling's category type */
        var sisterSubcategory = $filter('filter')(_this.allCustomCategoriesForPortal, { categoryID: subcategory.categoryID });
        if (sisterSubcategory.length > 0) { subcategory.categoryType = sisterSubcategory[0].categoryType; }
    }; 

    /* formatter for this service layer subcategory to add to allCustomCategoriesForPortal */
    _this.formatForSubcategory = function (subcategory) {
        var subcategoryId = ((subcategory.subcategoryID === undefined || subcategory.subcategoryID === null) ? null : subcategory.subcategoryID);
        var subEndDate = ((subcategory.endDate === undefined || subcategory.endDate === null) ? null : dateUtil.formatDate(subcategory.endDate));
        var subIsActive = ((subcategory.isActive === undefined || subcategory.isActive === null) ? true : subcategory.isActive);
                                                                                                                                                                                                                                                    
        return { 'subcategoryName': subcategory.subcategoryName, 'categoryname': subcategory.category.categoryName, 'description': subcategory.description, 'categoryID': subcategory.category.categoryID, 'canEdit': true, 'isActive': subIsActive, 'subcategoryID': subcategoryId, 'categoryNodeID': subcategory.category.categoryNodeID, 'imageName': subcategory.imageName, 'endDate': subEndDate };
    };

    _this.formatToSend = function (subcategory) {

        var cat = {};
        cat.parentId = subcategory.category.categoryID;
        cat.name = subcategory.subcategoryName;
        cat.description = (subcategory.description === undefined ? '     ' : subcategory.description);
        cat.active = subcategory.isActive;
        cat.endDate = ((subcategory.endDate === undefined || subcategory.endDate === null) ? null : dateUtil.formatDate(subcategory.endDate).fullDate);
        cat.parentNodeId = subcategory.category.categoryNodeID;
        cat.image = subcategory.imageName;
        if (subcategory.subcategoryID !== undefined && subcategory.subcategoryID !== null) {
            cat.id = subcategory.subcategoryID;
        }

        return cat;



    };

    _this.RemoveDocumentPolicies = function (data) {
        var nonPolicies = [];
        data.forEach(function (m) {
            if (!m.categoryname.includes("(Policies)")) {
                nonPolicies.push(m);
            }
        });
        return nonPolicies;
     };

}]);
