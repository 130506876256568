/* This controls the profile page */
app.controller('accountController', ['$location', '$scope', '$window', '$timeout', '$filter', 'BreadcrumbsService', 'CountriesService', 'StatesService', 'UsersService', 'TranscriptsService', 'SettingsService', 'CoursesService', function ($location, $scope, $window, $timeout, $filter, BreadcrumbsService, CountriesService, StatesService, UsersService, TranscriptsService, SettingsService, CoursesService) {
    $scope.user = UsersService;

    $scope.apiError = false;
    $scope.isLoading = false;
    $scope.success = false;
    $scope.passwordChangeSuccess = false;
    $scope.showPasswordForm = false;
    $scope.showEditProfileForm = false;
    $scope.isSaba = false;
    $scope.countryId = null;
    $scope.StateId = null;
    $scope.latitudeCountryList = null;
    $scope.stateList = [];
    $scope.isSaba = false;
    $scope.isLoadImmediate = true;
    $scope.userId = UsersService.getId();
    $scope.homeTemplate = "app/templates/account/index.html";
    $scope.settings = { displayTime: $scope.user.displayTime() };

    /* Training Insights and Career Path access determined by the organization settings */
    var sett = SettingsService.getSettings().then(function (success) {
        $scope.showResponsibilities = SettingsService.isTrainingInsightsEnabled();
        //if dashboard is disabled, Jason wants this disabled
        $scope.hasCareerPaths = SettingsService.isCareerPathDashboardEnabled();
    });


    $scope.loadStateAndCountry = function () {
        if (typeof $scope.user.getCountryId() === 'undefined' || $scope.user.user.homeAddress === null) {
            $scope.user.user.homeAddress = {};
        }

        CountriesService.getLatitudeCountryList(UsersService.getPortalId()).then(
        function (latitudeCountryList) {
            $scope.latitudeCountryList = latitudeCountryList;

            var countryId = $scope.user.getCountryId();
            if (typeof countryId !== 'undefined' && countryId !== null) {
                StatesService.getLatitudeStateList(countryId).then(
                function (stateList) {
                    $scope.stateList = stateList;
                    $scope.user.user.homeAddress.stateId = $scope.user.getStateId();
                });
            }
        });
    };


    $scope.user.getAddress();
    $scope.loadStateAndCountry();
    
    $scope.loadStates = function (countryId) {
        if (countryId === null) {
            $scope.stateList = [];
            $scope.user.stateId = null;
        }
        else {
            StatesService.getLatitudeStateList(countryId).then(function (stateList) { 
				$scope.stateList = stateList; 
			});
        }
    };

    // Use these wrapper functions to show/hide menus
    // Instead of booleans.
    // Booleans can quickly become unmanageable.
    $scope.resetMessages = function() {
        $scope.apiError = false;
        $scope.isLoading = false;
        $scope.success = false;
        $scope.passwordChangeSuccess = false;
        $scope.profileUpdateSuccess = false;
    };

    $scope.clearDisplays = function() {
        $scope.resetMessages();
        $scope.showPasswordForm = false;
        $scope.showEditProfileForm = false;
    };

    $scope.displayLoading = function() {
        $scope.resetMessages();
        $scope.isLoading = true;
    };

    $scope.displayApiError = function() {
        $scope.resetMessages();
        $scope.apiError = true;
    };

    $scope.displaySuccess = function() {
        $scope.resetMessages();
        $scope.success = true;
    };

    $scope.displayPasswordChangeSuccess = function() {
        $scope.resetMessages();
        $scope.passwordChangeSuccess = true;
    };

    $scope.displayProfileUpdateSuccess = function () {
        $scope.resetMessages();
        $scope.profileUpdateSuccess = true;
    };
    // End wrappers

    $scope.toggleEditProfileForm = function () {
        $scope.showPasswordForm = false;
        if ($scope.showEditProfileForm === false) {
            $scope.settings.displayTime = $scope.user.displayTime();
        }
        $scope.showEditProfileForm = !$scope.showEditProfileForm;
    };
    $scope.togglePasswordForm = function () {
        $scope.showEditProfileForm = false;
        $scope.showPasswordForm = !$scope.showPasswordForm;
    };

    $scope.changePassword = function() {
        $scope.displayLoading();

        var passwordChange = UsersService.resetPassword(this.baiform.password.$viewValue);

        passwordChange.then(function() {
            // Remove refresh and trigger a okay message
            //$window.location.reload();
            $scope.displayPasswordChangeSuccess();
        }, function() {
            $scope.displayApiError();
        }).then(function() {
            $timeout(function() {
                jQuery('#showForm').toggle();
                $scope.clearDisplays();
                //$scope.password = null;
                //$scope.passwordmatch = null;
            }, 3000);
        });
    };

    $scope.editProfile = function() {
        $scope.displayLoading();

        UsersService.user.displayDueDateTime = $scope.settings.displayTime;

        var profile = UsersService.editMyProfile();
        
        profile.then(function () {
            $scope.user.getAddress();
            $scope.loadStateAndCountry();
            $scope.displayProfileUpdateSuccess();
            
        }, function() {
            $scope.displayApiError();
        }).then(function () {
            $timeout(function () {
                jQuery('#showForm').toggle();
                $scope.clearDisplays();
            }, 3000);
        });
    };

    $scope.clearForm = function() {
        $window.location.reload();
    };

    if ((typeof $location.search().resetpw !== 'undefined' && $location.search().resetpw === 'true') || UsersService.userIsChangingPw === true) {
        $scope.togglePasswordForm();
        UsersService.setChangingPwFlag(false);
    } else if (UsersService.userIsEditingProfile === true) {
        $scope.toggleEditProfileForm();
        UsersService.setEditProfileFlag(false);
    }

    BreadcrumbsService.createHomeBreadcrumb(UsersService.homeRole);
    BreadcrumbsService.pushCrumb('profile');
}]);
