app.directive("messagingBuilder", function () {
    var templates = {
        error: {
            template: 'app/templates/components/messaging/error.html'
        },
        errorMicro: {
            template: 'app/templates/components/messaging/error-micro.html'
        },
        success: {
            template: 'app/templates/components/messaging/success.html'
        },
        warning: {
            template: 'app/templates/components/messaging/warning.html'
        },
        errorPreformatted: {
            template: 'app/templates/components/messaging/error-preformatted.html'
        }
    };

    return {
        restrict: "E",
        scope: {
            active: '=',
            message: '=',
            neverHide: '=',
            dontDisappear: '=',
            view: '='
        },
        link: function (scope, element, attrs) {

            scope.view = templates[attrs.view].template;

            if(typeof attrs.neverHide === 'undefined' || attrs.neverHide !== 'true') {
                scope.$watch('active', function (newVal) {
                    if (newVal === true) {
                        jQuery(element).show();

                        if (!attrs.dontDisappear) {
                            setTimeout(function () {
                                scope.active = false;

                                jQuery(element).slideUp().css('display', 'block');
                            }, 5000);
                        }
                    }
                });
            }
        },
        templateUrl: 'app/templates/components/messaging.html'
    };
});
