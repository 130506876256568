app.factory('sessionFormatterUtil', ['dateUtil', function (dateUtil) {
    return {
        existingSessionRanges: [

        ],
        formatableDates: [
            'endDateTime',
            'registrationEndDateTime',
            'startDateTime'
        ],
        /**
         * Used only by the recursive _organizeSessions method
         *
         * Need to store data somewhere for the recursion part of the method.
         */
        _sorted: [],
        /**
         *
         * @param session
         * @returns {*}
         */
        formatSession: function(session) {
            return session;
        },
        /**
         *
         * @param curOffering
         * @returns {*}
         */
        formatSessions: function (curOffering) {
            var meetings = curOffering.sessions || curOffering.studentSessions;

            this._sorted = [];

            this._organizeSessions(meetings);

            meetings = this._sorted;

            var today = moment();

            for(var meeting in meetings) {
                if(typeof meetings[meeting] === 'function') {
                    continue;
                }

                for(var meetingNode in meetings[meeting]) {
                    if(typeof meetings[meeting][meetingNode] === 'function') {
                        continue;
                    }

                    if(meetings[meeting][meetingNode] !== null && $.inArray(meetingNode, this.formatableDates) !== -1) {
                        meetings[meeting][meetingNode] = dateUtil.formatDate(meetings[meeting][meetingNode]);

                        // Find the lowest possible session in the set
                        if(meetingNode === 'startDateTime' && (curOffering.lowestSessionDateForOffering === null || moment(meetings[meeting].startDateTime.original).isBefore(curOffering.lowestSessionDateForOffering.original))) {
                            curOffering.lowestSessionDateForOffering = meetings[meeting].startDateTime;
                        }

                        // Find the session closest to today without going under (can be today).
                        if(meetingNode === 'startDateTime' && moment(meetings[meeting].startDateTime.original).isAfter(today)) {
                            if(curOffering.closestSession === null || moment(meetings[meeting].startDateTime.original).isBefore(moment(curOffering.closestSession))) {
                                curOffering.closestSessionKey = parseInt(meeting, 10) + 1;

                                curOffering.closestSession = meetings[meeting];
                            }
                        }

                        // Find the highest possible session in the set
                        if(meetingNode === 'endDateTime' && (curOffering.highestSessionDateForOffering === null || moment(meetings[meeting].endDateTime.original).isAfter(curOffering.highestSessionDateForOffering.original))) {
                            curOffering.highestSessionDateForOffering = meetings[meeting].endDateTime;
                        }
                    }
                }

                if(typeof meetings[meeting] !== 'undefined' && meetings[meeting] !== null && typeof meetings[meeting].hoursSpent !== 'undefined' && meetings[meeting].hoursSpent !== null) {
                    /**
                     * Absolute biggest garbage ever. Angular requires a DATE TIME for inputs, type of time.
                     */
                    meetings[meeting].hoursSpent = this._round(meetings[meeting].hoursSpent, 1);
                }
            }

            if(curOffering.closestSession === null) {
                curOffering.closestSession = meetings[meetings.length - 1];

                curOffering.closestSessionKey = meetings.length;
            }

            curOffering.sessions = meetings;

            return curOffering;
        },
        _round: function(value) {
            value = value > 0 ? +((Math.round(value * 100) / 100).toFixed(2)) : 0;

            return value;
        },
        /**
         *
         *
         * @param sessionCreator
         * @param repeatType
         * @param repeatAmount
         * @returns {Array}
         */
        createSessionsByStandardRepeat: function (sessionCreator, offerings, repeatType, repeatAmount) {
            var session = null;
            var sessions = [];
            var offering = _.findWhere(offerings, { localId: sessionCreator.offering });

            var endDate = sessionCreator.endDate.moment;
            var endDateClone = null;
            var startDate = sessionCreator.startDate.moment;
            var startDateClone = null;
            var endTime = (dateUtil.parseTime(sessionCreator.endTime));
            var startTime = (dateUtil.parseTime(sessionCreator.startTime));

            do {
                // We set the endDateClone and startDateClone both to start date here because these are only the start and end dates of each individual session, not times, and sessions can't span multiple days so the start and end date for a session
                // will always be the same date. If endDate is used here, it will use the date of the last session, which is not what we want.
                endDateClone = moment(startDate);
                endDateClone.set({ hour: endTime.getHours(), minute: endTime.getMinutes(), second: 0, millisecond: 0 });

                startDateClone = moment(startDate);
                startDateClone.set({ hour: startTime.getHours(), minute: startTime.getMinutes(), second: 0, millisecond: 0 });
                
                if(startDateClone.isAfter(endDateClone)) {
                    break;
                }

                var canCreateSession = true;

                if (repeatType === 'days') {
                    if (sessionCreator.includeWeekdaysOnly === true) {
                        var day = startDateClone.day();
                        var isWeekend = (day === 6) || (day === 0);
                        if (isWeekend === true) {
                            canCreateSession = false;
                        }
                    }
                }

                if (canCreateSession === true) {
                    session = {};

                    session.offeringId = sessionCreator.offering;
                    session.offeringName = offering.name;
                    offering.includeWeekdaysOnly = sessionCreator.includeWeekdaysOnly;

                    session.endDateTime = dateUtil.formatDate(endDateClone.toDate());
                    session.startDateTime = dateUtil.formatDate(startDateClone.toDate());

                    session.endDate = endDateClone.toDate();
                    session.endTime = endTime;
                    session.startDate = startDateClone.toDate();
                    session.startTime = startTime;

                    session.error = this._checkForSessionOverlap(session, offerings);
                    sessions.push(session);
                }
                startDate.add(repeatAmount, repeatType);
            } while (startDate.isBefore(endDate, 'day') || startDate.isSame(endDate, 'day') );

            return sessions;
        },
        /**
         * Cycle over the days selected, and create sessions for each day
         *
         * @param sessionCreator
         * @returns {Array}
         */
        createSessionsByRepeatingDays: function(sessionCreator, offerings) {
            var session = null;
            var sessions = [];
            var offering = _.findWhere(offerings, { localId: sessionCreator.offering });

            var endDate = moment(sessionCreator.endDate.moment);
            var endDateClone = null;
            var startDate = moment(sessionCreator.startDate.moment);
            var startDateClone = null;

            var endTime = moment(dateUtil.parseTime(sessionCreator.endTime));
            var startTime = moment(dateUtil.parseTime(sessionCreator.startTime));

            var startDateDay = startDate.day();
            var dayDifference = 0;

            var repeatAmount = sessionCreator.repeatAmount;

            for(var i = 0; i < repeatAmount; i++) {
                for(var j = 0; j < sessionCreator.days.length; j++) {
                    endDateClone = moment(startDate);
                    startDateClone = moment(startDate);

                    session = null;
                    session = {};

                    dayDifference = sessionCreator.days[j] - startDateDay;
                    /**
                     * Only skip when day difference is less than 0 AND it's the first cycle.
                     *
                     * E.g.: Sun/Sat selected as repeater days, but initial day is a Tuesday. Initial
                     * Sunday must be ignored.
                     *
                     * One more will have to be added to the loop... and if last day is more
                     * than start date, it will need to be omitted.
                     */
                    if(dayDifference < 0 && i === 0) {
                        repeatAmount++;

                        continue;
                    }

                    endDateClone = endDateClone.add(dayDifference, 'days');
                    startDateClone = startDateClone.add(dayDifference, 'days');

                    if(startDateClone.isAfter(endDate)) {
                        break;
                    }

                    session.offeringId = sessionCreator.offering;
                    session.offeringName = offering.name;
                    offering.includeWeekdaysOnly = sessionCreator.includeWeekdaysOnly;

                    session.endDateTime = dateUtil.formatDate(endDateClone.hours(endTime.hours()).minutes(endTime.minutes()));
                    session.startDateTime = dateUtil.formatDate(startDateClone.hours(startTime.hours()).minutes(startTime.minutes()));

                    session.endDate = endDateClone.toDate();
                    session.endTime = endTime.toDate();
                    session.startDate = startDateClone.toDate();
                    session.startTime = startTime.toDate();

                    session.error = this._checkForSessionOverlap(session, offerings);

                    sessions.push(session);
                }

                startDate = startDate.add(1, 'weeks');
            }

            return sessions;
        },
        _checkForSessionOverlap: function(session, offerings) {
            var endDateTime = session.endDateTime.moment;
            var startDateTime = session.startDateTime.moment;

            var nextSession = null;

            var offering = _.find(offerings, function(offering) {
                return parseInt(offering.localId, 10) === parseInt(session.offeringId);
            });

            if(typeof offering.sessions === 'undefined' || offering.sessions === null || offering.sessions.length === 0) {
                return false;
            }

            for(var i = 0; i < offering.sessions.length; i++) {
                nextSession = offering.sessions[i];

                /**
                 * If either the starting datetime or the ending date time falls in between an existing session, we have
                 * to skip it.
                 */
                if(startDateTime.isSame(nextSession.startDateTime.moment) || endDateTime.isSame(nextSession.endDateTime.moment)) {
                    return true;
                } else if(startDateTime.isAfter(nextSession.startDateTime.moment) && startDateTime.isBefore(nextSession.endDateTime.moment)) {
                    return true;
                } else if(endDateTime.isAfter(nextSession.startDateTime.moment) && endDateTime.isBefore(nextSession.endDateTime.moment)) {
                    return true;
                }
            }

            return false;
        },
        /**
         *
         * @param time
         * @returns {Date|*}
         * @private
         */
        _getTimeForTimeFields: function(time) {
            time = time === 0 ? "01:00:00" : time;

            time = time.split(':');

            time = new Date(1970, 0, 1, time[0], time[1], time[2]);

            return time;
        },
        _organizeSessions: function(sessions, nextSort) {
            /**
             * 1. Check if we're dealing with an empty _sorted array. If so, let's go ahead and kick that off.
             * 2. If there is something in sorted, let's start some logic.
             * 2a. If the current value is the lowest, insert it at 0.
             * 2b. If the current value is the highest, insert it at _sorted.length - 1
             * 2c. If the current value is in between, find where to insert it.
             *      1. Loop over _sorted array
             *      2. When previous array spot is first to be lower, split array at current spot, prepend before array spot
             *          and join arrays back together.
             */
            if(typeof nextSort === 'undefined') {
                this._sorted.push(sessions[0]);

                sessions.shift();

                if(typeof sessions[0] !== 'undefined') {
                    this._organizeSessions(sessions, sessions[0]);
                }

                return;
            } else {
                var i = 0;

                while(sessions.length > 0) {
                    switch(this._valueState(sessions[i])) {
                        case 0:
                            this._sorted.unshift(sessions[i]);

                            break;
                        case 1:
                            this._sorted.push(sessions[i]);

                            break;

                        case 2:
                            this._insertIntoCorrectSpot(sessions[i]);

                            break;
                    }

                    sessions.shift();

                    if(typeof sessions[0] !== 'undefined') {
                        this._organizeSessions(sessions, sessions[0]);
                    }

                    i++;
                }
            }
        },
        _valueState: function(value) {
            if(moment(value.startDateTime).isBefore(moment(this._sorted[0].startDateTime))) {
                return 0;
            } else if(moment(value.startDateTime).isAfter(moment(this._sorted[this._sorted.length - 1].startDateTime))) {
                return 1;
            } else {
                return 2;
            }
        },
        _insertIntoCorrectSpot: function(value) {
            var curDate = null;
            var nextDate = null;

            var valueDate = moment(value.startDateTime);

            for(var i = 0; i < this._sorted.length; i++) {
                if(typeof this._sorted[i] === 'function') {
                    continue;
                }

                i = parseInt(i, 10);

                curDate = moment(this._sorted[i].startDateTime);
                nextDate = moment(this._sorted[i + 1].startDateTime);

                if(valueDate.isAfter(curDate) && valueDate.isBefore(nextDate)) {
                    this._sorted.splice(i + 1, 0, value);

                    return;
                }
            }
        }
    };
}]);