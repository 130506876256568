// Global singleton for displaying crumbs on the header
// If you want to have your controller have navigation crumbs
// Inject BreadcrumbsService and use .pushCrumb for every navigation
// crumb you wish to add.
// Do not worry about clearing breadcrumbs. Breadcrumbs are cleared
// when the url changes. Just .pushCrumb in your controller.

// *** Breadcrumbs are cleared on every controller instantiation ***
app.service('BreadcrumbsService', ['$rootScope', 'PathFormatter', function ($rootScope, PathFormatter) {
    var _this = this;

    _this.crumbs = [];

    _this.pushCrumb = function (name, link, isHome) {
        // Takes a crumb name as a string
        // And an optional link.
        // If you don't pass a link,
        // the crumb will be rendered as a string
        var crumb = {};
        crumb.name = name;

        if (link) {
            crumb.link = link;
        }

        if (isHome) {
            crumb.isHome = isHome;
        }
        else {
            crumb.isHome = false;
        }

        _this.crumbs.push(crumb);
    };

    _this.getCrumbs = function() {
        // Returns a list of crumbs in the form of 
        // {'name': name, 'link': link}
        // link will be undefined (not null) if not specified
        // Used only when displaying the crumbs
        return _this.crumbs;
    };

    _this.reset = function() {
        _this.crumbs = [];
    };

    _this.createBreadcrumbs = function(pipeDelimitedPath, fromWeb, baseRoute, catalogType) {
        var crumbs = pipeDelimitedPath.split('|');
        var results = [];
        if (crumbs[0] === "") {
            return results;
        }

        var parentLink = baseRoute;
        if (!fromWeb) {
            results.push({ name: catalogType, link: baseRoute });
        }
        _.each(crumbs, function(crumb) {
            var c = {};
            c.name = crumb;
            c.link = PathFormatter.formatPathParamsWithEncode(parentLink, crumb);
            parentLink = c.link;
            results.push(c);
        });
        return results;
    };

    _this.createHomeBreadcrumb = function (homeRole) {
        console.log('homeRole: ', homeRole);
        var name = '';
        if (homeRole === 'manager') {
            name = 'team management';
        } else if (homeRole === 'instructor') {
            name = 'instructor desk';
        } else if (homeRole === 'ta') {
            name = 'administration';
        } else if (homeRole === 'decisionMaker') {
            name = 'training insights';
        } else { //student or nothing
            name = 'my dashboard';
        }

        _this.pushCrumb(name, '', true);
    };

    // Reset the crumbs whenever we change the URL.
    $rootScope.$on('$routeChangeStart', function(next, current) {
        _this.reset();
    });
}]);
