/* This page is for various components related to the premiumAdmin */
app.factory('Users_AdminOverride', ['$filter', '$http', '$q', '$sce', '$injector', '$route', '$timeout', '$location', '$httpParamSerializer', '$interval', 'dateUtil', 'AssignmentsService', 'BreadcrumbsService', 'CountriesService', 'CoursesService', 'GroupsService', 'JobsService', 'OfferingsService', 'OrganizationsService', 'StatesService', 'TimezoneService', 'TranscriptsService', 'UsersService', 'errorDisplayerUtil', 'OrgFunctions', 'LocationsService', 'PeopleGroupFunctions', 'TreeHelpers', 'sortService', 'queuePollingInterval', 'SettingsService',
    function($filter,
        $http,
        $q,
        $sce,
        $injector,
        $route,
        $timeout,
        $location,
        $httpParamSerializer,
        $interval,
        dateUtil,
        AssignmentsService,
        BreadcrumbsService,
        CountriesService,
        CoursesService,
        GroupsService,
        JobsService,
        OfferingsService,
        OrganizationsService,
        StatesService,
        TimezoneService,
        TranscriptsService,
        UsersService,
        errorDisplayerUtil,
        OrgFunctions,
        LocationsService,
        PeopleGroupFunctions,
        TreeHelpers,
        sortService,
        queuePollingInterval,
        SettingsService) {
        return function($scope) {
            var _this = this;

            /* Load the TA Content data */
            $scope.rows = Array();
            $scope.rowsAreLoading = true;

            UsersService.setHomeRole();

            /* Training Insights access determined by the organization settings */
            var sett = SettingsService.getSettings().then(function (success) {
                $scope.showTrainingInsights = SettingsService.isTrainingInsightsEnabled();
                $scope.showCareerPath = SettingsService.isCareerPathEnabled();
            });


            /* This will hide the breadcrumbs and search form on the top right of header */
            $scope.defaultHeader = true;
            $scope.hideCatalogSearch = true;

            /* This will make the navigation tab active on certain page */
            $scope.activeTab = 'tab-active-class';

            /*This will route users to the appropiate user manual based on active tab*/
            $scope.userManualHref = 'https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#People';

            /* This will help you to route back to home */
            _this.homeRoute = function() {
                $location.url("users/home");
            };

            /**
             * All controller-level scoped variables should be attached to general for cross-template communication
             */
            $scope.general = {};
            $scope.general.peopleSearch = null;

            $scope.general.filterBySearch = function () {
                //reset paging - so as to display first page on clicking simpleSearch irrespective of where the user is currently
                $scope.home.page = 1;
                var searchTerm = $scope.general.search;

                if ($scope.currentview !== 'People Groups') {
                    var advSearch = {};
                    advSearch.keyword = searchTerm;
                    _this.sendAdvancedSearchQueryToServerAndDisplayUsers(advSearch);
                } else {
                    var pgrps = (_this.originalPeopleGroups);
                    if (searchTerm === "") {
                        $scope.pplgrpRows = pgrps;
                    } else {
                        var filtered = $filter('filter')(pgrps, function (row) {
                            return (row.groupName.toLowerCase().indexOf(searchTerm.toLowerCase() || '') !== -1 ||
                                    row.id === parseInt(searchTerm));
                        });
                        $scope.pplgrpRows = filtered;
                    }
                }

            };

            /* ************************ Organization Tree View Actions ******************* */
            /* The below actions related to Organization Tree */
            /* This gets called by the UsersController
             * Consider it the entry point of the TA page.
             */

            // Initialize the data to an empty array until we load the organizations
            $scope.orgTree = [];
            $scope.OrgData = [];
            $scope.OrgOptions = {};
            $scope.OrgOptions.initiallyOpened = false;
            $scope.OrgOptions.rootNodeIsTopLevel = false;

            /* This veriable is initialized here only to make sure it exists.
             * Otherwise, when you type stuff into the treeFilter bar, it won't search the tree
             */
            $scope.OrgTreeFilter = "";
            $scope.OrgTreeIsLoading = true;
            $scope.PeopleTreeIsLoading = true;
            $scope.managerTreeIsLoading = true;

            $scope.listFilter = ""; // stores value of list filter bar

            $scope.home = {};
            $scope.home.limit = 50;
            $scope.home.typeaheadMinLength = 2;
            $scope.home.typeaheadWaitTime = 800; //timeout before Async calls are made after the last key stroke - in 'milliseconds'

            $scope.isGroupQueued = false;
            $scope.selectedGroupName = null;
            $scope.currentview = "";
            $scope.homeAction = function () {
                $scope.currentview = "home";
                $scope.home.page = 1;
                $scope.home.total = 0;
                $scope.home.maxVisiblePages = 10;
                //$scope.home.ItemsPerPage = 100;
                $scope.homeTemplate = 'app/templates/users/home/admin.html';
                $scope.peopleSelected = $scope.orgTree[0];
                /* If we try to run advanced search from the edit page
                 * it doesn't work because the code for displaying users
                 * is on the homepage. When a user runs advances search
                 * they get redirected here. */
                var advancedSearchParams = angular.copy($route.current.params);
                delete advancedSearchParams.controller;
                delete advancedSearchParams.name;

                if (_.keys(advancedSearchParams).length === 2 && _.has(advancedSearchParams, "group_id") && _.has(advancedSearchParams, "group_name")) {
                    $scope.selectedGroupName = advancedSearchParams.group_name;
                    $scope.nodeSource = "People Groups";
                    $scope.peopleSelected = GroupsService.getCurrentNode() === "inactive" ? $scope.PeopleData[0].nodes[1] : $scope.PeopleData[0].nodes[0];
                    $scope.peopleExpanded = [$scope.PeopleData[0]];

                    // Cancel interval if there is one
                    if (queuePollingInterval.peopleGroupPollingInterval !== null) {
                        $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
                    }
                    _this.groupId = advancedSearchParams.group_id;
                    _this.peopleList = null;

                    _this.peopleGroupUsers(_this.groupId);
                    return;
                }

                var runAdvancedSearch = false;
                var advancedSearchStruct = {};
                for (var property in advancedSearchParams) {
                    if (advancedSearchParams.hasOwnProperty(property)) {
                        advancedSearchStruct[property] = advancedSearchParams[property];
                        runAdvancedSearch = true;
                    }
                }
                /* Certain parameters to the homepage shouldn't trigger
                 * an advanced search like welcome=true. When welcome=true
                 * we're just displaying a welcome video */
                if (advancedSearchStruct.welcome) {
                    runAdvancedSearch = false;
                }

                if (runAdvancedSearch) {
                    /* only get advanced search results*/
                    _this.sendAdvancedSearchQueryToServerAndDisplayUsers(advancedSearchStruct);

                } else {
                    _this.fetchAllUsersPaged();
                }

            };

            $scope.checkSearchisEmpty = function() {
                var searchisEmpty = angular.equals({}, $scope.advancedSearchStruct);

                if (!searchisEmpty) {
                    searchisEmpty = true;
                    for (var property in $scope.advancedSearchStruct) {
                        if ($scope.advancedSearchStruct.hasOwnProperty(property)) {
                            if ($scope.advancedSearchStruct[property] !== null && $scope.advancedSearchStruct[property] !== "") {
                                searchisEmpty = false;
                                break;
                            }
                        }
                    }
                }

                return searchisEmpty;
            };

            $scope.home.nextPage = function () {
                $scope.selectAll.checked = false;
                var isEmpty = angular.equals({}, _this.advancedSearchStructForPaging);

                if ($scope.nodeSource === 'People Groups') {
                    _this.peopleList.options.currentPage = $scope.home.page;
                    _this.peopleGroupUsers(_this.groupId);
                }

                else if (isEmpty || typeof _this.advancedSearchStructForPaging === 'undefined') {
                    _this.fetchAllUsersPaged();
                }
                else {
                    _this.sendAdvancedSearchQueryToServerAndDisplayUsers(_this.advancedSearchStructForPaging);
                }
            };

            $scope.OrgTreeTitle = "Organizations";
            $scope.orgTreeError = {};
            $scope.orgTreeError.error = errorDisplayerUtil.create();
            $scope.predicate = { hasActiveUsersAssigned: true };
            _this.organizationCta = function() {
                $scope.orgTreeError.error.reset();
                var orgTreePromiseGroups = OrganizationsService.getOrgTree();
                return orgTreePromiseGroups.then(
                    function(orgTreeData) {
                        TreeHelpers.setPropertyOnAllNodes(orgTreeData, 'lockIcon', true);

                        $scope.OrgData = [
                            {
                                'name': 'Organizations',
                                'nodes': [orgTreeData[0]]
                            }
                        ];

                        $scope.OrgTreeIsLoading = false;
                    },
                    function(failure) {
                        $scope.OrgTreeIsLoading = false;
                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        console.log(failure);
                        $scope.orgTreeError.error.setError(errorCode, message);
                    });
            };

            //the below function is used for org type-aheads data. in peopleBatchEdit, AdvancedSearch, Create and Edit User
            // Also used to populate the TA picker multi-select
            _this.getOrganizationHierarchyData = function () {
                var orgDataPromise = OrganizationsService.getOrganizationHierarchy();
                return orgDataPromise.then(function(success) {
                    $scope.orgArray = [];
                    $scope.domainsArray = [];
                    $scope.orgCutoff = 100; // cutoff point for dropdown vs uib-typeahead
                    for (var i = 0; i < success.length; i++) {
                        var org = success[i];
                        var nameAndOrgObjOnly = {};
                        nameAndOrgObjOnly.pathToOrg = org.pathToOrg;
                        nameAndOrgObjOnly.name = org.name;
                        nameAndOrgObjOnly.id = org.id;
                        //LMS - 6623 Added a Property to use this has filter in Template
                        nameAndOrgObjOnly.enabled = org.enabled;
                        if (org.parentId === 0)
                        {
                            $scope.domainsArray.push(org);
                        }
                        $scope.orgArray.push(nameAndOrgObjOnly);
                    }

                    if ($scope.isSubDomainAdmin === false && $scope.domainsArray && $scope.domainsArray.length > 1) {
                        $scope.domainsArray.unshift({ id: UsersService.topLevelOrganizationId(), name: "Entire Portal" });
                    }
                });
            };

            //the below function is used for positions type-aheads data. in peopleBatchEdit, AdvancedSearch, Create and Edit User
            _this.getPositionsData = function () {
                var positionsTreePromise = JobsService.getAllJobs();
                positionsTreePromise.then(
                    function (success) {
                        $scope.jobs = [];
                        for (var i = 0; i < success.length; i++) {
                            if (success[i].isActive === true) {
                                $scope.jobs.push(success[i]);
                                var index = $scope.jobs.length - 1;
                                $scope.jobs[index].text = $scope.jobs[index].name;
                            }
                        }
                    });
            };

            $scope.peopleGroupData = [];
            $scope.PeopleOptions = {};
            $scope.PeopleOptions.initiallyOpened = false;
            $scope.PeopleOptions.showLocks = false;
            $scope.PeopleOptions.editPencil = true;
            $scope.PeopleOptions.nodeClick = function(node) {
                var peopleGroupId = node.id;
                $location.path('users/peoplegroup/' + peopleGroupId + '/');
            };

            $scope.PeopleTreeTitle = "People Groups";
            $scope.peopleGroupsError = {};
            $scope.peopleGroupsError.error = errorDisplayerUtil.create();

            $scope.peopleGroupsLoading = true;

            _this.getAllPeopleGroups = function(areActive) {
                $scope.peopleGroupsError.error.reset();

                var peopleGroupsTreePromise = GroupsService.getPeopleGroups(areActive);

                return peopleGroupsTreePromise.then(function (success) {
                    $scope.peopleGroupsLoading = false;
                    if (success.groups === null || success.groups.length < 1) {
                        $scope.peopleGroupsError.error.setError("", "There are no " + (areActive === true ? "Active" : "Inactive") + " PeopleGroups to display");
                    } else {
                        _this.originalPeopleGroups = success.groups;

                        _.each(_this.originalPeopleGroups, function (pg) {
                            if (pg.isGroupQueued === true) {
                                $scope.peoplegroups.processQueue.push(pg.id);
                            }
                        });

                        $scope.pplgrpRows = _this.originalPeopleGroups;
                    }
                    if (queuePollingInterval.peopleGroupPollingInterval !== null) {
                        $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
                    }

                    if ($scope.peoplegroups.processQueue.length > 0) {
                        queuePollingInterval.peopleGroupPollingInterval = $interval(_this.peopleGroupQueueStatusChecker, 20000);
                    }

                }, function(failure) {
                    $scope.peopleGroupsLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.peopleGroupsError.error.setError(errorCode, message);
                });
            };

            /* Manager tree stuff */
            $scope.managerTreeTitle = "Managers";
            $scope.managerTreeError = {};
            $scope.managerTreeError.error = errorDisplayerUtil.create();

            $scope.managerData = [];
            $scope.managerOptions = {};
            $scope.managerOptions.initiallyOpened = false;
            $scope.managerOptions.rootNodeIsTopLevel = false;
            $scope.managerOptions.showLocks = false;

            _this.fetchAllUsersPaged = function() {
                $scope.rowsAreLoading = true;
                var pagingAttributes = {};

                var currentPage = $scope.home.page;
                pagingAttributes.pageNumber = currentPage;
                pagingAttributes.pageSize = $scope.home.limit;
                pagingAttributes.sortBy = $scope.propertyName;
                pagingAttributes.sortAscending = !$scope.reverse;

                var allUsersPromise = UsersService.advancedSearch(pagingAttributes);
                return allUsersPromise.then(function(success) {
                    $scope.rowsAreLoading = false;

                    var activeUsers = _this.activeUsers(success);

                    /**
                     * Reset page size and info for paginator
                     */
                    _this.calculatePages(activeUsers[0].totalCount);

                    

                    $scope.rows = activeUsers;
                    _this.setRowSelection($scope.rows);

                }, function(failure) {
                    $scope.rowsAreLoading = false;
                    $scope.rows = [];
                    $scope.home.total = 0;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.userTableError.error.setError(errorCode, message);
                });

            };

            $scope.managerNodeClick = function(node) {

                //Clear previously selected nodes in different trees
                var selectedNodes = document.getElementsByClassName("tree-selected");
                Array.prototype.forEach.call(selectedNodes, function(x) {
                    $(x).removeClass("tree-selected");
                });

                var managerAttribute = {};
                managerAttribute.managerId = node.id;
                $scope.nodeSource = 'Managers';
                // Cancel interval if there is one
                if (queuePollingInterval.peopleGroupPollingInterval !== null) {
                    $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
                }
                $scope.home.page = 1;
                _this.sendAdvancedSearchQueryToServerAndDisplayUsers(managerAttribute);
            };

            // Use this method to return list of active users for portal
            _this.activeUsers = function(users) {
                var output = $filter('filter')(users, function(user) {
                    if (typeof user.endDate === "undefined" || user.endDate === null || user.endDate.jsDate > new Date()) {
                        return user;
                    }
                });

                return output;
            };

            _this.getuserDataForManagerstree = function() {
                $scope.managerTreeError.error.reset();
                $scope.managerTreeIsLoading = true;
                var managerTreePromise = UsersService.getManagerTree();
                return managerTreePromise.then(function(success) {
                    //var filteredManagers = _this.primaryManagersOnly(success);
                    $scope.managerData = success;
                    $scope.managerTree = [{ 'name': 'Managers', 'nodes': $scope.managerData }];
                    $scope.managerTreeIsLoading = false;
                }, function(failure) {
                    $scope.managerTreeIsLoading = false;
                    $scope.managerTreeError.error.setError(failure.statusCode, failure.errorMessage);
                });

            };
            
            _this.primaryManagersOnly = function (mgrarray) {

                for (var i = mgrarray.length - 1; i >= 0; i--)
                {
                    if (mgrarray[i].hasDirectReports === true) {
                        if (mgrarray[i].nodes !== null) {
                            _this.primaryManagersOnly(mgrarray[i].nodes);
                        } 
                    } else {
                        mgrarray.splice(i, 1);
                    }
                }

                return mgrarray;

            };


        /* ------------ Advanced Search ------------- */
            $scope.advancedSearch = {};

            $scope.showAdvancedSearch = false;
            /* Used for storing advanced search query data
             * Gets passed to UsersService.advancedSearch */
            $scope.advancedSearchStruct = {};

            $scope.advancedSearchCta = function () {
                if (typeof $scope.orgArray === 'undefined' || $scope.orgArray === null) {
                    _this.getOrganizationHierarchyData();
                }

                if (typeof $scope.jobs === 'undefined' || $scope.jobs === null) {
                    _this.getPositionsData();
                }
                

                $scope.showAdvancedSearch = !$scope.showAdvancedSearch;

                if ($scope.showAdvancedSearch && typeof $scope.timezones === 'undefined' || $scope.timezones === null) {
                    var timezones = TimezoneService.getAllTimezones();
                    timezones.then(function (data) {
                        $scope.timezones = data;
                    });
                }

                /**
                * Get a list of countries
                */
                if ($scope.showAdvancedSearch && typeof $scope.countries === 'undefined' || $scope.countries === null) {
                    
                    var countries = CountriesService.getLatitudeCountryList(UsersService.getPortalId());
                    countries.then(function (success) {
                        $scope.countries = success;

                        $scope.editUser.countriesLoading = false;
                    }, function (failure) {
                        console.log(failure);
                    });
                }

            };

            _this.clearAdvancedSearch = function() {
                $scope.advancedSearchStruct = {};
                //$scope.advancedSearchStruct.homeAddress = {};
            };

            // Initialized the advanced search fields in this case.
            _this.clearAdvancedSearch();

            //SLY-4142 used by uib-typeahead control for advanced search form
            $scope.advancedSearch.selectJob = function ($item, $model, $label) {
                $scope.advancedSearchStruct.jobId = $item.id;
            };

            //SLY-4141 used by uib-typeahead control for advanced search form
            $scope.advancedSearch.selectOrganization = function ($item, $model, $label) {
                $scope.advancedSearchStruct.organizationId = $item.id;
            };

            $scope.advancedSearch.selectManager = function($item, $model, $label) {
                var temp1 = ($scope.advancedSearchStruct.selectedManager);

                if (typeof $item === 'undefined' || $item === null) {
                    if((typeof temp1 === 'undefined' || temp1 === null) || (angular.isString(temp1)  && temp1.trim() === ""))
                        delete $scope.advancedSearchStruct.managerId;
                } else {
                    $scope.advancedSearchStruct.managerId = $item.id;
                }
            };

            $scope.advancedSearch.selectAlternateManager = function($item, $model, $label) {
                var temp2 = ($scope.advancedSearchStruct.selectedAltManager);

                if (typeof $item === 'undefined' || $item === null) {
                    if ((typeof temp2 === 'undefined' || temp2 === null) || (angular.isString(temp2)  && temp2.trim() === ""))
                        delete $scope.advancedSearchStruct.alternateManagerId;
                } else {
                    $scope.advancedSearchStruct.alternateManagerId = $item.id;
                }
                
            };

            $scope.advancedSearch.toggleTrainingAdministrator = function () {
                if (typeof $scope.advancedSearchStruct.isTa !== 'undefined') {
                    //SLY-4214
                    delete $scope.advancedSearchStruct.isTa;
                } else {
                    //SLY-4214
                    // allows for selecting both instrutor and TA
                    $scope.advancedSearchStruct.isTa = true;
                }
            };

            $scope.advancedSearch.toggleInstructor = function () {
                if (typeof $scope.advancedSearchStruct.isInstructor !== 'undefined') {
                    delete $scope.advancedSearchStruct.isInstructor;
                } else {
                    $scope.advancedSearchStruct.isInstructor = true;
                }
            };

            $scope.runAdvancedSearch = function() {
                // delete the temp advnace search manager objects
                delete $scope.advancedSearchStruct.selectedManager;
                delete $scope.advancedSearchStruct.selectedAltManager;

                //delete empty search fields 
                for (var property in $scope.advancedSearchStruct) {
                    if ($scope.advancedSearchStruct.hasOwnProperty(property)) {
                        if ($scope.advancedSearchStruct[property] === null || $scope.advancedSearchStruct[property] === '')
                            delete $scope.advancedSearchStruct[property];
                    }
                }

                //reset paging - so as to display first page on clicking simpleSearch irrespective of where the user is currently
                $scope.home.page = 1;
                $scope.advancedSearchStruct.searchAll = "true";
                /* If we're on the edit page, put these parameters
                 * as a GET string to /users/home/ where they will be interpreted later
                 * and turned into an advanced search query */
                if ($route.current.params.name !== 'home') {
                    $location.path('/users/home/').search($scope.advancedSearchStruct);
                } else {
                    _this.sendAdvancedSearchQueryToServerAndDisplayUsers($scope.advancedSearchStruct);
                }
            };

            _this.sendAdvancedSearchQueryToServerAndDisplayUsers = function(advancedSearchStruct) {
                _this.advancedSearchStructForPaging = {};
                _this.advancedSearchStructForPaging = (advancedSearchStruct);

                //SLY-4141 don't send this up.
                if (!_.isEmpty(advancedSearchStruct.pathToOrg)) {
                    delete advancedSearchStruct.pathToOrg;
                }
                
                advancedSearchStruct.pageNumber = $scope.home.page;
                advancedSearchStruct.pageSize = $scope.home.limit;
                advancedSearchStruct.sortBy = $scope.propertyName;
                advancedSearchStruct.sortAscending = !$scope.reverse;

                var search = UsersService.advancedSearch(advancedSearchStruct);

                $scope.rowsAreLoading = true;

                $scope.userTableError.error.reset();

                $scope.closeAdvancedSearch();

                return search.then(function (users) {
                    _this.calculatePages(users[0].totalCount);
                    
                    $scope.rows = users;
                    $scope.rowsAreLoading = false;
                    _this.setRowSelection($scope.rows);
                }, function(failure) {
                    // set the list of users on the left to a blank list
                    $scope.rowsAreLoading = false;

                    $scope.rows = [];
                    /* If there was an actual error, display it
                     * A 404 doesn't count as an error because
                     * it just means no users */
                    var errorCode = failure.StatusCode;
                    if (errorCode !== 404) {
                        var message = failure.ErrorMessage;
                        $scope.userTableError.error.setError(errorCode, message);
                    }
                });
            };

            $scope.closeAdvancedSearch = function() {
                _this.clearAdvancedSearch();
                $scope.showAdvancedSearch = false;
            };

            /**
             * Namespacing for the edit user page
             *
             * @type {Object}
             */
            $scope.editUser = {};
            $scope.editUser.error = errorDisplayerUtil.create();

            /**
             * Action to handle editing of single users
             *
             * @void
             */
            $scope.editAction = function() {
                var timezones = TimezoneService.getAllTimezones();
                timezones.then(function (data) {
                    $scope.timezones = data;
                });

                $scope.editUser.isLoading = true;
                $scope.editUser.countriesLoading = true;
                $scope.editUser.error.reset();

                /* Used to enable buttons specifically for editing
                 * users in admin.manageUserForm.html */
                $scope.editUser.isEditing = true;

                $scope.homeTemplate = 'app/templates/users/edit/admin.html';

                var id = $route.current.params.param.split('/')[0];

                /**
                 * Get a list of countries
                 */
                var countries = CountriesService.getLatitudeCountryList(UsersService.getPortalId());
                countries.then(function(success) {
                    $scope.countries = success;

                    $scope.editUser.countriesLoading = false;
                }, function(failure) {
                    /**
                     * @TODO: @sfrohm Figure out what to do for failures, as user will not be editable if there is no country
                     * by default
                     */
                    console.log(failure);
                });

                if (typeof $scope.orgArray === 'undefined' || $scope.orgArray === null) {
                    _this.getOrganizationHierarchyData().then(function () {
                        //execute user get after we retrieve organization data
                        $scope.getUserToEdit(id);
                    });
                } else { $scope.getUserToEdit(id); }

                if (typeof $scope.jobs === 'undefined' || $scope.jobs === null) {
                    _this.getPositionsData();
                }
               
            };

            $scope.initNewUserTimeZone = function () {
                if (typeof $scope.user.timeZone === 'undefined' || $scope.user.timeZone === null) {
                    var portalSettingsPromise = SettingsService.getSettings();
                    portalSettingsPromise.then(function (portalSettings) {
                        $scope.user.timeZone = portalSettings.timezone.value;
                    });
                }
            };

            $scope.initNewUserStartDate = function () {
                if (typeof $scope.user.hireDate === 'undefined' || $scope.user.hireDate === null) {
                    //LMS-7882 Change start time on 'Start Date' field to 3:00 AM in the Portal Time Zone.
                    var startDateTime = dateUtil.setPortalStartTime(new Date());
                    $scope.user.hireDate = dateUtil.formatDate(startDateTime);
                }
            };

            $scope.getUserToEdit = function (id) {
                var user = UsersService.fetch(id);
                user.then(function (response) {
                    $scope.user = response;
                    // Get a state list for the user, based on country
                    if (typeof $scope.user.homeAddress !== 'undefined' && $scope.user.homeAddress !== null && $scope.user.homeAddress.countryId !== null && parseInt($scope.user.homeAddress.countryId, 10) > 0) {
                        $scope.getStates($scope.user.homeAddress.countryId);
                    }

                    if ($scope.user.alternateManagers !== null &&
                        Object.keys($scope.user.alternateManagers).length > 0) {
                        var alternateManagerId = Object.keys($scope.user.alternateManagers)[0];
                        var fullName = $scope.user.alternateManagers[alternateManagerId];
                        /* Make a facade object so that we don't have to 
                         * find the entire user object to prefill the alternate
                         * manager form */
                        $scope.user.selectedAlternateManager = {};
                        $scope.user.selectedAlternateManager.id = alternateManagerId;
                        $scope.user.selectedAlternateManager.fullName = fullName;
                        $scope.selectAlternateManager($scope.user.selectedAlternateManager);
                    }
                                        
                    if ($scope.addUser.isUserTA()) {
                        // Top level TA logged in, just set TA to true in UI because user we're editing is a TA of something and top level can see everything
                        if (UsersService.isSubDomainAdmin() === false) {
                            $scope.addUser.trainingAdministrator = true;
                            $scope.addUser.toggleTrainingAdministrator(true);
                        }
                        // Logged in with a sub TA. Check to make sure user we're editing has TA privileges at our level or at the top before checking TA checkbox
                        else {
                            if ($scope.user.taRoleOrgIds.indexOf(UsersService.activeDomain().id) !== -1 || $scope.user.taRoleOrgIds.indexOf(UsersService.topLevelOrganizationId()) !== -1) {
                                $scope.addUser.trainingAdministrator = true;
                                $scope.addUser.toggleTrainingAdministrator(true);
                            }
                        }
                    }
                    if (_.contains($scope.user.roles, 'instructor')) {
                        $scope.addUser.instructor = true;
                        $scope.addUser.toggleInstructor(true);
                    }

                    $scope.editUser.isLoading = false;
                    $scope.editUser.isActive = UsersService.isUserActive($scope.user);
                    $scope.editUser.isInFuture = UsersService.isInFuture($scope.user);
                }, function(failure) {
                    $scope.editUser.isLoading = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.editUser.error.setError(errorCode, message);
                });
            };

            /* Deactivate user stuff */
            $scope.confirmDeactivateUserError = errorDisplayerUtil.create();
            $scope.confirmDeactivateUserLoading = false;
            $scope.confirmDeactivateUser = false;

            $scope.deactivateUser = function(userId) {
                $scope.confirmDeactivateUserLoading = true;
                var deactivateUser = UsersService.deactivateUser(userId);
                deactivateUser.then(function(Response) {
                    $scope.toggleDeactivateUserTooltip();
                    _this.homeRoute();
                }, function(failure) {
                    $scope.confirmDeactivateUserLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.confirmDeactivateUserError.setError(errorCode, message);
                });
            };

            $scope.toggleDeactivateUserTooltip = function() {
                $scope.confirmDeactivateUser = !$scope.confirmDeactivateUser;
                $scope.confirmDeactivateUserError.reset();
                $scope.confirmDeactivateUserLoading = false;
            };

            /* Deactivate user stuff */
            $scope.confirmActivate = {};
            $scope.confirmActivateUserError = errorDisplayerUtil.create();
            $scope.confirmActivate.UserLoading = false;
            $scope.confirmActivateUser = false;

            $scope.ActivateUser = function(userId) {
                $scope.confirmDeactivateUserLoading = true;
                var activateUser = UsersService.activateUser(userId);
                activateUser.then(function(Response) {
                    _this.homeRoute();
                }, function(failure) {
                    $scope.confirmActivateUserLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.confirmActivateUserError.setError(errorCode, message);
                });
            };

            $scope.userTableError = {};
            $scope.userTableError.error = errorDisplayerUtil.create();
            $scope.peopleGroupRequestUpdateError = errorDisplayerUtil.create();

            //Save user warning
            $scope.confirmSaveUser = false;

            $scope.toggleSaveUserTooltip = function() {
                $scope.confirmSaveUser = !$scope.confirmSaveUser;
            };

            //Cancel user warning
            $scope.confirmCancelUser = false;

            $scope.toggleCancelUserTooltip = function() {
                $scope.confirmCancelUser = !$scope.confirmCancelUser;
            };

            $scope.clearCancelBool = function() {
                $scope.confirmCancelUser = false;
            };


            /* This gets called when you click on any node in the Organization tree.
             * Filter the items on the right when you click on the node */
            $scope.OrgNodeClick = function (item) {
               
                //Remove selected class for selected nodes in other trees
                var selectedNodes = document.getElementsByClassName("tree-selected");
                Array.prototype.forEach.call(selectedNodes, function (x) {
                    $(x).removeClass("tree-selected");
                });
                
                if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "home") {
                    if (typeof item.id !== 'undefined') {

                        $scope.selectedNode = item.name;
                        $scope.nodeSource = "Organizations";
                        // Cancel interval if there is one
                        if (queuePollingInterval.peopleGroupPollingInterval !== null) {
                            $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
                        }
                        _this.filterRowsByOrgId(item);
                    }
                } else {
                    $location.url('users/home');
                }
            };

            /* this function clears the rows in the right hand side
             * and filters them by the organization tree */
            _this.filterRowsByOrgId = function(organization) {
                var orgId = typeof organization.id !== 'undefined' ? organization.id : 0;
                
                var searchAttribute = {};
                searchAttribute.organizationId = orgId;
				//SLY-4515 limit results based on selections in org tree to that node only.
                searchAttribute.limitToSingleOrg = true;
                $scope.home.page = 1;
                _this.sendAdvancedSearchQueryToServerAndDisplayUsers(searchAttribute);
            };

            /* This gets called when you click on any node in the Poeple tree.
             * Filter the items on the right when you click on the node
             */
            $scope.PeopleNodeClick = function (item) {

                //Clear previously selected nodes in different trees
                var selectedNodes = document.getElementsByClassName("tree-selected");
                Array.prototype.forEach.call(selectedNodes, function (x) {
                    $(x).removeClass("tree-selected");
                });

                var urlparam = item.name.toLowerCase() === "inactive" ? "inactive" : "active";
                
                $location.path('/users/peoplegroup/' + urlparam).search({});
            };

            _this.peopleGroupUsers = function (groupId) {
                var propertyToSortBy = '';
                $scope.userTableError.error.reset();
                $scope.rows = [];
                $scope.rowsAreLoading = true;
                $scope.initialQueueStatusLoading = true;

                if (_this.peopleList !== "undefined" && _this.peopleList !== null && _this.peopleList.groupId === groupId && $scope.isGroupQueued === false) {
                    $scope.initialQueueStatusLoading = false;
                    propertyToSortBy = $scope.propertyName;
                    // When sorting, the field passed in is 'positionName' but the javascript property is actually called 'jobType'
                    if (propertyToSortBy === "positionName") {
                        propertyToSortBy = "jobType";
                    }

                    _this.peopleList.people = _.sortBy(_this.peopleList.people, function(p) {
                        return p[propertyToSortBy].toLowerCase();
                    });
                    if ($scope.reverse) {
                        _this.peopleList.people.reverse();
                    }
                    $scope.rows = GroupsService.getPageOfUsers(_this.peopleList.people, _this.peopleList.options.currentPage, _this.peopleList.options.pageSize, _this.peopleList.totalCount);
                    _this.setRowSelection($scope.rows);
                    $scope.rowsAreLoading = false;
                } else {
                    GroupsService.getQueueStatus(groupId).then(function(success) {
                        $scope.queuedPeopleGroup = success;
                        $scope.initialQueueStatusLoading = false;
                        // 3 = success, 4 = error, 0 = Not in queue
                        if (success.processStatusId === 3 || success.processStatusId === 4 || success.processStatusId === 0) {
                            $scope.isGroupQueued = false;
                            $scope.rowsAreLoading = true;
                            $scope.home.page = 1;
                            _this.getUsersByPeopleGroup(groupId);
                        } else {
                            if (queuePollingInterval.peopleGroupPollingInterval !== null) {
                                $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
                            }
                            $scope.isGroupQueued = true;
                            $scope.rowsAreLoading = true;
                            if (_.indexOf($scope.peoplegroups.processQueue, groupId) === -1) {
                                $scope.peoplegroups.processQueue.push(groupId);
                            }

                            queuePollingInterval.peopleGroupPollingInterval = $interval(_this.peopleGroupQueueStatusChecker, 20000);
                        }
                    }, function(failure) {
                        $scope.initialQueueStatusLoading = false;
                        $scope.isGroupQueued = false;
                        $scope.rowsAreLoading = true;
                        $scope.home.page = 1;
                        _this.getUsersByPeopleGroup(groupId);
                    });
                }
            };

            _this.addGroupToProcessingQueue = function (groupId) {
                $scope.peopleGroupRequestUpdateError.reset();
                GroupsService.addGroupToProcessingQueue(groupId).then(function(success) {
                    $scope.queuedPeopleGroup = success;
                    if (queuePollingInterval.peopleGroupPollingInterval !== null) {
                        $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
                    }
                    $scope.isGroupQueued = true;
                    $scope.rowsAreLoading = true;
                    queuePollingInterval.peopleGroupPollingInterval = $interval(_this.peopleGroupQueueStatusChecker, 20000);
                }, function(failure) {
                    $scope.rowsAreLoading = false;
                    var errorCode = failure.StatusCode;
                    if (errorCode !== 404) {
                        var message = failure.ErrorMessage;
                        $scope.peopleGroupRequestUpdateError.setError(errorCode, message);
                    }
                });
            };

            $scope.addGroupToProcessingQueue = function (pplgroup) {
                if (_.indexOf($scope.peoplegroups.processQueue, pplgroup.id) === -1) {
                    pplgroup.isGroupQueued = true;
                    $scope.peoplegroups.processQueue.push(pplgroup.id);
                    _this.addGroupToProcessingQueue(pplgroup.id);
                }
            };

            _this.peopleGroupQueueStatusChecker = function () {
                _.each($scope.peoplegroups.processQueue, function(groupId) {
                    GroupsService.getQueueStatus(groupId).then(function(success) {
                        
                        // If process status is success or error, remove interval and get people group results
                        if (success.processStatusId === 3 || success.processStatusId === 4) {

                            var index = _.indexOf($scope.peoplegroups.processQueue, groupId);
                            if (index !== -1) {
                                $scope.peoplegroups.processQueue.splice(index, 1);
                            }

                            if (typeof _this.originalPeopleGroups !== 'undefined' && _this.originalPeopleGroups !== null) {
                                var grpmatch = _.findWhere(_this.originalPeopleGroups, { "id": groupId });
                                if (typeof grpmatch !== 'undefined' && grpmatch !== null) {
                                    grpmatch.isGroupQueued = false;
                                    grpmatch.recordDateTime = success.lastProcessedDate;
                                }
                            }

                            if ($scope.nodeSource === 'People Groups') {
                                $scope.queuedPeopleGroup = success;
                                $scope.isGroupQueued = false;
                                $scope.rowsAreLoading = true;
                                $scope.home.page = 1;
                                _this.getUsersByPeopleGroup(_this.groupId);
                            }
                        }
                    }, function(failure) {
                        console.log(JSON.stringify(failure));
                    });
                });
            };

            _this.getUsersByPeopleGroup = function(groupId) {
                GroupsService.getUsersByGroupId(groupId).then(function (success) {
                    _this.peopleList = {};
                    _this.peopleList.groupId = groupId;
                    _this.peopleList.people = success;
                    _this.peopleList.options = {};
                    _this.peopleList.options.pageSize = 50; // TODO: Replace with global variable containing page size if there is one
                    _this.peopleList.options.currentPage = 1;
                    _this.peopleList.totalCount = _this.peopleList.people.length;
                    var propertyToSortBy = $scope.propertyName;
                    // When sorting, the field passed in is 'positionName' but the javascript property is actually called 'jobType'
                    if (propertyToSortBy === "positionName") {
                        propertyToSortBy = "jobType";
                    }
                    _this.peopleList.people = _.sortBy(_this.peopleList.people, function (p) {
                        return p[propertyToSortBy].toLowerCase();
                    });
                    if ($scope.reverse) {
                        _this.peopleList.people.reverse();
                    }
                    $scope.rows = GroupsService.getPageOfUsers(_this.peopleList.people, _this.peopleList.options.currentPage, _this.peopleList.options.pageSize, _this.peopleList.totalCount);
                    _this.setRowSelection(_this.peopleList.people);
                    _this.calculatePages(_this.peopleList.totalCount);
                    $scope.rowsAreLoading = false;
                }, function (failure) {
                    // set the list of users on the left to a blank list
                    $scope.rowsAreLoading = false;

                    $scope.rows = [];
                    /* If there was an actual error, display it
                     * A 404 doesn't count as an error because
                     * it just means no users */
                    var errorCode = failure.StatusCode;
                    if (errorCode !== 404) {
                        var message = failure.ErrorMessage;
                        $scope.userTableError.error.setError(errorCode, message);
                    }
                });
            };

            $scope.userAuditInfo = {};
            $scope.userAuditInfo.isLoading = false;
            $scope.userAuditInfo.error = errorDisplayerUtil.create();

            /**
             *
             */
            $scope.auditAction = function() {
                $scope.homeTemplate = 'app/templates/users/audit/admin.html';

                /**
                 * @TODO: @sfrohm discuss with the gentlemen how we handle 404's. Do we create a route for all error
                 * situations (40x, 50x)?
                 */
                if (typeof $route.current.params.param === 'undefined') {
                    // how do we handle 404's?
                }

                $scope.userAuditInfo.error.reset();

                $scope.userAuditInfo.isLoading = true;

                var id = $route.current.params.param.split('/')[0];

                var audit = UsersService.getUserTrainingAudit(id);
                audit.then(function(success) {
                    $scope.userAuditInfo.isLoading = false;

                    $scope.audits = success;
                }, function(failure) {
                    $scope.userAuditInfo.isLoading = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;

                    $scope.userAuditInfo.error.setError(errorCode, message);
                });
            };

            /* This makes coloumns of a table sortables */
            $scope.propertyName = 'lastName';
            $scope.reverse = false;

            $scope.sortBy = function (propertyName) {
                //reset paging - so as to display first page on clicking sort irrespective of where the user is currently
                $scope.home.page = 1;
                $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
                $scope.propertyName = propertyName;

                //variable just for the HTML to use to sort
                //needed due to "positionName" and "jobType" being used for the same column
                $scope.htmlSortName = propertyName;
                if ($scope.htmlSortName === "positionName") {
                    $scope.htmlSortName = "jobType";
                }

                if ($scope.currentview === "People Groups") {
                    return;
                }

                var isEmpty = angular.equals({}, _this.advancedSearchStructForPaging);

                if ($scope.nodeSource === 'People Groups') {
                    _this.peopleList.options.currentPage = $scope.home.page;
                    _this.peopleGroupUsers(_this.groupId);
                }
                else if (isEmpty || typeof _this.advancedSearchStructForPaging === "undefined") {
                    _this.fetchAllUsersPaged();
                } else {
                    _this.sendAdvancedSearchQueryToServerAndDisplayUsers(_this.advancedSearchStructForPaging);
                }
            };

            /* This will check all the rows in the table in that instance */
            /// TO DO //
            $scope.selectAll = {};
            $scope.checkAll = function() {
                if ($scope.selectAll.checked) {
                    _.each(_.filter($scope.rows, { "isActive": true }), function (row) {
                        row.select = true;
                        var index = _.findWhere($scope.batch.selectedUsers, { "id": row.id });
                        if (typeof index === "undefined") {
                            $scope.batch.selectedUsers.push(angular.copy(row));
                        }
                    });
                } else {
                    _.each(_.filter($scope.rows, { "isActive": true }), function (row) {
                        row.select = false;
                        var index = _.findIndex($scope.batch.selectedUsers,{"id":row.id});
                        if (index !== -1) {
                            $scope.batch.selectedUsers.splice(index,1);
                        }
                    });
                }
            };

           

            /* This gets called when the user filters out the currently selected node in the tree
             * this will just hide all the rows on the right hand side in our case */
            $scope.OrgNodeNowHidden = function() {
                $scope.rows = [];
            };

            /**
             * Middle man to receive action and make call to API to edit a user
             *
             * @void
             */
            $scope.editUserForm = {};
            $scope.editUserForm.error = errorDisplayerUtil.create();

            $scope.callUserEdit = function() {
                var user = angular.copy($scope.user);
                
                $scope.editUserForm.isLoading = true;
                //SLY-4193 manager was removed
                if (user.managerName === '' && user.managerId !== null)
                {
                    user.managerName = null;
                    user.managerId = null;
                }
                if (user.selectedAlternateManager === '' && user.alternateManagers !== null)
                {
                    user.alternateManagers = { };
                }

                //user.hireDate = moment(user.hireDate.moment);
                if (user.endDate !== null){
                    if (typeof user.endDate.moment === 'undefined' || user.endDate.moment === null) {
                        user.endDate = null;
                    }
                }

                var editedUser = UsersService.taEditProfile([user]);
                editedUser.then(function(success) {
                    $scope.editUserForm.isLoading = false;
                    if (success[0].success === true) {
                        $location.url("users/home");
                    } else if (success[0].success === false) {
                        var errorCode = '404';
                        var message = success[0].message;
                        $scope.editUserForm.error.setError(errorCode, message);
                    }

                }, function(failure) {
                    $scope.editUserForm.isLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.editUserForm.error.setError(errorCode, message);

                });
            };
            //SLY-4141 used by uib-typeahead control for add and edit forms
            $scope.selectOrganization = function ($item, $model, $label, $event, user) {
                //if ($item.enabled === true) {
                $scope.user.organizationName = $item.name;
                $scope.user.organizationId = $item.id;
                //}

            };

            $scope.selectManager = function($item, $model, $label, $event, user) {
                $scope.user.managerId = $item.id;
            };

            $scope.selectAlternateManager = function($item, $model, $label, $event, user) {
                $scope.user.alternateManagers = {};
                $scope.user.alternateManagers[$item.id] = $item.fullName;
            };

            $scope.languageOptions = [{
                'id': 9,
                'name': "English"
            }];

            /******** BATCH EDIT ********/

            

            // Initialize batch form
            $scope.batch = {};
            $scope.batch.error = errorDisplayerUtil.create();
            $scope.batch.locationError = errorDisplayerUtil.create();

            /******** BatchEdit - Select Users Between Pages and Searches ********/
            $scope.batch.selectedUsers = [];
            $scope.batch.selectAll = {};
            $scope.batch.selectAll.checked = true;
            $scope.pushInArray = function (user) {

                if ($scope.selectAll.checked)
                    $scope.selectAll.checked = false;

                var index = _.findIndex($scope.batch.selectedUsers, { "id": user.id });
                if (user.select) {
                    if (index === -1) {
                        $scope.batch.selectedUsers.push(angular.copy(user));
                    }
                } else {
                    if (index !== -1)
                        $scope.batch.selectedUsers.splice(index, 1);
                }
            };

            $scope.batch.clearSelection = function () {
                $scope.batch.selectedUsers = [];
                var rowsToDeselect = $scope.rows;
                if ($scope.nodeSource === 'People Groups') {
                    rowsToDeselect = _this.peopleList.people;
                }
                _.each(rowsToDeselect, function (row) {
                    row.select = false;
                });

                $scope.selectAll.checked = false;
                $scope.batch.selectedUsersForm.$setPristine();
                jQuery('#peopleTabBatchSelectedUsersModal').modal('hide');
                $scope.showSelectUsersTooltip = false;

            };

            $scope.batch.selectUserRow = function() {
                $scope.batch.selectAll.checked = false;
            };

            $scope.batch.applySelectionChanges = function () {
                _.each(_.where($scope.batch.selectedUsers, { "select": false }), function (row) {

                    //uncheck current view items row selection
                    var rowsToDeselect = $scope.rows;
                    if ($scope.nodeSource === 'People Groups') {
                        rowsToDeselect = _this.peopleList.people;
                    }
                    var deselectrow = _.findWhere(rowsToDeselect, { "id": row.id });
                    if (typeof deselectrow !== "undefined" && deselectrow !== null) {
                        deselectrow.select = false;
                        $scope.selectAll.checked = false;
                    }

                    //remove from the selectedUsers array
                    var index = _.findIndex($scope.batch.selectedUsers, { "id": row.id });
                    if (index !== -1) {
                        $scope.batch.selectedUsers.splice(index, 1);
                    }
                    
                });

                $scope.batch.selectedUsersForm.$setPristine();
                jQuery('#peopleTabBatchSelectedUsersModal').modal('hide');
                $scope.showSelectUsersTooltip = false;
            };

            $scope.showSelectUsersTooltip = false;
            $scope.batch.toggleSelectUsersTooltip = function () {
                if (!$scope.batch.selectedUsersForm.$dirty) {
                    jQuery('#peopleTabBatchSelectedUsersModal').modal('hide');
                    $scope.showSelectUsersTooltip = false;
                } else {
                    $scope.showSelectUsersTooltip = !$scope.showSelectUsersTooltip;
                }
            };

            $scope.batch.closeSelectUsersModal = function () {
                var isFormDirty = $scope.batch.selectedUsersForm.$dirty;
                if (isFormDirty) {
                    _this.undoSelectedUserChanges();
                    $scope.batch.selectedUsersForm.$setPristine();
                }

                jQuery('#peopleTabBatchSelectedUsersModal').modal('hide');
                $scope.showSelectUsersTooltip = false;

            };

            _this.undoSelectedUserChanges = function () {

                _.each($scope.batch.selectedUsers, function (row) {
                    row.select = true;
                });

            };

            _this.setRowSelection = function (userRows) {

                _.each(userRows, function (row) {
                    var isrowselected = _.findWhere($scope.batch.selectedUsers, { "id": row.id });
                    if (typeof isrowselected !== "undefined") {
                        row.select = true;
                    }
                    row.isActive = UsersService.isUserActive(row);
                });

                $scope.selectAll.checked = _.every(userRows, { "select": true });
            };

            $scope.batch.checkAll = function() {
                if ($scope.batch.selectAll.checked) {
                    _.each($scope.batch.selectedUsers, function (row) {
                        row.select = true;
                    });
                } else {
                    _.each($scope.batch.selectedUsers, function (row) {
                        row.select = false;
                    });
                }
            };



            $scope.batch.selectedPeopleGroups = [];
            $scope.peoplegroups = {};
            $scope.peoplegroups.areActive = true;
            $scope.peoplegroups.processQueue = [];
            $scope.peoplegroups.selectAll = {};
            $scope.peoplegroups.selectAll.checked = false;
            $scope.peoplegroups.selectAll = function () {
                if ($scope.peoplegroups.selectAll.checked) {
                    _.each($scope.pplgrpRows, function (row) {
                        row.select = true;
                        var index = _.findWhere($scope.batch.selectedPeopleGroups, { "id": row.id });
                        if (typeof index === "undefined") {
                            $scope.batch.selectedPeopleGroups.push(angular.copy(row));
                        }
                    });
                } else {
                    _.each($scope.pplgrpRows, function (row) {
                        row.select = false;
                        var index = _.findIndex($scope.batch.selectedPeopleGroups, { "id": row.id });
                        if (index !== -1) {
                            $scope.batch.selectedPeopleGroups.splice(index, 1);
                        }
                    });
                }
            };


            //$scope.batch.selectAll = {};
            //$scope.batch.selectAll.checked = true;
            $scope.pushInPplGrpArray = function (pplgrp) {

                if ($scope.peoplegroups.selectAll.checked)
                    $scope.peoplegroups.selectAll.checked = false;

                var index = _.findIndex($scope.batch.selectedPeopleGroups, { "id": pplgrp.id });
                if (pplgrp.select) {
                    if (index === -1) {
                        $scope.batch.selectedPeopleGroups.push(angular.copy(pplgrp));
                    }
                } else {
                    if (index !== -1)
                        $scope.batch.selectedPeopleGroups.splice(index, 1);
                }
            };

            $scope.copyPeopleGroupError = {};
            $scope.copyPeopleGroupError.error = errorDisplayerUtil.create();
            $scope.copyPeopleGroupIsLoading = false;

            $scope.copyPeopleGroupsModal = function () {
                $scope.copyPeopleGroupIsLoading = true;
                $scope.copyPeopleGroupError.error.reset();
                var selectedPeopleGroups = GroupsService.formatPeopleGroupsForCopy($scope.batch.selectedPeopleGroups);
                if (selectedPeopleGroups.length > 0) {
                    $scope.copyPeopleGroupIsLoading = false;
                    $scope.showCopyTable = true;
                    $scope.rows_In_CopyModal = selectedPeopleGroups;
                } else {
                    $scope.copyPeopleGroupIsLoading = false;
                    $scope.showCopyTable = false;
                    var errorCode = "0";
                    var message = "There are no People Groups selected for copy!";
                    $scope.copyPeopleGroupError.error.setError(errorCode, message);
                }
            };

            $scope.copyPeopleGroups = function () {
                $scope.copyPeopleGroupError.error.reset();
                $scope.copyPeopleGroupIsLoading = true;
                var data = angular.copy($scope.rows_In_CopyModal);

                console.log($scope.rows_In_CopyModal);

                var copyPeopleGroupPromise = GroupsService.saveCopiedGroup(data);
                copyPeopleGroupPromise.then(function (success) {
                    $scope.copyPeopleGroupIsLoading = false;
                    $scope.showCopyTable = false;
                    jQuery('#copyPeopleGroupsModal').modal('hide').on('hidden.bs.modal', $route.reload);
                }, function (failure) {
                    $scope.copyPeopleGroupIsLoading = false;
                    $scope.showCopyTable = true;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.copyPeopleGroupError.error.setError(errorCode, message);

                });
            };

            /******** END: BatchEdit - Select Users Between Pages and Searches ********/

            $scope.batch.showPopover = function () {
                $scope.batch.popoverIsVisible = true;
                $scope.batch.selectAll.checked = true;
                jQuery('#peopleTabBatchSelectedUsersModal').modal();
            };

            $scope.batch.hidePopover = function () {
                $scope.batch.popoverIsVisible = false;
                jQuery('#peopleTabBatchSelectedUsersModal').modal('hide');
            };

            _this.clearBatchForm = function() {
                $scope.batch.form = {};
                $scope.batch.form.orgObj = null;
                $scope.batch.form.jobType = null;
                $scope.advancedSearchStruct.jobId = null;

                $scope.batch.locationLoading = false;

                $scope.batch.locationError.reset();
                $scope.batch.error.reset();
            };

            _this.clearBatchForm();

            $scope.batch.clearBatchEditModal = function () {
                _this.clearBatchForm();
            };

            $scope.batch.editUserModal = function () {

                if (typeof $scope.orgArray === 'undefined' || $scope.orgArray === null) {
                    _this.getOrganizationHierarchyData();
                }
                if (typeof $scope.jobs === 'undefined' || $scope.jobs === null) {
                    _this.getPositionsData();
                }

                /* The batch edit window reads this variable
                 * to make sure at least 1 active user is selected.
                 * If no active users are selected, an error will be displayed
                 * and the user will be told to exit the batch window */
                
                $scope.batch.selectedActiveUsers = _.filter($scope.batch.selectedUsers, UsersService.isUserActive);

                jQuery('#peopleTabBatchEditResultsModal').modal('hide');
                jQuery('#peopleTabBatchEditModal').modal();
                _this.clearBatchForm();
            };

            $scope.refreshView = false;
            $scope.batch.editResultsModal = function(refreshUsers) {
                jQuery('#peopleTabBatchEditModal').modal('hide');
                jQuery('#peopleTabBatchAddTranscriptModal').modal('hide');
                jQuery('#peopleTabBatchEditResultsModal').modal();
                //refresh the user data
                if (refreshUsers) {
                    $scope.refreshView = true;
                    $scope.batch.successMessage = "Batch edit successful!";
                    $scope.batch.failureMessage = "Please edit individual records";
                }
                else {
                    $scope.batch.successMessage = "Batch add to transcript successful!";
                    $scope.batch.failureMessage = "Please add transcript entry to individual records";
                }
            };

            $scope.batch.hideModals = function() {
                [
                    '#peopleTabBatchEditModal',
                    '#peopleTabBatchEditResultsModal',
                    '#peopleTabBatchAddTranscriptModal',
                    '#peopleTabBatchSelectedUsersModal',
                ].forEach(function(id) {
                    jQuery(id).modal('hide').on('hidden.bs.modal', function() {
                        _this.clearBatchForm();
                        _this.clearBatchAddTranscriptForm();
                        
                        if ($scope.refreshView) {
                            $route.reload();
                        }
                    });
                });
            };

            //SLY-4142 used by uib-typeahead control for batch edit form
            $scope.batch.selectJob = function ($item, $model, $label) {
                //SLY-4967 When job select is DDL, populate $item.id with the job ID selected
                if (typeof $item !== 'undefined' && typeof $item.jobId !== 'undefined') {                    
                    $item.id = $item.jobId;
                }
                $scope.batch.form.jobType = $item;
            };

            //SLY-4141 used by uib-typeahead control for batch edit form
            $scope.batch.selectOrganization = function ($item, $model, $label) {
                $scope.batch.form.orgObj = $item;
            };

            /* Fields that we update are defined in this function */
            _this.updateFieldsOnUsersToUpload = function (usersToUpload) {
                var updatedPropertiesAndValues = {};
                for (var i = 0; i < usersToUpload.length; i += 1) {

                    delete usersToUpload[i].nodes;

                    //LMS-7985 Add Custom Fields
                    // Set text fields if they've been changed
                    var fields = ['custom0', 'custom1',
                        'custom2', 'custom3',
                        'custom4', 'custom5', 'custom6', 'custom7', 'custom8', 'custom9', 'location'
                    ];
                    for (var j = 0; j < fields.length; j += 1) {
                        var field = fields[j];
                        var removeField = field + 'Remove';
                        if ($scope.batch.form[removeField] === true) {
                            usersToUpload[i][field] = null;
                            updatedPropertiesAndValues[field] = usersToUpload[i][field];
                        } else if ($scope.batch.form[field] &&
                            $scope.batch.form[field].trim &&
                            $scope.batch.form[field].trim() !== '') {
                            usersToUpload[i][field] = $scope.batch.form[field].trim();
                            updatedPropertiesAndValues[field] = usersToUpload[i][field];
                        }
                    }

                    if ($scope.batch.form.terminationDateRemove === true) {
                        usersToUpload[i].endDate = null;
                        updatedPropertiesAndValues.endDate = usersToUpload[i].endDate;
                    } else if ($scope.batch.form.endDate) {
                        usersToUpload[i].endDate = $scope.batch.form.endDate.moment.toISOString(); 
                        updatedPropertiesAndValues.endDate = usersToUpload[i].endDate;
                    }

                    if ($scope.batch.form.managerRemove === true) {
                        usersToUpload[i].managerId = null;
                        usersToUpload[i].managerName = '';
                        updatedPropertiesAndValues.managerId = usersToUpload[i].managerId;
                        updatedPropertiesAndValues.managerName = usersToUpload[i].managerName;
                    } else if ($scope.batch.form.manager && $scope.batch.form.manager !== '') {
                        usersToUpload[i].managerId = $scope.batch.form.manager.id;
                        usersToUpload[i].managerName = $scope.batch.form.manager.firstName + ' ' + $scope.batch.form.manager.lastName;
                        updatedPropertiesAndValues.managerId = usersToUpload[i].managerId;
                        updatedPropertiesAndValues.managerName = usersToUpload[i].managerName;
                    }

                    if ($scope.batch.form.alternateManagerRemove === true) {
                        usersToUpload[i].alternateManagers = {};
                        updatedPropertiesAndValues.alternateManagerId = null;
                        updatedPropertiesAndValues.alternateManagerName = '';
                    } else if ($scope.batch.form.alternateManager && $scope.batch.form.alternateManager !== '') {
                        usersToUpload[i].alternateManagers = {};
                        usersToUpload[i].alternateManagers[$scope.batch.form.alternateManager.id] = $scope.batch.form.alternateManager.firstName + ' ' + $scope.batch.form.alternateManager.lastName;
                        updatedPropertiesAndValues.alternateManagerId = $scope.batch.form.alternateManager.id;
                        updatedPropertiesAndValues.alternateManagerName = $scope.batch.form.alternateManager.firstName + ' ' + $scope.batch.form.alternateManager.lastName;
                    }

                    if ($scope.batch.form.jobType && $scope.batch.form.jobType !== '') {
                        usersToUpload[i].jobId = $scope.batch.form.jobType.id;
                        updatedPropertiesAndValues.jobId = usersToUpload[i].jobId;
                    }

                    if ($scope.batch.form.orgObj !== null) {
                        var orgObj = $scope.batch.form.orgObj;
                        // SLY-3952 org object flattened.
                        usersToUpload[i].organizationId = orgObj.id;
                        usersToUpload[i].organizationName = orgObj.name;
                        updatedPropertiesAndValues.organizationId = usersToUpload[i].organizationId;
                        updatedPropertiesAndValues.organizationName = usersToUpload[i].organizationName;
                    }
                }
                return updatedPropertiesAndValues;
            };

            $scope.batch.scrollBarConfig = {
                autoHideScrollbar: false,
                theme: 'bai',
                advanced: {
                    updateOnContentResize: true
                },
                setHeight: '50vh',
                scrollInertia: 0,
                scrollButtons: {
                    scrollAmount: 'auto', // scroll amount when button pressed
                    enable: true // enable scrolling buttons by default
                }
            };

            $scope.batch.applyChanges = function() {
                $scope.batch.processing = true;

                var propertiesToUpdate = _this.updateFieldsOnUsersToUpload($scope.batch.selectedActiveUsers);
                $scope.batch.selectedUsers = [];

                UsersService.batchUpdateUsers($scope.batch.selectedActiveUsers, propertiesToUpdate).then(function (success) {
                    // All the failed users will have a status of false
                    $scope.batch.failedUsers = _.filter(success, function(x) {
                        return !x.success;
                    });

                    // display the results modal
                    $scope.batch.processing = false;
                    $scope.batch.editResultsModal(true);
                }, function(failure) {
                    $scope.batch.processing = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.batch.error.setError(errorCode, message);

                    $scope.batch.editResultsModal(true);
                });

            };
            /******** BATCH ADD TO TRANSCRIPT ********/

            // Initialize batch form
            $scope.batchAddTranscript = {};
            $scope.batchAddTranscript.error = errorDisplayerUtil.create();

            _this.clearBatchAddTranscriptForm = function () {
                $scope.batchAddTranscript.form = {};
                $scope.batchAddTranscript.error.reset();
            };

            _this.clearUserBatchCheckboxes = function (rows) {
                _.filter(rows, function (user) {
                    if (user.select) {
                        user.select = false;
                    }
                });
                if($scope.selectAll.checked) {$scope.selectAll.checked = false;}
            };

            _this.clearBatchAddTranscriptForm();

            $scope.batch.addTranscriptModal = function () {
                /* The batch add to transcript window reads this variable
                 * to make sure at least 1 active user is selected.
                 * If no active users are selected, an error will be displayed
                 * and the user will be told to exit the batch window */
                $scope.batch.selectedActiveUsers = _.filter($scope.batch.selectedUsers, UsersService.isUserActive);

               // jQuery('#peopleTabBatchEditResultsModal').modal('hide');
                jQuery('#peopleTabBatchAddTranscriptModal').modal();
                _this.clearBatchAddTranscriptForm();
            };

            $scope.batch.showBatchTranscriptConfirmationModal = function () {
                jQuery('#peopleTabBatchAddTranscriptModal').modal('hide');
                jQuery('#peopleTabBatchEditResultsModal').modal();              
            };

            $scope.batch.submitBatchAddToTranscript = function () {
                $scope.batch.processing = true;
                var batchAddTranscriptData = angular.copy($scope.batch.batchAddTranscript.course);
                
                batchAddTranscriptData.completedDate = batchAddTranscriptData.completedDate.moment.toISOString();
                
                batchAddTranscriptData.startDate = batchAddTranscriptData.startDate.moment.toISOString();
                
                batchAddTranscriptData.users = _.map($scope.batch.selectedActiveUsers, function(usr) {
                    return _.pick(usr, 'id', 'firstName', 'lastName');
                });
                $scope.batch.selectedUsers = [];

                TranscriptsService.batchCreate(batchAddTranscriptData).then(function (success) {
                    // All the failed users will have a status of false
                    $scope.batch.failedUsers = _.filter(success, function (x) {
                        return !x.success;
                    });

                    // display the results modal
                    $scope.batch.processing = false;
                    $scope.batch.editResultsModal(false);
                    if (_this.nodeSource === 'People Groups') {
                        _this.clearUserBatchCheckboxes(_this.peopleList.people);
                    } else {
                        _this.clearUserBatchCheckboxes($scope.rows);
                    }
                }, function (failure) {
                    $scope.batch.processing = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.batch.error.setError(errorCode, message);

                    $scope.batch.editResultsModal(false);
                    if (_this.nodeSource === 'People Groups') {
                        _this.clearUserBatchCheckboxes(_this.peopleList.people);
                    } else {
                        _this.clearUserBatchCheckboxes($scope.rows);
                    }

                });
            };

            /******** END BATCH ADD TO TRANSCRIPT ********/

         

            /* Function closes the modal and refreshes the page */
            $scope.closeViewAssignmentModal = function() {
                $scope.editAction();
            };

            $scope.modalBodyShow = false;
            $scope.modalBodyToggle = function() {
                $scope.modalBodyShow = !$scope.modalBodyShow;
            };
            
            /**** JOBS TREE *****/
            $scope.jobsTreeLoading = true;
            $scope.jobsTreeTitle = "Positions";
            $scope.jobsTreeError = {};
            $scope.jobsTreeError.error = errorDisplayerUtil.create();

            $scope.jobsTreeOptions = {};
            $scope.jobsTreeOptions.initiallyOpened = false;
            $scope.jobsTreeOptions.rootNodeIsTopLevel = false;
            
            //SLY-4142 - TODO: probably need to figure out how to get it from a settings service.
            // this is for switching between dropdown and uib-typeahead
            $scope.jobCutoff = 100;
            
            //SLY-4142 used by uib-typeahead control for add and edit forms
            $scope.selectJob = function ($item, $model, $label, $event, user) {
                $scope.user.jobType = $item.name;
                $scope.user.jobId = $item.id;

            };


            $scope.jobsNodeClick = function (item) {

                //Clear previously selected nodes in different trees
                var selectedNodes = document.getElementsByClassName("tree-selected");
                Array.prototype.forEach.call(selectedNodes, function (x) {
                    $(x).removeClass("tree-selected");
                });

                var rows = [];

                if (typeof _this.params.name !== 'undefined' && _this.params.name !== null && _this.params.name === "home") {
                    if (typeof item.id !== 'undefined') {
                        $scope.selectedNode = item.name;
                        $scope.nodeSource = "Positions";
                        // Cancel interval if there is one
                        if (queuePollingInterval.peopleGroupPollingInterval !== null) {
                            $interval.cancel(queuePollingInterval.peopleGroupPollingInterval);
                        }
                        var searchAttribute = {};
                        searchAttribute.jobId = item.id;
                        $scope.home.page = 1;
                        _this.sendAdvancedSearchQueryToServerAndDisplayUsers(searchAttribute);
                    }
                } else {
                    $location.url('users/home');
                }

            };
            
            $scope.getStates = function(countryId) {
                //var countryId = $scope.user.homeAddress.countryId;

                var states = StatesService.getLatitudeStateList(countryId);

                states.then(function(stateList) {
                    $scope.states = stateList;
                }, function(error) {
                    $scope.states = [];
                });
            };

            // People Groups Actions Starts From Here

            $scope.createPeopleGroup = {};
            $scope.createPeopleGroup.inputPeopleGroupName = false;
            _this.params = $route.current.params;

            $scope.pplGrpEditView = false;
            $scope.peoplegroupAction = function () {
                //default template for both save and edit people group form peopleTab
                $scope.homeTemplate = 'app/templates/users/peoplegroup/admin.html';
                
                $scope.currentview = "People Groups";
                // Tree drilldown opened on page load
                $scope.peopleSelected = [$scope.PeopleData[0]];
                $scope.PeopleOptions.initiallyOpened = true;
                var showActiveGroups = GroupsService.getCurrentNode() === "inactive" ? false : true;
                $scope.peoplegroups.areActive = showActiveGroups;
                
                // Get peopleGroupId from routeParams - Only for editing people group
                if (typeof _this.params.param !== "undefined" && _this.params.param !== null) {
                    
                    if (_this.params.param === "create") {
                        $scope.pplGrpEditView = true;
                        // input for creating new people group name
                        $scope.createPeopleGroup.inputPeopleGroupName = true;
                        // people group template header changes based on create/update
                        $scope.createPeopleGroup.defaultPeopleGroupHeader = true;
                        // create new people group call
                        _this.createPeoplegroup();
                    } else if (_this.params.param.toLowerCase() === "active" || _this.params.param.toLowerCase() === "inactive") {
                        var tempData = {};
                        if (_this.params.param.toLowerCase() === "inactive") {
                            tempData.currentnode = "inactive";
                            $scope.peoplegroups.areActive= false;
                        } else {
                            tempData.currentnode = "active";
                            $scope.peoplegroups.areActive = true;
                        }
                        GroupsService.setData(tempData);
                        _this.getAllPeopleGroups($scope.peoplegroups.areActive);
                        
                    } else {
                        $scope.pplGrpEditView = true;
                        // people group not editable from update page
                        $scope.createPeopleGroup.inputPeopleGroupName = false;
                        // people group template header changes based on create/update
                        $scope.createPeopleGroup.defaultPeopleGroupHeader = false;
                        // get existing people group structure call
                        _this.getPeopleGroupsStructure();
                    }
                } else {
                    $scope.pplGrpEditView = false;
                    _this.getAllPeopleGroups($scope.peoplegroups.areActive);
                }

                $scope.peopleSelected = $scope.peoplegroups.areActive === true ? $scope.PeopleData[0].nodes[0] : $scope.PeopleData[0].nodes[1];
                $scope.peopleExpanded = [$scope.PeopleData[0]];
            };

            // Erro display for getting people group structure API
            $scope.peopleGroupError = {};
            $scope.peopleGroupError.error = errorDisplayerUtil.create();
            $scope.peopleGroupsAreLoading = true;
            $scope.viewPeopleGroupStructure = false;

            $scope.containerOptions = {};
            $scope.containerOptions.showLock = false;

            // _this.defaultStructure is the array, may have multiple root containers going forward.,
            // but for now only one root container for creating or updating people group.
            _this.createPeoplegroup = function() {
                $scope.peopleGroupsAreLoading = true;

                var all = $q.all(PeopleGroupFunctions.peopleGroupActions());
                all.then(function(success) {
                    $scope.groupStructure = {};
                    $scope.groupStructure = success[0];
                    $scope.selectOptionsData = success[1];
                    $scope.containerOperandsData = GroupsService.containerOperandsRequired(success[2]);

                    GroupsService.secondaryFormatGroupStructure(success[3], success[4].groups, success[5], success[6], success[7]);

                    $scope.peopleGroupsAreLoading = false;
                    $scope.viewPeopleGroupStructure = true;

                }, function(failure) {
                    _this.failurePeopleGroup(failure);
                });
            };

            _this.getPeopleGroupsStructure = function() {
                var peopleGroupId = _this.params.param.split('/')[0];
                $scope.peopleGroupsAreLoading = true;

                var all = $q.all(PeopleGroupFunctions.peopleGroupActions(peopleGroupId));
                all.then(function(success) {
                    $scope.groupStructure = {};
                    $scope.selectOptionsData = success[1];
                    $scope.containerOperandsData = GroupsService.containerOperandsRequired(success[2]);
                    
                    GroupsService.secondaryFormatGroupStructure(success[3], success[4].groups, success[5], success[6], success[7]);
                    // Formatting PG Structure to add label and other stuff based on criteris. %IMPORTANT
                    $scope.groupStructure = GroupsService.secondaryFormatGroupStructure_groupStructure(success[0]);
                    _this.showGroupStatus(success[0]);
                    $scope.peopleGroupsAreLoading = false;
                    $scope.viewPeopleGroupStructure = true;

                }, function(failure) {
                    _this.failurePeopleGroup(failure);
                });
            };

            _this.failurePeopleGroup = function(failure) {
                $scope.peopleGroupsAreLoading = false;
                $scope.viewPeopleGroupStructure = false;
                var errorCode = failure.StatusCode;
                var message = failure.ErrorMessage;
                $scope.peopleGroupError.error.setError(errorCode, message);
            };

            $scope.editPeopleGroup = {};
            $scope.cancelPeopleGroup = function() {
                $scope.editPeopleGroup.isOpen = !$scope.editPeopleGroup.isOpen;
            };
            // Error disaply if saving people group fails.
            $scope.peopleGroupSaveError = {};
            $scope.peopleGroupSaveError.error = errorDisplayerUtil.create();
           
            // This functions serves to create, update, activate and deactivate a single people group.
            // Activate/Deactivate is based on setting the end date to people group.
            $scope.savePeopleGroupCta = function(status) {
                $scope.peopleGroupSaveError.error.reset();
                $scope.savePeopleGroupAreLoading = true;
                var payLoad = $scope.groupStructure;


                var peopleGroupSave = GroupsService.savePeopleGroup(payLoad, status);

                peopleGroupSave.then(function(success) {
                    $scope.savePeopleGroupAreLoading = false;
                    _this.showGroupStatus(success);
                    $location.path('/users/peoplegroup/'+GroupsService.getCurrentNode());
                }, function(failure) {
                    $scope.savePeopleGroupAreLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.peopleGroupSaveError.error.setError(errorCode, message);
                });
            };           

            $scope.previewPeopleError = {};
            $scope.previewPeopleError.error = errorDisplayerUtil.create();
            $scope.previewPeopleIsLoading = false;
                       

            $scope.previewPeople = function() {                
                $scope.previewPeopleError.error.reset();
                $scope.previewPeopleIsLoading = true;
                var data = $scope.groupStructure;

                var previewPeoplePromise = GroupsService.getPeoplePreview(data);
                previewPeoplePromise.then(function(success) {
                    $scope.previewPeopleIsLoading = false;
                    $scope.showPreview = true;
                    $scope.people_rows = success; //GroupsService.mapUsersForPreview(success);
                }, function(failure) {
                    $scope.previewPeopleIsLoading = false;
                    $scope.showPreview = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.previewPeopleError.error.setError(errorCode, message);
                });

            };

            $scope.invalidPeopleError = {};
            $scope.invalidPeopleError.error = errorDisplayerUtil.create();
            $scope.invalidPeopleIsLoading = false;

            $scope.$on('invalidPeopleModalOpen', function (evt, params) {
                if (params[1] === "Warning") {
                    if (!$scope.tripleOr)
                    {
                        $scope.tripleOr = true;
                        $("#invalidPeopleModal").modal();
                    }
                }
                
                    message = params[0];
                    $scope.invalidPeopleError.error.reset();
                    $scope.invalidPeopleIsLoading = true;
                    $scope.invalidPeopleIsLoading = false;
                    var errorCode = 0;
                    $scope.invalidTitle = params[1];
                    $scope.invalidPeopleError.error.setError(errorCode, message);
                
            });

            // Used in admin.peopleGroupTable.html template to submit the export preview form.
            // This is because the export preview needs to bypass angular's $http service to return a downloaded file
            // so this posts the form to the server directly from the template instead of going through the group service.
            $scope.exportPeoplePreviewFormSubmit = function () {
                var data = $scope.groupStructure;
                var dataCopy = angular.copy(data);
                if (dataCopy.groupId === null) {
                    dataCopy.groupId = 0;
                }
                $scope.peopleGroupCriteriaToSend = JSON.stringify(GroupsService.formatGroupDataToSend(dataCopy));
                $scope.currentTimezone = moment.tz.guess();
            };           

            _this.showGroupStatus = function(group) {
                var groupEndDate = group.endDate;
                var dateTime = moment.utc().format();

                if (groupEndDate !== 'undefined' && groupEndDate !== null && groupEndDate.original <= dateTime) {
                    $scope.isActive = false;
                } else {
                    $scope.isActive = true;
                }
            };

            $scope.jsonLabel = "JSON";
            $scope.showHideJson = false;
            $scope.disableJson = true;
            $scope.showJson = function() {
                $scope.showHideJson = !$scope.showHideJson;
                if ($scope.showHideJson === false) {
                    $scope.jsonLabel = "Show JSON";
                } else {
                    $scope.jsonLabel = "Hide JSON";
                }
            };

            /*************** ADD USER SECTION **********/
            //////// GLOBAL VARIABLES
            $scope.addUser = {};
            $scope.addUser.isLoading = false;
            $scope.addUser.error = errorDisplayerUtil.create();

            $scope.addUser.countriesIsLoading = false;
            $scope.addUser.locationsIsLoading = false;

            $scope.addUser.trainingAdministrator = false;
            $scope.addUser.toggleInstructor = false;


            $scope.addUser.errorMessage = "Sorry, we couldn't load this page. Please try again";

            $scope.addAction = function() {
                $scope.user = {};
                $scope.user.homeAddress = {};
                $scope.user.roles = ['unknown'];

                //Defualt Language option
                var engLanguage = _.findWhere($scope.languageOptions, { 'name': "English" });
                if (typeof engLanguage !== "undefined" && engLanguage !== null) {
                    $scope.user.languageId = engLanguage.id;
                }

                $scope.homeTemplate = 'app/templates/users/add/admin.html';

                $scope.addUser.countriesIsLoading = true;

                /**
                 * Get a list of countries
                 */
                var countries = CountriesService.getLatitudeCountryList(UsersService.getPortalId());
                countries.then(function(success) {
                    $scope.countries = success;

                    $scope.addUser.countriesIsLoading = false;
                }, function(failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.addUser.error.setError(errorCode, message);
                });

                var sso = UsersService.isSSO();
                sso.then(function (success) { $scope.user.isSSO = success; });
                
                var timezones = TimezoneService.getAllTimezones();
                timezones.then(function (data) {
                    $scope.timezones = data;
                });

                if (typeof $scope.orgArray === 'undefined' || $scope.orgArray === null) {
                    _this.getOrganizationHierarchyData();
                }
                if (typeof $scope.jobs === 'undefined' || $scope.jobs === null) {
                    _this.getPositionsData();
                }
            };

            $scope.addUser.toggleTrainingAdministrator = function(makeTrainingAdministratorBool) {
                if (typeof $scope.user.roles === 'undefined' || $scope.user.roles === null) {
                    $scope.user.roles = ['unknown'];
                }
                if ($scope.user.taRoleOrgIds === undefined) {
                    $scope.user.taRoleOrgIds = [];
                }
                var roles = $scope.user.roles;
                if (makeTrainingAdministratorBool) {
                    if (!$scope.addUser.isUserTA()) {
                        if (roles.length === 1 && _.contains(roles, 'unknown')) {
                            $scope.user.roles = ['TA'];
                        } else {
                            $scope.user.roles.push('TA');
                        }
                    }

                    // If there's only 1 domain, no choice to make, go with active domain
                    // domainsArray will only be greater than 1 for a top level TA in a multi-domain portal
                    if ($scope.domainsArray.length === 1) {
                        if ($scope.user.taRoleOrgIds.indexOf(UsersService.activeDomain().id) === -1) {
                            $scope.user.taRoleOrgIds.push(UsersService.activeDomain().id);
                        }
                    }

                    jQuery("#taOrgPicker").show();
                } else {
                    if (roles.length === 1) {
                        $scope.user.roles = ['unknown'];
                    } else {
                        $scope.user.roles = _.filter(roles, function(role) {
                            return role.toUpperCase() !== 'TA';
                        });
                    }

                    // If there's only 1 domain, no choice to make, go with active domain
                    // domainsArray will only be greater than 1 for a top level TA in a multi-domain portal
                    if ($scope.domainsArray.length === 1) {
                        $scope.user.taRoleOrgIds.splice($scope.user.taRoleOrgIds.indexOf(UsersService.activeDomain().id), 1);
                    }

                    jQuery("#taOrgPicker").hide();
                }
            };
            /*
                To check if the user has a role of TA.
            */
            $scope.addUser.isUserTA = function () {
                var roles = $scope.user.roles;
                var taRole = _.filter(roles, function (role) {
                    return role.toUpperCase() === 'TA';
                });
                return taRole.length > 0;
            };

            $scope.addUser.toggleInstructor = function (makeinstructorBool) {
                if (typeof $scope.user.roles === 'undefined' || $scope.user.roles === null) {
                    $scope.user.roles = ['unknown'];
                }

                var roles = $scope.user.roles;
                if (makeinstructorBool) {
                    if (!_.contains(roles, 'instructor')) {
                        if (roles.length === 1 && _.contains(roles, 'unknown')) {
                            $scope.user.roles = ['instructor'];
                        } else {
                            $scope.user.roles.push('instructor');
                        }
                    }
                } else {
                    if (roles.length === 1) {
                        $scope.user.roles = ['unknown'];
                    } else {
                        $scope.user.roles = _.filter(roles, function (role) {
                            return role !== 'instructor';
                        });
                    }
                }
            };

            $scope.addUserSave = {};
            $scope.addUserSave.isLoading = false;
            $scope.addUserSave.error = errorDisplayerUtil.create();

            $scope.addUser.save = function() {
                var userObj = angular.copy($scope.user);

                if (typeof userObj.homeAddress === 'undefined' || Object.keys(userObj.homeAddress).length === 0) {
                    delete userObj.homeAddress;
                }
                
                $scope.addUserSave.isLoading = true;

                var user = UsersService.createUser(userObj);
                user.then(function(success) {
                    $scope.addUserSave.isLoading = false;
                    $location.url("users/home");
                }, function(failure) {
                    $scope.addUserSave.isLoading = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.addUserSave.error.setError(errorCode, message);
                    $scope.addUser.errorMessage = message;
                });
                $scope.confirmSaveUser = false;
            };

            /* User lands on to the student transcript page */
            $scope.transcriptsAction = function() {
                $scope.homeTemplate = 'app/templates/users/transcripts/admin.html';

                $scope.isAdmin = true;

                $scope.maxDate = new Date();

                $scope.pageOptions.isLoading = true; // We're no longer loading!

                var param = $route.current.params.param;
                if (typeof param !== 'undefined') {
                    var params = param.split('/');
                    $scope.studentId = params[0];
                } else {
                    $scope.studentId = UsersService.getId();
                }

                // Gets Full Name to display on transcript
                // Gets StudentId for datepickers on transcript
                var singleUser = UsersService.fetch($scope.studentId);

                singleUser.then(function(student) {
                    $scope.fullName = student.fullName;
                    $scope.studentId = student.id;
                }, function(error) {
                    //TODO based on feedback.
                    alert('Error displaying Full Name!');
                });

                // Gets the list of assignments based on studentId
                var assignments = TranscriptsService.fetch($scope.studentId);

                assignments.then(function(success) {
                    $scope.assignments = success;

                    $scope.pageOptions.isLoading = false; // We're no longer loading!

                    $scope.propertyName = 'status';
                    $scope.reverse = true;
                }, function(response) {
                    $scope.assignments = null;

                    $scope.pageOptions.isLoading = false; // We're no longer loading!

                    $scope.pageOptions.error.setError(response.StatusCode, response.ErrorMessage);
                });
            };

            /****************** EDIT TRANSCRIPT *********************/
            $scope.editTranscript = {};
            $scope.editTranscript.isLoading = false;
            $scope.editTranscript.error = errorDisplayerUtil.create();

            $scope.edittranscriptAction = function() {
                $scope.homeTemplate = 'app/templates/users/edittranscript/admin.html';

                $scope.editTranscript.isLoading = true;

                var studentId = parseInt($route.current.params.param.split('/')[0]);
                var transcriptId = parseInt($route.current.params.param.split('/')[1]);
                var searchBeginDate = ($route.current.params.param.split('/')[2]);
                var searchEndDay = encodeURI($route.current.params.param.split('/')[3]);

                // Gets Full Name to display on transcript
                // Gets StudentId for datepickers on transcript
                var singleUser = UsersService.fetch(studentId);

                singleUser.then(function(student) {
                    $scope.fullName = student.fullName;
                    $scope.studentId = student.id;

                    // Gets the list of assignments based on studentId
                    var assignments = TranscriptsService.getSingleTranscript(studentId, transcriptId);

                    assignments.then(function(success) {
                         
                        if (success.transcriptId === transcriptId) {
                            $scope.completionMaxDate = moment().set({ hour: 23, minute: 0, second: 0, millisecond: 0 });
                            $scope.assignment = success;
                            $scope.timeCapsule = $scope.assignment.completedDate; //moment().set({ hour: $scope.assignment.completedDate.moment._a[3], minute: $scope.assignment.completedDate.moment._a[4], second: $scope.assignment.completedDate.moment._a[5], millisecond: $scope.assignment.completedDate.moment._a[6] });
                            $scope.assignment.completedDate.moment.set({ hour: $scope.assignment.completedDate.moment._a[3], minute: $scope.assignment.completedDate.moment._a[4], second: $scope.assignment.completedDate.moment._a[5], millisecond: $scope.assignment.completedDate.moment._a[6] });
                            $scope.assignment.startDate.moment.set({ hour: 0, minute: 0, second: 0, millisecond: 9 });
                            var currentTime = new Date();
                            $scope.assignment.completedDate.moment.set({ hour: currentTime.getHours(), minute: currentTime.getMinutes(), second: currentTime.getSeconds(), millisecond: currentTime.getMilliseconds() });
                            $scope.assignment.startDate.moment.set({ hour: 0, minute: 0, second: 0, millisecond: 9 });
                        }

                        // throw an error
                        if (typeof $scope.assignment === "undefined" || $scope.assignment === null) {
                            $scope.orgTreeError.error.setError(404, "Couldn't find transcript");
                            return;
                        }
                        
                        // I keep a copy for display purposes in the title
                        $scope.assignmentCopy = angular.copy($scope.assignment);

                        $scope.editTranscript.isLoading = false;
                    }, function(failure) {
                        $scope.editTranscript.isLoading = false;

                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        $scope.editTranscript.error.setError(errorCode, message);
                    });
                }, function(failure) {
                    $scope.editTranscript.isLoading = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.editTranscript.error.setError(errorCode, message);
                });
            };

            $scope.editTranscript.saveTranscript = function (assignment) {

                $scope.editTranscript.isLoading = true;
                $scope.assignment.completedDate.moment.set({ hour: $scope.timeCapsule.moment._a[3] + ($scope.timeCapsule.moment.utcOffset() / 60), minute: $scope.timeCapsule.moment._a[4], second: $scope.timeCapsule.moment._a[5], millisecond: $scope.timeCapsule.moment._a[6] });
                var _assignment = TranscriptsService.updateTranscript(assignment);
                _assignment.then(function(success) {
                    $scope.editTranscript.isLoading = false;

                    $location.path('/users/transcripts/' + $scope.studentId + '/');
                }, function(failure) {
                    $scope.editTranscript.isLoading = false;

                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.editTranscript.error.setError(errorCode, message);
                });
            };

            /*
            * Search for users
            */

            $scope.userTypeahaed = {};
            $scope.userTypeahaed.error = errorDisplayerUtil.create();
            $scope.userTypeahaed.search = function (fieldName, value) {
                $scope.userTypeahaed.error.reset();
                $scope.userTypeahaed.isLoading = true;

                var searchAttributes = {};
                searchAttributes.pageNumber = 1;
                searchAttributes.pageSize = 25;
                //searchAttributes.sortBy = "firstName";

                if (fieldName === 'manager') {
                    searchAttributes.fullNameContains = value;
                    searchAttributes.roleid = 11;
                } else if (fieldName === 'person') {
                    searchAttributes.fullNameContains = value;
                }
                else {
                    searchAttributes[fieldName] = value;
                }
                
                var userSearchPromise = UsersService.advancedSearch(searchAttributes);
                return userSearchPromise.then(function(success) {
                    $scope.userTypeahaed.isLoading = false;
                    if (success.length === 0) {
                        $scope.userTypeahaed.error.setError(404, 'There are no items to display.');
                        return false;
                    }
                    var sortProperty = 'fullName';
                    if (fieldName === 'usernamecontains') {
                        sortProperty = "userName";
                    }
                    var finalSorted = sortService.sortByPropertyContains(success, value, sortProperty);
                    return finalSorted;
                }, function (failure) {
                    $scope.userTypeahaed.isLoading = false;
                    $scope.userTypeahaed.error.setError(failure.StatusCode, failure.ErrorMessage);
                    return [];
                });
            };

            $scope.PeopleData = [{ 'name': 'People Groups', 'nodes': [] }];
            $scope.PeopleData[0].nodes.push({ 'nodes': [], 'name': 'Active', 'lockIcon': true });
            $scope.PeopleData[0].nodes.push({ 'nodes': [], 'name': 'Inactive', 'lockIcon': true });
            $scope.PeopleTreeIsLoading = false;

            _this.getTreeData = function () {
                //Do not get the Tree data on load. Get the data on node toggle event
                $scope.managerTree = [{ 'name': 'Managers', 'nodes': [{'name':'','id':'-1'}] }];
                $scope.orgTree = [{ 'name': 'Organizations', 'nodes': [{ 'name': '', 'id': '-1' }] }];
                $scope.jobsTree = [{ 'name': 'Positions', 'nodes': [{ 'name': '', 'id': '-1' }] }];
                $scope.OrgTreeIsLoading = false;
                $scope.managerTreeIsLoading = false;
                $scope.jobsTreeLoading = false;
            };

            // loads the left-pane tree.
            _this.getTreeData();

            var locations = LocationsService.getAll();
            locations.then(function(success) {
                $scope.locations = success;
            }, function(failure) {
                // Locations is optional everywhere so we don't need to handle failure here
            });

            $scope.iconClick = function(node) {
                console.log(node);
            };

            // app/images/premiumAdmin/lock.svg
            $scope.treeOptions = {
                nodeChildren: 'nodes',
                templateUrl: 'tree.html',
                //limtiTo : 5,
                //multiSelection: true,
                //iconClick: $scope.iconClick,
                //pencilIcon: true,
                lockIcon: true,
                injectClasses: {
                    ul: "a1",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"
                },
                enableRenderOnScroll: true,
                childrenLimitToRenderOnScroll: 50
            };
            
            $scope.onNodeToggle = function (node, isExpanded) {

                if (node.name === 'Organizations') {
                    if (isExpanded === false) {
                        $scope.orgTree =[{ 'name' : 'Organizations', 'nodes': [{ 'name': '', 'id': '-1' }]}];
                        return;
                    }
                    if ($scope.orgTree[0].nodes[0].id !== "-1") {
                        $scope.OrgTreeIsLoading = false;
                        return;
                    }

                    if (typeof $scope.OrgData !== "undefined" && $scope.OrgData !== null && $scope.OrgData.length > 0) {
                        $scope.OrgTreeIsLoading = true;
                        $scope.orgTree = [{ 'name': 'Organizations', 'nodes': $scope.OrgData }];
                        $scope.OrgTreeIsLoading = false;
                    } else {
                        $scope.OrgTreeIsLoading = true;
                        var orgTreePromiseGroups = OrganizationsService.getOrgTree();
                        orgTreePromiseGroups.then(
                            function(success) {
                                TreeHelpers.setPropertyOnAllNodes(success, 'lockIcon', true);
                                var nodes = [];
                                var i = 0;
                                for (i = 0; i < success.length; i++) {
                                    nodes.push(success[i]);
                                }

                                $scope.OrgData = nodes;
                                $scope.orgTree = [{ 'name': 'Organizations', 'nodes': nodes }];
                                $scope.OrgTreeIsLoading = false;
                            },
                            function(failure) {
                                var errorCode = failure.StatusCode;
                                var message = failure.ErrorMessage;
                                $scope.OrgTreeIsLoading = false;
                                $scope.orgTreeError.error.setError(errorCode, message);
                            });
                    }
                    return;
                }

                if (node.name === 'Managers') {
                    if (isExpanded === false) {
                        $scope.managerTree = [{ 'name': 'Managers', 'nodes': [{ 'name': '', 'id': '-1' }] }];
                        return;
                    }

                    if ($scope.managerTree[0].nodes[0].id !== "-1") {
                        $scope.managerTreeIsLoading = false;
                        return;
                    }

                    if (typeof $scope.managerData !== "undefined" && $scope.managerData !== null && $scope.managerData.length > 0) {
                        $scope.managerTreeIsLoading = true;
                        $scope.managerTree = [{ 'name': 'Managers', 'nodes': $scope.managerData }];
                        $scope.managerTreeIsLoading = false;
                    } else {
                        $scope.managerTreeIsLoading = true;
                        var managerTreePromise = UsersService.getManagerTree();
                        managerTreePromise.then(function (success) {
                            $scope.managerData = success;
                            $scope.managerTree = [{ 'name': 'Managers', 'nodes': $scope.managerData }];
                            $scope.managerTreeIsLoading = false;
                        },
                            function (failure) {
                                console.log(failure);
                                var errorCode = failure.StatusCode;
                                var message = failure.ErrorMessage;
                                $scope.managerTreeIsLoading = false;
                                $scope.managerTreeError.error.setError(errorCode, message);
                            });
                    }
                    return;
                }

                if (node.name === 'Positions') {
                    if (isExpanded === false) {
                        $scope.jobsTree = [{ 'name': 'Positions', 'nodes': [{ 'name': '', 'id': '-1' }] }];
                        return;
                    }
                    if ($scope.jobsTree[0].nodes[0].id !== "-1") {
                        $scope.jobsTreeLoading = false;
                        return;
                    }

                    if (typeof $scope.jobs !== "undefined" && $scope.jobs !== null) {
                        $scope.jobsTreeLoading = true;
                        var tempjobs = _.map(_.filter($scope.jobs, { "hasActiveUsersAssigned" :true }), function (j) { return _.pick(j, "id", "name"); });
                        TreeHelpers.setPropertyOnAllNodes(tempjobs, 'lockIcon', true);
                        $scope.jobsTree = [{ 'name': 'Positions', 'nodes': tempjobs }];
                        $scope.jobsTreeLoading = false;


                    } else {
                        $scope.jobsTreeLoading = true;
                        var positionsTreePromise = JobsService.getAllJobs();
                        positionsTreePromise.then(function(success) {

                            $scope.jobs = [];
                            for (var i = 0; i < success.length; i++) {
                                if (success[i].isActive === true) {
                                    $scope.jobs.push(success[i]);
                                    var index = $scope.jobs.length - 1;
                                    $scope.jobs[index].text = $scope.jobs[index].name;
                                }
                            }

                            var activeJobs = _.map(_.filter($scope.jobs, { "hasActiveUsersAssigned": true }), function (j) { return _.pick(j, "id", "name"); });
                            TreeHelpers.setPropertyOnAllNodes(activeJobs, 'lockIcon', true);

                            $scope.jobsTree = [{ 'name': 'Positions', 'nodes': activeJobs }];
                            $scope.jobsTreeLoading = false;

                        }, function(failure) {
                            console.log(failure);
                            var errorCode = failure.StatusCode;
                            var message = failure.ErrorMessage;
                            $scope.jobsTreeLoading = false;
                            $scope.jobsTreeError.error.setError(errorCode, message);
                        });
                    }
                    return;
                }

                if (node.name === 'People Groups') {
                    return;
                }
            };

            _this.calculatePages = function(totalCount) {
                $scope.home.total = totalCount;
            };

            //$timeout(function() {
            //    var paginationEl = $('#pagination');
            //    paginationEl.find('a').addClass("portalBackground");
            //}, 3000, true);

            // SLY-5342 Sub domain management for user admin tab
            $scope.topLevelOrganizationId = UsersService.topLevelOrganizationId();
            $scope.isSubDomainAdmin = UsersService.isSubDomainAdmin();
            $scope.taDomains = UsersService.taDomains();
            $scope.activeDomain = UsersService.activeDomain();
            $scope.switchDomains = {};
            $scope.switchDomains.error = errorDisplayerUtil.create();

            $scope.onActiveDomainChanged = function (activeDomain, oldDomain) {
                var switchProfilePromise = UsersService.switchProfile(activeDomain.id);
                switchProfilePromise.then(function (success) {
                    location.reload();
                }, function (failure) {
                    $scope.switchDomains.error.setError(failure.StatusCode, failure.ErrorMessage);
                    jQuery("#switchDomainsError").show();
                    setTimeout(function () {
                        $scope.switchDomains.error.reset();
                        jQuery("#switchDomainsError").hide();
                    }, 2000);
                });
            };
            
            $scope.onDomainSelectedChanged = function(domainsSelected) {
                if ($scope.user.taRoleOrgIds.length > 0)
                {
                    $scope.user.taRoleOrgIds.splice(0,$scope.user.taRoleOrgIds.length);
                }
                if (domainsSelected !== undefined) {
                    var i = 0;
                    for (i = 0; i < domainsSelected.length; i++) {
                        $scope.user.taRoleOrgIds.push(domainsSelected[i].id);
                    }
                }
            };

            $scope.initSelectedDomains = function()
            {
                if ($scope.domainsSelected === undefined){
                    $scope.domainsSelected = [];
                }
                if ($scope.user.taRoleOrgIds === undefined) {
                    $scope.user.taRoleOrgIds = [];
                }
                $scope.domainsSelected.splice(0,$scope.domainsSelected.length);
                var i = 0;
                var j = 0;
                for (i = 0; i < $scope.user.taRoleOrgIds.length; i++) {
                    for (j = 0; j < $scope.domainsArray.length;j++) {
                        if ($scope.user.taRoleOrgIds[i] === $scope.domainsArray[j].id) {
                            $scope.domainsSelected.push($scope.domainsArray[j]);
                            break;
                        }
                    }
                }
            };

            $scope.setDefaultStartTime = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null &&
                    typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                        var portalStartTime = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                        dateObj = dateUtil.formatDate(portalStartTime);
                    } else {
                        dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                    }
                } else {
                    dateObj= null;
                }

                return dateObj;
            };

            $scope.setDefaultEndTime = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null &&
                    typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    if ((typeof dateObj.displayTime === "undefined" || dateObj.displayTime === null)) {
                        var portalEndTime = dateUtil.setPortalEndTime(dateObj.moment.toDate());
                        dateObj = dateUtil.formatDate(portalEndTime);
                    } else {
                        dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                    }
                } else {
                    dateObj = null;
                }
                return dateObj;
            };

            $scope.setCurrentTime = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null && typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    var currentTime = new Date();
                    dateObj.moment.set({ hour: currentTime.getHours(), minute: currentTime.getMinutes(), second: currentTime.getSeconds(), millisecond: currentTime.getMilliseconds() });
                    dateObj = dateUtil.formatDate(dateObj.moment.toDate(), dateObj.displayTime);
                } else {
                    dateObj = null;
                }
                return dateObj;
            };
        };
    }
]);

