function Exception(message, code) {
    this.message = message;
    this.code = code;

    return {
        getMessage: function() {
            return this.message;
        },
        getCode: function() {
            return this.code;
        }
    };
}