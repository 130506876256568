app.factory('TreeHelpers', [function() {
    var _this = this;
    /* Takes a tree in the form of 
     * [{ 'property': value, 'nodes': [{'property': value, 'nodes':[] ]}, ...]
     * And sets name = value on every node in the tree including the root */
    _this.setPropertyOnAllNodes = function(data, name, value) {
        for (var i = 0; i < data.length; i += 1) {
            data[i][name] = value;
            // if there are subnodes, 
            // set them to hidden also
            if (data[i].nodes) {
                _this.setPropertyOnAllNodes(data[i].nodes, name, value);
            }
        }
    };

    // make every top level node (you can have multiple)
    // have a group Id. Make every node above those top level nodes have the same group ID
    // The group ID of the top level nodes will just be the id
    // the group ID of the nodes below it will be _group_id
    // This function recursively adds groupIds
    // If groupId isn't passed, call itself with the groupId of each top level element
    // the recursive calls will pass in group id
    _this.addGroupIds = function(data, groupId) {
        if (groupId === undefined) {
            for (var i = 0; i < data.length; i += 1) {
                _this.addGroupIds(data[i], data[i]._id);
            }
        } else {
            data._group_id = groupId;

            if(typeof data.nodes !== 'undefined') {
                for (var nodeIndex = 0; nodeIndex < data.nodes.length; nodeIndex += 1) {
                    _this.addGroupIds(data.nodes[nodeIndex], groupId);
                }
            }
        }
    };

    return _this;
}]);
