app.directive('addAssignmentsWithLibraryModal', [function () {
    return {
        restrict: 'E',
        templateUrl: 'App/templates/users/home/modals/add-assignment-with-library.html',
        controller: ['$scope', '$location', '$attrs', '$timeout', '$filter', '$route', '$q', 'errorDisplayerUtil', 'UsersService', 'AssignmentsService', 'CoursesService', 'TeamService', 'dateUtil', 'TranscriptsService', 'CatalogService', 'CategoriesService','RosterService',
        function ($scope, $location, $attrs, $timeout, $filter, $route, $q, errorDisplayerUtil, UsersService, AssignmentsService, CoursesService, TeamService, dateUtil, TranscriptsService, CatalogService, CategoriesService,RosterService) {


$scope.addAssignmentsWithLibrary = {};

$scope.addAssignmentsWithLibrary.error = errorDisplayerUtil.create();

/**
 * Fix for 2403. Since JS is loose, not defining an attribute on an object returns undefined, which resolves to false,
 * which is bad for us, since we need it to resolve to true later.
 */
$scope.addAssignmentsWithLibrary.noUsersSelectedError = false;
$scope.addAssignmentsWithLibrary.initialIsLoading = true;
$scope.addAssignmentsWithLibrary.initialFailed = false;
$scope.addAssignmentsWithLibrary.offeringError = false;

            /* Stores which version of the modal we should be using */
$scope.addAssignmentsWithLibrary.TYPE_ENUM = {
    SIMPLE: 1,
    WITH_DATE: 2,
    TEAM_SELECT: 3
};

$scope.parameter = typeof $attrs.displaytype !== 'undefined' ? $attrs.displaytype : 1;
$scope.addAssignmentsWithLibrary.displaytype = parseInt($scope.parameter);

$scope.studentId = typeof $attrs.userid !== 'undefined' ? $attrs.userid : null;



/* Stores which step of the add assignments modal to track */
$scope.addAssignmentsWithLibrary.STEP_ENUM = {
    ASSIGNMENTS: 1,
    DUE_DATE: 2,
    AUDIENCE: 3
};

/* Stores what's currently displayed on the search tab */
$scope.addAssignmentsWithLibrary.SEARCH_TAB_ENUM = {
    PLEASE_BEGIN: 0, /* Haven't typed anything yet or deleted search term */
    LOADING: 1,
    ERROR: 2,
    NO_RESULTS: 3,
    RESULTS_FOUND: 4
};
$scope.addAssignmentsWithLibrary.searchTabState = $scope.addAssignmentsWithLibrary.SEARCH_TAB_ENUM.PLEASE_BEGIN;

/* Stores what's currently displayed on the audience tab */
$scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM = {
    SELECT_USERS: 0,
    LOADING: 1,
    ERROR: 2,
    SUCCESS: 3
};
$scope.addAssignmentsWithLibrary.audienceTabState = $scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM.SELECT_USERS;

$scope.addAssignmentsWithLibrary.searchCatalog = Array();
$scope.addAssignmentsWithLibrary.searchLibraries = Array();
$scope.addAssignmentsWithLibrary.searchCategories = Array();
$scope.addAssignmentsWithLibrary.searchFields = Array();
$scope.dueDates = {};

$scope.$on('openAddAssignmentsModal', function (evt, params) {
    $scope.addAssignmentsWithLibrary.displaytype = params.displaytype;
    $scope.studentId = params.studentId;
    $scope.showOfferingsOnAssignments = SetShowOfferingsOnAssignments($location.path(), $scope.addAssignmentsWithLibrary.displaytype);
    jQuery('#addAssignmentsWithLibraryModal').modal();
    if ($scope.addAssignmentsWithLibrary.displaytype === $scope.addAssignmentsWithLibrary.TYPE_ENUM.TEAM_SELECT) {
        $scope.stepThreeView = 'app/templates/users/home/modals/addAssignmentViews/step3.audience.fullTeam.library.html';
        var teamMember = UsersService.user;
        teamMember.studentId = UsersService.getId();
        getTeamRecursiveList(teamMember);
        var alternateTeam = null;
        if ($scope.apiDueDate !== null) {
            alternateTeam = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, $scope.apiDueDate);
        }
        else {
            alternateTeam = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, null);
        }
        alternateTeam.then(function (teamTree) {
            teamTree = teamTree;

            var audienceRoot = {};

            audienceRoot.firstName = teamMember.firstName;
            audienceRoot.lastName = teamMember.lastName;
            audienceRoot.studentId = teamMember.studentId;
            audienceRoot.teamList = teamTree;

            $scope.addAssignmentsWithLibrary.alternateDataList = audienceRoot; // needs to be in array because
            // end result is list of lists

            // add my team (direct) list to audienceData

            // Make all team members hidden by default
            $scope.setPropertyOnAllNodes($scope.addAssignmentsWithLibrary.alternateDataList, 'hidden', true);

            // uncheck all team members by default
            $scope.setPropertyOnAllNodes($scope.addAssignmentsWithLibrary.alternateDataList, 'checked', false);
        }, function (failure) {
            // @TODO do something if we can't load the modal
            console.log(failure);
        });
    }
    if ($scope.addAssignmentsWithLibrary.searchCatalog.length === 0) {
        var coursePromises = [];
        coursePromises.push(CatalogService.getBaiCatalogData()); // Used for catalog info
        coursePromises.push(CoursesService.getActiveCoursesForOrg()); // Used as course list, filtered using 2 above
        $q.all(coursePromises).then(function (coursesData) {
            var catalogTree = coursesData[0];
            $scope.addAssignmentsWithLibrary.searchCatalog = coursesData[1];
            _.each(catalogTree.formattedCatalogTreeData, function (catalog) {
                $scope.assignCatalog(catalog, catalog.nodeId, catalog.name);
            });
            // Create unique lists of catalogs and course types for dropdowns
            _.each($scope.addAssignmentsWithLibrary.searchCatalog, function (c) {
                if (c.rootNodeId !== null && typeof c.rootNodeId !== 'undefined' && _.findIndex($scope.addAssignmentsWithLibrary.searchLibraries, { "nodeId": c.rootNodeId }) === -1) {
                        $scope.addAssignmentsWithLibrary.searchLibraries.push({ "nodeId": c.rootNodeId, "name": c.rootNodeDescription });
                }
                if (_.findIndex($scope.addAssignmentsWithLibrary.searchCategories, { "friendlyName": $filter('assignmentAssetTypeName')(c.type) }) === -1) {
                        $scope.addAssignmentsWithLibrary.searchCategories.push({ "type": c.type, "friendlyName": $filter('assignmentAssetTypeName')(c.type) });
                }
            });
            $scope.addAssignmentsWithLibrary.searchFields = CoursesService.getSearchableCatalog();
            $scope.addAssignmentsWithLibrary.initialIsLoading = false;
            $scope.addAssignmentsWithLibrary.selectAll = false;
            $scope.catalogDropdownValues = _.sortBy($scope.catalogDropdownValues, "name");
            $scope.courseTypeDropdownValues = _.sortBy($scope.courseTypeDropdownValues, "type");
            $scope.courseTypeDropdownValues = $scope.courseTypeDropdownValues.reverse();
        }, function (failure) {
            $scope.coursesApiAreLoading = false;
            var errorCode = failure.StatusCode;
            var message = failure.ErrorMessage;
            $scope.viewCoursesError.error.setError(errorCode, message);
        });
           
    }
    else {
        $scope.addAssignmentsWithLibrary.initialIsLoading = false;
    }
});

$scope.assignCatalog = function (node, rootId, rootDescription) {
    var nodeId = node.nodeId;
    _.each($scope.addAssignmentsWithLibrary.searchCatalog, function (course) {
        if (course.catalogId === nodeId) {
            course.rootNodeId = rootId;
            course.rootNodeDescription = rootDescription;
        } else if (course.catalogId === null) {
            course.rootNodeId = 0;
            course.rootNodeDescription = "Custom Catalog";
        }
    });

    if (node.nodes) {
        _.each(node.nodes, function (childNode) {
            $scope.assignCatalog(childNode, rootId, rootDescription);
    });
    }
};


/* Stores assignments */
// This one stores the list of assignments in the
// search page
$scope.addAssignmentsWithLibrary.searchResults = Array();
// This one stores assignments indexed by ID
// we can add assignments with
// $scope.addAssignmentsWithLibrary.queuedAssignments[assignment.id] = assignment;
// and delete them with
// delete $scope.addAssignmentsWithLibrary.queuedAssignments[assignment.id];
// and check if an assignment exists with
// if (assignment.id in $scope.addAssignmentsWithLibrary.queuedAssignments)
$scope.addAssignmentsWithLibrary.queuedAssignments = {};
$scope.addAssignmentsWithLibrary.selectAll = false;
// Used on due date tab. keeps list of assignments that have been unchecked
// If the user goes to the first or last tabs, this variable will be used to remove assignments
// from the list of queued assignments
// Used in same manner as $scope.addAssignmentsWithLibrary.queuedAssignments except it's the opposite
$scope.addAssignmentsWithLibrary.assignmentsToggledForRemoval = {};

$scope.addAssignmentsWithLibrary.clearAddAssignmentsModal = function () {

    _.each($scope.addAssignmentsWithLibrary.searchResults, function (assignment) {

        assignment.checked = false; 

        if (assignment.type === 'ilt') {

            assignment.viewOfferings = false;
            assignment.selectedOffering = null;
            assignment.offerings = null;
            assignment.enrollError = false;
        }
    });

    if ($scope.addAssignmentsWithLibrary.alternateDataList) {
        $scope.setPropertyOnAllNodes($scope.addAssignmentsWithLibrary.alternateDataList, 'checked', false);
    }
    if ($scope.addAssignmentsWithLibrary.audienceData !== null || $scope.addAssignmentsWithLibrary.audienceData !== undefined) {
        $scope.setPropertyOnAllNodes($scope.addAssignmentsWithLibrary.audienceData, 'checked', false);
    }
    $scope.addAssignmentsWithLibrary.queuedAssignments = {};
    $scope.addAssignmentsWithLibrary.assignmentsToggledForRemoval = {};
    $scope.addAssignmentsWithLibrary.selectedUsers = {};
    $scope.addAssignmentsWithLibrary.keyword = '';
    $scope.addAssignmentsWithLibrary.library = null;
    $scope.addAssignmentsWithLibrary.contentType = null;
    $scope.addAssignmentsWithLibrary.selectAll = false;
    $scope.addAssignmentsWithLibrary.noUsersSelectedError = false;
    $scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.ASSIGNMENTS;
    $scope.addAssignmentsWithLibrary.searchResults = Array();
    $scope.addAssignmentsWithLibrary.searchTabState = $scope.addAssignmentsWithLibrary.SEARCH_TAB_ENUM.PLEASE_BEGIN;
    $scope.addAssignmentsWithLibrary.audienceTabState = $scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM.SELECT_USERS;
    $scope.addAssignmentsWithLibrary.autoOpenedList = false;
    $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = false;
    $scope.addAssignmentsWithLibrary.anySelected = false;
    $scope.addAssignmentsWithLibrary.selectAll = false;
    $scope.addAssignmentsWithLibrary.selectedCount = 0;
    $scope.addAssignmentsWithLibrary.error.reset();
    $scope.addAssignmentsWithLibrary.offeringError = false;
    activateAssignmentsPill();

};



// Stores the list of selected users
// Used same way as $scope.addAssignmentsWithLibrary.queuedAssignments
// See that $scope.addAssignmentsWithLibrary.queuedAssignments for details
$scope.addAssignmentsWithLibrary.selectedUsers = {};

// On audience tab, stores whether the Everyone, Alternate or Direct Team boxes are checked
// When these are checked, when the user is submitting in tab 3, these will be checked
// and will change the content / override $scope.addAssignmentsWithLibrary.selectedUsers

/* Used for storing search data for filtering assignments at top of modal */
$scope.addAssignmentsWithLibrary.keyword = '';

$scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.ASSIGNMENTS;

// Keeps track of whether we should disable the next/submit button
$scope.addAssignmentsWithLibrary.buttonDisabled = false;

// Flag to automatically open the team list in the add assignment modals.
$scope.addAssignmentsWithLibrary.autoOpenedList = false;

// In addition to revealing the select assignments tab
// this function checks the list of assignments toggled for removal
// and removes those assignments from the queue
$scope.addAssignmentsWithLibrary.selectAssignments = function () {
    // reset the last tab in case the user wants to assign more assignments
    $scope.addAssignmentsWithLibrary.audienceTabState = $scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM.SELECT_USERS;

    /* Check if any assignments are in the list of assignments toggled for removal
     * Remove those assignments */
    deleteAssignmentsToggledForRemoval($scope.addAssignmentsWithLibrary.queuedAssignments, $scope.addAssignmentsWithLibrary.assignmentsToggledForRemoval);
    $scope.addAssignmentsWithLibrary.assignmentsToggledForRemoval = {}; // need to figure out how to clear this inside the function

    $scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.ASSIGNMENTS;
    activateAssignmentsPill();
};

/* These three functions are necessary
 * because there isn't a clear way to override how bootstrap pills work
 * If there's a way to do it, override these functions
 */
var activateAssignmentsPill = function () {
    setTimeout(function () {
        //angular.element('#assignmentsTabSelector').addClass('active');
        //angular.element('#dueDateTabSelector').removeClass('active');
        //angular.element('#audienceTabSelector').removeClass('active');

        angular.element('#assignmentsTabSelector > a').addClass('portalBackground');
        angular.element('#assignmentsTabSelector > .nav-arrow').addClass('portalBorderLeft');
        if ($scope.addAssignmentsWithLibrary.displaytype >= $scope.addAssignmentsWithLibrary.TYPE_ENUM.WITH_DATE) {
            angular.element('#dueDateTabSelector > a').removeClass('portalBackground');
            angular.element('#dueDateTabSelector > .nav-arrow').removeClass('portalBorderLeft');
            angular.element('#dueDateTabSelector > .nav-wedge').removeClass('portalBorderTop portalBorderRight portalBorderBottom');

            if ($scope.addAssignmentsWithLibrary.displaytype === $scope.addAssignmentsWithLibrary.TYPE_ENUM.TEAM_SELECT) {
                angular.element('#audienceTabSelector > a').removeClass('portalBackground');
                angular.element('#audienceTabSelector > .nav-arrow').removeClass('portalBorderLeft');
                angular.element('#audienceTabSelector > .nav-wedge').removeClass('portalBorderTop portalBorderRight portalBorderBottom');
            }
        }
    }, 1);
};

var activateDueDatePill = function () {
    setTimeout(function () {
        //angular.element('#assignmentsTabSelector').removeClass('active');
        //angular.element('#dueDateTabSelector').addClass('active');
        //angular.element('#audienceTabSelector').removeClass('active');

        // Clear extra colors coming from custom CSS call for first tab
        angular.element('#assignmentsTabSelector > a').removeClass('portalBackground');
        angular.element('#assignmentsTabSelector > .nav-arrow').removeClass('portalBorderLeft');

        // Add extra colors coming from custom CSS call for second tab
        angular.element('#dueDateTabSelector > a').addClass('portalBackground');
        angular.element('#dueDateTabSelector > .nav-arrow').addClass('portalBorderLeft');
        angular.element('#dueDateTabSelector > .nav-wedge').addClass('portalBorderTop portalBorderRight portalBorderBottom');
        if ($scope.addAssignmentsWithLibrary.displaytype === $scope.addAssignmentsWithLibrary.TYPE_ENUM.TEAM_SELECT) {
            angular.element('#audienceTabSelector > a').removeClass('portalBackground');
            angular.element('#audienceTabSelector > .nav-arrow').removeClass('portalBorderLeft');
            angular.element('#audienceTabSelector > .nav-wedge').removeClass('portalBorderTop portalBorderRight portalBorderBottom');
        }
    }, 1);
};

var activateAudiencePill = function () {
    setTimeout(function () {
        //angular.element('#assignmentsTabSelector').removeClass('active');
        //angular.element('#dueDateTabSelector').removeClass('active');
        //angular.element('#audienceTabSelector').addClass('active');

        angular.element('#assignmentsTabSelector > a').removeClass('portalBackground');
        angular.element('#assignmentsTabSelector > .nav-arrow').removeClass('portalBorderLeft');

        // Clear colors coming from custom CSS call for second tab
        angular.element('#dueDateTabSelector > a').removeClass('portalBackground');
        angular.element('#dueDateTabSelector > .nav-arrow').removeClass('portalBorderLeft');
        angular.element('#dueDateTabSelector > .nav-wedge').removeClass('portalBorderTop portalBorderRight portalBorderBottom');

        // Add extra colors coming from custom CSS call for third tab
        angular.element('#audienceTabSelector > a').addClass('portalBackground');
        angular.element('#audienceTabSelector > .nav-wedge').addClass('portalBorderTop portalBorderRight portalBorderBottom');
    }, 1);
};

// This function goes through every assignment toggled for removal
// in the assignmentsToggledForRemoval variable
// and removes it from the list of queuedAssignments

var deleteAssignmentsToggledForRemoval = function (queuedAssignments, assignmentsToggledForRemoval) {
    var assignmentsToRemoveIds = Object.keys(assignmentsToggledForRemoval);

    for (var i = 0; i < assignmentsToRemoveIds.length; i += 1) {
        if (assignmentsToRemoveIds[i] in queuedAssignments) {
            delete queuedAssignments[assignmentsToRemoveIds[i]];
        }
    }
};

// This function also checks if we've selected some assignments,
// if not, it puts the user back on the select assignments tab.
$scope.addAssignmentsWithLibrary.selectDueDate = function () {
    // reset the last tab in case the user wants to assign more assignments
    $scope.addAssignmentsWithLibrary.audienceTabState = $scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM.SELECT_USERS;

    // If the user hasn't selected any assignments, don't let them go to the due date tab
    if (Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments).length === 0) {
        $scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.ASSIGNMENTS;
        activateAssignmentsPill();
        $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = true;
        return;
    }

    // otherwise let the user eat cake!
    $scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.DUE_DATE;
    $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = false;

    // Mark career paths recommended and all other course types recommended
    _.each($scope.addAssignmentsWithLibrary.queuedAssignments, function (assignment) {
        if (assignment.type === "careerPath" || assignment.productType === "Document") {
            assignment.isRequired = false;
        } else {
            assignment.isRequired = true;
        }
    });

    activateDueDatePill();
};

$scope.addAssignmentsWithLibrary.nextButton = function () {
    switch ($scope.addAssignmentsWithLibrary.displaytype) {
        case $scope.addAssignmentsWithLibrary.TYPE_ENUM.SIMPLE:
                   $scope.addAssignmentsWithLibrary.submitAssignmentsForSelectedUsers();

            break;
        case $scope.addAssignmentsWithLibrary.TYPE_ENUM.WITH_DATE:
            switch ($scope.addAssignmentsWithLibrary.step) {
                case $scope.addAssignmentsWithLibrary.STEP_ENUM.ASSIGNMENTS:
                    $scope.addAssignmentsWithLibrary.selectDueDate();

                    break;
                case $scope.addAssignmentsWithLibrary.STEP_ENUM.DUE_DATE:
                    $scope.addAssignmentsWithLibrary.submitAssignmentsForSelectedUsers();

                    break;
            }
            break;
        case $scope.addAssignmentsWithLibrary.TYPE_ENUM.TEAM_SELECT:
            switch ($scope.addAssignmentsWithLibrary.step) {
                case $scope.addAssignmentsWithLibrary.STEP_ENUM.ASSIGNMENTS:
                    $scope.addAssignmentsWithLibrary.selectDueDate();

                    break;
                case $scope.addAssignmentsWithLibrary.STEP_ENUM.DUE_DATE:
                    $scope.addAssignmentsWithLibrary.selectAudience();

                    break;
                    // Assign the selected assignments to all selected users
                case $scope.addAssignmentsWithLibrary.STEP_ENUM.AUDIENCE:
                    $scope.addAssignmentsWithLibrary.submitAssignmentsForSelectedUsers();
                    //jQuery('#addAssignmentsWithLibraryModal').modal('hide');

                    break;
            }
            break;
    }
};


/*** search assignments tab logic */
/* On the select assignments tab, this function allows the user to
 * search for assignments by assignment type. It handles calling the service
 * and displaying the data.
 * Also checks if assignments were already queued. if so, premarks them as checked
 */
 $scope.addAssignmentsWithLibrary.searchAssignments = function (keyword) {
     /* Adding this class fixes the issue where a nested modal, when the child is closed, */
     /*  removes the class required to scroll to see the full parent modal */

    $('body').addClass('modal-open');

    return $q(function (resolve, reject) {
    // If no search criteria, return nothing
    if ((typeof keyword === 'undefined' || keyword === null || keyword === "") &&
    ($scope.addAssignmentsWithLibrary.library === null || typeof $scope.addAssignmentsWithLibrary.library === 'undefined' || $scope.addAssignmentsWithLibrary.library === "") &&
    ($scope.addAssignmentsWithLibrary.contentType === null || typeof $scope.addAssignmentsWithLibrary.contentType === 'undefined' || $scope.addAssignmentsWithLibrary.contentType === "")) {
        $scope.addAssignmentsWithLibrary.searchResults = null;
        resolve(null); // Don't return anything
    }

    var checkKeyword = false;
    if (typeof keyword !== 'undefined' && keyword !== null && keyword !== "") {
        var stubResults = $filter('filter')($scope.addAssignmentsWithLibrary.searchFields, keyword);
        $scope.addAssignmentsWithLibrary.searchResults = $filter('filter')($scope.addAssignmentsWithLibrary.searchCatalog, function (course) {
            for (i = 0; i < stubResults.length; i++) {
                if (course.code === stubResults[i].code) {
                    return course;
                }
            }
        });
        checkKeyword = true;
    }

    var checkCatalog = false;
    if ($scope.addAssignmentsWithLibrary.library !== null && typeof $scope.addAssignmentsWithLibrary.library !== 'undefined' && $scope.addAssignmentsWithLibrary.library !== "") {
        checkCatalog = true;
        if (checkKeyword) {
            $scope.addAssignmentsWithLibrary.searchResults = $filter('filter')($scope.addAssignmentsWithLibrary.searchResults, function (course) {
                if (course.rootNodeDescription === $scope.addAssignmentsWithLibrary.library.name) {
                    return course;
                }
            });
        } else {
            $scope.addAssignmentsWithLibrary.searchResults = $filter('filter')($scope.addAssignmentsWithLibrary.searchCatalog, function (course) {
                if (course.rootNodeDescription === $scope.addAssignmentsWithLibrary.library.name) {
                    return course;
                }
            });
        }
    }
    if ($scope.addAssignmentsWithLibrary.contentType !== null && typeof $scope.addAssignmentsWithLibrary.contentType !== 'undefined' && $scope.addAssignmentsWithLibrary.contentType !== "") {
        checkType = true;
        if (checkKeyword || checkCatalog) {
            $scope.addAssignmentsWithLibrary.searchResults = $filter('filter')($scope.addAssignmentsWithLibrary.searchResults, function (course) {
                if (course.type === $scope.addAssignmentsWithLibrary.contentType.type) {
                    return course;
                }
            });
        } else {
            $scope.addAssignmentsWithLibrary.searchResults = $filter('filter')($scope.addAssignmentsWithLibrary.searchCatalog, function (course) {
                if (course.type === $scope.addAssignmentsWithLibrary.contentType.type) {
                    return course;
                }
            });
        }
    }
    if ($scope.addAssignmentsWithLibrary.searchResults === null || $scope.addAssignmentsWithLibrary.searchResults.length === 0) {
        $scope.addAssignmentsWithLibrary.searchTabState = $scope.addAssignmentsWithLibrary.SEARCH_TAB_ENUM.NO_RESULTS;
    } else {
        $scope.addAssignmentsWithLibrary.searchTabState = $scope.addAssignmentsWithLibrary.SEARCH_TAB_ENUM.RESULTS_FOUND;
    }
    });
    
};

$scope.addAssignmentsWithLibrary.selectedCount = 0;


// When you select an assignment in the search tab, it gets
// added to the list of queued assignments here
$scope.addAssignmentsWithLibrary.queueAssignment = function (assignment) {
    if (assignment.id in $scope.addAssignmentsWithLibrary.queuedAssignments) {
        delete $scope.addAssignmentsWithLibrary.queuedAssignments[assignment.id];
        $scope.addAssignmentsWithLibrary.selectedCount--;
        $scope.addAssignmentsWithLibrary.selectAll = false;
        if (Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments).length === 0) {
            $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = true;
            $scope.addAssignmentsWithLibrary.anySelected = false;
        }
        if (assignment.selectedOffering) {

            assignment.selectedOffering = null; 
            _.each(assignment.offerings, function (offering) { offering.checked = false; });
        }
    } else {
        $scope.addAssignmentsWithLibrary.queuedAssignments[assignment.id] = assignment;
        $scope.addAssignmentsWithLibrary.selectedCount++;
        if ($scope.addAssignmentsWithLibrary.selectedCount === $scope.addAssignmentsWithLibrary.searchResults.length) {
            $scope.addAssignmentsWithLibrary.selectAll = true;
        }
        // if you've selected something then you shouldn't see this error
        $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = false;
        $scope.addAssignmentsWithLibrary.anySelected = true;
    }
};

/*** due date tab stuff */
// when you click remove, remove the course from the list
$scope.addAssignmentsWithLibrary.toggleAssignmentForRemoval = function (assignment) {
    if (assignment.id in $scope.addAssignmentsWithLibrary.queuedAssignments) {
        //assignment is removed so deselect it, if they go back, they can reselect the course if they want
        assignment.checked = false;
        $scope.addAssignmentsWithLibrary.selectedCount--;
        $scope.addAssignmentsWithLibrary.selectAll = false;
        delete $scope.addAssignmentsWithLibrary.queuedAssignments[assignment.id];
    }

    if (Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments).length === 0) {
        $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = true;
    }
};

/*** audience tab stuff */
// Stores the list of trees in the audience tab
$scope.addAssignmentsWithLibrary.audienceData = [];

/* Handles group checking in the audience tab */
var selectUsersRecursively = function (item, checked) {
    item.checked = checked;

    // if the item is checked, add it to list of selected users
    if (item.checked) {
        $scope.addAssignmentsWithLibrary.selectedUsers[item.studentId] = item;
    } else {
        // @TODO @sneilan check if any other trees of users are checked
        // don't delete the user if it's checked somewhere else
        delete $scope.addAssignmentsWithLibrary.selectedUsers[item.studentId];
    }

    if (item.teamList && item.teamList.length > 0) {
        for (var i = 0; i < item.teamList.length; i += 1) {
            selectUsersRecursively(item.teamList[i], checked);
        }
    }

    if (item.alternateTeamList && item.alternateTeamList.length > 0) {
        for (var j = 0; j < item.alternateTeamList.length; j += 1) {
            selectUsersRecursively(item.alternateTeamList[j], checked);
        }
    }
};

$scope.addAssignmentsWithLibrary.toggleUser = function (item, recursive) {
    // By default remove the error
    // We'll renable the no users selected error near the bottom
    // If the user hasn't selected any users
    $scope.addAssignmentsWithLibrary.noUsersSelectedError = false;

    // toggle the checkbox
    item.checked = !item.checked;

    // if the item is checked, add it to list of selected users
    if (item.checked) {
        $scope.addAssignmentsWithLibrary.selectedUsers[item.studentId] = item;
    } else {
        // @TODO @sneilan check if any other trees of users are checked
        // don't delete the user if it's checked somewhere else
        delete $scope.addAssignmentsWithLibrary.selectedUsers[item.studentId];
    }

    if (recursive === true && item.teamList && item.teamList.length > 0) {
        for (var i = 0; i < item.teamList.length; i += 1) {
            selectUsersRecursively(item.teamList[i], item.checked);
        }
    }

    // if no users are selected, display an error
    /**
     * This code is never run unless this method is called
     */
    if (Object.keys($scope.addAssignmentsWithLibrary.selectedUsers).length === 0) {
        $scope.addAssignmentsWithLibrary.noUsersSelectedError = true;
    }
};

/* Handles visibility of team member lists in the audience tab */
$scope.addAssignmentsWithLibrary.toggle = function (item) {
    item.hidden = !item.hidden;
};

/* list, object, property.. */
//_this.setPropertyOnAllNodes = function (data, name, value) {
//    for (var i = 0; i < data.length; i += 1) {
//        data[i][name] = value;

//        // if there are subnodes,
//        // set them to hidden also
//        /**
//         * This never recurses.
//         */
//        if (typeof data[i].teamList !== 'undefined' && data[i].teamList.length > 0) {
//            _this.setPropertyOnAllNodes(data[i].teamList, name, value);
//        }
//    }
//};

// This function gets the assignments from
// $scope.addAssignmentsWithLibrary.queuedAssignments = {}
// and users from
// $scope.addAssignmentsWithLibrary.selectedUsers = {};
// and submits the assignments
// it's called by the next button but only on the audience tab
$scope.addAssignmentsWithLibrary.submitAssignmentsForSelectedUsers = function () {
    var assignmentIds;
    var userIds;
    /**
     * This check must be performed instead of the noUsersSelectedError check because
     * toggleUsers (the only place that is changed to true) is not inherently run, as it relies
     * on an user's action. We have to check how many
     */
    if ($scope.addAssignmentsWithLibrary.displaytype === $scope.addAssignmentsWithLibrary.TYPE_ENUM.SIMPLE) {
        if ($route.current.params.controller === 'rules'){
            $scope.applyNewAssignmentsCta($scope.addAssignmentsWithLibrary.queuedAssignments);
        }
        else{
            $scope.selectCourseInList($scope.addAssignmentsWithLibrary.queuedAssignments);
        }
        jQuery('#addAssignmentsWithLibraryModal').modal('hide');
        $scope.addAssignmentsWithLibrary.clearAddAssignmentsModal();
        return;
    }
    if ($scope.studentId !== null) {

        assignmentIds = Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments);

        userIds = [$scope.studentId];

        //CALL ENROLLMENT API FOR EACH ASSIGNMENT'S SELECTED OFFERING

        var offeringAssignments = _.filter($scope.addAssignmentsWithLibrary.queuedAssignments, function(assignment){

            return assignment.selectedOffering;
        });

        var submitOffering = function (assignment) {

            return RosterService.enrollSingle($scope.studentId, assignment.selectedOffering)
                .then(
                    function (success) {

                        assignment.selectedOffering = null;

                        if (success.data.status === 'InProgress') {
                            assignment.offeringInProgressMessage = success.data.message;
                            assignment.offeringInProgress = true;

                            $timeout(function () {
                                assignment.offeringInProgress = false;
                                jQuery("#" + assignment.id + "-offeringInProgress").hide();
                            }, 3000);
                        }

                        if (success.data.status === 'Waitlisted') {
                            assignment.offeringWaitlistMessage = success.data.message;
                            assignment.offeringWaitlist = true;

                            $timeout(function () {
                                assignment.offeringWaitlist = false;
                                jQuery("#" + assignment.id + "-offeringWaitlist").hide();
                            }, 3000);
                        }
                    },
                    function (error) {
                        assignment.offeringErrorMessage = error.ErrorMessage;
                        assignment.offeringError = true;
                        assignment.enrollError = true;
                        assignment.selectedOffering = null;
                        $scope.addAssignmentsWithLibrary.offeringError = true;

                        $timeout(function () {

                            assignment.offeringError = false;
                            jQuery("#" + assignment.id + "-offeringError").hide();
                        }, 3000);
                    });
        };
        
        var submitSequential = function (assignments) {

            return assignments.reduce(function (prev, assignment,idx) {
                return prev.then(function () {

                    return submitOffering(assignment);
                }).then(function (result) {

                    if (idx === assignments.length - 1) {
                        $timeout(function () { $scope.addAssignmentsWithLibrary.submitAssignmentPromises(assignmentIds, userIds); }, 3000);
                    }
                });
            }, Promise.resolve());
        };

        if (offeringAssignments.length > 0) { submitSequential(offeringAssignments); }
        else { $scope.addAssignmentsWithLibrary.submitAssignmentPromises(assignmentIds, userIds); }
    }
    else {
        if (Object.keys($scope.addAssignmentsWithLibrary.selectedUsers).length === 0) {
            $scope.addAssignmentsWithLibrary.noUsersSelectedError = true;

            return;
        }

        $scope.addAssignmentsWithLibrary.audienceTabState = $scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM.LOADING;

        assignmentIds = Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments);

        userIds = Object.keys($scope.addAssignmentsWithLibrary.selectedUsers);

        $scope.addAssignmentsWithLibrary.submitAssignmentPromises(assignmentIds, userIds);
    }  
};

$scope.addAssignmentsWithLibrary.submitAssignmentPromises = function (assignmentIds, userIds) {
    var promises = [];

    var createPromiseToSubmitAssignments = function (assignmentId, dueDate, required, users) {
        return AssignmentsService.submitSimpleAssignment(assignmentId, dueDate, required, users);
    };

    for (var i = 0; i < assignmentIds.length; i += 1) {
        var assignmentKey = Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments)[i];

        var assignment = $scope.addAssignmentsWithLibrary.queuedAssignments[assignmentKey];
        if (assignment.dueDate !== null && typeof assignment.dueDate !== 'undefined' && typeof assignment.dueDate.moment !== "undefined" && _.isObject(assignment.dueDate.moment))
            assignment.dueDate = assignment.dueDate.moment.utc().format();
        else assignment.dueDate = null;

        if (!assignment.enrollError) {
            promises.push(createPromiseToSubmitAssignments(assignment.id, assignment.dueDate, assignment.isRequired, userIds));
        }
    }

    $q.all(promises).then(function (values) {
        $scope.addAssignmentsWithLibrary.audienceTabState = $scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM.SUCCESS;
        switch ($scope.addAssignmentsWithLibrary.displaytype) {
            case $scope.addAssignmentsWithLibrary.TYPE_ENUM.WITH_DATE:

                if (!$scope.addAssignmentsWithLibrary.offeringError) { jQuery('#addAssignmentsWithLibraryModal').modal('hide'); }

                jQuery('#addAssignmentsWithLibraryModal').on('hidden.bs.modal', function () {
                    angular.element(document.querySelector('#viewAssignmentsModal')).modal();
                    $scope.addAssignmentsWithLibrary.clearAddAssignmentsModal();
                });
                break;
            case $scope.addAssignmentsWithLibrary.TYPE_ENUM.TEAM_SELECT:
                jQuery('#addAssignmentsWithLibraryModal').modal('hide');

                jQuery('#addAssignmentsWithLibraryModal').on('hidden.bs.modal', function () {
                    $scope.addAssignmentsWithLibrary.clearAddAssignmentsModal();
                    $scope.homeAction();
                });
                $scope.cpFilter.direct = true;
                $scope.cpFilter.alterate = false;
                $scope.cpFilter.sub = false;
                break;
        }

    }, function (failure) {
        $scope.addAssignmentsWithLibrary.audienceTabState = $scope.addAssignmentsWithLibrary.AUDIENCE_TAB_ENUM.ERROR;

        $scope.addAssignmentsWithLibrary.error.setError(failure.StatusCode, failure.ErrorMessage);
    });
};

// In addition to revealing the select audience tab
// this function checks the list of assignments toggled for removal
// and removes those assignments from the queue
$scope.addAssignmentsWithLibrary.selectAudience = function () {
    // If the user hasn't selected any assignments, don't let them go to the audience tab
    if (Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments).length === 0) {
        $scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.ASSIGNMENTS;

        activateAssignmentsPill();

        $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = true;

        return;
    }

    /* Check if any assignments are in the list of assignments toggled for removal
     * Remove those assignments.
     * If there are no assignments in the queue, send us back to the select assignments tab
     * trigger a tooltip on the select audience tab stating an error.
     */

    var numQueuedAssignments = Object.keys($scope.addAssignmentsWithLibrary.queuedAssignments).length;
    var numAssignmentsMarkedForDeletion = Object.keys($scope.addAssignmentsWithLibrary.assignmentsToggledForRemoval).length;

    // if the user has marked all assignments for deletion, don't let them continue
    if (numQueuedAssignments === numAssignmentsMarkedForDeletion) {
        $scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.DUE_DATE;

        activateDueDatePill();

        $scope.addAssignmentsWithLibrary.noAssignmentsSelectedError = true;

        return;
    }

    deleteAssignmentsToggledForRemoval($scope.addAssignmentsWithLibrary.queuedAssignments, $scope.addAssignmentsWithLibrary.assignmentsToggledForRemoval);

    $scope.addAssignmentsWithLibrary.assignmentsToggledForRemoval = {};

    $scope.addAssignmentsWithLibrary.step = $scope.addAssignmentsWithLibrary.STEP_ENUM.AUDIENCE;

    activateAudiencePill();
};

/*** Create the modal here */
// Creates the add assignments modal
// called by the Add Assignment button
// next to each team member
/**
 * Opens the add assignments modal for a specific user within a manager's team. They can be at any level of
 * the team tree.
 *
 * @param teamMember Object
 */
$scope.addAssignmentsAction = function (teamMember) {
    _this.getTeamRecursiveList(teamMember);

    $scope.stepThreeView = 'app/templates/users/home/modals/addAssignmentViews/step3.audience.subTeam.library.html';

    jQuery('#addAssignmentsModal').modal();
};

/*** Create the modal here */
// Creates the add assignments modal
// called by the Add Assignment button
// next to each team member
/**
 * Opens the add assignments modal for the entire team of the logged in manager.
 *
 * @param teamMember Object
 */
$scope.addAssignmentsActionForAllTeams = function () {
    var teamMember = UsersService.user;

    teamMember.studentId = UsersService.getId();

    _this.getTeamRecursiveList(teamMember);

    var alternateTeam = null;
    if ($scope.apiDueDate !== null) {
        alternateTeam = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, $scope.apiDueDate);
    }
    else {
        alternateTeam = TeamService.getAlternateTeamList($scope.startDate.apiDate, $scope.endDate.apiDate, $scope.fullyRecursiveBottom, null);
    }
    alternateTeam.then(function (teamTree) {
        teamTree = teamTree;

        var audienceRoot = {};

        audienceRoot.firstName = teamMember.firstName;
        audienceRoot.lastName = teamMember.lastName;
        audienceRoot.studentId = teamMember.studentId;
        audienceRoot.teamList = teamTree;

        $scope.addAssignmentsWithLibrary.alternateDataList = audienceRoot; // needs to be in array because
        // end result is list of lists

        // add my team (direct) list to audienceData

        // Make all team members hidden by default
        $scope.setPropertyOnAllNodes($scope.addAssignmentsWithLibrary.alternateDataList, 'hidden', true);

        // uncheck all team members by default
        $scope.setPropertyOnAllNodes($scope.addAssignmentsWithLibrary.alternateDataList, 'checked', false);
    }, function (failure) {
        // @TODO do something if we can't load the modal
        console.log(failure);
    });

    $scope.stepThreeView = 'app/templates/users/home/modals/addAssignmentViews/step3.audience.fullTeam.library.html';

    jQuery('#addAssignmentsModal').modal();

    $scope.setDefaultAssignmentType();
};

$scope.setDueDateEndTime = function (dateObj) {
    if (typeof dateObj !== 'undefined' && dateObj !== null && typeof dateObj.moment !== 'undefined' && _.isObject(dateObj.moment)) {
        var newdt = dateUtil.setPortalEndTime(dateObj.moment.toDate());
        dateObj = dateUtil.formatDate(newdt);
    } else {
        dateObj = null;
    }
    return dateObj;
};


$scope.mgrUpdateDueDate = function (dateObj, assignment) {
    assignment.dueDate = $scope.getEndDateTimeFromPicker(dateObj);
    $scope.editAssignment(assignment);
};


/**
 * Method for a manager to change the due date for a single individual
 *
 * @param assignment Object selected assigned which will have its due date changed.
 */
$scope.editAssignment = function (assignment) {
    var params = $route.current.params.param.split('/');
    var studentId = params[0];
    var courseId = assignment.assignmentCourseId;

    var finalDate = null;
    if (assignment.dueDate !== null && assignment.dueDate.moment !== null && assignment.dueDate.moment.isValid()) {
        finalDate = assignment.dueDate.moment.toISOString();
    }

    assignment.error.reset();
    assignment.openCalendar = false;
    assignment.changingDueDate = true;

    var change = AssignmentsService.updateDueDate(UsersService.getId(), studentId, courseId, finalDate);
    change.then(function (success) {
        assignment.changingDueDate = false;
    }, function (error) {
        assignment.error.setError(error.status, error.message);
        assignment.changingDueDate = false;
    });
        };

var getTeamRecursiveList = function (teamMember) {
    var audienceRoot = {};

    audienceRoot.firstName = teamMember.firstName;
    audienceRoot.lastName = teamMember.lastName;
    audienceRoot.studentId = teamMember.studentId;
    audienceRoot.hidden = false;

    // Preload the audience tab data
    if ($scope.apiDueDate !== null) {
        TeamService.recursiveTeamList(teamMember.studentId, $scope.startDate.apiDate, $scope.endDate.apiDate, $scope.apiDueDate).then(function (teamTree) {
            audienceRoot.teamList = teamTree;

            $scope.addAssignmentsWithLibrary.audienceData = [audienceRoot]; // needs to be in array because
        }, function () {
            /**
             * If there's a failure, let's at least show one person.
             */
            $scope.addAssignmentsWithLibrary.audienceData = [audienceRoot]; // needs to be in array because
        });
    }
    else {
        TeamService.recursiveTeamList(teamMember.studentId, $scope.startDate.apiDate, $scope.endDate.apiDate, null).then(function (teamTree) {
            audienceRoot.teamList = teamTree;

            $scope.addAssignmentsWithLibrary.audienceData = [audienceRoot]; // needs to be in array because
        }, function () {
            /**
             * If there's a failure, let's at least show one person.
             */
            $scope.addAssignmentsWithLibrary.audienceData = [audienceRoot]; // needs to be in array because
        });
    }
};
$scope.getShortAssignmentAssetTypeName = function (courseTypeName) {
    var courseTypeFullName = null;
    switch (courseTypeName) {
        case 'Web Based Training':
            courseTypeFullName = 'wbt';
            break;
        case 'Instructor Led Training':
            courseTypeFullName = 'ilt';
            break;
        case 'Virtual Classroom':
            courseTypeFullName = 'vle';
            break;
        case 'Tasks':
            courseTypeFullName = 'task';
            break;
        case 'Surveys':
            courseTypeFullName = 'survey';
            break;
        case 'Evaluations':
            courseTypeFullName = 'evaluation';
            break;
        case 'Assessments':
            courseTypeFullName = 'assessment';
            break;
        case 'Course Group':
            courseTypeFullName = 'courseGroup';
            break;
        case 'Course Groups (Certification)':
            courseTypeFullName = 'courseGroupCertification';
            break;
        case 'Course Groups (Curricula)':
            courseTypeFullName = 'courseGroupCurricula';
            break;
        case 'Career Path':
            courseTypeFullName = 'careerPath';
            break;
        case 'Word Document':
            courseTypeFullName = 'word';
            break;
        case 'Excel Spreadsheet':
            courseTypeFullName = 'excel';
            break;
        case 'PDF':
            courseTypeFullName = 'pdf';
            break;
        case 'MP4':
            courseTypeFullName = 'mP4';
            break;
        case 'MP3':
            courseTypeFullName = 'mP3';
            break;
        case 'URL':
            courseTypeFullName = 'url';
            break;
        case 'OTHER':
            courseTypeFullName = 'other';
            break;
        default:
            courseTypeFullName = 'failed to match';
            break;
    }
    return courseTypeFullName;
};
$scope.addAssignmentsWithLibrary.addAllCourses = function (selected) {
    for (i = 0; i < $scope.addAssignmentsWithLibrary.searchResults.length; i++) {
        if ($scope.addAssignmentsWithLibrary.searchResults[i].checked === selected || $scope.addAssignmentsWithLibrary.searchResults[i].checked === undefined){
            $scope.addAssignmentsWithLibrary.searchResults[i].checked = !selected;
            $scope.addAssignmentsWithLibrary.queueAssignment($scope.addAssignmentsWithLibrary.searchResults[i]);
        }
    }
};
$scope.setPropertyOnAllNodes = function (data, name, value) {
    for (var i = 0; i < data.length; i += 1) {
        data[i][name] = value;

        // if there are subnodes,
        // set them to hidden also
        /**
         * This never recurses.
         */
        if (typeof data[i].teamList !== 'undefined' && data[i].teamList.length > 0) {
            $scope.setPropertyOnAllNodes(data[i].teamList, name, value);
        }
    }
            };

            $scope.viewOfferings = function (assignment) {
                if (!assignment.offerings && !assignment.offeringsLoading) {
                    assignment.offeringsLoading = true;
                    assignment.viewOfferings = true;
                    var offerings = CoursesService.getSingleCourseOfferingsOpen(assignment.id);
                    offerings.then(function (success) {

                        assignment.offerings = $scope.getRosterStatus(success);
                        assignment.hasEnrolledOfferings = assignment.offerings.some(function (offering) { return offering.enrolled === true; });
                        assignment.offeringsLoading = false;
                    }, function (error) {
                        assignment.offeringsLoading = false;
                    });
                } else {
                    assignment.viewOfferings = true;
                }
            };

            $scope.hideOfferings = function (assignment) {
                assignment.viewOfferings = false;
            };

            function SetShowOfferingsOnAssignments(path, displayType) {
                //admin view when viewing assignments
                if (path.startsWith('/users/edit/')) {
                    return true;
                }
                //manager view when viewing an individual's assignments
                if (path.startsWith('/users/home/') && displayType !== $scope.addAssignmentsWithLibrary.TYPE_ENUM.TEAM_SELECT) {
                    return true;
                }
                return false;
            }

            $scope.getRosterStatus = function (offerings) {

                withRosterStatus = offerings;
                _.each(withRosterStatus, function (offering) {

                    offering.enrolled = false;
                    offering.checked = false;

                    if (offering.roster.attendingStudents.length > 0) {
                        var attending = offering.roster.attendingStudents;
                        _.each(attending, function (student) {
                            if (student.id === $scope.studentId && student.completionDate === null) { offering.enrolled = true; return false; }
                        });
                    }

                    if (offering.roster.waitlistedStudents.length > 0 && !offering.enrolled) {
                        var waitlist = offering.roster.waitlistedStudents;
                        _.each(waitlist, function (student) {
                            if (student.id === $scope.studentId && student.completionDate === null) { offering.enrolled = true; return false; }
                        });
                    }

                    if (offering.roster.pendingApprovalStudents.length > 0 && !offering.enrolled) {
                        var pending = offering.roster.pendingApprovalStudents;
                        _.each(pending, function (student) {
                            if (student.id === $scope.studentId && student.completionDate === null) { offering.enrolled = true; return false; }
                        });
                    }
                    if (offering.enrolled) { offering.checked = true; }
                });
                return withRosterStatus; 
            };

            $scope.updateSelection = function (selected, assignment) {

                selected.checked = !selected.checked;
                angular.forEach(assignment.offerings, function (offering) {
                    if (selected.id !== offering.id && !offering.enrolled) {
                        offering.checked = false;
                    }
                    else if (selected.id === offering.id && selected.checked) {

                        assignment.selectedOffering = selected.id;

                        if (!assignment.checked) { 
                            assignment.checked = true;
                            $scope.addAssignmentsWithLibrary.queueAssignment(assignment);
                        }
                    }
                    else if (selected.id === offering.id && !selected.checked) {

                        assignment.selectedOffering = null;

                        if (assignment.checked) {
                            assignment.checked = false;
                            $scope.addAssignmentsWithLibrary.queueAssignment(assignment);
                        }
                        
                    }
                });
            };
        }]
    };
}
]);