app.directive("calendar", ['$rootScope', 'UsersService', 'CoursesService', 'AssignmentsService', function ($rootScope, UsersService, CoursesService, AssignmentsService) {
    var templates = {
        day: {
            template: 'app/templates/components/calendar/day.html'
        },
        week: {
            template: 'app/templates/components/calendar/week.html'
        },
        month: {
            template: 'app/templates/components/calendar/month.html'
        }
    };

    return {
        restrict: "E",
        scope : false,
        link: function(scope, element, attrs) {
            scope.isOfferingsCalendar = attrs.type === 'offerings' ? true : false;
            scope.selected = moment();

            scope.day = _createDay(scope.selected.clone(), scope.selected);
            scope.week = scope.selected.clone();
            scope.month = scope.selected.clone();

            scope.view = templates.month.template;
            scope.selectedView = 'month';
            scope.dateInLoop = scope.selected.clone();
            scope.type = 'dueDate';

            if(scope.isOfferingsCalendar === true) {
                scope.type = 'lowestSessionDateForOffering';
            }

            scope.assignmentFilter = {
                isRequired: true
            };

            var start = scope.selected.clone();

            start.date(1);

            _removeTime(start.day(0));

            _buildMonth(scope, start, scope.month);

            scope.select = function (day) {
                scope.day = day;
                scope.selected = day.date;
            };

            scope.next = function () {
                if(scope.selectedView === 'month') {
                    var next = scope.month.clone();

                    _removeTime(next.month(next.month() + 1).date(1));

                    scope.month.month(scope.month.month() + 1);

                    _buildMonth(scope, next, scope.month);
                } else if(scope.selectedView === 'week') {
                    var weekStart = scope.week.clone().startOf('week');

                    _removeTime(weekStart.week(weekStart.week() + 1).startOf('week'));
                    scope.days = [];

                    scope.week = weekStart;

                    var days = _buildWeek(weekStart, scope.month);

                    scope.days = days;
                }
            };

            scope.previous = function () {
                if(scope.selectedView === 'month') {
                    var previous = scope.month.clone();

                    _removeTime(previous.month(previous.month() - 1).date(1));

                    scope.month.month(scope.month.month() - 1);

                    _buildMonth(scope, previous, scope.month);
                }  else if(scope.selectedView === 'week') {
                    var weekStart = scope.week.clone().startOf('week');

                    _removeTime(weekStart.week(weekStart.week() - 1).startOf('week'));
                    scope.days = [];

                    scope.week = weekStart;

                    var days = _buildWeek(weekStart, scope.month);

                    scope.days = days;
                }
            };

            scope.filterCourseByDay = function(day) {
                return function(input) {
                    return typeof input[scope.type] !== 'undefined' && input[scope.type] !== null &&
                        input[scope.type].day === day.number && input[scope.type].monthForCalender === day.month && input[scope.type].year === day.year;
                };
            };

            scope.filterOfferingByDay = function(day) {
                return function(input) {
                    return typeof input[scope.type] !== 'undefined' &&
                        input[scope.type] !== null &&
                        input[scope.type].day === day.number &&
                        input[scope.type].monthForCalender === day.month &&
                        input[scope.type].year === day.year;
                };
            };

            scope.dayView = function(day) {
                if(jQuery('#calendarAssignmentsList').length > 0) {
                    jQuery('#calendarAssignmentsList').hide();

                    jQuery('#courseCalendarHolder').css('width', '100%');
                    jQuery('#courseCalendarAssignmentHolder').css('width', '0%');
                }

                day = typeof day !== 'undefined' ? day : scope.day;

                scope.day = day;

                scope.curDay = day.date.format('dddd L');// + ' the, ' + day.format('Do') + ' of ' + day.format('MMMM, YYYY');

                scope.view = templates.day.template;
                scope.selectedView = 'day';
            };

            scope.weekView = function() {
                if(jQuery('#calendarAssignmentsList').length > 0) {
                    jQuery('#calendarAssignmentsList').hide();

                    jQuery('#courseCalendarHolder').css('width', '60%');
                    jQuery('#courseCalendarAssignmentHolder').css('width', '40%');
                }

                var weekStart = scope.week = scope.selected.clone().startOf('week');

                scope.days = [];

                var days = _buildWeek(weekStart, scope.month);

                scope.days = days;

                scope.view = templates.week.template;

                scope.selectedView = 'week';
            };

            scope.monthView = function() {
                if(jQuery('#calendarAssignmentsList').length > 0) {
                    jQuery('#calendarAssignmentsList').hide();

                    jQuery('#courseCalendarHolder').css('width', '60%');
                    jQuery('#courseCalendarAssignmentHolder').css('width', '40%');
                }

                start = scope.month.clone();

                start.date(1);

                _removeTime(start.day(0));

                _buildMonth(scope, start, scope.month);

                scope.view = templates.month.template;

                scope.selectedView = 'month';
            };

            scope.getCompletedAssignments = function(item) {
                return item.completedDate !== null;
            };

            scope.getRequiredAssignments = function(item) {
                return item.completedDate === null && item.isRequired === true && (item.status === 'new' || item.status === 'inProgress');
            };

            scope.getNewestAssignments = function(item) {
                return item.completedDate === null && item.status === 'new';
            };

            scope.getMyPicks = function(item) {
                return item.isUserAssigned === true && (item.status === 'new' || item.status === 'inProgress');
            };

            scope.scheduleOffering = function (assignmentId, assignmentType, sessionId) {

                scope.toScheduleAssignmentId = assignmentId;
                scope.toScheduleAssignmentType = assignmentType;
                scope.toScheduleSessionId = sessionId;

                // call Prereq api to see if any are there prereqs that the user needs to take before launching this course
                // if any are there, open modal and don't launch the course

                var prereqCall = AssignmentsService.getPrereqs(assignmentId);

                prereqCall.then(function (success) {

                    if (success.length === 0) {
                        //if nothing, do nothing, move on, schedule
                        scheduleOffering(scope.toScheduleAssignmentId, scope.toScheduleAssignmentType, scope.toScheduleSessionId);
                    } else {
                        //If there are prereqs - set the scoped variable for the prereq details             
                        scope.prereqModalData = success;
                        angular.element(document.querySelector('#preReqModal')).modal();
                    }

                }, function (failure) {
                    //if failure, the schedule api will catch that there is a prereq and show a failure message
                    scheduleOffering(scope.toScheduleAssignmentId, scope.toScheduleAssignmentType, scope.toScheduleSessionId);

                });

            };

            var scheduleOffering = function (assignmentId, assignmentType, sessionId) {

                var offering = CoursesService.scheduleOffering(assignmentId, UsersService.getId(), assignmentType, sessionId);

                offering.then(function(success) {
                    var obj = null;

                    jQuery('#offeringSlide').slideUp();

                    switch(success) {
                        case 'confirmed':
                        case 'inProgress':
                            obj = jQuery('#fullSuccess');

                            scope.apiMessage = 'Registration for this course was successful.';

                            break;
                        case 'waitlisted':
                            obj = jQuery('#waitlistedSuccess');

                            scope.apiMessage = 'The current course is full. You have been added to the course waitlist.';

                            break;
                        case 'pendingApproval':
                            obj = jQuery('#fullSuccess');

                            scope.apiMessage = 'Request for approval was successfully sent.';
                            break;
                    }

                    obj.slideDown(500, function () {
                        var _this = jQuery(this);

                        setTimeout(function () {
                            _this.slideUp(500);

                            scope.apiActionError = false;

                            $rootScope.$broadcast('slideDownDirective:refreshCurrentPage');
                        }, 1500);
                    });
                }, function (failure) {

                  
                    jQuery('#offeringSlide').slideUp();

                    if (failure.ErrorMessage.indexOf('Prereq') > -1) {
                        scope.apiErrorMessage = 'Prerequisites need to be completed for registering for this course. Registration could not be completed.';
                    }
                    else if (failure.ErrorMessage.indexOf('ILT because it is full') > -1) {
                        scope.apiErrorMessage = 'The current course is full. Registration could not be completed.';
                    }
                    else if (failure.StatusCode === 409) { scope.apiErrorMessage = 'Sorry! Cannot register because there is a scheduling conflict.'; }
                    else {
                        scope.apiErrorMessage = 'Sorry! There was a problem saving your offering. Please try again.';
                    }

                    scope.apiActionError = true;

                    jQuery('#underButtonError').slideDown(500, function () {
                        var _this = jQuery(this);

                        setTimeout(function () {
                            _this.slideUp(500);

                            scope.apiActionError = false;
                        }, 2500);
                    });
                    
                });
            };

            scope.requestApproval = function (courseId, type, offeringId) {
                jQuery('.showLoader').toggle();
                jQuery('.managerApprovalRequired-' + courseId).toggle();
                var userId = UsersService.getId();

                var approval = CoursesService.requestManagerialApproval(courseId, type, userId, offeringId);

                approval.then(function (success) {
                    jQuery('.showLoader').toggle();
                    jQuery('#offeringSlide').slideUp();

                    scope.apiMessage = 'Request for approval was successfully sent.';

                    jQuery('#fullSuccess').slideDown(500, function () {
                        var _this = jQuery(this);

                        setTimeout(function () {
                            _this.slideUp(500);

                            scope.apiActionError = false;

                            $rootScope.$broadcast('slideDownDirective:refreshCurrentPage');
                        }, 1500);
                    });
                }, function (failure) {
                    jQuery('.showLoader').toggle();
                    jQuery('.managerApprovalRequired-' + courseId).toggle();
                    if (failure.StatusCode === 409) { scope.apiErrorMessage = 'Sorry! Cannot register because there is a scheduling conflict.'; }
                    else {
                        scope.apiErrorMessage = 'Sorry! There was a problem requesting approval. Please try again.';
                    }

                    scope.apiActionError = true;

                    jQuery('#underButtonError').slideDown(500, function () {
                        var _this = jQuery(this);

                        setTimeout(function () {
                            _this.slideUp(500);

                            scope.apiActionError = false;
                        }, 2500);
                    });
                });
            };

            scope.completeAssignment = function(id, $event) {
                var completion = CoursesService.completeTask(id, UsersService.getId());

                scope.apiActionLoader = true;

                completion.then(function(success) {
                    jQuery($event.currentTarget).html('Task Completed!');

                    setTimeout(function() {
                        scope.apiActionError = false;
                        scope.apiActionLoader = false;

                        $rootScope.$broadcast('slideDownDirective:refreshCurrentPage');
                    }, 1000);
                }, function(failure) {
                    scope.apiActionError = true;
                    scope.apiActionLoader = false;

                    scope.apiErrorMessage = 'We\'re sorry! We couldn\'t complete the course. Please try again.';

                    setTimeout(function() {
                        scope.apiActionError = false;
                    }, 1000);
                });
            };


            scope.$on("assignmentFilter", function (event, args) {
                scope.assignmentFilter = args;
            });

        }
    };

    function _removeTime(date) {
        return date.day(0).hour(0).minute(0).second(0).millisecond(0);
    }

    function _buildMonth(scope, start, month) {
        scope.weeks = [];
        var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
        while (!done) {
            scope.weeks.push({days: _buildWeek(date.clone(), month)});
            date.add(1, "w");

            // done IFF there are at least 2 weeks and the month as incremented
            done = count++ > 2 && monthIndex !== date.month();

            // set month to the month of the date at current week interval;
            monthIndex = date.month();
        }
    }

    function _buildWeek(date, month) {
        var days = [];
        for (var i = 0; i < 7; i++) {
            days.push({
                name: date.format("dd").substring(0, 1),
                number: date.date(),
                isCurrentMonth: date.month() === month.month(),
                month: date.month(),
                isToday: date.isSame(new Date(), "day"),
                date: date,
                year: date.year()
            });
            date = date.clone();
            date.add(1, "d");
        }

        return days;
    }

    function _createDay(date, month) {
        var day = {
            name: date.format("dd").substring(0, 1),
            number: date.date(),
            isCurrentMonth: date.month() === month.month(),
            month: date.month(),
            isToday: date.isSame(new Date(), "day"),
            date: date,
            year: date.year()
        };

        return day;
    }
}]);
