app.controller('insightsController', ['$route', '$scope', 'RoleMapsMixin', 'UsersService', function ($route, $scope, RoleMapsMixin, UsersService) {
    var currentMethod = $route.current.params.name;

    UsersService.setHomeRole();
    /**** Load role specific functionality ****/
    /* This function takes the scope object
     * and overrides anything specific to the current page
     * or role.
     */
    RoleMapsMixin.overrideScope($scope);

    /* Call route action like homeAction here.
     * Return 404 if action doesn't exist after
     * injecting mixin. For example, if a certain role
     * doesn't have an ILT calendar, they will get a 404
     * Not every role needs every page.
     * Please follow the routing structure; non-alphanumeric characters should not be used.
     */
    if ($scope[currentMethod + 'Action']) {
        $scope[currentMethod + 'Action']();
    }
}]);
