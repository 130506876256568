app.service('RulesService', ['$http', '$q', 'dateUtil', function($http, $q, dateUtil) {
    var _this = this;

    _this.response = null;

    /**
     * Format the response; mainly to format all dates to UI.
     */
    _this.format = function(response) {
        var dates = ['endDate', 'startDate', 'lastProcessedOn', 'lastUpdatedOn', 'dueDateFixed', 'acceptPassingScoreDate', 'assignOnDate' ];

        var current = null;
        var node;

        if (response.length > 0) {
            for (var rule in response) {
                current = response[rule];

                for (node in current) {
                    if (typeof current[node] !== 'undefined' && current[node] !== null && $.inArray(node, dates) !== -1) {
                        current[node] = dateUtil.formatDate(current[node]);
                    }
                }
            }
        } else {
            for (node in response) {
                if (typeof response[node] !== 'undefined' && response[node] !== null && $.inArray(node, dates) !== -1) {
                    response[node] = dateUtil.formatDate(response[node]);
                }
            }
        }

        return response;
    };

    _this.formatToSend = function(data) {
        var dates = ['endDate', 'startDate', 'displayStart', 'displayEnd', 'newStartDate', 'newEndDate', 'lastProcessedOn', 'lastUpdatedOn', 'acceptPassingScoreDate', 'dueDateFixed', 'assignOnDate'];

        if (data.constructor !== Array) {
            for (var node in data) {
                if (typeof data[node] !== 'undefined' && data[node] !== null && _.isObject(data[node]) && $.inArray(node, dates) !== -1) {
                    if (typeof data[node].moment !== "undefined" && _.isObject(data[node].moment)) {
                        data[node] = data[node].moment.toISOString();
                    } else {
                        data[node] = null;
                    }
                } 
            }
        }

        if (data.hasOwnProperty('course') && data.course.constructor === Object ) {
            _this.formatToSend(data.course);
        }

        if (data.constructor === Array) {
            _.each(data, function (dataPoint) {
                //SLY-3935 Check if end date is before the start date, if so, add a year to the start date and use that as the end date
                if (typeof dataPoint.newEndDate !== 'undefined' && dataPoint.newEndDate !== null) {
                    var newStartD = angular.copy(dataPoint.newStartDate.moment);
                    var newEndD = angular.copy(dataPoint.newEndDate.moment);
                    if (newStartD.isAfter(newEndD)) {
                        dataPoint.newEndDate = dateUtil.formatDate(dateUtil.setPortalEndTime(newStartD.add(1, 'year').toDate()));
                    }
                }
                _this.formatToSend(dataPoint);
            });
        }

        return data;
    };

    _this.formatSingleRule = function(rule) {
        if (rule.afterDueNotificationDays !== 'undefined' && rule.afterDueNotificationDays !== null && rule.beforeDueNotificationDays !== 'undefined' && rule.beforeDueNotificationDays !== null) {
            return rule;
        } else {
            rule.afterDueNotificationDays = [];
            rule.beforeDueNotificationDays = [];
        }

        return rule;
    };

    _this.formatCourseObj = function(data) {
        var courseData = data;
        var newCoursesArr = [];
        _.each(courseData, function (course) {
            var ruleCourseObj = {};
            ruleCourseObj.ruleCourseId = "";
            if (course.type === "careerPath") {
                ruleCourseObj.required = false;
            } else {
                ruleCourseObj.required = true;
            }
            ruleCourseObj.dueDateFixed = null;
            ruleCourseObj.acceptPassingScoreDate = null;
            ruleCourseObj.dueDateDaysFromKey = "";
            ruleCourseObj.dueDateDaysFromValue = "";
            ruleCourseObj.recordUserId = "";
            ruleCourseObj.foriegnSystemId = "";
            ruleCourseObj.recurrence = "";
            ruleCourseObj.course = course;

            newCoursesArr.push(ruleCourseObj);

        });

        return _this.format(newCoursesArr);
    };

    _this.formatRuleForCopy = function (data) {
        var output_arr = [];

        _.each(data, function (dataPoint) {
            var startDateCopy = angular.copy(dataPoint.startDate);
            var endDateCopy = angular.copy(dataPoint.endDate);
            var newRuleObj = {};
            newRuleObj.id = dataPoint.id;
            newRuleObj.name = dataPoint.name;
            newRuleObj.newName = dataPoint.name + '_copy';
            newRuleObj.startDate = dataPoint.startDate;
            newRuleObj.endDate = dataPoint.endDate;
            newRuleObj.newStartDate = dateUtil.formatDate(dateUtil.setPortalStartTime(startDateCopy.moment.add(1, 'year').toDate()));
            if (endDateCopy !== null) {
                newRuleObj.newEndDate = dateUtil.formatDate(dateUtil.setPortalEndTime(endDateCopy.moment.add(1, 'year').toDate()));
            }
            else {
                newRuleObj.newEndDate = dataPoint.endDate;
            }
            newRuleObj.isAddYear = null;

            output_arr.push(newRuleObj);
        });

        return output_arr;
    };

    _this.getAllRules = function() {
        var req = {
            method: 'GET',
            url: 'api/rules/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getSingleRule = function(id) {
        var req = {
            method: 'GET',
            url: 'api/rules/' + id,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            _this.rule = _this.formatSingleRule(_this.response);

            return _this.rule;
        }, function(failure) {
            throw failure;
        });
    };

    _this.ruleGeneralCall = function(data, status) {

        var method;
        if (status !== 'undefined' && status !== null && status === 'create') {
            method = 'POST';
        } else if (status !== 'undefined' && status !== null && status === 'update') {
            method = 'PUT';
        }

        var req = {
            method: method,
            url: 'api/rules/',
            data: _this.formatToSend(data),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.format_courseDates = function(data) {
        var courses = data;
        _.each(courses, function(course) {
            if (course.dueDateDaysFromValue === null) {
                course.flagDueDateMethod = 0;
            } else if (course.dueDateFixed === null) {
                course.flagDueDateMethod = 1;
            }
        });

        return courses;
    };

    _this.getRuleAssignments = function(ruleId) {

        var req = {
            method: 'GET',
            url: 'api/rules/' + ruleId + '/courses',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format_courseDates(_this.format(success.data));

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getRuleCounts = function(ruleId) {

        var req = {
            method: 'GET',
            url: 'api/rules/' + ruleId + '/counts',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = success.data;

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.updateRuleAssignments = function(ruleId, Courses) {

        var req = {
            method: 'PUT',
            url: 'api/rules/' + ruleId + '/courses',
            data: _this.formatToSend(Courses),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.saveCopiedRule = function (data) {
        var req = {
            method: 'PUT',
            url: 'api/rules/copy',
            data: _this.formatToSend(data),
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        console.warn(req.data);

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);
            return _this.response;
        }, function(failure) { 
            throw failure;
        });      
    };

    _this.getRuleStatusOptions = function(isSuccessful) {

        var deferred = $q.defer();

        var statusArr = {
            "data": [{
                "id": 0,
                "name": "draft"
            }, {
                "id": 1,
                "name": "active"
            }, {
                "id": 2,
                "name": "closed"
            }]
        };

        if (isSuccessful === true) {
            deferred.resolve(statusArr.data);
        } else {
            deferred.reject("No Object available!!");
        }

        return deferred.promise;
    };

    _this.createRuleObj = function(isSuccessful) {

        var deferred = $q.defer();

        var ruleObj = {
            "data": {
                "id": null,
                "name": "",
                "description": "",
                "startDate": null,
                "endDate": null,
                "reoccurance": true,
                "groupId": null,
                "lastUpdatedByUser": "",
                "lastUpdatedByUserId": "",
                "beforeDueNotificationDays": [],
                "afterDueNotificationDays": [],
                "lastProcessedOn": "",
                "lastUpdatedOn": "",
                "status": ""
            }
        };


        if (isSuccessful === true) {
            deferred.resolve(ruleObj.data);
        } else {
            deferred.reject("No Object available!!");
        }

        return deferred.promise;
    };


    _this.getRuleHistoryByStep = function (ruleId, ruleStep, page, pageSize) {

        var req = {
            method: 'GET',
            url: 'api/rules/' + ruleId + '/' + ruleStep + '/' + page + '/' + pageSize,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _.each(success.data, function (s) {
                if (s.updated !== 'undefined') {
                    s.updated = dateUtil.formatDate(s.updated);
                }
                _.each(s.oldVNew, function (h) {
                    if (h.oldDateTimeValue !== null) {
                        h.oldDateTimeValue = dateUtil.formatDate(h.oldDateTimeValue);
                    }
                    if (h.newDateTimeValue !== null) {
                        h.newDateTimeValue = dateUtil.formatDate(h.newDateTimeValue);
                    }
                });  
            });
            return success.data;

        }, function (failure) {
            throw failure;
        });
    };

}]);
