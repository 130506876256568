(function () {
    catalog.controller('baiCatalogHeaderController', ['$scope', '$rootScope', '$location', '$timeout', '$q', 'CatalogSearchParameters', 'SearchServices', 'UsersService', 'SettingsService', baiCatalogHeaderController]);

    function baiCatalogHeaderController($scope, $rootScope, $location, $timeout, $q, CatalogSearchParameters, SearchServices, UsersService, SettingsService) {

        var _this = this;
        $scope.showDocuments = false;
        $scope.isSettingsLoading = true;
        $scope.fromWeb = $rootScope.fromWeb;

        var documentPromises = [];
        documentPromises.push(SettingsService.getSettings());
        documentPromises.push(SearchServices.getDocumentCount());

        if (!$scope.fromWeb) {
            $q.all(documentPromises).then(function (success) {
                $scope.isSettingsLoading = false;
                var isDocumentsEnabled = SettingsService.isDocumentsEnabled();
                var documentCount = success[1];
                if (isDocumentsEnabled === true && documentCount > 0) {
                    $scope.showDocuments = true;
                }
            }, function (failure) {
                $scope.isSettingsLoading = false;
            });
        }

        $scope.synonyms = [];
        $scope.showSuggestions = false;
        $scope.showLDConnect = false;
        if ($scope.fromWeb) {
            $scope.showLDConnect = false;
        } else {
            $scope.showLDConnect = UsersService.hasAdminRole();
        }

        _this.synonymsMap = [];
        _this.minLength = 3;
        _this.currentFocus = -1;
        _this.delayedSuggestions = null;
        _this.skipDiv = ["synonyms-divider", "suggestions-header", "courses-header"];

        $scope.executeSearch = function () {
            if ($scope.queryText.length < _this.minLength) {
                return;
            }
            if ($scope.fromWeb === false) {
                var context = UsersService.setUserContext('student');
                context.then(function () {
                    CatalogSearchParameters.clearBreadcrumbs();
                    CatalogSearchParameters.fromBrowse = false;
                    CatalogSearchParameters.browseFilter = {};
                    CatalogSearchParameters.addQueryText($scope.queryText);
                    $location.url('catalog/search/?query=' + $scope.queryText);
                });
            } else {
                CatalogSearchParameters.clearBreadcrumbs();
                CatalogSearchParameters.breadcrumbs.push({ name: "Home", link: "/" });
                CatalogSearchParameters.breadcrumbs.push({ name: "search", link: "" });
                CatalogSearchParameters.fromBrowse = false;
                CatalogSearchParameters.browseFilter = {};
                CatalogSearchParameters.addQueryText($scope.queryText);
                $location.path('search');
            }
        };

        $scope.queryText = "";
        if (window.query) {
            if (!window.location.pathname.includes("/courses/certificate/assignments")) {
                $scope.queryText = window.query;
                $scope.executeSearch();
            }
            delete window.query;
        }
        $scope.queryTextOriginal = $scope.queryText;

        _this.getSynonymsMap = function () {
            var map = SearchServices.getSynonymsMap();
            map.then(function (success) {
                _this.synonymsMap = success;
            }, function (error) {
                console.log(error);
            });
        };
        _this.getSynonymsMap();

        _this.getSuggestions = function () {
            _this.closeAllLists();

            if (typeof $scope.queryText === 'undefined') {
                return;
            }

            $scope.queryTextOriginal = $scope.queryText;
            //special handling for 2 character synonyms
            if ($scope.queryText.length === 2) {
                $scope.synonyms = _this.getSynonyms($scope.queryText);
                $scope.showSuggestions = $scope.synonyms.length > 0;
                return;
            } else if ($scope.queryText.length < _this.minLength) {
                return;
            }

            var suggestions = SearchServices.getSuggestions($scope.queryText);
            suggestions.then(function (success) {
                _this.delayedSuggestions = null;
                $scope.products = success;
                $scope.synonyms = _this.getSynonyms($scope.queryText);
                $scope.showSuggestions = $scope.products.length > 0 || $scope.synonyms.length > 0;
            }, function (error) {
                _this.delayedSuggestions = null;
                console.log(error);
            });
        };

        _this.getSynonyms = function (term) {
            var valLower = term.toLowerCase().trim();
            if (typeof _this.synonymsMap[valLower] !== 'undefined') {
                return _this.synonymsMap[valLower].synonyms;
            }
            else return [];
        };

        $scope.delaySuggestions = function () {

            if (_this.delayedSuggestions !== null) {
                $timeout.cancel(_this.delayedSuggestions);
            }

            _this.delayedSuggestions = _this.delay();
        };

        _this.delay = function () {
            return $timeout(function () {
                return _this.getSuggestions();
            }, 500);
        };

        $scope.handleKeys = function (e) {
            //escape will close the dropdown and revert to original text
            if (e.keyCode === 27) {
                _this.handleEscape(e, x);
                return;
            }

            var x = document.getElementById("autocomplete-list");

            if (x === null) {
                if (e.keyCode === 13) {
                    //enter and no suggestions open
                    _this.handleEnterOnInput(e, x);
                    return;
                }
            }

            if (x) {
                x = x.getElementsByClassName("suggestion-row");
            }

            if (e.keyCode === 40) {
                //arrow down
                _this.handleArrowDown(e, x);

            } else if (e.keyCode === 38) {
                //up arrow
                _this.handleArrowUp(e, x);
            } else if (e.keyCode === 13) {
                //enter
                _this.handleEnterOnSuggestion(e, x);
            }
        };

        $scope.handleMouseOver = function (e) {
            $scope.removeActive();
            $scope.resetCurrentFocus();
        };

        $scope.clickSynonym = function (e, text) {
            $scope.removeActive();
            _this.closeAllLists();
            $scope.queryText = text;
            _this.getSuggestions();
        };

        $scope.clickProduct = function (e, product) {
            $scope.removeActive();
            _this.closeAllLists();
            $scope.queryText = product.name;
            if ($scope.fromWeb) {
                $location.url(product.detailsUri);
            }
            else {
                var context = UsersService.setUserContext('student');
                context.then(function () {
                    $location.url(product.detailsUri);
                });
            }
        };

        $scope.clickSeeAll = function (e, text) {
            $scope.removeActive();
            _this.closeAllLists();
            $scope.queryText = text;
            $scope.executeSearch();
        };

        $scope.addActive = function (x) {
            if (!x) return false;
            $scope.removeActive(x);
            x[_this.currentFocus].classList.add("autocomplete-active");
        };

        $scope.removeActive = function (x) {
            if (typeof x === 'undefined') {
                x = document.getElementById("autocomplete-list").getElementsByTagName("div");
            }
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        };

        $scope.resetCurrentFocus = function () {
            _this.currentFocus = -1;
        };

        _this.closeAllLists = function () {
            $scope.resetCurrentFocus();
            $scope.queryTextOriginal = "";
            $scope.showSuggestions = false;
            $scope.products = [];
            $scope.synonyms = [];
        };

        _this.skipThis = function (divId) {
            if (typeof divId === 'undefined') {
                return false;
            }

            var skip = false;
            for (var i = 0; i < _this.skipDiv.length; i++) {
                if (_this.skipDiv[i] === divId) {
                    return true;
                }
            }
            return skip;
        };

        //close when clicking outside the suggestions drop-down
        window.onclick = function (e) {
            var SUGGESTION_ROW = '.suggestion-row';
            var targetIsSuggestion;
            if (e.target) {
                if (e.target.matches) {
                    targetIsSuggestion = e.target.matches(SUGGESTION_ROW);
                } else if (e.target.msMatchesSelector) {
                    targetIsSuggestion = e.target.msMatchesSelector(SUGGESTION_ROW);
                }
            }
            var parentIsSuggestion;
            if (e.target.parentNode) {
                if (e.target.parentNode.matches) {
                    parentIsSuggestion = e.target.parentNode.matches(SUGGESTION_ROW);
                } else if (e.target.parentNode.msMatchesSelector) {
                    parentIsSuggestion = e.target.parentNode.msMatchesSelector(SUGGESTION_ROW);
                }
            }
            if (!targetIsSuggestion && !parentIsSuggestion) {
                $scope.showSuggestions = false;
                $scope.$digest();
            }
        };

        _this.handleArrowDown = function (e, x) {
            if (x === null) {
                if ($scope.queryText.length >= _this.minLength) {
                    _this.getSuggestions();
                }
                else if ($scope.queryText.length === 2) {
                    $scope.queryTextOriginal = $scope.queryText;
                    $scope.synonyms = _this.getSynonyms($scope.queryText);
                    $scope.showSuggestions = $scope.synonyms.length > 0;
                }
                return;
            }

            e.preventDefault();
            _this.currentFocus++;

            if (x !== null && typeof x[_this.currentFocus] !== 'undefined')
                while (_this.skipThis(x[_this.currentFocus].id)) {
                    _this.currentFocus++;
                }
            if (_this.currentFocus < x.length) {
                $scope.addActive(x);
                if (x) {
                    $scope.queryText = x[_this.currentFocus].getAttribute("term");
                }
            }
            else {
                _this.currentFocus--;
            }
        };

        _this.handleArrowUp = function (e, x) {
            if (x === null || _this.currentFocus === -1) {
                return;
            }

            e.preventDefault();
            _this.currentFocus--;

            while (_this.skipThis(x[_this.currentFocus].id) && _this.currentFocus > 0) {
                _this.currentFocus--;
            }

            if (_this.currentFocus === 0) {
                $scope.queryText = $scope.queryTextOriginal;
                _this.closeAllLists();
            }
            else {
                $scope.addActive(x);
                $scope.queryText = x[_this.currentFocus].getAttribute("term");
            }
        };

        _this.handleEnterOnSuggestion = function (e, x) {
            e.preventDefault();
            if (x) {
                var productId = x[_this.currentFocus].getAttribute("productId");
                var detailsUri = x[_this.currentFocus].getAttribute("detailsUri");

                if (productId !== null) {
                    $scope.removeActive();
                    _this.closeAllLists();
                    if ($scope.fromWeb) {
                        $location.url(detailsUri);
                    }
                    else {
                        var context = UsersService.setUserContext('student');
                        context.then(function () {
                            $location.url(detailsUri);
                        });
                    }

                } else if (_this.currentFocus > -1) {
                    $scope.queryText = x[_this.currentFocus].getAttribute("term");
                    _this.closeAllLists();
                    $scope.executeSearch();
                }
            }
        };

        _this.handleEscape = function (e, x) {
            var queryText = $scope.queryTextOriginal === "" ? $scope.queryText : $scope.queryTextOriginal;
            _this.closeAllLists();
            $scope.queryText = queryText;
        };
        _this.handleEnterOnInput = function (e, x) {
            e.preventDefault();
            if ($scope.queryText.length >= _this.minLength) {
                $scope.executeSearch();
            }
        };

        $scope.openLibrary = function () {
            var context = UsersService.setUserContext('student');
            context.then(function () {
                $location.url('catalog');
            });
        };

        $scope.openDocumentsLibrary = function () {
            var context = UsersService.setUserContext('student');
            context.then(function () {
                $location.url('documents-catalog');
            });
        };

        $scope.openIltCalendar = function () {
            var context = UsersService.setUserContext('student');
            context.then(function () {
                $location.url('users/ilt-calendar');
            });
        };
    }
})();