app.service('EvaluationService', ['$http', '$q', function ($http, $q) {
    var _this = this;

    _this.singleResponse = null;

    _this.fetchSingle = function (userId, evaluationId) {

        var url = 'api/user/' + userId + '/evaluation/' + evaluationId + '/';

        var req = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.singleResponse = success.data;

            return _this.singleResponse;
        }, function (failure) {
            throw failure;
        });
    };
}]);
