(function () {
    catalog.controller("catalogController", ["$scope", "$route", catalogController]);

    function catalogController($scope, $route) {
        console.log($route);
        var controllerRoute = $route.current.params.controller;
        $scope.homeTemplate = "app/templates/"+ controllerRoute+ "/home.html";
        if ($route.current.params.name === "search") {
            $scope.homeTemplate = "app/templates/"+ controllerRoute+ "/search.html";
        }
        else if ($route.current.params.name === "product") {
            $scope.homeTemplate = "app/templates/"+ controllerRoute+ "/productDetails.html";
        }
        else if ($route.current.params.controller === "documents-catalog") {
            $scope.homeTemplate = "app/templates/documents-catalog/home.html";
        }
    }
})();