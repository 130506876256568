(function() {
    app.factory('IltRosterUtilities', [IltRosterUtilities]);

    function IltRosterUtilities() {
        // Public API
        return {
            isUserRegisteredForOffering: isUserRegisteredForOffering
        };

        function isUserRegisteredForOffering(offering, userId) {
            return getStatusForOfferingFromRoster(offering, userId) !== 'notRegistered';
        }

        function getStatusForOfferingFromRoster(offering, userId) {
            var defaultStatus = 'notRegistered';
            var loggedinUser = userId;
            //The roster now returns the total counts for attending and waitlisted students
            //If the logged in person is in the roster, their userid will be populated in the roster list
            //loop through the attending/waitlist/pendingApproval students and if a userID is not a dummy ie. 0
            //then return the roster status
            var attendingRoster = offering.roster.attendingStudents;
            for (var attend in attendingRoster) {
                var atten = attendingRoster[attend];
                if (typeof atten.id !== 'undefined' && atten.id !== 0 && loggedinUser === atten.id)
                { return atten.rosterStatus; }
            }

            var waitlistRoster = offering.roster.waitlistStudents;
            for (var waitL in waitlistRoster) {
                var wait = waitlistRoster[waitL];
                if (typeof wait.id !== 'undefined' && wait.id !== 0 && loggedinUser === wait.id)
                { return wait.rosterStatus; }
            }

            var pendingRoster = offering.roster.pendingApprovalStudents;
            for (var pender in pendingRoster) {
                var pend = pendingRoster[pender];
                if (typeof pend.id !== 'undefined' && pend.id !== 0 && loggedinUser === pend.id)
                { return pend.rosterStatus; }
            }

            return defaultStatus;
        }
    }
})();