app.service('CareerPathService', [function () {

    var _this = this;

    _this.createSummary = function (careerPath, children) {
        var summary = {};
        summary.courses = {};
        summary.skills = {};
        summary.skills.total = 0;

        summary.courses.total = children.length;
        summary.courses.completed = _.where(children, { status: "successful" }).length;
        summary.courses.percentComplete = Math.round(summary.courses.completed / summary.courses.total * 100);

        var skills = [];
        children.forEach(function (child) {
            child.skills.forEach(function (skill) {

                if (child.status === 'successful') {
                    skill.acquired = true;
                } else {
                    skill.acquired = false;
                }

                var existingSkill = _.findWhere(skills, { id: skill.id });
                if (existingSkill === undefined) {
                    skills.push(skill);
                }
                else {
                    if (skill.acquired === true) {
                        existingSkill.acquired = true;
                    }
                }
            });
        });

        summary.skills.total = skills.length;
        summary.skills.completed = _.where(skills, { acquired: true }).length;

        return summary;
    };

}]);