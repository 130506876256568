app.service('JobsService', ['$http', '$httpParamSerializer', function($http, $httpParamSerializer) {
    /* Jobs come back in this format.
     [ { "id": 200000471, "code": "*Business Developmen", "name": "*Business Development <<000046484O>>", "groupId": 0 }, ... ]
     */
    var _this = this;

    _this.getAllJobs = function() {
        var req = {
            method: 'GET',
            url: 'api/jobs/?=',
            timeout: window.timeout
        };

        return $http(req).then(function(jobsList) {
            //return _this.format(jobsList.data);
            return (jobsList.data);
        }, function(error) {
            return error;
        });
    };

    // See getAllJobs for format of jobs
    _this.searchJobs = function(query) {
        query = $httpParamSerializer(query);
        var req = {
            method: 'GET',
            url: 'api/jobs/?' + query,
            timeout: window.timeout
        };

        return $http(req).then(function(jobsList) {
            return _this.format(jobsList.data);
        }, function(error) {
            return error;
        });
    };

    // See getAllJobs for format of jobs
    _this.updateJob = function(jobObj) {
        var req = {
            method: 'PUT',
            url: 'api/jobs/',
            timeout: window.timeout,
            data: jobObj,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        return $http(req).then(function(success) {
            // Success will be a boolean true if it worked
            return success;
        }, function(error) {
            throw error;
        });
    };

    //Create new Position
    _this.createJob = function (jobObj) {
        var req = {
            method: 'POST',
            url: 'api/jobs/',
            timeout: window.timeout,
            data: jobObj,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        return $http(req).then(function (success) {
            // Success will be a boolean true if it worked
            return success;
        }, function (error) {
            throw error;
        });
    };

    _this.format = function(jobsList) {
        for (var i = 0; i < jobsList.length; i += 1) {
            /* This line adds a nodes attribute
             * to every job in the jobsList retrieved from
             * JobsService.getAllJobs or JobsService.searchJobs
             * Without a nodes attribute, the tree component will fail 
             * to render the jobs tree in the premium admin.
             */
            jobsList[i].nodes = [];

            jobsList[i].lastUpdate = moment(jobsList[i].lastUpdate);
        }
        return jobsList;
    };
}]);
