app.service('CatalogService', ['$http', '$q', 'SettingsService', function ($http, $q, SettingsService) { 
    var _this = this;

    /**** This is all related to BAI Catalog on Assignments Tab - TA Stuff ****/

    _this.getBaiCatalogData = function (refresh) {

        if (refresh === undefined) {
            refresh = false;
        }

        if (_this.formattedDataCatalog.originalFlatArray !== undefined && _this.formattedDataCatalog.originalFlatArray.length > 0 && !refresh) {
            return $q.resolve(_this.formattedDataCatalog);
        } else {

            var req = {
                method: 'GET',
                url: 'api/Catalog/',
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            return $http(req).then(function (success) {
               return _this.format_CatalogData(success.data);
            }, function (failure) {
                throw failure;
            });
        }
    };

    _this.formattedDataCatalog = [];
    _this.format_CatalogData = function(data) {
        _this.newArrayForCatlogData = [];
        _this.formattedDataCatalog.originalFlatArray = [];
        _this.formattedDataCatalog.formattedCatalogTree = [];
        _this.formattedDataCatalog.formattedCatalogTreeData = [];
        _this.formattedDataCatalog.formattedCoursesData = [];
        _this.formattedDataCatalog.originalFlatArray = format_FlattenCatalogData(data);

        format_CatalogCoursesAndTreeData(_this.newArrayForCatlogData);
        format_CatalogTreeData(_this.formattedDataCatalog.formattedCatalogTree);

        return _this.formattedDataCatalog;
    };

    _this.getBaiCatalogDataFromCache = function () {
        if (_this.formattedDataCatalog.originalFlatArray !== undefined && _this.formattedDataCatalog.originalFlatArray.length > 0) {

            return _this.formattedDataCatalog;
        }
    };

    var format_FlattenCatalogData = function(data) {
        _.each(data, function(node) {
            node.name = node.title;
            node.nodes = node.children;
            node.id = node.nodeId;

            delete node.title;
            delete node.children;

            _this.newArrayForCatlogData.push(node);
            if (node.nodes) {
                format_FlattenCatalogData(node.nodes);
            }
        });

        return _this.newArrayForCatlogData; 
    };

    var format_CatalogCoursesAndTreeData = function(data) {
        _.each(data, function(node) {
            node.nodes = [];
            if (node.courseId !== null && node.courseId !== undefined && node.nodes.length === 0) {
                _this.formattedDataCatalog.formattedCoursesData.push(node);
            } else {
                _this.formattedDataCatalog.formattedCatalogTree.push(node);
            }

        });
    };

    var format_CatalogTreeData = function(data) {
      // map is a reference to the current node
      var map = {}, node;

        _.each(data, function(node) {
            map[node.nodeId] = node;
            if (node.parentId !== null) {
                map[node.parentId].nodes.push(node);
            } else {
                _this.formattedDataCatalog.formattedCatalogTreeData.push(node);
            }

        });
    };

    _this.getCustomCatalog = function () {
        var req = {
            method: 'GET',
            url: 'api/course/custom',
            //SLY-3847 New courses not showing up in custom catalog until page is refreshed.
            //cache: true,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return _this.formatCustomCatalog(success.data);
        }, function(failure) {
            throw failure;
        });
    };

    _this.formatCustomCatalog = function(data) {
        var courseTypes = ['wbt', 'ilt', 'vle', 'task', 'survey', 'evaluation', 'courseGroups', 'assessment', 'careerPaths'];
        var courseTypesInData = [];

        if (data !== null) {
            for (var node in data) {
                if (data[node] !== null && data[node] !== 'undefined' && node !== 'courseGroups' && node !== 'careerPaths') {
                    courseTypesInData.push(
                        {
                            'api_name' : node,
                            'nodes': [],
                            'courses': data[node]
                        }
                    );
                }

                if (data[node] !== null && data[node] !== 'undefined' && (node === 'courseGroups' || (node === 'careerPaths' && SettingsService.isCareerPathEnabled()))) {
                    courseTypesInData.push(
                        {
                            'api_name' : node,
                            'courses': data[node]
                        }
                    );
                }
            }
        }

        _.each(courseTypesInData, function(courseTypeObj) {
            var courseTypeName = courseTypeObj.api_name;
            if (courseTypeName !== 'undefined' && courseTypeName !== null && $.inArray(courseTypeName, courseTypes) !== -1) {
                courseTypeObj.name = _this.courseTypeDisplayName(courseTypeName);

                if (courseTypeName === 'courseGroups') {
                    _.each(courseTypeObj.nodes, function (courseSubGroup) {
                        courseSubGroup.courses = courseSubGroup.children;
                        courseSubGroup.api_name = 'courseGroups';
                        delete courseSubGroup.children;
                    });
                }
            } else if (courseTypeName === 'careerPaths') {
                _.each(courseTypeObj.nodes, function (courseSubGroup) {
                    courseSubGroup.courses = courseSubGroup.children;
                    courseSubGroup.api_name = 'careerPaths';
                    delete courseSubGroup.children;
                });
            }
        });

        return courseTypesInData;
    };

    _this.courseTypeDisplayName = function (courseTypeName) {
        var courseTypeFullName;
        if (courseTypeName === 'wbt') {
            courseTypeFullName = 'Web Based Training';
        } else if (courseTypeName === 'ilt') {
            courseTypeFullName = 'Instructor Led Training';
        } else if (courseTypeName === 'vle') {
            courseTypeFullName = 'Virtual Classroom';
        } else if (courseTypeName === 'task') {
            courseTypeFullName = 'Tasks';
        } else if (courseTypeName === 'survey') {
            courseTypeFullName = 'Surveys';
        } else if (courseTypeName === 'evaluation') {
            courseTypeFullName = 'Evaluations';
        } else if (courseTypeName === 'assessment') {
            courseTypeFullName = 'Assessments';
        } else if (courseTypeName === 'courseGroups') {
            courseTypeFullName = 'Course Groups';
        } else if (courseTypeName === 'careerPaths') {
            courseTypeFullName = "Career Paths";
        }

        return courseTypeFullName;
    };

    /* Removes Duplicates from any array based on property.
     */
    _this.removeDuplicates =  function(arr, prop) {
        var new_arr = [];
        var lookup  = {};
     
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }
     
        for (i in lookup) {
            new_arr.push(lookup[i]);
        }
     
        return new_arr;
    };   

    /**** End of BAI Catalog on Assignments Tab - TA Stuff ****/

    // Return a unique, flat list of catalog courses sorted alphabetically by course name.
    _this.getCatalogCourses = function() {
        var catalogPromise = _this.getBaiCatalogData();
        return catalogPromise.then(function(success) {
                success.formattedCoursesData = _.sortBy(success.formattedCoursesData, 'courseId');
                var uniqueCourses = [];
                var lastCourseId = -1;
                _.each(success.formattedCoursesData, function(course) {
                    // The catalog returns the same course under different parents. Make sure the course only exists once in the unrelated course list
                    if (lastCourseId !== course.courseId) {
                        course.id = course.courseId;
                        uniqueCourses.push(course);
                    }
                    lastCourseId = course.courseId;
                });
                uniqueCourses = _.sortBy(uniqueCourses, 'name');
                return uniqueCourses;
            }, function(failure) {
                return failure;
            }
        );
    };

}]);
