app.service('CountriesService', ['$http', 'UsersService', function($http, UsersService) {
    var _this = this;

    _this.getCountryList = function() {
        var req = {
            method: 'GET',
            url: 'app/json/CountriesWithIsoCodes.json',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http.get(req.url).then(function (success) {
            // inject the user's country in the drop-list data if its not already there.
            //console.log(success.data);
            //console.log(success.data.find);
            
            _this.user = UsersService.user;
            var alreadyThere = success.data.find(function (c)

            { return c.value === _this.user.homeAddress.countryName; });
            if (!alreadyThere)
                success.data.push({ key: _this.user.homeAddress.countryName, value: _this.user.homeAddress.countryName });

            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.getLatitudeCountryList = function (structureId) {
        var req = {
            method: 'GET',
            url: 'api/user/countries/' + structureId + '/',
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http.get(req.url).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };
}]);
