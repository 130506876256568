catalog.service('CatalogSearchParameters', [function () {
    var _this = this;

    _this.breadcrumbs = [];
    _this.productBreadcrumbs = [];
    _this.fromBrowse = false;
    _this.browseFilter = {};

    var searchParameters = {
        searchTerm: "",
        filter: "",
        queryType: "",
        top: 15,
        skip: 0
    };

    if (navigator.userAgent.toLowerCase().indexOf("prerender") > -1) {
        searchParameters.top = 1000;
    }

    _this.addQueryText = function (queryText) {
        searchParameters.searchTerm = queryText;
        searchParameters.filter = "";
        searchParameters.queryType = "";
        searchParameters.skip = 0;
    };

    _this.clearBreadcrumbs = function () {
        _this.breadcrumbs = [];
    };

    _this.getSearchParameters = function() {
        return searchParameters;
    };

    _this.clearFilters = function() {
        searchParameters.filter = "";
        searchParameters.queryType = "";
    };

    _this.setPage = function(selectedPage) {
        searchParameters.skip = (selectedPage - 1) * searchParameters.top;
    };

    _this.setFilters = function(f) {
        searchParameters.filter = f;
    };

    _this.addFacetFilters = function (facets) {
        var filterText = "";
        var i = 0;
        var f = 0;
        for (i = 0; i < facets.length; i++) {
            var filterValues = [];
            for (f = 0; f < facets[i].values.length; f++) {
                if (facets[i].values[f].selected === true) {
                    var formattedValue = facets[i].values[f].value.replace(/\'/g, '\\\'\\\'');
                    filterValues.push(formattedValue);
                }
            }
            
            if (filterValues.length > 0) {
                if (filterText === "") {
                    if(facets[i].isString){
                        if (facets[i].negate) 
                        {
                            filterText += _this.addNotString(facets[i], filterValues);
                        }
                        else
                        {
                            filterText += _this.addString(facets[i], filterValues);
                        }
                    }
                    else {
                        filterText += _this.addCollection(facets, i, filterValues);
                    }
                } else {
                    if (facets[i].isString) {
                        if (facets[i].negate) 
                        {
                            filterText += _this.addNotStringAnd(facets[i], filterValues);
                        }
                        else
                        {
                            filterText += _this.addStringAnd(facets[i], filterValues);
                        }
                    }
                    else {
                        filterText += _this.addCollectionAnd(facets, i, filterValues);
                    }
                }
            }
        }
        searchParameters.filter = filterText;
    };

    _this.addString = function (facet, filterValues) {
        return facet.name + " eq '" + filterValues[0] + "'";
    };

    _this.addStringAnd = function (facet, filterValues) {
        return " and " + facet.name + " eq '" + filterValues[0] + "'";
    };

    _this.addNotString = function (facet, filterValues) {
        return facet.name + " ne '" + filterValues[0] + "'";
    };

    _this.addNotStringAnd = function (facet, filterValues) {
        return " and " + facet.name + " ne '" + filterValues[0] + "'";
    };
    _this.addCollection = function (facets, i, filterValues) {
        return facets[i].name + "/any(" + facets[i].name[0] + ": search.in(" + facets[i].name[0] + ", '" + filterValues.join('^') + "', '^'))";
    };

    _this.addCollectionAnd = function (facets, i, filterValues) {
        return " and " + facets[i].name + "/any(" + facets[i].name[0] + ": search.in(" + facets[i].name[0] + ", '" + filterValues.join('^') + "', '^'))";
    };

    _this.getQueryToSend = function () {
        var queryToSend = { search: '""', top: 0, skip: 0 };
        if (!_this.fromBrowse) {
            queryToSend.search = '"\\"' + searchParameters.searchTerm + '\\""';
        }
        queryToSend.top = searchParameters.top;
        queryToSend.skip = searchParameters.skip;
        console.log(queryToSend);
        if (searchParameters.filter !== "") {
            queryToSend.filter = "\""+searchParameters.filter+"\"";
        }
        if (searchParameters.queryType !== "") {
            queryToSend.queryType = "\"full\"";
        }

        return queryToSend;
    };

}]);