(function () {
    app.directive('studentTileContainer', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/studentui/studentTileContainer.html',
                transclude: true,
                scope: {
                    filled: "=",
                    noGutters: "=",
                    noTopBorder: "="
                },
                controller: ['$scope', studentSectionOutlineController]
            };
        }
    ]);

    function studentSectionOutlineController($scope) {
        $scope.fillCss = "";
        if ($scope.filled) {
            $scope.fillCss = "required-section";
        }
        if (typeof $scope.noTopBorder !== 'undefined' && $scope.noTopBorder) {
            $scope.fillCss = $scope.fillCss + " no-top-border";
        }

        $scope.outerCss = "";
        if (typeof $scope.noGutters !== 'undefined' && $scope.noGutters) {
            $scope.outerCss = "no-gutters";
        }
    }
})();