app.directive('baiInsightsDecisionMapWizardStep', [function () {
    return {
        restrict: 'E',
        templateUrl: 'App/templates/insights/mappingdecisions/wizard/baiInsightsDecisionMapWizardStep.html',
        scope: true,
        require: '^baiInsightsDecisionMapWizard',
        controller: ['$scope', '$q', '$location', '$timeout', 'InsightsService', 'InsightsDecisionMapService', 'errorDisplayerUtil',
            function ($scope, $q, $location, $timeout, InsightsService, InsightsDecisionMapService, errorDisplayerUtil) {
            var decisionMap = InsightsDecisionMapService.getDecisionMap();
            // If we don't have a decision map yet, redirect to insights home
            if (_.isEmpty(decisionMap)) {
                $location.path("insights/home");
                return;
            }
            $scope.isLoading = true;
            $scope.question = "";
            var relatedChoices = [];
            var unrelatedChoices = [];
            $scope.low = 0;
            $scope.medium = 1;
            $scope.high = 2;
            $scope.choices = [];
            $scope.shouldSeeMore = false;
            $scope.selectSingle = true;
            $scope.selectWithCategories = false;
            $scope.getStepError = {};
            $scope.getStepError.error = errorDisplayerUtil.create();
            $scope.seeMoreError = {};
            $scope.seeMoreError.error = errorDisplayerUtil.create();
            $scope.seeMoreLinkText = "See More";
            $scope.seeMoreLoading = false;
            $scope.decisionMapId = decisionMap.id;
            $scope.decisionMapName = decisionMap.name;
            $scope.unrelatedModal = [];
            $scope.showModal = InsightsDecisionMapService.shouldShowModal($scope.currentWizardStep);
            $scope.removedSelections = [];

            var preSelectChoice = function(choice) {
                if (choice && choice.key > 0) {
                    var relatedIndex = _.findIndex(relatedChoices, { "key": choice.key });
                    if (relatedIndex === -1) {
                        relatedChoices.push({ key: choice.key, value: choice.value });
                        $scope.unrelatedModal.push({ key: choice.key, value: choice.value });
                    }
                    $scope.selectedValue = choice.key;
                    $scope.enableNextButton();
                }
            };

            var preSelectMultiChoice = function (choices) {
                _.each(choices, function (c) {
                    if (c.key > 0) {
                        var relatedIndex = _.findIndex(relatedChoices, { "key": c.key });
                        if (relatedIndex > -1) {
                            relatedChoices[relatedIndex].selected = true;
                            $scope.enableNextButton();
                        } else {
                            relatedChoices.push({ key: c.key, value: c.value, selected: true });                           
                            $scope.unrelatedModal.push({ key: c.key, value: c.value });
                            $scope.enableNextButton();
                        }
                    }
                });
            };

            var preSelectRiskRating = function (choices) {
                //go through each selected risk rating and set the riskRating to that value for the risk in the relatedChoices 
                _.each(choices, function (c) {
                    if (c.key > 0) {
                        var relatedIndex = _.findIndex(relatedChoices, { "key": c.key });
                        if (relatedIndex > -1) {
                            if (relatedChoices[relatedIndex].activeRatings === undefined || relatedChoices[relatedIndex].activeRatings === null) {
                                relatedChoices[relatedIndex].activeRatings = [];
                                relatedChoices[relatedIndex].activeRatings.push('');
                                relatedChoices[relatedIndex].activeRatings.push('');
                                relatedChoices[relatedIndex].activeRatings.push('');
                            }
                            if (c.riskRating === 'Low') {
                                relatedChoices[relatedIndex].activeRatings[$scope.low] = 'active';
                            } else if (c.riskRating === 'Medium') {
                                relatedChoices[relatedIndex].activeRatings[$scope.medium] = 'active';
                            } else if (c.riskRating === 'High') {
                                relatedChoices[relatedIndex].activeRatings[$scope.high] = 'active';
                            }
                            relatedChoices[relatedIndex].riskRating = c.riskRating;
                            $scope.enableNextButton();
                        } else {
                            var ar = [];
                            ar.push('');
                            ar.push('');
                            ar.push('');
                            if (c.riskRating === 'Low') {
                                ar[$scope.low] = 'active';
                            } else if (c.riskRating === 'Medium') {
                                ar[$scope.medium] = 'active';
                            } else if (c.riskRating === 'High') {
                                ar[$scope.high] = 'active';
                            }
                            relatedChoices.push({ key: c.key, value: c.value, riskRating: c.riskRating, activeRatings: ar });

                            $scope.unrelatedModal.push({ key: c.key, value: c.value });
                            $scope.enableNextButton();
                        }
                    }
                });
            };

            var setupStep = function(questionText, shouldSeeMore, selectSingle, preSelectFunction, preSelectFunctionParams) {
              
                $scope.newCommentText = null;
                $scope.selectRiskRating = false;

                var promises = [];
                promises.push(InsightsService.getDecisionMapWizardOptions($scope.currentWizardStep, decisionMap.id));
                if($scope.currentWizardStep === 'responsibilities')
                {
                    $scope.selectWithCategories = true;
                    promises.push(InsightsService.getResponsibilityCategories());
                } else if ($scope.currentWizardStep === 'risks') {
                    promises.push(InsightsService.getRiskDetails());
                }

               $q.all(promises).then(function (success) {
                    $scope.question = questionText;
                    relatedChoices = success[0];

                    //Check the related choices for the unrelatedCount to see if there are any unrelated options
                    var unrelatedCountIdx = _.findIndex(relatedChoices, { "value": 'UnrelatedCount' });
                    var unrelatedCount = 1;/* default to 1 aka shouldSeeMore */
                    if (unrelatedCountIdx > -1) {
                        unrelatedCount = relatedChoices[unrelatedCountIdx].key;
                        relatedChoices.splice(unrelatedCountIdx, 1);
                    }

                    
                    $scope.selectSingle = selectSingle;
                    preSelectFunction(preSelectFunctionParams);
                    $scope.shouldSeeMore = (shouldSeeMore && unrelatedCount > 0 && ($scope.unrelatedModal.length < unrelatedCount));
                    $scope.choices = relatedChoices;
                    $scope.isLoading = false;
                    if ($scope.currentWizardStep === 'responsibilities') {
                        $scope.responsibilityCategories = success[1];
                        $scope.populateResponsibilityCategories();
                    } else if ($scope.currentWizardStep === 'risks') {
                        $scope.riskDetails = success[1];
                        $scope.populateRiskDetails();
                    }
                    if ($scope.showModal && $scope.unrelatedModal.length > 0) {
                        $scope.unrelatedModal = _.sortBy($scope.unrelatedModal, 'value');
                        angular.element(document.querySelector('#confirmModal')).modal();
                    } else {
                        InsightsDecisionMapService.resetModalFlag($scope.currentWizardStep);
                    }

                }, function(failure) {
                    $scope.getStepError.error.setError(failure.StatusCode, failure.ErrorMessage);
                    $scope.isLoading = false;
                });
            };

            if ($scope.currentWizardStep === "function") {
                setupStep("<label>What is a <strong>Function</strong> of this organizational structure? <i>(Select only one)</i></label>", true, true, preSelectChoice, decisionMap.function);
            } else if ($scope.currentWizardStep === "role") {
                setupStep("<label>What is a <strong>Role</strong> of this organizational structure? <i>(Select only one)</i></label>", true, true, preSelectChoice, decisionMap.role);
                $scope.notApplyText = "The following Role that you previously selected is not typically associated with the Decision Report's current Function selection.";
                $scope.currentWizardStepPretty = 'Role';
            } else if ($scope.currentWizardStep === 'responsibilities') {
                setupStep("<label>What are the main <strong>Responsibilities</strong> of this role? <i>(Select all that apply)</i></label>", true, false, preSelectMultiChoice, decisionMap.responsibilities);
                $scope.notApplyText = "The following Responsibilities that you previously selected are not typically associated with the Decision Report's current Role selection.";
                $scope.currentWizardStepPretty = 'Responsibilities';
            }  else if ($scope.currentWizardStep === 'experiences') {
                setupStep("<label>What are the <strong>Experience Levels</strong> of the people in this role? <i>(Select all that apply)</i></label>", false, false, preSelectMultiChoice, decisionMap.experiences);
            } else if ($scope.currentWizardStep === 'risks') {
                setupStep("<label>Rate the following <strong>Risks</strong> in terms of their relation to this role. <i>(Rate all that apply)</i></label>", true, false, preSelectRiskRating, decisionMap.risks);
                $scope.selectRiskRating = true;
                $scope.notApplyText = "The following Risks that you previously selected are not typically associated with the Decision Report's current Role selection. ";
                $scope.currentWizardStepPretty = 'Risks';
            }
            
            $scope.seeMore = function() {
                
                /* SEE MORE */
                if ($scope.seeMoreLinkText === "See More") {
                    var seeMoreChoicesPromise = InsightsService.getDecisionMapWizardOptions($scope.currentWizardStep, decisionMap.id, true);

                    $scope.seeMoreLoading = true;
                    seeMoreChoicesPromise.then(function(success) {
                        _.each(success, function(uc) {
                            var tempChoice = null;
                            // Find if choice is already in related list
                            _.each(relatedChoices, function(rc) {
                                if (rc.key === uc.key) {
                                    tempChoice = rc;
                                    return;
                                }
                            });
                            // If it's not in related list, add it to unrelated list
                            if (tempChoice === null) {
                                unrelatedChoices.push(uc);
                            }
                        });

                        unrelatedChoices = _.sortBy(unrelatedChoices, 'value');
                        $scope.choices = relatedChoices.concat(unrelatedChoices);

                        if ($scope.currentWizardStep === "responsibilities") {
                            //If the current step is Responsibilities, then after we have the full choices list
                            //create the responsibility category array
                            $scope.populateResponsibilityCategories();
                        } else if ($scope.currentWizardStep === 'risks') {
                            $scope.populateRiskDetails();
                        }

                        $scope.seeMoreLinkText = "See Less";
                        $scope.seeMoreLoading = false;
                    }, function(failure) {
                        $scope.seeMoreError.error.setError(failure.StatusCode, failure.ErrorMessage);
                        $timeout(function() {
                            $scope.seeMoreError.error.reset();
                        }, 3000);
                        $scope.seeMoreLoading = false;
                    });
                }
                    /* SEE LESS */
                else {

                    /* see less on function or role (single select) */
                    if ($scope.selectSingle) {
                        var selectedChoiceKey = 0;
                        if ($scope.currentWizardStep === "function") {
                            selectedChoiceKey = decisionMap.function.key;
                        } else if ($scope.currentWizardStep === "role") {
                            selectedChoiceKey = decisionMap.role.key;
                        }
                        _.each(unrelatedChoices, function(choice) {
                            if (selectedChoiceKey === choice.key) {
                                var relatedIndex = _.findIndex(relatedChoices, { "key": choice.key });
                                if (relatedIndex === -1) {
                                    relatedChoices.push(choice);
                                }
                            }
                        });
                    }
                    /* see less on Responsibilities, experiences and risks (multi select) */
                    else {

                        if ($scope.currentWizardStep === 'experiences' || $scope.currentWizardStep === 'risks') {
                            /* for experiences and risks, loop through choices to see what is selected */
                            _.each($scope.choices, function (choice) {
                                if (choice.selected === true || choice.riskRating) {
                                    // Sanity check against the "saved" choices in the decision map to make sure selected choice is a decisionMap choice. Don't know if this is 100% necessary
                                    var arrayToCheck = [];
                                    if ($scope.currentWizardStep === "experiences") {
                                        arrayToCheck = decisionMap.experiences;
                                    } else if ($scope.currentWizardStep === "risks") {
                                        arrayToCheck = decisionMap.risks;
                                    }
                                    var selectedIndex = _.findIndex(arrayToCheck, { "key": choice.key });
                                    if (selectedIndex > -1) {
                                        var relatedIndex = _.findIndex(relatedChoices, { "key": choice.key });
                                        if (relatedIndex === -1) {
                                            relatedChoices.push(choice);
                                        }
                                    }
                                }
                            });
                        } else if ($scope.currentWizardStep === "responsibilities") {
                            /* for Responsibilities, loop through the choicesGrouped - responsibilities */
                            var selectedResponsibilities = decisionMap.responsibilities;                            
                            _.each($scope.choicesGrouped, function (categoryRow) {

                                _.each(categoryRow, function (category) {

                                    _.each(category.responsibilities, function (c) {
                                        if (c.selected === true) {
                                            var selectedIndex = _.findIndex(selectedResponsibilities, { "key": c.key });
                                            if (selectedIndex > -1) {
                                                var relatedIndex = _.findIndex(relatedChoices, { "key": c.key });
                                                if (relatedIndex === -1) {
                                                    relatedChoices.push(c);
                                                }
                                            }
                                        }
                                    });

                                });
                            });
                        }

                      
                    }   /* end multi select*/


                    $scope.choices = relatedChoices;
                    if ($scope.currentWizardStep === "responsibilities") {
                        $scope.populateResponsibilityCategories();
                    }
                    unrelatedChoices.length = 0;
                    $scope.seeMoreLinkText = "See More";

                }   /*end see less*/
            };

            $scope.choiceSelectionChanged = function (selectedValue) {
                var selectedChoice = null;
                _.each($scope.choices, function (c) {
                    if (c.key === selectedValue) {
                        selectedChoice = { key: c.key, value: c.value };
                        return;
                    }
                });
                
                $scope.selectedValue = selectedChoice.key;
                if ($scope.currentWizardStep === "function") {
                    InsightsDecisionMapService.setFunction(selectedChoice);
                } else if ($scope.currentWizardStep === "role") {
                    InsightsDecisionMapService.setRole(selectedChoice);
                }
                 $scope.enableNextButton();
            };

            $scope.multiChoiceSelectionChanged = function () {
                var selectedChoices = [];            

                if ($scope.currentWizardStep === "responsibilities") {
                    //For Responsibilities, loop through the choicesGrouped - responsibilities
                    _.each($scope.choicesGrouped, function (categoryRow) {
                        _.each(categoryRow, function (category) {
                            _.each(category.responsibilities, function (c) {
                                if (c.selected === true) {
                                    selectedChoices.push({ key: c.key, value: c.value });
                                    var relInd = _.findIndex(relatedChoices, { "key": c.key });
                                    if (relInd > -1) { relatedChoices[relInd].selected = true; }
                                } else {
                                    var relIndUnchecked = _.findIndex(relatedChoices, { "key": c.key });
                                    if (relIndUnchecked > -1) { relatedChoices[relIndUnchecked].selected = false; }
                                }
                            });
                        });
                    });

                    InsightsDecisionMapService.setResponsibilites(selectedChoices);
                } else if ($scope.currentWizardStep === "experiences") {
                    //For experiences, loop through choices to see the selections
                    _.each($scope.choices, function (c) {
                        if (c.selected === true) {
                            selectedChoices.push({ key: c.key, value: c.value });
                        }
                    });
                    //Set the experiences on the map
                    InsightsDecisionMapService.setExperiences(selectedChoices);
                }
                
                //Enable or disable the next button
                if (selectedChoices.length > 0) {
                    $scope.enableNextButton();
                } else {
                    $scope.disableNextButton();
                }
            };

            $scope.changeRiskRating = function(riskRating, riskId) {
                var ratedChoices = [];
                _.each($scope.choices, function(c) {
                    if (c.key === riskId) {
                        if (c.activeRatings === null || c.activeRatings === undefined) {
                            c.activeRatings = [];
                            c.activeRatings.push('');
                            c.activeRatings.push('');
                            c.activeRatings.push('');
                        }
                        if (riskRating === 'Low') {
                            if (c.activeRatings[$scope.low] === 'active') {
                                c.activeRatings[$scope.low] = '';
                                c.riskRating = '';
                            } else {
                                c.activeRatings[$scope.low] = 'active';
                                c.riskRating = riskRating;
                                c.activeRatings[$scope.medium] = '';
                                c.activeRatings[$scope.high] = '';
                            }
                        } else if (riskRating === 'Medium') {
                            if (c.activeRatings[$scope.medium] === 'active') {
                                c.activeRatings[$scope.medium] = '';
                                c.riskRating = '';
                            } else {
                                c.activeRatings[$scope.low] = '';
                                c.activeRatings[$scope.medium] = 'active';
                                c.riskRating = riskRating;
                                c.activeRatings[$scope.high] = '';
                            }
                        } else if (riskRating === 'High') {
                            if (c.activeRatings[$scope.high] === 'active') {
                                c.activeRatings[$scope.high] = '';
                                c.riskRating = '';
                            } else {
                                c.activeRatings[$scope.low] = '';
                                c.activeRatings[$scope.medium] = '';
                                c.activeRatings[$scope.high] = 'active';
                                c.riskRating = riskRating;
                            }
                        }

                        return;
                    }
                });

                _.each($scope.choices, function(c) {
                    if (typeof c.riskRating !== 'undefined' && c.riskRating !== '') {
                        ratedChoices.push({ 'key': c.key, 'value': c.value, 'riskRating': c.riskRating });
                    }
                });
                InsightsDecisionMapService.setRisks(ratedChoices);

                if (ratedChoices.length > 0) {
                    $scope.enableNextButton();
                } else {
                    $scope.disableNextButton();
                }
            };

            $scope.populateResponsibilityCategories = function () {
                
                var cats = [];
                //Loop through the array of Responsibilities
                _.each($scope.choices, function (c) {
                    //Find the responsibilityCategory for the responsibility
                    var catIndex = _.findIndex($scope.responsibilityCategories, { "key": c.key });
                    if (catIndex > -1) {
                        //If that responsibility category already exists in the cats array
                        var i = _.findIndex(cats, { "category": $scope.responsibilityCategories[catIndex].value });
                        if (i > -1) {
                            //Then add the responsibility to that category
                            cats[i].responsibilities.push({ 'key': c.key, 'value': c.value, 'selected': c.selected });
                        } else {
                            //OR create the category and add the responsibility to the new category
                            cats.push({ "category": $scope.responsibilityCategories[catIndex].value });
                            cats[cats.length - 1].responsibilities = [];
                            cats[cats.length - 1].responsibilities.push({ 'key': c.key, 'value': c.value, 'selected': c.selected });
                        }
                    }
                });
                
                cats.sort(function (a, b) { return (a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0); });
                //Set the scope variable to use on the page
                $scope.choicesGrouped = createCategoryRows(cats, 2);
            };

            var createCategoryRows = function (arr, size) {
                var newArr = [];
                for (var i = 0; i < arr.length; i += size) {
                    newArr.push(arr.slice(i, i + size));
                }
                return newArr;
            };

            $scope.populateRiskDetails = function () {
                //foreach risk in choices, add the corresponding detail
                _.each($scope.choices, function (c) {
                    var detailIdx = _.findIndex($scope.riskDetails, { "key": c.key });
                    if (detailIdx > -1) {
                        c.detail = $scope.riskDetails[detailIdx].value;
                    } else {
                        c.detail = 'hideDetail';
                    }
                });

            };


            /* Remove all unassociated results in the confirm selections modal */
            $scope.removeAllSelections = function () {

                //Add all modal results to removedSelections to be used to update the main page
                _.each($scope.unrelatedModal, function (u) {
                    $scope.removedSelections.push({ "key": u.key });
                });
                //wipe out the unrelatedModal array
                $scope.unrelatedModal = [];
            };

                /* Remove one unassociated result in the confirm selections modal */
            $scope.removeSelection = function (key) {

                var relatedIndex = _.findIndex($scope.unrelatedModal, { "key": key });
                if (relatedIndex > -1) {
                    //Find the selection in the unrelatedModal - delete 
                    //add the selection to removedSelections to be used to update the main page
                    $scope.unrelatedModal.splice(relatedIndex, 1);
                    $scope.removedSelections.push({ "key": key });
                }
            };

            /* Confirm Selections modal Save */
            $scope.saveModalSelectionsOnPage = function () {
                //Hide the modal and reset the flag for the step
                angular.element(document.querySelector('#confirmModal')).modal('hide');
                InsightsDecisionMapService.resetModalFlag($scope.currentWizardStep);

                //remove selections on main page
                _.each($scope.removedSelections, function (s) {
                    var choicesIndex = _.findIndex($scope.choices, { "key": s.key });
                    if (choicesIndex > -1) {
                        $scope.choices.splice(choicesIndex, 1);
                    }
                });

                //if selections were removed
                if ($scope.removedSelections.length > 0) {

                    //The user removed at least one, so there is at least one in the see more
                    $scope.shouldSeeMore = true;

                    if ($scope.currentWizardStep === 'role') {
                        //for role, that means that there are no longer any roles selected
                        //the next button should be disabled
                        $scope.disableNextButton();
                    } else if ($scope.currentWizardStep === 'responsibilities') {
                        //For responsibilities, need to recalculate the categorization
                        $scope.populateResponsibilityCategories();
                        //recalculate the responsibilities selected and save to decision map in the service
                        $scope.multiChoiceSelectionChanged();
                    } else if ($scope.currentWizardStep === 'risks') {
                        //recalculate the risk ratings and save to decision map in the service
                        $scope.changeRiskRating(null, null);
                    }
                }
            };

        }],
        link: function($scope, el, attrs, parentCtrl) {
            $scope.enableNextButton = function() {
                parentCtrl.enableNextButton();
            };
            $scope.disableNextButton = function() {
                parentCtrl.disableNextButton();
            };
        }
    };
}]);