/* Various helper functions for manipulating organization trees from OrganizationsService */

/**
 * @TODO: @sneilan move this to organizations model.
 */
app.factory('OrgFunctions', [function() {
    /* This function takes the root organization
     * and returns a list of organizations with their org tree paths
     * Saves data to dictionaryToSaveOrgsTo
     * Do something like var dictionaryToSaveOrgsTo = {};
     * organization should be the return value of OrganizationsService.getOrganizationHierarchy;
     * whereas you'll send in the first number
     * var orgTreePromise = OrganizationsService.getOrganizationHierarchy();
     * orgTreePromise.then(function(orgTreeData) {
     *   var dictionaryToSaveOrgsTo = {};
     *   createOrgHierarchyList(orgTreeData[0], dictionaryToSaveOrgsTo);
     *   // dictionaryToSaveOrgsTo now has data!! :)
     * }
     * dictionaryToSaveOrgsTo will look like this
     *  dictionaryToSaveOrgsTo[organization.id] = [{name: name, _id: organization.id, orgObject: organization}... and so forth]
     *  whereas the first node in each row of that dictionary is the root and it goes down.
     */
    function createOrgHierarchyList(organization, dictionaryToSaveOrgsTo, currentPathArray) {
        /**
         * We are assuming this will always be there. That is not the case. We need to ensure that we are doing a check,
         * otherwise there will be a failure and JS error.
         */
        if(typeof organization === 'undefined') {
            return;
        }

        if (currentPathArray === undefined) {
            currentPathArray = [];
        }

        var orgFolder = {};

        orgFolder.name = organization.name;
        orgFolder._id = organization.id;
        orgFolder.orgObject = organization;

        // Javascript passes everything by reference which interferes with programming
        // recursive functions. This type of recursion only works if the arguments are passed by
        // value and not reference.

        var currentPathArrayCopy = angular.copy(currentPathArray);

        currentPathArrayCopy.push(orgFolder);

        dictionaryToSaveOrgsTo[organization.id] = currentPathArrayCopy;

        if (organization.nodes) {
            for (var i = 0; i < organization.nodes.length; i += 1) {
                createOrgHierarchyList(organization.nodes[i], dictionaryToSaveOrgsTo, currentPathArrayCopy);
            }
        }
    }

    // http://stackoverflow.com/a/31247960/761726
    /** Takes a flat list of tree nodes and turns them into a tree
     * Each node needs a marker for the id, parentId & something to say
     * whether it's a root node. (We're actually mapping multiple trees)
     *
     * Example: You can have a array of nodes like this
     * { 'id' : 1, 'parentId': 3, 'isTopLevelOrg' : false}
     * where 'id' is what's in idMarkerString,
     * 'parentId' is what's in parentIdMarkerString
     * function(node) { return node.isTopLeveLorg; } is a function to determine if something is a top level node. (rootCheckerFunction)
     *
     * each id should be unique. each parentId should point to another node
     * If a node is a top level node, the rootCheckerFunction should return true.
     *
     * So the call for this array would be 
     * var arr = [{ 'id' : 1, 'parentId': 3, 'isTopLevelOrg' : false}];
     * var tree = unflatten(arr, 'id', 'parentId', function(node) { return node.isTopLevelOrg; });
     *
     * If you had something like this
     * var arr = [{ 'id' : 1, 'managerId': 3, 'managerId' : 123},
     * { 'id' : 2, 'managerId': 3, 'managerId' : 0}];
     * Where managerId is 0 if the node is a top level node, you could call unflatten like so
     * var tree = unflatten(arr, 'id', 'managerId', function(node) { return node.managerId === 0; });
     *
     *
     * In ancient times, Jesus turned water into wine
     * But he did not take a flat list of organizations and
     * transmute it into a tree structure.
     *
     * @var idMarkerTring string Definition of idMarkerSTring
     */
    function unflatten(arr, idMarkerString, parentIdMarkerString, rootCheckerFunction, includeFilterFunction) {
      var tree = [],
          mappedArr = {},
          arrElem,
          mappedElem;

      // First map the nodes of the array to an object -> create a hash table.
      for(var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem[idMarkerString]] = arrElem;
        mappedArr[arrElem[idMarkerString]].nodes = [];
      }

      for (var id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
          mappedElem = mappedArr[id];
          // If the element is not at the root level, add it to its parent array of children.
          if (!rootCheckerFunction(mappedElem)) {
             /* To account for bad data, check if someone's managerId points to a user
              * That's not actually a manager */
              //SLY-4000 use filter function here to exclude nodes from the tree if defined.
              if (typeof mappedArr[mappedElem[parentIdMarkerString]] !== 'undefined' && (typeof includeFilterFunction !== 'function' || includeFilterFunction(mappedElem))) 
                mappedArr[mappedElem[parentIdMarkerString]].nodes.push(mappedElem);
          }
          // If the element is at the root level, add it to first level elements array.
          else {
            tree.push(mappedElem);
          }
        }
      }
      return tree;
    }

    /* Recursively Gets a list of node ids under this node.
     * Useful for filtering rows by a tree or subtree
     *
     * Takes a rootnode with nodes below it.
     * A dictionary to store the list of ids.
     * An id marker to know what we should build a list of
     *
     * Returns a list of ids under the node
     *
     * Recommended: use createOrgHierarchyList to create a rootNode
     * listToStoreOrgIdsIn should be an array
     * id marker will be a string
     *
     * var rootNode = createOrgHierarchyList(..); // SEE definition for details
     * var listToStoreIdsIn = [];
     * var idMarker = 'id';
     * getSubIdsRecursively(rootNode, listToStoreIdsIn, idMarker);
     * console.log(listToStoreOrgIdsIn);
     *
     * The top level organization ID will also be in the list of ids returned */
    function getSubIdsRecursively(rootNode, listToStoreIdsIn, idMarker) {
        listToStoreIdsIn.push(rootNode[idMarker]);
        if (rootNode.nodes) {
            for (var i = 0; i < rootNode.nodes.length; i += 1) {
                getSubIdsRecursively(rootNode.nodes[i], listToStoreIdsIn, idMarker);
            }
        }
    }

    return {
        createOrgHierarchyList: createOrgHierarchyList,
        unflatten: unflatten,
        getSubIdsRecursively: getSubIdsRecursively
    };
}]);

