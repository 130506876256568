(function () {
    catalog.directive('baiCatalogHeader', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/components/baiCatalogHeader.html',
                controller: 'baiCatalogHeaderController'
            };
        }
    ]);
})();