(function () {
    app.directive('studentSectionHeader', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/studentui/studentSectionHeader.html',
                scope: {
                    title: '@'
                },
                controller: ['$scope', studentSectionHeaderController]
            };
        }
    ]);

    function studentSectionHeaderController($scope) {
    }
})();