(function () {
    app.directive('studentTiles', [
        function () {
            return {
                restrict: 'E',
                templateUrl: 'App/templates/studentui/studentTiles.html',
                scope: {
                    assignments: "=",
                    seeMoreColor: "=",
                    courseSectionName: "=",
                    displayTime: "="
                },
                controller: 'studentTilesController',
            };
        }
    ]);
})();