app.controller('Offerings_AdminController', ['$scope', '$uibModalInstance', 'offeringId', '$mdDialog', '$uibModal', 'dateUtil', 'errorDisplayerUtil', 'OfferingsService', 'RosterService', 'UsersService',
    function ($scope, $uibModalInstance, offeringId, $mdDialog, $uibModal, dateUtil, errorDisplayerUtil, OfferingsService, RosterService, UsersService) {

    var _this = this;

    $scope.offeringId = offeringId;
        
    var offeringType = 'ilt';

    _this.totalCompleteCalls = 0;
    _this.groupSaveTotal = 0;
    _this.totalSavedFromGroupCall = 0;
        
    $scope.allHours = 0;

    $scope.isLoading = false;
    $scope.offeringDate = new Date();

    /**
        * Set loading, error, etc. options onto the generic API action.
        *
        * @type {{}}
        */
    $scope.userSearchOptions = {};

    $scope.userSearchOptions.error = errorDisplayerUtil.create();
    $scope.userSearchOptions.searched = false;
    $scope.userSearchOptions.selected = 0;

    $scope.rosterStatus = 'default';
    $scope.selectedSession = 1;
    $scope.sessions = null;
    $scope.sessionOrder = 'lowestSessionDateForOffering.startDate';
    $scope.statuses = $scope.completionStatuses;


    $scope.options = {};
    $scope.options.isLoading = false;
    $scope.options.error = errorDisplayerUtil.create();

    $scope.rosterViewOptions = {};
    $scope.rosterViewOptions.isLoading = false;
    $scope.rosterViewOptions.error = errorDisplayerUtil.create();

    $scope.waitlistViewOptions = {};
    $scope.waitlistViewOptions.isLoading = false;
    $scope.waitlistViewOptions.error = errorDisplayerUtil.create();

    $scope.pendingOptions = {};
    $scope.pendingOptions.isLoading = false;
    $scope.pendingOptions.error = errorDisplayerUtil.create();

    $scope.dialogOptions = {};
    $scope.dialogOptions.isLoading = false;
    $scope.dialogOptions.error = errorDisplayerUtil.create();

    $scope.hasDecimal = false;

    $scope.rosterStatus = 'Change Status';

    $scope.completionStatuses = [
        'Change Status',
        'Successful',
        'Unsuccessful',
        'No Show'
    ];

    $scope.userCompletionStatuses = [{
        name: 'Change Status',
        value: 'default'
    }, {
        name: 'Successful',
        value: 'successful'
    }, {
        name: 'Unsuccessful',
        value: 'unsuccessful'
    }, {
        name: 'No Show',
        value: 'cancelledNoShow'
    }];

    $scope.substitutor = {};
    $scope.substitutor.error = errorDisplayerUtil.create();

    /**
     * "Private" methods; don't want these ever called directly from scope.
     */
    /**
     *
     * @param user
     */
    _this.updateStudentInBatch = function (user, status, data, successCallback, errorCallback, finalSuccessCallback, finalErrorCallback) {
        user.call = RosterService.updateSinglePersonOnRoster(user.id, offeringId, status, offeringType, data);

        user.call.then(function (success) {
            successCallback(success, user);

            _this.totalCompleteCalls++;
            _this.totalSavedFromGroupCall++;

            _this.groupSaveCounter(finalSuccessCallback, finalErrorCallback, user);
        }, function (error) {
            errorCallback(error, user);

            _this.totalCompleteCalls++;

            _this.groupSaveCounter(finalSuccessCallback, finalErrorCallback, user);
        });
    };

    /**
     * Generic function to keep track of any and all batch edits/creates.
     *
     * Once all have succeeded, the callback will trigger.
     *
     * @param callback
     */
    _this.groupSaveCounter = function (successCallback, errorCallback, user) {
        if (_this.totalSavedFromGroupCall === _this.groupSaveTotal) {
            successCallback(user);
        } else if (_this.totalCompleteCalls === _this.groupSaveTotal && _this.totalSavedFromGroupCall !== _this.groupSaveTotal) {
            errorCallback(user);
        }
    };

    /**
     * Scope methods
     */
    /**
     * Action that is called when the URI matches /offerings/view/<id>/, and the tab is Info
     *
     * Method will go get single offering information for display.
     */
    $scope.viewAction = function () {
        $scope.options.isLoading = true;

        $scope.options.error.reset();

        var offering = OfferingsService.fetchSingle(offeringId, offeringType);
        offering.then(function (success) {
            $scope.userEmailList = RosterService.generateMailToFromUsers('attendingStudents').join(';');

            $scope.options.isLoading = false;

            $scope.offering = success;
            $scope.offeringComplete = true;

            var offeringStatus = success.status;
            if (offeringStatus === "open") {
                $scope.offeringComplete = false;
            }

            $scope.offeringDate = new Date($scope.offering.lowestSessionDateForOffering.original);

            $scope.selectedSession = OfferingsService.getLowestSessionIdByDate();
            $scope.sessions = $scope.offering.sessions;

            // If no students have been set to any completed status, this will be true.
            $scope.isCancellable = OfferingsService.isOfferingCancellable();

        }, function (failure) {
            $scope.options.isLoading = false;

            $scope.options.error.setError(failure.StatusCode, failure.ErrorMessage);
        });

        jQuery('.instructor-extra-nav').hide();
    };

    /**
     * Action that is called when the URI matches /offerings/view/<id>/, and the tab is Roster
     */
    $scope.rosterAction = function () {
        $scope.rosterViewOptions.error.reset();

        $scope.rosterViewOptions.isLoading = true;

        var roster = RosterService.fetch(offeringId, offeringType);
        roster.then(function (success) {
            $scope.rosterViewOptions.isLoading = false;

            $scope.roster = RosterService.getAttendingStudents();

            $scope.selectedSession = OfferingsService.getLowestSessionIdByDate();
            $scope.sessions = $scope.offering.sessions;
            $scope.sessionsForDropdown = [];

            /**
             * Extraneous code because handling dropdowns in angular has to be overcomplicated to be cool.
             */
            var curIndex = 0;
            for (var i = 0; i < $scope.sessions; i++) {
                curIndex = parseInt(i, 10) + 1;

                $scope.sessionsForDropdown.push({
                    name: 'Session ' + curIndex + ' - ' + $scope.sessions[i].startDateTime.fullDateWithTime,
                    value: $scope.sessions[i].id
                });
            }
        }, function (failure) {
            $scope.rosterViewOptions.isLoading = false;

            $scope.rosterViewOptions.error.setError(failure.StatusCode, failure.ErrorMessage);
        });

        jQuery('.instructor-extra-nav').show();
    };

    /**
     * Action that is called when the URI matches /offerings/view/<id>/, and the tab is Waitlist
     */
    $scope.waitlistAction = function () {
        $scope.waitlistroster = null;

        $scope.options.error.reset();
        $scope.waitlistViewOptions.error.reset();

        $scope.waitlistViewOptions.isLoading = true;

        var roster = RosterService.fetch(offeringId, offeringType); //adding offeringType gives success, avoids error 8/9/16 D.Orr

        roster.then(function (success) {
            $scope.waitlistroster = RosterService.getWaitlistedStudents();

            if ($scope.waitlistroster === null || $scope.waitlistroster.length === 0) {
                $scope.waitlistViewOptions.error.setError(404, 'The wait list for this course is currently empty.', true);
            }

            $scope.waitlistViewOptions.isLoading = false;
        }, function (failure) {
            $scope.waitlistViewOptions.isLoading = false;

            $scope.waitlistViewOptions.error.setError(failure.StatusCode, failure.ErrorMessage);
        });

        jQuery('.instructor-extra-nav').show();
    };

    /**
     * Action that is called when the URI matches /offerings/view/<id>/, and the tab is Waitlist
     */
    $scope.pendingApprovalAction = function () {
        $scope.pendingOptions.error.reset();

        $scope.pendingOptions.isLoading = true;

        var roster = RosterService.fetch(offeringId, offeringType);
        roster.then(function (success) {
            $scope.pendingOptions.isLoading = false;

            $scope.roster = RosterService.getPendingApprovalStudents();

            if ($scope.roster.length === 0) {
                $scope.pendingOptions.error.setError(404, 'There are no students pending approval for this course.', true);
            }
        }, function (failure) {
            $scope.pendingOptions.error.setError(failure.StatusCode, failure.ErrorMessage);
        });

        jQuery('.instructor-extra-nav').show();
    };

    /**
     * Show the dialog that allows the user to confirm the offering cancellation
     *
     * @param event Object that triggered the event
     * @param offering The offering that is going to be cancelled
     */
    $scope.showCancelOfferingModal = function (event, offering) {
        $scope.offering = offering;
        $scope.dialogOptions.error.reset();
        $mdDialog.show({
            controller: 'offeringsController',
            focusOnOpen: true,
            targetEvent: event,
            preserveScope: true,
            scope: $scope,
            templateUrl: 'app/templates/offerings/components/dialogs/cancellationDialog.html',
            clickOutsideToClose: true,
            parent: angular.element('.rosterModal')
        }).then(function (answer) {

        }, function () {

        });
    };

    /**
     * Cancels an offering if the user has chosen to do so. This will run logic to make AJAX request to the offering
     * cancellation call. If answer === false, modal will close.
     *
     * @param answer Boolean passed from buttons, based on angular.material pattern
     */
    $scope.cancelOffering = function (answer) {
        if (answer === true) {
            $scope.dialogOptions.error.reset();

            $scope.dialogOptions.isLoading = true;

            var cancel = OfferingsService.cancelOffering($scope.offering.id, $scope.offering.type);
            cancel.then(function (success) {
                $scope.dialogOptions.isLoading = false;

                //$location.url('users/home/');
                _this.closeModal();
            }, function (failure) {
                $scope.dialogOptions.isLoading = false;

                $scope.dialogOptions.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        } else {
            $mdDialog.hide();
        }
    };

    /**
     * Offering cancellation logic
     */
    /**
     * Opens offering modal. This method runs a method to gather information regarding users in the roster. If there
     * are users which are not completed, or if there are no assigned users, appropriate messaging will be shown.
     *
     * @param $event event containing target that was clicked
     * @param offering current offering data
     */
    $scope.showCompleteOfferingModal = function ($event, offering) {
        $scope.offering = offering;
        $scope.dialogOptions.error.reset();
        /**
         * Get count of incomplete users;
         * if there are -1 users, there are no users signed up yet;
         * if there are 0 users, then we are good to go;
         * if there are > 0 users, there are users who have not completed the offering.
         */
        var total = OfferingsService.getIncompleteUserCount();

        total = 0;
        switch (total) {
            case -1:
                $scope.offeringDeliveryErrorMessage = 'Sorry! There are no students who have been signed up for this offering!';
                $scope.showOfferingDeliveryErrorMessage = true;

                setTimeout(function () {
                    $scope.offeringDeliveryErrorMessage = '';
                    $scope.showOfferingDeliveryErrorMessage = false;
                }, 5000);

                break;
            case 0:
                $mdDialog.show({
                    clickOutsideToClose: true,
                    focusOnOpen: true,
                    parent: angular.element('.rosterModal'),
                    preserveScope: true,
                    scope: $scope,
                    templateUrl: 'app/templates/offerings/components/dialogs/completionDialog.html'
                }).then(function (answer) {

                }, function () {

                });

                break;
            default:
                $scope.offeringDeliveryErrorMessage = 'Sorry! There are still students who have not completed the offering!';
                $scope.showOfferingDeliveryErrorMessage = true;

                setTimeout(function () {
                    $scope.offeringDeliveryErrorMessage = '';
                    $scope.showOfferingDeliveryErrorMessage = false;
                }, 5000);

                break;
        }
    };

    /**
     * Show the dialog that allows the user to confirm the offering cancellation
     *dialogOptions
     * @param event Object that triggered the event
     * @param offering The offering that is going to be cancelled
     */
    $scope.showDropUserModal = function (event, person) {
        $scope.droppedPerson = person;

        $mdDialog.show({
            clickOutsideToClose: true,
            focusOnOpen: true,
            parent: angular.element('.rosterModal'),
            targetEvent: event,
            preserveScope: true,
            scope: $scope,
            templateUrl: 'app/templates/offerings/components/dialogs/dropPersonDialog.html'
        }).then(function (answer) {

        }, function () {

        });
    };

    $scope.dropStudent = function (answer) {
        $mdDialog.hide();

        if (answer === true) {
            $scope.droppedPerson.droppingStudent = true;

            $scope.droppedPerson.error.reset();

            $scope.droppedPerson.call = RosterService.updateSinglePersonOnRoster($scope.droppedPerson.student.id, offeringId, 'cancelled', offeringType);

            $scope.droppedPerson.call.then(function (success) {
                $scope.rosterAction();
                $scope.viewAction();
                $mdDialog.hide();
            }, function (failure) {
                $scope.droppedPerson.droppingStudent = false;

                $scope.droppedPerson.error.setError(failure.StatusCode, failure.ErrorMessage);
                setTimeout(function () { droppedPerson.error.reset(); }, 2000);
            });
        }
    };

    /**
     * Completes an offering if the user has chosen to do so. This will run logic to make AJAX request to the offering
     * completion call. If answer === false, modal will close.
     *
     * @param answer Boolean passed from buttons, based on angular.material pattern
     */
    $scope.completeOffering = function (answer) {
        if (answer === true) {
            $scope.completingOffering = true;
            $scope.dialogOptions.isLoading = true;
            var completion = OfferingsService.completeOffering($scope.offering.id, $scope.offering.type);
            completion.then(function (success) {
                $scope.dialogOptions.isLoading = false;
                $mdDialog.hide();
                $scope.viewAction();
            }, function (failure) {
                $scope.dialogOptions.isLoading = false;

                $scope.dialogOptions.error.setError(failure.StatusCode, failure.ErrorMessage);
            });
        } else {
            $mdDialog.hide();
        }
    };

        $scope.changeAllStatuses = function(toStatus) {
        var roster = $scope.roster;
            var status = toStatus;

            var member = _.findWhere($scope.userCompletionStatuses, { name: status });

        /**
         * Skip going forward if there is no value; defaults don't have values.
         */
        if (typeof member === 'undefined' || typeof member.value === 'undefined') {
            return;
        }

            _.each(roster, function(r) {
                r.statusForDropdown = member.value;
            });
            
    };

    /**
     * Attach the grade to all students.
     */
    $scope.setAllGrades = function () {
        var allGrade = this.allGrades;

        if ((allGrade !== null || allGrade !== 'undefined') && (allGrade >= 0 && allGrade <= 100)) {
            for (var i in $scope.roster) {
                $scope.roster[i].student.score = allGrade;
                $scope.gradeRangeError = false;
            }
        } else {
            $scope.gradeRangeError = true;
        }
    };

    /**
     * Attach the time spent to all students on a single session.
     */
    $scope.setHoursSpent = function () {
        var allHours = this.allHours;

        for (var i in $scope.roster) {
            for (var session in $scope.roster[i].sessions) {
                sessions = $scope.roster[i].sessions;

                if (sessions[session].id === $scope.selectedSession) {
                    sessions[session].hoursSpent = allHours;
                }
            }
        }
    };

    /**
     * Update a single person for the full offering.
     *
     * @param person Person to be updated.
     */
    $scope.savePersonForFullOffering = function (person) {
        person.isLoading = true;

        person.error.reset();

        /**
         * Uses person.statusForDropdown instead of person.student.registrationStatus due to limitations in
         * angular.
         */
        var personUpdate = RosterService.updateSinglePersonOnRoster(person.student.id, offeringId, person.statusForDropdown, offeringType, {
            completionDate: $scope.offering.highestSessionDateForOffering.moment.utc().format(),
            score: parseInt(person.student.score, 10)
        });

        personUpdate.then(function (success) {
            person.isLoading = false;

            person.hasCompletedTheOffering = true;
        }, function (failure) {
            person.isLoading = false;

            person.error.setError(failure.StatusCode, failure.ErrorMessage);
            setTimeout(function () { person.error.reset(); }, 2000);
        });
    };

    /**
     * Update the session to properly track next session to update
     */
    $scope.changeSession = function () {
        $scope.selectedSession = this.selectedSession;
    };

    /**
     * Save a single person's session
     *
     * @param person Next user to save session
     */
    $scope.savePersonPerSession = function (person) {
        person.isLoading = true;

        person.error.reset();

        var correctSession = RosterService.getCorrectSessionFromUser(person.student.id, $scope.selectedSession);

        person.call = RosterService.updateSessionByUserOnOffering(person.student.id, offeringId, {
            sessionId: $scope.selectedSession,
            hoursSpent: parseFloat(correctSession.hoursSpent, 10)
        });

        person.call.then(function (success) {
            person.isLoading = false;
        }, function (failure) {
            person.isLoading = false;

            person.error.setError(failure.StatusCode, failure.ErrorMessage);
            setTimeout(function () { person.error.reset(); }, 2000);
        });
    };

    $scope.saveAllUsersForCurrentSession = function () {
        var roster = $scope.roster;

        for (var i in roster) {
            $scope.savePersonPerSession(roster[i]);
        }
    };

    $scope.saveAllForFullOffering = function () {
        var roster = $scope.roster;

        _this.groupSaveTotal = roster.length;
        _this.totalSavedFromGroupCall = 0;

        $scope.fullOfferingUpdateIsLoading = true;

        for (var i in roster) {
            if (roster[i].statusForDropdown === 'default') {
                continue;
            }

            roster[i].isLoading = true;

            roster[i].id = roster[i].student.id;

            _this.updateStudentInBatch(roster[i], roster[i].statusForDropdown, {
                completionDate: $scope.offering.highestSessionDateForOffering,
                score: parseInt(roster[i].student.score, 10)
            }, function (data, user) {
                //success callback
                user.isLoading = false;
                user.success = true;

                user.hasCompletedTheOffering = true;
            }, function (failure, user) {
                user.isLoading = false;

                user.error.setError(failure.StatusCode, failure.ErrorMessage);

            }, function (user) {
                // final success callback
            }, function () {
                // final error callback
            });
        }
    };

    $scope.openAdditionModal = function () {
        $scope.userSearchOptions.isLoading = false;
        $scope.userSearchOptions.searched = false;
        $scope.userSearchOptions.selected = 0;

        $scope.users = null;

        jQuery('#userAdditionModal').modal();
    };

    $scope.openSubstituteModal = function (person) {
        $scope.userSearchOptions.isLoading = false;
        $scope.userSearchOptions.searched = false;
        $scope.userSearchOptions.selected = 0;

        $scope.users = null;

        $scope.substitutee = person;

        $uibModal.open({
            templateUrl: 'app/templates/offerings/components/modals/userSubstitutionModal.html',
            scope: $scope,
            //size:'lg',
            windowClass: 'offeringModal',
            backdrop: 'static',
            controller: 'ModalInstanceCtrl'
        });

    };

    $scope.searchForUsers = function () {
        var userSearch = this.userSearchOptions.value;

        // Reset the error
        $scope.userSearchOptions.error.reset();

        $scope.userSearchOptions.addStudentLoader = false;
        $scope.userSearchOptions.isLoading = true;
        $scope.userSearchOptions.searched = true;

        var users = UsersService.search(userSearch);

        users.then(function (success) {
            $scope.userSearchOptions.isLoading = false;

            $scope.users = success;
        }, function (failure) {
            $scope.users = [];

            $scope.isLoading = false;

            $scope.userSearchOptions.isLoading = false;

            $scope.userSearchOptions.error.setError(failure.StatusCode, failure.ErrorMessage);
        });
    };

    $scope.setCountOfSelectedUsers = function ($event) {
        var isChecked = jQuery($event.currentTarget).is(':checked');

        if (isChecked === true) {
            $scope.userSearchOptions.selected += 1;
        } else {
            $scope.userSearchOptions.selected -= 1;
        }
    };

    $scope.addStudents = function () {
        $scope.userSearchOptions.addStudentLoader = true;

        _this.groupSaveTotal = jQuery('.checkbox:checked').length;
        _this.totalSavedFromGroupCall = 0;

        var errorCallback = function (failure, user) {
            user.showEx = true;
            $scope.userSearchOptions.addStudentLoader = false;

            user.error.reset();

            user.error.setError(failure.StatusCode, failure.ErrorMessage);

        };

        var successCallback = function (data, user) {
            user.showCheck = true;
            user.showEx = false;
        };

        var finalSuccessCallback = function () {
            $scope.userSearchOptions.addStudentLoader = false;

            jQuery('#userAdditionModal').modal('hide');

            $scope.rosterAction();
            $scope.waitlistAction();
        };

        var finalErrorCallback = function () {
            $scope.userSearchOptions.addStudentLoader = false;
        };

        for (var i in $scope.users) {
            if (typeof $scope.users[i].checked !== 'undefined' && $scope.users[i].checked === true) {
                _this.updateStudentInBatch($scope.users[i], 'confirmed', null, successCallback, errorCallback, finalSuccessCallback, finalErrorCallback);
            }
        }
    };

    /* Function refreshs the page on close of modal*/
    $scope.closeModal = function () {
        //$scope.rosterAction();
        $uibModalInstance.close();
    };

    $scope.substituteStudent = function () {
        var userId = null;

        $scope.substitutee.error.reset();

        $scope.userSearchOptions.addStudentLoader = true;

        for (var i in $scope.users) {
            if (parseInt($scope.users[i].checked, 10) > 0) {
                userId = $scope.users[i].id;

                break;
            }
        }

        // oldUser, newUser, offeringId, type
        var substitution = RosterService.substituteUser($scope.substitutee.student.id, userId, offeringId, offeringType);
        substitution.then(function (success) {
            $scope.userSearchOptions.addStudentLoader = false;

            jQuery('#userSubstitutionModal').modal('hide');

            $scope.rosterAction();
        }, function (failure) {
            $scope.userSearchOptions.addStudentLoader = false;

            $scope.substitutee.error.setError(failure.StatusCode, failure.ErrorMessage);
            setTimeout(function () { substitutee.error.reset(); }, 2000);
        });
    };

    $scope.dump = function (value) { console.warn(value); };

    $scope.showWaitlistRemoveConfirmationModal = function (event, person) {
        $scope.person = person;
        var waitlistPersonToDelete = $scope.person.fullName;

        $mdDialog.show({
            controller: ['$scope', 'person', 'reallyRemoveFromWaitList', function ($scope, person, reallyRemoveFromWaitList) {
                $scope.person = person;
                $scope.reallyRemoveFromWaitList = reallyRemoveFromWaitList;
            }],
            focusOnOpen: false,
            targetEvent: event,

            templateUrl: 'app/templates/offerings/components/dialogs/removeWaitlistDialog.html',
            clickOutsideToClose: true,
            parent: angular.element('.rosterModal'),
            locals: {
                person: $scope.person,
                reallyRemoveFromWaitList: $scope.reallyRemoveFromWaitList
            }
        }).then(function (answer) {

        });
    };

    $scope.reallyRemoveFromWaitList = function (answer) {
        if (answer === true) {
            $scope.removingWaitListStudent = true;

            var somev = OfferingsService.removeStudentFromWaitList($scope.person, $scope.offeringId);
            somev.then(function (success) {
                $scope.waitlistAction();               
                $scope.viewAction();
                $scope.removingWaitListStudent = false;
                $mdDialog.hide();
            });

        } else {
            $mdDialog.hide();
        }
    };

    $scope.getCorrectLabelForStatusDropdown = function (status) {
        return $scope.userCompletionStatuses.filter(function (member) {
            if (member.value === status) {
                return member;
            }
        })[0].name;
    };

    $scope.highlightDates = function (date) {
        var curSession = null;

        date = moment(date);

        var curDate = date.date();
        var curMonth = date.month();
        var curYear = date.year();

        if (typeof $scope.sessions !== 'undefined' && $scope.sessions !== null && $scope.sessions.length > 0) {
            for (var i = 0; i < $scope.sessions.length; i++) {
                curSession = $scope.sessions[i];

                if (curDate === curSession.startDateTime.day && curMonth === (curSession.startDateTime.monthNumber - 1) && curYear === curSession.startDateTime.year) {
                    return true;
                }
            }
        }
        

        return false;
    };
    
    }]);

//Controller for modal
app.controller('ModalInstanceCtrl', function ($scope, $uibModalInstance) {
    
    $scope.close = function () {
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});